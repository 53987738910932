import { groupBy } from 'lodash-es';

import { ESellerSettlementType } from '../../../schema/types';
import { SettlementListTableDataType } from '../types';

export const getSettlementsWithSheet = (
  settlements: SettlementListTableDataType[]
) => {
  return settlements.filter((settlement) => {
    if (!settlement.sheets) return false;

    const marketSheet = settlement.sheets.find(
      (sheet) => sheet.settlementSheetType === 'MARKET'
    );

    const vendorSheet = settlement.sheets.find(
      (sheet) => sheet.settlementSheetType === 'VENDOR'
    );

    if (marketSheet && vendorSheet) return true;
  });
};

export const getSettlementWithUnknownAmount = (
  settlements: SettlementListTableDataType[]
) => {
  return settlements.filter((settlement) => {
    if (settlement.sellerSettlementAmount === null) return true;
    if (settlement.vendorSettlementAmount === null) return true;
    if (settlement.deliveryBearingAmount === null) return true;
    if (settlement.inHouseMargin === null) return true;
  });
};

export const getCodeErrorMessage = (
  settlements: SettlementListTableDataType[]
) => {
  let orderCodes = '';

  if (settlements.length > 3) {
    orderCodes = settlements
      .slice(0, 3)
      .map((settlement) => settlement.orderCode)
      .join(', ');
    orderCodes += ` 외 ${settlements.length - 3}건`;
  } else {
    orderCodes = settlements
      .map((settlement) => settlement.orderCode)
      .join(', ');
  }

  return orderCodes;
};

export const getOrderCodesFromSettlementWithDifferentMarketId = (
  selectedSettlements: SettlementListTableDataType[]
) => {
  const group = groupBy(selectedSettlements, 'marketId');
  const marketIds = Object.keys(group).filter((id) => {
    return id !== 'null' && id !== 'undefined';
  });
  const orderCodes = marketIds.map((marketId) => {
    return group[marketId][0].orderCode;
  });

  return orderCodes;
};

export const getOrderCodesFromSettlementWithNoMarketId = (
  selectedSettlements: SettlementListTableDataType[]
) => {
  const group = groupBy(selectedSettlements, 'marketId');
  const marketIds = Object.keys(group).filter((id) => {
    return id === 'null' || id === 'undefined';
  });
  const orderCodes = marketIds.map((marketId) => {
    return group[marketId][0].orderCode;
  });

  return orderCodes;
};

export const isDifferentSellerSettlementType = (
  selectedSettlements: SettlementListTableDataType[],
  marketSellerSettlementType: ESellerSettlementType
) => {
  return !selectedSettlements.every(
    (settlement) =>
      settlement.sellerSettlementType === marketSellerSettlementType
  );
};

export const getCreateSettlementSheetOrderCodesText = (
  orderCodes: string[]
) => {
  let orderCodesText = orderCodes.slice(0, 3).join(', ');
  if (orderCodes.length > 3) {
    orderCodesText += ` 외 ${orderCodes.length - 3}건`;
  }

  return orderCodesText;
};
