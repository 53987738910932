import dayjs from 'dayjs';
import { FormState } from 'react-hook-form';

import { EPromotionType } from '../../schema/types';

import { PromotionEventFormValues, PromotionFormValues } from './types';

type GetDefaultValues = FormState<PromotionFormValues>['defaultValues'];
type GetDefaultEventValues =
  FormState<PromotionEventFormValues>['defaultValues'];

type GetDefaultValuesParmas = {
  promotionType: EPromotionType;
};

export const getPromotionProduct =
  (): PromotionFormValues['promotionProducts']['0'] => {
    return {
      inHouseBuyPrice: undefined,
      isFreeProvided: true,
      isPrepayment: false,
      isSellerBearing: false,
      isWiredBearing: false,
      customPromotionProductName: undefined,
      promotionItems: [],
      sellerBearingCost: undefined,
      setCount: 1,
      wiredBearingCost: undefined,
      originalProductId: undefined,
    };
  };

export const getDefaultRelease = (): NonNullable<
  NonNullable<GetDefaultValues>['promotionReleases']
>['0'] => ({
  deliveryMessage: undefined,
  receiverName: '',
  receiverPhoneNumber: '',
  zipCode: undefined,
  receiverAddress: '',
  receiverDetailAddress: undefined,
  // note, scheduledReleaseAt, sellerId, sellerSettlementInfoId -> submit map 처리
});

export const getDefaultScheduledReleasedAt = () =>
  dayjs().isBefore(dayjs().hour(11).minute(50))
    ? dayjs().toISOString()
    : dayjs().add(1, 'day').toISOString();

export const getDefaultValues = ({
  promotionType,
}: GetDefaultValuesParmas): GetDefaultValues => {
  return {
    promotionType,
    managerId: undefined,
    marketId: undefined,
    isDeliveryFee: false,
    deliveryFee: undefined,
    deliveryFeeBearer: undefined,
    productGroupId: undefined,
    promotionReleases: [getDefaultRelease()],
    promotionProducts: [],
    // note, scheduledReleaseAt, sellerId, sellerSettlementInfoId -> submit map 처리
    note: undefined,
    scheduledReleaseAt: getDefaultScheduledReleasedAt(),
    sellerId: undefined,
    sellerSettlementInfoId: undefined,

    marketUndesignated: true,
    isSeller: promotionType === 'SAMPLE' ? true : false,
  };
};

export const getDefaultEventValues = (): GetDefaultEventValues => ({
  promotionType: 'EVENT',
  managerId: undefined,
  marketId: undefined,
  marketEventId: undefined,
  promotionReleases: [getDefaultRelease()],
  // note, scheduledReleaseAt, sellerId, sellerSettlementInfoId -> submit map 처리
  note: undefined,
  scheduledReleaseAt: getDefaultScheduledReleasedAt(),
  sellerId: undefined,
  sellerSettlementInfoId: undefined,

  marketUndesignated: false,
  isSeller: false,
});

export const numberWithUnknownString = (num?: number | null) =>
  typeof num === 'number' ? num.toLocaleString() : '모름';
