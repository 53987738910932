import { type ClipboardEvent } from 'react';

export default function useClipboardImage() {
  const getClipboardImageFile = (e: ClipboardEvent<HTMLInputElement>) => {
    const clipboardItems = e.clipboardData.files;

    let file: File | undefined;
    for (const clipboardItem of clipboardItems) {
      if (clipboardItem.type?.startsWith('image/')) {
        file = clipboardItem;
      }
    }

    if (!file) throw new Error('No Image');

    return file;
  };

  return {
    getClipboardImageFile,
  };
}
