import {
  DownOutlined,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { App, Button, Dropdown } from 'antd';
import { useCallback, useState } from 'react';

import useExcelDownload from '../../../../common/hooks/useExcelDownload';
import { ESettlementSheetStatus } from '../../../../schema/types';
import {
  SETTLEMENT_SHEET_STATUS_LABEL,
  SETTLEMENT_SHEET_STATUS_UPDATE_TO,
} from '../../const';
import {
  AdminCountSettlementSheetStatusesDocument,
  AdminSettlementSheetsDocument,
  useAdminBulkDeleteSettlementSheetMutation,
  useAdminBulkPatchSettlementSheetStatusMutation,
} from '../../graphql/settlementSheet.generated';
import useSettlementPermission from '../../hooks/useSettlementPermission';
import useSettlementSheetListSearchParams from '../../hooks/useSettlementSheetListSearchParams';
import { SettlementSheetListTableDataType } from '../../types';

const DROPDOWN_ITEMS: { key: ESettlementSheetStatus; label: string }[] = [
  {
    key: 'SETTLEMENT_COMPLETED',
    label: '정산완료',
  },
  {
    key: 'SETTLEMENT_PENDING',
    label: '정산보류',
  },
  {
    key: 'SETTLEMENT_UNCOLLECTED',
    label: '정산미수',
  },
];

function TableActionButtons({
  selectedSettlementSheets,
  clearSelectedRows,
}: {
  selectedSettlementSheets: SettlementSheetListTableDataType[];
  clearSelectedRows: () => void;
}) {
  const { message, modal } = App.useApp();
  const hasPermission = useSettlementPermission();
  const [deleteSettlementSheetMutation] =
    useAdminBulkDeleteSettlementSheetMutation();
  const [patchSettlementSheetStatusMutation] =
    useAdminBulkPatchSettlementSheetStatusMutation();

  const handleDelete = useCallback(() => {
    const hasCompeletedSettlementSheet = selectedSettlementSheets.some(
      ({ status }) => status === 'SETTLEMENT_COMPLETED'
    );
    const selectedSettlementSheetIds = selectedSettlementSheets.map(
      ({ id }) => id
    );
    if (hasCompeletedSettlementSheet) {
      void message.error('해당 정산서는 정산이 완료되어 삭제가 불가능합니다.');
      return;
    }
    void modal.confirm({
      icon: <CloseCircleFilled style={{ color: '#ff4d4f' }} />,
      title: '정산서 삭제',
      content: (
        <>
          정산서를 삭제하면, 다시복구할 수 없습니다.
          <br />
          선택하신 정산서를 삭제하시겠습니까?
        </>
      ),
      okText: '네',
      cancelText: '아니오',
      onOk: async () => {
        await deleteSettlementSheetMutation({
          variables: {
            ids: selectedSettlementSheetIds,
          },
          refetchQueries: [
            AdminSettlementSheetsDocument,
            AdminCountSettlementSheetStatusesDocument,
          ],
          onCompleted(data) {
            void message.success(data.adminBulkDeleteSettlementSheet.message);
            clearSelectedRows();
          },
          onError() {
            void message.error(
              '해당 정산서는 정산이 완료되어 삭제가 불가능합니다.'
            );
            clearSelectedRows();
          },
        });
      },
    });
  }, [
    modal,
    message,
    deleteSettlementSheetMutation,
    selectedSettlementSheets,
    clearSelectedRows,
  ]);

  const handleStatusUpdate = useCallback(
    async (status: ESettlementSheetStatus) => {
      const selectedSettlementSheetIds = selectedSettlementSheets.map(
        ({ id }) => id
      );

      const isOk = selectedSettlementSheets.every((sheet) =>
        SETTLEMENT_SHEET_STATUS_UPDATE_TO[status].includes(sheet.status)
      );

      if (!isOk) {
        void message.error(
          `"${SETTLEMENT_SHEET_STATUS_LABEL[status]}"로 정산상태를 변경할 수 없습니다.`
        );
        return;
      }
      await patchSettlementSheetStatusMutation({
        variables: {
          ids: selectedSettlementSheetIds,
          settlementSheetData: { status },
        },
        refetchQueries: [
          AdminSettlementSheetsDocument,
          AdminCountSettlementSheetStatusesDocument,
        ],
        onCompleted() {
          void message.success(
            `"${SETTLEMENT_SHEET_STATUS_LABEL[status]}"${
              status === 'SETTLEMENT_CONFIRMED' ? '으로' : '로'
            } 정산상태가 변경되었습니다.`
          );
          clearSelectedRows();
        },
        onError() {
          void message.error('정산상태 변경을 실패했습니다.');
          clearSelectedRows();
        },
      });
    },
    [
      message,
      patchSettlementSheetStatusMutation,
      selectedSettlementSheets,
      clearSelectedRows,
    ]
  );

  const handleSettlementComplete = useCallback(() => {
    const isOk = selectedSettlementSheets.every(({ status }) =>
      SETTLEMENT_SHEET_STATUS_UPDATE_TO['SETTLEMENT_COMPLETED'].includes(status)
    );
    if (!isOk) {
      void message.error(
        `"${SETTLEMENT_SHEET_STATUS_LABEL['SETTLEMENT_COMPLETED']}"로 정산상태를 변경할 수 없습니다.`
      );
      return;
    }
    void modal.confirm({
      icon: <ExclamationCircleFilled style={{ color: '#FAAD14' }} />,
      title: '정산상태 변경',
      content: (
        <>
          [정산완료]로 변경 시, 정산서를 삭제하거나 상태를 변경할 수 없습니다.
          <br />
          선택하신 정산서를 정산완료로 변경하시겠습니까?
        </>
      ),
      okText: '네',
      cancelText: '아니오',
      onOk: () => handleStatusUpdate('SETTLEMENT_COMPLETED'),
    });
  }, [handleStatusUpdate, modal, message, selectedSettlementSheets]);

  const filter = useSettlementSheetListSearchParams();
  const { excelDownload } = useExcelDownload();
  const [excelDownloadLoading, setExcelDownloadLoading] = useState(false);
  const handleExcelDownload = useCallback(async () => {
    setExcelDownloadLoading(true);
    try {
      await excelDownload({
        fileName: '정산서_내역',
        url: '/admin/settlement-sheets/download',
        config: {
          params: {
            settlementSheetType: filter.settlementSheetType,
            status: filter.status,
            marketId: filter.marketId,
            marketManagerIds: filter.marketManagerIds
              ? filter.marketManagerIds.join(',')
              : undefined,
            vendorManagerIds: filter.vendorManagerIds
              ? filter.vendorManagerIds.join(',')
              : undefined,
            code: filter.code,
            type: filter.period?.type,
            startedAt: filter.period?.startedAt,
            endedAt: filter.period?.endedAt,
          },
        },
      });
    } catch (err) {
      void message.error('엑셀다운로드를 실패했습니다.');
    } finally {
      setExcelDownloadLoading(false);
    }
  }, [excelDownload, filter, message]);

  return (
    <div className='flex justify-between'>
      <div>
        {hasPermission && !!selectedSettlementSheets.length && (
          <Button.Group>
            <Button onClick={handleDelete}>정산서 삭제</Button>
            <Button onClick={() => handleStatusUpdate('SETTLEMENT_CONFIRMED')}>
              정산서 확인
            </Button>
            <Dropdown
              overlayStyle={{ minWidth: 82 }}
              placement='bottomRight'
              menu={{
                items: DROPDOWN_ITEMS,
                onClick: ({ key }) => {
                  const status = key as ESettlementSheetStatus;
                  if (status === 'SETTLEMENT_COMPLETED') {
                    handleSettlementComplete();
                  } else {
                    void handleStatusUpdate(key as ESettlementSheetStatus);
                  }
                },
              }}
            >
              <Button>
                <DownOutlined />
                정산상태 변경
              </Button>
            </Dropdown>
          </Button.Group>
        )}
      </div>
      <Button onClick={handleExcelDownload} loading={excelDownloadLoading}>
        전체 다운로드
      </Button>
    </div>
  );
}

export default TableActionButtons;
