import { Tag } from 'antd';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { ORDER_STATUS_TEXT } from '../const';
import { OrderListSearchParams } from '../types';

export default function SearchTag() {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<OrderListSearchParams>();

  const {
    code,
    groupCode,
    productGroupName,
    vendorName,
    brandName,
    marketName,
    startOrderAmount,
    endOrderAmount,
    orderStatuses,
  } = searchParams;

  return (
    <div>
      {code && (
        <Tag
          className='mt-2'
          closable
          onClose={() => setSearchParams({ code: undefined })}
        >
          {code}
        </Tag>
      )}
      {groupCode && (
        <Tag
          className='mt-2'
          closable
          onClose={() => setSearchParams({ groupCode: undefined })}
        >
          {groupCode}
        </Tag>
      )}
      {productGroupName && (
        <Tag
          className='mt-2'
          closable
          onClose={() => setSearchParams({ productGroupName: undefined })}
        >
          {productGroupName}
        </Tag>
      )}
      {vendorName && (
        <Tag
          className='mt-2'
          closable
          onClose={() => setSearchParams({ vendorName: undefined })}
        >
          {vendorName}
        </Tag>
      )}
      {brandName && (
        <Tag
          className='mt-2'
          closable
          onClose={() => setSearchParams({ brandName: undefined })}
        >
          {brandName}
        </Tag>
      )}
      {marketName && (
        <Tag
          className='mt-2'
          closable
          onClose={() => setSearchParams({ marketName: undefined })}
        >
          {marketName}
        </Tag>
      )}
      {startOrderAmount && !endOrderAmount && (
        <Tag
          className='mt-2'
          closable
          onClose={() => setSearchParams({ startOrderAmount: undefined })}
        >
          {`${startOrderAmount} ~`}
        </Tag>
      )}
      {endOrderAmount && !startOrderAmount && (
        <Tag
          className='mt-2'
          closable
          onClose={() => setSearchParams({ endOrderAmount: undefined })}
        >
          {`~ ${endOrderAmount}`}
        </Tag>
      )}
      {startOrderAmount && endOrderAmount && (
        <Tag
          className='mt-2'
          closable
          onClose={() =>
            setSearchParams({
              startOrderAmount: undefined,
              endOrderAmount: undefined,
            })
          }
        >
          {`${startOrderAmount} ~ ${endOrderAmount}`}
        </Tag>
      )}
      {orderStatuses &&
        orderStatuses.map((status) => (
          <Tag
            key={status}
            className='mt-2'
            closable
            onClose={() =>
              setSearchParams({
                orderStatuses: orderStatuses?.filter((item) => item !== status),
              })
            }
          >
            {ORDER_STATUS_TEXT[status]}
          </Tag>
        ))}
    </div>
  );
}
