import { groupBy, mapValues } from 'lodash-es';
import { useMemo } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { EOrderClaimStatus, EOrderClaimType } from '../../../schema/types';
import { useAdminCountOrderByLatestClaimStatusesQuery } from '../graphql/claims.generated';
import { ClaimListSearchParams } from '../type';
export const useClaimStatusCount = (activeClaimType: EOrderClaimType) => {
  const { searchParams } = useRouteSearchParams<ClaimListSearchParams>();

  const {
    startedAt,
    endedAt,
    searchKeyword,
    periodType,

    brandName,
    productName,
    vendorName,
    marketName,

    claimAssigneeOperatorIds,

    salesChannelType,
  } = searchParams;

  const { data, loading } = useAdminCountOrderByLatestClaimStatusesQuery({
    variables: {
      orderFilter: {
        claimType: activeClaimType,
        searchKeyword,
        salesChannelTypes:
          salesChannelType === 'PB_SHOP'
            ? ['PB_SHOP', 'PB_SHOP_IMWEB', 'PB_SHOP_SMART_STORE']
            : salesChannelType
            ? [salesChannelType]
            : undefined,

        brandName,
        productName,
        vendorName,
        marketName,
        orderTypes: ['PRODUCT'],

        claimAssigneeOperatorIds: claimAssigneeOperatorIds
          ?.filter((id) => !isNaN(Number(id)))
          .map((id) => Number(id)),
        period: periodType
          ? {
              type: periodType,
              startedAt,
              endedAt,
            }
          : undefined,
      },
    },
  });

  const statuses = useMemo<{ [key in EOrderClaimStatus]?: number }>(() => {
    return mapValues(
      groupBy(data?.adminCountOrderByLatestClaimStatuses || [], 'status'),
      (v) => v?.[0]?.count || 0
    );
  }, [data]);

  return { statuses, loading };
};
