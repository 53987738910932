import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { useState } from 'react';

import { SortableItem } from './ContentsSortableItem';
import { SortableItemContents } from './ContentsSortableItemContents';

type ContentsSortableProps = {
  onDragEnd: (urls: string[]) => void;
  items: string[];
  disabled?: boolean;
};

export default function ContentsSortable({
  items,
  onDragEnd,
  disabled,
}: ContentsSortableProps) {
  const [activeUrl, setActiveUrl] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = items.findIndex((url) => url === active.id);
      const overIndex = items.findIndex((url) => url === over?.id);
      const newUrls = arrayMove(items, activeIndex, overIndex);

      onDragEnd(newUrls);
    }

    setActiveUrl(null);
  };

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;

    setActiveUrl(active.id.toString());
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={items.map((url) => url)}
        strategy={rectSortingStrategy}
      >
        {items.map((url) => (
          <SortableItem
            id={url}
            url={url}
            key={url}
            dragging={activeUrl === url}
            disabled={disabled}
          />
        ))}
      </SortableContext>
      <DragOverlay>
        <SortableItemContents sortableItem={false} url={activeUrl ?? ''} />
      </DragOverlay>
    </DndContext>
  );
}
