import { Select, Tag } from 'antd';

import { EProductGroupStatus } from '../../../../schema/types';

type OptionType = {
  label: string;
  value: EProductGroupStatus;
  color?: string;
};

type ProductCardStatusSelectType = {
  options: OptionType[];
  value: EProductGroupStatus;
  onChange?: (status: EProductGroupStatus) => void;
  editable?: boolean;
};

export default function ProductCardStatusSelect({
  options,
  value,
  onChange,
  editable,
}: ProductCardStatusSelectType) {
  return (
    <Select
      className='[&_.ant-select-selector]:p-0'
      size='small'
      style={{ width: 160 }}
      suffixIcon={null}
      defaultValue={value}
      bordered={false}
      onChange={onChange}
      disabled={!editable}
    >
      {options.map((option) => (
        <Select.Option
          value={option.value}
          label={option.label}
          key={option.value}
        >
          <Tag color={option.color} className='h-[20px] font-normal'>
            {option.label}
          </Tag>
        </Select.Option>
      ))}
    </Select>
  );
}
