import { useParams } from 'react-router-dom';

import { useAdminPromotionReleaseQuery } from '../graphql/promotionRelease.generated';

function useReleaseDetail() {
  const params = useParams();
  const releaseId = Number(params.releaseId);
  const { data, loading } = useAdminPromotionReleaseQuery({
    variables: { adminPromotionReleaseId: releaseId },
  });
  const isCompleted =
    data?.adminPromotionRelease.releaseStatus === 'RELEASE_COMPLETED';

  return {
    releaseId,
    data,
    loading,
    isCompleted,
  };
}

export default useReleaseDetail;
