import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, App, Button, Typography } from 'antd';
import { omit } from 'lodash-es';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Modal from '../../../common/components/Modal';
import { useFormError } from '../../../common/hooks/useFormError';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { deleteCacheFields } from '../../../common/utils/apollo';
import { EOrderClaimType } from '../../../schema/types';
import {
  CREATE_CLAIM_DESCRIPTIONS,
  CREATE_CLAIM_TITLES,
  CREATE_CLAIM_TARGET_STATUS,
  CREATE_CLAIM_SUCCESS_MESSAGES,
  INITIAL_ORDER_CLAIM_STATUS,
  shouldDeleteQueriesAfterClaimUpdate,
} from '../const';
import {
  AdminOrderGroupQuery,
  useAdminBulkCreateOrderClaimMutation,
} from '../graphql/createClaim.generated';
import { getClaimConfirmMessage, getMaxClaimQuantity } from '../helper/create';
import { getRejectReasonChangeClaim } from '../helper/orderClaim';
import { CreateClaimFormValues } from '../type';
import { createClaimSchema } from '../validator';

import ClaimOrderSummary from './ClaimOrderSummary';
import ClaimTargetOrders from './ClaimTargetOrders';
import ExchangeForm from './ExchangeForm';
import PartialClaimForm from './PartialClaimForm';
import PartialClaimsHistory from './PartialClaimsHistory';
import ReturnForm from './ReturnForm';

type ReturnReceiptModalProps = {
  type: EOrderClaimType;
  onCancel: () => void;
  orderGroup: AdminOrderGroupQuery['adminOrderGroup'];
  order?: AdminOrderGroupQuery['adminOrderGroup']['orders']['0'];
};

export default function CreateClaimModal({
  type,
  orderGroup,
  order,
  onCancel,
}: ReturnReceiptModalProps) {
  const { hasPermission } = usePermissions(['ORDER_EDIT']);

  const { modal, message } = App.useApp();

  const [mutate, { data }] = useAdminBulkCreateOrderClaimMutation({
    onCompleted(data) {
      const failureOrderIds = data.adminBulkCreateOrderClaim.failureOrderIds;
      const successOrderIds = data.adminBulkCreateOrderClaim.successOrderIds;

      if (failureOrderIds.length) {
        message.warning(
          `${successOrderIds.length}건 성공/${failureOrderIds.length}건 실패`
        );
        return;
      }

      message.success(CREATE_CLAIM_SUCCESS_MESSAGES[type]);
      onCancel();
    },
    onError(error) {
      message.error(error.message || '클레임을 생성하지 못했습니다.');
    },
    update(cache) {
      deleteCacheFields(cache, shouldDeleteQueriesAfterClaimUpdate);
    },
  });

  const maxClaimQuantity = order ? getMaxClaimQuantity(order, type) : 0;

  const methods = useForm<CreateClaimFormValues>({
    defaultValues: {
      reason: '',
      reasonType: type === 'CANCEL' ? 'NULL' : 'ORDER_MISTAKE',
      status: INITIAL_ORDER_CLAIM_STATUS[type],
      target: 'BUYER',
      claimType: type,
      isWiredOwnDeliveryFault: false,
      orders: order
        ? [
            {
              orderId: order.id,
              claimQuantity: maxClaimQuantity,
            },
          ]
        : [],
    },
    mode: 'onChange',
    resolver: yupResolver<CreateClaimFormValues>(createClaimSchema),
  });
  const { handleSubmit, setValue } = methods;

  const { onInvalid } = useFormError();

  const createOrderClaim = useCallback(
    (orderClaim: CreateClaimFormValues) => {
      try {
        const validatedOrders = orderClaim.orders.map(
          ({ orderId, claimQuantity }, i) => {
            const order = orderGroup.orders.find(
              (order) => order.id === orderId
            );

            const rejectReason = order
              ? getRejectReasonChangeClaim(order, {
                  type,
                  status: CREATE_CLAIM_TARGET_STATUS[type],
                })
              : '';

            if (rejectReason) {
              throw new Error(rejectReason);
            }

            if (claimQuantity > maxClaimQuantity) {
              setValue(`orders.${i}.claimQuantity`, maxClaimQuantity);
              if (type === 'PARTIAL_RETURN') {
                throw new Error('반품수량이 잔여주문수량을 초과하였습니다.');
              }

              if (type === 'PARTIAL_EXCHANGE') {
                throw new Error('교환수량이 잔여주문수량을 초과하였습니다.');
              }

              throw new Error('클레임수량이 잔여주문수량을 초과하였습니다.');
            }

            return {
              orderId,
              claimQuantity,
            };
          }
        );

        const orderClaims = validatedOrders.map(
          ({ orderId, claimQuantity }) => ({
            ...omit(orderClaim, 'orders'),
            orderId,
            claimQuantity,
            status: CREATE_CLAIM_TARGET_STATUS[type],
          })
        );

        void mutate({
          variables: { orderClaimData: { orderClaims } },
        });
      } catch (e: any) {
        message.error(e?.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderGroup.orders, mutate, type]
  );

  const onSubmit = (orderClaim: CreateClaimFormValues) => {
    const messages = getClaimConfirmMessage(
      type,
      CREATE_CLAIM_TARGET_STATUS[type],
      order
    );

    modal.warning({
      title: messages.title,
      content: messages.message,
      className: 'whitespace-pre-line',
      centered: true,
      okText: '네',
      cancelText: '아니오',
      okCancel: true,
      onOk() {
        void createOrderClaim(orderClaim);
      },
    });
  };

  const orders = orderGroup?.orders || [];
  const title = CREATE_CLAIM_TITLES[type];
  const description = CREATE_CLAIM_DESCRIPTIONS[type];
  const failureOrderCods =
    data?.adminBulkCreateOrderClaim.failureOrderIds.map((id) => {
      return orders.find((order) => order.id === id)?.code || '';
    }) || [];

  const withProductSelect =
    type === 'RETURN' || type === 'CANCEL' || type === 'EXCHANGE';
  const withPartialClaimHistory =
    type === 'PARTIAL_CANCEL' ||
    type === 'PARTIAL_EXCHANGE' ||
    type === 'PARTIAL_RETURN';

  return (
    <Modal
      width={1000}
      title={title}
      onCancel={onCancel}
      footer={null}
      // 모달 위에 뜨는 message, alert을 배치하기 위한 z-index 설정
      wrapProps={{
        style: { zIndex: 1000 },
      }}
      styles={{
        mask: { zIndex: 1000 },
      }}
    >
      <div className={'pt-4'}>
        <div className={'w-full bg-[#E6F4FF] px-4 py-3'}>
          <Typography.Title
            level={5}
            className={'m-0'}
          >{`와이어드 품목주문코드: ${order?.code || ''}`}</Typography.Title>
        </div>

        {description && (
          <Alert
            showIcon
            type={'warning'}
            className={'mt-4 whitespace-pre-wrap'}
            description={description}
          />
        )}

        <div className={'mt-6 grid gap-12'}>
          <ClaimOrderSummary
            orderGroup={orderGroup}
            order={order}
            showOrder={withPartialClaimHistory}
          />
        </div>
        <div>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
              {withProductSelect && order?.id && (
                <div className={'mt-12'}>
                  {failureOrderCods?.length > 0 && (
                    <Alert
                      showIcon
                      type={'error'}
                      className={'mb-4 whitespace-pre-line'}
                      description={`${
                        failureOrderCods.length
                      }건 실패\n실패 주문 코드: (${failureOrderCods.join(
                        ', '
                      )})`}
                    />
                  )}

                  <ClaimTargetOrders
                    title={title}
                    orders={orders}
                    orderId={order?.id}
                    orderGroupCode={orderGroup.code}
                    claimType={type}
                    onChange={(v) =>
                      setValue('orders', v, {
                        shouldTouch: true,
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  />
                </div>
              )}

              {withPartialClaimHistory && order && (
                <>
                  <div className={'mt-12'}>
                    <PartialClaimsHistory order={order} />
                  </div>
                  <div className={'mt-12 grid gap-12'}>
                    <PartialClaimForm
                      type={type}
                      onCancel={onCancel}
                      submittable
                      maxClaimQuantity={maxClaimQuantity}
                    />
                  </div>
                </>
              )}

              {type === 'RETURN' && (
                <div className={'mt-12'}>
                  <ReturnForm />
                </div>
              )}
              {type === 'EXCHANGE' && (
                <div className={'mt-12'}>
                  <ExchangeForm />
                </div>
              )}

              {(type === 'EXCHANGE' || type === 'RETURN') && (
                <div className={'mt-4 flex justify-end gap-2'}>
                  <Button onClick={onCancel}>취소</Button>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    disabled={!hasPermission}
                  >
                    {title}
                  </Button>
                </div>
              )}
            </form>
          </FormProvider>
        </div>

        {type === 'CANCEL' && (
          <div className={'mt-4 flex justify-end gap-2'}>
            <Button onClick={onCancel}>취소</Button>
            <Button
              type={'primary'}
              htmlType={'submit'}
              onClick={handleSubmit(onSubmit, onInvalid)}
            >
              취소처리
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}
