import {
  LeftOutlined,
  RightOutlined,
  ImportOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, MenuProps, Modal } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer-continued';

import { EHistoryUiType } from '../../../schema/types';
import { useHistoriesQuery } from '../../graphql/history.generated';

import { managerLabel, sellerLabel } from './temp';
import { HistoryMapper } from './utils/HistoryMapper';

const { Content, Sider } = Layout;

type HistoryModalProps = {
  uiType: EHistoryUiType;
  id: number;
  onClose: () => void;
  open: boolean;
};

type ArrowMoveType = 'PREV' | 'NEXT';

const LABELS: Record<EHistoryUiType, Record<string, string>> = {
  SELLER: sellerLabel,
  VENDOR: {},
  BRAND: {},
  MANAGER: managerLabel,
  MARKET: {},
  PRODUCT: {},
  PROMOTION: {},
};

export default function HistoryModal({
  uiType,
  id,
  onClose,
  open,
}: HistoryModalProps) {
  const mapper = useMemo(() => {
    return new HistoryMapper(LABELS[uiType]);
  }, [uiType]);

  const { data } = useHistoriesQuery({
    variables: {
      historiesInput: {
        originId: id,
        uiType,
      },
    },
  });

  const histories = useMemo(() => {
    return (data?.adminHistories ?? []).map(
      ({ id, snapShot, createdAt, manager }) => {
        return {
          id,
          key: String(id),
          label: `${manager.name} ${dayjs(createdAt).format(
            'YYYY.MM.DD HH:mm:ss'
          )}`,
          snapShot,
        };
      }
    );
  }, [data]);

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  useEffect(() => {
    const initialIndex = histories.length > 0 ? 0 : -1;
    setCurrentIndex(initialIndex);
  }, [histories]);

  const [currentHistory, prevHistory] = useMemo<
    ((typeof histories)[number] | undefined)[]
  >(() => {
    if (currentIndex < 0) {
      return [];
    }

    return histories.slice(currentIndex, currentIndex + 2);
  }, [currentIndex, histories]);

  const currentSnapshot = mapper.getHistory(currentHistory?.snapShot ?? '');
  const prevSnapshot = mapper.getHistory(prevHistory?.snapShot ?? '');

  // 좌우버튼
  const listRef = useRef<(HTMLDivElement | null)[]>([]);
  const moveHistory = (type: ArrowMoveType) => {
    const toIndex = currentIndex + (type === 'NEXT' ? -1 : 1);
    setCurrentIndex(toIndex);
    scrollIntoList(toIndex);
  };
  const toPrev = () => moveHistory('PREV');
  const toNext = () => moveHistory('NEXT');
  const prevButtonDisabled = currentIndex >= histories.length - 1;
  const nextButtonDisabled = currentIndex <= 0;
  const scrollIntoList = (index: number) => {
    const param: ScrollIntoViewOptions = {
      block: 'nearest',
    };
    listRef.current[index]?.scrollIntoView(param);
  };

  // 사이드메뉴
  const menuWrapperRef = useRef<HTMLDivElement>(null);
  const menuItems = useMemo(() => {
    return histories.map(({ key, label }, index) => {
      return {
        key,
        label: (
          <div
            className='overflow-hidden text-ellipsis text-center'
            ref={(el) => (listRef.current[index] = el)}
          >
            {label}
          </div>
        ),
      };
    });
  }, [histories]);
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const selectedMenuKeys = currentHistory ? [currentHistory.key] : undefined;
  const selectMenu: MenuProps['onSelect'] = (item) => {
    const index = histories.findIndex((history) => history.key === item.key);
    setCurrentIndex(index);
  };

  // index 초기화, 메뉴 스크롤 초기화
  const initHistoryModal = () => {
    setCurrentIndex(0);
    setCollapsed(true);
    menuWrapperRef.current?.scrollTo(0, 0);
  };

  const handleClose = () => {
    onClose();
    initHistoryModal();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      width={'100%'}
      footer={null}
      title='변경 히스토리'
    >
      <Layout className='bg-white'>
        <Layout hasSider className='p-0'>
          <Content className='flex flex-1 items-center gap-4 p-4'>
            <HistoryButton
              icon={<LeftOutlined className='text-sm' />}
              onClick={toPrev}
              disabled={prevButtonDisabled}
            />
            <div className='max-h-[calc(100vh-272px)] flex-1 overflow-auto'>
              <ReactDiffViewer
                showDiffOnly={false}
                extraLinesSurroundingDiff={0}
                oldValue={prevSnapshot}
                newValue={currentSnapshot}
                splitView={true}
                leftTitle={prevHistory?.label}
                rightTitle={currentHistory?.label}
                disableWordDiff={true}
              />
            </div>
            <HistoryButton
              icon={<RightOutlined className='text-sm' />}
              onClick={toNext}
              disabled={nextButtonDisabled}
            />
          </Content>
          <Sider
            width={260}
            collapsed={collapsed}
            onCollapse={setCollapsed}
            collapsedWidth={56}
            theme='light'
            className='max-h-[calc(100vh-240px)] overflow-auto px-2'
          >
            <div className='flex h-full flex-col'>
              <div className='mb-2 px-1 '>
                <Button
                  className='flex items-center justify-center'
                  onClick={toggleCollapsed}
                  icon={collapsed ? <ImportOutlined /> : <ExportOutlined />}
                />
              </div>
              <div
                ref={menuWrapperRef}
                className='scrollbar-hide overflow-y-auto'
              >
                <Menu
                  mode='inline'
                  className='flex flex-col gap-1 border-r-0 [&>li]:!px-1'
                  selectedKeys={selectedMenuKeys}
                  items={menuItems}
                  onSelect={selectMenu}
                />
              </div>
            </div>
          </Sider>
        </Layout>
      </Layout>
    </Modal>
  );
}

type HistoryButtonProps = {
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

function HistoryButton({ icon, onClick, disabled }: HistoryButtonProps) {
  return (
    <Button
      disabled={disabled}
      icon={icon}
      shape='round'
      className='h-9 w-9 rounded-full p-0'
      onClick={onClick}
    />
  );
}
