import { get } from 'lodash-es';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from './FieldWithLabel';
import ManagerSelect, { ManagerSelectProps } from './ManagerSelect';
import { FieldProps } from './types';

export type SelectFieldProps<TFieldValues extends FieldValues> =
  ManagerSelectProps &
    FieldProps & {
      control: Control<TFieldValues>;
      name: Path<TFieldValues>;
    };

function ManagerSelectField<TFieldValues extends FieldValues>({
  name,
  label,
  required,
  control,
  hideError,
  hideErrorMessage,
  ...props
}: SelectFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const error = get(formState.errors, name);
        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;
        const status = !hideError && errorMessage ? 'error' : '';

        return (
          <FieldWithLabel label={label} required={required}>
            <ManagerSelect
              className={'w-full'}
              filterOption={(input, option) => {
                if (!option?.label) {
                  return false;
                }

                if (typeof option.label === 'string') {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }

                return option.label === input;
              }}
              status={status}
              {...field}
              {...props}
            />

            {!hideErrorMessage && !hideError && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}

export default ManagerSelectField;
