import { useCallback, useEffect, useMemo, useState } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import {
  SettlementSheetListTableDataType,
  SheetListSearchParams,
} from '../types';

function useSettlementSheetSelectedRows() {
  const { searchParams } = useRouteSearchParams<SheetListSearchParams>();
  const [selectedRows, setSelectedRows] = useState<
    SettlementSheetListTableDataType[]
  >([]);
  const selectedRowKeys = useMemo(
    () => selectedRows.map(({ key }) => key),
    [selectedRows]
  ) as number[];

  useEffect(() => {
    setSelectedRows([]);
  }, [searchParams]);

  const handleSelectedRows = useCallback(
    (_: React.Key[], selectedRows: SettlementSheetListTableDataType[]) =>
      setSelectedRows(selectedRows),
    []
  );

  const clearSelectedRows = useCallback(() => setSelectedRows([]), []);

  return {
    selectedRows,
    selectedRowKeys,
    handleSelectedRows,
    clearSelectedRows,
  };
}

export default useSettlementSheetSelectedRows;
