import { useFormContext } from 'react-hook-form';

import { ProductGroupFormValues } from '../../types';

import CategorySelectField from './CategorySelectField';
import UpperCategorySelectField from './UpperCategorySelectField';
import UppermostCategorySelectField from './UppermostCategorySelectField';

type CategoriesSelectField = {
  disabled?: boolean;
  hideErrorMessage?: boolean;
};

export default function CategoriesSelectField({
  disabled,
  hideErrorMessage,
}: CategoriesSelectField) {
  const { control, watch, setValue } = useFormContext<ProductGroupFormValues>();

  const uppermostCategoryId = watch('uppermostCategoryId');
  const upperCategoryId = watch('upperCategoryId');

  return (
    <div className='flex gap-2 align-baseline'>
      <div className='w-[240px]'>
        <UppermostCategorySelectField
          className='w-[240px]'
          placeholder={'카테고리 (대)'}
          control={control}
          name='uppermostCategoryId'
          onChange={() => {
            setValue('upperCategoryId', null);
            setValue('categoryId', null);
          }}
          disabled={disabled}
          allowClear
          hideErrorMessage={hideErrorMessage}
        />
      </div>
      <div className='w-[240px]'>
        <UpperCategorySelectField
          className='w-[240px]'
          control={control}
          name='upperCategoryId'
          placeholder={'카테고리 (중)'}
          uppermostCategoryId={uppermostCategoryId}
          onChange={() => {
            setValue('categoryId', null);
          }}
          disabled={disabled}
          allowClear
          hideErrorMessage={hideErrorMessage}
        />
      </div>
      <div className='w-[240px]'>
        <CategorySelectField
          className='w-[240px]'
          control={control}
          name='categoryId'
          placeholder={'카테고리 (소)'}
          upperCategoryId={upperCategoryId}
          disabled={disabled}
          allowClear
          hideErrorMessage={hideErrorMessage}
        />
      </div>
    </div>
  );
}
