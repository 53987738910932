import { Tabs, Typography } from 'antd';
import { useMemo } from 'react';

import PageLayout from '../common/components/PageLayout';
import useRouteSearchParams from '../common/hooks/useRouteSearchParams';
import MarketSettlementSheetTable from '../domain/settlement/components/settlement-sheet-list/MarketSettlementSheetTable';
import SearchForm from '../domain/settlement/components/settlement-sheet-list/SearchForm';
import TableActionButtons from '../domain/settlement/components/settlement-sheet-list/TableActionButtons';
import VendorSettlementSheetTable from '../domain/settlement/components/settlement-sheet-list/VendorSettlementSheetTable';
import Summary, { SummaryProps } from '../domain/settlement/components/Summary';
import { SETTLEMENT_SHEET_TAB_ITEMS } from '../domain/settlement/const';
import { useAdminCountSettlementSheetStatusesQuery } from '../domain/settlement/graphql/settlementSheet.generated';
import useSettlementSheetSelectedRows from '../domain/settlement/hooks/useSettlementSheetSelectedRows';
import { SheetListSearchParams } from '../domain/settlement/types';

function SettlementSheetList() {
  const { searchParams, setSearchParams, replaceSearchParams } =
    useRouteSearchParams<SheetListSearchParams>();

  const { data } = useAdminCountSettlementSheetStatusesQuery({
    variables: {
      filter: {
        settlementSheetTypes: searchParams.type
          ? [searchParams.type]
          : ['MARKET'],
      },
    },
  });

  const summaryItems: SummaryProps['items'] = useMemo(
    () => [
      {
        key: 'SETTLEMENT_WAITING',
        label: '정산대기',
        count:
          data?.adminCountSettlementSheetStatuses.find(
            ({ status }) => status === 'SETTLEMENT_WAITING'
          )?.count || 0,
        onClick: () =>
          replaceSearchParams({
            type: searchParams.type,
            summaryStatus: 'SETTLEMENT_WAITING',
          }),
      },
      {
        key: 'SETTLEMENT_CONFIRMED',
        label: '정산서 확인',
        count:
          data?.adminCountSettlementSheetStatuses.find(
            ({ status }) => status === 'SETTLEMENT_CONFIRMED'
          )?.count || 0,
        onClick: () =>
          replaceSearchParams({
            type: searchParams.type,
            summaryStatus: 'SETTLEMENT_CONFIRMED',
          }),
      },
      {
        key: 'SETTLEMENT_COMPLETED',
        label: '정산완료',
        count:
          data?.adminCountSettlementSheetStatuses.find(
            ({ status }) => status === 'SETTLEMENT_COMPLETED'
          )?.count || 0,
        onClick: () =>
          replaceSearchParams({
            type: searchParams.type,
            summaryStatus: 'SETTLEMENT_COMPLETED',
          }),
      },
      {
        key: 'SETTLEMENT_PENDING',
        label: '정산보류',
        count:
          data?.adminCountSettlementSheetStatuses.find(
            ({ status }) => status === 'SETTLEMENT_PENDING'
          )?.count || 0,
        onClick: () =>
          replaceSearchParams({
            type: searchParams.type,
            summaryStatus: 'SETTLEMENT_PENDING',
          }),
      },
      {
        key: 'SETTLEMENT_UNCOLLECTED',
        label: '정산미수',
        count:
          data?.adminCountSettlementSheetStatuses.find(
            ({ status }) => status === 'SETTLEMENT_UNCOLLECTED'
          )?.count || 0,
        onClick: () =>
          replaceSearchParams({
            type: searchParams.type,
            summaryStatus: 'SETTLEMENT_UNCOLLECTED',
          }),
      },
    ],
    [data, searchParams, replaceSearchParams]
  );

  const {
    selectedRowKeys,
    selectedRows,
    handleSelectedRows,
    clearSelectedRows,
  } = useSettlementSheetSelectedRows();

  return (
    <PageLayout
      summary={
        <>
          <Tabs
            items={SETTLEMENT_SHEET_TAB_ITEMS}
            activeKey={searchParams.type}
            onChange={(activeKey) =>
              setSearchParams({
                type: activeKey as SheetListSearchParams['type'],
                currentPage: undefined,
                marketId: undefined,
                vendorId: undefined,
              })
            }
          />
          <Summary
            label='정산현황'
            activeKey={searchParams.summaryStatus}
            items={summaryItems}
          />
        </>
      }
      search={<SearchForm />}
    >
      <div className='bg-white px-6'>
        <div className='sticky top-tableTitlePositionTop z-10 flex flex-col gap-4 bg-white pb-4 pt-6'>
          <Typography.Title level={4} className={'sticky mb-0 bg-white'}>
            정산서 리스트
          </Typography.Title>
          <TableActionButtons
            selectedSettlementSheets={selectedRows}
            clearSelectedRows={clearSelectedRows}
          />
        </div>
        {(searchParams.type === undefined ||
          searchParams.type === 'MARKET') && (
          <MarketSettlementSheetTable
            selectedRowKeys={selectedRowKeys}
            handleSelectedRows={handleSelectedRows}
          />
        )}
        {searchParams.type === 'VENDOR' && (
          <VendorSettlementSheetTable
            selectedRowKeys={selectedRowKeys}
            handleSelectedRows={handleSelectedRows}
          />
        )}
      </div>
    </PageLayout>
  );
}

export default SettlementSheetList;
