import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import MarketSelect from '../../../../common/components/Fields/MarketSelect';
import { useAdminMarketInSettlementListLazyQuery } from '../../graphql/market.generated';

export type SelectFieldProps = SelectProps<number> & {
  initialLoad?: boolean;
};

const SettlementMarketSelect = forwardRef<BaseSelectRef, SelectFieldProps>(
  function ({ initialLoad, ...props }, ref) {
    const [initialOption, setInitialOption] = useState<
      null | undefined | { value: number; label: string }
    >(null);

    const [fetch] = useAdminMarketInSettlementListLazyQuery();
    const fetchMarket = useCallback(async () => {
      if (!props.value) {
        setInitialOption(undefined);
        return;
      }

      const { data } = await fetch({
        variables: {
          marketId: props.value,
        },
      });

      if (data?.adminMarket) {
        setInitialOption({
          value: data.adminMarket.id,
          label: data.adminMarket.name,
        });
      }
    }, [fetch, props.value]);

    useEffect(() => {
      fetchMarket();
    }, []);

    if (initialOption === null) return <Select className={props.className} />;

    return (
      <MarketSelect
        ref={ref}
        initialLoad={initialLoad}
        className={'w-full'}
        initialOption={initialOption}
        {...props}
      />
    );
  }
);

export default SettlementMarketSelect;
