import { debounce } from 'lodash-es';
import { useState, ChangeEvent, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { formSetValueOption } from '../../../common/utils/const';
import {
  CreateOrderGroupDepositGraphqlType,
  EOrderGroupDepositSettlementTarget,
} from '../../../schema/types';
import { useVendorAndSellerNameInCreateDepositQuery } from '../graphql/deposit.generated';
import { useAdminOrderGroupByCodeLazyQuery } from '../graphql/order.generated';
import { depositResolver } from '../validator/createDepositValidator';

function useCreateDeposit() {
  const methods = useForm<CreateOrderGroupDepositGraphqlType>({
    defaultValues: {
      comment: '',
    },
    resolver: depositResolver,
  });
  const { setValue, getValues, resetField } = methods;

  const [orderGroupCode, setOrderGroupCode] = useState('');
  const [
    fetchOrderGroup,
    {
      data: orderGroupData,
      loading: orderGroupLoading,
      error: orderGroupError,
    },
  ] = useAdminOrderGroupByCodeLazyQuery({
    onCompleted({ adminOrderGroupByCode }) {
      setValue('groupId', adminOrderGroupByCode.id, formSetValueOption);
      setValue(
        'depositorName',
        adminOrderGroupByCode.ordererName || '',
        formSetValueOption
      );
    },
    onError() {
      resetField('depositAmount');
      resetField('depositorName');
      resetField('settlementTarget');
      resetField('comment');
      resetField('settlementTargetName');
    },
  });

  const { data: vendorAndSellerData } =
    useVendorAndSellerNameInCreateDepositQuery({
      variables: {
        adminProductGroupId:
          orderGroupData?.adminOrderGroupByCode.orders[0].productGroupId || 0,
        marketId: orderGroupData?.adminOrderGroupByCode.marketId || 0,
      },
      skip: !orderGroupData?.adminOrderGroupByCode,
      onCompleted({ adminProductGroup }) {
        const chargeDetail = getValues('chargeDetail');
        if (
          chargeDetail === 'EXCHANGE_DELIVERY_FEE' &&
          adminProductGroup.exchangeFee
        ) {
          setValue(
            'depositAmount',
            adminProductGroup.exchangeFee,
            formSetValueOption
          );
        }
        if (
          chargeDetail === 'RETURN_DELIVERY_FEE' &&
          adminProductGroup.returnFee
        ) {
          setValue(
            'depositAmount',
            adminProductGroup.returnFee,
            formSetValueOption
          );
        }
      },
    });
  const vendorName = vendorAndSellerData?.adminProductGroup.vendor.name;
  const sellerName = vendorAndSellerData?.adminMarket.seller?.name;

  const debounceQuery = useCallback(debounce(fetchOrderGroup, 300), []);
  const handleOrderGroupCode = useCallback(
    async (ev: ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;
      setOrderGroupCode(value);
      if (value.trim()) {
        await debounceQuery({ variables: { code: value } });
      }
    },
    [debounceQuery]
  );

  const handleSettlementTarget = useCallback(
    (value: EOrderGroupDepositSettlementTarget) => {
      setValue('settlementTarget', value);
      if (value === 'SELLER' && sellerName) {
        setValue('settlementTargetName', sellerName);
      }
      if (value === 'VENDOR' && vendorName) {
        setValue('settlementTargetName', vendorName);
      }
    },
    [vendorName, sellerName, setValue]
  );

  return {
    methods,
    handleOrderGroupCode,
    handleSettlementTarget,
    orderGroupCode,
    orderGroupData,
    orderGroupLoading,
    orderGroupError,
  };
}

export default useCreateDeposit;
