import { useMemo, useState } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { SETTLEMENT_PAYMENT_METHOD_TEXT } from '../const';
import { useAdminSettlementsQuery } from '../graphql/settlements.generated';
import { SettlementListSearchParams } from '../types';

import useSettlementListSearchParams from './useSettlementListSearchParams';

function useSettlementList() {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<SettlementListSearchParams>();

  const currentPage = Number(searchParams.currentPage) - 1 || 0;
  const pageSize = Number(searchParams.pageSize) || 200;

  const { filter } = useSettlementListSearchParams();

  const { data } = useAdminSettlementsQuery({
    variables: {
      filter,
      pagination: {
        skip: currentPage * pageSize,
        take: pageSize,
      },
    },
  });

  const total = data?.adminSettlements?.totalCount ?? 0;
  const dataSource = data?.adminSettlements?.edges.map(({ node }) => {
    return {
      key: node.id,
      salesChannel: node.salesChannel,
      sellerName: node.sellerName || '-',
      marketId: node.marketId,
      marketName: node.marketName || '-',
      vendorName: node.vendorName || '-',
      vendorId: node.vendorId,
      brandName: node.brandName || '-',
      productGroupName: node.productGroupName || '-',
      sheets: node.sheets?.map((sheet) => {
        return {
          id: sheet.id,
          code: sheet.code,
          settlementSheetType: sheet.settlementSheetType,
        };
      }),
      id: node.id,
      orderGroupCode: node.orderGroupCode || '-',
      orderCode: node.orderCode || '-',
      orderId: node.orderId,
      promotionReleaseCode: node.promotionReleaseCode || '-',
      receiverName: node.receiverName || '-',
      settlementType: node.settlementType,
      settlementDetailType: node.settlementDetailType,
      salesAmount: node.salesAmount,
      soldAt: node.soldAt,
      productName: node.productName || '-',
      productId: node.productId,
      productQuantity: node.productQuantity?.toString() || '-',
      sellerSettlementAmount: node.sellerSettlementAmount,
      vendorSettlementAmount: node.vendorSettlementAmount,
      deliveryBearingAmount: node.deliveryBearingAmount,
      productionAmount: node.productionAmount,
      inHouseMargin: node.inHouseMargin,
      comment: node.comment,
      releasedAt: node.releasedAt,
      sellerSettlementType: node.sellerSettlementType,
      paymentMethod: SETTLEMENT_PAYMENT_METHOD_TEXT[node.paymentMethod],
    };
  });

  const handlePageChange = (page: number, size: number) => {
    if (size !== pageSize) {
      setSearchParams({ currentPage: 1, pageSize: size });
    } else {
      setSearchParams({ currentPage: page });
    }
  };

  const pagination = {
    showSizeChanger: true,
    total,
    showTotal: (total: number) => `총 ${total}건`,
    pageSize,
    onChange: handlePageChange,
    pageSizeOptions: ['10', '20', '50', '100', '200'],
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection = {
    columnWidth: 48,
    fixed: true,
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const selectedRows = useMemo(() => {
    return dataSource?.filter((row) => selectedRowKeys.includes(row.key)) ?? [];
  }, [dataSource, selectedRowKeys]);

  return {
    selectedRows,
    rowSelection,
    dataSource,
    pagination,
  };
}

export default useSettlementList;
