import { Radio, Typography, Input, Descriptions } from 'antd';
import { debounce } from 'lodash-es';
import { ChangeEvent } from 'react';

import ManagerSelect from '../../../common/components/Fields/ManagerSelect';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { SELLER_GRADES_WITH_ALL } from '../const';
import { SellerListSearchParams } from '../types';

type ListSearchFormProps = {
  onSearch: (params: SellerListSearchParams) => void;
};
function ListSearchForm({ onSearch }: ListSearchFormProps) {
  const { searchParams } = useRouteSearchParams<SellerListSearchParams>();

  const grade = searchParams.grade?.map((grade) => Number(grade));
  const managerId = searchParams.managerId?.map((manager) => Number(manager));

  const handleSearch = debounce((ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;

    onSearch({
      search: value.trim(),
      grade: undefined,
      managerId: undefined,
      phoneNumber: undefined,
      currentPage: undefined,
    });
  }, 500);

  const handleManager = (value: number[]) => {
    onSearch({
      search: undefined,
      managerId: value.map((id) => id.toString()),
      currentPage: undefined,
    });
  };

  const handleGrade = (value: number) => {
    /**
     * NONE(0)과 NONE이 아닌 등급(1, 2, 3, 4, 5)은 서로 배타적이다.
     */
    if (value === 0) {
      onSearch({ search: undefined, grade: ['0'], currentPage: undefined });
    } else if (value === -1) {
      onSearch({ search: undefined, grade: undefined, currentPage: undefined });
    } else {
      const grades = grade?.map((value) => value.toString());
      const currentValue = value.toString();

      if (grades?.length) {
        if (grades?.includes(currentValue)) {
          onSearch({
            search: undefined,
            grade: grades?.filter((grade) => grade !== currentValue),
            currentPage: undefined,
          });
        } else {
          onSearch({
            search: undefined,
            grade: [...grades, currentValue],
            currentPage: undefined,
          });
        }
      } else {
        onSearch({
          search: undefined,
          grade: [currentValue],
          currentPage: undefined,
        });
      }
    }
  };

  const handlePhoneNumber = debounce((ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    onSearch({ search: undefined, phoneNumber: value, currentPage: undefined });
  }, 500);

  return (
    <div className='flex flex-col gap-4 bg-white p-6'>
      <Typography.Title level={4} className='m-0'>
        검색
      </Typography.Title>
      <Input
        placeholder='셀러명, 케미회원아이디, 실명, 사업자명 입력'
        style={{ width: 540 }}
        defaultValue={searchParams.search}
        onChange={handleSearch}
      />
      {!searchParams.search && (
        <Descriptions
          bordered
          column={1}
          labelStyle={{ width: '200px' }}
          size='middle'
          contentStyle={{ padding: '8px 24px' }}
        >
          <Descriptions.Item label='담당자'>
            <ManagerSelect
              style={{ width: 326 }}
              mode='multiple'
              allowClear
              defaultActiveFirstOption={false}
              suffixIcon={null}
              notFoundContent={null}
              onChange={handleManager}
              value={managerId}
            />
          </Descriptions.Item>
          <Descriptions.Item label='등급'>
            {SELLER_GRADES_WITH_ALL.map(({ label, value }) => (
              <Radio.Button
                key={label}
                checked={grade?.includes(value)}
                value={value}
                onClick={() => handleGrade(value)}
              >
                {label}
              </Radio.Button>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label='휴대폰번호'>
            <Input
              placeholder='숫자만 입력'
              defaultValue={searchParams.phoneNumber}
              onChange={handlePhoneNumber}
              style={{ width: 326 }}
            />
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
}

export default ListSearchForm;
