import { ExclamationCircleOutlined } from '@ant-design/icons';
import { App, Button, Descriptions, Divider, Typography } from 'antd';
import { get, isEmpty } from 'lodash-es';
import { useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import Label from '../../../common/components/Fields/Label';
import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import RadioField from '../../../common/components/Fields/RadioField';
import TextareaField from '../../../common/components/Fields/TextareaField';
import TextField from '../../../common/components/Fields/TextField';
import HistoryModal from '../../../common/components/History/HistoryModal';
import { useFormError } from '../../../common/hooks/useFormError';
import { SELLER_GRADES } from '../const';
import { updateSellerFormResolver } from '../helper/validator';
import { UpdateSellerFormValues } from '../types';

import AddressesField from './AddressesField';
import EmailsField from './EmailsField';
import LinksField from './LinksField';
import MobileField from './MobileField';
import SellerNameField from './SellerNameField';
import SettlementsField from './SettlementsField';

type UpdateSellerFormProps = {
  initialValues: UpdateSellerFormValues;
  onSubmitOk: (data: UpdateSellerFormValues) => void;
  editable: boolean;
};

function UpdateSellerForm({
  initialValues,
  editable,
  onSubmitOk,
}: UpdateSellerFormProps) {
  const { modal } = App.useApp();
  const params = useParams();
  const sellerId = Number(params.sellerId);

  const methods = useForm<UpdateSellerFormValues>({
    defaultValues: initialValues,
    /**
     * input change 시점에 validation 동작
     * default는 submit 시점에 validation 동작
     */
    mode: 'onChange',
    resolver: updateSellerFormResolver,
  });
  const { onInvalid } = useFormError({
    afterInValid: (errors: FieldErrors<UpdateSellerFormValues>) => {
      const checkedPhoneNumberError = get(errors, 'isCheckedPhoneNumber');
      const uniquePhoneNumberError = get(errors, 'isUniquePhoneNumber');

      if (checkedPhoneNumberError || uniquePhoneNumberError) {
        methods.setFocus('phoneNumber');
      }
    },
  });
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  const { handleSubmit, control, formState } = methods;

  const onSubmit = (data: UpdateSellerFormValues) => {
    modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: '셀러 저장',
      content: '수정 사항을 저장하시겠습니까??',
      onOk: () => onSubmitOk(data),
      okText: '네',
      cancelText: '아니오',
      centered: true,
    });
  };

  // formState.isDirty는 touched되는 순간 이후로 원복이 안되어서 fields로 감지함.
  const isDirty = !isEmpty(formState.dirtyFields);

  return (
    <div>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit, onInvalid)}
          className={'grid gap-xl'}
        >
          <Typography.Title level={4} className={'m-0'}>
            셀러 기본정보
          </Typography.Title>
          <Descriptions
            bordered
            column={1}
            labelStyle={{ width: 200 }}
            contentStyle={{ padding: '12px 24px' }}
          >
            <Descriptions.Item label={<Label required>담당자</Label>}>
              <ManagerSelectField
                disabled={!editable}
                className={'w-[310px]'}
                name={'managerId'}
                control={control}
              />
            </Descriptions.Item>
            <Descriptions.Item label={<Label required>셀러명</Label>}>
              <div className={'w-[310px]'}>
                <SellerNameField withoutSearch disabled={!editable} />
              </div>
            </Descriptions.Item>

            <Descriptions.Item label='케미회원아이디'>
              <TextField
                disabled={!editable}
                className={'w-[310px]'}
                control={control}
                name={'kemiUserId'}
                maxLength={30}
              />
            </Descriptions.Item>
            <Descriptions.Item label='등급'>
              <RadioField
                disabled={!editable}
                control={control}
                name={'grade'}
                options={[...SELLER_GRADES, { label: 'None', value: 0 }]}
                optionType='button'
                buttonStyle='outline'
              />
            </Descriptions.Item>
          </Descriptions>

          <Typography.Title level={4} className={'m-0'}>
            셀러 상세정보
          </Typography.Title>
          <div className={'grid w-updateFormWidth gap-xl'}>
            <LinksField disabled={!editable} />
            <EmailsField disabled={!editable} />
            <MobileField disabled={!editable} sellerId={sellerId} />
            <AddressesField disabled={!editable} />
            <FieldWithLabel label={'비고'}>
              <TextareaField
                autoSize
                control={control}
                name={'memo'}
                disabled={!editable}
              />
            </FieldWithLabel>
          </div>
          <Divider orientation='left' className={'m-0'}>
            정산정보
          </Divider>

          <SettlementsField disabled={!editable} />

          <Divider />

          <div className={'flex justify-between'}>
            <Button htmlType={'button'} onClick={() => setIsHistoryOpen(true)}>
              변경 히스토리
            </Button>
            <Button
              type={'primary'}
              htmlType={'submit'}
              disabled={!isDirty || !editable}
            >
              저장
            </Button>
          </div>
        </form>
      </FormProvider>
      <HistoryModal
        uiType={'SELLER'}
        id={sellerId}
        onClose={() => setIsHistoryOpen(false)}
        open={isHistoryOpen}
      />
    </div>
  );
}

export default UpdateSellerForm;
