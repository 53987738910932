import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from './FieldWithLabel';
import { FieldProps } from './types';

type TextareaFieldProps<TFieldValues extends FieldValues> = TextAreaProps &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
  };

function TextareaField<TFieldValues extends FieldValues>({
  name,
  label,
  required,
  control,
  tooltip,
  ...props
}: TextareaFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FieldWithLabel label={label} required={required} tooltip={tooltip}>
          <Input.TextArea {...field} {...props} />
        </FieldWithLabel>
      )}
    />
  );
}

export default TextareaField;
