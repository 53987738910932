import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';

type Status = 'error' | 'warning' | 'success';

type SuffixIconProps = {
  status?: Status | '';
};

function SuffixIcon({ status }: SuffixIconProps) {
  switch (status) {
    case 'error':
      return <CloseCircleFilled style={{ color: '#ff4d4f' }} />;
    case 'warning':
      return <ExclamationCircleFilled style={{ color: '#faad14' }} />;
    case 'success':
      return <CheckCircleFilled style={{ color: '#52C41A' }} />;
    default:
      return null;
  }
}

export default SuffixIcon;
