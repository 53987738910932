import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PromotionSellerOptionsQueryVariables = Types.Exact<{
  sellersInput: Types.SellersGraphqlInputType;
}>;


export type PromotionSellerOptionsQuery = { __typename?: 'Query', adminSellers: { __typename?: 'SellersPayload', sellers: Array<{ __typename?: 'SellerGraphqlType', id: number, name: string, phoneNumber: string, settlementInfos: Array<{ __typename?: 'SellerSettlementInfoGraphqlType', id: number, type: Types.ESellerSettlementType, isDefault: boolean, companyName?: string | null, sellerName?: string | null, bank?: Types.EBankCode | null, accountNumber?: string | null }>, addresses: Array<{ __typename?: 'SellerAddressGraphqlType', id: number, isDefault: boolean, address: string, detailAddress?: string | null, zipCode: string }> }> } };

export type PromotionSellerQueryVariables = Types.Exact<{
  adminSellerId: Types.Scalars['Int']['input'];
}>;


export type PromotionSellerQuery = { __typename?: 'Query', adminSeller: { __typename?: 'SellerGraphqlType', id: number, name: string, phoneNumber: string, settlementInfos: Array<{ __typename?: 'SellerSettlementInfoGraphqlType', id: number, type: Types.ESellerSettlementType, isDefault: boolean, companyName?: string | null, sellerName?: string | null, bank?: Types.EBankCode | null, accountNumber?: string | null }>, addresses: Array<{ __typename?: 'SellerAddressGraphqlType', id: number, isDefault: boolean, address: string, detailAddress?: string | null, zipCode: string }> } };


export const PromotionSellerOptionsDocument = gql`
    query PromotionSellerOptions($sellersInput: SellersGraphqlInputType!) {
  adminSellers(sellersInput: $sellersInput) {
    sellers {
      id
      name
      phoneNumber
      settlementInfos {
        id
        type
        isDefault
        companyName
        sellerName
        bank
        accountNumber
      }
      addresses {
        id
        isDefault
        address
        detailAddress
        zipCode
      }
    }
  }
}
    `;

/**
 * __usePromotionSellerOptionsQuery__
 *
 * To run a query within a React component, call `usePromotionSellerOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionSellerOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionSellerOptionsQuery({
 *   variables: {
 *      sellersInput: // value for 'sellersInput'
 *   },
 * });
 */
export function usePromotionSellerOptionsQuery(baseOptions: Apollo.QueryHookOptions<PromotionSellerOptionsQuery, PromotionSellerOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionSellerOptionsQuery, PromotionSellerOptionsQueryVariables>(PromotionSellerOptionsDocument, options);
      }
export function usePromotionSellerOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionSellerOptionsQuery, PromotionSellerOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionSellerOptionsQuery, PromotionSellerOptionsQueryVariables>(PromotionSellerOptionsDocument, options);
        }
export type PromotionSellerOptionsQueryHookResult = ReturnType<typeof usePromotionSellerOptionsQuery>;
export type PromotionSellerOptionsLazyQueryHookResult = ReturnType<typeof usePromotionSellerOptionsLazyQuery>;
export type PromotionSellerOptionsQueryResult = Apollo.QueryResult<PromotionSellerOptionsQuery, PromotionSellerOptionsQueryVariables>;
export const PromotionSellerDocument = gql`
    query PromotionSeller($adminSellerId: Int!) {
  adminSeller(id: $adminSellerId) {
    id
    name
    phoneNumber
    settlementInfos {
      id
      type
      isDefault
      companyName
      sellerName
      bank
      accountNumber
    }
    addresses {
      id
      isDefault
      address
      detailAddress
      zipCode
    }
  }
}
    `;

/**
 * __usePromotionSellerQuery__
 *
 * To run a query within a React component, call `usePromotionSellerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionSellerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionSellerQuery({
 *   variables: {
 *      adminSellerId: // value for 'adminSellerId'
 *   },
 * });
 */
export function usePromotionSellerQuery(baseOptions: Apollo.QueryHookOptions<PromotionSellerQuery, PromotionSellerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionSellerQuery, PromotionSellerQueryVariables>(PromotionSellerDocument, options);
      }
export function usePromotionSellerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionSellerQuery, PromotionSellerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionSellerQuery, PromotionSellerQueryVariables>(PromotionSellerDocument, options);
        }
export type PromotionSellerQueryHookResult = ReturnType<typeof usePromotionSellerQuery>;
export type PromotionSellerLazyQueryHookResult = ReturnType<typeof usePromotionSellerLazyQuery>;
export type PromotionSellerQueryResult = Apollo.QueryResult<PromotionSellerQuery, PromotionSellerQueryVariables>;