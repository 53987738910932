import { noop } from 'lodash-es';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

export const RouteScrollContext = createContext<{
  preventScrollTop: () => void;
}>({
  preventScrollTop: noop,
});

function RouteScrollProvider({ children }: { children: ReactNode }) {
  const { key } = useLocation();
  const navigationType = useNavigationType();

  const [shouldScrollTop, setShouldScrollTop] = useState(true);

  const preventScrollTop = () => setShouldScrollTop(false);

  useEffect(() => {
    if (!shouldScrollTop) {
      setShouldScrollTop(true);
      return;
    }

    if (navigationType === 'PUSH' || navigationType === 'REPLACE') {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return (
    <RouteScrollContext.Provider value={{ preventScrollTop }}>
      {children}
    </RouteScrollContext.Provider>
  );
}

export default RouteScrollProvider;
