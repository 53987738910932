import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminProductRelationsQueryVariables = Types.Exact<{
  productGroupId: Types.Scalars['Int']['input'];
}>;


export type AdminProductRelationsQuery = { __typename?: 'Query', adminProductRelations: { __typename?: 'ProductItemRelationPageGraphqlType', edges: Array<{ __typename?: 'PaginatedProductItemRelationGraphqlTypeEdge', node: { __typename?: 'ProductItemRelationGraphqlType', id: number, setCount: number, isLinkedItem: boolean, linkedProductGroups?: Array<{ __typename?: 'LinkedProductGroupGraphqlType', id: number, name: string }> | null, product: { __typename?: 'ProductGraphqlType', id: number, code: string, name: string }, item: { __typename?: 'ItemGraphqlType', id: number, code: string, name: string, stock?: number | null, isInfinityStock: boolean } } }> } };

export type AdminUpdateProductItemRelationsMutationVariables = Types.Exact<{
  input: Types.UpdateProductItemRelationsGraphqlInput;
}>;


export type AdminUpdateProductItemRelationsMutation = { __typename?: 'Mutation', adminUpdateProductItemRelations: { __typename?: 'UpdateProductItemRelationsPayload', productItemRelations: Array<{ __typename?: 'ProductItemRelationGraphqlType', id: number, setCount: number, isLinkedItem: boolean, linkedProductGroups?: Array<{ __typename?: 'LinkedProductGroupGraphqlType', id: number, name: string }> | null, product: { __typename?: 'ProductGraphqlType', id: number, code: string, name: string }, item: { __typename?: 'ItemGraphqlType', id: number, code: string, name: string, stock?: number | null, isInfinityStock: boolean } }> } };


export const AdminProductRelationsDocument = gql`
    query AdminProductRelations($productGroupId: Int!) {
  adminProductRelations(productGroupId: $productGroupId) {
    edges {
      node {
        id
        setCount
        isLinkedItem
        linkedProductGroups {
          id
          name
        }
        product {
          id
          code
          name
        }
        item {
          id
          code
          name
          stock
          isInfinityStock
        }
      }
    }
  }
}
    `;

/**
 * __useAdminProductRelationsQuery__
 *
 * To run a query within a React component, call `useAdminProductRelationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductRelationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductRelationsQuery({
 *   variables: {
 *      productGroupId: // value for 'productGroupId'
 *   },
 * });
 */
export function useAdminProductRelationsQuery(baseOptions: Apollo.QueryHookOptions<AdminProductRelationsQuery, AdminProductRelationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductRelationsQuery, AdminProductRelationsQueryVariables>(AdminProductRelationsDocument, options);
      }
export function useAdminProductRelationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductRelationsQuery, AdminProductRelationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductRelationsQuery, AdminProductRelationsQueryVariables>(AdminProductRelationsDocument, options);
        }
export type AdminProductRelationsQueryHookResult = ReturnType<typeof useAdminProductRelationsQuery>;
export type AdminProductRelationsLazyQueryHookResult = ReturnType<typeof useAdminProductRelationsLazyQuery>;
export type AdminProductRelationsQueryResult = Apollo.QueryResult<AdminProductRelationsQuery, AdminProductRelationsQueryVariables>;
export const AdminUpdateProductItemRelationsDocument = gql`
    mutation AdminUpdateProductItemRelations($input: UpdateProductItemRelationsGraphqlInput!) {
  adminUpdateProductItemRelations(input: $input) {
    productItemRelations {
      id
      setCount
      isLinkedItem
      linkedProductGroups {
        id
        name
      }
      product {
        id
        code
        name
      }
      item {
        id
        code
        name
        stock
        isInfinityStock
      }
    }
  }
}
    `;
export type AdminUpdateProductItemRelationsMutationFn = Apollo.MutationFunction<AdminUpdateProductItemRelationsMutation, AdminUpdateProductItemRelationsMutationVariables>;

/**
 * __useAdminUpdateProductItemRelationsMutation__
 *
 * To run a mutation, you first call `useAdminUpdateProductItemRelationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateProductItemRelationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateProductItemRelationsMutation, { data, loading, error }] = useAdminUpdateProductItemRelationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateProductItemRelationsMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateProductItemRelationsMutation, AdminUpdateProductItemRelationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateProductItemRelationsMutation, AdminUpdateProductItemRelationsMutationVariables>(AdminUpdateProductItemRelationsDocument, options);
      }
export type AdminUpdateProductItemRelationsMutationHookResult = ReturnType<typeof useAdminUpdateProductItemRelationsMutation>;
export type AdminUpdateProductItemRelationsMutationResult = Apollo.MutationResult<AdminUpdateProductItemRelationsMutation>;
export type AdminUpdateProductItemRelationsMutationOptions = Apollo.BaseMutationOptions<AdminUpdateProductItemRelationsMutation, AdminUpdateProductItemRelationsMutationVariables>;