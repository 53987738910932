import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminPatchOrderClaimStatusMutationVariables = Types.Exact<{
  orderClaimData: Types.PatchOrderClaimStatusGraphqlType;
  salesChannelType: Types.ESalesChannelType;
}>;


export type AdminPatchOrderClaimStatusMutation = { __typename?: 'Mutation', adminPatchOrderClaimStatus: { __typename?: 'OrderClaimOutputGraphqlType', orderClaim: { __typename?: 'OrderClaimGraphqlType', status: Types.EOrderClaimStatus, claimType: Types.EOrderClaimType } } };

export type AdminBulkUpdateOrderClaimMutationVariables = Types.Exact<{
  orderClaimsData: Types.BulkUpdateOrderClaimInputGraphqlType;
  salesChannelType: Types.ESalesChannelType;
}>;


export type AdminBulkUpdateOrderClaimMutation = { __typename?: 'Mutation', adminBulkUpdateOrderClaim: { __typename?: 'BulkUpdateOrderClaimOutputGraphqlType', failureOrderIds: Array<number>, successOrderIds: Array<number>, updatedOrderClaims: Array<{ __typename?: 'OrderClaimGraphqlType', claimQuantity?: number | null, claimType: Types.EOrderClaimType, createdAt: string, id: number, isWiredOwnDeliveryFault?: boolean | null, reason?: string | null, reasonType: Types.EOrderClaimReasonType, status: Types.EOrderClaimStatus, target: Types.EOrderClaimTarget, updatedAt: string, orderId: number }> } };

export type ClaimDeliveryFeeQueryVariables = Types.Exact<{
  adminProductGroupId: Types.Scalars['Int']['input'];
  adminPromotionReleaseId: Types.Scalars['Int']['input'];
}>;


export type ClaimDeliveryFeeQuery = { __typename?: 'Query', adminProductGroup: { __typename?: 'ProductGroupGraphqlType', exchangeFee?: number | null, productGroupType: Types.EProductGroupType }, adminPromotionRelease: { __typename?: 'PromotionReleaseGraphqlType', promotion: { __typename?: 'PromotionGraphqlType', deliveryFeeBearer?: Types.ECostBearer | null, deliveryFee?: number | null, deliveryFeeSettlementPrice?: number | null, deliveryFeeSettlementTarget?: Types.ESettlementTarget | null } } };

export type AdminProductGroupClaimFeeQueryVariables = Types.Exact<{
  adminProductGroupId: Types.Scalars['Int']['input'];
}>;


export type AdminProductGroupClaimFeeQuery = { __typename?: 'Query', adminProductGroup: { __typename?: 'ProductGroupGraphqlType', exchangeFee?: number | null, returnFee?: number | null } };


export const AdminPatchOrderClaimStatusDocument = gql`
    mutation AdminPatchOrderClaimStatus($orderClaimData: PatchOrderClaimStatusGraphqlType!, $salesChannelType: ESalesChannelType!) {
  adminPatchOrderClaimStatus(
    orderClaimData: $orderClaimData
    salesChannelType: $salesChannelType
  ) {
    orderClaim {
      status
      claimType
    }
  }
}
    `;
export type AdminPatchOrderClaimStatusMutationFn = Apollo.MutationFunction<AdminPatchOrderClaimStatusMutation, AdminPatchOrderClaimStatusMutationVariables>;

/**
 * __useAdminPatchOrderClaimStatusMutation__
 *
 * To run a mutation, you first call `useAdminPatchOrderClaimStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPatchOrderClaimStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPatchOrderClaimStatusMutation, { data, loading, error }] = useAdminPatchOrderClaimStatusMutation({
 *   variables: {
 *      orderClaimData: // value for 'orderClaimData'
 *      salesChannelType: // value for 'salesChannelType'
 *   },
 * });
 */
export function useAdminPatchOrderClaimStatusMutation(baseOptions?: Apollo.MutationHookOptions<AdminPatchOrderClaimStatusMutation, AdminPatchOrderClaimStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPatchOrderClaimStatusMutation, AdminPatchOrderClaimStatusMutationVariables>(AdminPatchOrderClaimStatusDocument, options);
      }
export type AdminPatchOrderClaimStatusMutationHookResult = ReturnType<typeof useAdminPatchOrderClaimStatusMutation>;
export type AdminPatchOrderClaimStatusMutationResult = Apollo.MutationResult<AdminPatchOrderClaimStatusMutation>;
export type AdminPatchOrderClaimStatusMutationOptions = Apollo.BaseMutationOptions<AdminPatchOrderClaimStatusMutation, AdminPatchOrderClaimStatusMutationVariables>;
export const AdminBulkUpdateOrderClaimDocument = gql`
    mutation AdminBulkUpdateOrderClaim($orderClaimsData: BulkUpdateOrderClaimInputGraphqlType!, $salesChannelType: ESalesChannelType!) {
  adminBulkUpdateOrderClaim(
    orderClaimsData: $orderClaimsData
    salesChannelType: $salesChannelType
  ) {
    failureOrderIds
    successOrderIds
    updatedOrderClaims {
      claimQuantity
      claimType
      createdAt
      id
      isWiredOwnDeliveryFault
      reason
      reasonType
      status
      target
      updatedAt
      orderId
    }
  }
}
    `;
export type AdminBulkUpdateOrderClaimMutationFn = Apollo.MutationFunction<AdminBulkUpdateOrderClaimMutation, AdminBulkUpdateOrderClaimMutationVariables>;

/**
 * __useAdminBulkUpdateOrderClaimMutation__
 *
 * To run a mutation, you first call `useAdminBulkUpdateOrderClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkUpdateOrderClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkUpdateOrderClaimMutation, { data, loading, error }] = useAdminBulkUpdateOrderClaimMutation({
 *   variables: {
 *      orderClaimsData: // value for 'orderClaimsData'
 *      salesChannelType: // value for 'salesChannelType'
 *   },
 * });
 */
export function useAdminBulkUpdateOrderClaimMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkUpdateOrderClaimMutation, AdminBulkUpdateOrderClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkUpdateOrderClaimMutation, AdminBulkUpdateOrderClaimMutationVariables>(AdminBulkUpdateOrderClaimDocument, options);
      }
export type AdminBulkUpdateOrderClaimMutationHookResult = ReturnType<typeof useAdminBulkUpdateOrderClaimMutation>;
export type AdminBulkUpdateOrderClaimMutationResult = Apollo.MutationResult<AdminBulkUpdateOrderClaimMutation>;
export type AdminBulkUpdateOrderClaimMutationOptions = Apollo.BaseMutationOptions<AdminBulkUpdateOrderClaimMutation, AdminBulkUpdateOrderClaimMutationVariables>;
export const ClaimDeliveryFeeDocument = gql`
    query ClaimDeliveryFee($adminProductGroupId: Int!, $adminPromotionReleaseId: Int!) {
  adminProductGroup(id: $adminProductGroupId) {
    exchangeFee
    productGroupType
  }
  adminPromotionRelease(id: $adminPromotionReleaseId) {
    promotion {
      deliveryFeeBearer
      deliveryFee
      deliveryFeeSettlementPrice
      deliveryFeeSettlementTarget
    }
  }
}
    `;

/**
 * __useClaimDeliveryFeeQuery__
 *
 * To run a query within a React component, call `useClaimDeliveryFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimDeliveryFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimDeliveryFeeQuery({
 *   variables: {
 *      adminProductGroupId: // value for 'adminProductGroupId'
 *      adminPromotionReleaseId: // value for 'adminPromotionReleaseId'
 *   },
 * });
 */
export function useClaimDeliveryFeeQuery(baseOptions: Apollo.QueryHookOptions<ClaimDeliveryFeeQuery, ClaimDeliveryFeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClaimDeliveryFeeQuery, ClaimDeliveryFeeQueryVariables>(ClaimDeliveryFeeDocument, options);
      }
export function useClaimDeliveryFeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClaimDeliveryFeeQuery, ClaimDeliveryFeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClaimDeliveryFeeQuery, ClaimDeliveryFeeQueryVariables>(ClaimDeliveryFeeDocument, options);
        }
export type ClaimDeliveryFeeQueryHookResult = ReturnType<typeof useClaimDeliveryFeeQuery>;
export type ClaimDeliveryFeeLazyQueryHookResult = ReturnType<typeof useClaimDeliveryFeeLazyQuery>;
export type ClaimDeliveryFeeQueryResult = Apollo.QueryResult<ClaimDeliveryFeeQuery, ClaimDeliveryFeeQueryVariables>;
export const AdminProductGroupClaimFeeDocument = gql`
    query AdminProductGroupClaimFee($adminProductGroupId: Int!) {
  adminProductGroup(id: $adminProductGroupId) {
    exchangeFee
    returnFee
  }
}
    `;

/**
 * __useAdminProductGroupClaimFeeQuery__
 *
 * To run a query within a React component, call `useAdminProductGroupClaimFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductGroupClaimFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductGroupClaimFeeQuery({
 *   variables: {
 *      adminProductGroupId: // value for 'adminProductGroupId'
 *   },
 * });
 */
export function useAdminProductGroupClaimFeeQuery(baseOptions: Apollo.QueryHookOptions<AdminProductGroupClaimFeeQuery, AdminProductGroupClaimFeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductGroupClaimFeeQuery, AdminProductGroupClaimFeeQueryVariables>(AdminProductGroupClaimFeeDocument, options);
      }
export function useAdminProductGroupClaimFeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductGroupClaimFeeQuery, AdminProductGroupClaimFeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductGroupClaimFeeQuery, AdminProductGroupClaimFeeQueryVariables>(AdminProductGroupClaimFeeDocument, options);
        }
export type AdminProductGroupClaimFeeQueryHookResult = ReturnType<typeof useAdminProductGroupClaimFeeQuery>;
export type AdminProductGroupClaimFeeLazyQueryHookResult = ReturnType<typeof useAdminProductGroupClaimFeeLazyQuery>;
export type AdminProductGroupClaimFeeQueryResult = Apollo.QueryResult<AdminProductGroupClaimFeeQuery, AdminProductGroupClaimFeeQueryVariables>;