import { Table } from 'antd';

import useProductMarketListTable from '../hooks/useProductMarketListTable';
import useProductMarketListTableColumns from '../hooks/useProductMarketListTableColumns';
import { ProductMarketListModalStatusType } from '../types';

import ProductMarketListOptionTable from './ProductMarketListOptionTable';

type ProductMarketListTableProps = {
  type: ProductMarketListModalStatusType;
  productGroupId: number;
};

function ProductMarketListTable({
  type,
  productGroupId,
}: ProductMarketListTableProps) {
  const { dataSource, pagination } = useProductMarketListTable({
    type,
    productGroupId,
  });
  const { columns } = useProductMarketListTableColumns();

  return (
    <Table
      size='small'
      expandable={{
        expandedRowRender: ({ id }) => (
          <ProductMarketListOptionTable marketId={id} />
        ),
      }}
      dataSource={dataSource}
      pagination={pagination}
      columns={columns}
    />
  );
}

export default ProductMarketListTable;
