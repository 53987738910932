import { Button, Table, App } from 'antd';
import { useCallback } from 'react';

import Modal from '../../../common/components/Modal';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { UpdateProductItemRelationsGraphqlInput } from '../../../schema/types';
import { useAdminUpdateProductItemRelationsMutation } from '../graphql/productRelations.generated';
import useProductRelationsTable from '../hooks/useProductRelationsTable';

type ProductRelationsModalProps = {
  onClose: () => void;
  productGroupId: number;
};

function ProductRelationsModal({
  onClose,
  productGroupId,
}: ProductRelationsModalProps) {
  const { message } = App.useApp();
  const { dataSources, columns, loading } =
    useProductRelationsTable(productGroupId);
  const scrollX = columns.reduce((acc, cur) => acc + Number(cur.width), 0);
  const [mutate] = useAdminUpdateProductItemRelationsMutation();
  const { hasPermission } = usePermissions('PRODUCT_EDIT');

  const onSave = useCallback(async () => {
    const updateProductRelations: UpdateProductItemRelationsGraphqlInput['productItemRelations'] =
      dataSources.map((data) => ({
        productItemRelationId: data.id,
        itemId: data.itemId,
        stock: data.stock,
        isInfinityStock: data.isInfinityStock,
        setCount: data.setCount,
      }));
    await mutate({
      variables: { input: { productItemRelations: updateProductRelations } },
      onCompleted() {
        void message.success('재고가 업데이트되었습니다.');
        onClose();
      },
      onError() {
        void message.error('수정을 실패했습니다.');
      },
    });
  }, [mutate, dataSources, message]);

  return (
    <Modal
      open
      title='재고관리'
      onCancel={onClose}
      width={'fit-content'}
      footer={null}
    >
      <Table
        className='mt-6'
        tableLayout='fixed'
        footer={undefined}
        scroll={{ x: scrollX }}
        columns={columns}
        dataSource={dataSources}
        pagination={false}
        loading={loading}
      />
      <div className='mt-4 flex justify-end gap-2'>
        <Button onClick={onClose}>취소</Button>
        <Button type='primary' onClick={onSave} disabled={!hasPermission}>
          저장
        </Button>
      </div>
    </Modal>
  );
}

export default ProductRelationsModal;
