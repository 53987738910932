import { PlusOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import Label from '../../../common/components/Fields/Label';
import NumberField from '../../../common/components/Fields/NumberField';
import SelectField from '../../../common/components/Fields/SelectField';
import { IS_FREE_PROVIDED_OPTIONS } from '../const';
import { getPromotionProduct } from '../helper';
import { PromotionFormValues } from '../types';

import PromotionProductItemSelectCell from './PromotionProductItemSelectCell';
import PromotionProductSelectCell from './PromotionProductSelectCell';
import PromotionProductExpandable from './PromotionProductTableExpandable';

type PromotionProductsTableDataType =
  PromotionFormValues['promotionProducts']['0'];

function PromotionProductsTable({ disabled }: { disabled?: boolean }) {
  const { control, watch, setValue } = useFormContext<PromotionFormValues>();
  const [promotionProducts] = watch(['promotionProducts']);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const expandedRowIndex = promotionProducts
    .map(({ isFreeProvided }, i) => (!isFreeProvided ? i : null))
    .filter((value) => typeof value === 'number');

  return (
    <ArrayField control={control} name='promotionProducts'>
      {({ append, remove, fields }) => (
        <>
          {!disabled && (
            <Button
              onClick={() => {
                remove(
                  selectedRowKeys.map((key) =>
                    fields.findIndex((field) => field.key === key)
                  )
                );
                setSelectedRowKeys([]);
              }}
            >
              선택삭제
            </Button>
          )}
          <Table<PromotionProductsTableDataType>
            dataSource={fields}
            pagination={false}
            expandable={{
              expandedRowRender: (_, index) => (
                <PromotionProductExpandable
                  index={index}
                  disabled={disabled}
                  className={!disabled ? 'ml-12' : ''}
                />
              ),
              rowExpandable: () => true,
              expandedRowKeys: fields
                .filter((_, i) => expandedRowIndex.includes(i))
                .map(({ key }) => key),
              showExpandColumn: false,
            }}
            rowSelection={
              !disabled
                ? {
                    type: 'checkbox',
                    columnWidth: 48,
                    selectedRowKeys,
                    onChange: (rowKeys) =>
                      setSelectedRowKeys(rowKeys as string[]),
                  }
                : undefined
            }
            scroll={{ x: 856 }}
            tableLayout='fixed'
            footer={
              !disabled
                ? () => (
                    <div className='flex items-center justify-between'>
                      <Button
                        type='text'
                        icon={<PlusOutlined />}
                        onClick={() => append(getPromotionProduct())}
                        disabled={promotionProducts.length > 9}
                      >
                        품목 추가
                      </Button>
                    </div>
                  )
                : undefined
            }
            className='mt-2'
          >
            <Table.Column<PromotionProductsTableDataType>
              title={
                <Label
                  required
                  tooltip={'PB제품은 와이어드를 업체로 간주합니다.'}
                >
                  업체 유/무상
                </Label>
              }
              dataIndex='isFreeProvided'
              width={150}
              render={(_, __, index) => (
                <SelectField
                  options={IS_FREE_PROVIDED_OPTIONS}
                  className='w-full'
                  disabled={disabled}
                  control={control}
                  name={`promotionProducts.${index}.isFreeProvided`}
                  value={Number(
                    watch(`promotionProducts.${index}.isFreeProvided`)
                  )}
                  onChange={(value) =>
                    setValue(
                      `promotionProducts.${index}.isFreeProvided`,
                      !!value
                    )
                  }
                />
              )}
            />
            <Table.Column<PromotionProductsTableDataType>
              title={<Label required>품목명</Label>}
              dataIndex='name'
              render={(_, __, index) => (
                <PromotionProductSelectCell index={index} disabled={disabled} />
              )}
            />
            <Table.Column<PromotionProductsTableDataType>
              title={<Label required>옵션명</Label>}
              render={(_, __, index) => (
                <PromotionProductItemSelectCell
                  index={index}
                  disabled={disabled}
                />
              )}
            />
            <Table.Column<PromotionProductsTableDataType>
              title={<Label required>수량</Label>}
              dataIndex='setCount'
              width={108}
              render={(_, __, index) => (
                <NumberField
                  disabled={disabled}
                  control={control}
                  name={`promotionProducts.${index}.setCount`}
                />
              )}
            />
          </Table>
        </>
      )}
    </ArrayField>
  );
}

export default PromotionProductsTable;
