import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useState } from 'react';

import PageLayout from '../common/components/PageLayout';
import useRouteSearchParams from '../common/hooks/useRouteSearchParams';
import CreateDepositModal from '../domain/settlement/components/settlement-deposit-list/CreateDepositModal';
import DepositTable from '../domain/settlement/components/settlement-deposit-list/DepositTable';
import SearchForm from '../domain/settlement/components/settlement-deposit-list/SearchForm';
import Summary, { SummaryProps } from '../domain/settlement/components/Summary';
import { useAdminCountOrderGroupDepositStatusesQuery } from '../domain/settlement/graphql/deposit.generated';
import { DepositListSearchParams } from '../domain/settlement/types';

function SettlementDepositList() {
  const [depositModal, setDepositModal] = useState(false);
  const { searchParams, replaceSearchParams } =
    useRouteSearchParams<DepositListSearchParams>();
  const { data } = useAdminCountOrderGroupDepositStatusesQuery();
  const summaryItems: SummaryProps['items'] = [
    {
      key: 'WAITING',
      label: '입금대기',
      count:
        data?.adminCountOrderGroupDepositStatuses?.find(
          ({ status }) => status === 'WAITING'
        )?.count || 0,
      onClick: () => replaceSearchParams({ summaryStatus: 'WAITING' }),
    },
  ];

  return (
    <PageLayout
      summary={
        <Summary
          label='요청현황'
          activeKey={searchParams.summaryStatus}
          items={summaryItems}
        />
      }
      search={<SearchForm />}
    >
      <div className='bg-white p-6 pt-0'>
        <div className='flex justify-between pb-4 pt-6'>
          <Typography.Title level={4} className='m-0'>
            입금리스트
          </Typography.Title>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => setDepositModal(true)}
          >
            입금대기 등록
          </Button>
        </div>
        <DepositTable />
        {depositModal && (
          <CreateDepositModal onClose={() => setDepositModal(false)} />
        )}
      </div>
    </PageLayout>
  );
}

export default SettlementDepositList;
