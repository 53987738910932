import { ESellerSettlementType } from '../../schema/types';

export const SELLER_GRADES = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

export const SELLER_GRADES_WITH_ALL = [
  { label: '전체', value: -1 },
  ...SELLER_GRADES,
  { label: 'None', value: 0 },
];

export const SELLER_SETTLEMENT_LABELS: {
  [k in ESellerSettlementType]: string;
} = {
  BUSINESS: '사업자',
  FREELANCER: '프리랜서',
};

export const SELLER_SETTLEMENT_TYPES: {
  value: ESellerSettlementType;
  label: string;
}[] = [
  { value: 'BUSINESS', label: '사업자' },
  { value: 'FREELANCER', label: '프리랜서' },
];

export const defaultSellersVariables = {
  sellersInput: {
    filter: {},
    pagination: {
      skip: 0,
      take: 50,
    },
  },
};

export const SELLER_LINKS_MAX_LENGTH = 5;
export const SELLER_LINKS_MIN_LENGTH = 1;
export const SELLER_EMAILS_MAX_LENGTH = 3;
export const SELLER_SETTLEMENTS_MAX_LENGTH = 3;
export const SELLER_SETTLEMENTS_MIN_LENGTH = 1;
export const SELLER_ADDRESSES_MAX_LENGTH = 3;
