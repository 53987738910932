import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminBrandsInOrderSearchQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AdminListBrandsPageQueryFilter>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminBrandsInOrderSearchQuery = { __typename?: 'Query', adminBrands: { __typename?: 'BrandPageGraphqlType', edges: Array<{ __typename?: 'PaginatedBrandGraphqlTypeEdge', node: { __typename?: 'BrandGraphqlType', name: string, id: number } }> } };


export const AdminBrandsInOrderSearchDocument = gql`
    query AdminBrandsInOrderSearch($filter: AdminListBrandsPageQueryFilter, $pagination: Pagination) {
  adminBrands(filter: $filter, pagination: $pagination) {
    edges {
      node {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useAdminBrandsInOrderSearchQuery__
 *
 * To run a query within a React component, call `useAdminBrandsInOrderSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBrandsInOrderSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBrandsInOrderSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminBrandsInOrderSearchQuery(baseOptions?: Apollo.QueryHookOptions<AdminBrandsInOrderSearchQuery, AdminBrandsInOrderSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminBrandsInOrderSearchQuery, AdminBrandsInOrderSearchQueryVariables>(AdminBrandsInOrderSearchDocument, options);
      }
export function useAdminBrandsInOrderSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminBrandsInOrderSearchQuery, AdminBrandsInOrderSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminBrandsInOrderSearchQuery, AdminBrandsInOrderSearchQueryVariables>(AdminBrandsInOrderSearchDocument, options);
        }
export type AdminBrandsInOrderSearchQueryHookResult = ReturnType<typeof useAdminBrandsInOrderSearchQuery>;
export type AdminBrandsInOrderSearchLazyQueryHookResult = ReturnType<typeof useAdminBrandsInOrderSearchLazyQuery>;
export type AdminBrandsInOrderSearchQueryResult = Apollo.QueryResult<AdminBrandsInOrderSearchQuery, AdminBrandsInOrderSearchQueryVariables>;