import { Table, Tag, Tooltip, Typography } from 'antd';
import { SelectionSelectFn, TableRowSelection } from 'antd/es/table/interface';
import { differenceBy } from 'lodash-es';
import { useCallback, useContext, useEffect, useState } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import {
  APP_HEADER_HEIGHT,
  TABLE_ACTION_HEADER_HEIGHT,
} from '../../../theme/const';
import { OrderListTableSelectedRowContext } from '../contexts/OrderListTableSelectedRowContext';
import { OrderListSearchParams, OrderTableDataType } from '../types';

import OrderDetailModal from './OrderDetailModal';
import OrderTableDeliveryCell from './OrderTableDeliveryCell';

type OrderListTableProps = {
  dataSourceState: OrderTableDataType[];
  total: number;
  pageSize: number;
  disabled?: boolean;
};

export default function OrderListTable({
  dataSourceState,
  total,
  pageSize,
  disabled,
}: OrderListTableProps) {
  const {
    selectedRowKeys,
    setSelectedRowKeys,
    rowSpanGroupIds,
    setRowSpanGroupIds,
  } = useContext(OrderListTableSelectedRowContext);

  const { searchParams, setSearchParams } =
    useRouteSearchParams<OrderListSearchParams>();

  const onSelect: SelectionSelectFn<OrderTableDataType> = (record) => {
    // 해제되어 그룹에서 제외되는 경우
    if (rowSpanGroupIds.includes(record.groupId)) {
      handleOrderCellSpan(record.groupId);
    }
  };

  const rowSelection: TableRowSelection<OrderTableDataType> = {
    columnWidth: 48,
    fixed: true,
    selectedRowKeys: selectedRowKeys.map(({ key }) => key),
    onSelect: onSelect,
    onChange: (_, selectedRows) => {
      const selectedRowKeys = selectedRows.map(
        ({ key, groupId, id, marketId }) => ({
          key,
          groupId,
          id,
          marketId,
        })
      );

      setSelectedRowKeys(selectedRowKeys);

      if (!selectedRows.length) setRowSpanGroupIds([]);
    },
  };

  const handleOrderCellSpan = useCallback(
    (groupId: number) => {
      const newRowSpanGroupIds = rowSpanGroupIds.includes(groupId)
        ? rowSpanGroupIds.filter((id) => id !== groupId)
        : [...rowSpanGroupIds, groupId];

      setRowSpanGroupIds(newRowSpanGroupIds);
    },
    [rowSpanGroupIds, setRowSpanGroupIds]
  );

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<number | undefined>();
  const handleOrderCodeClick = (orderId: number) => {
    setOpenDetailModal(true);
    setCurrentOrderId(orderId);
  };

  const handlePageChange = (page: number, size: number) => {
    if (size !== pageSize) {
      setSearchParams({ currentPage: 1, pageSize: size });
    } else {
      setSearchParams({ currentPage: page });
    }
  };

  useEffect(() => {
    setRowSpanGroupIds([]);
    setSelectedRowKeys([]);
  }, [searchParams, setRowSpanGroupIds, setSelectedRowKeys]);

  return (
    <>
      <Table<OrderTableDataType>
        scroll={{ x: 4858 }}
        dataSource={dataSourceState}
        rowSelection={disabled ? undefined : rowSelection}
        pagination={{
          showSizeChanger: true,
          total,
          showTotal: (total) => `총 ${total}건`,
          pageSize: pageSize,
          onChange: handlePageChange,
          pageSizeOptions: ['10', '20', '50', '100', '200'],
        }}
        sticky={{
          offsetHeader: APP_HEADER_HEIGHT + TABLE_ACTION_HEADER_HEIGHT,
        }}
      >
        <Table.Column<OrderTableDataType>
          title='와이어드 품목주문코드'
          width={200}
          fixed='left'
          render={(_, record) => {
            return (
              <div className='text-center'>
                <Typography.Paragraph
                  className='m-0 cursor-pointer text-primary'
                  onClick={() => handleOrderCodeClick(record.id)}
                >
                  {record.code}
                </Typography.Paragraph>
                {record.marketEventId && (
                  <div>
                    <Tag>{`이벤트ID: ${record.marketEventId}`}</Tag>
                  </div>
                )}
                <Tooltip
                  title='판매채널상품주문번호'
                  arrow={false}
                  placement='bottomLeft'
                >
                  <Typography.Paragraph className='m-0 text-description'>
                    {record.salesChannelOrderCode &&
                      `(${record.salesChannelOrderCode})`}
                  </Typography.Paragraph>
                </Tooltip>
              </div>
            );
          }}
        />
        <Table.Column<OrderTableDataType>
          title='와이어드 주문코드'
          width={200}
          fixed='left'
          render={(_, record) => {
            return (
              <>
                <Typography.Paragraph className='m-0'>
                  {record.groupCode}
                </Typography.Paragraph>
                <Tooltip
                  title='판매채널주문번호'
                  arrow={false}
                  placement='bottomLeft'
                >
                  <Typography.Paragraph className='m-0  text-description'>
                    {record.salesChannelOrderGroupCode &&
                      `(${record.salesChannelOrderGroupCode})`}
                  </Typography.Paragraph>
                </Tooltip>
              </>
            );
          }}
        />
        <Table.Column<OrderTableDataType>
          title='주문상태'
          width={120}
          render={(_, record) => {
            return (
              <>
                <Typography.Paragraph className='m-0'>
                  {record.status}
                </Typography.Paragraph>
                <Typography.Paragraph className='m-0 text-description'>
                  {record.claimType}
                </Typography.Paragraph>
              </>
            );
          }}
        />
        <Table.Column<OrderTableDataType>
          title='마켓명 (마켓ID)'
          width={240}
          render={(_, record) => {
            return (
              <>
                {record.marketName && record.marketId ? (
                  <>
                    <Typography.Paragraph className='m-0 w-[208px]' ellipsis>
                      {record.marketName}
                    </Typography.Paragraph>
                    <Typography.Paragraph className='m-0'>
                      ({record.marketId})
                    </Typography.Paragraph>
                  </>
                ) : (
                  <Typography.Paragraph className='m-0'>-</Typography.Paragraph>
                )}
              </>
            );
          }}
        />
        <Table.Column<OrderTableDataType>
          title='업체명'
          width={240}
          dataIndex={'vendorName'}
          render={(_, record) => (
            <Typography.Paragraph
              className='m-0 w-[208px]'
              ellipsis={{ rows: 3 }}
            >
              {record.vendorName}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<OrderTableDataType>
          title='브랜드명'
          width={240}
          dataIndex={'brandName'}
        />
        <Table.Column<OrderTableDataType>
          title='상품명'
          width={240}
          dataIndex={'productGroupName'}
        />
        <Table.Column<OrderTableDataType>
          title='품목명'
          width={240}
          render={(_, record) => {
            if (!record.productName) record.salesChannelProductName ?? '-';

            const ProductName = () => (
              <span>
                {record.productName}
                {record.productUseType === 'SELECT' &&
                  record.items.length > 0 && (
                    <span>({record.items.join(', ')})</span>
                  )}
              </span>
            );

            return (
              <Tooltip title={ProductName} placement='bottomLeft'>
                <Typography.Paragraph
                  className='m-0 w-[208px]'
                  ellipsis={{ rows: 3 }}
                >
                  <ProductName />
                </Typography.Paragraph>
              </Tooltip>
            );
          }}
        />
        <Table.Column<OrderTableDataType>
          title='수량'
          width={120}
          dataIndex={'productQuantity'}
        />
        <Table.Column<OrderTableDataType>
          title='상품주문금액'
          width={120}
          render={(_, record) => (
            <Typography.Paragraph className='m-0'>
              {record.orderType === 'EVENT'
                ? 0
                : record.orderAmount.toLocaleString()}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<OrderTableDataType>
          title='묶음배송코드'
          width={160}
          dataIndex={'bundleDeliveryCode'}
        />
        <Table.Column<OrderTableDataType>
          title='배송비'
          width={120}
          render={(_, record) => (
            <Typography.Paragraph className='m-0'>
              {record.orderType === 'EVENT' || !record.deliveryFee
                ? 0
                : record.deliveryFee.toLocaleString()}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<OrderTableDataType>
          title='지역별 배송비'
          width={120}
          render={(_, record) => (
            <Typography.Paragraph className='m-0'>
              {record.orderType === 'EVENT' || !record.regionDeliveryFee
                ? 0
                : record.regionDeliveryFee.toLocaleString()}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<OrderTableDataType>
          title='추가할인금액'
          width={120}
          className='m-0'
          render={(_, record) => (
            <Typography.Paragraph className='m-0'>
              {record.orderType === 'EVENT' || !record.extraDiscount
                ? 0
                : record.extraDiscount.toLocaleString()}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan, groupId, siblings }) => {
            const isMergedSpan = siblings.every(({ key }) =>
              selectedRowKeys.find(
                ({ key: selectedKey }) => key === selectedKey
              )
            );

            return {
              rowSpan:
                rowSpanGroupIds.includes(groupId) && isMergedSpan ? rowSpan : 1,
            };
          }}
          title='택배사 / 송장번호'
          width={240}
          render={(_, record) => {
            return (
              <OrderTableDeliveryCell
                record={record}
                onCellSpanChange={(groupId) => {
                  const isMergedSpan = rowSpanGroupIds.includes(groupId);

                  if (isMergedSpan) {
                    // 개별송장입력

                    const diff = differenceBy(
                      selectedRowKeys,
                      record.siblings,
                      'key'
                    );

                    setSelectedRowKeys(diff);
                  } else {
                    // 통합송장입력
                    const diff = differenceBy(
                      record.siblings,
                      selectedRowKeys,
                      'key'
                    );

                    setSelectedRowKeys([...selectedRowKeys, ...diff]);
                  }

                  handleOrderCellSpan(groupId);
                }}
                isMergedSpan={rowSpanGroupIds.includes(record.groupId)}
              />
            );
          }}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan }) => ({
            rowSpan,
          })}
          title='수령자정보'
          width={240}
          render={(_, record) => (
            <div>
              <Typography.Paragraph className='m-0' strong>
                {record.receiverName} / {record.receiverPhoneNumber}
              </Typography.Paragraph>

              <Typography.Paragraph className='m-0'>
                {record.receiverAddress}
                {record.receiverDetailAddress} (우)
                {record.receiverZipCode}
              </Typography.Paragraph>
              <Tooltip
                title={record.receiverDeliveryMemo}
                arrow={false}
                placement='bottomLeft'
              >
                <Typography.Paragraph className='m-0 w-[208px]' ellipsis>
                  {record.receiverDeliveryMemo}
                </Typography.Paragraph>
              </Tooltip>
            </div>
          )}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan }) => ({
            rowSpan,
          })}
          title='판매채널'
          width={200}
          render={(_, record) => (
            <Typography.Paragraph className='m-0'>
              {`${record.salesChannelType}${
                record.salesChannel === record.salesChannelType
                  ? ''
                  : record.salesChannel
              }`}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan }) => ({
            rowSpan,
          })}
          title='주문자정보'
          width={200}
          render={(_, record) => (
            <Typography.Paragraph className='m-0'>
              {record.ordererName} / {record.ordererPhoneNumber}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan }) => ({
            rowSpan,
          })}
          title='결제수단'
          width={160}
          render={(_, record) => (
            <Typography.Paragraph className='m-0'>
              {record.paymentMethod ? record.paymentMethod : '-'}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan }) => ({
            rowSpan,
          })}
          title='적립금사용금액'
          width={120}
          render={(_, record) => (
            <Typography.Paragraph className='m-0'>
              {record.orderType === 'EVENT' || !record.pointDiscount
                ? 0
                : record.pointDiscount.toLocaleString()}
            </Typography.Paragraph>
          )}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan }) => ({
            rowSpan,
          })}
          title='주문일시'
          width={170}
          dataIndex={'orderedAt'}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan }) => ({
            rowSpan,
          })}
          title='결제일시'
          width={170}
          dataIndex={'paidAt'}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan }) => ({
            rowSpan,
          })}
          title='주문서변환일시'
          width={170}
          dataIndex={'orderConvertedAt'}
        />
        <Table.Column<OrderTableDataType>
          onCell={({ rowSpan }) => ({
            rowSpan,
          })}
          title='주문최종업데이트일시'
          width={170}
          dataIndex={'updatedAt'}
        />
      </Table>

      {openDetailModal && currentOrderId && (
        <OrderDetailModal
          id={currentOrderId}
          onClose={() => {
            setOpenDetailModal(false);
            setCurrentOrderId(undefined);
          }}
        />
      )}
    </>
  );
}
