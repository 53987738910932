import { Descriptions } from 'antd';

import { formatPriceWithComma } from '../../../../common/utils/price';
import { EProductGroupDeliveryFeeType } from '../../../../schema/types';
import { SHIPPING_TYPE_NAMES } from '../../const';

type ProposalShippingTableProps = {
  deliveryFeeType: EProductGroupDeliveryFeeType;
  deliveryFee: number;
  freeDeliveryConditionFee: number;
  jejuIslandFee: number;
  additionalFee: number;
};

export default function ProposalShippingTable({
  deliveryFeeType,
  deliveryFee,
  freeDeliveryConditionFee,
  jejuIslandFee,
  additionalFee,
}: ProposalShippingTableProps) {
  return (
    <Descriptions
      bordered
      column={2}
      labelStyle={{ width: 200 }}
      contentStyle={{ padding: '12px 24px' }}
    >
      <Descriptions.Item label='배송비 유형' span={2}>
        {SHIPPING_TYPE_NAMES[deliveryFeeType]}
      </Descriptions.Item>
      <Descriptions.Item label='배송비'>
        {deliveryFeeType !== 'FREE' ? `${deliveryFee ?? 0} 원` : '무료'}

        {deliveryFeeType === 'CONDITIONAL_FREE' &&
          `/ ${freeDeliveryConditionFee}원 이상 무료배송`}
      </Descriptions.Item>
      <Descriptions.Item label='지역별 배송비'>
        <span>
          제주도 {formatPriceWithComma(jejuIslandFee ? jejuIslandFee : 0)}
        </span>
        <span> / </span>
        <span>
          도서산간 {formatPriceWithComma(additionalFee ? additionalFee : 0)}
        </span>
      </Descriptions.Item>
    </Descriptions>
  );
}
