import { SelectProps } from 'antd';
import { get } from 'lodash-es';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import { FieldProps } from '../../../../common/components/Fields/types';

import UppermostCategorySelect from './UppermostCategorySelect';

type UppermostCategorySelectFieldProps<TFieldValues extends FieldValues> =
  SelectProps &
    FieldProps & {
      control: Control<TFieldValues>;
      name: Path<TFieldValues>;
    };

export default function UppermostCategorySelectField<
  TFieldValues extends FieldValues,
>({
  name,
  control,
  label,
  hideError,
  required,
  hideErrorMessage,
  ...props
}: UppermostCategorySelectFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const error = get(formState.errors, name);
        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;
        const status = !hideError && errorMessage ? 'error' : '';

        return (
          <FieldWithLabel label={label} required={required}>
            <UppermostCategorySelect
              status={status}
              {...field}
              {...props}
              onChange={(value, option) => {
                field.onChange(value ?? null);
                props.onChange?.(value, option);
              }}
            />
            {!hideErrorMessage && !hideError && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}
