import { Typography } from 'antd';
import { FieldValues, useFormContext } from 'react-hook-form';

import NumberField, {
  NumberFieldProps,
} from '../../../common/components/Fields/NumberField';

function NumberWithUnknownField<TFieldValues extends FieldValues>({
  onUnknownClick,
  ...props
}: NumberFieldProps<TFieldValues> & {
  onUnknownClick: () => void;
}) {
  const { watch } = useFormContext();

  const hasValue = !!watch(props.name);

  return (
    <NumberField
      placeholder={'금액입력'}
      numberType={'price'}
      className={'w-full'}
      addonAfter={
        <Typography.Text
          disabled={props.disabled}
          type={hasValue || props.disabled ? 'secondary' : undefined}
          className={`${
            props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } text-[12px] ${hasValue || props.disabled ? '' : 'text-primary'}`}
          onClick={props.disabled ? undefined : onUnknownClick}
        >
          모름
        </Typography.Text>
      }
      {...props}
    />
  );
}

export default NumberWithUnknownField;
