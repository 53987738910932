import { Typography, Button, Select, Spin } from 'antd';
import { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import EmptyTable from '../../../common/components/EmptyTable';
import useExcelDownload from '../../../common/hooks/useExcelDownload';
import { usePermissions } from '../../../common/hooks/usePermissions';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { EVENT_FILTER_OPTIONS } from '../const';
import { OrderListTableSelectedRowContext } from '../contexts/OrderListTableSelectedRowContext';
import useOrderListTable from '../hooks/useOrderListTable';
import { EventFilterType, OrderListSearchParams } from '../types';

import InvoiceExcelUploadModal from './InvoiceExcelUploadModal';
import OrderListActionButtons from './OrderListActionButtons';
import OrderListTable from './OrderListTable';

export default function OrderListTableWrapper() {
  const { hasPermission } = usePermissions('ORDER_EDIT');

  const { searchParams, setSearchParams } =
    useRouteSearchParams<OrderListSearchParams>();
  const { dataSourceState, total, loading, pageSize, isNoResult, params } =
    useOrderListTable();
  const { excelDownload } = useExcelDownload();
  const { selectedRowKeys } = useContext(OrderListTableSelectedRowContext);

  const handleEventFilter = (value: EventFilterType) => {
    if (value === 'ALL') {
      setSearchParams({
        orderTypes: undefined,
        currentPage: undefined,
        summaryStatus: undefined,
      });
    } else if (value === 'EVENT') {
      setSearchParams({
        orderTypes: ['EVENT'],
        currentPage: undefined,
        summaryStatus: undefined,
      });
    } else {
      setSearchParams({
        orderTypes: ['PRODUCT'],
        currentPage: undefined,
        summaryStatus: undefined,
      });
    }
  };

  const formOrders = useMemo(
    () =>
      dataSourceState.map((order) => ({
        id: order.id,
        deliveryCompanyCode: order.deliveryCompanyCode,
        trackingNumber: order.trackingNumber,
      })),
    [dataSourceState]
  );

  const methods = useForm({
    defaultValues: {
      orders: formOrders,
    },
  });

  const { reset } = methods;

  useEffect(() => {
    reset({
      orders: formOrders,
    });
  }, [formOrders, reset]);

  const orderTypes = useMemo(() => {
    if (searchParams.orderTypes && searchParams.orderTypes.length === 1) {
      if (searchParams.orderTypes[0] === 'EVENT') return 'EVENT';

      if (searchParams.orderTypes[0] === 'PRODUCT') return 'PRODUCT';
    }

    return 'ALL';
  }, [searchParams.orderTypes]);

  const handleUploadInvoiceExcel = () => {
    setInvoiceExcelUploadModalOpen(true);
  };

  const handleExcelDownload = async () => {
    const ordersParams = {
      ...params,
      orderTypes: params.orderTypes ? params.orderTypes.join(',') : undefined,
      statuses: params.statuses ? params.statuses.join(',') : undefined,
    };

    await excelDownload({
      url: '/admin/orders/download',
      config: { params: ordersParams },
      fileName: '주문리스트',
    });
  };

  const [invoiceExcelUploadModalOpen, setInvoiceExcelUploadModalOpen] =
    useState(false);

  return (
    <>
      <FormProvider {...methods}>
        <div className='flex flex-col px-6'>
          <div className='sticky top-tableTitlePositionTop z-10 flex flex-col gap-4 bg-white pb-4 pt-6'>
            <Typography.Title level={4} className={'sticky mb-0 bg-white'}>
              주문리스트
            </Typography.Title>
            <div className='flex justify-between'>
              <div>
                {selectedRowKeys.length > 0 && hasPermission && (
                  <OrderListActionButtons dataSourceState={dataSourceState} />
                )}
              </div>
              <div className='flex gap-2'>
                <Button
                  onClick={handleUploadInvoiceExcel}
                  disabled={!hasPermission}
                >
                  송장 일괄업로드
                </Button>
                <Button onClick={handleExcelDownload}>전체 다운로드</Button>
                <Select
                  className='w-28 [&_.ant-select-arrow]:text-primary [&_.ant-select-selection-item]:text-primary'
                  bordered={false}
                  value={orderTypes}
                  options={EVENT_FILTER_OPTIONS}
                  onChange={handleEventFilter}
                />
              </div>
            </div>
          </div>

          {isNoResult && <EmptyTable />}
          {!isNoResult && (
            <Spin tip='Loading...' spinning={loading}>
              <OrderListTable
                dataSourceState={dataSourceState}
                total={total ?? 0}
                pageSize={pageSize}
                disabled={!hasPermission}
              />
            </Spin>
          )}
        </div>
      </FormProvider>
      {invoiceExcelUploadModalOpen && (
        <InvoiceExcelUploadModal
          onClose={() => setInvoiceExcelUploadModalOpen(false)}
        />
      )}
    </>
  );
}
