import { TableColumnsType, Typography } from 'antd';
import { Link, generatePath } from 'react-router-dom';

import { ROUTES } from '../../../routes/const';
import { ProductMarketDataType } from '../types';

function useProductMarketListTableColumns() {
  const columns: TableColumnsType<ProductMarketDataType> = [
    {
      title: '마켓명',
      width: 240,
      dataIndex: 'name',
      key: 'name',
      render: (_, { name, id }) => {
        return (
          <Link
            to={generatePath(ROUTES.MARKET_DETAIL.path, {
              marketId: id,
            })}
            target='_blank'
          >
            <Typography.Text ellipsis className='w-[224px] text-primary'>
              {name}
            </Typography.Text>
          </Link>
        );
      },
    },
    {
      title: '마켓차수',
      width: 108,
      dataIndex: 'session',
      key: 'session',
    },
    {
      title: '판매채널',
      width: 160,
      dataIndex: 'sellingChannel',
      key: 'sellingChannel',
    },
    {
      title: '매출',
      width: 140,
      dataIndex: 'totalOrderAmount',
      key: 'totalOrderAmount',
      render: (_, { totalOrderAmount }) => totalOrderAmount.toLocaleString(),
    },
    {
      title: '마켓기간',
      width: 248,
      dataIndex: 'startedAt',
      key: 'startedAt',
      render: (startedAt, { endedAt }) => {
        return (
          <>
            <Typography.Text>{startedAt}</Typography.Text>
            {endedAt && (
              <Typography.Text className='ml-2'>{endedAt}</Typography.Text>
            )}
          </>
        );
      },
    },
    {
      title: '판매수량',
      width: 108,
      dataIndex: 'totalItemQuantity',
      key: 'totalItemQuantity',
      render: (_, { totalItemQuantity }) => totalItemQuantity.toLocaleString(),
    },
  ];

  return {
    columns,
  };
}

export default useProductMarketListTableColumns;
