import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Alert, Collapse, CollapseProps, Typography } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

import { Market } from '../types';

type PriceInformationReferencedMarketProps = {
  markets: Market[];
};

export default function PriceInformationReferencedMarket({
  markets,
}: PriceInformationReferencedMarketProps) {
  const [isOpen, setIsOpen] = useState(false);

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Typography.Paragraph className='mb-0 text-base'>
          참조된 마켓이 있습니다. (마켓에 참조되어 있는 품목은 수정 및 삭제가
          불가능합니다.)
        </Typography.Paragraph>
      ),
      children: markets.map(({ name, startedAt, endedAt }, index) => (
        <div className='flex justify-between last:mb-0' key={index}>
          <p>{name}</p>
          <p>
            {dayjs(startedAt).format('YY.MM.DD')}
            {endedAt ? ` ~ ${dayjs(endedAt).format('YY.MM.DD')}` : ''}
          </p>{' '}
        </div>
      )),
      extra: (
        <div className='flex gap-2'>
          {isOpen ? <UpOutlined /> : <DownOutlined />}
          <p>상세보기</p>
        </div>
      ),
      showArrow: false,
    },
  ];

  return (
    <Alert
      description={
        <Collapse
          items={items}
          accordion
          ghost
          className='[&_.ant-collapse-content-box]:mt-4 [&_.ant-collapse-content-box]:flex [&_.ant-collapse-content-box]:flex-col [&_.ant-collapse-content-box]:gap-2 [&_.ant-collapse-content-box]:p-0 [&_.ant-collapse-header]:p-0'
          onChange={(v) => setIsOpen(v.length > 0)}
        />
      }
      type='warning'
      showIcon
    />
  );
}
