import * as yup from 'yup';

import { bankOptions } from '../../common/utils/const';
import {
  CreateVendorWithBrandsInput,
  UpdateVendorInput,
  CreateBrandInput,
  UpdateBrandInput,
  EBrandContactType,
  EBrandStatus,
  EVendorContactType,
  EBankCode,
} from '../../schema/types';

import { CONTACT_ROLES } from './const';

const contactTypeValues = CONTACT_ROLES.map(({ value }) => value);
const bankCodeValues = bankOptions.map(({ value }) => value);

const contactSchema = {
  name: yup.string().trim().optional().nullable(),
  email: yup
    .string()
    .trim()
    .email('유효하지 않는 Email 입니다.')
    .optional()
    .nullable(),
  phone: yup.string().optional().nullable(),
};
const ERROR_MESSAGE = '필수항목이 입력되지 않았습니다.';
export const vendorSchema = {
  name: yup.string().trim().required(ERROR_MESSAGE),
  managerId: yup.number().required(ERROR_MESSAGE),
  vendorContacts: yup
    .array()
    .required(ERROR_MESSAGE)
    .of(
      yup.object({
        type: yup
          .mixed<EVendorContactType>()
          .oneOf(Object.values(contactTypeValues))
          .required(ERROR_MESSAGE),
        ...contactSchema,
      })
    )
    .min(1),
  settlementDates: yup.array().of(yup.string().required()).max(3).nullable(),
  businessName: yup.string().trim().nullable(),
  businessRegistrationNumber: yup.string().nullable(),
  businessRegistrationImageUrl: yup.string().nullable(),
  bankCode: yup.mixed<EBankCode>().oneOf(bankCodeValues).nullable(),
  accountNumber: yup.string().nullable(),
  businessBankBookImagerUrl: yup.string().nullable(),
  accountHolderName: yup.string().trim().nullable(),
  comment: yup.string().trim().optional(),
};

const brandSchema = {
  name: yup.string().trim().required(ERROR_MESSAGE),
  status: yup
    .mixed<EBrandStatus>()
    .oneOf(['ACTIVE', 'INACTIVE'])
    .required(ERROR_MESSAGE),
  brandContacts: yup
    .array()
    .min(1)
    .required(ERROR_MESSAGE)
    .of(
      yup.object({
        type: yup
          .mixed<EBrandContactType>()
          .oneOf(Object.values(contactTypeValues))
          .required(ERROR_MESSAGE),
        ...contactSchema,
      })
    ),
};

export const createVendorSchema = yup
  .object<CreateVendorWithBrandsInput>()
  .shape({
    ...vendorSchema,
    brands: yup
      .array()
      .min(1, '한 개 이상의 브랜드를 필수로 등록해주세요.')
      .required(ERROR_MESSAGE)
      .of(yup.object({ ...brandSchema })),
  });

export const updateVendorSchema = yup.object<UpdateVendorInput>().shape({
  id: yup.number().required(ERROR_MESSAGE),
  ...vendorSchema,
  vendorContacts: yup
    .array()
    .required(ERROR_MESSAGE)
    .of(
      yup.object({
        id: yup.number().optional(),
        type: yup
          .mixed<EVendorContactType>()
          .oneOf(Object.values(contactTypeValues))
          .required(ERROR_MESSAGE),
        ...contactSchema,
      })
    )
    .min(1),
});

export const createBrandSchema = yup.object<CreateBrandInput>().shape({
  vendorId: yup.number().required(ERROR_MESSAGE),
  ...brandSchema,
});

export const updateBrandSchema = yup.object<UpdateBrandInput>().shape({
  id: yup.number().required(ERROR_MESSAGE),
  ...brandSchema,
  brandContacts: yup
    .array()
    .min(1)
    .required(ERROR_MESSAGE)
    .of(
      yup.object({
        id: yup.number().optional(),
        type: yup
          .mixed<EBrandContactType>()
          .oneOf(Object.values(contactTypeValues))
          .required(ERROR_MESSAGE),
        ...contactSchema,
      })
    ),
});
