import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminBrandInSettlementListQueryVariables = Types.Exact<{
  adminBrandId: Types.Scalars['Int']['input'];
}>;


export type AdminBrandInSettlementListQuery = { __typename?: 'Query', adminBrand: { __typename?: 'BrandGraphqlType', id: number, name: string } };


export const AdminBrandInSettlementListDocument = gql`
    query AdminBrandInSettlementList($adminBrandId: Int!) {
  adminBrand(id: $adminBrandId) {
    id
    name
  }
}
    `;

/**
 * __useAdminBrandInSettlementListQuery__
 *
 * To run a query within a React component, call `useAdminBrandInSettlementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBrandInSettlementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBrandInSettlementListQuery({
 *   variables: {
 *      adminBrandId: // value for 'adminBrandId'
 *   },
 * });
 */
export function useAdminBrandInSettlementListQuery(baseOptions: Apollo.QueryHookOptions<AdminBrandInSettlementListQuery, AdminBrandInSettlementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminBrandInSettlementListQuery, AdminBrandInSettlementListQueryVariables>(AdminBrandInSettlementListDocument, options);
      }
export function useAdminBrandInSettlementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminBrandInSettlementListQuery, AdminBrandInSettlementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminBrandInSettlementListQuery, AdminBrandInSettlementListQueryVariables>(AdminBrandInSettlementListDocument, options);
        }
export type AdminBrandInSettlementListQueryHookResult = ReturnType<typeof useAdminBrandInSettlementListQuery>;
export type AdminBrandInSettlementListLazyQueryHookResult = ReturnType<typeof useAdminBrandInSettlementListLazyQuery>;
export type AdminBrandInSettlementListQueryResult = Apollo.QueryResult<AdminBrandInSettlementListQuery, AdminBrandInSettlementListQueryVariables>;