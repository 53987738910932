import { FormProvider } from 'react-hook-form';

import TextField from '../../../common/components/Fields/TextField';
import Modal from '../../../common/components/Modal';
import useSearchFilterModal from '../hooks/useSearchFilterModal';

import BrandAutoCompleteField from './Fields/BrandAutoCompleteField';
import MarketAutoCompleteField from './Fields/MarketAutoCompleteField';
import OrderAmountField from './Fields/OrderAmountField';
import OrderStatusField from './Fields/OrderStatusField';
import ProductGroupAutoCompleteField from './Fields/ProductGroupAutoCompleteField';
import VenderAutoCompleteField from './Fields/VenderAutoCompleteField';

type SearchFilterModalProps = {
  onClose: () => void;
};

export default function SearchFilterModal({ onClose }: SearchFilterModalProps) {
  const { methods, onSubmit } = useSearchFilterModal();
  const { control } = methods;

  const handleOk = onSubmit(onClose);

  return (
    <Modal
      title={'검색설정'}
      okText={'검색'}
      onOk={handleOk}
      onCancel={onClose}
    >
      <FormProvider {...methods}>
        <form className='flex flex-col gap-6 pb-2 pt-4'>
          <TextField
            name='code'
            control={control}
            label={'와이어드 품목주문코드'}
            placeholder='와이어드 품목주문코드 입력'
          />
          <TextField
            name='groupCode'
            control={control}
            label={'와이어드 주문코드'}
            placeholder='와이어드 주문코드 입력'
          />
          <ProductGroupAutoCompleteField
            label='상품명'
            name='productGroupName'
            control={control}
            placeholder={'상품명'}
          />
          <VenderAutoCompleteField
            label='업체명'
            name='vendorName'
            control={control}
            placeholder={'업체명'}
          />
          <BrandAutoCompleteField
            label='브랜드명'
            name='brandName'
            control={control}
            placeholder={'브랜드명'}
          />
          <MarketAutoCompleteField
            label='마켓명'
            name='marketName'
            control={control}
            placeholder={'마켓명'}
          />
          <OrderAmountField />
          <OrderStatusField />
        </form>
      </FormProvider>
    </Modal>
  );
}
