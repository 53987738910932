import { Divider, Table } from 'antd';
import { useMemo } from 'react';

import { AdminSettlementSheetQuery } from '../../../graphql/settlementSheet.generated';
import { SettlementSheetExtraInfoTableDataType } from '../../../types';

import Block from './Block';

function ExtraInfo({
  settlementSheet,
}: {
  settlementSheet: AdminSettlementSheetQuery['adminSettlementSheet'];
}) {
  const dataSource: SettlementSheetExtraInfoTableDataType[] = useMemo(() => {
    const data: SettlementSheetExtraInfoTableDataType[] = [];
    if (settlementSheet.sampleRecords) {
      settlementSheet.sampleRecords.forEach(
        ({ productName, count, settlementAmount }) => {
          settlementAmount &&
            data.push({
              key: data.length,
              type: '샘플비용',
              description: productName,
              count,
              settlementAmount,
            });
        }
      );
    }
    if (
      settlementSheet.normalDeliveryOrderCount &&
      settlementSheet.normalDeliveryFee
    ) {
      data.push({
        key: data.length,
        type: '일반배송비',
        description: '-',
        count: settlementSheet.normalDeliveryOrderCount,
        settlementAmount: settlementSheet.normalDeliveryFee,
      });
    }
    if (
      settlementSheet.regionDeliveryOrderCount &&
      settlementSheet.regionDeliveryFee
    ) {
      data.push({
        key: data.length,
        type: '지역배송비',
        description: '-',
        count: settlementSheet.regionDeliveryOrderCount,
        settlementAmount: settlementSheet.regionDeliveryFee,
      });
    }

    if (
      settlementSheet.refundDeliveryFeeCount &&
      settlementSheet.refundDeliveryFeeAmount
    ) {
      data.push({
        key: data.length,
        type: '반품배송비',
        description: '-',
        count: settlementSheet.refundDeliveryFeeCount,
        settlementAmount: settlementSheet.refundDeliveryFeeAmount,
      });
    }

    if (
      settlementSheet.exchangeDeliveryFeeCount &&
      settlementSheet.exchangeDeliveryFeeAmount
    ) {
      data.push({
        key: data.length,
        type: '교환배송비',
        description: '-',
        count: settlementSheet.exchangeDeliveryFeeCount,
        settlementAmount: settlementSheet.exchangeDeliveryFeeAmount,
      });
    }

    if (settlementSheet.eventRecords) {
      settlementSheet.eventRecords.forEach(
        ({ productName, count, settlementAmount }) => {
          settlementAmount &&
            data.push({
              key: data.length,
              type: '이벤트비용',
              description: productName,
              count,
              settlementAmount,
            });
        }
      );
    }

    if (settlementSheet.etcRecords) {
      settlementSheet.etcRecords.forEach(
        ({ productName, count, settlementAmount }) => {
          settlementAmount &&
            data.push({
              key: data.length,
              type: '기타비용',
              description: productName,
              count,
              settlementAmount,
            });
        }
      );
    }
    return data;
  }, [settlementSheet]);
  if (dataSource.length === 0) return null;
  return (
    <Block>
      <Divider orientation='left' className='mt-0'>
        판매 외 정보
      </Divider>
      <Table size='small' pagination={false} dataSource={dataSource}>
        <Table.Column<SettlementSheetExtraInfoTableDataType>
          title='구분'
          dataIndex={'type'}
        />
        <Table.Column<SettlementSheetExtraInfoTableDataType>
          title='내용'
          dataIndex={'description'}
        />
        <Table.Column<SettlementSheetExtraInfoTableDataType>
          title='수량(건)'
          dataIndex={'count'}
          render={(_, record) => record.count.toLocaleString()}
        />
        <Table.Column<SettlementSheetExtraInfoTableDataType>
          title='정산금액'
          dataIndex={'settlementAmount'}
          render={(_, record) =>
            `${record.settlementAmount.toLocaleString()}원`
          }
        />
      </Table>
    </Block>
  );
}

export default ExtraInfo;
