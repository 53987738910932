import { Button, Typography } from 'antd';
import { ReactNode } from 'react';

type SummaryCardProps = {
  label: string | ReactNode;
  buttonText: string;
  count: number;
  onClick?: () => void;
  className?: string;
};

export default function SummaryCard({
  label,
  count,
  onClick,
  buttonText,
  className = '',
}: SummaryCardProps) {
  return (
    <div
      className={`flex items-center justify-between gap-4 rounded-lg border border-solid border-[#0000000f] p-4 ${className}`}
    >
      <div>
        <Typography.Text>{label}</Typography.Text>
        <Typography.Title level={3} className='m-0'>
          {count}개
        </Typography.Title>
      </div>
      <Button type='primary' onClick={onClick}>
        {buttonText}
      </Button>
    </div>
  );
}
