import { DatePicker, Descriptions, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';

import ManagerSelect from '../../../common/components/Fields/ManagerSelect';
import TextInput from '../../../common/components/Input/TextInput';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { ESellerSettlementType } from '../../../schema/types';
import { SELLER_SETTLEMENT_TYPES } from '../../seller/const';
import { MARKET_STATUS, SEARCH_RANGE_TYPE } from '../const';
import { MarketListSearchParams } from '../types';

const defaultSearchParams: MarketListSearchParams = {
  name: undefined,
  marketEndFilter: undefined,
  marketStartFilter: undefined,
  marketOrProductManagerIds: undefined,
  periodType: undefined,
  startedAt: undefined,
  endedAt: undefined,
  settlementType: undefined,
  statuses: undefined,
  currentPage: undefined,
  pageSize: undefined,
  sortingField: undefined,
  sortingOrder: undefined,
};

function MarketsSearchForm() {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<MarketListSearchParams>();

  const view = searchParams.view || 'table';
  const handleChange = (value: MarketListSearchParams) => {
    setSearchParams({ ...value, currentPage: 1 });
  };

  const handleSearch = debounce(handleChange, 500);

  return (
    <form className='flex flex-col gap-4 bg-white p-6'>
      <Typography.Title level={4} className='m-0'>
        검색
      </Typography.Title>
      <TextInput
        allowClear
        placeholder='마켓명, 업체명, 브랜드명, 상품명, 셀러명 입력'
        style={{ width: 540 }}
        defaultValue={searchParams.searchKeyword}
        onChange={(e) =>
          handleSearch({
            searchKeyword: e.target.value.trim(),
            currentPage: '1',
            ...defaultSearchParams,
            periodType: view === 'calendar' ? 'MARKET_PERIOD' : undefined,
            marketStartFilter:
              view === 'calendar' ? searchParams.marketStartFilter : undefined,
            marketEndFilter:
              view === 'calendar' ? searchParams.marketEndFilter : undefined,
          })
        }
      />
      {!searchParams.searchKeyword && (
        <Descriptions
          bordered
          column={1}
          labelStyle={{ width: '200px' }}
          size='middle'
          contentStyle={{ padding: '8px 24px' }}
        >
          <Descriptions.Item label='기간'>
            <div className={'flex w-[473px] gap-md'}>
              <Select
                allowClear
                className={'basis-[120px]'}
                placeholder={'--선택--'}
                options={
                  view === 'calendar'
                    ? [
                        { label: '마켓등록일', value: 'CREATED_AT' },
                        {
                          label: '정산예정일',
                          value: 'EXPECTED_SETTLEMENT_DATE',
                        },
                      ]
                    : SEARCH_RANGE_TYPE
                }
                value={
                  view === 'calendar' &&
                  searchParams.periodType === 'MARKET_PERIOD'
                    ? null
                    : searchParams.periodType
                }
                onChange={(v) =>
                  handleChange({
                    periodType: v,
                  })
                }
              />

              <div className={'flex flex-1 items-center gap-md'}>
                <DatePicker
                  allowClear
                  className={'basis-[160px]'}
                  value={
                    searchParams.startedAt
                      ? dayjs(searchParams.startedAt)
                      : null
                  }
                  onChange={(v) => {
                    const end = searchParams.endedAt;
                    const startedAt =
                      end && v && dayjs(end).isBefore(v)
                        ? dayjs(end).startOf('date').toISOString()
                        : v?.startOf('date').toISOString() || undefined;
                    handleChange({ startedAt });
                  }}
                />
                <span>~</span>
                <DatePicker
                  allowClear
                  className={'basis-[160px]'}
                  value={
                    searchParams.endedAt ? dayjs(searchParams.endedAt) : null
                  }
                  onChange={(v) => {
                    const start = searchParams.startedAt;
                    const endedAt =
                      start && v && dayjs(start).isAfter(v)
                        ? dayjs(start).endOf('date').toISOString()
                        : v?.endOf('date').toISOString() || undefined;

                    handleChange({ endedAt });
                  }}
                />
              </div>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label='담당자'>
            <ManagerSelect
              className={'w-[473px]'}
              mode='multiple'
              allowClear
              defaultActiveFirstOption={false}
              suffixIcon={null}
              notFoundContent={null}
              value={searchParams.marketOrProductManagerIds?.map((id) => {
                return Number(id);
              })}
              onChange={(v: number[]) =>
                handleChange({
                  marketOrProductManagerIds: v.length > 0 ? v : undefined,
                })
              }
            />
          </Descriptions.Item>

          <Descriptions.Item label='판매유형'>
            <Select
              allowClear
              options={[
                { label: '전체', value: 'ALL' as const },
                ...SELLER_SETTLEMENT_TYPES,
              ]}
              className={'w-[473px]'}
              placeholder={'--선택--'}
              value={searchParams.settlementType || 'ALL'}
              onChange={(v) => {
                const settlementType =
                  v !== 'ALL' ? (v as ESellerSettlementType) : undefined;
                handleChange({ settlementType });
              }}
            />
          </Descriptions.Item>

          <Descriptions.Item label='마켓상태'>
            <Select
              allowClear
              mode='multiple'
              options={MARKET_STATUS}
              className={'w-[473px]'}
              placeholder={'마켓상태 선택'}
              value={searchParams.statuses}
              onChange={(v) =>
                handleChange({
                  statuses: v.length > 0 ? v : undefined,
                })
              }
            />
          </Descriptions.Item>
        </Descriptions>
      )}
    </form>
  );
}

export default MarketsSearchForm;
