import { Badge } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { ROUTES } from '../../../routes/const';
import { useVendorsQuery } from '../graphql/vendor.generated';
import { VendorListSearchParams } from '../types';

export type VendorTableDataType = {
  key: React.Key;
  vendorId: number;
  vendorName: string;
  isActive: boolean;
  manager: string;
  createdAt: string;
  settlementPeriod: string;
  rowSpan: number;
  brandId: number;
  brandName: string;
  isBrandActive: boolean;
};

const sharedOnCell = ({ rowSpan }: VendorTableDataType) => {
  return { rowSpan };
};

function useVendorTable() {
  const columns: ColumnsType<VendorTableDataType> = [
    {
      title: '업체ID',
      key: 'vendorId',
      dataIndex: 'vendorId',
      width: 160,
      onCell: sharedOnCell,
    },
    {
      title: '업체명',
      width: 240,
      dataIndex: 'vendorName',
      key: 'vendorName',
      onCell: sharedOnCell,
      render: (_, record) => (
        <div className='flex items-center gap-4'>
          <Badge status={record.isActive ? 'success' : 'default'} />
          <Link
            className='line-clamp-2'
            to={generatePath(ROUTES.VENDOR_DETAIL.path, {
              vendorId: record.vendorId,
            })}
          >
            {record.vendorName}
          </Link>
        </div>
      ),
    },
    {
      title: '담당자',
      key: 'manager',
      dataIndex: 'manager',
      width: 160,
      onCell: sharedOnCell,
    },
    {
      title: '등록일',
      key: 'createdAt',
      sorter: true,
      dataIndex: 'createdAt',
      width: 160,
      onCell: sharedOnCell,
    },
    {
      title: '정산일자',
      key: 'settlementPeriod',
      dataIndex: 'settlementPeriod',
      width: 160,
      onCell: sharedOnCell,
    },
    {
      title: '브랜드ID',
      key: 'brandId',
      dataIndex: 'brandId',
      width: 160,
    },
    {
      title: '브랜드명',
      key: 'brandName',
      dataIndex: 'brandName',
      width: 240,
      render: (_, record) => (
        <div className='flex items-center gap-4'>
          <Badge status={record.isBrandActive ? 'success' : 'default'} />
          <Link
            className='line-clamp-2'
            to={generatePath(`${ROUTES.VENDOR_DETAIL.path}/?tab=brands`, {
              vendorId: record.vendorId,
            })}
          >
            {record.brandName}
          </Link>
        </div>
      ),
    },
  ];
  const { searchParams } = useRouteSearchParams<VendorListSearchParams>();
  const currentPage = Number(searchParams.currentPage) - 1 || 0;
  const pageSize = Number(searchParams.pageSize) || 50;
  const { data, loading } = useVendorsQuery({
    variables: {
      vendorsInput: {
        managerIds: searchParams.managerIds?.map((id) => Number(id)),
        pagination: {
          skip: currentPage * pageSize,
          take: pageSize,
        },
        period:
          searchParams.startDate || searchParams.endDate
            ? {
                startDate:
                  searchParams.startDate &&
                  dayjs(searchParams.startDate).startOf('day').toISOString(),
                endDate:
                  searchParams.endDate &&
                  dayjs(searchParams.endDate).endOf('day').toISOString(),
                target: 'CREATED_AT',
              }
            : undefined,
        search: {
          vendorNameOrCode: searchParams.vendorNameOrCode?.trim(),
          brandNameOrCode: searchParams.brandNameOrCode?.trim(),
        },
        status: searchParams.status,
        sortings: searchParams.sortingOrder
          ? [{ field: 'CREATED_AT', order: searchParams.sortingOrder }]
          : [{ field: 'UPDATED_AT', order: 'DESC' }],
      },
    },
  });
  const total = data?.adminVendors?.totalCount;
  const dataSources = useMemo(() => {
    const tableData: VendorTableDataType[] = [];
    data?.adminVendors?.vendors.forEach(({ brands, ...vendor }) => {
      brands.forEach((brand, index) => {
        tableData.push({
          key: brand.id,
          vendorId: vendor.id,
          vendorName: vendor.name,
          isActive: vendor.status === 'ACTIVE',
          manager: vendor.manager?.name || '',
          createdAt: dayjs(vendor.createdAt).format('YY-MM-DD'),
          settlementPeriod: vendor.settlementDates
            ? vendor.settlementDates
                .map((date) => date && (date === 'LAST' ? '말일' : `${date}일`))
                .join(' / ')
            : '',
          rowSpan: index === 0 ? brands.length : 0,
          brandId: brand.id,
          brandName: brand.name,
          isBrandActive: brand.status === 'ACTIVE',
        });
      });
    });
    return tableData;
  }, [data]);

  return { columns, dataSources, loading, total };
}

export default useVendorTable;
