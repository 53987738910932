import { Typography } from 'antd';
import { get } from 'lodash-es';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { getErrorStatus } from '../../utils/form';
import { parseNumber } from '../../utils/number';
import NumberInput, { NumberInputProps } from '../Input/NumberInput';

import FieldWithLabel from './FieldWithLabel';
import { FieldProps } from './types';

export type NumberFieldProps<TFieldValues extends FieldValues> =
  NumberInputProps &
    FieldProps & {
      control: Control<TFieldValues>;
      name: Path<TFieldValues>;
      numberType?: 'number' | 'price' | 'percent';
      caption?: string;
      hideErrorMessage?: boolean;
    };

function NumberField<TFieldValues extends FieldValues>({
  name,
  label,
  tooltip,
  required,
  control,
  numberType = 'number',
  formatter,
  parser,
  caption,
  hideErrorMessage,
  ...props
}: NumberFieldProps<TFieldValues>) {
  const isNumber = numberType === 'number';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const error = get(formState.errors, name);
        const errorMessage =
          typeof error?.message === 'string' ? error.message : undefined;

        const status = errorMessage ? getErrorStatus(error) : '';

        const handlePercentAndPriceFormatter = (
          value: string | number | undefined
        ) => {
          if (value) {
            if (numberType === 'price') {
              return Number(value).toLocaleString();
            }

            if (numberType === 'percent') {
              return (Number(value) * 100).toLocaleString();
            }
          }

          return '';
        };

        const handlePercentParser = (value: string | undefined) => {
          if (value) {
            if (numberType === 'price') {
              return Number(parseNumber(value));
            }

            if (numberType === 'percent') {
              return Number(Number(value) / 100).toLocaleString('ko-KR', {
                maximumFractionDigits: 5,
              });
            }
          }

          return '';
        };

        return (
          <FieldWithLabel label={label} required={required} tooltip={tooltip}>
            <NumberInput
              controls={false}
              className={'w-full'}
              formatter={isNumber ? formatter : handlePercentAndPriceFormatter}
              parser={isNumber ? parser : handlePercentParser}
              status={status}
              numberType={numberType}
              {...field}
              {...props}
            />
            {caption && (
              <Typography.Paragraph type='secondary' className='mb-0'>
                {caption}
              </Typography.Paragraph>
            )}
            {!hideErrorMessage && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}

export default NumberField;
