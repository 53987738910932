import { App, Image } from 'antd';
import { useMemo } from 'react';

import Modal from '../../../../common/components/Modal';
import { useFileUpload } from '../../../../common/hooks/useFileUpload';
import { EUploadResourcesPurpose } from '../../../../schema/types';

type ScreenShotModalProps = {
  onClose: () => void;
  clipboardImageFile: File;
  endUpload: (url: string) => void;
  purpose: EUploadResourcesPurpose;
};

export default function ScreenShotModal({
  onClose,
  clipboardImageFile,
  endUpload,
  purpose,
}: ScreenShotModalProps) {
  const { message } = App.useApp();

  const { upload, fetchUploadResources } = useFileUpload({
    purpose,
  });

  const thumbnail = useMemo(
    () => URL.createObjectURL(clipboardImageFile),
    [clipboardImageFile]
  );

  const handleUpload = async () => {
    try {
      const resource = await fetchUploadResources(clipboardImageFile);
      const url = await upload(resource, clipboardImageFile);

      endUpload(url);
    } catch (error) {
      void message.error('파일 업로드를 실패하였습니다.');
    } finally {
      onClose();
    }
  };

  return (
    <Modal
      title='대표이미지 업로드'
      okText='업로드'
      onCancel={onClose}
      onOk={handleUpload}
    >
      <Image src={thumbnail} alt='대표이미지' />
    </Modal>
  );
}
