import { Divider, Table } from 'antd';

import { AdminSettlementSheetQuery } from '../../../graphql/settlementSheet.generated';
import { SettlementSheetPaymentInfoTableDataType } from '../../../types';

import Block from './Block';

function PaymentInfo({
  paymentMethodRecords,
}: {
  paymentMethodRecords: AdminSettlementSheetQuery['adminSettlementSheet']['paymentMethodRecords'];
}) {
  const dataSource: SettlementSheetPaymentInfoTableDataType[] = [];
  const cardPayment = paymentMethodRecords?.find(
    ({ paymentMethod }) => paymentMethod === 'CARD'
  );
  const bankTransferOrVirtualAccountPaymentOrFree =
    paymentMethodRecords?.filter(
      ({ paymentMethod }) =>
        paymentMethod === 'BANK_TRANSFER' ||
        paymentMethod === 'VIRTUAL_ACCOUNT' ||
        paymentMethod === 'FREE'
    );
  const etcPayments = paymentMethodRecords?.filter(
    ({ paymentMethod }) =>
      paymentMethod !== 'CARD' &&
      paymentMethod !== 'BANK_TRANSFER' &&
      paymentMethod !== 'VIRTUAL_ACCOUNT' &&
      paymentMethod !== 'FREE'
  );
  if (cardPayment) {
    dataSource.push({
      key: 'CARD',
      paymentMethod: '신용카드 (3.3%)',
      count: cardPayment.count,
      paymentFeeAmount: cardPayment.paymentFeeAmount,
    });
  }
  if (bankTransferOrVirtualAccountPaymentOrFree?.length) {
    dataSource.push({
      key: 'BANK_TRANSFER_OR_VIRTUAL_ACCOUNT',
      paymentMethod: '가상계좌 / 무통장입금 (수수료 무료)',
      count: bankTransferOrVirtualAccountPaymentOrFree.reduce(
        (acc, cur) => acc + cur.count,
        0
      ),
      paymentFeeAmount: bankTransferOrVirtualAccountPaymentOrFree.reduce(
        (acc, cur) => acc + cur.paymentFeeAmount,
        0
      ),
    });
  }
  if (etcPayments?.length) {
    dataSource.push({
      key: 'ETC',
      paymentMethod: '그 외 (3.3%)',
      count: etcPayments.reduce((acc, cur) => acc + cur.count, 0),
      paymentFeeAmount: etcPayments.reduce(
        (acc, cur) => acc + cur.paymentFeeAmount,
        0
      ),
    });
  }
  if (dataSource.length === 0) return null;
  return (
    <Block>
      <Divider orientation='left' className='mt-0'>
        결제정보
      </Divider>
      <Table
        size='small'
        tableLayout='fixed'
        pagination={false}
        dataSource={dataSource}
        summary={(dataSource) => {
          const total = dataSource.reduce(
            (acc, cur) => ({
              count: acc.count + cur.count,
              paymentFeeAmount: acc.paymentFeeAmount + cur.paymentFeeAmount,
            }),
            { count: 0, paymentFeeAmount: 0 }
          );
          return (
            <Table.Summary>
              <Table.Summary.Row className=' bg-[#fafafa] font-semibold'>
                <Table.Summary.Cell index={0}>총계</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {total.count.toLocaleString()}건
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  {total.paymentFeeAmount.toLocaleString()}원
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      >
        <Table.Column<SettlementSheetPaymentInfoTableDataType>
          title='결제수단'
          dataIndex={'paymentMethod'}
        />
        <Table.Column<SettlementSheetPaymentInfoTableDataType>
          width={370}
          title='주문수'
          dataIndex={'count'}
          render={(_, record) => `${record.count.toLocaleString()}건`}
        />
        <Table.Column<SettlementSheetPaymentInfoTableDataType>
          width={370}
          title='결제수수료'
          dataIndex={'paymentFeeAmount'}
          render={(_, record) =>
            `${record.paymentFeeAmount.toLocaleString()}원`
          }
        />
      </Table>
    </Block>
  );
}

export default PaymentInfo;
