import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { debounce } from 'lodash-es';
import { useCallback } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import {
  ESettlementPeriodFilter,
  ESettlementType,
} from '../../../schema/types';
import {
  SettlementCodeOption,
  SettlementListSearchParams,
  SettlementSalesChannelOption,
  SettlementUnknownAmountOption,
} from '../types';

function useSettlementListSearchForm() {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<SettlementListSearchParams>();

  const handlePeriodChange = useCallback(
    ({
      periodType,
      startedAt,
      endedAt,
    }: {
      periodType?: ESettlementPeriodFilter;
      startedAt?: string;
      endedAt?: string;
    }) => {
      setSearchParams({
        periodType,
        startedAt,
        endedAt,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleSalesChannelChange = useCallback(
    (salesChannel: SettlementSalesChannelOption) => {
      const value = salesChannel === 'ALL' ? undefined : salesChannel;

      setSearchParams({
        salesChannel: value,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleSettlementTypeChange = useCallback(
    (settlementTypes: ESettlementType[]) => {
      setSearchParams({
        settlementTypes,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleMarketChange = useCallback(
    (marketId: number) => {
      setSearchParams({
        marketId,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleSellerChange = useCallback(
    (sellerId: number) => {
      setSearchParams({
        sellerId,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleVendorChange = useCallback(
    (vendorId: number) => {
      setSearchParams({
        vendorId,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleBrandChange = useCallback(
    (brandId: number) => {
      setSearchParams({
        brandId,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleProductGroupChange = useCallback(
    (productGroupId: number) => {
      setSearchParams({
        productGroupId,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleProductChange = useCallback(
    (productId: number) => {
      setSearchParams({
        productId,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleUnknownAmountChange = useCallback(
    (unknownAmountType: SettlementUnknownAmountOption) => {
      setSearchParams({
        unknownAmountType:
          unknownAmountType === 'ALL' ? undefined : unknownAmountType,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleReceiverNameChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setSearchParams({
        receiverName: ev.target.value,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleNoSettlementCheck = useCallback(
    (ev: CheckboxChangeEvent) => {
      const noSettlement = ev.target.checked ? 'true' : undefined;

      setSearchParams({
        noSettlement,
        currentPage: undefined,
      });
    },
    [setSearchParams]
  );

  const handleSettlementCodeChange = debounce(
    ({ code, option }: { code?: string; option: SettlementCodeOption }) => {
      return setSearchParams({
        [option]: code,
        currentPage: undefined,
      });
    },
    200
  );

  return {
    handlePeriodChange,
    handleSalesChannelChange,
    handleSettlementTypeChange,
    handleMarketChange,
    handleSellerChange,
    handleVendorChange,
    handleBrandChange,
    handleProductGroupChange,
    handleProductChange,
    handleUnknownAmountChange,
    handleReceiverNameChange,
    handleNoSettlementCheck,
    handleSettlementCodeChange,
    searchParams,
    setSearchParams,
  };
}

export default useSettlementListSearchForm;
