import { EditOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';

import HistoryModal from '../../../../common/components/History/HistoryModal';
import usePromotionPermission from '../../hooks/usePromotionPermission';
import useReleaseDetail from '../../hooks/useReleaseDetail';
import useUpdateRelease from '../../hooks/useUpdateRelease';
import ExchangeRedeliveyUpdatePromotionModal from '../UpdatePromotionModal/ExchangeRedeliveyUpdatePromotionModal';
import UpdatePromotionModal from '../UpdatePromotionModal/UpdatePromotionModal';

import BasicInformation from './BasicInformation';
import DetailInformation from './DetailInformation';
import ExtraInformation from './ExtraInformation';
import ReceiverInformation from './ReceiverInformation';

function ReleaseDetail() {
  const hasPermission = usePromotionPermission();

  const [updatePromotionModal, setUpdatePromotionModal] = useState(false);
  const [exchangeRedeliveryModal, setExchangeRedeliveryModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const { releaseId, data } = useReleaseDetail();
  const { methods, onSubmit } = useUpdateRelease();
  const {
    formState: { isDirty },
  } = methods;

  if (!data) return null;

  const promotionType = data.adminPromotionRelease.promotion.promotionType;

  return (
    <>
      <FormProvider {...methods}>
        <form className='bg-white p-6' onSubmit={onSubmit}>
          <BasicInformation />
          <Typography.Title level={4} className='m-0 py-4'>
            출고요청 상세정보
          </Typography.Title>
          <ReceiverInformation />
          <DetailInformation />
          <Button
            className='mt-4'
            icon={<EditOutlined />}
            onClick={() =>
              promotionType === 'EXCHANGE_RE_DELIVERY'
                ? setExchangeRedeliveryModal(true)
                : setUpdatePromotionModal(true)
            }
            disabled={
              !hasPermission ||
              data.adminPromotionRelease.promotion.promotionType === 'EVENT'
            }
          >
            상세정보 일괄수정
          </Button>
          <ExtraInformation />
          <div className='mt-6 flex justify-between'>
            <Button onClick={() => setHistoryModal(true)}>히스토리</Button>
            <Button
              htmlType='submit'
              type='primary'
              disabled={!hasPermission || !isDirty}
            >
              저장
            </Button>
          </div>
        </form>
      </FormProvider>
      {updatePromotionModal && (
        <UpdatePromotionModal
          onClose={() => setUpdatePromotionModal(false)}
          promotionRelease={data.adminPromotionRelease}
        />
      )}
      {exchangeRedeliveryModal && (
        <ExchangeRedeliveyUpdatePromotionModal
          onClose={() => setExchangeRedeliveryModal(false)}
          promotionRelease={data.adminPromotionRelease}
        />
      )}
      {!!releaseId && (
        <HistoryModal
          uiType='PROMOTION'
          id={releaseId}
          open={historyModal}
          onClose={() => setHistoryModal(false)}
        />
      )}
    </>
  );
}

export default ReleaseDetail;
