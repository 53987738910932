import { App } from 'antd';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import SelectField from '../../../common/components/Fields/SelectField';
import { EVENT_TYPES_LABEL } from '../../market/const';
import { usePromotionMarketEventsQuery } from '../graphql/markets.generated';
import { PromotionEventFormValues } from '../types';

function MarketEventSelectField() {
  const { message } = App.useApp();
  const { control, watch, resetField } =
    useFormContext<PromotionEventFormValues>();
  const marketId = watch('marketId');

  const { data } = usePromotionMarketEventsQuery({
    variables: { marketId: marketId || 0 },
    skip: !marketId,
  });

  const marketEventsOption = useMemo(() => {
    return data?.adminMarket.events?.map(({ id, count, type }) => ({
      label: (
        <div className='flex items-end gap-1'>
          {typeof count === 'number'
            ? `${EVENT_TYPES_LABEL[type]} / ${count}명`
            : EVENT_TYPES_LABEL[type]}

          <span className='text-[12px] text-gray-300'>이벤트ID: {id}</span>
        </div>
      ),
      value: id,
    }));
  }, [data]);

  useEffect(() => {
    resetField('marketEventId');
  }, [marketId, resetField]);

  useEffect(() => {
    if (marketId && data) {
      if (!data.adminMarket.events?.length) {
        void message.error(
          '마켓에 등록된 이벤트가 없습니다. 마켓관리에서 이벤트를 등록해 주세요.'
        );
      }
    }
  }, [marketId, data, message]);

  return (
    <SelectField
      control={control}
      name='marketEventId'
      options={marketEventsOption}
      placeholder='--이벤트선택--'
    />
  );
}

export default MarketEventSelectField;
