import { Select, Spin } from 'antd';
import { useState } from 'react';

import { EMarketStatus } from '../../../schema/types';
import { MARKET_STATUS } from '../const';
import { getMarketStatusOptions } from '../helper';

interface EditableStatusSelectCellProps {
  initialValue: EMarketStatus | null;
  startedAt: string;
  endedAt: string | null;
  onSubmit: (v: EMarketStatus) => Promise<void>;
}

function EditableStatusSelectCell({
  initialValue,
  startedAt,
  endedAt,
  onSubmit,
}: EditableStatusSelectCellProps) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const label =
    MARKET_STATUS.find((option) => option.value === value)?.label || '';
  const options = getMarketStatusOptions(startedAt, endedAt);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const handleChange = async (v: EMarketStatus) => {
    setValue(v);
    await onSubmit(v);
    toggleEdit();
  };

  if (editing) {
    return (
      <Spin spinning={false} size={'small'}>
        <Select
          open={editing}
          ref={(ref) => {
            ref?.focus();
          }}
          allowClear={false}
          onBlur={toggleEdit}
          onChange={handleChange}
          value={value}
          className={'w-full'}
          options={options}
        />
      </Spin>
    );
  }

  return (
    <div
      className='editable-cell min-h-[30px] rounded-lg px-lg py-sm hover:cursor-pointer'
      style={{ paddingRight: 24 }}
      onClick={toggleEdit}
    >
      {label || '-'}
    </div>
  );
}

export default EditableStatusSelectCell;
