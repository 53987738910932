import { Divider } from 'antd';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import SwitchField from '../../../../common/components/Fields/SwitchField';
import { getDefaultEventValues, getDefaultValues } from '../../helper';
import { PromotionFormValues, PromotionEventFormValues } from '../../types';
import EditableTable from '../EditableTable';
import SellerReceiver from '../SellerReceiver';

function ReceiverInformation() {
  const methods = useFormContext<
    PromotionFormValues | PromotionEventFormValues
  >();
  const { control, watch, reset, getValues } = methods;
  const [promotionType, isSeller] = watch(['promotionType', 'isSeller']);

  useEffect(() => {
    // 셀러토글 변경시 수령자정보, 상세정보 초기화
    const { promotionType, scheduledReleaseAt, note, managerId } = getValues();
    if (promotionType === 'EVENT') {
      reset({
        ...getDefaultEventValues(),
        scheduledReleaseAt,
        note,
        isSeller,
        managerId,
      });
    } else {
      reset({
        ...getDefaultValues({ promotionType }),
        scheduledReleaseAt,
        note,
        isSeller,
        managerId,
      });
    }
  }, [getValues, reset, isSeller]);

  return (
    <div>
      <Divider orientation='left' className='m-0 py-4'>
        수령자정보
      </Divider>
      <SwitchField
        label='셀러'
        control={control}
        name='isSeller'
        disabled={promotionType === 'SAMPLE'}
        size='small'
      />
      {isSeller ? <SellerReceiver /> : <EditableTable />}
    </div>
  );
}

export default ReceiverInformation;
