import { DownloadOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';

import FileDnDUploader from '../../../common/components/FileDnDUploader';
import Modal from '../../../common/components/Modal';
import useExcelHandler from '../hooks/useExcelHandler';

type InvoiceUploadModalProps = {
  onClose: () => void;
};

function InvoiceUploadModal({ onClose }: InvoiceUploadModalProps) {
  const { fileList, setFileList, handleExcelUpload } = useExcelHandler();

  return (
    <Modal
      title='송장일괄업로드'
      onOk={handleExcelUpload}
      onCancel={onClose}
      okButtonProps={{ disabled: !fileList[0] }}
    >
      <div className='my-6'>
        <Button
          download='출고요청_송장업로드_양식.xlsx'
          icon={<DownloadOutlined />}
          href={'/form/promotion_release_excel_upload_form.xlsx'}
        >
          양식 다운로드
        </Button>
        <div className='flex flex-col gap-6'>
          <Typography.Text className='mt-2 text-red-400'>
            *엑셀 업로드 시, 기존 작성내용이 있을경우 덮어쓰게 됩니다.
          </Typography.Text>
          <FileDnDUploader
            fileList={fileList}
            onChange={setFileList}
            accept='.xlsx, .xls'
            maxCount={1}
            description='엑셀파일을 양식에 맞게 업로드해주세요.'
          />
        </div>
      </div>
    </Modal>
  );
}

export default InvoiceUploadModal;
