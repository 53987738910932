import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminOrderGroupByCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type AdminOrderGroupByCodeQuery = { __typename?: 'Query', adminOrderGroupByCode: { __typename?: 'OrderGroupGraphqlType', id: number, ordererName?: string | null, marketId?: number | null, orders: Array<{ __typename?: 'OrderGraphqlType', productGroupId: number }> } };


export const AdminOrderGroupByCodeDocument = gql`
    query AdminOrderGroupByCode($code: String!) {
  adminOrderGroupByCode(code: $code) {
    id
    ordererName
    marketId
    orders {
      productGroupId
    }
  }
}
    `;

/**
 * __useAdminOrderGroupByCodeQuery__
 *
 * To run a query within a React component, call `useAdminOrderGroupByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrderGroupByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrderGroupByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAdminOrderGroupByCodeQuery(baseOptions: Apollo.QueryHookOptions<AdminOrderGroupByCodeQuery, AdminOrderGroupByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOrderGroupByCodeQuery, AdminOrderGroupByCodeQueryVariables>(AdminOrderGroupByCodeDocument, options);
      }
export function useAdminOrderGroupByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOrderGroupByCodeQuery, AdminOrderGroupByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOrderGroupByCodeQuery, AdminOrderGroupByCodeQueryVariables>(AdminOrderGroupByCodeDocument, options);
        }
export type AdminOrderGroupByCodeQueryHookResult = ReturnType<typeof useAdminOrderGroupByCodeQuery>;
export type AdminOrderGroupByCodeLazyQueryHookResult = ReturnType<typeof useAdminOrderGroupByCodeLazyQuery>;
export type AdminOrderGroupByCodeQueryResult = Apollo.QueryResult<AdminOrderGroupByCodeQuery, AdminOrderGroupByCodeQueryVariables>;