import { Layout, Typography } from 'antd';
import { Link, generatePath } from 'react-router-dom';

import { ROUTES } from '../../routes/const';
import LogoutButton from '../components/LogoutButton';
import { useMeQuery } from '../graphql/me.generated';

const { Header } = Layout;

export default function LayoutHeader() {
  const { data: user, loading } = useMeQuery();

  const isLogin = !loading && !!user;

  return (
    <Header className='sticky left-0 top-0 z-20 flex w-screen items-center justify-between'>
      <Link to={'/'}>
        <Typography.Title level={4} className={'m-0 text-white'}>
          WIREDMIN
        </Typography.Title>
      </Link>
      {isLogin && (
        <div className='flex items-center gap-4'>
          <Link
            to={generatePath(ROUTES.OPERATOR_DETAIL.path, {
              operatorId: user.adminMe.operatorId,
            })}
          >
            <Typography.Text className={'text-white'}>
              {user.adminMe?.name} ({user.adminMe?.email})
            </Typography.Text>
          </Link>

          <LogoutButton />
        </div>
      )}
    </Header>
  );
}
