import {
  EMarketChecklistType,
  EMarketEventType,
  EMarketPeriodFilter,
  EMarketStatus,
  EProductGroupDeliveryFeeType,
  EProductGroupReleaseType,
  EPurchaseOrderType,
  ESellingChannel,
  EShippingBearer,
} from '../../schema/types';

export const MARKET_STATUS: { label: string; value: EMarketStatus }[] = [
  { label: '마켓준비', value: 'READY' },
  { label: '마켓중', value: 'ON_SALE' },
  { label: '마켓종료', value: 'ENDED' },
  { label: '마켓취소', value: 'CANCELED' },
  { label: '마켓중지', value: 'SUSPENDED' },
];

export const RELEASE_TYPES: {
  label: string;
  value: EProductGroupReleaseType;
}[] = [
  { label: '당일출고', value: 'TODAY' },
  { label: '익일출고', value: 'NEXT_DAY' },
  { label: '일괄출고', value: 'BULK' },
];

export const EVENT_TYPES: { label: string; value: EMarketEventType }[] = [
  { label: '선착순', value: 'FIRST_SERVED' },
  { label: '랜덤', value: 'RANDOM' },
  { label: '체험단', value: 'TESTER' },
  { label: '구매완료/인증', value: 'CONFIRMED' },
  { label: '추가증정', value: 'FREE_GIFT' },
  { label: '사전', value: 'BEFORE' },
  { label: '기타', value: 'ETC' },
];

export const EVENT_TYPES_LABEL: { [k in EMarketEventType]: string } =
  EVENT_TYPES.reduce(
    (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
    {} as { [k in EMarketEventType]: string }
  );

export const DELIVERY_FEE_TYPES: {
  label: string;
  value: EProductGroupDeliveryFeeType;
}[] = [
  { label: '무료배송', value: 'FREE' },
  { label: '유료배송', value: 'CHARGED' },
  { label: '조건부무료배송', value: 'CONDITIONAL_FREE' },
];

export const SHIPPING_BEARER: { label: string; value: EShippingBearer }[] = [
  { label: '업체', value: 'VENDOR' },
  { label: '셀러', value: 'SELLER' },
  { label: '와이어드', value: 'WIRED' },
  { label: '고객', value: 'CUSTOMER' },
];

export const SELLING_CHANNELS: { label: string; value: ESellingChannel }[] = [
  { label: '케미', value: 'KEMI' },
  { label: '셀러쇼핑몰', value: 'SELLER_SHOP' },
  { label: '기타', value: 'ETC' },
  { label: 'PB몰 - 스마트스토어', value: 'PB_SMART_STORE' },
  { label: 'PB몰 - 아임웹', value: 'PB_IMWEB' },
];

export const SEARCH_RANGE_TYPE: {
  label: string;
  value: EMarketPeriodFilter | 'MARKET_PERIOD';
}[] = [
  { label: '마켓기간', value: 'MARKET_PERIOD' },
  { label: '마켓등록일', value: 'CREATED_AT' },
  { label: '정산예정일', value: 'EXPECTED_SETTLEMENT_DATE' },
];

export const MARKET_STATUS_MUTATION_SUCCESS_MESSAGES: {
  [key in EMarketStatus]: string;
} = {
  READY: '마켓상태가 마켓준비로 변경되었습니다.',
  ON_SALE: '마켓상태가 마켓중으로 변경되었습니다.',
  ENDED: '마켓상태가 마켓종료로 변경되었습니다.',
  SUSPENDED: '마켓상태가 마켓중지로 변경되었습니다.',
  CANCELED: '마켓상태가 마켓취소로 변경되었습니다.',
};

export const PURCHASE_ORDER_TYPES: {
  label: string;
  value: EPurchaseOrderType;
}[] = [
  { label: '발주서 일괄전달', value: 'NEXT_DAY' },
  { label: '발주서 당일전달', value: 'TODAY' },
];

export const MARKET_CHECKLIST_TYPES: {
  label: string;
  value: EMarketChecklistType;
}[] = [
  { value: 'LOWEST_PRICE', label: '최저가 확인' },
  { value: 'PRICE', label: '가격 확인' },
  { value: 'EXPIRATION_DATE', label: '유통기한 확인' },
  { value: 'DETAIL_PAGE', label: '상세페이지 확인' },
  { value: 'STOCK', label: '재고 확인' },
  { value: 'EVENT', label: '이벤트 확인' },
  { value: 'NOTICE', label: '공지사항 확인' },
  { value: 'MARKET_OPEN_FEED', label: '마켓 오픈피드 확인' },
];

export const MARKET_STATUS_COLOR_MAP: {
  [key in EMarketStatus]: string;
} = {
  READY: 'yellow',
  ON_SALE: 'green',
  ENDED: 'geekblue',
  SUSPENDED: 'red',
  CANCELED: 'grey',
};

export const MARKET_PRODUCT_GROUPS_MAX_LENGTH = 5;
export const MARKET_EVENTS_MAX_LENGTH = 5;
export const MARKET_EVENT_PRODUCTS_MAX_LENGTH = 5;

export const TEMP_PRODUCT_PREFIX_ID = 'temp-product-options';

export const MAX_CHECKLIST_MANAGERS_LENGTH = 4;
