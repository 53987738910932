import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import NumberField from '../../../common/components/Fields/NumberField';
import SelectField from '../../../common/components/Fields/SelectField';
import SwitchField from '../../../common/components/Fields/SwitchField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { EProductGroupDeliveryFeeType } from '../../../schema/types';
import { SHIPPING_TYPE } from '../const';
import { ProductGroupFormValues } from '../types';

import LogisticsField from './Fields/LogisticsField';

export default function ProductShippingInformation() {
  const { control, watch, resetField, setValue } =
    useFormContext<ProductGroupFormValues>();
  const deliveryFeeType = watch('deliveryFeeType');
  const isRegionFee = watch('isRegionFee');
  const { hasPermission } = usePermissions('PRODUCT_EDIT');

  useEffect(() => {
    if (!isRegionFee) {
      resetField('jejuIslandFee');
      resetField('additionalFee');
    }
  }, [isRegionFee]);

  const handleDeliveryFeeTypeChange = (type: EProductGroupDeliveryFeeType) => {
    if (type === 'FREE') {
      setValue('deliveryFee', null);
      setValue('freeDeliveryConditionFee', null);
    } else if (type === 'CHARGED') {
      setValue('freeDeliveryConditionFee', null);
    }
  };

  return (
    <div className='flex flex-col gap-6'>
      <SelectField
        options={SHIPPING_TYPE}
        label={'배송비 유형'}
        required={hasPermission}
        className='w-full'
        defaultValue={'FREE'}
        control={control}
        name='deliveryFeeType'
        onChange={handleDeliveryFeeTypeChange}
        disabled={!hasPermission}
        hideErrorMessage
      />
      {deliveryFeeType !== 'FREE' && (
        <div>
          <NumberField
            label={'배송비'}
            control={control}
            name='deliveryFee'
            required={hasPermission}
            numberType='price'
            disabled={!hasPermission}
            hideErrorMessage
          />
          {deliveryFeeType === 'CONDITIONAL_FREE' && (
            <div className='mt-2 flex items-center gap-2'>
              <div className='flex-1'>
                <NumberField
                  control={control}
                  name='freeDeliveryConditionFee'
                  numberType='price'
                  required={hasPermission}
                  disabled={!hasPermission}
                  hideErrorMessage
                />
              </div>
              <span>원 이상 무료배송</span>
            </div>
          )}
        </div>
      )}
      <div className='flex items-center gap-1'>
        <span>지역별 배송비</span>
        <SwitchField
          control={control}
          name='isRegionFee'
          disabled={!hasPermission}
          size='small'
        />
      </div>
      {isRegionFee && (
        <div className='flex gap-2'>
          <NumberField
            label={'제주도 배송비'}
            control={control}
            name='jejuIslandFee'
            required={hasPermission}
            numberType='price'
            disabled={!hasPermission}
            hideErrorMessage
          />
          <NumberField
            label={'도서산간 배송비'}
            control={control}
            name='additionalFee'
            required={hasPermission}
            numberType='price'
            disabled={!hasPermission}
            hideErrorMessage
          />
        </div>
      )}
      <LogisticsField disabled={!hasPermission} />
      <div className='flex gap-2'>
        <NumberField
          placeholder={'내용입력'}
          control={control}
          name='exchangeFee'
          label='단순변심 교환비용 (왕복)'
          numberType='price'
          disabled={!hasPermission}
        />
        <NumberField
          placeholder={'내용입력'}
          control={control}
          name='returnFee'
          label='단순변심 반품비용 (왕복)'
          numberType='price'
          disabled={!hasPermission}
        />
      </div>
    </div>
  );
}
