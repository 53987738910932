import ReleaseDetail from '../domain/promotion-release/components/ReleaseDetail/ReleaseDetail';
import useReleaseDetail from '../domain/promotion-release/hooks/useReleaseDetail';

function PromotionReleaseDetail() {
  const { data, loading } = useReleaseDetail();

  if (loading || !data) return null;

  return <ReleaseDetail />;
}

export default PromotionReleaseDetail;
