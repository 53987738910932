import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommonVendorsQueryVariables = Types.Exact<{
  vendorsInput: Types.VendorsInput;
}>;


export type CommonVendorsQuery = { __typename?: 'Query', adminVendors: { __typename?: 'VendorsPayload', totalCount: number, vendors: Array<{ __typename?: 'VendorGraphqlType', id: number, name: string, brands: Array<{ __typename?: 'BrandGraphqlType', id: number, name: string }> }> } };


export const CommonVendorsDocument = gql`
    query CommonVendors($vendorsInput: VendorsInput!) {
  adminVendors(vendorsInput: $vendorsInput) {
    totalCount
    vendors {
      id
      name
      brands {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCommonVendorsQuery__
 *
 * To run a query within a React component, call `useCommonVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonVendorsQuery({
 *   variables: {
 *      vendorsInput: // value for 'vendorsInput'
 *   },
 * });
 */
export function useCommonVendorsQuery(baseOptions: Apollo.QueryHookOptions<CommonVendorsQuery, CommonVendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommonVendorsQuery, CommonVendorsQueryVariables>(CommonVendorsDocument, options);
      }
export function useCommonVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommonVendorsQuery, CommonVendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommonVendorsQuery, CommonVendorsQueryVariables>(CommonVendorsDocument, options);
        }
export type CommonVendorsQueryHookResult = ReturnType<typeof useCommonVendorsQuery>;
export type CommonVendorsLazyQueryHookResult = ReturnType<typeof useCommonVendorsLazyQuery>;
export type CommonVendorsQueryResult = Apollo.QueryResult<CommonVendorsQuery, CommonVendorsQueryVariables>;