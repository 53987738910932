import { Categories } from '../../types';

import CategorySelect from './CategorySelect';
import UpperCategorySelect from './UpperCategorySelect';
import UppermostCategorySelect from './UppermostCategorySelect';

type SearchCategoriesSelectProps = {
  onChange: (categories: Categories) => void;
  uppermostCategoryId?: number;
  upperCategoryId?: number;
  categoryId?: number;
};

export default function SearchCategoriesSelect({
  onChange,
  uppermostCategoryId,
  upperCategoryId,
  categoryId,
}: SearchCategoriesSelectProps) {
  return (
    <div className='flex gap-2'>
      <UppermostCategorySelect
        className='w-[240px]'
        placeholder={'카테고리 (대)'}
        onChange={(value: number) => {
          onChange({
            uppermostCategoryId: value,
            upperCategoryId: undefined,
            categoryId: undefined,
          });
        }}
        value={uppermostCategoryId}
        allowClear
      />
      <UpperCategorySelect
        className='w-[240px]'
        placeholder={'카테고리 (중)'}
        onChange={(value: number) => {
          onChange({
            uppermostCategoryId,
            upperCategoryId: value,
            categoryId: undefined,
          });
        }}
        uppermostCategoryId={uppermostCategoryId}
        value={upperCategoryId}
        allowClear
      />
      <CategorySelect
        className='w-[240px]'
        placeholder={'카테고리 (소)'}
        onChange={(value: number) => {
          onChange({
            uppermostCategoryId,
            upperCategoryId,
            categoryId: value,
          });
        }}
        upperCategoryId={upperCategoryId}
        value={categoryId}
        allowClear
      />
    </div>
  );
}
