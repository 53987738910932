import { Button } from 'antd';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { usePermissions } from '../../../common/hooks/usePermissions';
import { appendProductMapper } from '../helper/mapper';
import useGetReferencedMarkets from '../hooks/useGetReferencedMarkets';

import ProductListItem from './ProductListItem';
import ProductPriceInformationHeader from './ProductPriceInformationHeader';

export default function ProductPriceInformation() {
  const { hasPermission } = usePermissions('PRODUCT_EDIT');

  const { control } = useFormContext();
  const fieldArray = useFieldArray({
    control,
    name: 'products',
  });

  const { editableProduct } = useGetReferencedMarkets();

  const disabled = !hasPermission || !editableProduct;

  const { append, fields } = fieldArray;

  const handleAppendClick = () => {
    const product = appendProductMapper();
    append(product);
  };

  return (
    <div>
      <ProductPriceInformationHeader disabled={disabled} append={append} />
      <div>
        <div className='mt-6 flex flex-col gap-4'>
          {fields.map((field, index) => {
            return (
              <ProductListItem key={field.id} index={index} {...fieldArray} />
            );
          })}
          <Button block onClick={handleAppendClick} disabled={!hasPermission}>
            + 품목추가
          </Button>
        </div>
      </div>
    </div>
  );
}
