import { Button, Tag } from 'antd';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import CheckboxField from '../../../common/components/Fields/CheckboxField';
import DateField from '../../../common/components/Fields/DateField';
import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import RadioField from '../../../common/components/Fields/RadioField';
import SelectField from '../../../common/components/Fields/SelectField';
import TextNumberField from '../../../common/components/Fields/TextNumberField';
import Modal from '../../../common/components/Modal';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { EPromotionBearerTypeFilter } from '../../../schema/types';
import {
  RELEASE_STATUS_OPTIONS,
  PERIOD_OPTIONS,
  IS_FREE_PROVIDED_SEARCH_FILTER_OPTIONS,
  FILTER_BEARER_TYPES,
} from '../const';
import { PromotionReleaseListSearchParams } from '../types';

type SearchFilterModalProps = {
  onClose: () => void;
};

function SearchFilterModal({ onClose }: SearchFilterModalProps) {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<PromotionReleaseListSearchParams>();
  const methods = useForm<PromotionReleaseListSearchParams>({
    defaultValues: {
      managerIds: searchParams.managerIds?.map((id) => Number(id)),
      receiverPhoneNumber: searchParams.receiverPhoneNumber,
      marketUndesignated: searchParams.marketUndesignated,
      periodType: searchParams.periodType,
      startedAt: searchParams.startedAt,
      endedAt: searchParams.endedAt,
      isFreeProvided: searchParams.isFreeProvided,
      bearerTypes: searchParams.bearerTypes || [],
      promotionReleaseStatuses: searchParams.promotionReleaseStatuses,
    },
  });
  const { control, watch, handleSubmit, setValue } = methods;
  const [isFreeProvided, bearerTypes] = watch([
    'isFreeProvided',
    'bearerTypes',
  ]);

  useEffect(() => {
    if (isFreeProvided === 'false' && !bearerTypes?.length) {
      setValue('bearerTypes', [
        'WIRED',
        'SELLER_POST_PAYMENT',
        'SELLER_PREPAYMENT',
      ]);
    }
  }, [isFreeProvided, setValue, bearerTypes]);

  const handleTag = (value: EPromotionBearerTypeFilter) => {
    if (!bearerTypes) return;
    const isIncludeTag = bearerTypes.includes(value);
    if (isIncludeTag) {
      setValue(
        'bearerTypes',
        bearerTypes.filter((type) => type !== value)
      );
    } else {
      setValue('bearerTypes', [...bearerTypes, value]);
    }
  };
  const onSubmit = handleSubmit((data) => {
    setSearchParams({
      ...data,
      summaryReleaseStatus: undefined,
      summarySampleStatus: undefined,
      currentPage: undefined,
    });
    onClose();
  });

  return (
    <Modal title='검색설정' footer={null} onCancel={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className='grid gap-6 py-4'>
            <FieldWithLabel label='요청자'>
              <ManagerSelectField
                className='w-full'
                mode='multiple'
                control={control}
                name='managerIds'
              />
            </FieldWithLabel>
            <FieldWithLabel label='기간'>
              <div className='grid grid-cols-[auto_1fr_auto_1fr] items-center gap-2'>
                <SelectField
                  control={control}
                  name='periodType'
                  className='w-[120px]'
                  options={PERIOD_OPTIONS}
                />
                <DateField control={control} name='startedAt' /> ~{' '}
                <DateField control={control} name='endedAt' />
              </div>
            </FieldWithLabel>
            <FieldWithLabel label='출고상태'>
              <SelectField
                control={control}
                name='promotionReleaseStatuses'
                className='w-full'
                mode='multiple'
                options={RELEASE_STATUS_OPTIONS}
              />
            </FieldWithLabel>
            <FieldWithLabel label='수령자 연락처'>
              <TextNumberField
                control={control}
                name='receiverPhoneNumber'
                format='###########'
              />
            </FieldWithLabel>
            <FieldWithLabel label='업체 무상 / 업체 유상'>
              <RadioField
                control={control}
                name='isFreeProvided'
                className='grid grid-cols-3 [&>label]:text-center'
                options={IS_FREE_PROVIDED_SEARCH_FILTER_OPTIONS}
                optionType='button'
              />
              {isFreeProvided === 'false' && (
                <div className='mt-2'>
                  <Tag
                    className='cursor-pointer'
                    onClick={() =>
                      setValue('bearerTypes', [
                        'SELLER_POST_PAYMENT',
                        'SELLER_PREPAYMENT',
                        'WIRED',
                      ])
                    }
                  >
                    전체
                  </Tag>
                  {FILTER_BEARER_TYPES.map(({ label, value }) => (
                    <Tag
                      key={value}
                      className='cursor-pointer'
                      color={
                        bearerTypes?.includes(value) ? '#1677ff' : 'default'
                      }
                      onClick={() => handleTag(value)}
                    >
                      {label}
                    </Tag>
                  ))}
                </div>
              )}
            </FieldWithLabel>
            <CheckboxField control={control} name='marketUndesignated'>
              마켓 미지정 모아보기
            </CheckboxField>
          </div>
          <div className='mt-4 flex justify-end gap-2'>
            <Button onClick={onClose}>취소</Button>
            <Button htmlType='submit' type='primary' onClick={onSubmit}>
              검색
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default SearchFilterModal;
