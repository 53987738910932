import { App } from 'antd';
import { useState } from 'react';

import {
  getSettlementsWithSheet,
  getCodeErrorMessage,
  getSettlementWithUnknownAmount,
} from '../helper/settlementList';
import { SettlementListTableDataType } from '../types';

function useSettlementListActions() {
  const { modal } = App.useApp();
  const [addSettlementSheetModalOpen, setAddSettlementSheetModalOpen] =
    useState(false);
  const [createSettlementSheetModalOpen, setCreateSettlementSheetModalOpen] =
    useState(false);

  const unknownAmountModal = (title: string, orderCodes: string) => {
    void modal.error({
      title: title,
      content: (
        <>
          <p>
            금액을 알 수 없는 항목이 있습니다. 금액을 확정한 후 정산서를
            생성해주세요.
          </p>
          <p className='text-[#00000073]'>{orderCodes}</p>
        </>
      ),
    });
  };

  const handleCreateSettlementSheet = (
    settlements: SettlementListTableDataType[]
  ) => {
    // 정산서는 마켓, 업체 각각 하나씩 생성이 가능함
    // 마켓, 업체의 모든 정산서가 있다면 정산서 생성 불가
    const settlementsWithSheet = getSettlementsWithSheet(settlements);

    if (settlementsWithSheet.length) {
      const orderCodes = getCodeErrorMessage(settlementsWithSheet);

      void modal.error({
        title: '정산서 생성',
        content: (
          <>
            <p>이미 생성된 정산서가 있습니다.</p>
            <p className='text-[#00000073]'>{orderCodes}</p>
          </>
        ),
      });
      return;
    }

    const settlementWithUnknownAmount =
      getSettlementWithUnknownAmount(settlements);

    if (settlementWithUnknownAmount.length) {
      const orderCodes = getCodeErrorMessage(settlementWithUnknownAmount);

      unknownAmountModal('정산서 생성', orderCodes);
      return;
    }

    setCreateSettlementSheetModalOpen(true);
  };

  const handleAddSettlementSheet = (
    settlements: SettlementListTableDataType[]
  ) => {
    const settlementsWithSheet = getSettlementsWithSheet(settlements);

    if (settlementsWithSheet.length) {
      const orderCodes = getCodeErrorMessage(settlementsWithSheet);

      void modal.error({
        title: '기존 정산서에 추가',
        content: (
          <>
            <p>이미 정산서가 존재합니다.</p>
            <p className='text-[#00000073]'>{orderCodes}</p>
          </>
        ),
      });
      return;
    }

    const settlementWithUnknownAmount =
      getSettlementWithUnknownAmount(settlements);

    if (settlementWithUnknownAmount.length) {
      const orderCodes = getCodeErrorMessage(settlementWithUnknownAmount);

      unknownAmountModal('기존 정산서에 추가', orderCodes);
      return;
    }

    setAddSettlementSheetModalOpen(true);
  };

  return {
    handleCreateSettlementSheet,
    addSettlementSheetModalOpen,
    setAddSettlementSheetModalOpen,
    createSettlementSheetModalOpen,
    setCreateSettlementSheetModalOpen,
    handleAddSettlementSheet,
  };
}

export default useSettlementListActions;
