import { yupResolver } from '@hookform/resolvers/yup';
import { App } from 'antd';
import { get } from 'lodash-es';
import { FieldErrors, useForm } from 'react-hook-form';

import { useFormError } from '../../../common/hooks/useFormError';
import { CreateVendorWithBrandsInput } from '../../../schema/types';
import { DEFAULT_CONTACT_VALUE } from '../const';
import {
  VendorsDocument,
  useCreateVendorWithBrandsMutation,
} from '../graphql/vendor.generated';
import { createVendorSchema } from '../validator';

function useVendorFormValues() {
  const { message } = App.useApp();
  const [createVendorWithBrands] = useCreateVendorWithBrandsMutation({
    refetchQueries: [VendorsDocument],
  });
  const method = useForm<CreateVendorWithBrandsInput>({
    defaultValues: {
      name: '',
      managerId: undefined,
      vendorContacts: [DEFAULT_CONTACT_VALUE],
      settlementDates: [],
      businessName: '',
      businessRegistrationNumber: '',
      businessRegistrationImageUrl: null,
      bankCode: undefined,
      accountNumber: '',
      businessBankBookImagerUrl: null,
      accountHolderName: '',
      comment: '',
      brands: [],
    },
    resolver: yupResolver<CreateVendorWithBrandsInput>(createVendorSchema),
  });
  const { onInvalid } = useFormError({
    afterInValid: (errors: FieldErrors<CreateVendorWithBrandsInput>) => {
      const businessRegistrationImageUrlError = get(
        errors,
        'businessRegistrationImageUrl'
      );
      const businessBankBookImagerUrlError = get(
        errors,
        'businessBankBookImagerUrl'
      );

      if (businessRegistrationImageUrlError) {
        method.setFocus('businessRegistrationNumber');
      }
      if (businessBankBookImagerUrlError) {
        method.setFocus('businessBankBookImagerUrl');
      }
    },
  });
  const onSubmit = (callback: () => void) =>
    method.handleSubmit(async (data) => {
      await createVendorWithBrands({
        variables: { createVendorWithBrandsInput: data },
        onCompleted() {
          message.success('저장이 완료되었습니다.');
          callback();
        },
        onError() {
          message.error('저장을 실패하였습니다.');
        },
      });
    }, onInvalid);

  return {
    method,
    onSubmit,
  };
}

export default useVendorFormValues;
