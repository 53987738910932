import { SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback } from 'react';

import { useVendorsInSelectFieldLazyQuery } from '../../graphql/vendors.generated';
import AsyncSelect from '../Input/AsyncSelect';

export type SelectFieldProps = SelectProps & {
  initialOption?: { value: number; label: string };
  initialLoad?: boolean;
};

const VendorSelect = forwardRef<BaseSelectRef, SelectFieldProps>(function (
  { initialLoad, ...props },
  ref
) {
  const [fetchVendor] = useVendorsInSelectFieldLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const fetchItems = useCallback(
    async (v: string) => {
      const { data } = await fetchVendor({
        variables: {
          vendorsInput: {
            sortings: [{ field: 'UPDATED_AT', order: 'DESC' }],
            search: { vendorNameOrCode: v },
            pagination: { skip: 0, take: 20 },
          },
        },
      });

      return (data?.adminVendors.vendors || []).map((vendor) => {
        return {
          label: vendor.name,
          value: vendor.id,
          data: vendor,
        };
      });
    },
    [fetchVendor]
  );

  return (
    <AsyncSelect
      ref={ref}
      initialLoad={initialLoad}
      className={'w-full'}
      fetchItems={fetchItems}
      {...props}
    />
  );
});

export default VendorSelect;
