import { LoadingOutlined } from '@ant-design/icons';
import { App, Descriptions, Input, Spin, Tag } from 'antd';
import { GraphQLError } from 'graphql';
import { debounce } from 'lodash-es';
import { ChangeEvent, useCallback, useState } from 'react';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import SuffixIcon from '../../../../common/components/Input/SuffixIcon';
import Modal from '../../../../common/components/Modal';
import { hasDetailErrorCode } from '../../../../common/utils/apollo';
import { SETTLEMENT_SHEET_TYPE_LABEL } from '../../const';
import { useAdminAddSettlementsIntoSheetMutation } from '../../graphql/settlement.generated';
import { AdminSettlementsDocument } from '../../graphql/settlements.generated';
import { useAdminSettlementSheetByCodeLazyQuery } from '../../graphql/settlementSheet.generated';
import { SettlementListTableDataType } from '../../types';

type AddSettlementSheetModalProps = {
  selectedSettlements: SettlementListTableDataType[];
  onClose: () => void;
};

function AddSettlementSheetModal({
  onClose,
  selectedSettlements,
}: AddSettlementSheetModalProps) {
  const { message } = App.useApp();
  const [addSettlementIntoSheetMutation, { loading: mutateLoading }] =
    useAdminAddSettlementsIntoSheetMutation();
  const [code, setCode] = useState('');
  const [fetchSettlementSheet, { data, loading, error }] =
    useAdminSettlementSheetByCodeLazyQuery();
  const handleOk = async () => {
    const sheetId = data?.adminSettlementSheetByCode?.id;
    if (sheetId) {
      await addSettlementIntoSheetMutation({
        variables: {
          input: {
            settlementIds: selectedSettlements.map(({ id }) => id),
            sheetId,
          },
        },
        refetchQueries: [AdminSettlementsDocument],
        onCompleted() {
          void message.success('선택하신 항목을 기존 정산서에서 추가했습니다.');
          onClose();
        },
        onError({ graphQLErrors, message: errorMessage }) {
          const hasSettlementSheetError = hasDetailErrorCode(
            graphQLErrors,
            'SETTLEMENT_SHEET_ALREADY_EXIST'
          );

          if (hasSettlementSheetError) {
            showSettlementSheetErrorModal(hasSettlementSheetError);
            return;
          }

          const hasUnknownAmountSettlementError = hasDetailErrorCode(
            graphQLErrors,
            'UNKNOWN_AMOUNT_SETTLEMENT_EXIST'
          );

          if (hasUnknownAmountSettlementError) {
            showSettlementSheetErrorModal(hasUnknownAmountSettlementError);
            return;
          }

          if (errorMessage) {
            void message.error(errorMessage);
            return;
          }

          void message.error('정산서 생성에 실패했습니다.');
        },
      });
    }
  };

  const showSettlementSheetErrorModal = (error: GraphQLError) => {
    const { extensions, message: errorMessage } = error;
    const orderCodes = (extensions?.orderCodes as number[]) || [];

    let orderCodesText = orderCodes.slice(0, 3).join(', ');

    if (orderCodes.length > 3) {
      orderCodesText += ` 외 ${orderCodes.length - 3}건`;
    }

    void message.error({
      content: (
        <div className='inline-flex flex-col items-start'>
          <p>{errorMessage}</p>
          {orderCodes.length && (
            <p className='text-[#00000073]'>{orderCodesText}</p>
          )}
        </div>
      ),
    });
  };

  const debounceQuery = useCallback(debounce(fetchSettlementSheet, 300), []);
  const handleCode = useCallback(
    async (ev: ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;
      setCode(value);
      if (value.trim()) {
        await debounceQuery({ variables: { code: value } });
      }
    },
    [debounceQuery, setCode]
  );

  const settlementSheetType =
    data?.adminSettlementSheetByCode?.settlementSheetType;
  const tagColor = settlementSheetType === 'MARKET' ? 'magenta' : 'blue';
  const label = settlementSheetType === 'MARKET' ? '마켓명' : '업체명';
  const name =
    settlementSheetType === 'MARKET'
      ? data?.adminSettlementSheetByCode?.marketName
      : data?.adminSettlementSheetByCode?.vendorName;
  return (
    <Modal
      onCancel={onClose}
      title='기존 정산서에 추가'
      okText={'추가'}
      onOk={handleOk}
      okButtonProps={{ loading: mutateLoading }}
    >
      <div className='flex flex-col gap-4 pt-4'>
        <FieldWithLabel label={'정산서코드'} required>
          <Input
            placeholder='정산서코드'
            onChange={handleCode}
            value={code}
            status={code && error ? 'error' : undefined}
            suffix={
              loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                <SuffixIcon
                  status={!code ? undefined : error ? 'error' : 'success'}
                />
              )
            }
          />
          {code && error && (
            <p className={'text-xs text-red-500'}>
              일치하는 정산서가 없습니다. 확인 후 다시 입력해주세요.
            </p>
          )}
        </FieldWithLabel>
        {data?.adminSettlementSheetByCode && (
          <Descriptions
            bordered
            layout='horizontal'
            column={1}
            labelStyle={{ width: 160 }}
          >
            <Descriptions.Item label='정산서코드'>
              {settlementSheetType && (
                <Tag color={tagColor}>
                  {SETTLEMENT_SHEET_TYPE_LABEL[settlementSheetType]}
                </Tag>
              )}
              {data.adminSettlementSheetByCode.code}
            </Descriptions.Item>
            <Descriptions.Item label={label}>{name}</Descriptions.Item>
          </Descriptions>
        )}
      </div>
    </Modal>
  );
}

export default AddSettlementSheetModal;
