import { useApolloClient } from '@apollo/client';
import { App, Input, InputRef, Spin } from 'antd';
import { useEffect, useRef, useState } from 'react';

import {
  extractBadRequestError,
  removeCache,
} from '../../../common/utils/apollo';
import { useUpdateSellerPartialMutation } from '../graphql/sellers.generated';

type EditableKemiUserIdCellProps = {
  id: number;
  initialValue: string;
};

function EditableKemiUserIdCell({
  initialValue,
  id,
}: EditableKemiUserIdCellProps) {
  const { message } = App.useApp();

  const client = useApolloClient();
  const [updateSellerPartial, { loading }] = useUpdateSellerPartialMutation();

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<InputRef>(null);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    if (initialValue === value) {
      toggleEdit();
      return;
    }

    const close = message.loading('처리 중입니다.');

    await updateSellerPartial({
      variables: { updateSellerPartialInput: { id, kemiUserId: value.trim() } },
      onCompleted() {
        close();
        message.success('케미회원아이디가 업데이트되었습니다.');
        toggleEdit();
      },
      onError({ graphQLErrors }) {
        close();

        const noSellerError = extractBadRequestError(graphQLErrors);

        if (noSellerError) {
          message.error(noSellerError.message);

          removeCache(client.cache, {
            id,
            __typename: 'SellerGraphqlType',
          });
        } else {
          message.error('수정을 실패하였습니다.');
        }
      },
    });
  };

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  if (editing) {
    return (
      <Spin spinning={loading} size={'small'}>
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          className={'w-full'}
          allowClear
          maxLength={30}
        />
      </Spin>
    );
  }

  return (
    <div
      className='editable-cell min-h-[30px] rounded-lg px-lg py-sm hover:cursor-pointer'
      style={{ paddingRight: 24 }}
      onClick={toggleEdit}
    >
      {value || '-'}
    </div>
  );
}

export default EditableKemiUserIdCell;
