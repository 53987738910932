import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { some, uniqBy, uniqueId } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import SelectField from '../../../../common/components/Fields/SelectField';
import TextInput from '../../../../common/components/Input/TextInput';
import { TEMP_PRODUCT_PREFIX_ID } from '../../const';
import {
  useAdminMarketEventProductQuery,
  useAdminProductsOptionsQuery,
} from '../../graphql/products.generated';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../../types';

function ProductSelectField({
  eventIndex,
  productIndex,
  editable,
}: {
  eventIndex: number;
  productIndex: number;
  editable?: boolean;
}) {
  const { control, watch, formState, setValue } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();

  const [searchKeyword, setSearchKeyword] = useState('');

  const brandId = watch('brandId');
  const { data } = useAdminProductsOptionsQuery({
    variables: {
      filter: {
        brandId,
        searchKeyword,
      },
      // TODO: 20개로 고정으로 가고 케이스가 생기면 추후 DEVELOP하기
      pagination: {
        take: 20,
        skip: 0,
      },
    },
    fetchPolicy: 'no-cache',
    skip: !brandId,
  });

  const [addedValue, setAddedValue] = useState('');
  const [customProductOptions, setCustomProductOptions] = useState<
    {
      label: string;
      value: string | number;
      data?: { productId?: number | null };
    }[]
  >([]);

  const defaultProduct =
    formState.defaultValues?.events?.[eventIndex]?.products?.[productIndex];
  const product = watch(`events.${eventIndex}.products.${productIndex}`);

  const { data: eventProductData } = useAdminMarketEventProductQuery({
    variables: {
      adminProductId: product.productId ? product.productId : 0,
    },
    skip: !product.productId,
    onCompleted(data) {
      setValue(
        `events.${eventIndex}.products.${productIndex}.useType`,
        data.adminProduct?.useType
      );
    },
  });

  const inHousePurchasePrice =
    eventProductData?.adminProduct?.inHousePurchasePrice || 0;
  const count = product.count;

  const productOptions = useMemo(() => {
    return (
      data?.adminProducts?.edges.map((edge) => {
        return {
          label: edge.node.name,
          value: `${TEMP_PRODUCT_PREFIX_ID}__${edge.node.id}`,
          data: {
            productId: edge.node.id,
          },
        };
      }) || []
    );
  }, [data]);

  const options = useMemo(() => {
    const initialOption =
      typeof defaultProduct?.id === 'number'
        ? [
            {
              label: defaultProduct.name,
              value: defaultProduct.id,
              data: { productId: defaultProduct.productId },
            },
          ]
        : [];

    return uniqBy(
      [
        ...customProductOptions,
        ...uniqBy([...initialOption, ...productOptions], 'data.productId'),
      ],
      'value'
    );
  }, [
    customProductOptions,
    product?.id,
    product.name,
    product?.productId,
    productOptions,
  ]);

  const hasAddedValue = some(
    options,
    (item) => item.label === addedValue.trim()
  );

  const handleCustomOptionAdd = () => {
    const newValue = addedValue.trim();

    if (hasAddedValue || !newValue) {
      return;
    }

    const newId = uniqueId(TEMP_PRODUCT_PREFIX_ID);

    setCustomProductOptions((options) => [
      ...options,
      {
        label: newValue,
        value: newId,
      },
    ]);

    setValue(`events.${eventIndex}.products.${productIndex}.id`, newId, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue(`events.${eventIndex}.products.${productIndex}.name`, newValue, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });
    setValue(`events.${eventIndex}.products.${productIndex}.productId`, null, {
      shouldValidate: true,
      shouldTouch: true,
      shouldDirty: true,
    });

    setAddedValue('');
  };

  useEffect(() => {
    if (inHousePurchasePrice && !product.isFreeProvided) {
      setValue(
        `events.${eventIndex}.products.${productIndex}.inhouseBuyPrice`,
        inHousePurchasePrice * count,
        {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true,
        }
      );
    }
  }, [product.isFreeProvided, inHousePurchasePrice, count]);

  return (
    <SelectField
      required
      showSearch
      disabled={!editable}
      control={control}
      name={`events.${eventIndex}.products.${productIndex}.name`}
      placeholder={'품목선택'}
      options={options}
      onSearch={(v) => {
        setSearchKeyword(v);
      }}
      className={'flex w-full [&_.ant-select-selector]:line-clamp-1'}
      popupClassName={'w-[360px] [&_.ant-select-item]:line-clamp-1'}
      popupMatchSelectWidth={false}
      onChange={(_: string, option) => {
        if (Array.isArray(option)) return;

        const name = (option.label as string) || '';
        const productId = option?.data?.productId as number | null;
        // 이벤트 품목 가격 업데이트
        // option.data가 있다 === 브랜드의 품목으로 추가된 아이템
        // product.isFreeProvided가 true이다 === 업체 유상일 경우에만 이벤트 품목 가격 업데이트
        // 커스텀 생성 품목의 경우 productId를 지워주어야 한다.
        setValue(`events.${eventIndex}.products.${productIndex}.name`, name, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true,
        });
        setValue(
          `events.${eventIndex}.products.${productIndex}.productId`,
          productId,
          { shouldValidate: true, shouldTouch: true, shouldDirty: true }
        );
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <div className={'flex gap-2 p-2'}>
            <TextInput
              disabled={!editable}
              className={'f-1'}
              placeholder='추가할 품목을 입력해주세요.'
              value={addedValue}
              onPressEnter={handleCustomOptionAdd}
              onChange={(e) => setAddedValue(e.target.value)}
            />
            <Button
              type='text'
              disabled={!editable || !addedValue.trim() || hasAddedValue}
              icon={<PlusOutlined />}
              onClick={handleCustomOptionAdd}
            >
              품목 생성
            </Button>
          </div>
        </>
      )}
    />
  );
}

export default ProductSelectField;
