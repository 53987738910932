import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorsQueryVariables = Types.Exact<{
  vendorsInput: Types.VendorsInput;
}>;


export type VendorsQuery = { __typename?: 'Query', adminVendors: { __typename?: 'VendorsPayload', totalCount: number, vendors: Array<{ __typename?: 'VendorGraphqlType', id: number, name: string, createdAt: string, status?: Types.EVendorStatus | null, settlementDates?: Array<string> | null, manager?: { __typename?: 'ManagerGraphqlType', name: string } | null, brands: Array<{ __typename?: 'BrandGraphqlType', id: number, name: string, status: Types.EBrandStatus }> }> } };

export type VendorWithBrandsQueryVariables = Types.Exact<{
  vendorId: Types.Scalars['Int']['input'];
}>;


export type VendorWithBrandsQuery = { __typename?: 'Query', adminVendorWithBrands: { __typename?: 'VendorGraphqlType', id: number, name: string, managerId: number, status?: Types.EVendorStatus | null, settlementDates?: Array<string> | null, businessRegistrationNumber?: string | null, businessRegistrationImageUrl?: string | null, bankCode?: Types.EBankCode | null, accountNumber?: string | null, businessBankBookImagerUrl?: string | null, businessName?: string | null, accountHolderName?: string | null, comment?: string | null, vendorContacts: Array<{ __typename?: 'VendorContactGraphqlType', id: number, type: Types.EVendorContactType, name?: string | null, email?: string | null, phone?: string | null }>, brands: Array<{ __typename?: 'BrandGraphqlType', id: number, name: string, status: Types.EBrandStatus, brandContacts: Array<{ __typename?: 'BrandContactGraphqlType', id: number, type: Types.EBrandContactType, name?: string | null, email?: string | null, phone?: string | null }> }> } };

export type CreateVendorWithBrandsMutationVariables = Types.Exact<{
  createVendorWithBrandsInput: Types.CreateVendorWithBrandsInput;
}>;


export type CreateVendorWithBrandsMutation = { __typename?: 'Mutation', adminCreateVendorWithBrands: { __typename?: 'CreateVendorWithBrandsPayload', vendor: { __typename?: 'VendorGraphqlType', id: number } } };

export type UpdateVendorMutationVariables = Types.Exact<{
  updateVendorInput: Types.UpdateVendorInput;
}>;


export type UpdateVendorMutation = { __typename?: 'Mutation', adminUpdateVendor: { __typename?: 'UpdateVendorPayload', vendor: { __typename?: 'VendorGraphqlType', id: number, name: string, managerId: number, status?: Types.EVendorStatus | null, settlementDates?: Array<string> | null, businessRegistrationNumber?: string | null, businessRegistrationImageUrl?: string | null, bankCode?: Types.EBankCode | null, accountNumber?: string | null, businessBankBookImagerUrl?: string | null, businessName?: string | null, accountHolderName?: string | null, comment?: string | null, vendorContacts: Array<{ __typename?: 'VendorContactGraphqlType', id: number, type: Types.EVendorContactType, name?: string | null, email?: string | null, phone?: string | null }> } } };

export type DeleteVendorMutationVariables = Types.Exact<{
  deleteVendorId: Types.Scalars['Int']['input'];
}>;


export type DeleteVendorMutation = { __typename?: 'Mutation', adminDeleteVendor: { __typename?: 'DeleteVendorPayload', vendorId: number } };


export const VendorsDocument = gql`
    query Vendors($vendorsInput: VendorsInput!) {
  adminVendors(vendorsInput: $vendorsInput) {
    totalCount
    vendors {
      id
      name
      createdAt
      status
      settlementDates
      manager {
        name
      }
      brands {
        id
        name
        status
      }
    }
  }
}
    `;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *      vendorsInput: // value for 'vendorsInput'
 *   },
 * });
 */
export function useVendorsQuery(baseOptions: Apollo.QueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
      }
export function useVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
        }
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = Apollo.QueryResult<VendorsQuery, VendorsQueryVariables>;
export const VendorWithBrandsDocument = gql`
    query VendorWithBrands($vendorId: Int!) {
  adminVendorWithBrands(vendorId: $vendorId) {
    id
    name
    managerId
    vendorContacts {
      id
      type
      name
      email
      phone
    }
    status
    settlementDates
    businessRegistrationNumber
    businessRegistrationImageUrl
    bankCode
    accountNumber
    businessBankBookImagerUrl
    businessName
    accountHolderName
    comment
    brands {
      id
      name
      status
      brandContacts {
        id
        type
        name
        email
        phone
      }
    }
  }
}
    `;

/**
 * __useVendorWithBrandsQuery__
 *
 * To run a query within a React component, call `useVendorWithBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorWithBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorWithBrandsQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useVendorWithBrandsQuery(baseOptions: Apollo.QueryHookOptions<VendorWithBrandsQuery, VendorWithBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorWithBrandsQuery, VendorWithBrandsQueryVariables>(VendorWithBrandsDocument, options);
      }
export function useVendorWithBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorWithBrandsQuery, VendorWithBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorWithBrandsQuery, VendorWithBrandsQueryVariables>(VendorWithBrandsDocument, options);
        }
export type VendorWithBrandsQueryHookResult = ReturnType<typeof useVendorWithBrandsQuery>;
export type VendorWithBrandsLazyQueryHookResult = ReturnType<typeof useVendorWithBrandsLazyQuery>;
export type VendorWithBrandsQueryResult = Apollo.QueryResult<VendorWithBrandsQuery, VendorWithBrandsQueryVariables>;
export const CreateVendorWithBrandsDocument = gql`
    mutation CreateVendorWithBrands($createVendorWithBrandsInput: CreateVendorWithBrandsInput!) {
  adminCreateVendorWithBrands(
    createVendorWithBrandsInput: $createVendorWithBrandsInput
  ) {
    vendor {
      id
    }
  }
}
    `;
export type CreateVendorWithBrandsMutationFn = Apollo.MutationFunction<CreateVendorWithBrandsMutation, CreateVendorWithBrandsMutationVariables>;

/**
 * __useCreateVendorWithBrandsMutation__
 *
 * To run a mutation, you first call `useCreateVendorWithBrandsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorWithBrandsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorWithBrandsMutation, { data, loading, error }] = useCreateVendorWithBrandsMutation({
 *   variables: {
 *      createVendorWithBrandsInput: // value for 'createVendorWithBrandsInput'
 *   },
 * });
 */
export function useCreateVendorWithBrandsMutation(baseOptions?: Apollo.MutationHookOptions<CreateVendorWithBrandsMutation, CreateVendorWithBrandsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVendorWithBrandsMutation, CreateVendorWithBrandsMutationVariables>(CreateVendorWithBrandsDocument, options);
      }
export type CreateVendorWithBrandsMutationHookResult = ReturnType<typeof useCreateVendorWithBrandsMutation>;
export type CreateVendorWithBrandsMutationResult = Apollo.MutationResult<CreateVendorWithBrandsMutation>;
export type CreateVendorWithBrandsMutationOptions = Apollo.BaseMutationOptions<CreateVendorWithBrandsMutation, CreateVendorWithBrandsMutationVariables>;
export const UpdateVendorDocument = gql`
    mutation UpdateVendor($updateVendorInput: UpdateVendorInput!) {
  adminUpdateVendor(updateVendorInput: $updateVendorInput) {
    vendor {
      id
      name
      managerId
      vendorContacts {
        id
        type
        name
        email
        phone
      }
      status
      settlementDates
      businessRegistrationNumber
      businessRegistrationImageUrl
      bankCode
      accountNumber
      businessBankBookImagerUrl
      businessName
      accountHolderName
      comment
    }
  }
}
    `;
export type UpdateVendorMutationFn = Apollo.MutationFunction<UpdateVendorMutation, UpdateVendorMutationVariables>;

/**
 * __useUpdateVendorMutation__
 *
 * To run a mutation, you first call `useUpdateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorMutation, { data, loading, error }] = useUpdateVendorMutation({
 *   variables: {
 *      updateVendorInput: // value for 'updateVendorInput'
 *   },
 * });
 */
export function useUpdateVendorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVendorMutation, UpdateVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVendorMutation, UpdateVendorMutationVariables>(UpdateVendorDocument, options);
      }
export type UpdateVendorMutationHookResult = ReturnType<typeof useUpdateVendorMutation>;
export type UpdateVendorMutationResult = Apollo.MutationResult<UpdateVendorMutation>;
export type UpdateVendorMutationOptions = Apollo.BaseMutationOptions<UpdateVendorMutation, UpdateVendorMutationVariables>;
export const DeleteVendorDocument = gql`
    mutation DeleteVendor($deleteVendorId: Int!) {
  adminDeleteVendor(id: $deleteVendorId) {
    vendorId
  }
}
    `;
export type DeleteVendorMutationFn = Apollo.MutationFunction<DeleteVendorMutation, DeleteVendorMutationVariables>;

/**
 * __useDeleteVendorMutation__
 *
 * To run a mutation, you first call `useDeleteVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVendorMutation, { data, loading, error }] = useDeleteVendorMutation({
 *   variables: {
 *      deleteVendorId: // value for 'deleteVendorId'
 *   },
 * });
 */
export function useDeleteVendorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVendorMutation, DeleteVendorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVendorMutation, DeleteVendorMutationVariables>(DeleteVendorDocument, options);
      }
export type DeleteVendorMutationHookResult = ReturnType<typeof useDeleteVendorMutation>;
export type DeleteVendorMutationResult = Apollo.MutationResult<DeleteVendorMutation>;
export type DeleteVendorMutationOptions = Apollo.BaseMutationOptions<DeleteVendorMutation, DeleteVendorMutationVariables>;