import { EditOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { get } from 'lodash-es';
import { useState, useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import TextField from '../../../../common/components/Fields/TextField';
import { PRODUCT_NAME_MAX_LENGTH } from '../../const';
import { ProductGroupFormValues } from '../../types';

type ProductTitleFieldProps = {
  index: number;
  disabled?: boolean;
  hideErrorMessage?: boolean;
};

export default function ProductTitleField({
  index,
  disabled,
  hideErrorMessage,
}: ProductTitleFieldProps) {
  const { control, trigger, setFocus } =
    useFormContext<ProductGroupFormValues>();

  const { errors } = useFormState({ name: `products.${index}.name` });
  const error = get(errors, `products.${index}.name`);

  const name = useWatch({ control, name: `products.${index}.name` });
  const [nameEditable, setNameEditable] = useState(!name);

  useEffect(() => {
    nameEditable && setFocus(`products.${index}.name`);
  }, [nameEditable]);

  return (
    <div>
      {nameEditable ? (
        <TextField
          className='w-[560px]'
          control={control}
          placeholder='품목명을 입력해주세요.'
          name={`products.${index}.name`}
          onBlur={() => {
            if (error) return;

            if (!name) {
              trigger(`products.${index}.name`);
              return;
            }

            setNameEditable(false);
          }}
          maxLength={PRODUCT_NAME_MAX_LENGTH}
          onPressEnter={(e) => {
            if (error) return;
            e.preventDefault();
            setNameEditable(false);
          }}
          hideErrorMessage={hideErrorMessage}
        />
      ) : (
        <Typography.Title className='m-0 flex items-center gap-sm' level={3}>
          {name}
          {!disabled && (
            <Button
              htmlType='button'
              size='small'
              type='default'
              danger={error}
              icon={<EditOutlined />}
              className='shrink-0'
              onClick={() => {
                setNameEditable(true);
              }}
            />
          )}
        </Typography.Title>
      )}
    </div>
  );
}
