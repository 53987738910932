import { ApolloCache } from '@apollo/client';
import { App } from 'antd';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useMeQuery } from '../../../common/graphql/me.generated';
import { useFormError } from '../../../common/hooks/useFormError';
import { deleteCacheFields } from '../../../common/utils/apollo';
import {
  CreatePromotionInputGraphqlType,
  EPromotionType,
} from '../../../schema/types';
import {
  AdminCountPromotionStatusesDocument,
  AdminPromotionReleasesDocument,
  useAdminCreatePromotionMutation,
} from '../graphql/promotionRelease.generated';
import { getDefaultValues } from '../helper';
import { PromotionFormValues } from '../types';
import { promotionResolver } from '../validator/createPromotionValidator';

function useCreatePromotion({
  promotionType,
  onClose,
}: {
  promotionType: EPromotionType;
  onClose: () => void;
}) {
  const { message, modal } = App.useApp();
  const { data: userData } = useMeQuery();
  const [createPromotionMutation] = useAdminCreatePromotionMutation();
  const { onInvalid } = useFormError();
  const methods = useForm<PromotionFormValues>({
    defaultValues: getDefaultValues({
      promotionType,
    }),
    resolver: promotionResolver,
  });
  const { setValue } = methods;

  useEffect(() => {
    if (userData) {
      setValue('managerId', userData.adminMe.id);
    }
  }, [userData, setValue]);

  const createPromotion = useCallback(
    async (data: PromotionFormValues) => {
      const promotionReleases: CreatePromotionInputGraphqlType['promotionReleases'] =
        data.promotionReleases.map((release) => ({
          ...release,
          note: data.note,
          scheduledReleaseAt: data.scheduledReleaseAt || null,
          sellerId: data.sellerId,
          sellerSettlementInfoId: data.sellerSettlementInfoId,
        }));
      const promotionProducts: CreatePromotionInputGraphqlType['promotionProducts'] =
        data.promotionProducts.map((product) =>
          product.isFreeProvided
            ? {
                isFreeProvided: product.isFreeProvided,
                promotionItems: product.promotionItems,
                setCount: product.setCount,
                customPromotionProductName: product.customPromotionProductName,
                originalProductId:
                  typeof product.originalProductId === 'number'
                    ? product.originalProductId
                    : null,
              }
            : {
                isFreeProvided: product.isFreeProvided,
                promotionItems: product.promotionItems,
                setCount: product.setCount,
                customPromotionProductName: product.customPromotionProductName,
                originalProductId:
                  typeof product.originalProductId === 'number'
                    ? product.originalProductId
                    : null,
                inHouseBuyPrice: product.inHouseBuyPrice,
                isSellerBearing: product.isSellerBearing,
                sellerBearingCost: product.sellerBearingCost,
                isPrepayment: product.isPrepayment,
                isWiredBearing: product.isWiredBearing,
                wiredBearingCost: product.wiredBearingCost,
              }
        );

      await createPromotionMutation({
        variables: {
          promotionData: {
            isDeliveryFee: data.isDeliveryFee,
            deliveryFee: data.isDeliveryFee ? data.deliveryFee : undefined,
            deliveryFeeBearer: data.isDeliveryFee
              ? data.deliveryFeeBearer
              : undefined,
            deliveryFeeSettlementPrice: data.isDeliveryFee
              ? data.deliveryFeeSettlementPrice
              : undefined,
            deliveryFeeSettlementTarget: data.isDeliveryFee
              ? data.deliveryFeeSettlementTarget
              : undefined,
            managerId: data.managerId,
            marketId: data.marketId,
            productGroupId: data.productGroupId,
            promotionType: data.promotionType,
            promotionReleases,
            promotionProducts,
          },
        },
        refetchQueries: [
          AdminPromotionReleasesDocument,
          AdminCountPromotionStatusesDocument,
        ],
        update: (cache: ApolloCache<any>) =>
          deleteCacheFields(cache, ['adminPromotionReleases']),
        onCompleted() {
          void message.success('저장이 완료되었습니다.');
          onClose();
        },
        onError() {
          void message.error('저장을 실패하였습니다.');
        },
      });
    },
    [createPromotionMutation, message, onClose]
  );

  const onSubmit = methods.handleSubmit((data) => {
    void modal.confirm({
      title: '출고요청',
      content: '출고요청을 진행하시겠습니까?',
      onOk: () => createPromotion(data),
    });
  }, onInvalid);

  return { methods, onSubmit };
}

export default useCreatePromotion;
