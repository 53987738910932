import { RadioChangeEvent } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Dayjs } from 'dayjs';
import { debounce } from 'lodash-es';
import { ChangeEvent } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { EVendorStatus } from '../../../schema/types';
import { VendorListSearchParams } from '../types';

import { VendorTableDataType } from './useVendorTable';

function useListSearchFormValues() {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<VendorListSearchParams>();

  const currentPage = searchParams.currentPage
    ? Number(searchParams.currentPage)
    : 1;
  const pageSize = searchParams.pageSize ? Number(searchParams.pageSize) : 50;

  const handleVendor = debounce((ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setSearchParams({ vendorNameOrCode: value, currentPage: undefined });
  }, 500);
  const handleBrand = debounce((ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setSearchParams({ brandNameOrCode: value, currentPage: undefined });
  }, 500);
  const handleManager = (value: string[]) => {
    setSearchParams({ managerIds: value, currentPage: undefined });
  };
  const handleStartDate = (_: Dayjs | null, dateString: string) => {
    setSearchParams({ startDate: dateString, currentPage: undefined });
  };
  const handleEndDate = (_: Dayjs | null, dateString: string) => {
    setSearchParams({ endDate: dateString, currentPage: undefined });
  };
  const handleStatus = (ev: RadioChangeEvent) => {
    const value = ev.target.value as EVendorStatus;
    setSearchParams({ status: value, currentPage: undefined });
  };
  const handlePageChange = (page: number, size: number) => {
    if (size !== pageSize) {
      setSearchParams({ currentPage: 1, pageSize: size });
    } else {
      setSearchParams({ currentPage: page });
    }
  };

  const handleSort = (sorter: SorterResult<VendorTableDataType>) => {
    switch (sorter.order) {
      case 'ascend':
        setSearchParams({ sortingOrder: 'ASC' });
        break;
      case 'descend':
        setSearchParams({ sortingOrder: 'DESC' });
        break;
      default:
        setSearchParams({ sortingOrder: undefined });
    }
  };

  return {
    searchParams,
    currentPage,
    pageSize,

    handleVendor,
    handleBrand,
    handleManager,
    handleStartDate,
    handleEndDate,
    handleStatus,
    handlePageChange,
    handleSort,
  };
}

export default useListSearchFormValues;
