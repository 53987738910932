import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useMemo } from 'react';

import { useDeliveryCompaniesQuery } from '../../../domain/product/graphql/productGroup.generated';

const DeliveryCompanySelect = forwardRef<BaseSelectRef, SelectProps>(
  function (props, ref) {
    const { data } = useDeliveryCompaniesQuery();

    const options = useMemo(
      () =>
        data?.adminDeliveryCompanies.map(({ code, name }) => ({
          value: code,
          label: name,
        })),
      [data?.adminDeliveryCompanies]
    );

    return <Select ref={ref} options={options} {...props} />;
  }
);

export default DeliveryCompanySelect;
