import { toJpeg } from 'html-to-image';
import React, { useState } from 'react';

function useScreenCapture() {
  const captureRef = React.useRef(null);
  const [loading, setLoading] = useState(false);

  async function captureImage() {
    if (captureRef.current === null) {
      return;
    }

    // 화면이 새로그려지는 시간을 기다리기 위해 promise를 사용
    await new Promise((resolve) => {
      resolve(true);
      setLoading(true);
    });

    const dataUrl = await toJpeg(captureRef.current);
    const link = document.createElement('a');
    link.download = 'capture.jpeg';
    link.href = dataUrl;
    link.click();

    setLoading(false);
  }

  return {
    captureImage,
    captureRef,
    loading,
  };
}

export default useScreenCapture;
