import { useApolloClient } from '@apollo/client';
import { App, Button, Modal, Tag } from 'antd';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { usePermissions } from '../../../common/hooks/usePermissions';
import {
  extractBadRequestError,
  removeCache,
} from '../../../common/utils/apollo';
import { ROUTES } from '../../../routes/const';
import {
  ProductGroupQuery,
  useDeleteProductGroupMutation,
} from '../graphql/productGroup.generated';
import useUpdateProductGroupFormValues from '../hooks/useUpdateProductGroupFormValues';

import ProductGroupForm from './ProductGroupForm';
import ProductGroupName from './ProductGroupName';
import ProposalModal from './Proposal/ProposalModal';

type UpdateProductGroupFormProps = {
  productGroup: ProductGroupQuery['adminProductGroup'];
};

export default function UpdateProductGroupForm({
  productGroup,
}: UpdateProductGroupFormProps) {
  const [modal, contextHolder] = Modal.useModal();
  const [proposalOpen, setProposalOpen] = useState(false);
  const { methods, onSubmit } = useUpdateProductGroupFormValues(productGroup);
  const client = useApolloClient();
  const [deleteProductGroup] = useDeleteProductGroupMutation();

  const { message } = App.useApp();
  const { hasPermission } = usePermissions('PRODUCT_EDIT');
  const navigate = useNavigate();

  const params = useParams();
  const productGroupId = Number(params.productGroupId);

  const handleDeleteProductGroup = async () => {
    await deleteProductGroup({
      variables: {
        adminDeleteProductGroupId: productGroupId,
      },
      onCompleted() {
        message.success('삭제되었습니다.');
        navigate(ROUTES.PRODUCTS.path);
      },
      update(cache) {
        removeCache(cache, {
          id: productGroupId,
          __typename: 'ProductGroupGraphqlType',
        });
      },
      onError({ graphQLErrors }) {
        const noProductGroupError = extractBadRequestError(graphQLErrors);

        if (noProductGroupError) {
          message.error(noProductGroupError.message);

          removeCache(client.cache, {
            id: productGroupId,
            __typename: 'ProductGroupGraphqlType',
          });
        } else {
          message.error('삭제를 실패하였습니다.');
        }
      },
    });
  };

  const confirmModal = () => {
    modal.confirm({
      title: '상품삭제',
      content: (
        <p className='break-keep'>
          상품삭제 시, 상품 정보를 되돌릴 수 없습니다.
          <br />
          삭제를 진행하시겠습니까?
        </p>
      ),
      onOk: handleDeleteProductGroup,
      cancelText: '아니오',
      okText: '예',
      maskClosable: true,
    });
  };

  const handleOpenProposal = () => {
    setProposalOpen(true);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className='flex justify-between bg-white p-6 pb-0'>
            <div>
              <Tag className={'mb-sm'}>{`상품ID: ${productGroupId || ''}`}</Tag>
              <ProductGroupName />
            </div>
            <div className='flex gap-2'>
              <Button htmlType='button' onClick={handleOpenProposal}>
                상품제안서
              </Button>
              <Button
                htmlType='button'
                danger
                onClick={confirmModal}
                disabled={!hasPermission}
              >
                상품삭제
              </Button>
            </div>
          </div>
          <ProductGroupForm />
        </form>
      </FormProvider>
      {proposalOpen && (
        <ProposalModal
          productGroupId={productGroupId}
          onClose={() => {
            setProposalOpen(false);
          }}
        />
      )}
      {contextHolder}
    </>
  );
}
