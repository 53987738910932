import { Button, Divider, Table, Typography } from 'antd';
import { useFormContext } from 'react-hook-form';

import NumberField from '../../../common/components/Fields/NumberField';
import SelectField from '../../../common/components/Fields/SelectField';
import TextareaField from '../../../common/components/Fields/TextareaField';
import { RETURN_REASON_OPTIONS } from '../const';
import { CreateClaimFormValues } from '../type';

import WiredOwnDeliveryFaultCheckboxField from './WiredOwnDeliveryFaultCheckboxField';

type EOrderPartialCalimType =
  | 'PARTIAL_RETURN'
  | 'PARTIAL_EXCHANGE'
  | 'PARTIAL_CANCEL';

const CREATE_CLAIM_TITLES: {
  [key in EOrderPartialCalimType]: { title: string; label: string };
} = {
  PARTIAL_RETURN: { title: '부분반품', label: '반품' },
  PARTIAL_EXCHANGE: { title: '부분교환', label: '교환' },
  PARTIAL_CANCEL: { title: '부분취소', label: '취소' },
};

type PartialClaimFormProps = {
  onCancel: () => void;
  submittable: boolean;
  type: 'PARTIAL_RETURN' | 'PARTIAL_EXCHANGE' | 'PARTIAL_CANCEL';
  maxClaimQuantity: number;
};

function PartialClaimForm({
  type,
  onCancel,
  submittable,
  maxClaimQuantity,
}: PartialClaimFormProps) {
  const { control, setValue } = useFormContext<CreateClaimFormValues>();
  const { title, label } = CREATE_CLAIM_TITLES[type];

  return (
    <>
      <div className={'grid gap-6'}>
        <Divider orientation={'left'} className={'m-0'}>
          {`${title}사유`}
        </Divider>
        <div className={'w-[232px]'}>
          <SelectField
            required
            label={`${label}사유`}
            control={control}
            name={'reasonType'}
            options={RETURN_REASON_OPTIONS}
            onChange={(_, option) => {
              if (!Array.isArray(option)) {
                const target = option.data;

                setValue('target', target, {
                  shouldTouch: true,
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }
            }}
          />
          <WiredOwnDeliveryFaultCheckboxField />
        </div>
        <div className={'w-[472px]'}>
          <TextareaField
            control={control}
            name={'reason'}
            label={'상세사유'}
            placeholder={'내용입력'}
          />
        </div>
      </div>
      <div className={'grid gap-6'}>
        <Divider orientation={'left'} className={'m-0'}>
          {`${title} 처리방법`}
        </Divider>
        <div>
          <Typography.Paragraph type={'secondary'} className={'m-0'}>
            {`${title}할 수량을 선택해 주세요.`}
          </Typography.Paragraph>
          <Typography.Paragraph type={'secondary'} className={'mb-2'}>
            {`*${title} 수량은 잔여 주문수량을 초과할 수 없습니다.`}
          </Typography.Paragraph>
          <Table
            rowKey={'index'}
            columns={[
              {
                key: 'maxClaimQuantity',
                dataIndex: 'maxClaimQuantity',
                title: '잔여 주문수량',
              },
              {
                key: 'claimQuantity',
                dataIndex: 'claimQuantity',
                title: `${label}수량`,
                render() {
                  return (
                    <div className={'w-[76px]'}>
                      <NumberField
                        controls
                        hideErrorMessage
                        control={control}
                        // 부분 클레임은 order가 무조건 1개이므로, 0번째만 수정한다.
                        name={'orders.0.claimQuantity'}
                        className={'w-[76px]'}
                      />
                    </div>
                  );
                },
              },
            ]}
            dataSource={[{ maxClaimQuantity, index: 0 }]}
            pagination={false}
          />
        </div>
      </div>
      <div className={'mt-4 flex justify-end gap-2'}>
        <Button onClick={onCancel}>취소</Button>
        <Button type={'primary'} htmlType={'submit'} disabled={!submittable}>
          {`${title}처리`}
        </Button>
      </div>
    </>
  );
}

export default PartialClaimForm;
