import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  ECostBearer,
  EProductUseType,
  EPromotionType,
  ESellerSettlementType,
  ESettlementTarget,
} from '../../../schema/types';
import {
  DELIVERY_FEE_BEARER_OPTIONS,
  DELIVERY_FEE_SETTLEMENT_TARGET,
  PROMOTION_TYPES,
} from '../const';
import { PromotionFormValues, PromotionEventFormValues } from '../types';

const ERROR_MESSAGE = '필수항목이 입력되지 않았습니다.';

const delivery = {
  isDeliveryFee: yup.boolean().required(),
  deliveryFee: yup.number().optional().nullable(),
  deliveryFeeBearer: yup
    .mixed<ECostBearer>()
    .oneOf(DELIVERY_FEE_BEARER_OPTIONS.map(({ value }) => value))
    .when('isDeliveryFee', {
      is: true,
      then: (schema) => schema.required(ERROR_MESSAGE),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  deliveryFeeSettlementPrice: yup.number().optional().nullable(),
  deliveryFeeSettlementTarget: yup
    .mixed<ESettlementTarget>()
    .oneOf(DELIVERY_FEE_SETTLEMENT_TARGET.map(({ value }) => value))
    .when('isDeliveryFee', {
      is: true,
      then: (schema) => schema.required(ERROR_MESSAGE),
      otherwise: (schema) => schema.optional().nullable(),
    }),
};

const extra = {
  // note, scheduledReleaseAt, sellerId, sellerSettlementInfoId -> submit map 처리
  note: yup.string().optional().nullable(),
  scheduledReleaseAt: yup.string().optional().nullable(),
  sellerId: yup.number().when('isSeller', {
    is: true,
    then: (schema) => schema.required(ERROR_MESSAGE),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  sellerSettlementInfoId: yup.number().when('isSeller', {
    is: true,
    then: (schema) => schema.required(ERROR_MESSAGE),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  settlementType: yup
    .mixed<ESellerSettlementType>()
    .oneOf(['BUSINESS', 'FREELANCER'])
    .optional()
    .nullable(),
  // for toggle
  marketUndesignated: yup.boolean().optional().nullable(),
  isSeller: yup.boolean().optional().nullable(),
};

const promotionRelease = {
  receiverAddress: yup.string().trim().required(ERROR_MESSAGE),
  receiverName: yup.string().trim().required(ERROR_MESSAGE),
  receiverPhoneNumber: yup.string().trim().required(ERROR_MESSAGE),
};

const promotionReleases = yup
  .array()
  .of(yup.object(promotionRelease))
  .required()
  .min(1, '수령자정보를 등록해주세요.');

const promotionProduct = {
  useType: yup
    .mixed<EProductUseType>()
    .oneOf(['FIX', 'SELECT'])
    .optional()
    .nullable(),
  setCount: yup.number().required(ERROR_MESSAGE),
  isFreeProvided: yup.boolean().required(),
  originalProductId: yup
    .mixed<string | number>()
    .required('품목을 등록해주세요.'),
  promotionItems: yup
    .array()
    .of(yup.object({ originalItemId: yup.number().required() }))
    .when('useType', ([useType], schema) =>
      useType === 'SELECT'
        ? schema.required().min(1, '옵션을 등록해주세요.')
        : schema.optional().nullable()
    ),
};

export const promotionSchema = yup.object().shape({
  managerId: yup.number().required(ERROR_MESSAGE),
  promotionType: yup
    .mixed<EPromotionType>()
    .oneOf(PROMOTION_TYPES.map(({ value }) => value))
    .required(),
  promotionReleases,
  marketId: yup.number().when('marketUndesignated', {
    is: true,
    then: (schema) => schema.optional().nullable(),
    otherwise: (schema) => schema.required(ERROR_MESSAGE),
  }),
  productGroupId: yup.number().required('상품을 등록해주세요.'),
  promotionProducts: yup
    .array()
    .of(yup.object(promotionProduct))
    .required()
    .min(1, '상품(품목)을 추가해주세요.'),
  ...delivery,
  ...extra,

  orderId: yup.number().optional().nullable(),
  orderCode: yup.string().optional().nullable(),
});

export const promotionEventSchema = yup.object().shape({
  managerId: yup.number().required(),
  promotionType: yup
    .mixed<EPromotionType>()
    .oneOf(PROMOTION_TYPES.map(({ value }) => value))
    .required(),
  promotionReleases,
  marketId: yup.number().required(ERROR_MESSAGE),
  marketEventId: yup.number().required(ERROR_MESSAGE),
  ...extra,
});

export const promotionEventResolver =
  yupResolver<PromotionEventFormValues>(promotionEventSchema);

export const promotionResolver =
  yupResolver<PromotionFormValues>(promotionSchema);
