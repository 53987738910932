import { MessageTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

import SimpleEditor from '../../../../common/components/SimpleEditor';
import {
  EOrderSupportChannelType,
  EOrderSupportRequestType,
} from '../../../../schema/types';
import { orderSupportChannels, orderSupportRequestType } from '../../const';
import { AdminOrderSupportMessagesQuery } from '../../graphql/orderSupport.generated';

type OrderSupportMessageItemProps = {
  supportMessage: AdminOrderSupportMessagesQuery['adminOrderSupportMessages']['edges']['0']['node'];
  channel?: EOrderSupportChannelType;
  requestType?: EOrderSupportRequestType;
  editable: boolean;
  loading: boolean;
  onSubmit: (values: { messageId: number; content: string }) => Promise<void>;
};
function OrderSupportMessageItem({
  supportMessage,
  channel,
  requestType,
  editable,
  onSubmit,
  loading,
}: OrderSupportMessageItemProps) {
  const [editMode, setEdiMode] = useState(false);
  const [content, setContent] = useState(supportMessage.content || '');

  const handleUpdateClick = async () => {
    try {
      await onSubmit({
        messageId: supportMessage.id,
        content: content || '',
      });

      setEdiMode(false);
    } catch (e) {
      setEdiMode(true);
    }
  };

  return (
    <div className={'rounded-lg bg-[#00000005] p-6'}>
      <div className={'mb-1 flex items-center gap-2 py-1'}>
        <div className={'flex h-6 w-6 items-center justify-center'}>
          {!supportMessage.isStatusUpdate && (
            <MessageTwoTone style={{ fontSize: 21 }} />
          )}
          {supportMessage.isStatusUpdate && (
            <CheckCircleTwoTone style={{ fontSize: 21 }} />
          )}
        </div>
        <Typography.Title level={5} className={'m-0'}>
          {supportMessage.title}
          {editable && (
            <a
              onClick={(e) => {
                e.preventDefault();
                setEdiMode((edit) => !edit);
              }}
              className={'px-4 text-sm font-normal'}
            >
              {editMode ? '닫기' : '수정'}
            </a>
          )}
        </Typography.Title>
      </div>
      {editMode && (
        <div className={'pl-8'}>
          <SimpleEditor
            editable
            content={content || ''}
            deps={[editMode]}
            onChange={(v) => setContent(v)}
          />
          <div className={'mt-2 text-right'}>
            <Button
              disabled={supportMessage.content === content}
              loading={loading}
              onClick={handleUpdateClick}
            >
              업데이트
            </Button>
          </div>
        </div>
      )}
      {!editMode && (
        <div className={'pl-8'}>
          <div
            dangerouslySetInnerHTML={{ __html: supportMessage.content || '' }}
            className={'mb-2 [&_p]:min-h-[22px]'}
          />
          <div className={'text-[#00000073]'}>
            <p>
              {channel ? `인입채널 | ${orderSupportChannels[channel]}` : ''}
            </p>
            <p>
              {requestType
                ? `문의유형 | ${orderSupportRequestType[requestType]}`
                : ''}
            </p>
            <p>
              {dayjs(supportMessage.updatedAt).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderSupportMessageItem;
