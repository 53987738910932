import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { AdminUpdatePromotionReleaseMutationVariables } from '../graphql/promotionRelease.generated';

const ERROR_MESSAGE = '필수항목이 입력되지 않았습니다.';

const nonSeller = {
  receiverName: yup.string().trim().required(ERROR_MESSAGE),
  receiverAddress: yup.string().trim().required(ERROR_MESSAGE),
  receiverPhoneNumber: yup.string().trim().required(ERROR_MESSAGE),
  zipCode: yup.string().trim().required(ERROR_MESSAGE),
};

const seller = {
  ...nonSeller,
  sellerId: yup.number().required(ERROR_MESSAGE),
  sellerSettlementInfoId: yup.number().required(ERROR_MESSAGE),
};

export const getReleaseSchema = (isSeller: boolean) =>
  yup.object().shape({
    adminUpdatePromotionReleaseId: yup.number().required(),
    promotionReleaseData: yup
      .object()
      .shape(isSeller ? seller : nonSeller)
      .required(),
  });

export const getUpdateResolver = (isSeller: boolean) =>
  yupResolver<AdminUpdatePromotionReleaseMutationVariables>(
    getReleaseSchema(isSeller)
  );
