import { createContext, useContext, useState } from 'react';

type UpdateFormDirtyContextValue = {
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
};

const UpdateFormDirtyContext =
  createContext<UpdateFormDirtyContextValue | null>(null);

export const UpdateFormDirtyContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <UpdateFormDirtyContext.Provider value={{ isDirty, setIsDirty }}>
      {children}
    </UpdateFormDirtyContext.Provider>
  );
};

export const useUpdateFormDirtyContext = () => {
  const value = useContext(UpdateFormDirtyContext);

  if (!value) throw new Error('context is null');

  return value;
};
