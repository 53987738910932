import { Typography } from 'antd';

import { ProductMarketListModalStatusType } from '../types';

import ProductMarketListEmptyTable from './ProductMarketListEmptyTable';
import ProductMarketListTable from './ProductMarketListTable';
import ProductMarketListTotalAmount from './ProductMarketListTotalAmount';

type ProductMarketListProps = {
  type: ProductMarketListModalStatusType;
  productGroupId: number;
  hasMarket?: boolean;
};

function ProductMarketList({
  type,
  productGroupId,
  hasMarket = true,
}: ProductMarketListProps) {
  return (
    <div className='pb-4'>
      {hasMarket && (
        <>
          <ProductMarketListTotalAmount
            type={type}
            productGroupId={productGroupId}
          />
        </>
      )}
      <div className='mt-2'>
        <Typography.Paragraph strong className='mb-0 p-2'>
          마켓별 상세
        </Typography.Paragraph>
        {hasMarket ? (
          <ProductMarketListTable type={type} productGroupId={productGroupId} />
        ) : (
          <ProductMarketListEmptyTable />
        )}
      </div>
    </div>
  );
}

export default ProductMarketList;
