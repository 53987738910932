import { App, Button, Tabs, TabsProps } from 'antd';
import { flatMapDeep } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import HistoryModal from '../../../common/components/History/HistoryModal';
import { clearLocalProductOptions } from '../graphql/localProductOptions';
import { productGroupSchema } from '../helper/validator';
import { ProductGroupFormValues, ProductGroupTabType } from '../types';

import ProductBasicInformation from './ProductBasicInformation';
import ProductDetailInformation from './ProductDetailInformation';
import ProductGroupSummitButtons from './ProductGroupSummitButtons';
import ProductPriceInformation from './ProductPriceInformation';
import ProductRelationsModal from './ProductRelationsModal';

export default function ProductGroupForm() {
  const { message } = App.useApp();
  const { formState, trigger, getValues } =
    useFormContext<ProductGroupFormValues>();
  const [tabKey, setTabKey] = useState<ProductGroupTabType>('product');
  const [productRelationModalOpen, setProductRelationModalOpen] =
    useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);

  const params = useParams();
  const productGroupId = Number(params.productGroupId);
  const formType = getValues('formType');
  const isUpdate = formType === 'UPDATE';

  const items: TabsProps['items'] = [
    {
      key: 'product',
      label: '상품정보',
      children: (
        <div className='mt-6 flex flex-col gap-6'>
          <ProductBasicInformation />
          <ProductDetailInformation />
        </div>
      ),
    },
    {
      key: 'price',
      label: '가격정보',
      children: (
        <div className='mt-6'>
          <ProductPriceInformation />
        </div>
      ),
      forceRender: true,
    },
  ];

  const productGroupSchemaFields = Object.keys(
    productGroupSchema
  ) as (keyof typeof productGroupSchema)[];

  const getErrorMessages = (errors: any) => {
    const messages = flatMapDeep(errors, (v) => {
      if (!v) {
        return;
      }

      if (Array.isArray(v)) {
        return v.map((e) => getErrorMessages(e));
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
      if (v?.message) return [v.message];

      return getErrorMessages(v);
    }) as string[];

    return messages;
  };

  const handleNextStep = async () => {
    const isClear = await trigger(productGroupSchemaFields, {
      shouldFocus: true,
    });
    const errorMessages = getErrorMessages(formState.errors);

    if (errorMessages?.[0]) {
      message.error(errorMessages[0] || '');
    }

    isClear && setTabKey('price');
  };

  const handleChangeTab = async (key: ProductGroupTabType) => {
    if (isUpdate || key === 'product') {
      setTabKey(key);
    } else {
      await handleNextStep();
    }
  };

  useEffect(() => {
    return () => {
      // 로컬에서 공유하는 옵션 데이터를 삭제(해당 페이지에서만 공유)

      clearLocalProductOptions();
    };
  }, []);

  return (
    <>
      <div className={'bg-white p-xl pb-[114px]'}>
        <Tabs
          items={items}
          className='z-10 bg-white [&_.ant-tabs-nav]:mb-0'
          activeKey={tabKey}
          onChange={(key) => handleChangeTab(key as ProductGroupTabType)}
          tabBarExtraContent={
            isUpdate && {
              right: (
                <Button onClick={() => setProductRelationModalOpen(true)}>
                  재고관리
                </Button>
              ),
            }
          }
        />
      </div>

      <div className='sticky bottom-0 z-[9] flex h-16 justify-between bg-white px-6 py-4 drop-shadow-topside'>
        {isUpdate ? (
          <Button htmlType={'button'} onClick={() => setHistoryModalOpen(true)}>
            변경히스토리
          </Button>
        ) : (
          <div />
        )}
        <ProductGroupSummitButtons tabKey={tabKey} onClick={handleNextStep} />
      </div>
      {isUpdate && productRelationModalOpen && (
        <ProductRelationsModal
          onClose={() => setProductRelationModalOpen(false)}
          productGroupId={productGroupId}
        />
      )}
      {!!productGroupId && (
        <HistoryModal
          uiType='PRODUCT'
          id={productGroupId}
          open={historyModalOpen}
          onClose={() => setHistoryModalOpen(false)}
        />
      )}
    </>
  );
}
