import { useParams } from 'react-router-dom';

import NotFound from '../common/components/404';
import PageLoader from '../common/components/PageLoader';
import UpdateProductGroupForm from '../domain/product/components/UpdateProductGroupForm';
import { useProductGroupQuery } from '../domain/product/graphql/productGroup.generated';

export default function ProductDetail() {
  const params = useParams();
  const productGroupId = Number(params.productGroupId);

  const { data, error, loading } = useProductGroupQuery({
    variables: {
      adminProductGroupId: productGroupId,
    },
  });

  const productGroup = data?.adminProductGroup;

  if (error) {
    return <NotFound subTitle={'상품을 찾을 수 없습니다.'} />;
  }

  if (loading || !productGroup) {
    return <PageLoader />;
  }

  return <UpdateProductGroupForm productGroup={productGroup} />;
}
