import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { AdminMarketInOrderQuery } from '../graphql/markets.generated';

type Product = Exclude<
  AdminMarketInOrderQuery['adminMarket']['events'],
  undefined | null
>[0]['products'][0];

type OrderDetailEventProductTableProps = {
  products: Product[];
};

type ProductTableDataType = {
  key: number;
  isFreeProvided: boolean;
  name: string;
  items: string;
  count: number;
  sellerBearingCost: string;
  wiredBearingCost: string;
  customerBearingCost: string;
};

export default function OrderDetailEventProductTable({
  products,
}: OrderDetailEventProductTableProps) {
  const dataSource = products.map(
    ({
      id,
      isFreeProvided,
      name,
      items,
      count,
      sellerBearingCost,
      wiredBearingCost,
      customerBearingCost,
    }) => ({
      key: id,
      isFreeProvided: isFreeProvided,
      name: name,
      items: items?.map((item) => item.name).join(', ') ?? '-',
      count: count,
      sellerBearingCost: sellerBearingCost
        ? sellerBearingCost.toLocaleString()
        : '-',
      wiredBearingCost: wiredBearingCost
        ? wiredBearingCost.toLocaleString()
        : '-',
      customerBearingCost: customerBearingCost
        ? customerBearingCost.toLocaleString()
        : '-',
    })
  );

  const columns: ColumnsType<ProductTableDataType> = [
    {
      title: '업체 유/무상',
      width: 120,
      render: (_, record) => {
        return <>{record.isFreeProvided ? '무상' : '유상'}</>;
      },
    },
    {
      title: '품목명',
      width: 356,
      render: (_, record) => {
        return <>{record.name}</>;
      },
    },
    {
      title: '옵션명',
      width: 356,
      render: (_, record) => {
        return <>{record.items}</>;
      },
    },
    {
      title: '수량',
      width: 120,
      render: (_, record) => {
        return <>{record.count}</>;
      },
    },
  ];

  return (
    <Table<ProductTableDataType>
      dataSource={dataSource}
      expandable={{
        defaultExpandAllRows: true,
        showExpandColumn: false,
        rowExpandable: ({ isFreeProvided }) => !isFreeProvided,
        expandedRowRender: (record) => (
          <div className='-m-4 pl-[120px]'>
            <Table
              className='[&_.ant-table-thead]:bg-[]] [&_.ant-table-cell]:border-b-0'
              pagination={false}
              dataSource={[
                {
                  key: record.key,
                  name: record.name,
                },
              ]}
            >
              <Table.Column
                title={'셀러부담금액'}
                width={356}
                render={() => record.sellerBearingCost}
              />
              <Table.Column
                title={'와이어드부담금액'}
                width={356}
                render={() => record.wiredBearingCost}
              />
              <Table.Column
                title={'고객부담금액'}
                render={() => record.customerBearingCost}
              />
            </Table>
          </div>
        ),
      }}
      pagination={false}
      columns={columns}
      className='mt-4'
    />
  );
}
