import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { EOrderClaimType } from '../../../schema/types';
import { INACTIVE_CLAIM_STATUSES } from '../const';
import { useAdminOrdersInClaimsQuery } from '../graphql/claims.generated';
import { ClaimListSearchParams } from '../type';

export const useSearchClaimsParams = (activeClaimType: EOrderClaimType) => {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<ClaimListSearchParams>();

  const {
    startedAt,
    endedAt,
    searchKeyword,
    periodType,

    brandName,
    productName,
    vendorName,
    marketName,

    claimAssigneeOperatorIds,
    claimStatus,
    orderStatus,
    salesChannelType,

    activeClaimStatus,
  } = searchParams;

  const page = searchParams.page ? Number(searchParams.page) : 1;
  const size = searchParams.size ? Number(searchParams.size) : 50;
  const hasActiveExchangeReDeliveryPromotionRelease =
    searchParams.hasActiveExchangeReDeliveryPromotionRelease
      ? Number(searchParams.hasActiveExchangeReDeliveryPromotionRelease) === 1
      : undefined;
  const isInactiveStatus = claimStatus
    ? INACTIVE_CLAIM_STATUSES.includes(claimStatus)
    : false;

  const response = useAdminOrdersInClaimsQuery({
    variables: {
      filter: {
        searchKeyword,
        activeClaimType: isInactiveStatus ? undefined : activeClaimType,
        claimType: isInactiveStatus ? activeClaimType : undefined,
        orderTypes: ['PRODUCT'],
        activeClaimStatus,
        statuses: orderStatus ? [orderStatus] : undefined,
        salesChannelTypes:
          salesChannelType === 'PB_SHOP'
            ? ['PB_SHOP', 'PB_SHOP_IMWEB', 'PB_SHOP_SMART_STORE']
            : salesChannelType
            ? [salesChannelType]
            : undefined,

        brandName,
        productName,
        vendorName,
        marketName,

        claimAssigneeOperatorIds: claimAssigneeOperatorIds
          ?.filter((id) => !isNaN(Number(id)))
          .map((id) => Number(id)),
        period: periodType
          ? {
              type: periodType,
              startedAt,
              endedAt,
            }
          : undefined,
        hasActiveExchangeReDeliveryPromotionRelease,
      },
      pagination: {
        skip: size * (page - 1),
        take: size,
      },
      order: {
        claims: {
          updatedAt: 'DESC',
        },
      },
    },
  });

  return {
    setSearchParams,
    response,
    searchParams: {
      startedAt,
      endedAt,
      searchKeyword,
      periodType,
      brandName,
      productName,
      vendorName,
      marketName,
      claimAssigneeOperatorIds,
      claimStatus,
      orderStatus,
      salesChannelType,
      activeClaimStatus,
      page,
      size,
      hasActiveExchangeReDeliveryPromotionRelease,
    },
  };
};
