import { DownOutlined } from '@ant-design/icons';
import {
  Badge,
  Divider,
  Dropdown,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import TextInput from '../../../../common/components/Input/TextInput';
import useRouteSearchParams from '../../../../common/hooks/useRouteSearchParams';
import { SELLER_SETTLEMENT_TYPES } from '../../../seller/const';
import {
  MARKET_STATUS,
  MARKET_STATUS_COLOR_MAP,
  SELLING_CHANNELS,
} from '../../const';
import { AdminMarketPreviewQuery } from '../../graphql/market.generated';
import { MarketListSearchParams } from '../../types';

function PreviewContent({
  market,
}: {
  market: AdminMarketPreviewQuery['adminMarket'];
}) {
  const { searchParams } = useRouteSearchParams<MarketListSearchParams>();

  const expectedSettlementAt = market.expectedSettlementAt || null;
  const isSellerAssigned = market.isSellerAssigned;

  const initialOption = MARKET_STATUS.find(
    (option) => option.value === market?.status
  );

  const label = market.startedAt
    ? `${dayjs(market.startedAt).format('YYYY-MM-DD')} ~ ${
        market.endedAt ? dayjs(market.endedAt).format('YYYY-MM-DD') : '상시'
      }`
    : '-';

  const items = useMemo(() => {
    return (
      market.seller?.links?.map((link) => ({
        label: (
          <a href={link?.url} rel='noopener noreferer' target={'_blank'}>
            {link?.url}
          </a>
        ),
        key: link?.id || 0,
      })) || []
    );
  }, [market.seller]);
  const settlementInfo = useMemo(() => {
    return (
      SELLER_SETTLEMENT_TYPES.find(
        (type) => type.value === market.sellerSettlementInfo?.type
      )?.label || ''
    );
  }, [market.sellerSettlementInfo]);
  const sellingChannel = useMemo(() => {
    return (
      SELLING_CHANNELS.find((type) => type.value === market.sellingChannel)
        ?.label || ''
    );
  }, [market.sellingChannel]);

  return (
    <div>
      <div className={'gep-2 mb-lg flex items-center pr-4'}>
        {market.isSoldOut && (
          <div>
            <Tag color={'error'}>품절</Tag>
          </div>
        )}
        <Link
          to={`/markets/${market.id}`}
          state={searchParams}
          className={'line-clamp-1 text-base font-semibold'}
        >
          {market?.name || ''}
        </Link>
      </div>
      <div className={'flex items-center justify-between'}>
        <div className={'flex items-center gap-md'}>
          <Badge color={MARKET_STATUS_COLOR_MAP[market.status]} />
          <Typography.Text>{initialOption?.label || ''}</Typography.Text>
        </div>
        <Typography.Text
          type={'secondary'}
          className={'text-xs'}
        >{`마켓등록일: ${dayjs(market.createdAt).format(
          'YYYY-MM-DD'
        )}`}</Typography.Text>
      </div>
      <Divider className={'my-4'} />
      <div className={isSellerAssigned ? 'flex gap-2' : 'w-1/2'}>
        <FieldWithLabel label={'담당자'}>
          <TextInput
            disabled
            size={'small'}
            className={
              '[&_input]:text-ellipsis [&_input]:text-xs [&_input]:text-black'
            }
            value={market.manager.name}
          />
        </FieldWithLabel>

        {isSellerAssigned && (
          <>
            <FieldWithLabel label={'판매유형'}>
              <TextInput
                disabled
                size={'small'}
                className={
                  '[&_input]:text-ellipsis [&_input]:text-xs [&_input]:text-black'
                }
                value={settlementInfo}
              />
            </FieldWithLabel>
            <FieldWithLabel label={'판매채널'}>
              <TextInput
                disabled
                size={'small'}
                className={
                  '[&_input]:text-ellipsis [&_input]:text-xs [&_input]:text-black'
                }
                value={sellingChannel}
              />
            </FieldWithLabel>

            <FieldWithLabel label={'셀러링크'}>
              <div className={'w-full text-center'}>
                {items.length > 0 ? (
                  <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    dropdownRender={(menus) => {
                      return (
                        <div className={'max-w-[400px] break-all'}>{menus}</div>
                      );
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>
                        바로가기
                        <DownOutlined />
                      </Space>
                    </a>
                  </Dropdown>
                ) : (
                  '-'
                )}
              </div>
            </FieldWithLabel>
          </>
        )}
      </div>
      <Divider className={'my-4'} />
      <FieldWithLabel label={'마켓기간'}>
        <TextInput
          disabled
          size={'small'}
          className={
            '[&_input]:text-ellipsis [&_input]:text-xs [&_input]:text-black'
          }
          value={label}
        />
      </FieldWithLabel>
      <Divider className={'my-4'} />
      <div className={'flex gap-2'}>
        <FieldWithLabel label={'업체명'}>
          <Tooltip title={market.vendor.name}>
            <div>
              <TextInput
                disabled
                size={'small'}
                className={
                  '[&_input]:text-ellipsis [&_input]:text-xs [&_input]:text-black'
                }
                value={market.vendor.name}
              />
            </div>
          </Tooltip>
        </FieldWithLabel>
        <FieldWithLabel label={'브랜드명'}>
          <Tooltip title={market.brand.name}>
            <div>
              <TextInput
                disabled
                value={market.brand.name}
                size={'small'}
                className={
                  '[&_input]:text-ellipsis [&_input]:text-xs [&_input]:text-black'
                }
              />
            </div>
          </Tooltip>
        </FieldWithLabel>
        <FieldWithLabel label={'상품명'}>
          <Tooltip
            title={market.productGroups.map(({ name }) => name).join(', ')}
          >
            <div>
              <TextInput
                disabled
                size={'small'}
                className={
                  '[&_input]:text-ellipsis [&_input]:text-xs [&_input]:text-black'
                }
                value={market.productGroups.map(({ name }) => name).join(', ')}
              />
            </div>
          </Tooltip>
        </FieldWithLabel>
      </div>
      <Divider className={'my-4'} />
      <FieldWithLabel label={'정산예정일'}>
        <TextInput
          disabled
          size={'small'}
          className={
            '[&_input]:text-ellipsis [&_input]:text-xs [&_input]:text-black'
          }
          value={
            expectedSettlementAt
              ? dayjs(expectedSettlementAt).format('YYYY-MM-DD')
              : '미정'
          }
        />
      </FieldWithLabel>
    </div>
  );
}

export default PreviewContent;
