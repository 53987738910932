import dayjs from 'dayjs';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { SettlementSheetFilterQuery } from '../../../schema/types';
import { SheetListSearchParams } from '../types';

function useSettlementSheetListSearchParams(): SettlementSheetFilterQuery {
  const { searchParams } = useRouteSearchParams<SheetListSearchParams>();
  const periodTarget = searchParams.periodTarget || 'EXPECTED_SETTLEMENT_AT';

  return {
    code: searchParams.code,
    status: searchParams.summaryStatus,
    marketId: searchParams.marketId ? Number(searchParams.marketId) : undefined,
    vendorId: searchParams.vendorId ? Number(searchParams.vendorId) : undefined,
    marketManagerIds:
      searchParams.type === 'VENDOR'
        ? undefined
        : searchParams.managerIds?.map((id) => Number(id)),
    vendorManagerIds:
      searchParams.type === 'VENDOR'
        ? searchParams.managerIds?.map((id) => Number(id))
        : undefined,
    period:
      searchParams.startedAt || searchParams.endedAt
        ? {
            type: periodTarget,
            startedAt: searchParams.startedAt
              ? dayjs(searchParams.startedAt).startOf('date').toISOString()
              : undefined,
            endedAt: searchParams.endedAt
              ? dayjs(searchParams.endedAt).endOf('date').toISOString()
              : undefined,
          }
        : undefined,
    settlementSheetType: searchParams.type || 'MARKET',
  };
}

export default useSettlementSheetListSearchParams;
