import { ArrowRightOutlined } from '@ant-design/icons';
import { App, Button, Spin } from 'antd';
import { valueType } from 'antd/es/statistic/utils';
import { isNull } from 'lodash-es';
import { useCallback, useState } from 'react';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import NumberInput from '../../../../common/components/Input/NumberInput';
import Modal from '../../../../common/components/Modal';
import { parseNumber } from '../../../../common/utils/number';
import {
  AdminSettlementSheetsDocument,
  useAdminPatchSettlementSheetMutation,
} from '../../graphql/settlementSheet.generated';
import useSettlementPermission from '../../hooks/useSettlementPermission';

function VendorFinalSettlementAmountCell({
  settlementSheetId,
  finalSettlementAmount,
  lastChangedAt,
  lastChangedOperatorName,
}: {
  settlementSheetId: number;
  finalSettlementAmount: number;
  lastChangedAt?: string | null;
  lastChangedOperatorName?: string | null;
}) {
  const { message } = App.useApp();
  const hasPermission = useSettlementPermission();
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);
  const [mutate, { loading }] = useAdminPatchSettlementSheetMutation();
  const [_finalSettlementAmount, setFinalSettlementAmount] = useState(
    finalSettlementAmount
  );

  const handleChange = useCallback((value: valueType | null) => {
    if (isNull(value)) {
      setFinalSettlementAmount(0);
    }
    if (typeof value === 'number') {
      setFinalSettlementAmount(value);
    }
  }, []);
  const handleParser = useCallback(
    (value: string | undefined) => (value ? Number(parseNumber(value)) : ''),
    []
  );
  const handleFormatter = useCallback(
    (value: valueType | undefined) =>
      value ? Number(value).toLocaleString() : '',
    []
  );
  const handleOk = useCallback(async () => {
    await mutate({
      variables: {
        adminPatchSettlementSheetId: settlementSheetId,
        input: { adjustmentAmount: _finalSettlementAmount },
      },
      refetchQueries: [AdminSettlementSheetsDocument],
      onCompleted() {
        setOpen(false);
        void message.success('최종정산금액이 수정되었습니다.');
      },
      onError() {
        setFinalSettlementAmount(finalSettlementAmount);
        void message.error('수정실패하였습니다.');
      },
    });
  }, [
    settlementSheetId,
    mutate,
    message,
    finalSettlementAmount,
    _finalSettlementAmount,
  ]);

  return (
    <div className='flex justify-between'>
      <div>{finalSettlementAmount.toLocaleString()}</div>
      {hasPermission && (
        <Button type='text' className='text-[#1677FF]' onClick={handleOpen}>
          수정
        </Button>
      )}
      {open && (
        <Modal title='최종정산금액 수정' onCancel={handleClose} onOk={handleOk}>
          <div className='grid grid-cols-[1fr_64px_1fr]'>
            <FieldWithLabel label='현재금액'>
              <NumberInput
                numberType='price'
                value={finalSettlementAmount}
                parser={handleParser}
                formatter={handleFormatter}
                disabled
              />
            </FieldWithLabel>
            <div className='flex items-end justify-center pb-1'>
              <ArrowRightOutlined className='text-xl text-gray-400' />
            </div>
            <FieldWithLabel required label='수정금액'>
              <Spin spinning={loading}>
                <NumberInput
                  numberType='price'
                  defaultValue={_finalSettlementAmount}
                  onChange={handleChange}
                  parser={handleParser}
                  formatter={handleFormatter}
                />
              </Spin>
            </FieldWithLabel>
          </div>
          {lastChangedAt && (
            <div className='mt-2 text-[#00000073]'>
              마지막 수정: {lastChangedOperatorName} ({lastChangedAt})
            </div>
          )}
        </Modal>
      )}
    </div>
  );
}

export default VendorFinalSettlementAmountCell;
