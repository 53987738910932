import { AxiosResponse } from 'axios';

import { axiosClient } from '../../common/axios/axiosClient';

import { LOGIN_URL, REISSUE_TOKEN_URL } from './url';

export function getUrlWithRedirectUri(url: string) {
  const REDIRECT_URI = `${window.location.origin}/oauth-callback`;
  return `${url}?redirect_uri=${REDIRECT_URI}`;
}

type ReissueSuccessResponse = {
  code: string;
  data: { accessToken: string };
  message: string;
};

class Authorization {
  private _accessToken = '';
  private _accessTokenPromise: Promise<
    AxiosResponse<ReissueSuccessResponse>
  > | null = null;

  get accessToken() {
    return this._accessToken;
  }

  set accessToken(newAccessToken: string) {
    this._accessToken = newAccessToken;
  }

  private async resolveAccessToken() {
    // this._accessTokenInLoading 값이 있을때만 호출합니다.
    const { data } = await this._accessTokenPromise!;
    const newAccessToken = data.data.accessToken;
    return newAccessToken;
  }

  async reissue() {
    try {
      if (this._accessTokenPromise) {
        // reissue가 여러번 실행되는 경우 첫번째 실행의 resolve 값을 반환합니다.
        const token = await this.resolveAccessToken();
        return token;
      }
      // reissue 첫번째 호출시 연속실행 방지를 위해 promise를 보관합니다.

      this._accessTokenPromise =
        axiosClient.get<ReissueSuccessResponse>(REISSUE_TOKEN_URL);
      this.accessToken = await this.resolveAccessToken();
      axiosClient.defaults.headers.common.Authorization = `Bearer ${this.accessToken}`;
      return this.accessToken;
    } catch (err) {
      window.location.href = LOGIN_URL;
    } finally {
      this._accessTokenPromise = null;
    }
  }
}

export const authorization = new Authorization();
