import { Modal as AntdModal, ModalProps } from 'antd';

function Modal(props: ModalProps) {
  return (
    <AntdModal
      open
      width={520}
      style={{
        maxHeight: 'calc(100vh - 200px)',
        borderRadius: 8,
        paddingBottom: 0,
        overflow: 'scroll',
      }}
      {...props}
    />
  );
}

export default Modal;
