import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminMarketsInSettlementListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MarketPageQueryFilter>;
}>;


export type AdminMarketsInSettlementListQuery = { __typename?: 'Query', adminMarkets: { __typename?: 'AdminMarketPageGraphqlType', edges: Array<{ __typename?: 'PaginatedMarketGraphqlTypeEdge', node: { __typename?: 'MarketGraphqlType', name: string, id: number } }> } };

export type AdminMarketsForScheduledSettlementQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MarketPageQueryFilter>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminMarketsForScheduledSettlementQuery = { __typename?: 'Query', adminMarketsForScheduledSettlement: { __typename?: 'AdminMarketPageGraphqlType', totalCount: number, edges: Array<{ __typename?: 'PaginatedMarketGraphqlTypeEdge', node: { __typename?: 'MarketGraphqlType', id: number, name: string, status: Types.EMarketStatus, startedAt: string, endedAt?: string | null, expectedSettlementAt?: string | null, sellingChannel?: Types.ESellingChannel | null, comment?: string | null, isExistSettlementSheet?: boolean | null, manager: { __typename?: 'ManagerGraphqlType', id: number, name: string }, sellerSettlementInfo?: { __typename?: 'SellerSettlementInfoGraphqlType', type: Types.ESellerSettlementType } | null } }> } };

export type AdminCountMarketsForScheduledSettlementQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminCountMarketsForScheduledSettlementQuery = { __typename?: 'Query', adminCountMarketsForScheduledSettlement: { __typename?: 'CountMarketsForScheduledSettlementOutputGraphqlType', count: number } };


export const AdminMarketsInSettlementListDocument = gql`
    query AdminMarketsInSettlementList($filter: MarketPageQueryFilter) {
  adminMarkets(filter: $filter) {
    edges {
      node {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useAdminMarketsInSettlementListQuery__
 *
 * To run a query within a React component, call `useAdminMarketsInSettlementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMarketsInSettlementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMarketsInSettlementListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminMarketsInSettlementListQuery(baseOptions?: Apollo.QueryHookOptions<AdminMarketsInSettlementListQuery, AdminMarketsInSettlementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMarketsInSettlementListQuery, AdminMarketsInSettlementListQueryVariables>(AdminMarketsInSettlementListDocument, options);
      }
export function useAdminMarketsInSettlementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMarketsInSettlementListQuery, AdminMarketsInSettlementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMarketsInSettlementListQuery, AdminMarketsInSettlementListQueryVariables>(AdminMarketsInSettlementListDocument, options);
        }
export type AdminMarketsInSettlementListQueryHookResult = ReturnType<typeof useAdminMarketsInSettlementListQuery>;
export type AdminMarketsInSettlementListLazyQueryHookResult = ReturnType<typeof useAdminMarketsInSettlementListLazyQuery>;
export type AdminMarketsInSettlementListQueryResult = Apollo.QueryResult<AdminMarketsInSettlementListQuery, AdminMarketsInSettlementListQueryVariables>;
export const AdminMarketsForScheduledSettlementDocument = gql`
    query AdminMarketsForScheduledSettlement($filter: MarketPageQueryFilter, $pagination: Pagination) {
  adminMarketsForScheduledSettlement(filter: $filter, pagination: $pagination) {
    totalCount
    edges {
      node {
        id
        name
        manager {
          id
          name
        }
        status
        startedAt
        endedAt
        expectedSettlementAt
        sellingChannel
        comment
        sellerSettlementInfo {
          type
        }
        isExistSettlementSheet
      }
    }
  }
}
    `;

/**
 * __useAdminMarketsForScheduledSettlementQuery__
 *
 * To run a query within a React component, call `useAdminMarketsForScheduledSettlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMarketsForScheduledSettlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMarketsForScheduledSettlementQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminMarketsForScheduledSettlementQuery(baseOptions?: Apollo.QueryHookOptions<AdminMarketsForScheduledSettlementQuery, AdminMarketsForScheduledSettlementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMarketsForScheduledSettlementQuery, AdminMarketsForScheduledSettlementQueryVariables>(AdminMarketsForScheduledSettlementDocument, options);
      }
export function useAdminMarketsForScheduledSettlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMarketsForScheduledSettlementQuery, AdminMarketsForScheduledSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMarketsForScheduledSettlementQuery, AdminMarketsForScheduledSettlementQueryVariables>(AdminMarketsForScheduledSettlementDocument, options);
        }
export type AdminMarketsForScheduledSettlementQueryHookResult = ReturnType<typeof useAdminMarketsForScheduledSettlementQuery>;
export type AdminMarketsForScheduledSettlementLazyQueryHookResult = ReturnType<typeof useAdminMarketsForScheduledSettlementLazyQuery>;
export type AdminMarketsForScheduledSettlementQueryResult = Apollo.QueryResult<AdminMarketsForScheduledSettlementQuery, AdminMarketsForScheduledSettlementQueryVariables>;
export const AdminCountMarketsForScheduledSettlementDocument = gql`
    query AdminCountMarketsForScheduledSettlement {
  adminCountMarketsForScheduledSettlement {
    count
  }
}
    `;

/**
 * __useAdminCountMarketsForScheduledSettlementQuery__
 *
 * To run a query within a React component, call `useAdminCountMarketsForScheduledSettlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountMarketsForScheduledSettlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountMarketsForScheduledSettlementQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminCountMarketsForScheduledSettlementQuery(baseOptions?: Apollo.QueryHookOptions<AdminCountMarketsForScheduledSettlementQuery, AdminCountMarketsForScheduledSettlementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCountMarketsForScheduledSettlementQuery, AdminCountMarketsForScheduledSettlementQueryVariables>(AdminCountMarketsForScheduledSettlementDocument, options);
      }
export function useAdminCountMarketsForScheduledSettlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCountMarketsForScheduledSettlementQuery, AdminCountMarketsForScheduledSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCountMarketsForScheduledSettlementQuery, AdminCountMarketsForScheduledSettlementQueryVariables>(AdminCountMarketsForScheduledSettlementDocument, options);
        }
export type AdminCountMarketsForScheduledSettlementQueryHookResult = ReturnType<typeof useAdminCountMarketsForScheduledSettlementQuery>;
export type AdminCountMarketsForScheduledSettlementLazyQueryHookResult = ReturnType<typeof useAdminCountMarketsForScheduledSettlementLazyQuery>;
export type AdminCountMarketsForScheduledSettlementQueryResult = Apollo.QueryResult<AdminCountMarketsForScheduledSettlementQuery, AdminCountMarketsForScheduledSettlementQueryVariables>;