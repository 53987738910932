import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  CreateOrderGroupDepositGraphqlType,
  EOrderGroupDepositChargeDetail,
  EOrderGroupDepositSettlementTarget,
} from '../../../schema/types';

const ERROR_MESSAGE = '필수항목이 입력되지 않았습니다.';

const depositSchema = yup.object().shape({
  comment: yup.string().optional().nullable(),
  settlementTargetName: yup.string().optional().nullable(),
  settlementTarget: yup
    .mixed<EOrderGroupDepositSettlementTarget>()
    .oneOf(['SELLER', 'VENDOR', 'WIRED'])
    .required(ERROR_MESSAGE),
  depositorName: yup.string().required(ERROR_MESSAGE).trim(),
  depositAmount: yup
    .number()
    .moreThan(0, '0원 이상 입력해주세요.')
    .required(ERROR_MESSAGE),
  chargeDetail: yup
    .mixed<EOrderGroupDepositChargeDetail>()
    .oneOf(['EXCHANGE_DELIVERY_FEE', 'RETURN_DELIVERY_FEE'])
    .required(ERROR_MESSAGE),
  groupId: yup.number().required(),
});

export const depositResolver =
  yupResolver<CreateOrderGroupDepositGraphqlType>(depositSchema);
