import { ApolloError } from '@apollo/client';
import { App } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { deleteCacheFields } from '../../../common/utils/apollo';
import { EOrderClaimStatus, EOrderClaimType } from '../../../schema/types';
import { CLAIM_STATUS, shouldDeleteQueriesAfterClaimUpdate } from '../const';
import { useAdminOrderGroupLazyQuery } from '../graphql/createClaim.generated';
import { useAdminPatchOrderClaimStatusMutation } from '../graphql/updateClaim.generated';
import { getStatusUpdateFailMessage } from '../helper/orderClaim';
import { getUpdateClaimSelectOptions } from '../helper/updateStatus';
import { OrderForClaimStatus } from '../type';

type ClaimValue = {
  id: number;
  claimStatus: EOrderClaimStatus;
  claimType: EOrderClaimType;
};

export const useUpdateClaim = (order: OrderForClaimStatus) => {
  const { message, modal } = App.useApp();

  const [fetchOrderGroup, { data, loading }] = useAdminOrderGroupLazyQuery({
    variables: { adminOrderGroupId: order.orderGroupId },
  });
  const [status, setStatus] = useState(order.activeClaim?.status || null);
  const [targetClaim, setTargetClaim] = useState<
    [EOrderClaimType, EOrderClaimStatus] | null
  >(null);
  const [modalType, setModalType] = useState<
    'update' | 'completed' | 'promotion-release' | null
  >(null);

  const [mutate] = useAdminPatchOrderClaimStatusMutation();

  const adminOrderGroup = data?.adminOrderGroup;
  const currentOrder = data?.adminOrderGroup.orders.find(
    (groupOrder) => groupOrder.id === order.orderId
  );

  const options = useMemo(() => {
    if (!order.activeClaim) return [];

    const type = order.activeClaim.claimType;
    const status = order.activeClaim.status;

    const targets = getUpdateClaimSelectOptions(order);
    const current = {
      label: CLAIM_STATUS[status],
      value: status,
      data: { claimStatus: status, claimType: type },
    };

    return [current, ...targets];
  }, [order]);

  const updateStatus = ({ id, claimStatus, claimType }: ClaimValue) => {
    return new Promise<void | ApolloError>((resolve, reject) => {
      void mutate({
        variables: {
          orderClaimData: {
            status: claimStatus,
            claimType,
            id,
            orderId: order.orderId,
          },
          salesChannelType: order.salesChannelType,
        },
        onError(e) {
          reject(e);
        },
        onCompleted() {
          message.success(`${CLAIM_STATUS[claimStatus]} 처리 완료되었습니다.`);
          resolve();
        },
        update(cache) {
          deleteCacheFields(cache, shouldDeleteQueriesAfterClaimUpdate);
        },
      });
    });
  };

  const handleWithPromotionReleaseClaimChange = async (
    claimStatus: EOrderClaimStatus,
    claimType: EOrderClaimType
  ) => {
    const activeClaim = order.activeClaim;

    if (!activeClaim) {
      return;
    }

    switch (claimStatus) {
      case 'EXCHANGE_CANCELED':
      case 'RETURN_RECEIPT':
        await modal.warning({
          content: `${CLAIM_STATUS[claimStatus]}로 상태 변경 시 출고요청건이 요청철회됩니다. 상태를 변경하시겠어요?`,
          centered: true,
          okText: '네',
          cancelText: '아니오',
          okCancel: true,
          async onOk() {
            await updateStatus({
              claimStatus,
              claimType,
              id: activeClaim.id,
            });
            setStatus(status);
          },
        });

        return;
      case 'EXCHANGE_RE_DELIVERY':
        message.error('이미 출고가 처리중입니다.');

        return;

      case 'EXCHANGE_COMPLETED':
        message.error(
          '이미 출고가 처리중입니다. 출고완료 후 완료처리할 수 있습니다.'
        );

        return;

      default:
        setModalType('update');
        return;
    }
  };

  const handleChange = async (
    claimStatus: EOrderClaimStatus,
    claimType: EOrderClaimType
  ) => {
    try {
      void fetchOrderGroup();
      setTargetClaim([claimType, claimStatus]);

      const activeClaim = order.activeClaim;

      if (!activeClaim) {
        return;
      }

      const hasPromotionRelease =
        order.hasActiveExchangeReDeliveryPromotionRelease;

      // 현재 교환접수 상태인 경우에만 허용
      if (
        hasPromotionRelease &&
        activeClaim.claimType === 'EXCHANGE' &&
        activeClaim.status === 'EXCHANGE_RECEIPT'
      ) {
        await handleWithPromotionReleaseClaimChange(claimStatus, claimType);
        return;
      }

      if (
        claimStatus === 'RETURN_RECEIPT' ||
        claimStatus === 'EXCHANGE_RECEIPT'
      ) {
        setModalType('update');
        return;
      }

      if (
        claimStatus === 'RETURN_CANCELED' ||
        claimStatus === 'EXCHANGE_CANCELED'
      ) {
        await modal.warning({
          content: `${CLAIM_STATUS[claimStatus]} 처리하시겠습니까?`,
          centered: true,
          okText: '네',
          cancelText: '아니오',
          okCancel: true,
          async onOk() {
            await updateStatus({
              claimStatus,
              claimType,
              id: activeClaim.id,
            });
            setStatus(status);
          },
        });
        return;
      }

      if (claimStatus === 'EXCHANGE_RE_DELIVERY') {
        setModalType('promotion-release');
        return;
      }

      if (
        claimStatus === 'EXCHANGE_COMPLETED' ||
        claimStatus === 'RETURN_COMPLETED'
      ) {
        setModalType('completed');
        return;
      }

      await updateStatus({
        claimStatus,
        claimType,
        id: activeClaim.id,
      });
      setStatus(status);
    } catch (e) {
      if (e instanceof ApolloError) {
        const statusError = getStatusUpdateFailMessage(e);

        if (statusError) {
          message.error(
            `${CLAIM_STATUS[claimStatus]} 처리를 실패했습니다. ${statusError}`
          );
          return;
        }
        message.error(`${CLAIM_STATUS[claimStatus]} 처리 실패했습니다.`);
      }
    }
  };

  const initialize = () => {
    setTargetClaim(null);
    setModalType(null);
  };

  useEffect(() => {
    if (order.activeClaim?.status) {
      setStatus(order.activeClaim.status);
    }
  }, [order.activeClaim?.status]);

  return {
    initialize,
    adminOrderGroup,
    currentOrder,
    loading,
    handleChange,
    options,
    targetClaim,
    modalType,
    status,
  };
};
