import { Divider, Table, Tag, Typography } from 'antd';
import { sortBy } from 'lodash-es';
import { useMemo, useState } from 'react';

import { usePermissions } from '../../../common/hooks/usePermissions';
import { EOrderClaimStatus, EOrderClaimType } from '../../../schema/types';
import { CREATE_CLAIM_TARGET_STATUS } from '../const';
import { AdminOrderGroupQuery } from '../graphql/createClaim.generated';
import { getOrderClaimStatus } from '../helper/create';
import { getRejectReasonChangeClaim } from '../helper/orderClaim';

type ClaimTargetOrdersProps = {
  title: string;
  orderGroupCode: string;
  orderId: number;
  orders: AdminOrderGroupQuery['adminOrderGroup']['orders'];
  claimType: EOrderClaimType;
  claimStatus?: EOrderClaimStatus;
  selectable?: boolean;
  onChange: (
    v: { orderId: number; claimQuantity: number; activeClaimId?: number }[]
  ) => void;
};

function ClaimTargetOrders({
  title,
  orderGroupCode,
  orderId,
  orders,
  claimType,
  claimStatus,
  onChange,
  selectable = true,
}: ClaimTargetOrdersProps) {
  const { hasPermission } = usePermissions(['ORDER_EDIT']);

  const [selectedKeys, setSelectedKeys] = useState([orderId]);

  const dataSource = useMemo(() => {
    return sortBy(
      orders
        .filter(
          (order) =>
            order.orderType === 'PRODUCT' &&
            order.status !== 'CANCEL_COMPLETED' &&
            order.status !== 'RETURN_COMPLETED'
        )
        .map((order) => {
          const optionNames =
            order.productUseType === 'SELECT' && order?.items
              ? ` (${order.items.map((item) => item.name).join(', ')})`
              : '';

          const { status, claimType: claimTypeText } = getOrderClaimStatus({
            activeClaim: order.activeClaim,
            status: order.status,
          });

          return {
            id: order.id,
            code: order.code,
            productId: order.productId,
            productName: `${order.productName}${optionNames}`,
            orderAmount: order.orderAmount.toLocaleString(),
            productQuantity: order.productQuantity,
            status,
            claimType: claimTypeText,
            activeClaimId: order.activeClaim?.id,
            disabled:
              orderId === order.id ||
              !!getRejectReasonChangeClaim(order, {
                type: claimType,
                status: claimStatus || CREATE_CLAIM_TARGET_STATUS[claimType],
              }),
          };
        }),
      'bundleDeliveryCode'
    );
  }, [orders, orderId, claimType, claimStatus]);

  return (
    <div>
      <div className={'mb-4 flex items-center gap-4'}>
        <Divider orientation={'left'} className={'m-0 min-w-0 flex-1'}>
          {title}
        </Divider>
        <Tag className={'flex-0'}>{`와이어드 주문코드: ${orderGroupCode}`}</Tag>
      </div>
      <Table
        tableLayout={'fixed'}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedKeys,
          getCheckboxProps: (record) => ({
            disabled: !hasPermission || record.disabled || !selectable,
            key: record.id,
          }),
          onChange: (keys, record) => {
            setSelectedKeys(keys as number[]);
            onChange(
              record.map((order) => ({
                orderId: order.id,
                claimQuantity: order.productQuantity,
                activeClaimId: order.activeClaimId,
              }))
            );
          },
        }}
        rowKey={'id'}
        columns={[
          {
            title: '와이어드 품목주문코드',
            key: 'code',
            dataIndex: 'code',
            width: 200,
          },
          {
            title: '품목명',
            key: 'productName',
            dataIndex: 'productName',
          },
          {
            title: '주문수량',
            key: 'productQuantity',
            width: 120,
            render(_, record) {
              return record.productQuantity.toLocaleString();
            },
          },
          {
            title: '주문상태',
            key: 'status',
            width: 120,
            render(_, record) {
              if (record.claimType) {
                return (
                  <>
                    <Typography.Paragraph className={'m-0'}>
                      {record.status}
                    </Typography.Paragraph>
                    <Typography.Paragraph className={'m-0'} type={'secondary'}>
                      {record.claimType}
                    </Typography.Paragraph>
                  </>
                );
              }
              return record.status;
            },
          },
          {
            title: '주문금액',
            key: 'orderAmount',
            dataIndex: 'orderAmount',
            width: 120,
          },
        ]}
        dataSource={dataSource || []}
        pagination={false}
      />
    </div>
  );
}

export default ClaimTargetOrders;
