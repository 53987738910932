import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminOrderGroupDepositsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderGroupDepositFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminOrderGroupDepositsQuery = { __typename?: 'Query', adminOrderGroupDeposits?: { __typename?: 'OrderGroupDepositPageGraphqlType', totalCount: number, edges: Array<{ __typename?: 'PaginatedOrderGroupDepositGraphqlTypeEdge', node: { __typename?: 'OrderGroupDepositGraphqlType', id: number, code: string, status: Types.EOrderGroupDepositStatus, chargeDetail: Types.EOrderGroupDepositChargeDetail, depositAmount: number, depositorName: string, settlementTarget: Types.EOrderGroupDepositSettlementTarget, settlementTargetName?: string | null, groupId: number, assigneeOperatorName?: string | null, creatorOperatorName: string, depositedAt?: string | null, comment?: string | null, createdAt: string, group?: { __typename?: 'OrderGroupGraphqlType', code: string } | null } }> } | null };

export type AdminCountOrderGroupDepositStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminCountOrderGroupDepositStatusesQuery = { __typename?: 'Query', adminCountOrderGroupDepositStatuses?: Array<{ __typename?: 'CountOrderGroupDepositStatuses', count: number, status: Types.EOrderGroupDepositStatus }> | null };

export type AdminCreateOrderGroupDepositMutationVariables = Types.Exact<{
  orderGroupDepositData: Types.CreateOrderGroupDepositGraphqlType;
}>;


export type AdminCreateOrderGroupDepositMutation = { __typename?: 'Mutation', adminCreateOrderGroupDeposit: { __typename?: 'OrderGroupDepositOutputGraphqlType', orderGroupDeposit: { __typename?: 'OrderGroupDepositGraphqlType', id: number } } };

export type AdminPatchOrderGroupDepositByIdMutationVariables = Types.Exact<{
  adminPatchOrderGroupDepositByIdId: Types.Scalars['Int']['input'];
  orderGroupDepositData: Types.PatchOrderGroupDepositGraphqlType;
}>;


export type AdminPatchOrderGroupDepositByIdMutation = { __typename?: 'Mutation', adminPatchOrderGroupDepositById: { __typename?: 'OrderGroupDepositOutputGraphqlType', orderGroupDeposit: { __typename?: 'OrderGroupDepositGraphqlType', id: number, status: Types.EOrderGroupDepositStatus, assigneeOperatorName?: string | null, creatorOperatorName: string, depositedAt?: string | null } } };

export type AdminDeleteOrderGroupDepositByIdMutationVariables = Types.Exact<{
  adminDeleteOrderGroupDepositByIdId: Types.Scalars['Int']['input'];
}>;


export type AdminDeleteOrderGroupDepositByIdMutation = { __typename?: 'Mutation', adminDeleteOrderGroupDepositById: { __typename?: 'OrderGroupDepositOutputGraphqlType', orderGroupDeposit: { __typename?: 'OrderGroupDepositGraphqlType', id: number } } };

export type VendorAndSellerNameInCreateDepositQueryVariables = Types.Exact<{
  adminProductGroupId: Types.Scalars['Int']['input'];
  marketId: Types.Scalars['Int']['input'];
}>;


export type VendorAndSellerNameInCreateDepositQuery = { __typename?: 'Query', adminProductGroup: { __typename?: 'ProductGroupGraphqlType', returnFee?: number | null, exchangeFee?: number | null, vendor: { __typename?: 'VendorGraphqlType', name: string } }, adminMarket: { __typename?: 'MarketGraphqlType', seller?: { __typename?: 'SellerGraphqlType', name: string } | null } };


export const AdminOrderGroupDepositsDocument = gql`
    query AdminOrderGroupDeposits($filter: OrderGroupDepositFilterQuery, $pagination: Pagination) {
  adminOrderGroupDeposits(filter: $filter, pagination: $pagination) {
    totalCount
    edges {
      node {
        id
        code
        status
        chargeDetail
        depositAmount
        depositorName
        settlementTarget
        settlementTargetName
        groupId
        group {
          code
        }
        assigneeOperatorName
        creatorOperatorName
        depositedAt
        comment
        createdAt
      }
    }
  }
}
    `;

/**
 * __useAdminOrderGroupDepositsQuery__
 *
 * To run a query within a React component, call `useAdminOrderGroupDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrderGroupDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrderGroupDepositsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminOrderGroupDepositsQuery(baseOptions?: Apollo.QueryHookOptions<AdminOrderGroupDepositsQuery, AdminOrderGroupDepositsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOrderGroupDepositsQuery, AdminOrderGroupDepositsQueryVariables>(AdminOrderGroupDepositsDocument, options);
      }
export function useAdminOrderGroupDepositsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOrderGroupDepositsQuery, AdminOrderGroupDepositsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOrderGroupDepositsQuery, AdminOrderGroupDepositsQueryVariables>(AdminOrderGroupDepositsDocument, options);
        }
export type AdminOrderGroupDepositsQueryHookResult = ReturnType<typeof useAdminOrderGroupDepositsQuery>;
export type AdminOrderGroupDepositsLazyQueryHookResult = ReturnType<typeof useAdminOrderGroupDepositsLazyQuery>;
export type AdminOrderGroupDepositsQueryResult = Apollo.QueryResult<AdminOrderGroupDepositsQuery, AdminOrderGroupDepositsQueryVariables>;
export const AdminCountOrderGroupDepositStatusesDocument = gql`
    query AdminCountOrderGroupDepositStatuses {
  adminCountOrderGroupDepositStatuses {
    count
    status
  }
}
    `;

/**
 * __useAdminCountOrderGroupDepositStatusesQuery__
 *
 * To run a query within a React component, call `useAdminCountOrderGroupDepositStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountOrderGroupDepositStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountOrderGroupDepositStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminCountOrderGroupDepositStatusesQuery(baseOptions?: Apollo.QueryHookOptions<AdminCountOrderGroupDepositStatusesQuery, AdminCountOrderGroupDepositStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCountOrderGroupDepositStatusesQuery, AdminCountOrderGroupDepositStatusesQueryVariables>(AdminCountOrderGroupDepositStatusesDocument, options);
      }
export function useAdminCountOrderGroupDepositStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCountOrderGroupDepositStatusesQuery, AdminCountOrderGroupDepositStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCountOrderGroupDepositStatusesQuery, AdminCountOrderGroupDepositStatusesQueryVariables>(AdminCountOrderGroupDepositStatusesDocument, options);
        }
export type AdminCountOrderGroupDepositStatusesQueryHookResult = ReturnType<typeof useAdminCountOrderGroupDepositStatusesQuery>;
export type AdminCountOrderGroupDepositStatusesLazyQueryHookResult = ReturnType<typeof useAdminCountOrderGroupDepositStatusesLazyQuery>;
export type AdminCountOrderGroupDepositStatusesQueryResult = Apollo.QueryResult<AdminCountOrderGroupDepositStatusesQuery, AdminCountOrderGroupDepositStatusesQueryVariables>;
export const AdminCreateOrderGroupDepositDocument = gql`
    mutation AdminCreateOrderGroupDeposit($orderGroupDepositData: CreateOrderGroupDepositGraphqlType!) {
  adminCreateOrderGroupDeposit(orderGroupDepositData: $orderGroupDepositData) {
    orderGroupDeposit {
      id
    }
  }
}
    `;
export type AdminCreateOrderGroupDepositMutationFn = Apollo.MutationFunction<AdminCreateOrderGroupDepositMutation, AdminCreateOrderGroupDepositMutationVariables>;

/**
 * __useAdminCreateOrderGroupDepositMutation__
 *
 * To run a mutation, you first call `useAdminCreateOrderGroupDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateOrderGroupDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateOrderGroupDepositMutation, { data, loading, error }] = useAdminCreateOrderGroupDepositMutation({
 *   variables: {
 *      orderGroupDepositData: // value for 'orderGroupDepositData'
 *   },
 * });
 */
export function useAdminCreateOrderGroupDepositMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateOrderGroupDepositMutation, AdminCreateOrderGroupDepositMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateOrderGroupDepositMutation, AdminCreateOrderGroupDepositMutationVariables>(AdminCreateOrderGroupDepositDocument, options);
      }
export type AdminCreateOrderGroupDepositMutationHookResult = ReturnType<typeof useAdminCreateOrderGroupDepositMutation>;
export type AdminCreateOrderGroupDepositMutationResult = Apollo.MutationResult<AdminCreateOrderGroupDepositMutation>;
export type AdminCreateOrderGroupDepositMutationOptions = Apollo.BaseMutationOptions<AdminCreateOrderGroupDepositMutation, AdminCreateOrderGroupDepositMutationVariables>;
export const AdminPatchOrderGroupDepositByIdDocument = gql`
    mutation AdminPatchOrderGroupDepositById($adminPatchOrderGroupDepositByIdId: Int!, $orderGroupDepositData: PatchOrderGroupDepositGraphqlType!) {
  adminPatchOrderGroupDepositById(
    id: $adminPatchOrderGroupDepositByIdId
    orderGroupDepositData: $orderGroupDepositData
  ) {
    orderGroupDeposit {
      id
      status
      assigneeOperatorName
      creatorOperatorName
      depositedAt
    }
  }
}
    `;
export type AdminPatchOrderGroupDepositByIdMutationFn = Apollo.MutationFunction<AdminPatchOrderGroupDepositByIdMutation, AdminPatchOrderGroupDepositByIdMutationVariables>;

/**
 * __useAdminPatchOrderGroupDepositByIdMutation__
 *
 * To run a mutation, you first call `useAdminPatchOrderGroupDepositByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPatchOrderGroupDepositByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPatchOrderGroupDepositByIdMutation, { data, loading, error }] = useAdminPatchOrderGroupDepositByIdMutation({
 *   variables: {
 *      adminPatchOrderGroupDepositByIdId: // value for 'adminPatchOrderGroupDepositByIdId'
 *      orderGroupDepositData: // value for 'orderGroupDepositData'
 *   },
 * });
 */
export function useAdminPatchOrderGroupDepositByIdMutation(baseOptions?: Apollo.MutationHookOptions<AdminPatchOrderGroupDepositByIdMutation, AdminPatchOrderGroupDepositByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPatchOrderGroupDepositByIdMutation, AdminPatchOrderGroupDepositByIdMutationVariables>(AdminPatchOrderGroupDepositByIdDocument, options);
      }
export type AdminPatchOrderGroupDepositByIdMutationHookResult = ReturnType<typeof useAdminPatchOrderGroupDepositByIdMutation>;
export type AdminPatchOrderGroupDepositByIdMutationResult = Apollo.MutationResult<AdminPatchOrderGroupDepositByIdMutation>;
export type AdminPatchOrderGroupDepositByIdMutationOptions = Apollo.BaseMutationOptions<AdminPatchOrderGroupDepositByIdMutation, AdminPatchOrderGroupDepositByIdMutationVariables>;
export const AdminDeleteOrderGroupDepositByIdDocument = gql`
    mutation AdminDeleteOrderGroupDepositById($adminDeleteOrderGroupDepositByIdId: Int!) {
  adminDeleteOrderGroupDepositById(id: $adminDeleteOrderGroupDepositByIdId) {
    orderGroupDeposit {
      id
    }
  }
}
    `;
export type AdminDeleteOrderGroupDepositByIdMutationFn = Apollo.MutationFunction<AdminDeleteOrderGroupDepositByIdMutation, AdminDeleteOrderGroupDepositByIdMutationVariables>;

/**
 * __useAdminDeleteOrderGroupDepositByIdMutation__
 *
 * To run a mutation, you first call `useAdminDeleteOrderGroupDepositByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteOrderGroupDepositByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteOrderGroupDepositByIdMutation, { data, loading, error }] = useAdminDeleteOrderGroupDepositByIdMutation({
 *   variables: {
 *      adminDeleteOrderGroupDepositByIdId: // value for 'adminDeleteOrderGroupDepositByIdId'
 *   },
 * });
 */
export function useAdminDeleteOrderGroupDepositByIdMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteOrderGroupDepositByIdMutation, AdminDeleteOrderGroupDepositByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteOrderGroupDepositByIdMutation, AdminDeleteOrderGroupDepositByIdMutationVariables>(AdminDeleteOrderGroupDepositByIdDocument, options);
      }
export type AdminDeleteOrderGroupDepositByIdMutationHookResult = ReturnType<typeof useAdminDeleteOrderGroupDepositByIdMutation>;
export type AdminDeleteOrderGroupDepositByIdMutationResult = Apollo.MutationResult<AdminDeleteOrderGroupDepositByIdMutation>;
export type AdminDeleteOrderGroupDepositByIdMutationOptions = Apollo.BaseMutationOptions<AdminDeleteOrderGroupDepositByIdMutation, AdminDeleteOrderGroupDepositByIdMutationVariables>;
export const VendorAndSellerNameInCreateDepositDocument = gql`
    query VendorAndSellerNameInCreateDeposit($adminProductGroupId: Int!, $marketId: Int!) {
  adminProductGroup(id: $adminProductGroupId) {
    returnFee
    exchangeFee
    vendor {
      name
    }
  }
  adminMarket(marketId: $marketId) {
    seller {
      name
    }
  }
}
    `;

/**
 * __useVendorAndSellerNameInCreateDepositQuery__
 *
 * To run a query within a React component, call `useVendorAndSellerNameInCreateDepositQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorAndSellerNameInCreateDepositQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorAndSellerNameInCreateDepositQuery({
 *   variables: {
 *      adminProductGroupId: // value for 'adminProductGroupId'
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useVendorAndSellerNameInCreateDepositQuery(baseOptions: Apollo.QueryHookOptions<VendorAndSellerNameInCreateDepositQuery, VendorAndSellerNameInCreateDepositQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorAndSellerNameInCreateDepositQuery, VendorAndSellerNameInCreateDepositQueryVariables>(VendorAndSellerNameInCreateDepositDocument, options);
      }
export function useVendorAndSellerNameInCreateDepositLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorAndSellerNameInCreateDepositQuery, VendorAndSellerNameInCreateDepositQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorAndSellerNameInCreateDepositQuery, VendorAndSellerNameInCreateDepositQueryVariables>(VendorAndSellerNameInCreateDepositDocument, options);
        }
export type VendorAndSellerNameInCreateDepositQueryHookResult = ReturnType<typeof useVendorAndSellerNameInCreateDepositQuery>;
export type VendorAndSellerNameInCreateDepositLazyQueryHookResult = ReturnType<typeof useVendorAndSellerNameInCreateDepositLazyQuery>;
export type VendorAndSellerNameInCreateDepositQueryResult = Apollo.QueryResult<VendorAndSellerNameInCreateDepositQuery, VendorAndSellerNameInCreateDepositQueryVariables>;