import dayjs from 'dayjs';
import { omit, pick, sortBy } from 'lodash-es';

import { bankOptions } from '../../common/utils/const';
import {
  EMarketEventType,
  CreateMarketInputGraphqlType,
  UpdateMarketDataGraphqlType,
  EMarketStatus,
} from '../../schema/types';
import { SELLER_SETTLEMENT_TYPES } from '../seller/const';

import { MARKET_STATUS } from './const';
import { AdminMarketQuery } from './graphql/market.generated';
import { AdminMarketsQueryVariables } from './graphql/markets.generated';
import { AdminSellerSettlementsQuery } from './graphql/seller.generated';
import {
  CreateMarketFormValues,
  MarketListSearchParams,
  MarketListStringifySearchParams,
  SettlementInfo,
  UpdateMarketFormValues,
} from './types';

export const hasParticipantsEventType = (eventType: EMarketEventType) => {
  const needParticipantsTypes: EMarketEventType[] = [
    'FIRST_SERVED',
    'RANDOM',
    'TESTER',
    'CONFIRMED',
    'BEFORE',
  ];

  return needParticipantsTypes.includes(eventType);
};

export const toCreateMarketVariables = (
  data: CreateMarketFormValues
): CreateMarketInputGraphqlType => {
  const payload = pick(data, [
    'additionalFee',
    'brandId',
    'comment',
    'deliveryCost',
    'deliveryFeeType',
    'endedAt',
    'expectedSales',
    'expectedSettlementAt',
    'freeDeliveryConditionFee',
    'isRegionFee',
    'isSellerAssigned',
    'jejuIslandFee',
    'managerId',
    'name',
    'releaseTime',
    'releaseType',
    'sellerId',
    'sellerSettlementInfoId',
    'sellingChannel',
    'session',
    'startedAt',
    'purchaseOrderType',
    'etcSellingChannel',
  ]);
  return {
    ...payload,
    productGroupIds: data.productGroupIds
      .map(({ id }) => id)
      .filter((id): id is number => !!id),
    events: data.events?.map((event) => ({
      ...omit(event, ['editable', 'deletable']),
      products: event.products.map((product) => ({
        ...omit(product, ['id', 'useType', 'itemIds']),
        name: product.name || '',
        itemIds: product.useType === 'SELECT' ? product.itemIds : null,
      })),
    })),
  };
};

export const toUpdateMarketFormValues = (
  data: AdminMarketQuery['adminMarket'],
  seller?: AdminSellerSettlementsQuery['adminSeller']
): UpdateMarketFormValues => {
  return {
    additionalFee: data.additionalFee,
    brandId: data.brand.id,
    brandName: data.brand.name,
    comment: data.comment,
    deliveryCost: data.deliveryCost,
    deliveryFeeType: data.deliveryFeeType || 'FREE',
    endedAt: data.endedAt,
    events: sortBy(data.events || [], 'id').map((event) => ({
      ...omit(event, '__typename'),
      products: sortBy(event.products || [], 'id').map((product) => ({
        ...omit(product, ['__typename', 'items']),
        itemIds: product.items?.map((item) => item.id),
      })),
    })),
    expectedSales: data.expectedSales,
    freeDeliveryConditionFee: data.freeDeliveryConditionFee,
    jejuIslandFee: data.jejuIslandFee,
    session: data.session,
    status: data.status,
    sellingChannel: data.sellingChannel,
    startedAt: data.startedAt,
    releaseTime: data.releaseTime,
    managerId: data.manager.id,
    productGroupIds: data.productGroups.map((group) => ({
      id: group.id,
      name: group.name,
      managerId: group.manager.id,
    })),
    expectedSettlementAt: data.expectedSettlementAt || null,
    name: data.name,
    isSellerAssigned: data.isSellerAssigned,
    isRegionFee: data.isRegionFee,
    releaseType: data.releaseType || 'BULK',
    sellerId: data.seller?.id,
    sellerName: data.seller?.name,
    sellerKemiId: data.seller?.kemiUserId,
    sellerSettlementInfoId: data.sellerSettlementInfo?.id,
    settlementInfos:
      seller?.settlementInfos.map((info) => ({
        id: info.id,
        type: info.type,
        name:
          (info.type === 'FREELANCER' ? info.sellerName : info.companyName) ||
          '',
        bank: info.bank || null,
        accountHolderName: info.accountHolderName || '',
        accountNumber: info.accountNumber || '',
        isDefault: info.isDefault,
      })) || [],
    purchaseOrderType: data.purchaseOrderType,
    etcSellingChannel: data.etcSellingChannel,
  };
};

export const toUpdateMarketVariables = (
  data: UpdateMarketFormValues
): UpdateMarketDataGraphqlType => {
  return {
    additionalFee: data.additionalFee,
    brandId: data.brandId,
    comment: data.comment,
    deliveryCost: data.deliveryCost,
    deliveryFeeType: data.deliveryFeeType,
    endedAt: data.endedAt,
    expectedSales: data.expectedSales,
    expectedSettlementAt: data.expectedSettlementAt,
    freeDeliveryConditionFee: data.freeDeliveryConditionFee,
    isRegionFee: data.isRegionFee,
    isSellerAssigned: data.isSellerAssigned,
    jejuIslandFee: data.jejuIslandFee,
    managerId: data.managerId,
    name: data.name,
    releaseTime: data.releaseTime,
    releaseType: data.releaseType,
    sellerId: data.sellerId,
    sellerSettlementInfoId: data.sellerSettlementInfoId,
    session: data.session,
    startedAt: data.startedAt,
    status: data.status,
    purchaseOrderType: data.purchaseOrderType,
    etcSellingChannel: data.etcSellingChannel,

    productGroupIds: data.productGroupIds
      .map(({ id }) => id)
      .filter((id): id is number => !!id),
    sellingChannel: data.sellingChannel,
    events: data.events?.map((event) => ({
      ...omit(event, ['editable', 'deletable']),
      products: event.products.map((product) => ({
        ...omit(product, ['useType', 'itemIds']),
        id: typeof product.id === 'string' ? undefined : product.id,
        name: product.name || '',
        itemIds: product.useType === 'SELECT' ? product.itemIds : null,
      })),
    })),
  };
};

export const getExpectedSettlementDate = (end: string | null) => {
  if (end) {
    const isNextMonthSettled = dayjs(end).get('date') > 15;
    return isNextMonthSettled
      ? dayjs(end).add(1, 'month').set('date', 15).startOf('date').toISOString()
      : dayjs(end).endOf('month').startOf('date').toISOString();
  }

  return null;
};

export const getMarketStatusByRange = (
  start: string | null,
  end: string | null,
  status: EMarketStatus
): EMarketStatus => {
  //바꿀 수 없는 status: 마켓준비, 마켓종료, 마켓중지
  const fixedStatuses: EMarketStatus[] = ['ENDED', 'CANCELED'];

  //바꿀 수 없는 status일 경우
  if (fixedStatuses.includes(status) || !start) {
    return status;
  }

  //상시
  if (!end) {
    //마켓중
    if (dayjs(start).isAfter(dayjs())) {
      return 'ON_SALE';
    }

    //마켓준비
    return 'READY';
  }

  // 기간
  // 현재 날짜가 startedAt 이전일 때,
  if (dayjs().isBefore(dayjs(start))) {
    return 'READY';
  }

  // 연재 날짜가 endedAt 이전일 때,
  if (dayjs().isBefore(dayjs(end))) {
    return 'ON_SALE';
  }

  return 'ENDED';
};

export const getMarketStatusOptions = (
  start: string | null,
  end: string | null
): { label: string; value: EMarketStatus }[] => {
  const fixedOptions = MARKET_STATUS.filter(
    ({ value }) =>
      value === 'CANCELED' || value === 'ENDED' || value === 'SUSPENDED'
  );

  // 현재 기간에 따른 상태 : 판매중 or 판매준비
  const currentStatusOption: { label: string; value: EMarketStatus }[] =
    MARKET_STATUS.filter(({ value }) => {
      // 상시
      if (!end) {
        if (dayjs().isBefore(dayjs(start))) {
          return value === 'READY';
        }
        return value === 'ON_SALE';
      }

      // 기간
      // 현재 날짜가 startedAt 이전일 때,
      if (dayjs().isBefore(dayjs(start))) {
        return value === 'READY';
      }

      // 연재 날짜가 endedAt 이전일 때,
      if (dayjs().isBefore(dayjs(end))) {
        return value === 'ON_SALE';
      }
    });

  return currentStatusOption.concat(fixedOptions);
};

export const buildSettlementInfoLabel = (info: SettlementInfo) => {
  const type =
    SELLER_SETTLEMENT_TYPES.find((type) => type.value === info.type)?.label ||
    '';
  const bank =
    bankOptions.find((option) => option.value === info.bank)?.label || '';
  const bankInfo =
    bank && info.accountNumber
      ? ` / ${bank} ${info.accountNumber.slice(-4)}`
      : '';
  const isDefault = info.isDefault ? '(대표)' : '';
  const label = `${type}${info.name ? ` / ${info.name}` : ''}${bankInfo}`;

  return { isDefault, label };
};

export const toMarketsVariables = (
  params: MarketListStringifySearchParams | MarketListSearchParams | null
): { variables: AdminMarketsQueryVariables; size: number; page: number } => {
  const {
    marketOrProductManagerIds,
    periodType,
    settlementType,
    statuses,
    startedAt,
    endedAt,
    sortingOrder = 'DESC',
    sortingField = 'startedAt',
    pageSize,
    currentPage,
    searchKeyword,
    marketEndFilter,
    marketStartFilter,
    view = 'table',
  } = params || {};

  const size = view === 'calendar' ? 50 : Number(pageSize) || 50;
  const page = view === 'calendar' ? 1 : Number(currentPage) || 1;

  return {
    variables: {
      filter: {
        statuses,
        settlementType,
        searchKeyword,
        marketOrProductManagerIds: marketOrProductManagerIds?.map((id) => {
          return Number(id);
        }),
        period:
          periodType && periodType !== 'MARKET_PERIOD' && (startedAt || endedAt)
            ? {
                startedAt,
                endedAt,
                type: periodType,
              }
            : undefined,
        marketEndFilter:
          view === 'calendar'
            ? marketEndFilter
            : periodType === 'MARKET_PERIOD'
            ? endedAt
            : undefined,
        marketStartFilter:
          view === 'calendar'
            ? marketStartFilter
            : periodType === 'MARKET_PERIOD'
            ? startedAt
            : undefined,
      },
      order: { [sortingField]: sortingOrder },
      pagination: {
        skip: size * (page - 1),
        take: size,
      },
    },
    size,
    page,
  };
};
