import { App, Button, Segmented, Select } from 'antd';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import SimpleEditor from '../../../../common/components/SimpleEditor';
import { useFormError } from '../../../../common/hooks/useFormError';
import { deleteCacheFields } from '../../../../common/utils/apollo';
import { CreateOrderSupportMessageInputGraphqlType } from '../../../../schema/types';
import { orderSupportMessagePresetOptions } from '../../const';
import {
  AdminOrderGroupDocument,
  AdminOrderGroupQuery,
} from '../../graphql/createClaim.generated';
import {
  AdminOrderSupportMessagesDocument,
  useAdminCreateOrderSupportMessageMutation,
  useAdminCreateOrderSupportWithMessageMutation,
} from '../../graphql/orderSupport.generated';
import CreateClaimSelect from '../ClaimStatusSelects/CreateClaimSelect';
import UpdateClaimSelect from '../ClaimStatusSelects/UpdateClaimSelect';

function UpdateOrderSupportMessageForm({
  orderSupportId,
  order,
  hasMessages,
  orderGroupId,
  disabled,
}: {
  orderSupportId?: number;
  order: AdminOrderGroupQuery['adminOrderGroup']['orders']['0'];
  hasMessages: boolean;
  orderGroupId: number;
  disabled?: boolean;
}) {
  const { message } = App.useApp();

  const [segment, setSegment] = useState<'status' | 'content'>('status');
  const [preset, setPreset] = useState<string>('');

  const [createOrderSupportMessage, { loading }] =
    useAdminCreateOrderSupportMessageMutation();
  const [createOrderSupport] = useAdminCreateOrderSupportWithMessageMutation();

  const methods = useForm<CreateOrderSupportMessageInputGraphqlType>({
    defaultValues: { content: '' },
  });
  const { handleSubmit, watch, setValue, reset, formState } = methods;

  const activeClaim = order?.activeClaim || undefined;
  const baseOrderData = useMemo(() => {
    if (!order) {
      return null;
    }

    return {
      claims: order.claims,
      claimStatus: activeClaim?.status,
      orderCode: order.code,
      orderId: order.id,
      orderStatus: order.status,
      orderType: order.orderType,
      orderGroupId,
      activeClaim,
      salesChannel: order.salesChannel,
      salesChannelType: order.salesChannelType,
      productName: order.productName,
      hasActiveExchangeReDeliveryPromotionRelease:
        !!order.hasActiveExchangeReDeliveryPromotionRelease,
    };
  }, [order, orderGroupId, activeClaim]);

  const updateSupportMessages = ({
    content,
    orderSupportId,
  }: {
    orderSupportId: number;
    content?: string | null;
  }) => {
    void createOrderSupportMessage({
      variables: {
        supportMessageData: {
          orderSupportId,
          content,
        },
      },
      refetchQueries: [AdminOrderSupportMessagesDocument],
      onError() {
        message.error('응대 메시지를 업데이트하지 못했습니다.');
      },
      onCompleted() {
        message.success('응대 메시지를 업데이트하였습니다.');
        reset({ content: '' });
        setPreset('');
      },
      update(cache) {
        deleteCacheFields(cache, [
          'adminOrders',
          'adminOrderGroup',
          'adminCountOrderByLatestClaimStatuses',
          'adminCountOrderSupportStatuses',
        ]);
      },
    });
  };

  const onSubmit = (values: CreateOrderSupportMessageInputGraphqlType) => {
    if (!orderSupportId) {
      if (order?.id) {
        void createOrderSupport({
          variables: {
            orderSupportData: {
              orderId: order.id,
              channel: 'INTERNAL',
              requestType: 'DEFECT',
              message: { content: '' },
            },
          },
          refetchQueries: [
            AdminOrderGroupDocument,
            AdminOrderSupportMessagesDocument,
          ],
          onError(error) {
            message.error(error.message || '응대 내역 생성을 실패하였습니다.');
          },
          onCompleted(data) {
            const orderSupportId =
              data.adminCreateOrderSupportWithMessage.orderSupport.id;

            updateSupportMessages({
              orderSupportId,
              content: values.content,
            });

            message.success('응대 내역을 생성하였습니다.');
          },
          update(cache) {
            deleteCacheFields(cache, [
              'adminOrders',
              'adminOrderGroup',
              'adminCountOrderByLatestClaimStatuses',
            ]);
          },
        });
      }

      return;
    }

    updateSupportMessages({ orderSupportId, content: values.content });
  };

  const { onInvalid } = useFormError();

  const content = watch('content') || '';
  const isUpdate = !!baseOrderData?.activeClaim;

  return (
    <div
      className={`${
        hasMessages ? 'min-h-[220px]' : 'min-h-[180px]'
      } bg-[#00000005] p-6`}
    >
      {hasMessages && (
        <div className={'mb-2'}>
          <Segmented
            options={[
              { label: '주문상태 변경', value: 'status' },
              { label: '응대내용 생성', value: 'content' },
            ]}
            value={segment}
            onChange={(v) => setSegment(v as 'status' | 'content')}
          />
        </div>
      )}

      {hasMessages && segment === 'status' && (
        <div className={'flex min-h-[132px] flex-col'}>
          <div className={'w-[120px] flex-1'}>
            {baseOrderData && isUpdate && (
              <UpdateClaimSelect
                order={baseOrderData}
                renderUpdateExchangeReceipt={false}
                renderUpdateReturnReceipt={false}
              />
            )}
            {baseOrderData && !isUpdate && (
              <CreateClaimSelect order={baseOrderData} />
            )}
          </div>
        </div>
      )}
      {(!hasMessages || segment === 'content') && (
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit, onInvalid)}
            className={'grid gap-2'}
          >
            <div className={'w-[140px]'}>
              <Select
                disabled={disabled}
                className={'w-full'}
                options={orderSupportMessagePresetOptions}
                placeholder={'---선택---'}
                value={preset || null}
                onChange={(v: string, option) => {
                  if (!Array.isArray(option)) {
                    setPreset(v);
                    setValue('content', option.label, {
                      shouldTouch: true,
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }
                }}
              />
            </div>
            <div>
              <SimpleEditor
                editable={!disabled}
                deps={[preset, loading]}
                content={content}
                onChange={(v) =>
                  setValue('content', v, {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                wrapperClassName={
                  'resize-y overflow-scroll rounded-md border border-solid bg-white px-[11px] py-1 min-h-[52px]'
                }
                placeholder={'응대내용을 입력하세요.'}
              />
            </div>
            <div className={'text-right'}>
              <Button htmlType={'submit'} disabled={!formState.isDirty}>
                업데이트
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
}

export default UpdateOrderSupportMessageForm;
