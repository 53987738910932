import { Table } from 'antd';
import { useMemo } from 'react';

import { useAdminItemsByMarketIdQuery } from '../graphql/items.generated';

type ProductMarketListOptionTableProps = {
  marketId: number;
};

type DataSourceType = {
  key: number;
  id: number;
  name: string;
  itemQuantity: number;
  code: string;
};

function ProductMarketListOptionTable({
  marketId,
}: ProductMarketListOptionTableProps) {
  const { data, loading } = useAdminItemsByMarketIdQuery({
    variables: {
      marketId,
    },
  });

  const dataSource = useMemo(
    () =>
      data?.adminItemsByMarketId.map(({ code, id, name, itemQuantity }) => {
        return {
          key: id,
          code,
          id,
          name,
          itemQuantity: itemQuantity || 0,
        };
      }) || [],
    [data?.adminItemsByMarketId]
  );

  if (loading || !data?.adminItemsByMarketId.length) return null;

  return (
    <Table<DataSourceType>
      pagination={false}
      dataSource={dataSource}
      className='[&_.ant-table-thead]:rounded-none [&_.ant-table-thead]:bg-black/[0.02]'
    >
      <Table.Column<DataSourceType>
        title='옵션코드 (옵션ID)'
        width={240}
        dataIndex='optionId'
        className='bg-black/[0.02]'
        render={(_, { code, id }) => {
          return (
            <p>
              {code} <span className='text-black/[0.45]'>({id})</span>
            </p>
          );
        }}
      />
      <Table.Column
        title='옵션명'
        width={656}
        dataIndex='name'
        className='bg-black/[0.02]'
      />
      <Table.Column
        title='판매수량'
        width={108}
        dataIndex='itemQuantity'
        className='bg-black/[0.02]'
      />
    </Table>
  );
}

export default ProductMarketListOptionTable;
