import { Typography } from 'antd';
import { PropsWithChildren } from 'react';

type PageLayoutProps = {
  summary: React.ReactNode;
  search: React.ReactNode;
} & PropsWithChildren;

function PageLayout({ children, summary, search }: PageLayoutProps) {
  return (
    <div className='flex flex-col gap-4'>
      <div className='bg-white p-6'>{summary}</div>
      <div className='bg-white p-6'>
        <Typography.Title level={5}>검색</Typography.Title>
        {search}
      </div>
      {children}
    </div>
  );
}

export default PageLayout;
