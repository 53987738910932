import { Button, Modal, Typography, Spin } from 'antd';
import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';

import { bankLabels } from '../../../../../common/utils/const';
import { SELLER_SETTLEMENT_LABELS } from '../../../../seller/const';
import { SELLING_CHANNEL_LABEL } from '../../../const';
import { useAdminSettlementSheetQuery } from '../../../graphql/settlementSheet.generated';
import { createPdf } from '../../../helper';

import Block from './Block';
import ExtraInfo from './ExtraInfo';
import PaymentInfo from './PaymentInfo';
import SellingInfo from './SellingInfo';
import Summary from './Summary';
import SupplyInfo from './SupplyInfo';

type PdfModalProps = {
  settlementSheetId: number;
  onClose: () => void;
};

function PdfModal({ settlementSheetId, onClose }: PdfModalProps) {
  const [name, setName] = useState('');
  const { data, loading } = useAdminSettlementSheetQuery({
    variables: { adminSettlementSheetId: settlementSheetId },
    onCompleted({ adminSettlementSheet }) {
      if (adminSettlementSheet.settlementSheetType === 'MARKET') {
        setName(adminSettlementSheet.marketName || '');
      } else if (adminSettlementSheet.settlementSheetType === 'VENDOR') {
        setName(adminSettlementSheet.vendorName || '');
      }
    },
  });

  const [downloadLoading, setDownloadLoading] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleSumbit = async () => {
    if (buttonRef.current) {
      buttonRef.current.disabled = true;
      setDownloadLoading(true);
      await createPdf('정산서');
      setDownloadLoading(false);
      buttonRef.current.disabled = false;
    }
  };
  const settlementSheetType = data?.adminSettlementSheet.settlementSheetType;
  const marketStartedAt = data?.adminSettlementSheet.marketStartedAt;
  const marketEndedAt = data?.adminSettlementSheet.marketEndedAt;
  const marketPeriod = `${
    marketStartedAt ? dayjs(marketStartedAt).format('YYYY.MM.DD') : ''
  } - ${marketEndedAt ? dayjs(marketEndedAt).format('YYYY.MM.DD') : '상시'}
  `;
  const salesChannel = data?.adminSettlementSheet.salesChannel
    ? SELLING_CHANNEL_LABEL[data.adminSettlementSheet.salesChannel]
    : '-';
  const selletSettlementType = data?.adminSettlementSheet.sellerSettlementType
    ? SELLER_SETTLEMENT_LABELS[data.adminSettlementSheet.sellerSettlementType]
    : '-';

  const finalSettlementAmount = useMemo(() => {
    if (!data) return '-';
    const finalSettlementAmount =
      data.adminSettlementSheet.finalSettlementAmount.toLocaleString();
    // 업체정산서만 수정이 가능하여 최종정산금액은 adjustmentAmount
    // adjustAmount 가 없을 경우에는 finalSettlementAmount 가 최종정산금액이다.
    const adjustmentAmount =
      typeof data.adminSettlementSheet.adjustmentAmount === 'number'
        ? data.adminSettlementSheet.adjustmentAmount.toLocaleString()
        : finalSettlementAmount;

    return adjustmentAmount;
  }, [data]);

  const settlementCode = data?.adminSettlementSheet.code;
  const bankName = data?.adminSettlementSheet.bank
    ? bankLabels[data.adminSettlementSheet.bank]
    : '-';
  const accountNumber = data?.adminSettlementSheet.accountNumber;
  const accountHolderName = data?.adminSettlementSheet.accountHolderName;

  const productSalesRecord =
    data?.adminSettlementSheet.productSalesRecords || [];
  const paymentMethodRecords =
    data?.adminSettlementSheet.paymentMethodRecords || [];

  return (
    <Modal
      open
      title='정산서 PDF'
      width={1190}
      footer={null}
      onCancel={onClose}
    >
      <Spin spinning={loading || downloadLoading}>
        <div className='p-4'>
          <Block padding={false}>
            <div className='BLOCK flex items-center justify-between'>
              <Typography.Title level={4} className='m-0'>
                {settlementSheetType === 'MARKET' ? '마켓' : '업체'} 정산서
              </Typography.Title>
              {settlementSheetType === 'MARKET' && (
                <Typography.Text className='text-[#00000073]'>
                  마켓기간: {marketPeriod} (11일) / 판매채널: {salesChannel} (
                  {selletSettlementType})
                </Typography.Text>
              )}
            </div>
          </Block>
          <Block>
            <Typography.Title
              level={2}
              className='m-0'
              editable={
                settlementSheetType === 'VENDOR' && {
                  triggerType: ['text'],
                  text: name,
                  onChange: setName,
                }
              }
            >
              {name}
            </Typography.Title>
          </Block>
          {!!data?.adminSettlementSheet && (
            <Summary settlementSheet={data.adminSettlementSheet} />
          )}
          {!!productSalesRecord.length && (
            <SellingInfo
              productSalesRecords={productSalesRecord}
              settlementSheetType={settlementSheetType}
            />
          )}
          {!!data?.adminSettlementSheet && (
            <ExtraInfo settlementSheet={data.adminSettlementSheet} />
          )}
          {!!paymentMethodRecords.length &&
            settlementSheetType !== 'VENDOR' && (
              <PaymentInfo paymentMethodRecords={paymentMethodRecords} />
            )}
          <SupplyInfo settlementSheetType={settlementSheetType} />
          <Block>
            <div className='flex flex-col gap-2'>
              <div className='flex items-center justify-between bg-[#0000000A] px-4 py-2'>
                <Typography.Title className='m-0' level={5}>
                  {bankName} / {accountNumber} / {accountHolderName}
                </Typography.Title>
                <div className='flex items-center gap-4'>
                  <Typography.Text>최종 정산금액</Typography.Text>
                  <Typography.Title className='m-0' level={2}>
                    {finalSettlementAmount}원
                  </Typography.Title>
                </div>
              </div>
              <Typography.Text className='text-[#00000073]'>
                정산서코드: {settlementCode}
              </Typography.Text>
            </div>
          </Block>
        </div>
        <div className='mt-4 flex justify-end gap-2'>
          <Button
            ref={buttonRef}
            type='primary'
            loading={loading || downloadLoading}
            onClick={handleSumbit}
          >
            PDF 다운로드
          </Button>
        </div>
      </Spin>
    </Modal>
  );
}

export default PdfModal;
