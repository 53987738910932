import { EyeOutlined, PlusOutlined, FileTextOutlined } from '@ant-design/icons';
import { App, Button, Typography, Upload, UploadFile } from 'antd';
import mime from 'mime';
import { useEffect, useMemo, useState } from 'react';

import PreviewModal from '../../../../common/components/PreviewModal';
import { ALLOW_UPLOAD_IMAGE_MIME_TYPES } from '../../../../common/utils/const';

import ArchiveUploadModal from './ArchiveUploadModal';
import ProductArchiveContentsModal from './ContentsModal';

type ArchiveUploadListProps = {
  title: string;
  uploadList: string[];
  onChange: (value: string[]) => void;
  accept?: string;
  required?: boolean;
  error?: string;
  editable?: boolean;
  description?: string;
  hideErrorMessage?: boolean;
};

export function ArchiveUploadList({
  title,
  uploadList,
  onChange,
  accept = ALLOW_UPLOAD_IMAGE_MIME_TYPES.join(','),
  required,
  error,
  editable,
  description,
  hideErrorMessage,
}: ArchiveUploadListProps) {
  const { message } = App.useApp();
  const [urls, setUrls] = useState<string[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [contentsModalOpen, setContentsModalOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const urlToFileList = (urls: string[]) => {
    return urls.map((fileUrl, i) => {
      return {
        uid: i.toString(),
        name: '',
        thumbUrl: fileUrl,
        status: 'done',
        url: fileUrl,
      };
    }) as UploadFile[];
  };

  const [fileList, setFileList] = useState<UploadFile[]>(() => {
    return urlToFileList(urls);
  });

  useEffect(() => {
    setUrls(uploadList);
    setFileList(urlToFileList(uploadList));
  }, [uploadList]);

  const removeFile = (id: string) => {
    const urlsCopy = urls.slice();
    urlsCopy.splice(Number(id), 1);

    changeUrls(urlsCopy);
  };

  const changeUrls = (urls: string[]) => {
    setUrls(urls);
    onChange(urls);
  };

  const fileListView = useMemo(() => {
    return fileList.map((file) => ({
      ...file,
      url: file.status === 'error' ? undefined : file.url,
      thumbUrl: file.status === 'error' ? undefined : file.thumbUrl,
    }));
  }, [fileList]);

  const handleError = (target: UploadFile) => {
    setFileList((fileList) => {
      return fileList.map((file) => {
        if (target.uid === file.uid) {
          return {
            ...file,
            status: 'error',
            error: {
              statusText: (
                <div>
                  <span>파일을 불러올 수 없습니다.</span>
                  <br />
                  <span>파일을 확인하여 다시 업로드해주세요.</span>
                  <br />
                  <span>(권장 확장자 : jpeg | jpg | png | webp | gif)</span>
                </div>
              ),
            },
          };
        }
        return file;
      });
    });
  };

  const validateImage = (file: UploadFile) => {
    if (!file.url) {
      return false;
    }

    const extension = mime.getType(file.url);

    return extension
      ? ALLOW_UPLOAD_IMAGE_MIME_TYPES.includes(extension)
      : false;
  };

  const itemRender = (originNode: React.ReactNode, file: UploadFile) => {
    return (
      <div className={'h-[96px] w-[96px]'} onError={() => handleError(file)}>
        {originNode}
      </div>
    );
  };

  const iconRender = (file: UploadFile) => {
    const status = file.status;
    const color = status === 'error' ? 'red' : 'rgba(0, 0, 0, 0.45)';

    return (
      <div
        className={
          'absolute left-0 top-0 flex h-full w-full items-center justify-center'
        }
      >
        <FileTextOutlined style={{ fontSize: 24, color }} />
      </div>
    );
  };

  const handleRemove = (file: UploadFile) => {
    removeFile(file.uid);
  };

  const handlePreview = (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const MAX_COUNT = 50;
  const possibleCount = useMemo(() => MAX_COUNT - urls.length, [urls]);

  const handleClickUpload = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (possibleCount > 0) {
      setUploadModalOpen(true);
    } else {
      void message.error(
        `업로드 가능한 파일의 개수(${MAX_COUNT}개)를 초과하였습니다. 삭제후 다시 시도해주세요.`
      );
    }
  };

  const FileUploaderClassName =
    '[&_.ant-upload-list-item-container]:h-[96px] ' +
    '[&_.ant-upload-list-item-container]:w-[96px] ' +
    '[&_.ant-upload-list-item-container:nth-child(n+11)]:hidden ' +
    '[&_.ant-upload-select]:h-[96px] ' +
    '[&_.ant-upload-select]:w-[96px] ' +
    (error ? '[&_.ant-upload-select]:border-[#f5222d]' : '');

  return (
    <>
      <div className='mb-4 mt-8 flex items-center gap-1'>
        <Typography.Paragraph className='mb-0'>
          {required && <span className={'mr-sm text-red-400'}>*</span>}
          {title}
        </Typography.Paragraph>
        {description && (
          <Typography.Text className='text-xs text-gray-400'>
            ({description})
          </Typography.Text>
        )}
      </div>
      <Upload
        listType='picture-card'
        fileList={fileListView}
        onRemove={handleRemove}
        isImageUrl={validateImage}
        itemRender={itemRender}
        iconRender={iconRender}
        className={FileUploaderClassName}
        onPreview={handlePreview}
        showUploadList={{
          showRemoveIcon: editable,
        }}
      >
        {editable && (
          <div
            className='flex h-full w-full items-center justify-center'
            onClick={handleClickUpload}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </div>
        )}
      </Upload>
      {fileList.length > 0 && (
        <Button
          icon={<EyeOutlined />}
          onClick={() => setContentsModalOpen(true)}
        >
          전체보기
        </Button>
      )}
      {!hideErrorMessage && error && (
        <Typography.Text className='text-xs' type='danger'>
          {error}
        </Typography.Text>
      )}
      {contentsModalOpen && (
        <ProductArchiveContentsModal
          urls={urls}
          onChange={changeUrls}
          onClose={() => setContentsModalOpen(false)}
          disabled={!editable}
        />
      )}
      {previewOpen && (
        <PreviewModal
          onClose={() => setPreviewOpen(false)}
          previewImage={previewImage}
        />
      )}
      {uploadModalOpen && (
        <ArchiveUploadModal
          onClose={() => setUploadModalOpen(false)}
          possibleCount={possibleCount}
          accept={accept}
          endUpload={(newUrls) => {
            changeUrls([...urls, ...newUrls]);
          }}
        />
      )}
    </>
  );
}
