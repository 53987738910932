import { TablePaginationConfig } from 'antd';
import { useMemo } from 'react';

import { EMarketStatus } from '../../../schema/types';
import {
  MARKETS_FOR_PRODUCT_GROUP_ALL_STATUSES,
  SELLING_CHANNEL_LABEL,
} from '../const';
import { useAdminMarketsForProductGroupQuery } from '../graphql/market.generated';
import {
  ProductMarketDataType,
  ProductMarketListModalStatusType,
} from '../types';

function useProductMarketListTable({
  productGroupId,
  type,
}: {
  productGroupId: number;
  type: ProductMarketListModalStatusType;
}) {
  const statuses: EMarketStatus[] =
    type === 'ALL' ? MARKETS_FOR_PRODUCT_GROUP_ALL_STATUSES : ['ON_SALE'];

  const { data: marketsData } = useAdminMarketsForProductGroupQuery({
    variables: {
      filter: {
        productGroupId,
        statuses,
      },
      order: {
        startedAt: type === 'ALL' ? 'DESC' : undefined,
        totalItemQuantity: type === 'ON_SALE' ? 'DESC' : undefined,
      },
    },
  });

  const totalCount = marketsData?.adminMarketsForProductGroup.count || 0;

  const dataSource: ProductMarketDataType[] = useMemo(
    () =>
      marketsData?.adminMarketsForProductGroup.edges.map(({ node }) => {
        return {
          key: node.id,
          id: node.id,
          name: node.name,
          session: node.session ? `${node.session}차` : '-',
          sellingChannel: node.sellingChannel
            ? SELLING_CHANNEL_LABEL[node.sellingChannel]
            : '-',
          totalOrderAmount: node.totalOrderAmount || 0,
          startedAt: node.startedAt,
          endedAt: node.endedAt || undefined,
          totalItemQuantity: node.totalItemQuantity || 0,
        };
      }) || [],
    [marketsData?.adminMarketsForProductGroup.edges]
  );

  const pagination: TablePaginationConfig | boolean =
    totalCount > 5
      ? {
          pageSize: 5,
          showTotal: () => `총 ${totalCount}개`,
        }
      : false;

  return {
    dataSource,
    pagination,
  };
}

export default useProductMarketListTable;
