import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ECostBearer, ESettlementTarget } from '../../../schema/types';
import {
  DELIVERY_FEE_BEARER_OPTIONS,
  DELIVERY_FEE_SETTLEMENT_TARGET,
} from '../const';
import { ExchangeRedeliveryUpdateFormValues } from '../types';

const ERROR_MESSAGE = '필수항목이 입력되지 않았습니다.';

const updateExchangeRedeliverySchema = yup.object().shape({
  isDeliveryFee: yup.boolean().required(ERROR_MESSAGE),
  deliveryFee: yup.number().optional().nullable(),
  deliveryFeeBearer: yup
    .mixed<ECostBearer>()
    .oneOf(DELIVERY_FEE_BEARER_OPTIONS.map(({ value }) => value))
    .when('isDeliveryFee', {
      is: true,
      then: (schema) => schema.required(ERROR_MESSAGE),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  deliveryFeeSettlementPrice: yup.number().optional().nullable(),
  deliveryFeeSettlementTarget: yup
    .mixed<ESettlementTarget>()
    .oneOf(DELIVERY_FEE_SETTLEMENT_TARGET.map(({ value }) => value))
    .when('isDeliveryFee', {
      is: true,
      then: (schema) => schema.required(ERROR_MESSAGE),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  promotionProductId: yup.number().required(ERROR_MESSAGE),
  originalItemIds: yup
    .array()
    .of(yup.number().required())
    .optional()
    .nullable(),
});

export const updateExchangeRedeliveryResolver =
  yupResolver<ExchangeRedeliveryUpdateFormValues>(
    updateExchangeRedeliverySchema
  );
