import { useFormContext } from 'react-hook-form';

import SelectField from '../../../../common/components/Fields/SelectField';
import { useAdminMarketEventProductQuery } from '../../graphql/products.generated';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../../types';

function EventProductFreeProvidedSelectField({
  eventIndex,
  productIndex,
  editable,
}: {
  eventIndex: number;
  productIndex: number;
  editable?: boolean;
}) {
  const { control, watch, setValue } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();

  const product = watch(`events.${eventIndex}.products.${productIndex}`);
  const name =
    `events.${eventIndex}.products.${productIndex}.isFreeProvided` as const;

  const { data } = useAdminMarketEventProductQuery({
    variables: {
      adminProductId: product.productId ? product.productId : 0,
    },
    skip: !product.productId,
  });

  return (
    <SelectField
      disabled={!editable}
      name={name}
      control={control}
      value={product.isFreeProvided ? 1 : 0}
      onChange={(v) => {
        setValue(name, !!v);

        if (v) {
          setValue(
            `events.${eventIndex}.products.${productIndex}`,
            {
              ...product,
              isFreeProvided: true,
              inhouseBuyPrice: null,
              sellerBearingCost: null,
              isPrepayment: null,
              wiredBearingCost: null,
              isWiredBearing: false,
              isSellerBearing: false,
            },
            { shouldDirty: true, shouldTouch: true, shouldValidate: true }
          );
          return;
        }

        if (!v && data) {
          setValue(
            `events.${eventIndex}.products.${productIndex}.inhouseBuyPrice`,
            data?.adminProduct?.inHousePurchasePrice,
            { shouldDirty: true, shouldTouch: true, shouldValidate: true }
          );
        }
      }}
      options={[
        { label: '유상', value: 0 },
        { label: '무상', value: 1 },
      ]}
    />
  );
}

export default EventProductFreeProvidedSelectField;
