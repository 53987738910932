import { useCallback } from 'react';
import { useDaumPostcodePopup, Address } from 'react-daum-postcode';

type PopupCallback = (params: {
  data: Address;
  fullAddress: string;
  extraAddress: string;
  zoneCode: string;
}) => void;

function usePostcodePopup(onComplete: PopupCallback) {
  const open = useDaumPostcodePopup();

  const handleComplete = useCallback(
    (data: Address) => {
      let fullAddress = data.address;
      let extraAddress = '';
      const zoneCode = data.zonecode;

      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress +=
            extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
        }
        fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
      }

      onComplete({ data, fullAddress, extraAddress, zoneCode });
    },
    [onComplete]
  );

  const openPopup = useCallback(
    () => open({ onComplete: handleComplete }),
    [open, handleComplete]
  );

  return openPopup;
}

export default usePostcodePopup;
