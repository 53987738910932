import { Observable } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { MessageInstance } from 'antd/es/message/interface';

import { authorization } from '../../domain/auth/helper';
import { ERROR } from '../constants/error';

const errorLink = (message: MessageInstance) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const graphQLError of graphQLErrors) {
        const { extensions, message } = graphQLError;
        switch (extensions?.code) {
          case ERROR.UNAUTHENTICATED:
          case ERROR.UNAUTHORIZED: {
            return new Observable((observer) => {
              void authorization.reissue().then((newAccessToken) => {
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    authorization: newAccessToken
                      ? `Bearer ${newAccessToken}`
                      : '',
                  },
                }));
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry last failed request
                forward(operation).subscribe(subscriber);
              });
            });
          }
          default: {
            console.error(extensions.code, message);
          }
        }
      }
    }

    if (networkError) {
      message.error('네트워크에 오류가 발생했습니다. 네트워크를 확인해주세요.');
      console.error(`[Network error]: ${JSON.stringify(networkError)}`);
    }
  });

export default errorLink;
