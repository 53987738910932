import { Spin, Typography } from 'antd';
import { Key, ReactNode } from 'react';

import SummaryCard from '../../../common/components/SummaryCard';
import { EOrderClaimStatus, EOrderSupportStatus } from '../../../schema/types';

export type SummaryItem = {
  key: EOrderClaimStatus | EOrderSupportStatus;
  label: string | ReactNode;
  count: number;
  onClick: () => void;
  disabled?: boolean;
};

export type SummaryProps = {
  label: string;
  activeKey?: string;
  items: SummaryItem[];
  loading?: boolean;
};

function Summary({ label, activeKey, items, loading }: SummaryProps) {
  return (
    <div>
      <Typography.Title level={5}>{label}</Typography.Title>
      <div className={'flex gap-4'}>
        {items
          ?.filter((item) => !item.disabled)
          .map((item) => (
            <div
              key={item.key as Key}
              className={items.length > 2 ? 'flex-1' : 'w-[264px]'}
            >
              <Spin spinning={loading}>
                <SummaryCard
                  label={item.label}
                  count={item.count}
                  active={activeKey === item.key}
                  onClick={item.onClick}
                  color='blue'
                />
              </Spin>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Summary;
