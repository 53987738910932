import dayjs from 'dayjs';
import { useMemo } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { PromotionReleaseListSearchParams } from '../types';

function usePromotionReleaseTableFilter() {
  const { searchParams } =
    useRouteSearchParams<PromotionReleaseListSearchParams>();

  return useMemo(() => {
    const promotionType = searchParams.promotionType || 'ALL';

    if (searchParams.summaryReleaseStatus) {
      return {
        promotionReleaseStatuses: [searchParams.summaryReleaseStatus],
        promotionTypes: promotionType === 'ALL' ? undefined : [promotionType],
      };
    }
    if (searchParams.summarySampleStatus) {
      return {
        sampleStatus: searchParams.summarySampleStatus,
        promotionTypes: promotionType === 'ALL' ? undefined : [promotionType],
      };
    }
    return {
      isMarket: searchParams.marketUndesignated
        ? searchParams.marketUndesignated === 'true'
          ? false
          : true
        : undefined,
      managerIds: searchParams.managerIds?.map((id) => Number(id)),
      period: searchParams.periodType && {
        type: searchParams.periodType,
        startedAt:
          searchParams.startedAt &&
          dayjs(searchParams.startedAt).startOf('date').toISOString(),
        endedAt:
          searchParams.endedAt &&
          dayjs(searchParams.endedAt).endOf('date').toISOString(),
      },
      promotionPayment: searchParams.isFreeProvided
        ? {
            isFreeProvided:
              searchParams.isFreeProvided === 'true' ? true : false,
            bearerTypes: searchParams.bearerTypes || [
              'SELLER_POST_PAYMENT',
              'SELLER_PREPAYMENT',
              'WIRED',
            ],
          }
        : undefined,
      promotionReleaseStatuses: searchParams.promotionReleaseStatuses,
      promotionTypes: promotionType === 'ALL' ? undefined : [promotionType],
      receiverPhoneNumber: searchParams.receiverPhoneNumber,
      searchKeyword: searchParams.searchKeyword,
    };
  }, [searchParams]);
}

export default usePromotionReleaseTableFilter;
