import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import Label from '../../../common/components/Fields/Label';
import TextField from '../../../common/components/Fields/TextField';
import { TABLE_LAST_ROW_CLASSNAME } from '../const';
import useEditableTable from '../hooks/useEditableTable';
import {
  EditableTableDataType,
  PromotionEventFormValues,
  PromotionFormValues,
} from '../types';

function EditableTable() {
  const methods = useFormContext<
    PromotionFormValues | PromotionEventFormValues
  >();

  const { control } = methods;

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { dataSources, handleOnCell, handleCellPaste } = useEditableTable();

  return (
    <div className='mt-4'>
      <ArrayField control={control} name='promotionReleases'>
        {({ fields, append, remove }) => (
          <>
            {!!selectedRowKeys.length && (
              <Button
                icon={<DeleteOutlined />}
                onClick={() => {
                  remove(
                    selectedRowKeys.map((key) =>
                      fields.findIndex((field) => field.key === key)
                    )
                  );
                  setSelectedRowKeys([]);
                }}
              >
                선택 삭제
              </Button>
            )}
            <Table<EditableTableDataType>
              className='mt-1'
              dataSource={fields}
              pagination={false}
              scroll={{ y: 320 }}
              onRow={(_, rowIndex) =>
                rowIndex === dataSources.length - 1
                  ? { className: TABLE_LAST_ROW_CLASSNAME }
                  : {}
              }
              rowSelection={{
                type: 'checkbox',
                columnWidth: 48,
                selectedRowKeys,
                onChange: (rowKeys) => setSelectedRowKeys(rowKeys),
              }}
              footer={() => (
                <div className='flex items-center justify-between'>
                  <Button
                    type='text'
                    icon={<PlusOutlined />}
                    disabled={fields.length > 99}
                    onClick={() => {
                      append({
                        receiverName: '',
                        receiverPhoneNumber: '',
                        zipCode: undefined,
                        receiverAddress: '',
                        receiverDetailAddress: undefined,
                        deliveryMessage: undefined,
                      });
                      setTimeout(() => {
                        // 새로운 열 추가시 스크롤 이동을 위한 코드
                        const element = document.querySelector(
                          `.${TABLE_LAST_ROW_CLASSNAME}`
                        );
                        element?.scrollIntoView({
                          block: 'end',
                          inline: 'nearest',
                          behavior: 'smooth',
                        });
                      }, 50);
                    }}
                  >
                    수령자 추가
                  </Button>
                  <div>총 {fields.length}건</div>
                </div>
              )}
            >
              <Table.Column<EditableTableDataType>
                title={<Label required>수령자명</Label>}
                key='receiverName'
                width={100}
                onCell={handleOnCell}
                render={(_, __, index) => (
                  <TextField
                    placeholder='수령자명'
                    maxLength={30}
                    control={control}
                    hideErrorMessage
                    name={`promotionReleases.${index}.receiverName`}
                    onPaste={(ev) => handleCellPaste(ev, index, 0)}
                  />
                )}
              />
              <Table.Column<EditableTableDataType>
                title={<Label required>휴대폰번호</Label>}
                key='receiverPhoneNumber'
                width={160}
                onCell={handleOnCell}
                render={(_, __, index) => (
                  <TextField
                    placeholder='휴대폰번호'
                    maxLength={30}
                    hideErrorMessage
                    control={control}
                    name={`promotionReleases.${index}.receiverPhoneNumber`}
                    onPaste={(ev) => handleCellPaste(ev, index, 1)}
                  />
                )}
              />
              <Table.Column<EditableTableDataType>
                title='우편번호'
                key='zipCode'
                width={100}
                onCell={handleOnCell}
                render={(_, __, index) => (
                  <TextField
                    placeholder='우편번호'
                    maxLength={20}
                    control={control}
                    name={`promotionReleases.${index}.zipCode`}
                    onPaste={(ev) => handleCellPaste(ev, index, 2)}
                  />
                )}
              />
              <Table.Column<EditableTableDataType>
                title={<Label required>배송지주소</Label>}
                key='receiverAddress'
                width={200}
                onCell={handleOnCell}
                render={(_, __, index) => (
                  <TextField
                    placeholder='배송지주소'
                    maxLength={100}
                    hideErrorMessage
                    control={control}
                    name={`promotionReleases.${index}.receiverAddress`}
                    onPaste={(ev) => handleCellPaste(ev, index, 3)}
                  />
                )}
              />
              <Table.Column<EditableTableDataType>
                title='배송메시지'
                key='deliveryMessage'
                width={200}
                onCell={handleOnCell}
                render={(_, __, index) => (
                  <TextField
                    placeholder='배송메시지'
                    maxLength={250}
                    control={control}
                    name={`promotionReleases.${index}.deliveryMessage`}
                    onPaste={(ev) => handleCellPaste(ev, index, 4)}
                  />
                )}
              />
            </Table>
          </>
        )}
      </ArrayField>
    </div>
  );
}

export default EditableTable;
