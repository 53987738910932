import { Select, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { EVENT_TYPE_TEXT } from '../const';
import { useAdminMarketInOrderQuery } from '../graphql/markets.generated';

type EventSelectProps = {
  marketId: number;
  onChange: (value: number) => void;
};

export default function EventSelect({ marketId, onChange }: EventSelectProps) {
  const { data } = useAdminMarketInOrderQuery({
    variables: {
      marketId,
    },
  });

  const options = useMemo(
    () =>
      data?.adminMarket.events?.map((event) => {
        const type = EVENT_TYPE_TEXT[event.type];
        const count = event.count ? ` / ${event.count}명` : '';
        const comment = event.comment ? ` / ${event.comment}` : '';

        return {
          label: type + count + comment,
          value: event.id,
        };
      }) ?? [],
    [data]
  );

  const [eventId, setEventId] = useState<number>();

  useEffect(() => {
    const id = data?.adminMarket.events?.[0]?.id;

    if (!eventId && id) {
      setEventId(id);
      onChange(id);
    }
  }, [data?.adminMarket.events]);

  return (
    <Select
      options={options}
      className='w-full'
      value={eventId}
      onChange={(value) => {
        setEventId(value);
        onChange(value);
      }}
      optionRender={(oriOption) => {
        return (
          <div className='flex items-center gap-1'>
            <Typography.Text className='w-[360]' ellipsis>
              {oriOption.label}
            </Typography.Text>
            <span className='flex-1 text-xs text-black/25'>{`이벤트ID: ${
              oriOption.value ?? ''
            }`}</span>
          </div>
        );
      }}
    />
  );
}
