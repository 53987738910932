import { App } from 'antd';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useFormError } from '../../../common/hooks/useFormError';
import {
  AdminPromotionReleaseDocument,
  AdminPromotionReleaseQuery,
} from '../graphql/promotionRelease.generated';
import { useAdminUpdateExchangePromotionMutation } from '../graphql/redelivery.generated';
import { ExchangeRedeliveryUpdateFormValues } from '../types';
import { updateExchangeRedeliveryResolver } from '../validator/updateExchangePromotionValidator';

function useUpdateExchangePromotion({
  onClose,
  promotionRelease,
}: {
  onClose: () => void;
  promotionRelease: AdminPromotionReleaseQuery['adminPromotionRelease'];
}) {
  const { message, modal } = App.useApp();
  const [updateExchangePromotionmutation] =
    useAdminUpdateExchangePromotionMutation();
  const { promotion, promotionProducts } = promotionRelease;
  const hasOrderConfirm = useMemo(
    () =>
      promotion.promotionReleases?.some(
        ({ releaseStatus }) => releaseStatus === 'ORDER_CONFIRMED'
      ),
    [promotion.promotionReleases]
  );
  const { onInvalid } = useFormError();
  const methods = useForm<ExchangeRedeliveryUpdateFormValues>({
    defaultValues: {
      isDeliveryFee: promotion.isDeliveryFee,
      deliveryFee: promotion.deliveryFee,
      deliveryFeeBearer: promotion.deliveryFeeBearer,
      deliveryFeeSettlementPrice: promotion.deliveryFeeSettlementPrice,
      deliveryFeeSettlementTarget: promotion.deliveryFeeSettlementTarget,
      promotionProductId: promotionProducts[0].originalProductId,
      originalItemIds: promotionProducts[0].promotionItems.map(
        ({ originalItem }) => originalItem?.id
      ),
    },
    resolver: updateExchangeRedeliveryResolver,
  });

  const updateExchangePromotion = useCallback(
    (data: ExchangeRedeliveryUpdateFormValues) => {
      const promotionProductId = data.promotionProductId;

      if (!promotionProductId) return;

      void updateExchangePromotionmutation({
        variables: {
          adminUpdateExchangePromotionId: promotion.id,
          exchangePromotionData: {
            promotionProductId,
            isDeliveryFee: data.isDeliveryFee,
            deliveryFee: data.isDeliveryFee ? data.deliveryFee : null,
            deliveryFeeBearer: data.isDeliveryFee
              ? data.deliveryFeeBearer
              : null,
            deliveryFeeSettlementPrice: data.isDeliveryFee
              ? data.deliveryFeeSettlementPrice
              : null,
            deliveryFeeSettlementTarget: data.isDeliveryFee
              ? data.deliveryFeeSettlementTarget
              : null,
            promotionItems: data.originalItemIds?.map((id) => ({
              originalItemId: id,
            })),
          },
        },
        refetchQueries: [AdminPromotionReleaseDocument],
        onCompleted() {
          void message.success('저장이 완료되었습니다.');
          onClose();
        },
        onError() {
          void message.error('저장을 실패하였습니다.');
        },
      });
    },
    []
  );

  const onSubmit = methods.handleSubmit((data) => {
    if (hasOrderConfirm) {
      void modal.confirm({
        title: '정보 변경',
        content:
          '발주확인 상태입니다. 물류팀 확인 후 정보 변경을 진행해 주세요.',
        onOk: () => updateExchangePromotion(data),
        okText: '확인했습니다',
        cancelText: '취소',
      });
      return;
    }
    void modal.confirm({
      title: '상세정보 일괄수정',
      content:
        '수령자가 여러 명일 경우, 일괄로 수정사항이 적용됩니다. 상세정보를 수정하시겠습니까?',
      onOk: () => updateExchangePromotion(data),
      okText: '네',
      cancelText: '아니오',
    });
  }, onInvalid);

  return { methods, onSubmit };
}

export default useUpdateExchangePromotion;
