import { Button, Select } from 'antd';

import PageLoader from '../../../../common/components/PageLoader';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import CreatePromotionModal from '../../../promotion-release/components/CreatePromotionModal/CreatePromotionModal';
import { CLAIM_STATUS } from '../../const';
import { useUpdateClaim } from '../../hooks/useUpdateClaim';
import { OrderForClaimStatus } from '../../type';
import UpdateClaimModal from '../UpdateClaimModal';

function UpdateClaimSelect({
  order,
  renderUpdateReturnReceipt,
  renderUpdateExchangeReceipt,
}: {
  order: OrderForClaimStatus;
  renderUpdateReturnReceipt: boolean;
  renderUpdateExchangeReceipt: boolean;
}) {
  const { hasPermission } = usePermissions('ORDER_EDIT');
  const {
    initialize,
    adminOrderGroup,
    currentOrder,
    loading,
    handleChange,
    options,
    targetClaim,
    modalType,
    status,
  } = useUpdateClaim(order);

  const openUpdateModal =
    modalType === 'update' &&
    !!adminOrderGroup &&
    !!targetClaim &&
    !!currentOrder;

  const openCompletedModal =
    modalType === 'completed' && adminOrderGroup && targetClaim && currentOrder;

  return (
    <div className={'flex flex-col items-center text-center'}>
      <Select
        disabled={!hasPermission || options.length <= 1}
        className={'w-[128px]'}
        options={options}
        value={status}
        onChange={(_, option) => {
          if (!Array.isArray(option)) {
            const claimType = option.data.claimType;
            const status = option.data.claimStatus;

            handleChange(status, claimType);
          }
        }}
        placeholder={'변경할 클레임을 선택해주세요.'}
      />

      {renderUpdateReturnReceipt && (
        <Button
          type={'link'}
          onClick={() => handleChange('RETURN_RECEIPT', 'RETURN')}
          disabled={!hasPermission}
        >
          접수내역 수정
        </Button>
      )}
      {renderUpdateExchangeReceipt && (
        <Button
          type={'link'}
          onClick={() => handleChange('EXCHANGE_RECEIPT', 'EXCHANGE')}
          disabled={!hasPermission}
        >
          접수내역 수정
        </Button>
      )}

      {openUpdateModal && (
        <UpdateClaimModal
          targetClaimStatus={targetClaim[1]}
          targetClaimType={targetClaim[0]}
          onCancel={initialize}
          orderGroup={adminOrderGroup}
          order={currentOrder}
        />
      )}

      {openCompletedModal && (
        <UpdateClaimModal
          targetClaimStatus={targetClaim[1]}
          targetClaimType={targetClaim[0]}
          onCancel={initialize}
          orderGroup={adminOrderGroup}
          order={currentOrder}
          title={CLAIM_STATUS[targetClaim[1]]}
          productsTitle={`${CLAIM_STATUS[targetClaim[1]]} 상품내역`}
          productsSelectable={false}
        />
      )}

      {modalType === 'promotion-release' && (
        <CreatePromotionModal
          onClose={initialize}
          orderCode={order.orderCode}
        />
      )}

      {loading && <PageLoader />}
    </div>
  );
}

export default UpdateClaimSelect;
