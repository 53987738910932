import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OperatorsInOperatorQueryVariables = Types.Exact<{
  order?: Types.InputMaybe<Types.Scalars['Order']['input']>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type OperatorsInOperatorQuery = { __typename?: 'Query', adminOperators: { __typename?: 'OperatorPageGraphqlType', count: number, edges: Array<{ __typename?: 'PaginatedOperatorGraphqlTypeEdge', node: { __typename?: 'OperatorGraphqlType', name: string, id: number, email: string, roles: Array<Types.ERole | null> } }> } };


export const OperatorsInOperatorDocument = gql`
    query OperatorsInOperator($order: Order, $pagination: Pagination) {
  adminOperators(order: $order, pagination: $pagination) {
    count
    edges {
      node {
        name
        id
        email
        roles
      }
    }
  }
}
    `;

/**
 * __useOperatorsInOperatorQuery__
 *
 * To run a query within a React component, call `useOperatorsInOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorsInOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorsInOperatorQuery({
 *   variables: {
 *      order: // value for 'order'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useOperatorsInOperatorQuery(baseOptions?: Apollo.QueryHookOptions<OperatorsInOperatorQuery, OperatorsInOperatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperatorsInOperatorQuery, OperatorsInOperatorQueryVariables>(OperatorsInOperatorDocument, options);
      }
export function useOperatorsInOperatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperatorsInOperatorQuery, OperatorsInOperatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperatorsInOperatorQuery, OperatorsInOperatorQueryVariables>(OperatorsInOperatorDocument, options);
        }
export type OperatorsInOperatorQueryHookResult = ReturnType<typeof useOperatorsInOperatorQuery>;
export type OperatorsInOperatorLazyQueryHookResult = ReturnType<typeof useOperatorsInOperatorLazyQuery>;
export type OperatorsInOperatorQueryResult = Apollo.QueryResult<OperatorsInOperatorQuery, OperatorsInOperatorQueryVariables>;