import {
  EOrderClaimCostBearer,
  EOrderClaimReasonType,
  EOrderClaimStatus,
  EOrderClaimTarget,
  EOrderClaimType,
  EOrderSupportChannelType,
  EOrderSupportRequestType,
  EOrderSupportStatus,
  ESalesChannel,
  ESalesChannelType,
  ESettlementTarget,
} from '../../schema/types';

import { ClaimListSearchParams } from './type';

export const INITIAL_ORDER_CLAIM_STATUS: {
  [key in EOrderClaimType]: EOrderClaimStatus;
} = {
  RETURN: 'RETURN_REQUESTED',
  EXCHANGE: 'EXCHANGE_REQUESTED',
  CANCEL: 'CANCEL_REQUESTED',
  PARTIAL_CANCEL: 'CANCEL_REQUESTED',
  PARTIAL_EXCHANGE: 'EXCHANGE_COMPLETED',
  PARTIAL_RETURN: 'RETURN_COMPLETED',
};

export const RETURN_REASON_OPTIONS: {
  label: string;
  value: EOrderClaimReasonType;
  data: EOrderClaimTarget;
}[] = [
  { label: '고객 귀책 - 주문실수', value: 'ORDER_MISTAKE', data: 'BUYER' },
  { label: '고객 귀책 - 단순변심', value: 'CHANGE_MIND', data: 'BUYER' },
  {
    label: '판매자 귀책 - 파손 및 불량',
    value: 'PRODUCT_DEFECT',
    data: 'SELLER',
  },
  {
    label: '판매자 귀책 - 오배송 및 지연',
    value: 'DELIVERY_DELAY_OR_WRONG',
    data: 'SELLER',
  },
  {
    label: '케미스트 처리',
    value: 'CANCEL',
    data: 'BUYER',
  },
];

export const EXCHANGE_REASON_OPTIONS: {
  label: string;
  value: EOrderClaimReasonType;
  data: EOrderClaimTarget;
}[] = [
  { label: '고객 귀책 - 주문실수', value: 'ORDER_MISTAKE', data: 'BUYER' },
  {
    label: '고객 귀책 - 색상, 사이즈 변경',
    value: 'CHANGE_COLOR_OR_SIZE',
    data: 'BUYER',
  },
  {
    label: '판매자 귀책 - 파손 및 불량',
    value: 'PRODUCT_DEFECT',
    data: 'SELLER',
  },
  {
    label: '판매자 귀책 - 오배송 및 지연',
    value: 'DELIVERY_DELAY_OR_WRONG',
    data: 'SELLER',
  },
  {
    label: '케미스트 처리',
    value: 'CANCEL',
    data: 'BUYER',
  },
];

export const CREATE_CLAIM_TARGET_STATUS: {
  [key in EOrderClaimType]: EOrderClaimStatus;
} = {
  RETURN: 'RETURN_RECEIPT',
  EXCHANGE: 'EXCHANGE_RECEIPT',
  CANCEL: 'CANCEL_COMPLETED',
  PARTIAL_CANCEL: 'CANCEL_COMPLETED',
  PARTIAL_EXCHANGE: 'EXCHANGE_COMPLETED',
  PARTIAL_RETURN: 'RETURN_COMPLETED',
};
export const CREATE_CLAIM_DESCRIPTIONS: { [key in EOrderClaimType]: string } = {
  RETURN:
    '주문자와 협의 완료된 경우에 반품접수를 진행해주시기 바랍니다.\n합배송된 동일 주문번호 건에 대해서 동시에 반품접수 처리가 가능합니다.\n해당 기능으로 반품 접수까지만 적용되며, 실제 반품 환불 처리는 각 판매채널에서 직접 해주셔야 합니다.',
  EXCHANGE:
    '주문자와 협의 완료된 경우에 교환접수를 진행해주시기 바랍니다.\n합배송된 동일 주문번호 건에 대해서 동시에 교환접수 처리가 가능합니다.\n부분반품 또는 다른품목으로 부분교환을 한 경우에는 교환접수가 불가능합니다. 부분교환으로 처리해주세요.',
  CANCEL:
    '판매채널이 ‘셀러쇼핑몰 - 주문서변환’ 일 경우에만 자체 취소처리가 가능합니다. 정확한 정산을 위해 데이터를 기록할 뿐 실제 환불처리는 결제가 발생한 셀러쇼핑몰에서 진행되어야 합니다. 셀러쇼핑몰 외의 판매채널 취소를 원하실 경우에는 각 채널에서 직접 취소환불 처리해주세요.\n동일 주문번호 건에 대해서 동시에 취소처리가 가능합니다.',
  // RESPONSE_MANAGEMENT: '',
  PARTIAL_RETURN:
    '부분반품은 각 판매채널에서 기능을 제공하고 있지 않습니다. 단, 케미 주문은 케미의 반품환불을 이용해주세요.\n차감/회수 재고를 파악하고 정확한 정산을 진행할 수 있도록 와이어드민을 이용해주세요. 여기서 진행되는 반품은 각 판매채널에 적용되지 않습니다.',
  PARTIAL_EXCHANGE:
    '부분교환은 각 판매채널에서 기능을 제공하고 있지 않습니다.\n차감/회수 재고를 파악하고 정확한 정산을 진행할 수 있도록 와이어드민을 이용해주세요. 여기서 진행되는 교환은 각 판매채널에 적용되지 않습니다.',
  PARTIAL_CANCEL:
    '부분취소는 각 판매채널에서 기능을 제공하고 있지 않습니다.\n주문한 수량 중 일부를 취소할 경우에만 와이어드민의 정산을 위해 이용해주세요.',
};

export const UPDATE_CLAIM_DESCRIPTIONS: {
  [key in EOrderClaimStatus]?: string;
} = {
  RETURN_COMPLETED:
    '판매 채널이 셀러쇼핑몰일 경우에만 반품완료 처리가 가능합니다. 정확한 정산을 위해 데이터를 기록할 뿐 실제 환불처리는 결제가 발생한 셀러 쇼핑몰에서 진행되어야 합니다.',
  EXCHANGE_COMPLETED:
    '주문자와 협의 완료된 경우에 교환처리를 진행해주시기 바랍니다.',

  RETURN_RECEIPT:
    '주문자와 협의 완료된 경우에 반품접수를 진행해주시기 바랍니다.\n합배송된 동일 주문번호 건에 대해서 동시에 반품접수 처리가 가능합니다.\n해당 기능으로 반품 접수까지만 적용되며, 실제 반품 환불 처리는 각 판매채널에서 직접 해주셔야 합니다.',
  EXCHANGE_RECEIPT:
    '주문자와 협의 완료된 경우에 교환접수를 진행해주시기 바랍니다.\n합배송된 동일 주문번호 건에 대해서 동시에 교환접수 처리가 가능합니다.\n부분반품 또는 다른품목으로 부분교환을 한 경우에는 교환접수가 불가능합니다. 부분교환으로 처리해주세요.',
  CANCEL_COMPLETED:
    '판매채널이 ‘셀러쇼핑몰 - 주문서변환’ 일 경우에만 자체 취소처리가 가능합니다. 정확한 정산을 위해 데이터를 기록할 뿐 실제 환불처리는 결제가 발생한 셀러쇼핑몰에서 진행되어야 합니다. 셀러쇼핑몰 외의 판매채널 취소를 원하실 경우에는 각 채널에서 직접 취소환불 처리해주세요.\n동일 주문번호 건에 대해서 동시에 취소처리가 가능합니다.',
};

export const CREATE_CLAIM_CONFIRM_MESSAGES: {
  [key in EOrderClaimType]: { title: string; message: string };
} = {
  RETURN: {
    title: '반품접수',
    message: '선택하신 주문을 반품접수 처리하시겠습니까?',
  },
  EXCHANGE: {
    title: '교환접수',
    message: '선택하신 주문을 교환접수 처리하시겠습니까?',
  },
  CANCEL: {
    title: '취소처리',
    message: '선택하신 주문을 취소처리하시겠습니까?',
  },
  PARTIAL_RETURN: {
    title: '부분반품처리',
    message:
      '선택하신 주문을 접수처리하시겠습니까?\n반품배송비가 발생했을 경우 정산관리 > 입금내역에서 입금을 등록해주세요.',
  },
  PARTIAL_EXCHANGE: {
    title: '부분교환처리',
    message:
      '선택하신 주문을 접수처리하시겠습니까?\n교환배송비가 발생했을 경우 정산관리 > 입금내역에서 입금을 등록해주세요.',
  },
  PARTIAL_CANCEL: {
    title: '부분취소처리',
    message: '선택하신 주문을 부분취소처리하시겠습니까?',
  },
};

export const CREATE_CLAIM_SUCCESS_MESSAGES: {
  [key in EOrderClaimType]: string;
} = {
  RETURN: '반품접수가 등록되었습니다.',
  EXCHANGE: '교환접수가 등록되었습니다.',
  CANCEL: '취소처리가 등록되었습니다.',
  PARTIAL_RETURN: '부분반품접수가 완료되었습니다.',
  PARTIAL_EXCHANGE: '부분교환접수가 완료되었습니다.',
  PARTIAL_CANCEL: '부분취소처리가 완료되었습니다.',
};

export const CLAIM_STATUS_OPTIONS: {
  label: string;
  value: EOrderClaimStatus;
}[] = [
  { value: 'CANCEL_COMPLETED', label: '취소완료' },
  { value: 'CANCEL_NOT_APPROVED', label: '취소미승인' },
  { value: 'CANCEL_REQUESTED', label: '취소요청' },
  { value: 'EXCHANGE_CANCELED', label: '교환취소' },
  { value: 'EXCHANGE_COMPLETED', label: '교환완료' },
  { value: 'EXCHANGE_RECEIPT', label: '교환접수' },
  { value: 'EXCHANGE_REQUESTED', label: '교환요청' },
  { value: 'EXCHANGE_RE_DELIVERY', label: '교환재출고' },
  { value: 'RETURN_CANCELED', label: '반품취소' },
  { value: 'RETURN_COMPLETED', label: '반품완료' },
  { value: 'RETURN_RECEIPT', label: '반품접수' },
  { value: 'RETURN_RECLAIM_COMPLETED', label: '반품회수완료' },
  { value: 'RETURN_REQUESTED', label: '반품요청' },
  { value: 'RETURN_HOLD_COMPLETED', label: '반품완료대기' },
];

export const CLAIM_STATUS: { [key in EOrderClaimStatus]: string } = {
  CANCEL_COMPLETED: '취소완료',
  CANCEL_NOT_APPROVED: '취소미승인',
  CANCEL_REQUESTED: '취소요청',
  EXCHANGE_CANCELED: '교환취소',
  EXCHANGE_COMPLETED: '교환완료',
  RETURN_HOLD_COMPLETED: '반품완료대기',
  EXCHANGE_RECEIPT: '교환접수',
  EXCHANGE_REQUESTED: '교환요청',
  EXCHANGE_RE_DELIVERY: '교환재출고',
  RETURN_CANCELED: '반품취소',
  RETURN_COMPLETED: '반품완료',
  RETURN_RECEIPT: '반품접수',
  RETURN_RECLAIM_COMPLETED: '반품회수완료',
  RETURN_REQUESTED: '반품요청',
};

export const CREATE_CLAIM_TITLES: { [key in EOrderClaimType]: string } = {
  RETURN: '반품접수',
  EXCHANGE: '교환접수',
  CANCEL: '취소처리',
  // RESPONSE_MANAGEMENT: '응대관리',
  PARTIAL_RETURN: '부분반품',
  PARTIAL_EXCHANGE: '부분교환',
  PARTIAL_CANCEL: '부분취소',
};
export const SALES_CHANNEL_MAP: { [key in ESalesChannel]: string } = {
  KEMI: '케미',
  IMWEB: '아임웹',
  MANUAL: '기타',
  SMART_STORE: '스마트스토어',
  BALJU: '발주서 변환',
};
export const SALES_CHANNEL_TYPE_MAP: { [key in ESalesChannelType]: string } = {
  KEMI: '케미',
  PB_SHOP: 'PB몰',
  SELLER_SHOP: '셀러쇼핑몰',
  PB_SHOP_IMWEB: 'PB몰 - 아임웹',
  PB_SHOP_SMART_STORE: 'PB몰 - 스마트스토어',
};

export const SEARCH_PERIOD_TYPE_OPTIONS: {
  label: string;
  value: ClaimListSearchParams['periodType'];
}[] = [
  { label: '주문일시', value: 'ORDERED_AT' },
  { label: '결제일시', value: 'PAID_AT' },
  { label: '주문서변환일시', value: 'ORDER_CONVERTED_AT' },
  { label: '마켓기간', value: 'MARKET_OPEN_AT' },
  { label: '클레임접수일', value: 'CLAIM_RECEIPT_AT' },
  { label: '상태변경일', value: 'CLAIM_UPDATED_AT' },
];

export const SEARCH_SUPPORT_PERIOD_TYPE_OPTIONS: {
  label: string;
  value: ClaimListSearchParams['periodType'];
}[] = [
  { label: '주문일시', value: 'ORDERED_AT' },
  { label: '결제일시', value: 'PAID_AT' },
  { label: '주문서변환일시', value: 'ORDER_CONVERTED_AT' },
  { label: '마켓기간', value: 'MARKET_OPEN_AT' },
  { label: '응대접수일', value: 'SUPPORT_RECEIPT_AT' },
  { label: '응대상태변경일', value: 'SUPPORT_MESSAGE_UPDATED_AT' },
];

export const orderSupportChannelOptions: {
  label: string;
  value: EOrderSupportChannelType;
}[] = [
  { value: 'SELLER', label: '셀러' },
  { value: 'CUSTOMER', label: '고객' },
  { value: 'INTERNAL', label: '내부' },
  { value: 'VENDOR', label: '업체' },
];

export const orderSupportChannels = orderSupportChannelOptions.reduce(
  (acc, option) => {
    acc[option.value] = option.label;
    return acc;
  },
  {} as { [key in EOrderSupportChannelType]: string }
);

export const orderSupportRequestTypeOptions: {
  label: string;
  value: EOrderSupportRequestType;
}[] = [
  { value: 'DAMAGE', label: '파손' },
  { value: 'DEFECT', label: '불량' },
  { value: 'WRONG_DELIVERY_', label: '오배송' },
  { value: 'EXCHANGE', label: '교환' },
  { value: 'LOWEST_PRICE_ISSUE', label: '최저가이슈' },
  { value: 'NON_RECEIPT', label: '미수령' },
  { value: 'OTHER', label: '기타' },
  { value: 'PARTIAL_OMISSION', label: '부분누락' },
  { value: 'RECOVERY', label: '회수' },
  { value: 'RETURN', label: '반품' },
];

export const orderSupportRequestType = orderSupportRequestTypeOptions.reduce(
  (acc, option) => {
    acc[option.value] = option.label;
    return acc;
  },
  {} as { [key in EOrderSupportRequestType]: string }
);

export const orderSupportMessagePresetOptions = [
  { value: '0', label: '고객 부재' },
  { value: '1', label: '유선 응대' },
  { value: '2', label: '문자 응대' },
  { value: '3', label: '채널톡 응대' },
  { value: '4', label: '카카오톡 응대' },
  { value: '5', label: '담당자 전달 완료' },
  { value: '6', label: '본사 직접 연락' },
  { value: '7', label: '업체 접수 완료' },
  { value: '8', label: '업체 처리 완료' },
];

export const orderSupportStatusOptions: {
  label: string;
  value: EOrderSupportStatus;
}[] = [
  { value: 'SUPPORT_RECEIPT', label: '응대요청' },
  { value: 'SUPPORT_PROGRESS', label: '응대진행중' },
  { value: 'SUPPORT_COMPLETED', label: '응대완료' },
  { value: 'SUPPORT_CANCELED', label: '응대취소' },
];

export const orderSupportStatus = orderSupportStatusOptions.reduce(
  (acc, option) => {
    acc[option.value] = option.label;
    return acc;
  },
  {} as { [key in EOrderSupportStatus]: string }
);

export const orderClaimCostBearerOptions: {
  value: EOrderClaimCostBearer;
  label: string;
}[] = [
  { value: 'WIRED', label: '와이어드' },
  { value: 'SELLER', label: '셀러' },
  { value: 'DELIVERY', label: '물류' },
];

export const settlementTargetOptions: {
  value: ESettlementTarget;
  label: string;
}[] = [
  { value: 'WIRED', label: '와이어드' },
  { value: 'SELLER', label: '셀러' },
  { value: 'VENDOR', label: '업체' },
];

export const shouldDeleteQueriesAfterClaimUpdate = [
  'adminOrders', // order list
  'adminOrderGroup', // claim modal target orders
  'adminCountOrderByLatestClaimStatuses', // order list tabs
  'adminCountOrderSupportStatuses', // order support list tabs
  'adminOrderSupportMessages', // order support modal message list
];

export const INACTIVE_CLAIM_STATUSES: EOrderClaimStatus[] = [
  'RETURN_COMPLETED',
  'EXCHANGE_COMPLETED',
  'CANCEL_COMPLETED',
  'CANCEL_NOT_APPROVED',
];
