import { App, Select } from 'antd';
import { useState } from 'react';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import ManagerSelect from '../../../../common/components/Fields/ManagerSelect';
import Modal from '../../../../common/components/Modal';
import { useMeQuery } from '../../../../common/graphql/me.generated';
import {
  ESettlementSheetType,
  SettlementSheetInputGraphqlType,
} from '../../../../schema/types';
import {
  SELLER_SETTLEMENT_TYPE_LABEL,
  SETTLEMENT_SHEET_TYPE_OPTIONS,
} from '../../const';
import {
  isDifferentSellerSettlementType,
  getOrderCodesFromSettlementWithDifferentMarketId,
  getCreateSettlementSheetOrderCodesText,
  getOrderCodesFromSettlementWithNoMarketId,
} from '../../helper/settlementList';
import useCreateSettlementSheet from '../../hooks/useCreateSettlementSheet';
import useCreateSettlementSheetModal from '../../hooks/useCreateSettlementSheetModal';
import { SettlementListTableDataType } from '../../types';

import CreateSettlementSheetModalMarketName from './CreateSettlementSheetModalMarketName';
import CreateSettlementSheetModalVendorName from './CreateSettlementSheetModalVendorName';

type CreateSettlementSheetModalProps = {
  onClose: () => void;
  type?: ESettlementSheetType;
  selectedSettlements: SettlementListTableDataType[];
};

function CreateSettlementSheetModal({
  onClose,
  type: initialType = 'MARKET',
  selectedSettlements,
}: CreateSettlementSheetModalProps) {
  const { data: user } = useMeQuery();
  const [type, setType] = useState<ESettlementSheetType>(initialType);
  const [sellerId, setSellerId] = useState(user?.adminMe.id);
  const { modal } = App.useApp();

  const { setMarketId, market, setVendorId, vendor } =
    useCreateSettlementSheetModal({
      selectedSettlements,
    });

  const { createSettlementSheet } = useCreateSettlementSheet();

  const validateConfirmModals = async () => {
    let cancel = false;
    const orderCodesFromSettlementWithDifferentMarketId =
      getOrderCodesFromSettlementWithDifferentMarketId(selectedSettlements);

    const orderCodesFromSettlementWithNoMarketId =
      getOrderCodesFromSettlementWithNoMarketId(selectedSettlements);

    const marketSellerSettlementType =
      market?.sellerSettlementInfo?.type || 'FREELANCER';

    if (orderCodesFromSettlementWithDifferentMarketId.length > 1) {
      const orderCodesText = getCreateSettlementSheetOrderCodesText(
        orderCodesFromSettlementWithDifferentMarketId
      );

      await modal.confirm({
        title: '정산서 생성',
        content: (
          <>
            <p>
              다른 마켓id를 가진 와이어드품목주문코드가 있습니다. <br />
              선택한 마켓으로 정산서 생성을 진행하시겠습니까?
            </p>
            <p className='text-[#00000073]'>{orderCodesText}</p>
          </>
        ),
        onCancel: () => {
          cancel = true;
        },
      });
    } else if (orderCodesFromSettlementWithNoMarketId.length) {
      const orderCodesText = getCreateSettlementSheetOrderCodesText(
        orderCodesFromSettlementWithNoMarketId
      );

      await modal.confirm({
        title: '정산서 생성',
        content: (
          <>
            <p>
              선택하신 항목 중 마켓ID가 없는 와이어드품목주문코드가 있습니다.
              <br />
              정산서 생성한 마켓으로 덮어씌우시겠습니까?
            </p>
            <p className='text-[#00000073]'>{orderCodesText}</p>
          </>
        ),
        onCancel: () => {
          cancel = true;
        },
      });
    } else if (
      isDifferentSellerSettlementType(
        selectedSettlements,
        marketSellerSettlementType
      )
    ) {
      const sellerSettlementTypeText =
        SELLER_SETTLEMENT_TYPE_LABEL[marketSellerSettlementType];

      await modal.confirm({
        title: `[${sellerSettlementTypeText}]로 정산서 생성`,
        content: `선택한 모든 상품주문들의 셀러정산금액을 [${sellerSettlementTypeText}] 마진으로 업데이트합니다.`,
        cancelButtonProps: {
          style: { display: 'none' },
        },
      });
    }

    return { isCanceled: cancel };
  };

  const handleOk = async () => {
    const { isCanceled } = await validateConfirmModals();

    if (isCanceled) return;

    const input: SettlementSheetInputGraphqlType = {
      type,
      settlementIds: selectedSettlements.map((settlement) => settlement.id),
      sellerId,
    };

    if (type === 'MARKET' && market) {
      input['marketId'] = market.id;
      input['sellerSettlementType'] =
        market.sellerSettlementInfo?.type || 'FREELANCER';
    }

    if (type === 'VENDOR' && vendor) {
      input['vendorId'] = vendor.id;
    }

    await createSettlementSheet(input);
    onClose();
  };

  return (
    <>
      <Modal
        onCancel={onClose}
        title='새로운 정산서 생성'
        okText={'생성'}
        onOk={handleOk}
      >
        <div className='flex flex-col gap-6 pt-4'>
          <FieldWithLabel label={'구분'} required>
            <Select
              className='w-40'
              defaultValue={type ? type : 'MARKET'}
              options={SETTLEMENT_SHEET_TYPE_OPTIONS}
              onChange={(value: ESettlementSheetType) => setType(value)}
            ></Select>
          </FieldWithLabel>
          <FieldWithLabel label={'정산서 담당자'} required>
            <ManagerSelect
              className='w-40'
              value={sellerId}
              onChange={setSellerId}
            />
          </FieldWithLabel>
          {type === 'MARKET' && market && (
            <CreateSettlementSheetModalMarketName
              market={market}
              onChange={(marketId) => setMarketId(marketId)}
            />
          )}
          {type === 'VENDOR' && vendor && (
            <CreateSettlementSheetModalVendorName
              vendor={vendor}
              onChange={(vendorId) => setVendorId(vendorId)}
            />
          )}
        </div>
      </Modal>
    </>
  );
}

export default CreateSettlementSheetModal;
