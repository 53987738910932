import { App, Select, Tooltip, Typography } from 'antd';
import { debounce, merge } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  useLocalProductOptionMutation,
  useLocalProductOptionsQuery,
} from '../../graphql/localProductOptions';
import { useItemsLazyQuery } from '../../graphql/productGroup.generated';
import { itemCodeGenerator } from '../../helper/generator';
import { ItemType, ProductOptionSelectField } from '../../types';
import DropdownItem from '../ProductOptionDropdownItem';

type ProductOptionSelectFieldProps = {
  index: number;
  disabled?: boolean;
  placeholder?: string;
  hideErrorMessage?: boolean;
};

const MAX_OPTION_COUNT = 100;
const MAX_OPTION_MESSAGE = `옵션은 최대 ${MAX_OPTION_COUNT}개까지만 선택 가능합니다.`;
const TAKE = 50;

export default function ProductOptionSelectField({
  index,
  disabled,
  placeholder,
  hideErrorMessage,
}: ProductOptionSelectFieldProps) {
  const { message } = App.useApp();
  const { control, setValue, getValues, watch } =
    useFormContext<ProductOptionSelectField>();
  const { setLocalProductOptions } = useLocalProductOptionMutation();
  const { data } = useLocalProductOptionsQuery();
  const brandId = watch('brandId');

  const [fetchItems, { data: optionsData, fetchMore }] = useItemsLazyQuery();
  const [searchOption, setSearchOption] = useState<string>('');

  useEffect(() => {
    brandId &&
      fetchItems({
        variables: {
          pagination: {
            skip: 0,
            take: 100,
          },
          filter: {
            brandId,
            name: searchOption ? searchOption : undefined,
          },
        },
      });
  }, [brandId, fetchItems, searchOption]);

  const queryOptions = useMemo(
    () =>
      optionsData?.adminItems.edges?.map(
        ({ node: { name, code, productGroupNames } }) => ({
          value: code,
          label: name,
          productGroupNames,
        })
      ) || [],
    [optionsData?.adminItems.edges]
  );

  const localOptions = useMemo(
    () =>
      (data?.localProductOptions || []).map(
        ({ name, code, productGroupNames }) => ({
          value: code,
          label: name,
          productGroupNames,
        })
      ) || [],
    [data?.localProductOptions]
  );

  const options = useMemo(
    () => [...localOptions, ...queryOptions],
    [localOptions, queryOptions]
  );

  const handleCreateOption = (name: string) => {
    const current = getValues(`products.${index}.items`);

    const code = itemCodeGenerator();
    const productGroupName = getValues('name');
    const productGroupNames = productGroupName ? [productGroupName] : [];

    setLocalProductOptions([
      {
        code,
        name,
        productGroupNames,
      },
    ]);

    if (current?.length >= MAX_OPTION_COUNT) {
      message.error(MAX_OPTION_MESSAGE);
      return;
    }

    const createdOption = {
      code,
      name,
    };

    setValue(`products.${index}.items`, [...(current ?? []), createdOption], {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const handleChange = (values: { value: string; label: string }[]) => {
    if (values.length > MAX_OPTION_COUNT) {
      message.error(MAX_OPTION_MESSAGE);
      return;
    }

    const value = values.map((option) => ({
      code: option.value,
      name: option.label,
    }));

    setValue(`products.${index}.items`, value, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });

    setSearchOption('');
  };

  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    if (!brandId) return;
    if (!optionsData) return;

    const target = e.target as HTMLDivElement;

    if (target.scrollTop + target.clientHeight === target.scrollHeight) {
      const { pageInfo } = optionsData?.adminItems || {};

      if (pageInfo?.hasNextPage) {
        await fetchMore({
          variables: {
            pagination: {
              skip: Number(pageInfo?.endCursor),
              take: TAKE,
            },
            filter: {
              brandId,
              searchOption: searchOption ? searchOption : undefined,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!prev.adminItems.edges) return optionsData;
            if (!fetchMoreResult?.adminItems.edges) return prev;

            return merge({}, prev, {
              adminItems: {
                edges: [
                  ...prev.adminItems.edges,
                  ...fetchMoreResult.adminItems.edges,
                ],
                pageInfo: fetchMoreResult.adminItems.pageInfo,
              },
            });
          },
        });
      }
    }
  };

  const handleSearch = debounce(setSearchOption, 200);

  return (
    <Controller
      control={control}
      name={`products.${index}.items`}
      render={({ field, fieldState: { error } }) => {
        const errorMessage =
          typeof error?.message === 'string' ? error.message : undefined;
        const status = errorMessage ? 'error' : '';

        const value = field.value?.map((item: ItemType) => ({
          value: item.code,
          label: item.name,
        }));

        return (
          <>
            <Select
              labelInValue
              className='w-full'
              mode='multiple'
              allowClear
              placeholder={placeholder}
              value={value}
              showSearch
              onChange={handleChange}
              status={status}
              optionLabelProp='label'
              maxTagCount='responsive'
              dropdownStyle={{ minWidth: '640px' }}
              maxTagTextLength={10}
              filterOption={(input, option) => {
                if (!option?.label) {
                  return false;
                }

                if (typeof option.label === 'string') {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }

                return option.label === input;
              }}
              dropdownRender={(menu) => {
                return (
                  <DropdownItem onChange={handleCreateOption}>
                    {menu}
                  </DropdownItem>
                );
              }}
              disabled={disabled}
              onPopupScroll={debounce(handleScroll, 200)}
              onSearch={handleSearch}
              onBlur={() => setSearchOption('')}
            >
              {options.map((option) => {
                const productGroupNames = option.productGroupNames?.join(', ');

                return (
                  <Select.Option
                    key={option.value}
                    value={option.value}
                    label={option.label}
                  >
                    <Tooltip
                      title={productGroupNames}
                      placement='bottom'
                      align={{
                        offset: [100, 8],
                      }}
                      arrow={false}
                    >
                      {option.label}
                      <Typography.Text className='ml-2 text-xs text-black/20'>
                        {productGroupNames}
                      </Typography.Text>
                    </Tooltip>
                  </Select.Option>
                );
              })}
            </Select>
            {!hideErrorMessage && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </>
        );
      }}
    />
  );
}
