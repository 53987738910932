import { Input, InputRef, Tooltip, Typography } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';

import { usePermissions } from '../../../../common/hooks/usePermissions';
import useSettlementList from '../../hooks/useEditSettlementListFields';

type CommentEditableCellProps = {
  id: number;
  comment?: string | null;
  hasSheet: boolean;
};

function CommentEditableCell(props: CommentEditableCellProps) {
  const { hasPermission } = usePermissions('SETTLEMENT_EDIT');
  const [editing, setEditing] = useState(false);
  const [comment, setComment] = useState(props.comment);
  const inputRef = useRef<InputRef>(null);

  const disabled = !hasPermission || props.hasSheet;

  const { editComment } = useSettlementList();

  const handleCommentClick = () => {
    setEditing(true);
  };

  const handleComment = useCallback(async () => {
    if (comment && comment !== props.comment) {
      await editComment(props.id, comment);
    }

    setEditing(false);
  }, [comment, editComment, props.comment, props.id]);

  const handleClickOutside = useCallback(
    async (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.input?.contains(event.target as Node)
      ) {
        await handleComment();
      }
    },
    [handleComment]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.currentTarget.value);
  };

  const handlePressEnter = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.nativeEvent.isComposing) return; // 한글조합 중일 때는 무시
    await handleComment();
  };

  if (disabled) {
    return (
      <div className='px-3 py-1.5'>
        <Typography.Paragraph className='mb-0' ellipsis={{ rows: 2 }}>
          {comment || '-'}
        </Typography.Paragraph>
      </div>
    );
  }

  return (
    <div className='flex items-center justify-between'>
      {editing ? (
        <Input
          ref={inputRef}
          value={comment || ''}
          onChange={handleCommentChange}
          onPressEnter={handlePressEnter}
        />
      ) : (
        <Tooltip title={comment || '-'} placement='bottomLeft' arrow={false}>
          <div
            className='min-h-[32px] w-[208px] rounded-md border border-[#00000026] px-[12px] py-[6px] hover:cursor-pointer hover:border-solid hover:px-[11px] hover:py-[5px]'
            onClick={handleCommentClick}
          >
            <Typography.Paragraph className='mb-0' ellipsis={{ rows: 2 }}>
              {comment || '-'}
            </Typography.Paragraph>
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default CommentEditableCell;
