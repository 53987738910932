import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ProductGroupFormValues } from '../types';

const ProductGroupName = () => {
  const navigate = useNavigate();
  const { control } = useFormContext<ProductGroupFormValues>();

  const productGroupName = useWatch({ control, name: 'name' });

  return (
    <Typography.Title className={'m-0 flex items-center gap-sm'} level={3}>
      <Button
        icon={<ArrowLeftOutlined />}
        type='text'
        size='small'
        onClick={() => navigate(-1)}
        htmlType='button'
      />
      {productGroupName}
    </Typography.Title>
  );
};

export default ProductGroupName;
