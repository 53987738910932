import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Typography } from 'antd';
import { map } from 'lodash-es';
import { Link, matchRoutes, useLocation } from 'react-router-dom';

import { ROUTES } from '../../routes/const';

const routes = map(ROUTES, ({ path }) => ({
  path,
}));

const breadcrumbNameMap = Object.values(ROUTES).reduce<
  Record<string, { title: string; onlyMenu?: boolean }>
>((acc, route) => {
  acc[route.path] = route;
  return acc;
}, {});

function PageTitle() {
  const location = useLocation();
  const matches = matchRoutes(routes, location);

  const path = matches?.[0].route.path;
  const pathSnippets = path?.split('/').filter((i) => i) || [];
  const title = path ? breadcrumbNameMap[path].title : '';

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const { title, onlyMenu } = breadcrumbNameMap[url];

    const isDynamicRoute = url.includes(':');
    const isCurrentPath = url === path;

    if (onlyMenu || isDynamicRoute || isCurrentPath) {
      return {
        key: url,
        title: <span>{title}</span>,
      };
    }
    return {
      key: url,
      title: <Link to={url}>{title}</Link>,
    };
  });

  const breadcrumbItems = [
    {
      title: (
        <Link to='/'>
          <HomeOutlined /> Home
        </Link>
      ),
      key: 'home',
    },
  ].concat(extraBreadcrumbItems);

  return (
    <>
      <Breadcrumb className={'mb-lg'} items={breadcrumbItems} />
      {title && <Typography.Title level={2}>{title}</Typography.Title>}
    </>
  );
}

export default PageTitle;
