import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminVendorsInSettlementListQueryVariables = Types.Exact<{
  vendorsInput: Types.VendorsInput;
}>;


export type AdminVendorsInSettlementListQuery = { __typename?: 'Query', adminVendors: { __typename?: 'VendorsPayload', vendors: Array<{ __typename?: 'VendorGraphqlType', id: number, name: string }> } };

export type AdminVendorsForScheduledSettlementQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.VendorPageQueryFilter>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminVendorsForScheduledSettlementQuery = { __typename?: 'Query', adminVendorsForScheduledSettlement: { __typename?: 'AdminVendorPageGraphqlType', totalCount: number, edges: Array<{ __typename?: 'PaginatedVendorGraphqlTypeEdge', node: { __typename?: 'VendorGraphqlType', name: string, id: number, settlementDates?: Array<string> | null, comment?: string | null, manager?: { __typename?: 'ManagerGraphqlType', name: string } | null } }> } };

export type AdminCountVendorsForScheduledSettlementQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminCountVendorsForScheduledSettlementQuery = { __typename?: 'Query', adminCountVendorsForScheduledSettlement: { __typename?: 'CountVendorsForScheduledSettlementOutputGraphqlType', count: number } };


export const AdminVendorsInSettlementListDocument = gql`
    query AdminVendorsInSettlementList($vendorsInput: VendorsInput!) {
  adminVendors(vendorsInput: $vendorsInput) {
    vendors {
      id
      name
    }
  }
}
    `;

/**
 * __useAdminVendorsInSettlementListQuery__
 *
 * To run a query within a React component, call `useAdminVendorsInSettlementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminVendorsInSettlementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminVendorsInSettlementListQuery({
 *   variables: {
 *      vendorsInput: // value for 'vendorsInput'
 *   },
 * });
 */
export function useAdminVendorsInSettlementListQuery(baseOptions: Apollo.QueryHookOptions<AdminVendorsInSettlementListQuery, AdminVendorsInSettlementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminVendorsInSettlementListQuery, AdminVendorsInSettlementListQueryVariables>(AdminVendorsInSettlementListDocument, options);
      }
export function useAdminVendorsInSettlementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminVendorsInSettlementListQuery, AdminVendorsInSettlementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminVendorsInSettlementListQuery, AdminVendorsInSettlementListQueryVariables>(AdminVendorsInSettlementListDocument, options);
        }
export type AdminVendorsInSettlementListQueryHookResult = ReturnType<typeof useAdminVendorsInSettlementListQuery>;
export type AdminVendorsInSettlementListLazyQueryHookResult = ReturnType<typeof useAdminVendorsInSettlementListLazyQuery>;
export type AdminVendorsInSettlementListQueryResult = Apollo.QueryResult<AdminVendorsInSettlementListQuery, AdminVendorsInSettlementListQueryVariables>;
export const AdminVendorsForScheduledSettlementDocument = gql`
    query AdminVendorsForScheduledSettlement($filter: VendorPageQueryFilter, $pagination: Pagination) {
  adminVendorsForScheduledSettlement(filter: $filter, pagination: $pagination) {
    edges {
      node {
        name
        id
        manager {
          name
        }
        settlementDates
        comment
      }
    }
    totalCount
  }
}
    `;

/**
 * __useAdminVendorsForScheduledSettlementQuery__
 *
 * To run a query within a React component, call `useAdminVendorsForScheduledSettlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminVendorsForScheduledSettlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminVendorsForScheduledSettlementQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminVendorsForScheduledSettlementQuery(baseOptions?: Apollo.QueryHookOptions<AdminVendorsForScheduledSettlementQuery, AdminVendorsForScheduledSettlementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminVendorsForScheduledSettlementQuery, AdminVendorsForScheduledSettlementQueryVariables>(AdminVendorsForScheduledSettlementDocument, options);
      }
export function useAdminVendorsForScheduledSettlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminVendorsForScheduledSettlementQuery, AdminVendorsForScheduledSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminVendorsForScheduledSettlementQuery, AdminVendorsForScheduledSettlementQueryVariables>(AdminVendorsForScheduledSettlementDocument, options);
        }
export type AdminVendorsForScheduledSettlementQueryHookResult = ReturnType<typeof useAdminVendorsForScheduledSettlementQuery>;
export type AdminVendorsForScheduledSettlementLazyQueryHookResult = ReturnType<typeof useAdminVendorsForScheduledSettlementLazyQuery>;
export type AdminVendorsForScheduledSettlementQueryResult = Apollo.QueryResult<AdminVendorsForScheduledSettlementQuery, AdminVendorsForScheduledSettlementQueryVariables>;
export const AdminCountVendorsForScheduledSettlementDocument = gql`
    query AdminCountVendorsForScheduledSettlement {
  adminCountVendorsForScheduledSettlement {
    count
  }
}
    `;

/**
 * __useAdminCountVendorsForScheduledSettlementQuery__
 *
 * To run a query within a React component, call `useAdminCountVendorsForScheduledSettlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountVendorsForScheduledSettlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountVendorsForScheduledSettlementQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminCountVendorsForScheduledSettlementQuery(baseOptions?: Apollo.QueryHookOptions<AdminCountVendorsForScheduledSettlementQuery, AdminCountVendorsForScheduledSettlementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCountVendorsForScheduledSettlementQuery, AdminCountVendorsForScheduledSettlementQueryVariables>(AdminCountVendorsForScheduledSettlementDocument, options);
      }
export function useAdminCountVendorsForScheduledSettlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCountVendorsForScheduledSettlementQuery, AdminCountVendorsForScheduledSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCountVendorsForScheduledSettlementQuery, AdminCountVendorsForScheduledSettlementQueryVariables>(AdminCountVendorsForScheduledSettlementDocument, options);
        }
export type AdminCountVendorsForScheduledSettlementQueryHookResult = ReturnType<typeof useAdminCountVendorsForScheduledSettlementQuery>;
export type AdminCountVendorsForScheduledSettlementLazyQueryHookResult = ReturnType<typeof useAdminCountVendorsForScheduledSettlementLazyQuery>;
export type AdminCountVendorsForScheduledSettlementQueryResult = Apollo.QueryResult<AdminCountVendorsForScheduledSettlementQuery, AdminCountVendorsForScheduledSettlementQueryVariables>;