import { Alert, Select } from 'antd';
import { useState } from 'react';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import Modal from '../../../../common/components/Modal';
import { EPromotionType } from '../../../../schema/types';
import { PROMOTION_TYPES } from '../../const';

import ExchangeRedeliveryForm from './ExchangeRedeliveryForm';
import PromotionEventFormProvider from './PromotionEventFormProvider';
import PromotionFormProvider from './PromotionFormProvider';

type CreatePromotionModalProps = {
  orderCode?: string;
  onClose: () => void;
};

function CreatePromotionModal({
  onClose,
  orderCode,
}: CreatePromotionModalProps) {
  const [promotionType, setPromotionType] = useState<EPromotionType | null>(
    orderCode ? 'EXCHANGE_RE_DELIVERY' : null
  );

  return (
    <Modal
      title='출고요청'
      onCancel={onClose}
      footer={null}
      width={1000}
      maskClosable={false}
    >
      {promotionType === 'EXCHANGE_RE_DELIVERY' && (
        <Alert
          className='mb-4'
          message='와이어드 자체물류 귀책 등의 사유로 상품 재출고 시, 업체에 상품공급가를 정산해야하는 경우에는 “기타" 구분을 사용해주세요.'
          type='info'
        />
      )}
      <FieldWithLabel required label='구분'>
        <Select
          disabled={!!orderCode}
          className='w-[120px]'
          options={PROMOTION_TYPES}
          placeholder='--선택--'
          onChange={(value) => setPromotionType(value as EPromotionType)}
          value={promotionType}
        />
      </FieldWithLabel>
      {promotionType === 'EVENT' && (
        <PromotionEventFormProvider onClose={onClose} />
      )}
      {promotionType === 'SAMPLE' && (
        <PromotionFormProvider
          promotionType={promotionType}
          onClose={onClose}
        />
      )}
      {promotionType === 'EXTRA' && (
        <PromotionFormProvider
          promotionType={promotionType}
          onClose={onClose}
        />
      )}
      {promotionType === 'EXCHANGE_RE_DELIVERY' && (
        <ExchangeRedeliveryForm onClose={onClose} orderCode={orderCode} />
      )}
    </Modal>
  );
}

export default CreatePromotionModal;
