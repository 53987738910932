type AppEnv = 'dev' | 'stage' | 'production';

export const ENV = import.meta.env.VITE_APP_ENV as AppEnv;

const KEMIST_URLS: { [key in AppEnv]: string } = {
  dev: 'https://admin-dev.kemi.io',
  stage: 'https://admin-stage.kemi.io',
  production: 'https://admin.kemi.io',
};
export const KEMIST_BASE_URL = KEMIST_URLS[ENV];
