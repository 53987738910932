import { UploadProps } from 'antd';
import { get } from 'lodash-es';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form';

import { EUploadResourcesPurpose } from '../../../schema/types';
import { getErrorStatus } from '../../utils/form';
import { formatNumber, parseNumber } from '../../utils/number';
import FileUploader, { UploadButton } from '../FileUploader';
import TextInput, { TextInputProps } from '../Input/TextInput';

import FieldWithLabel from './FieldWithLabel';
import { FieldProps } from './types';

type BusinessRegistrationFieldProps<TFieldValues extends FieldValues> = {
  inputProps: TextInputProps & {
    name: Path<TFieldValues>;
  };
  uploadProps: Omit<UploadProps, 'onChange' | 'value'> & {
    id: string;
    name: Path<TFieldValues>;
    purpose: EUploadResourcesPurpose;
  };
} & FieldProps & {
    control: Control<TFieldValues>;
    selectPosition?: 'before' | 'after';
  };

function BusinessRegistrationField<TFieldValues extends FieldValues>({
  label,
  required,
  inputProps,
  uploadProps,
  control,
}: BusinessRegistrationFieldProps<TFieldValues>) {
  const { formState } = useFormContext<TFieldValues>();
  const inputError = get(formState.errors, inputProps.name);
  const uploadError = get(formState.errors, uploadProps.name);
  const inputErrorMessage =
    typeof inputError?.message === 'string' ? inputError.message : undefined;
  const uploadErrorMessage =
    typeof uploadError?.message === 'string' ? uploadError?.message : undefined;
  const errorMessage = inputErrorMessage || uploadErrorMessage;

  return (
    <div>
      <FieldWithLabel label={label || '사업자등록번호'} required={required}>
        <div className={'flex items-start gap-sm'}>
          <div className={'flex-1'}>
            <Controller
              control={control}
              name={inputProps.name}
              render={({
                field: { onChange, value, ...lastField },
                formState,
              }) => {
                const error = get(formState.errors, inputProps.name);

                const status = error?.message ? getErrorStatus(error) : '';
                return (
                  <TextInput
                    status={status}
                    errorMessage={errorMessage}
                    onChange={(e) => {
                      e.target.value = parseNumber(
                        formatNumber(e.target.value, '###-##-#####')
                      );
                      onChange(e);
                    }}
                    value={formatNumber(value || '', '###-##-#####')}
                    {...lastField}
                    {...inputProps}
                  />
                );
              }}
            />
          </div>

          <Controller
            render={({ field, formState }) => {
              const error = get(formState.errors, uploadProps.name);

              return (
                <UploadButton
                  id={uploadProps.id}
                  label={'사업자등록증'}
                  disabled={!!field.value || uploadProps.disabled}
                  danger={!!error}
                />
              );
            }}
            name={uploadProps.name}
          />
        </div>
      </FieldWithLabel>
      {errorMessage && (
        <p className={'m-0 text-xs text-red-500'}>{errorMessage}</p>
      )}
      <Controller
        render={({ field }) => {
          const url = field.value ? [field.value] : [];

          return (
            <FileUploader
              multiple={false}
              maxCount={1}
              value={url}
              onChange={([url]) => field.onChange(url || null)}
              {...uploadProps}
            />
          );
        }}
        name={uploadProps.name}
      />
    </div>
  );
}

export default BusinessRegistrationField;
