import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isEmpty } from 'lodash-es';
import { FormProvider } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import TextField from '../../../common/components/Fields/TextField';
import Modal from '../../../common/components/Modal';
import { DEFAULT_CONTACT_VALUE } from '../const';
import useBrandFormValues from '../hooks/useBrandFormValues';
import useCancelConfirmModal from '../hooks/useCancelConfirmModal';

import ContactForm from './ContactForm';

type CreateBrandModalProps = {
  vendorId: number;
  onClose: () => void;
};

function CreateBrandModal({ onClose, vendorId }: CreateBrandModalProps) {
  const { method, onSubmit } = useBrandFormValues(vendorId);
  const { control } = method;
  const isDirty = !isEmpty(method.formState.dirtyFields);
  const openCancelConfirm = useCancelConfirmModal();
  const onCancel = () => {
    if (isDirty) {
      openCancelConfirm(onClose);
    } else {
      onClose();
    }
  };
  return (
    <Modal title='브랜드등록' footer={null} onCancel={onCancel}>
      <FormProvider {...method}>
        <form onSubmit={onSubmit(onClose)}>
          <FieldWithLabel required label='브랜드명'>
            <TextField
              placeholder='브랜드명 입력'
              name='name'
              control={control}
              maxLength={30}
            />
          </FieldWithLabel>
          <ArrayField name='brandContacts' control={control}>
            {({ fields, append, remove, replace }) => (
              <>
                {fields.map((field, index) => (
                  <ContactForm
                    key={field.key}
                    control={control}
                    typeSelectProps={{ name: `brandContacts.${index}.type` }}
                    nameInputProps={{ name: `brandContacts.${index}.name` }}
                    emailInputProps={{ name: `brandContacts.${index}.email` }}
                    phoneInputProps={{ name: `brandContacts.${index}.phone` }}
                    onRemove={() => {
                      if (fields.length === 1) {
                        replace(DEFAULT_CONTACT_VALUE);
                      } else {
                        remove(index);
                      }
                    }}
                  />
                ))}
                <div className='mt-4'>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => append(DEFAULT_CONTACT_VALUE)}
                  />
                </div>
              </>
            )}
          </ArrayField>
          <div className='mt-4 flex justify-end gap-2'>
            <Button onClick={onCancel}>취소</Button>
            <Button type='primary' htmlType='submit'>
              등록
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default CreateBrandModal;
