import { ERole } from '../../schema/types';

export const ROLES: ERole[] = [
  'MANAGER_EDIT',
  'PRODUCT_EDIT',
  'SELLER_EDIT',
  'BRAND_AND_VENDOR_EDIT',
  'MARKET_EDIT',
  'PROMOTION_RELEASE_EDIT',
];

export const ROLES_TEXT: Record<ERole, string> = {
  MANAGER_EDIT: '운영자관리',
  PRODUCT_EDIT: '상품관리',
  SELLER_EDIT: '셀러관리',
  BRAND_AND_VENDOR_EDIT: '업체 / 브랜드관리',
  MARKET_EDIT: '마켓관리',
  PROMOTION_RELEASE_EDIT: '출고요청관리',
  ORDER_EDIT: '주문 / CS관리',
  SETTLEMENT_EDIT: '정산관리',
};
