import { Descriptions } from 'antd';
import { useFormContext } from 'react-hook-form';

import { DELIVERY_FEE_BEARER_LABEL } from '../const';
import { usePromotionMarketEventsQuery } from '../graphql/markets.generated';
import { numberWithUnknownString } from '../helper';

function MarketEventProductsDescription() {
  const { watch } = useFormContext<{
    marketId?: number;
    marketEventId?: number;
  }>();
  const [marketId, marketEventId] = watch(['marketId', 'marketEventId']);
  const { data: marketData } = usePromotionMarketEventsQuery({
    variables: { marketId: marketId || 0 },
    skip: !marketId,
  });

  const selectedEvent = marketData?.adminMarket.events?.find(
    ({ id }) => id === marketEventId
  );

  const freeProvidedProducts = selectedEvent?.products.filter(
    ({ isFreeProvided }) => isFreeProvided
  );
  const chargedProducts = selectedEvent?.products.filter(
    ({ isFreeProvided }) => !isFreeProvided
  );
  if (!selectedEvent) return null;
  return (
    <Descriptions
      bordered
      layout='horizontal'
      column={1}
      labelStyle={{ width: 200 }}
    >
      <Descriptions.Item label='업체무상품목' contentStyle={{ padding: 0 }}>
        <Descriptions
          bordered
          layout='horizontal'
          className='promotion__descriptions'
          column={1}
          labelStyle={{ width: 0 }}
        >
          {freeProvidedProducts?.map((product, i) => (
            <Descriptions.Item key={i}>
              {product.name}({product.count}개)
              {!!product.items?.length &&
                ` / ${product.items.map(({ name }) => name).join(', ')}`}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Descriptions.Item>
      <Descriptions.Item label='업체유상품목' contentStyle={{ padding: 0 }}>
        <Descriptions
          bordered
          layout='horizontal'
          className='promotion__descriptions'
          column={1}
          labelStyle={{ width: 0 }}
        >
          {chargedProducts?.map((product, i) => {
            const bearing: string[] = [];
            if (product.isSellerBearing) {
              bearing.push(
                `셀러부담: ${numberWithUnknownString(
                  product.sellerBearingCost
                )} (${product.isPrepayment ? '선결제' : '정산차감'})`
              );
            }
            if (product.isWiredBearing) {
              bearing.push(
                `와이어드 부담: ${numberWithUnknownString(
                  product.wiredBearingCost
                )}`
              );
            }

            return (
              <Descriptions.Item key={i}>
                {product.name}({product.count}개)
                {!!product.items?.length &&
                  ` / ${product.items.map(({ name }) => name).join(', ')}`}
                <br />
                <span className='text-[#00000073]'>
                  자사매입가: {product.inhouseBuyPrice} / {bearing.join(', ')}
                </span>
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </Descriptions.Item>
      <Descriptions.Item label='배송비 별도부과'>
        {selectedEvent.shippingBearer &&
          DELIVERY_FEE_BEARER_LABEL[selectedEvent.shippingBearer]}{' '}
        /{' '}
        {typeof selectedEvent.shippingCost === 'number'
          ? selectedEvent.shippingCost
          : '-'}{' '}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default MarketEventProductsDescription;
