import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminProductGroupInSettlementListQueryVariables = Types.Exact<{
  adminProductGroupId: Types.Scalars['Int']['input'];
}>;


export type AdminProductGroupInSettlementListQuery = { __typename?: 'Query', adminProductGroup: { __typename?: 'ProductGroupGraphqlType', id: number, name: string } };


export const AdminProductGroupInSettlementListDocument = gql`
    query AdminProductGroupInSettlementList($adminProductGroupId: Int!) {
  adminProductGroup(id: $adminProductGroupId) {
    id
    name
  }
}
    `;

/**
 * __useAdminProductGroupInSettlementListQuery__
 *
 * To run a query within a React component, call `useAdminProductGroupInSettlementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductGroupInSettlementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductGroupInSettlementListQuery({
 *   variables: {
 *      adminProductGroupId: // value for 'adminProductGroupId'
 *   },
 * });
 */
export function useAdminProductGroupInSettlementListQuery(baseOptions: Apollo.QueryHookOptions<AdminProductGroupInSettlementListQuery, AdminProductGroupInSettlementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductGroupInSettlementListQuery, AdminProductGroupInSettlementListQueryVariables>(AdminProductGroupInSettlementListDocument, options);
      }
export function useAdminProductGroupInSettlementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductGroupInSettlementListQuery, AdminProductGroupInSettlementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductGroupInSettlementListQuery, AdminProductGroupInSettlementListQueryVariables>(AdminProductGroupInSettlementListDocument, options);
        }
export type AdminProductGroupInSettlementListQueryHookResult = ReturnType<typeof useAdminProductGroupInSettlementListQuery>;
export type AdminProductGroupInSettlementListLazyQueryHookResult = ReturnType<typeof useAdminProductGroupInSettlementListLazyQuery>;
export type AdminProductGroupInSettlementListQueryResult = Apollo.QueryResult<AdminProductGroupInSettlementListQuery, AdminProductGroupInSettlementListQueryVariables>;