import { LinkOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import TextField from '../../../common/components/Fields/TextField';
import { useSellersByFormQuery } from '../graphql/sellers.generated';
import { CreateSellerFormValues, UpdateSellerFormValues } from '../types';

type SellerNameFieldProps = {
  disabled: boolean;
  withoutSearch?: boolean;
};

function SellerNameField({ disabled, withoutSearch }: SellerNameFieldProps) {
  const { control, watch } = useFormContext<
    CreateSellerFormValues | UpdateSellerFormValues
  >();

  const name = watch('name');

  const { data } = useSellersByFormQuery({
    fetchPolicy: 'no-cache',
    variables: {
      sellersInput: {
        search: {
          types: ['NAME'],
          keyword: name,
        },
        pagination: { take: 5, skip: 0 },
      },
    },
    skip: !name || withoutSearch,
  });

  const [open, setOpen] = useState(false);

  const options = useMemo(
    () =>
      data?.adminSellers?.sellers.map((seller) => ({
        label: (
          <Link to={`/sellers/${seller.id}`} target={'_blank'}>
            {seller.name}
            <LinkOutlined className={'ml-sm'} />
          </Link>
        ),
        key: seller.id,
      })) || [],
    [data]
  );

  useEffect(() => {
    if (options.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [options]);

  return (
    <div className={'flex items-center gap-md'}>
      <TextField
        disabled={disabled}
        control={control}
        name={'name'}
        required
        maxLength={30}
        placeholder={'셀러명 입력'}
      />

      <Dropdown
        open={open}
        menu={{ items: options }}
        onOpenChange={setOpen}
        trigger={['click']}
      >
        <div>
          {open && (
            <Button
              type={'text'}
              htmlType={'button'}
              className={'basis-[150px] text-xs text-blue-400'}
            >
              유사한 셀러가 있어요{' '}
            </Button>
          )}
        </div>
      </Dropdown>
    </div>
  );
}

export default SellerNameField;
