import { App, Button } from 'antd';

import { usePermissions } from '../../../../common/hooks/usePermissions';
import {
  AdminCountOrderSupportStatusesDocument,
  useAdminPatchOrderSupportStatusMutation,
} from '../../graphql/orderSupport.generated';
import { OrderClaimsTableData } from '../../type';

import CreateClaimSelect from './CreateClaimSelect';
import UpdateClaimSelect from './UpdateClaimSelect';

function OrderSupportStatusCell({ order }: { order: OrderClaimsTableData }) {
  const { modal, message } = App.useApp();
  const { hasPermission } = usePermissions(['ORDER_EDIT']);

  const [cancelSupport] = useAdminPatchOrderSupportStatusMutation();

  const isUpdate = !!order.activeClaim;

  const handleCancelButtonClick = () => {
    const id = order.supportId;

    if (!id) {
      return;
    }

    modal.warning({
      content: '응대를 취소하시겠습니까?',
      centered: true,
      okText: '네',
      cancelText: '아니오',
      okCancel: true,
      onOk() {
        void cancelSupport({
          refetchQueries: [AdminCountOrderSupportStatusesDocument],
          variables: {
            orderSupportData: {
              status: 'SUPPORT_CANCELED',
              id,
            },
          },
          onError() {
            message.error('응대취소처리를 실패했습니다.');
          },
          onCompleted() {
            message.success('응대가 취소되었습니다.');
          },
        });
      },
    });
  };

  return (
    <div className={'flex flex-col items-center text-center'}>
      {isUpdate ? (
        <UpdateClaimSelect
          order={order}
          renderUpdateReturnReceipt={false}
          renderUpdateExchangeReceipt={false}
        />
      ) : (
        <CreateClaimSelect order={order} />
      )}

      {order.supportStatus === 'SUPPORT_RECEIPT' && (
        <Button
          type={'link'}
          onClick={handleCancelButtonClick}
          disabled={!hasPermission}
        >
          응대취소
        </Button>
      )}
    </div>
  );
}

export default OrderSupportStatusCell;
