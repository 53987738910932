import { ExclamationCircleOutlined } from '@ant-design/icons';
import { App, Button, Divider } from 'antd';
import { get, isEmpty, omit } from 'lodash-es';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';

import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import SelectField from '../../../common/components/Fields/SelectField';
import TextareaField from '../../../common/components/Fields/TextareaField';
import TextField from '../../../common/components/Fields/TextField';
import Modal from '../../../common/components/Modal';
import { useMeQuery } from '../../../common/graphql/me.generated';
import { useFormError } from '../../../common/hooks/useFormError';
import { SellerSettlementInfoGraphqlInputType } from '../../../schema/types';
import { SELLER_GRADES } from '../const';
import { useCreateSellerMutation } from '../graphql/seller.generated';
import { SellersDocument } from '../graphql/sellers.generated';
import { createSellerFormResolver } from '../helper/validator';
import { CreateSellerFormValues } from '../types';

import AddressesField from './AddressesField';
import EmailsField from './EmailsField';
import LinksField from './LinksField';
import MobileField from './MobileField';
import SellerNameField from './SellerNameField';
import SettlementsField from './SettlementsField';

type CreateSellerFormProps = {
  close: () => void;
  editable: boolean;
  onSuccess: () => void;
};

const defaultValues = {
  name: '',
  kemiUserId: '',
  links: [{ url: '', isDefault: true }],
  emails: [{ email: '', isDefault: true }],
  phoneNumber: '',
  isUniquePhoneNumber: false,
  memo: '',
  addresses: [
    {
      zipCode: '',
      address1: '',
      address2: '',
      isDefault: true,
    },
  ],
  settlementInfos: [
    {
      isDefault: true,
      accountImageUrl: null,
    },
  ],
};

function CreateSellerForm({
  close,
  editable,
  onSuccess,
}: CreateSellerFormProps) {
  const { message, modal } = App.useApp();

  const { data: user } = useMeQuery();
  const [mutate] = useCreateSellerMutation({
    refetchQueries: [SellersDocument],
  });

  const { onInvalid } = useFormError({
    afterInValid: (errors: FieldErrors<CreateSellerFormValues>) => {
      const checkedPhoneNumberError = get(errors, 'isCheckedPhoneNumber');
      const uniquePhoneNumberError = get(errors, 'isUniquePhoneNumber');

      if (checkedPhoneNumberError || uniquePhoneNumberError) {
        methods.setFocus('phoneNumber');
      }
    },
  });
  const methods = useForm<CreateSellerFormValues>({
    defaultValues: {
      ...defaultValues,
      managerId: user?.adminMe?.id,
    },
    /**
     * input change 시점에 validation 동작
     * default는 submit 시점에 validation 동작
     */
    mode: 'onChange',
    resolver: createSellerFormResolver,
  });

  const { handleSubmit, control, formState } = methods;
  const onSubmit = async (data: CreateSellerFormValues) => {
    const createSellerInput = omit(
      {
        ...data,
        settlementInfos: data.settlementInfos.filter(
          // info.type = null 인 상황을 제거하고 SellerSettlementInfoInput으로 타입 가드
          (info): info is SellerSettlementInfoGraphqlInputType => !!info.type
        ),
      },
      ['isUniquePhoneNumber', 'isCheckedPhoneNumber']
    );

    await mutate({
      variables: { createSellerInput },
      onCompleted() {
        message.success('저장이 완료되었습니다.');
        onSuccess();
      },
      onError() {
        message.error('저장을 실패하였습니다.');
      },
    });
  };
  const handleCancel = () => {
    if (isDirty) {
      modal.confirm({
        icon: <ExclamationCircleOutlined />,
        title: '작성 취소',
        content: '변경사항이 저장되지 않습니다.\n작성을 취소하시겠습니까?',
        onOk: close,
        okText: '네',
        cancelText: '아니오',
        centered: true,
      });

      return;
    }
    close();
  };

  // formState.isDirty는 touched되는 순간 이후로 원복이 안되어서 fields로 감지함.
  const isDirty = !isEmpty(formState.dirtyFields);

  return (
    <Modal onCancel={handleCancel} title={'셀러등록'} footer={null}>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit, onInvalid)}
          className={'grid w-full gap-xl pt-lg'}
        >
          <ManagerSelectField
            required
            disabled={!editable}
            label={'담당자'}
            name={'managerId'}
            control={control}
          />

          <FieldWithLabel required label={'셀러명'}>
            <SellerNameField disabled={!editable} />
          </FieldWithLabel>

          <div className={'grid grid-cols-2 gap-md'}>
            <TextField
              disabled={!editable}
              label={'케미회원아이디'}
              placeholder={'케미회원아이디'}
              control={control}
              name={'kemiUserId'}
              maxLength={30}
            />
            <SelectField
              allowClear
              disabled={!editable}
              label={'등급'}
              name={'grade'}
              control={control}
              options={SELLER_GRADES}
              placeholder={'선택'}
            />
          </div>
          <Divider className={'m-0'} />

          <LinksField disabled={!editable} />
          <EmailsField disabled={!editable} />
          <MobileField disabled={!editable} />
          <AddressesField disabled={!editable} />

          <FieldWithLabel label={'비고'}>
            <TextareaField
              disabled={!editable}
              control={control}
              name={'memo'}
            />
          </FieldWithLabel>

          <Divider orientation='left' className={'m-0'}>
            정산정보
          </Divider>

          <SettlementsField disabled={!editable} />
          <div
            className={'sticky bottom-0 flex justify-end gap-md bg-white pb-lg'}
          >
            <Button htmlType={'button'} onClick={handleCancel}>
              취소
            </Button>
            <Button
              htmlType={'submit'}
              type={'primary'}
              loading={formState.isSubmitting}
              disabled={!isDirty || !editable}
            >
              등록
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default CreateSellerForm;
