import { useEffect } from 'react';

import { EOrderClaimStatus, EOrderClaimType } from '../../../schema/types';
import { AdminOrderGroupQuery } from '../graphql/createClaim.generated';
import { getRejectReasonChangeClaim } from '../helper/orderClaim';

export const usePossibleClaim = ({
  order,
  type,
  status,
  onReject,
}: {
  order?: AdminOrderGroupQuery['adminOrderGroup']['orders']['0'];
  type: EOrderClaimType | null;
  status: EOrderClaimStatus | null;
  onReject: (message: string) => void;
}) => {
  useEffect(() => {
    if (order && type && status) {
      const reason = getRejectReasonChangeClaim(order, { type, status });

      if (reason) {
        onReject(reason);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, type]);
};
