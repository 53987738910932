import { Divider } from 'antd';
import { useFormContext } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import { SELLER_ADDRESSES_MAX_LENGTH } from '../const';
import { CreateSellerFormValues, UpdateSellerFormValues } from '../types';

import AddressItemField from './AddressItemField';

type AddressesFieldProps = {
  disabled: boolean;
};

function AddressesField({ disabled }: AddressesFieldProps) {
  const { control, setValue, watch, clearErrors } = useFormContext<
    CreateSellerFormValues | UpdateSellerFormValues
  >();

  const addresses = watch('addresses') || [];

  return (
    <ArrayField
      disabled={disabled}
      name={'addresses'}
      control={control}
      label={'샘플수령지'}
      maxLength={SELLER_ADDRESSES_MAX_LENGTH}
      addButtonProps={{
        onClick: ({ append }) => {
          append({
            zipCode: '',
            address: '',
            detailAddress: '',
            isDefault: false,
          });
        },
      }}
    >
      {({ fields, remove, update }) => {
        return (
          <ul className={'mb-md'}>
            {fields.map((field, index) => {
              const address = addresses[index];

              return (
                <li key={field.key}>
                  {index > 0 && (
                    <Divider orientation='left' plain>
                      {`주소${index + 1}`}
                    </Divider>
                  )}
                  <AddressItemField
                    disabled={disabled}
                    index={index}
                    control={control}
                    hasIsDefaultRadio={fields.length > 1}
                    onAddressLoad={(address) => {
                      update(index, {
                        zipCode: address.zipCode,
                        address: address.address,
                        detailAddress: field.detailAddress,
                        isDefault: field.isDefault,
                      });
                    }}
                    onRemove={() => {
                      clearErrors('addresses');

                      if (address.isDefault) {
                        const target = index === 0 ? 1 : index - 1;

                        update(target, {
                          ...addresses[target],
                          isDefault: true,
                        });
                      }
                      remove(index);
                    }}
                    onIsDefaultChange={() => {
                      setValue(
                        'addresses',
                        fields.map((eachField, i) => ({
                          ...addresses?.[i],
                          isDefault: eachField.key === field.key,
                        })),
                        {
                          shouldTouch: true,
                          shouldValidate: true,
                          shouldDirty: true,
                        }
                      );
                    }}
                    canSetDefault={!!field.zipCode && !!field.address}
                  />
                </li>
              );
            })}
          </ul>
        );
      }}
    </ArrayField>
  );
}

export default AddressesField;
