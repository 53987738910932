import { App, Divider } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { MARKET_PRODUCT_GROUPS_MAX_LENGTH } from '../const';
import { useAdminProductGroupsOptionsQuery } from '../graphql/products.generated';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../types';

import BrandsSelectField, { BrandOption } from './BrandsSelectField';
import MarketProductsFieldItem from './MarketProductsFieldItem';

const defaultData = { id: null, name: '', session: null, managerId: null };

function MarketProductsField({
  hideSession,
  productEditable = true,
}: {
  hideSession?: boolean;
  productEditable?: boolean;
}) {
  const { hasPermission } = usePermissions('MARKET_EDIT');
  const { message } = App.useApp();

  const { control, watch, formState, setValue } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();

  const brandId = watch('brandId');

  const { data: productGroupsData } = useAdminProductGroupsOptionsQuery({
    variables: {
      filter: { brandId },
      // TODO: 20개로 고정으로 가고 케이스가 생기면 추후 DEVELOP하기
      pagination: { skip: 0, take: 20 },
    },
    skip: !brandId,
    fetchPolicy: 'no-cache',
  });

  const productGroups =
    productGroupsData?.adminProductGroups?.edges.map((group) => {
      return {
        label: group.node.name,
        value: group.node.id,
        data: group.node,
      };
    }) || [];

  const brandName = watch('brandName');
  const events = watch('events') || [];
  const hasEvents = !!events?.length;
  const productGroupIds = watch('productGroupIds') || [];
  const productsError = formState.errors?.productGroupIds?.message;

  const brandEditable = useMemo(() => {
    return (
      events.every((event) => event.editable && event.deletable) &&
      productEditable
    );
  }, [events, productEditable]);

  const uniqueProductGroupOptions = productGroups.filter(
    (option) =>
      !productGroupIds.find((productGroup) => productGroup.id === option.value)
  );

  const handleBrandChange = useCallback(
    (_: any, option: DefaultOptionType | DefaultOptionType[]) => {
      if (!Array.isArray(option)) {
        const brandOption = option.data as BrandOption;

        setValue('brandName', brandOption.name);
        setValue('productGroupIds', [defaultData]);

        if (hasEvents) {
          message.warning('변경사항이 있어 이벤트가 모두 삭제되었습니다.');
          setValue('events', [], {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }
      }
    },
    [hasEvents, message, setValue]
  );

  return (
    <div className={'grid w-full gap-xl'}>
      <div>
        <Divider orientation={'left'} className={'my-4'}>
          상품정보
        </Divider>
        <BrandsSelectField
          required={hasPermission}
          disabled={!hasPermission || !brandEditable}
          className={'w-marketCreateInputWidth @lg:w-updateFormWidth'}
          control={control}
          name={'brandId'}
          label={'브랜드'}
          placeholder={'브랜드 선택'}
          onChange={handleBrandChange}
          initialOption={
            brandId ? { value: brandId, label: brandName || '' } : undefined
          }
        />
      </div>

      {!!brandId && (
        <ArrayField
          name={'productGroupIds'}
          control={control}
          maxLength={Math.min(
            MARKET_PRODUCT_GROUPS_MAX_LENGTH,
            productGroups.length
          )}
          disabled={!hasPermission}
          addButtonProps={{
            type: 'primary',
            children: '상품 추가',
            onClick({ append }) {
              append(defaultData);
            },
          }}
          errorMessage={productsError}
        >
          {({ fields, remove }) => {
            return (
              <ul>
                {fields.map((field, i) => {
                  return (
                    <li className={'mb-6'} key={field.key}>
                      <MarketProductsFieldItem
                        index={i}
                        options={uniqueProductGroupOptions}
                        onRemove={() => remove(i)}
                        hideSession={hideSession}
                        // field.id가 없으면 신규 추가된 상품이므로 수정할 수 있어야 한다.
                        productEditable={!field.id || productEditable}
                      />
                    </li>
                  );
                })}
              </ul>
            );
          }}
        </ArrayField>
      )}
    </div>
  );
}

export default MarketProductsField;
