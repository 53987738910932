import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { App } from 'antd';
import { PropsWithChildren, useState } from 'react';

import { authorization } from '../../domain/auth/helper';
import { localProductOptionsVar } from '../../domain/product/graphql/localProductOptions';

import errorLink from './errorLink';

const httpLink = new HttpLink({
  uri: `${import.meta.env.VITE_SERVER_URL}/graphql`,
  credentials: 'include',
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = authorization.accessToken;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation);
});

function Provider({ children }: PropsWithChildren) {
  const { message } = App.useApp();

  const [queryClient] = useState(
    () =>
      new ApolloClient({
        cache: new InMemoryCache({
          typePolicies: {
            Query: {
              fields: {
                localProductOptions: {
                  read() {
                    return localProductOptionsVar();
                  },
                },
              },
            },
            OrderGroupGraphqlType: {
              merge: true,
            },
          },
        }),
        link: from([authMiddleware, errorLink(message), httpLink]),
      })
  );

  return <ApolloProvider client={queryClient}>{children}</ApolloProvider>;
}

export default Provider;
