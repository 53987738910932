import { EventClickArg, EventSourceInput } from '@fullcalendar/core';
import ko from '@fullcalendar/core/locales/ko';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './calendar.css';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { AdminMarketsQuery } from '../graphql/markets.generated';
import { MarketListSearchParams } from '../types';

type MarketsList = AdminMarketsQuery['adminMarkets']['edges'];

type MarketCalendarProps = {
  data: MarketsList;
  loading: boolean;
};

function MarketCalendar({ data, loading }: MarketCalendarProps) {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<MarketListSearchParams>();
  const navigate = useNavigate();

  const ref = useRef<FullCalendar>(null);

  const [datesSet, setDatesSet] = useState<[string | null, string | null]>([
    null,
    null,
  ]);
  const [active, setActive] = useState('');

  const [start, end] = datesSet;
  const marketStartFilter = searchParams.marketStartFilter || null;
  const marketEndFilter = searchParams.marketEndFilter || null;
  const initialDate = useMemo(() => {
    return marketStartFilter && marketEndFilter
      ? // start 와 end가 해당 월이 아닐 수 있기 때문에 중간값을 설정하여 확인한다.
        new Date(
          (new Date(marketStartFilter).getTime() +
            new Date(marketEndFilter).getTime()) /
            2
        )
      : undefined;
  }, [marketStartFilter, marketEndFilter]);

  const events = useMemo<EventSourceInput>(() => {
    return data.map((item) => {
      const start =
        marketStartFilter &&
        dayjs(item.node.startedAt).isBefore(marketStartFilter)
          ? marketStartFilter
          : item.node.startedAt;

      const end =
        marketEndFilter &&
        (!item.node.endedAt ||
          dayjs(item.node.endedAt).isAfter(marketEndFilter))
          ? marketEndFilter
          : item.node.endedAt || undefined;

      const isActive = active === item.node.id.toString();
      const backgroundColor = item.node.isSoldOut
        ? isActive
          ? '#FFCCC7'
          : '#FFF1F0'
        : isActive
        ? '#0000000F'
        : '#FFFFFF';

      return {
        id: item.node.id.toString(),
        title: item.node.name,
        start,
        end,
        className: 'duration-300 transition ease-in-out',
        borderColor: item.node.isSoldOut ? '#FF7875' : '#00000026',
        textColor: item.node.isSoldOut ? '#FF7875' : '#00000073',
        display: 'block',
        backgroundColor,
      };
    });
  }, [data, marketStartFilter, marketEndFilter, active]);

  // 최초 로딩시 여러번 실행되는 현상 방지를 위해 debounce 처리
  const handleMonthChange = useCallback(
    ([start, end]: [string, string]) => {
      setSearchParams(
        {
          marketStartFilter: start,
          marketEndFilter: end,
          view: 'calendar',
        },
        { preventScrollReset: true }
      );
    },
    [setSearchParams]
  );
  const handleEventClick = (ev: EventClickArg) => {
    navigate(`/markets/${ev.event.id}`);
  };

  useEffect(() => {
    if (start && end) {
      handleMonthChange([start, end]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  return (
    <Spin spinning={loading}>
      <div className='bg-white p-4 [&_.fc-popover]:h-auto [&_.fc-popover]:max-w-md'>
        <FullCalendar
          ref={ref}
          height={'auto'}
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView='dayGridMonth'
          events={events}
          eventMouseEnter={(v) => {
            setActive(v.event.id);
          }}
          eventMouseLeave={() => {
            setActive('');
          }}
          dayMaxEvents={5}
          locale={ko}
          dayCellClassNames={'bg-red h-[187px]'}
          eventClick={handleEventClick}
          moreLinkClassNames={['z-[1000]']}
          initialDate={initialDate}
          datesSet={(event) => {
            const start = dayjs(event.start).startOf('date').toISOString();
            const end = dayjs(event.end).endOf('date').toISOString();
            setDatesSet([start, end]);
          }}
          displayEventTime={false}
          eventClassNames={'text-[10px] font-semibold leading-5 py-0'}
        />
      </div>
    </Spin>
  );
}

export default MarketCalendar;
