import { App, DatePicker, Divider } from 'antd';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import TextareaField from '../../../../common/components/Fields/TextareaField';
import { formSetValueOption } from '../../../../common/utils/const';
import { AdminUpdatePromotionReleaseMutationVariables } from '../../graphql/promotionRelease.generated';
import usePromotionPermission from '../../hooks/usePromotionPermission';
import useReleaseDetail from '../../hooks/useReleaseDetail';

function ExtraInformation() {
  const { message } = App.useApp();
  const hasPermission = usePromotionPermission();
  const methods =
    useFormContext<AdminUpdatePromotionReleaseMutationVariables>();
  const { control, watch, setValue } = methods;
  const scheduledReleaseAt = watch('promotionReleaseData.scheduledReleaseAt');
  const handleReleaseAt = useCallback(
    (date: dayjs.Dayjs | null) => {
      const isTodayPossible = dayjs().isBefore(dayjs().hour(11).minute(50));
      // 11:50 전에는 오늘부터 출고요청이 가능.
      // 11:50 후에는 내일부터 출고요청이 가능.
      const standard = isTodayPossible
        ? dayjs().startOf('day')
        : dayjs().add(1, 'day').startOf('day');

      if (date?.isBefore(standard)) {
        void message.warning('오늘 이후의 날짜를 선택해주세요.');
        return;
      }

      const scheduledReleaseAt = date?.toISOString() || '';
      setValue(
        'promotionReleaseData.scheduledReleaseAt',
        scheduledReleaseAt,
        formSetValueOption
      );
    },
    [setValue, message]
  );
  const { isCompleted } = useReleaseDetail();

  return (
    <div>
      <Divider orientation='left' className='mt-6'>
        기타정보
      </Divider>
      <div className='flex w-[472px] flex-col gap-6'>
        <FieldWithLabel label='출고 희망일'>
          <DatePicker
            disabled={!hasPermission || isCompleted}
            className='w-[232px]'
            value={scheduledReleaseAt ? dayjs(scheduledReleaseAt) : null}
            onChange={handleReleaseAt}
          />
        </FieldWithLabel>
        <FieldWithLabel label='비고'>
          <TextareaField
            disabled={!hasPermission}
            control={control}
            name='promotionReleaseData.note'
            placeholder='내용입력'
          />
        </FieldWithLabel>
      </div>
    </div>
  );
}

export default ExtraInformation;
