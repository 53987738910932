import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Typography, Button, Divider } from 'antd';
import { isEmpty } from 'lodash-es';
import { Fragment } from 'react';
import { FormProvider } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import BankAccountField from '../../../common/components/Fields/BankAccountField';
import BusinessRegistrationField from '../../../common/components/Fields/BusinessRestrationField';
import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import SelectField from '../../../common/components/Fields/SelectField';
import TextareaField from '../../../common/components/Fields/TextareaField';
import TextField from '../../../common/components/Fields/TextField';
import Modal from '../../../common/components/Modal';
import {
  DATE_ITEMS,
  DEFAULT_BRAND_VALUE,
  DEFAULT_CONTACT_VALUE,
  SETTLEMENT_WARNING_DATES,
} from '../const';
import useCancelConfirmModal from '../hooks/useCancelConfirmModal';
import useVendorFormValues from '../hooks/useVendorFormValues';

import ContactForm from './ContactForm';

type CreateVendorModalProps = {
  onClose: () => void;
};

function CreateVendorModal({ onClose }: CreateVendorModalProps) {
  const { method, onSubmit } = useVendorFormValues();
  const { control, watch, setValue } = method;
  const [settlementDates] = watch(['settlementDates']);
  const showSettlementWarning = settlementDates?.some(
    (date) => date && SETTLEMENT_WARNING_DATES.includes(date)
  );
  const isDirty = !isEmpty(method.formState.dirtyFields);
  const openCancelConfirm = useCancelConfirmModal();
  const onCancel = () => {
    if (isDirty) {
      openCancelConfirm(onClose);
    } else {
      onClose();
    }
  };
  return (
    <Modal title='업체등록' footer={null} onCancel={onCancel}>
      <FormProvider {...method}>
        <form onSubmit={onSubmit(onClose)}>
          <div className='flex flex-col gap-6 py-2'>
            <ManagerSelectField
              label='담당자'
              required
              name='managerId'
              control={control}
              showSearch
            />
            <FieldWithLabel label='업체명' required>
              <TextField
                placeholder='업체명 입력'
                name='name'
                control={control}
                maxLength={30}
              />
            </FieldWithLabel>
          </div>
          <Divider orientation='left'>업체연락처</Divider>
          <div>
            <ArrayField name='vendorContacts' control={control}>
              {({ fields, append, remove, replace }) => (
                <>
                  {fields.map((field, i) => (
                    <ContactForm
                      key={field.key}
                      control={control}
                      typeSelectProps={{ name: `vendorContacts.${i}.type` }}
                      nameInputProps={{ name: `vendorContacts.${i}.name` }}
                      emailInputProps={{ name: `vendorContacts.${i}.email` }}
                      phoneInputProps={{ name: `vendorContacts.${i}.phone` }}
                      onRemove={() => {
                        if (fields.length === 1) {
                          replace(DEFAULT_CONTACT_VALUE);
                        } else {
                          remove(i);
                        }
                      }}
                    />
                  ))}
                  <div className='mt-4'>
                    <Button
                      icon={<PlusOutlined />}
                      onClick={() => append(DEFAULT_CONTACT_VALUE)}
                    />
                  </div>
                </>
              )}
            </ArrayField>
          </div>
          <Divider orientation='left'>업체정산정보</Divider>
          <div className='flex flex-col gap-6'>
            <FieldWithLabel label='정산일자 (매월)'>
              <SelectField
                className='w-full'
                mode='multiple'
                name='settlementDates'
                control={control}
                onChange={(value: string[]) => {
                  if (value.length < 4) {
                    setValue('settlementDates', value, {
                      shouldDirty: true,
                      shouldValidate: true,
                      shouldTouch: true,
                    });
                  }
                }}
                value={settlementDates}
                options={DATE_ITEMS}
              />
              {showSettlementWarning && (
                <p className={'mt-1 text-xs'}>
                  일부 월에 없는 날짜가 포함되어있습니다. 해당 월에는 정산내역이
                  보이지 않을 수 있습니다.
                </p>
              )}
            </FieldWithLabel>
            <FieldWithLabel label='상호명'>
              <TextField
                placeholder='상호명 입력'
                name='businessName'
                control={control}
              />
            </FieldWithLabel>
            <BusinessRegistrationField
              inputProps={{
                name: 'businessRegistrationNumber',
              }}
              uploadProps={{
                id: 'business-registration-image',
                name: 'businessRegistrationImageUrl',
                purpose: 'VENDORS',
              }}
              control={control}
            />
            <BankAccountField
              selectProps={{
                name: 'bankCode',
              }}
              inputProps={{
                name: 'accountNumber',
              }}
              uploadProps={{
                id: 'bank-account-copy-upload',
                name: 'businessBankBookImagerUrl',
                purpose: 'VENDORS',
              }}
              control={control}
            />
            <FieldWithLabel label='예금주명'>
              <TextField
                placeholder='예금주명 입력'
                name='accountHolderName'
                control={control}
              />
            </FieldWithLabel>

            <FieldWithLabel label='비고'>
              <TextareaField
                placeholder='내용입력'
                name='comment'
                control={control}
              />
            </FieldWithLabel>
          </div>
          <div>
            <ArrayField name='brands' control={control}>
              {({ fields, append: appendBrand, remove: removeBrand }) => (
                <div>
                  {fields.map((field, i) => (
                    <Fragment key={field.key}>
                      <div className='grid grid-cols-[1fr_auto] items-center'>
                        <Divider orientation='left'>
                          브랜드정보{i !== 0 && i + 1}
                        </Divider>
                        <Button
                          danger
                          type='text'
                          size='small'
                          icon={<DeleteOutlined />}
                          onClick={() => removeBrand(i)}
                        >
                          브랜드 삭제
                        </Button>
                      </div>
                      <FieldWithLabel label='브랜드명' required>
                        <TextField
                          placeholder='브랜드명 입력'
                          name={`brands.${i}.name`}
                          control={control}
                          maxLength={30}
                        />
                      </FieldWithLabel>
                      <ArrayField
                        name={`brands.${i}.brandContacts`}
                        control={control}
                      >
                        {({
                          fields,
                          append: appendContact,
                          remove: removeContact,
                        }) => (
                          <>
                            {fields.map((field, index) => (
                              <ContactForm
                                key={field.key}
                                control={control}
                                typeSelectProps={{
                                  name: `brands.${i}.brandContacts.${index}.type`,
                                }}
                                nameInputProps={{
                                  name: `brands.${i}.brandContacts.${index}.name`,
                                }}
                                emailInputProps={{
                                  name: `brands.${i}.brandContacts.${index}.email`,
                                }}
                                phoneInputProps={{
                                  name: `brands.${i}.brandContacts.${index}.phone`,
                                }}
                                onRemove={() => {
                                  if (fields.length === 1) {
                                    setValue(`brands.${i}.brandContacts`, [
                                      DEFAULT_CONTACT_VALUE,
                                    ]);
                                  } else {
                                    removeContact(index);
                                  }
                                }}
                              />
                            ))}
                            <div className='mt-4'>
                              <Button
                                icon={<PlusOutlined />}
                                onClick={() =>
                                  appendContact(DEFAULT_CONTACT_VALUE)
                                }
                              />
                            </div>
                          </>
                        )}
                      </ArrayField>
                    </Fragment>
                  ))}
                  <div className='mt-4'>
                    <Button
                      type='primary'
                      icon={<PlusOutlined />}
                      onClick={() => appendBrand(DEFAULT_BRAND_VALUE)}
                    >
                      브랜드추가
                    </Button>
                    {!fields.length && (
                      <div className='mt-2'>
                        <Typography.Text type='danger'>
                          *한 개 이상의 브랜드를 필수로 등록해주세요.
                        </Typography.Text>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </ArrayField>
          </div>
          <div className='mt-4 flex justify-end gap-2'>
            <Button onClick={onCancel}>취소</Button>
            <Button type='primary' htmlType='submit'>
              등록
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default CreateVendorModal;
