import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import * as yup from 'yup';

import {
  EProductGroupDeliveryFeeType,
  EProductGroupReleaseType,
  EProductGroupStatus,
  EProductGroupType,
  EProductUseType,
  ETaxType,
} from '../../../schema/types';
import {
  CreateProductGroupFormValues,
  ProductGroupFormType,
  UpdateProductGroupFormValues,
} from '../types';

export const productGroupSchema = {
  formType: yup
    .mixed<ProductGroupFormType>()
    .oneOf(['CREATE', 'UPDATE'])
    .required('필수 입력 항목입니다.'),
  managerId: yup.number().required('필수 입력 항목입니다.'),
  name: yup
    .string()
    .trim()
    .required('필수 입력 항목입니다.')
    .max(50, '50자 이내로 입력해주세요.'),
  status: yup
    .mixed<EProductGroupStatus>()
    .oneOf(['HOLD', 'PENDING', 'SELL', 'SOLD'])
    .required('필수 입력 항목입니다.'),
  productGroupType: yup
    .mixed<EProductGroupType>()
    .oneOf(['DISTRIBUTION', 'PB'])
    .required('필수 입력 항목입니다.'),
  vendorId: yup.number().required('필수 입력 항목입니다.'),
  brandId: yup.number().required('필수 입력 항목입니다.'),
  uppermostCategoryId: yup.number().required('필수 입력 항목입니다.'),
  upperCategoryId: yup.number().required('필수 입력 항목입니다.'),
  categoryId: yup.number().required('필수 입력 항목입니다.'),
  notice: yup.string(),
  productImage: yup.string().required('필수 입력 항목입니다.'),
  productUrl: yup.string(),
  productUsp: yup.string(),
  sampleSupport: yup.string(),
  isPurchaseQuantityNoLimit: yup.boolean().required('필수 입력 항목입니다.'),
  purchaseQuantityLimit: yup.number().when('isPurchaseQuantityNoLimit', {
    is: false,
    then: (schema) => schema.required('필수 입력 항목입니다.'),
    otherwise: (schema) => schema.nullable(),
  }),
  note: yup.string(),
  deliveryFeeType: yup
    .mixed<EProductGroupDeliveryFeeType>()
    .oneOf(['CHARGED', 'CONDITIONAL_FREE', 'FREE'])
    .required('필수 입력 항목입니다.'),
  deliveryFee: yup
    .number()
    .nullable()
    .when('deliveryFeeType', {
      is: (schema: EProductGroupDeliveryFeeType) => schema !== 'FREE',
      then: (schema) =>
        schema
          .min(1, '배송비는 0보다 커야합니다.')
          .required('필수 입력 항목입니다.'),
    }),
  freeDeliveryConditionFee: yup
    .number()
    .nullable()
    .when('deliveryFeeType', {
      is: 'CONDITIONAL_FREE',
      then: (schema) =>
        schema
          .min(1, '배송비는 0보다 커야합니다.')
          .required('필수 입력 항목입니다.'),
    }),
  isRegionFee: yup.boolean().required('필수 입력 항목입니다.'),
  jejuIslandFee: yup.number().when('isRegionFee', {
    is: true,
    then: (schema) => schema.required('필수 입력 항목입니다.'),
  }),
  additionalFee: yup.number().when('isRegionFee', {
    is: true,
    then: (schema) => schema.required('필수 입력 항목입니다.'),
  }),
  deliveryCompanyCode: yup.string().required('필수 입력 항목입니다.'),
  deliveryCompanyName: yup.string().required('필수 입력 항목입니다.'),
  releaseType: yup
    .mixed<EProductGroupReleaseType>()
    .oneOf(['BULK', 'NEXT_DAY', 'TODAY'])
    .required('필수 입력 항목입니다.'),
  releaseTime: yup.string().when('releaseType', {
    is: 'TODAY',
    then: (schema) => schema.required('필수 입력 항목입니다.'),
  }),
  exchangeFee: yup.number().nullable(),
  returnFee: yup.number().nullable(),
  multimedia: yup
    .object({
      thumbnail: yup
        .array()
        .of(yup.string().required('필수 입력 항목입니다.'))
        .min(1, '한 개 이상의 이미지를 필수로 등록해주세요.')
        .required('필수 입력 항목입니다.'),
      detail: yup
        .array()
        .of(yup.string().required('필수 입력 항목입니다.'))
        .min(1, '한 개 이상의 이미지를 필수로 등록해주세요.')
        .required('필수 입력 항목입니다.'),
      marketing: yup.array().of(yup.string().required('필수 입력 항목입니다.')),
    })
    .required('필수 입력 항목입니다.'),
};

export const productSchema = {
  id: yup.number().required('필수 입력 항목입니다.'),
  name: yup
    .string()
    .trim()
    .max(30, '30자 이내로 입력해주세요.')
    .required('필수 입력 항목입니다.'),
  items: yup
    .array()
    .of(
      yup.object({
        code: yup.string().required('필수 입력 항목입니다.'),
        name: yup
          .string()
          .max(50, '옵션명은 50자 이하로 입력해주세요')
          .required('필수 입력 항목입니다.'),
      })
    )
    .min(1, '한 개 이상의 옵션을 필수로 등록해주세요.')
    .required('필수 입력 항목입니다.'),
  retailPrice: yup.number().nullable().required('필수 입력 항목입니다.'),
  onlineLowestPrice: yup.number().nullable(),
  jointPurchasePrice: yup.number().required('필수 입력 항목입니다.'),
  businessSellerSupplyPrice: yup.number().required('필수 입력 항목입니다.'),
  freelanceSellerSupplyPrice: yup.number().required('필수 입력 항목입니다.'),
  businessSellerMargin: yup.number().required('필수 입력 항목입니다.'),
  freelanceSellerMargin: yup.number().required('필수 입력 항목입니다.'),
  businessSellerMarginRate: yup.number().required('필수 입력 항목입니다.'),
  freelanceSellerMarginRate: yup.number().required('필수 입력 항목입니다.'),
  inHousePurchasePrice: yup.number().required('필수 입력 항목입니다.'),
  businessInHouseMargin: yup.number().required('필수 입력 항목입니다.'),
  freelanceInHouseMargin: yup.number().required('필수 입력 항목입니다.'),
  businessInHouseMarginRate: yup.number().required('필수 입력 항목입니다.'),
  freelanceInHouseMarginRate: yup.number().required('필수 입력 항목입니다.'),
  isAdditionalProduct: yup.boolean().required('필수 입력 항목입니다.'),
  isPrimary: yup.boolean().required('필수 입력 항목입니다.'),
  taxType: yup
    .mixed<ETaxType>()
    .oneOf(['FREE', 'TAX', 'EXEMPTION'])
    .required('필수 입력 항목입니다.'),
  note: yup.string(),
  description: yup.string(),
  useType: yup
    .mixed<EProductUseType>()
    .oneOf(['FIX', 'SELECT'])
    .required('필수 입력 항목입니다.'),
};

export const createProductGroupSchema = yup
  .object<CreateProductGroupFormValues>()
  .shape({
    ...productGroupSchema,
    products: yup
      .array()
      .of(yup.object(productSchema))
      .required('필수 입력 항목입니다.'),
  });

export const updateProductGroupSchema = yup
  .object<UpdateProductGroupFormValues>()
  .shape({
    ...productGroupSchema,
    products: yup
      .array()
      .of(
        yup.object({
          ...productSchema,
          items: yup
            .array()
            .of(
              yup.object({
                code: yup.string().required('필수 입력 항목입니다.'),
                name: yup.string().required('필수 입력 항목입니다.'),
              })
            )
            .min(1, '한 개 이상의 옵션을 필수로 등록해주세요.')
            .required('필수 입력 항목입니다.'),
        })
      )
      .required('필수 입력 항목입니다.'),
  });

export const createProductGroupFormResolver: Resolver<
  CreateProductGroupFormValues
> = (data, context, options) => {
  return yupResolver<CreateProductGroupFormValues>(createProductGroupSchema)(
    data,
    context,
    options
  );
};

export const updateProductGroupFormResolver: Resolver<
  UpdateProductGroupFormValues
> = (data, context, options) => {
  return yupResolver<UpdateProductGroupFormValues>(updateProductGroupSchema)(
    data,
    context,
    options
  );
};
