import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RedeliveryOrderByCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type RedeliveryOrderByCodeQuery = { __typename?: 'Query', adminOrderByCode: { __typename?: 'OrderGraphqlType', id: number, code: string, receiverName: string, receiverPhoneNumber: string, receiverZipCode: string, receiverAddress: string, receiverDetailAddress?: string | null, receiverDeliveryMemo?: string | null, productGroupId: number, productGroupName: string, productId: number, productName: string, productQuantity: number, productUseType: Types.EProductUseType, hasActiveExchangeReDeliveryPromotionRelease?: boolean | null, items?: Array<{ __typename?: 'OrderItemGraphqlType', itemId: number }> | null, activeClaim?: { __typename?: 'OrderClaimGraphqlType', status: Types.EOrderClaimStatus, claimType: Types.EOrderClaimType } | null } };

export type RedeliveryProductItemsQueryVariables = Types.Exact<{
  adminProductGroupId: Types.Scalars['Int']['input'];
  adminProductId: Types.Scalars['Int']['input'];
}>;


export type RedeliveryProductItemsQuery = { __typename?: 'Query', adminProductGroup: { __typename?: 'ProductGroupGraphqlType', exchangeFee?: number | null }, adminProduct?: { __typename?: 'ProductGraphqlType', items?: Array<{ __typename?: 'ItemGraphqlType', id: number, name: string }> | null } | null };

export type AdminUpdateExchangePromotionMutationVariables = Types.Exact<{
  exchangePromotionData: Types.UpdateExchangePromotionInputGraphqlType;
  adminUpdateExchangePromotionId: Types.Scalars['Int']['input'];
}>;


export type AdminUpdateExchangePromotionMutation = { __typename?: 'Mutation', adminUpdateExchangePromotion: { __typename?: 'PromotionOutputGraphqlType', promotion: { __typename?: 'PromotionGraphqlType', id: number } } };


export const RedeliveryOrderByCodeDocument = gql`
    query RedeliveryOrderByCode($code: String!) {
  adminOrderByCode(code: $code) {
    id
    code
    receiverName
    receiverPhoneNumber
    receiverZipCode
    receiverAddress
    receiverDetailAddress
    receiverDeliveryMemo
    productGroupId
    productGroupName
    productId
    productName
    productQuantity
    productUseType
    hasActiveExchangeReDeliveryPromotionRelease
    items {
      itemId
    }
    activeClaim {
      status
      claimType
    }
  }
}
    `;

/**
 * __useRedeliveryOrderByCodeQuery__
 *
 * To run a query within a React component, call `useRedeliveryOrderByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedeliveryOrderByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedeliveryOrderByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useRedeliveryOrderByCodeQuery(baseOptions: Apollo.QueryHookOptions<RedeliveryOrderByCodeQuery, RedeliveryOrderByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RedeliveryOrderByCodeQuery, RedeliveryOrderByCodeQueryVariables>(RedeliveryOrderByCodeDocument, options);
      }
export function useRedeliveryOrderByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RedeliveryOrderByCodeQuery, RedeliveryOrderByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RedeliveryOrderByCodeQuery, RedeliveryOrderByCodeQueryVariables>(RedeliveryOrderByCodeDocument, options);
        }
export type RedeliveryOrderByCodeQueryHookResult = ReturnType<typeof useRedeliveryOrderByCodeQuery>;
export type RedeliveryOrderByCodeLazyQueryHookResult = ReturnType<typeof useRedeliveryOrderByCodeLazyQuery>;
export type RedeliveryOrderByCodeQueryResult = Apollo.QueryResult<RedeliveryOrderByCodeQuery, RedeliveryOrderByCodeQueryVariables>;
export const RedeliveryProductItemsDocument = gql`
    query RedeliveryProductItems($adminProductGroupId: Int!, $adminProductId: Int!) {
  adminProductGroup(id: $adminProductGroupId) {
    exchangeFee
  }
  adminProduct(id: $adminProductId) {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useRedeliveryProductItemsQuery__
 *
 * To run a query within a React component, call `useRedeliveryProductItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedeliveryProductItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedeliveryProductItemsQuery({
 *   variables: {
 *      adminProductGroupId: // value for 'adminProductGroupId'
 *      adminProductId: // value for 'adminProductId'
 *   },
 * });
 */
export function useRedeliveryProductItemsQuery(baseOptions: Apollo.QueryHookOptions<RedeliveryProductItemsQuery, RedeliveryProductItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RedeliveryProductItemsQuery, RedeliveryProductItemsQueryVariables>(RedeliveryProductItemsDocument, options);
      }
export function useRedeliveryProductItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RedeliveryProductItemsQuery, RedeliveryProductItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RedeliveryProductItemsQuery, RedeliveryProductItemsQueryVariables>(RedeliveryProductItemsDocument, options);
        }
export type RedeliveryProductItemsQueryHookResult = ReturnType<typeof useRedeliveryProductItemsQuery>;
export type RedeliveryProductItemsLazyQueryHookResult = ReturnType<typeof useRedeliveryProductItemsLazyQuery>;
export type RedeliveryProductItemsQueryResult = Apollo.QueryResult<RedeliveryProductItemsQuery, RedeliveryProductItemsQueryVariables>;
export const AdminUpdateExchangePromotionDocument = gql`
    mutation AdminUpdateExchangePromotion($exchangePromotionData: UpdateExchangePromotionInputGraphqlType!, $adminUpdateExchangePromotionId: Int!) {
  adminUpdateExchangePromotion(
    exchangePromotionData: $exchangePromotionData
    id: $adminUpdateExchangePromotionId
  ) {
    promotion {
      id
    }
  }
}
    `;
export type AdminUpdateExchangePromotionMutationFn = Apollo.MutationFunction<AdminUpdateExchangePromotionMutation, AdminUpdateExchangePromotionMutationVariables>;

/**
 * __useAdminUpdateExchangePromotionMutation__
 *
 * To run a mutation, you first call `useAdminUpdateExchangePromotionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateExchangePromotionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateExchangePromotionMutation, { data, loading, error }] = useAdminUpdateExchangePromotionMutation({
 *   variables: {
 *      exchangePromotionData: // value for 'exchangePromotionData'
 *      adminUpdateExchangePromotionId: // value for 'adminUpdateExchangePromotionId'
 *   },
 * });
 */
export function useAdminUpdateExchangePromotionMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateExchangePromotionMutation, AdminUpdateExchangePromotionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateExchangePromotionMutation, AdminUpdateExchangePromotionMutationVariables>(AdminUpdateExchangePromotionDocument, options);
      }
export type AdminUpdateExchangePromotionMutationHookResult = ReturnType<typeof useAdminUpdateExchangePromotionMutation>;
export type AdminUpdateExchangePromotionMutationResult = Apollo.MutationResult<AdminUpdateExchangePromotionMutation>;
export type AdminUpdateExchangePromotionMutationOptions = Apollo.BaseMutationOptions<AdminUpdateExchangePromotionMutation, AdminUpdateExchangePromotionMutationVariables>;