/**
 * 사용주의!!
 * error type 정의가 힘들어서 any 처리함
 * @param error
 */
export const getErrorStatus = (error: any): 'error' | 'warning' | undefined => {
  if (!error) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  const type = error?.type;
  const isRequiredError =
    type === 'required' || type === 'optionality' || type === 'nullable';

  const status = isRequiredError ? 'error' : 'warning';

  return status;
};
