import { useFormContext } from 'react-hook-form';

import CheckboxField from '../../../../common/components/Fields/CheckboxField';
import Label from '../../../../common/components/Fields/Label';
import RadioField from '../../../../common/components/Fields/RadioField';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { useAdminMarketEventProductQuery } from '../../graphql/products.generated';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../../types';
import NumberWithUnknownField from '../NumberWithUnknownField';

type ProductPricesProps = {
  eventIndex: number;
  productIndex: number;
};

function ProductPrices({ eventIndex, productIndex }: ProductPricesProps) {
  const { hasPermission } = usePermissions('MARKET_EDIT');

  const { control, watch, setValue } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();

  const product = watch(`events.${eventIndex}.products.${productIndex}`);
  const sellerSettlementInfoType = watch('sellerSettlementInfoType');

  const { data } = useAdminMarketEventProductQuery({
    variables: {
      adminProductId: product.productId ? product.productId : 0,
    },
    skip: !product.productId,
  });

  const isSellerBearing = product.isSellerBearing;
  const isWiredBearing = product.isWiredBearing;
  const hasName = !!product.name;
  const supplyPrice =
    sellerSettlementInfoType === 'BUSINESS'
      ? data?.adminProduct?.businessSellerSupplyPrice
      : data?.adminProduct?.freelanceSellerSupplyPrice;
  const priceEditable =
    hasPermission && !!hasName && watch(`events.${eventIndex}.editable`);

  return (
    <div
      className={'col-span-3 grid w-full grid-cols-6 gap-2 px-8 @lg:w-[600px]'}
    >
      <div className={'col-span-3'}>
        <NumberWithUnknownField
          prefix={<span className={'text-xs'}>자사매입가</span>}
          control={control}
          name={`events.${eventIndex}.products.${productIndex}.inhouseBuyPrice`}
          disabled={!priceEditable}
          onUnknownClick={() =>
            setValue(
              `events.${eventIndex}.products.${productIndex}.inhouseBuyPrice`,
              null,
              {
                shouldValidate: true,
                shouldTouch: true,
                shouldDirty: true,
              }
            )
          }
        />
      </div>
      <div className={'col-span-3'} />
      <div className={'col-span-2'}>
        <CheckboxField
          control={control}
          name={`events.${eventIndex}.products.${productIndex}.isSellerBearing`}
          disabled={!priceEditable}
          onChange={(e) => {
            if (e.target.checked) {
              setValue(
                `events.${eventIndex}.products.${productIndex}.sellerBearingCost`,
                supplyPrice ? supplyPrice * product.count : null
              );
              setValue(
                `events.${eventIndex}.products.${productIndex}.isPrepayment`,
                false
              );
            }
          }}
        >
          <Label
            tooltip={
              '기존에 등록된 품목을 선택한 경우에는 셀러공급가가 자동으로 기입됩니다.'
            }
          >
            셀러 부담
          </Label>
        </CheckboxField>
      </div>
      <div className={'col-span-2'}>
        <CheckboxField
          control={control}
          name={`events.${eventIndex}.products.${productIndex}.isWiredBearing`}
          disabled={!priceEditable}
          onChange={(e) => {
            if (e.target.checked) {
              setValue(
                `events.${eventIndex}.products.${productIndex}.wiredBearingCost`,
                product.inhouseBuyPrice
                  ? product.inhouseBuyPrice * product.count
                  : null
              );
            }
          }}
        >
          <Label
            tooltip={
              '기존에 등록한 품목을 선택한 경우에는 자사매입가가 자동으로 기입됩니다.'
            }
          >
            와이어드 부담
          </Label>
        </CheckboxField>
      </div>
      <div className={'col-span-2'} />
      <div className={'col-span-2'}>
        {isSellerBearing && (
          <NumberWithUnknownField
            disabled={!priceEditable}
            control={control}
            name={`events.${eventIndex}.products.${productIndex}.sellerBearingCost`}
            onUnknownClick={() =>
              setValue(
                `events.${eventIndex}.products.${productIndex}.sellerBearingCost`,
                null,
                {
                  shouldValidate: true,
                  shouldTouch: true,
                  shouldDirty: true,
                }
              )
            }
          />
        )}
      </div>
      <div className={'col-span-2'}>
        {isWiredBearing && (
          <NumberWithUnknownField
            disabled={!priceEditable}
            control={control}
            name={`events.${eventIndex}.products.${productIndex}.wiredBearingCost`}
            onUnknownClick={() =>
              setValue(
                `events.${eventIndex}.products.${productIndex}.wiredBearingCost`,
                null,
                {
                  shouldValidate: true,
                  shouldTouch: true,
                  shouldDirty: true,
                }
              )
            }
          />
        )}
      </div>
      <div className={'col-span-2'}></div>
      {isSellerBearing && (
        <div className={'col-span-4'}>
          <RadioField
            disabled={!priceEditable}
            control={control}
            name={`events.${eventIndex}.products.${productIndex}.isPrepayment`}
            options={[
              { label: '정산차감', value: false },
              { label: '선결제', value: true },
            ]}
          />
        </div>
      )}
    </div>
  );
}

export default ProductPrices;
