import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { App, Tag, Switch, Button, Divider, Typography } from 'antd';
import { get, isEmpty } from 'lodash-es';
import { useEffect, MouseEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import TextField from '../../../common/components/Fields/TextField';
import HistoryModal from '../../../common/components/History/HistoryModal';
import { HistoriesDocument } from '../../../common/graphql/history.generated';
import { useFormError } from '../../../common/hooks/useFormError';
import {
  BrandContactGraphqlType,
  EBrandStatus,
  UpdateBrandInput,
} from '../../../schema/types';
import { DEFAULT_CONTACT_VALUE } from '../const';
import { useUpdateFormDirtyContext } from '../contexts/UpdateFormDirtyContext';
import { useUpdateBrandMutation } from '../graphql/brand.generated';
import { VendorWithBrandsDocument } from '../graphql/vendor.generated';
import useRemoveVendorOrBrand from '../hooks/useRemoveVendorOrBrand';
import { updateBrandSchema } from '../validator';

import ContactForm from './ContactForm';

type BrandDetailFormItemProps = {
  brandId: number;
  name: string;
  status: EBrandStatus;
  removeDisabled: boolean;
  disabled?: boolean;
  brandContacts: Omit<
    BrandContactGraphqlType,
    '__typename' | 'createdAt' | 'updatedAt'
  >[];
};

export function BrandDetailExtra({
  brandId,
  status,
  disabled,
  removeDisabled,
}: Omit<BrandDetailFormItemProps, 'name' | 'brandContacts'>) {
  const { message } = App.useApp();
  const removeBrand = useRemoveVendorOrBrand({ type: 'BRAND', id: brandId });
  const handleRemove = (
    ev: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLAnchorElement>
  ) => {
    ev.stopPropagation();
    if (removeDisabled) {
      message.error(
        '해당 브랜드를 삭제할 수 없습니다. 최소 1개 브랜드가 필수 등록되어야 합니다.'
      );
      return;
    }
    removeBrand();
  };
  return (
    <>
      <Tag color={status === 'ACTIVE' ? 'blue' : 'default'}>
        {status === 'ACTIVE' ? '거래중' : '거래중단'}
      </Tag>
      <Button
        danger
        type='text'
        size='small'
        disabled={disabled}
        onClick={handleRemove}
      >
        삭제
      </Button>
    </>
  );
}

export function BrandDetailForm({
  brandId,
  disabled,
  ...props
}: BrandDetailFormItemProps) {
  const { message } = App.useApp();
  const { onInvalid } = useFormError();
  const { setIsDirty } = useUpdateFormDirtyContext();
  const [updateBrand] = useUpdateBrandMutation();
  const method = useForm<UpdateBrandInput>({
    defaultValues: {
      id: brandId,
      name: props.name,
      status: props.status,
      brandContacts: props.brandContacts,
    },
    resolver: yupResolver<UpdateBrandInput>(updateBrandSchema),
  });
  const { control, watch, setValue, handleSubmit, formState } = method;
  const nameError = get(formState.errors, 'name');
  const [status, name] = watch(['status', 'name']);
  const isUpdated = !isEmpty(method.formState.dirtyFields);
  useEffect(() => {
    setIsDirty(isUpdated);
  }, [isUpdated, setIsDirty]);

  const handleSwitch = (checked: boolean) => {
    setValue('status', checked ? 'ACTIVE' : 'INACTIVE', {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  };
  const onSubmit = handleSubmit(async (data) => {
    await updateBrand({
      variables: { updateBrandInput: data },
      onCompleted() {
        method.reset(method.getValues());
        message.success('저장이 완료되었습니다.');
      },
      onError() {
        message.error('저장을 실패하였습니다.');
      },
      refetchQueries: [HistoriesDocument, VendorWithBrandsDocument],
    });
  }, onInvalid);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [nameEditable, setNameEditable] = useState(false);

  return (
    <FormProvider {...method}>
      <form onSubmit={onSubmit}>
        <div className='flex items-center justify-between'>
          <Tag className={'mb-sm'}>{`브랜드ID: ${brandId}`}</Tag>
          <Typography.Text className='flex items-center gap-2'>
            거래중
            <Switch
              size='small'
              disabled={disabled}
              checked={status === 'ACTIVE'}
              onChange={handleSwitch}
            />
          </Typography.Text>
        </div>

        {nameEditable ? (
          <TextField
            className='w-[480px]'
            control={control}
            name='name'
            onBlur={() => setNameEditable(false)}
            maxLength={30}
          />
        ) : (
          <Typography.Title className='m-0 flex items-center gap-sm' level={3}>
            {name}
            {!disabled && (
              <Button
                htmlType='button'
                size='small'
                type='default'
                danger={!!nameError}
                icon={<EditOutlined />}
                onClick={() => setNameEditable(true)}
              />
            )}
          </Typography.Title>
        )}
        <Divider orientation='left'>브랜드연락처</Divider>
        <ArrayField name='brandContacts' control={control}>
          {({ fields, append, remove, replace }) => (
            <>
              {fields.map((field, i) => (
                <ContactForm
                  key={field.key}
                  type='row'
                  control={control}
                  disabled={disabled}
                  typeSelectProps={{ name: `brandContacts.${i}.type` }}
                  nameInputProps={{ name: `brandContacts.${i}.name` }}
                  emailInputProps={{ name: `brandContacts.${i}.email` }}
                  phoneInputProps={{ name: `brandContacts.${i}.phone` }}
                  onRemove={() => {
                    if (fields.length === 1) {
                      replace(DEFAULT_CONTACT_VALUE);
                    } else {
                      remove(i);
                    }
                  }}
                />
              ))}
              <Button
                className='mt-2'
                icon={<PlusOutlined />}
                disabled={disabled}
                onClick={() => append(DEFAULT_CONTACT_VALUE)}
              />
            </>
          )}
        </ArrayField>

        <div className='mt-6 flex justify-between'>
          <Button onClick={() => setHistoryModalOpen(true)}>
            변경히스토리
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            disabled={disabled || !isUpdated}
          >
            저장
          </Button>
        </div>
      </form>
      <HistoryModal
        uiType='BRAND'
        id={brandId}
        open={historyModalOpen}
        onClose={() => setHistoryModalOpen(false)}
      />
    </FormProvider>
  );
}
