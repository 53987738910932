import { App, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';

type PeriodSelectFieldParams<TPeriodType> = {
  periodType?: TPeriodType;
  startedAt?: string;
  endedAt?: string;
};

type PeriodSelectFieldProps<TPeriodType> =
  PeriodSelectFieldParams<TPeriodType> & {
    periodOptions: { label: string; value: TPeriodType }[];
    onChange: (params: PeriodSelectFieldParams<TPeriodType>) => void;
  };

function PeriodSelect<TPeriodType>({
  periodOptions,
  onChange,
  periodType,
  startedAt,
  endedAt,
}: PeriodSelectFieldProps<TPeriodType>) {
  const { message } = App.useApp();

  const handlePeriodType = (v: TPeriodType) => {
    const startedAtInPeriodType =
      startedAt || endedAt ? startedAt : dayjs().add(-7, 'day').toISOString();
    const endedAtInPeriodType =
      startedAt || endedAt ? endedAt : dayjs().toISOString();

    onChange({
      periodType: v,
      startedAt: startedAtInPeriodType,
      endedAt: endedAtInPeriodType,
    });
  };

  const handleStartedAt = (v: dayjs.Dayjs | null) => {
    if (!v) {
      if (!endedAt) {
        onChange({
          periodType: undefined,
          startedAt: undefined,
          endedAt: undefined,
        });

        return;
      }

      onChange({
        periodType,
        startedAt: undefined,
        endedAt,
      });

      return;
    }

    let newStartedAt = v.toISOString();
    let newEndedAt = endedAt ?? dayjs().toISOString();

    if (endedAt) {
      const maxStartedAt = v.add(3, 'month').toISOString();
      const isBetween = dayjs(endedAt).isBetween(v, maxStartedAt, 'day', '[]');

      if (v.isAfter(endedAt)) {
        newStartedAt = endedAt;
      } else if (!isBetween) {
        void message.warning('최대 3개월까지 조회 가능합니다.');
        newEndedAt = maxStartedAt;
      }
    }

    onChange({
      periodType: periodType ? periodType : periodOptions[0].value,
      startedAt: newStartedAt,
      endedAt: newEndedAt,
    });
  };

  const handleEndedAt = (v: dayjs.Dayjs | null) => {
    if (!v) {
      if (!startedAt) {
        onChange({
          periodType: undefined,
          startedAt: undefined,
          endedAt: undefined,
        });

        return;
      }

      onChange({
        periodType,
        startedAt,
        endedAt: undefined,
      });

      return;
    }

    let newEndedAt = v.toISOString();
    let newStartedAt = startedAt;

    if (startedAt) {
      const minEndedAt = v.add(-3, 'month').toISOString();
      const isBetween = dayjs(startedAt).isBetween(minEndedAt, v, 'day', '[]');

      if (v.isBefore(startedAt)) {
        newEndedAt = startedAt;
      } else if (!isBetween) {
        void message.warning('최대 3개월까지 조회 가능합니다.');
        newStartedAt = minEndedAt;
      }
    }

    onChange({
      periodType: periodType ? periodType : periodOptions[0].value,
      startedAt: newStartedAt,
      endedAt: newEndedAt,
    });
  };

  return (
    <div className='flex items-center'>
      <Select
        options={periodOptions}
        onChange={handlePeriodType}
        value={periodType}
        placeholder='--선택--'
        className='mr-2 w-[180px]'
      />
      <div>
        <DatePicker
          className='w-[160px]'
          onChange={handleStartedAt}
          value={startedAt ? dayjs(startedAt) : null}
        />
      </div>
      <span className='mx-2'>~</span>
      <div>
        <DatePicker
          className='w-[160px]'
          onChange={handleEndedAt}
          value={endedAt ? dayjs(endedAt) : null}
        />
      </div>
    </div>
  );
}

export default PeriodSelect;
