import { useFormContext, useWatch } from 'react-hook-form';

import TextField from '../../../common/components/Fields/TextField';
import { ProductGroupFormValues } from '../types';

import ProductOptionSelectField from './Fields/ProductOptionSelectField';

type ProductTypeProps = {
  index: number;
  disabled: boolean;
};

const ProductOptionType = ({ index, disabled }: ProductTypeProps) => {
  const { control } = useFormContext<ProductGroupFormValues>();
  const useType = useWatch({ control, name: `products.${index}.useType` });

  return (
    <>
      <div className='w-[560px]'>
        <ProductOptionSelectField
          index={index}
          disabled={disabled}
          placeholder={
            useType === 'SELECT'
              ? '구매자가 선택할 수 있는 옵션을 기재해주세요.'
              : '구성되어 있는 옵션 혹은 재고를 관리할 수 있는 최소단위를 기재해주세요. (ex) 세븐브레스 1box'
          }
          hideErrorMessage
        />
      </div>
      {useType === 'SELECT' && (
        <div className='flex-1'>
          <TextField
            control={control}
            name={`products.${index}.description`}
            placeholder='상품등록 시, 참고해야할 내용이 있다면 적어주세요.'
            maxLength={100}
            disabled={disabled}
          />
        </div>
      )}
    </>
  );
};

export default ProductOptionType;
