import { LinkOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { sumBy } from 'lodash-es';

import { bankLabels } from '../../../../common/utils/const';
import { ROUTES } from '../../../../routes/const';
import PdfButton from '../../components/settlement-sheet-list/PdfButton';
import {
  SELLER_SETTLEMENT_TYPE_LABEL,
  SELLING_CHANNEL_LABEL,
  SETTLEMENT_SHEET_STATUS_LABEL,
} from '../../const';
import { SettlementSheetListTableDataType } from '../../types';

import TaxInvoiceInssuedAtCell from './TaxInvoiceInssuedAtCell';
import TaxInvoiceIssuedCell from './TaxInvoiceIssuedCell';

export const columns: ColumnsType<SettlementSheetListTableDataType> = [
  {
    width: 180,
    title: '정산서코드',
    dataIndex: 'code',
    key: 'code',
    render: (_, record) => (
      <div className='flex items-center justify-between'>
        {record.code}
        <Button
          type='link'
          icon={<LinkOutlined />}
          href={`${ROUTES.SETTLEMENTS_LIST.path}?settlementSheetCode=${record.code}`}
          target='_blank'
          rel='noopener noreferer'
        />
      </div>
    ),
  },
  {
    width: 240,
    title: '마켓명',
    dataIndex: 'marketName',
    key: 'marketName',
    render: (_, record) => (
      <Tooltip title={record.marketName} placement='bottomLeft' arrow={false}>
        <Typography.Paragraph ellipsis={{ rows: 1 }} className='mb-0'>
          {record.marketName}
        </Typography.Paragraph>
      </Tooltip>
    ),
  },
  {
    width: 180,
    title: '마켓기간',
    key: 'period',
    render: (_, record) => `${
      record.marketStartedAt
        ? dayjs(record.marketStartedAt).format('YYYY.MM.DD')
        : ''
    } ~ ${
      record.marketEndedAt
        ? dayjs(record.marketEndedAt).format('YYYY.MM.DD')
        : '상시'
    }
    `,
  },
  {
    width: 170,
    title: '판매채널',
    dataIndex: 'salesChannel',
    key: 'salesChannel',
    render: (_, record) =>
      record.salesChannel ? SELLING_CHANNEL_LABEL[record.salesChannel] : '-',
  },
  {
    width: 120,
    title: '정산예정일',
    dataIndex: 'expectedSettlementAt',
    key: 'expectedSettlementAt',
    render: (_, record) =>
      record.expectedSettlementAt
        ? dayjs(record.expectedSettlementAt).format('YYYY.MM.DD')
        : '-',
  },
  {
    width: 120,
    title: '정산유형',
    dataIndex: 'sellerSettlementType',
    key: 'sellerSettlementType',
    render: (_, record) =>
      record.sellerSettlementType
        ? SELLER_SETTLEMENT_TYPE_LABEL[record.sellerSettlementType]
        : '-',
  },
  {
    width: 120,
    title: '정산상태',
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => SETTLEMENT_SHEET_STATUS_LABEL[record.status],
  },
  {
    width: 120,
    title: '매출금액',
    dataIndex: 'salesAmount',
    key: 'salesAmount',
    render: (_, record) => record.salesAmount.toLocaleString(),
  },
  {
    width: 120,
    title: '판매정산금액',
    dataIndex: 'settlementAmount',
    key: 'settlementAmount',
    render: (_, record) => record.settlementAmount.toLocaleString(),
  },
  {
    width: 120,
    title: '결제수수료',
    dataIndex: 'paymentFee',
    key: 'paymentFee',
    render: (_, record) => record.paymentFee.toLocaleString(),
  },
  {
    width: 120,
    title: '샘플금액',
    dataIndex: 'sampleAmount',
    key: 'sampleAmount',
    render: (_, record) => record.sampleAmount.toLocaleString(),
  },
  {
    width: 120,
    title: '이벤트금액',
    dataIndex: 'eventAmount',
    key: 'eventAmount',
    render: (_, record) => record.eventAmount.toLocaleString(),
  },
  {
    width: 120,
    title: '기타금액',
    dataIndex: 'etcAmount',
    key: 'etcAmount',
    render: (_, record) => record.etcAmount.toLocaleString(),
  },
  {
    width: 120,
    title: '반품배송비',
    dataIndex: 'refundDeliveryFeeAmount',
    key: 'refundDeliveryFeeAmount',
    render: (_, record) => record.refundDeliveryFeeAmount.toLocaleString(),
  },
  {
    width: 120,
    title: '교환배송비',
    dataIndex: 'exchangeDeliveryFeeAmount',
    key: 'exchangeDeliveryFeeAmount',
    render: (_, record) => record.exchangeDeliveryFeeAmount.toLocaleString(),
  },
  {
    width: 120,
    title: '원천징수세',
    dataIndex: 'withholdingTax',
    key: 'withholdingTax',
    render: (_, record) =>
      record.withholdingTax ? record.withholdingTax.toLocaleString() : '-',
  },
  {
    width: 120,
    title: '최종정산금액',
    dataIndex: 'finalSettlementAmount',
    key: 'finalSettlementAmount',
    render: (_, record) =>
      record.finalSettlementAmount
        ? record.finalSettlementAmount.toLocaleString()
        : '-',
  },
  {
    width: 120,
    title: '계산서 발행',
    dataIndex: 'isTaxInvoiceIssued',
    key: 'isTaxInvoiceIssued',
    render: (_, record) => (
      <TaxInvoiceIssuedCell
        settlementSheetId={record.id}
        isIssued={record.isTaxInvoiceIssued}
      />
    ),
  },
  {
    width: 160,
    title: '계산서 발행일',
    dataIndex: 'taxInvoiceIssuedAt',
    key: 'taxInvoiceIssuedAt',
    render: (_, record) => (
      <TaxInvoiceInssuedAtCell
        settlementSheetId={record.id}
        issuedAt={record.taxInvoiceIssuedAt}
      />
    ),
  },
  {
    width: 120,
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (_, record) => dayjs(record.createdAt).format('YYYY.MM.DD'),
  },
  {
    width: 120,
    title: '정산완료일',
    dataIndex: 'settlementFinishedAt',
    key: 'settlementFinishedAt',
    render: (_, record) =>
      record.settlementFinishedAt
        ? dayjs(record.settlementFinishedAt).format('YYYY.MM.DD')
        : '-',
  },
  {
    width: 120,
    title: '입금은행',
    dataIndex: 'bank',
    key: 'bank',
    render: (_, record) => (record.bank ? bankLabels[record.bank] : '-'),
  },
  {
    width: 160,
    title: '입금계좌번호',
    dataIndex: 'accountNumber',
    key: 'accountNumber',
  },
  {
    width: 160,
    title: '예금주명',
    dataIndex: 'accountHolderName',
    key: 'accountHolderName',
  },
  {
    width: 120,
    title: '정산서PDF',
    key: 'pdf',
    render: (_, record) => <PdfButton settlementSheetId={record.id} />,
  },
];

export const summaryRenderer = (
  dataSource: readonly SettlementSheetListTableDataType[],
  hasPermission: boolean
) => {
  const salesAmount = sumBy(dataSource, (item) => item.salesAmount);
  const settlementAmount = sumBy(dataSource, (item) => item.settlementAmount);
  const paymentFee = sumBy(dataSource, (item) => item.paymentFee);
  const sampleAmount = sumBy(dataSource, (item) => item.sampleAmount);
  const eventAmount = sumBy(dataSource, (item) => item.eventAmount);
  const etcAmount = sumBy(dataSource, (item) => item.etcAmount);
  const refundDeliveryFeeAmount = sumBy(
    dataSource,
    (item) => item.refundDeliveryFeeAmount
  );
  const exchangeDeliveryFeeAmount = sumBy(
    dataSource,
    (item) => item.exchangeDeliveryFeeAmount
  );
  const withholdingTax = sumBy(dataSource, (item) => item.withholdingTax || 0);
  const finalSettlementAmount = sumBy(
    dataSource,
    (item) => item.finalSettlementAmount
  );

  return (
    <Table.Summary fixed='top'>
      <Table.Summary.Row className='bg-[#fafafa] font-semibold'>
        {hasPermission && <Table.Summary.Cell index={-1} colSpan={1} />}
        <Table.Summary.Cell index={0} colSpan={7}>
          합계
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1}>
          {salesAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          {settlementAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          {paymentFee.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4}>
          {sampleAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          {eventAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}>
          {etcAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7}>
          {refundDeliveryFeeAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8}>
          {exchangeDeliveryFeeAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={9}>
          {withholdingTax.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={10}>
          {finalSettlementAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={0} colSpan={8}></Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};
