import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { useEditor, EditorContent } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import DOMPurify from 'dompurify';
import { useEffect } from 'react';

import useAntdTheme from '../hooks/useAntdTheme';

export type SimpleEditorProps = {
  content: string;
  onChange: (content: string) => void;
  onBlur?: () => void;
  placeholder?: string;
  focusOnMount?: boolean;
  hasError?: boolean;
  editable?: boolean;
  wrapperClassName?: string;
  deps?: React.DependencyList;
};

type TiptapExtensionType = typeof StarterKit | typeof Placeholder | typeof Link;

export default function SimpleEditor({
  content,
  onChange,
  placeholder = '내용입력',
  onBlur,
  focusOnMount,
  hasError,
  editable,
  wrapperClassName,
  deps,
}: SimpleEditorProps) {
  const {
    colorBorder,
    colorErrorBorder,
    colorBgContainerDisabled,
    colorTextDisabled,
  } = useAntdTheme();
  const extensions: TiptapExtensionType[] = [StarterKit, Link];

  if (placeholder) {
    const placeHolderExtension = Placeholder.configure({ placeholder });
    extensions.push(placeHolderExtension);
  }

  const editor = useEditor(
    {
      extensions,
      content: DOMPurify.sanitize(content),
      editorProps: {
        attributes: {
          class: 'focus:outline-none h-full',
        },
      },
      onUpdate: ({ editor }) => {
        if (editor.isEmpty) {
          onChange('');
          return;
        }

        const sanitizedContent = DOMPurify.sanitize(editor.getHTML());

        onChange(sanitizedContent);
      },
      editable,
      onBlur,
    },
    deps
  );
  // onBlur && editor?.setOptions({ onBlur });

  useEffect(() => {
    if (editor && focusOnMount) {
      editor.commands.focus();
    }
  }, [editor, focusOnMount]);

  if (!editor) return null;

  return (
    <div
      className={
        wrapperClassName ||
        'min-h-[108px] resize overflow-scroll rounded-md border border-solid bg-white px-[11px] py-1'
      }
      style={{
        borderColor: hasError ? colorErrorBorder : colorBorder,
        backgroundColor: editable ? undefined : colorBgContainerDisabled,
        color: editable ? undefined : colorTextDisabled,
      }}
    >
      <EditorContent
        editor={editor}
        value={content}
        className='h-full w-full [&_ol]:px-4 [&_ul]:px-4'
        disabled={!editable}
      />
    </div>
  );
}
