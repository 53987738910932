import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { ArchiveUploadList } from '../Archive/ArchiveUploadList';

type ArchiveUploadListFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  title: string;
  accept?: string;
  required?: boolean;
  editable?: boolean;
  hideErrorMessage?: boolean;
};

export default function ArchiveUploadListField<
  TFieldValues extends FieldValues,
>({ control, name, ...props }: ArchiveUploadListFieldProps<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState }) => {
        return (
          <ArchiveUploadList
            uploadList={value || []}
            onChange={onChange}
            error={fieldState.error?.message}
            {...props}
          />
        );
      }}
    />
  );
}
