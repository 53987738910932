import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip, Typography } from 'antd';

import useExcelDownload from '../../../../common/hooks/useExcelDownload';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { SETTLEMENT_UNKNOWN_AMOUNT_OPTIONS } from '../../const';
import useSettlementListActions from '../../hooks/useSettlementListActions';
import useSettlementListSearchForm from '../../hooks/useSettlementListSearchForm';
import useSettlementListSearchParams from '../../hooks/useSettlementListSearchParams';
import { SettlementListTableDataType } from '../../types';

import AddSettlementSheetModal from './AddSettlementSheetModal';
import CreateSettlementSheetModal from './CreateSettlementSheetModal';

type SettlementListTableHeaderProps = {
  selectedRows: SettlementListTableDataType[];
};

function SettlementListTableHeader({
  selectedRows,
}: SettlementListTableHeaderProps) {
  const { hasPermission } = usePermissions('SETTLEMENT_EDIT');
  const { handleUnknownAmountChange, searchParams } =
    useSettlementListSearchForm();

  const {
    addSettlementSheetModalOpen,
    setAddSettlementSheetModalOpen,
    createSettlementSheetModalOpen,
    setCreateSettlementSheetModalOpen,
    handleCreateSettlementSheet,
    handleAddSettlementSheet,
  } = useSettlementListActions();

  const { filter } = useSettlementListSearchParams();

  const { excelDownload } = useExcelDownload();

  const handleExcelDownload = async () => {
    const params = {
      ...filter,
      settlementTypes: filter.settlementTypes
        ? filter.settlementTypes.join(',')
        : undefined,
    };

    await excelDownload({
      url: '/admin/settlements/download',
      config: {
        params,
      },
      fileName: '정산리스트',
    });
  };

  return (
    <>
      <div className='sticky top-tableTitlePositionTop z-10 flex flex-col gap-4 bg-white pb-4 pt-6'>
        <Typography.Title level={4} className={'sticky mb-0 bg-white'}>
          정산내역 리스트
        </Typography.Title>
        <div className='flex justify-between'>
          <div>
            {hasPermission && !!selectedRows.length && (
              <Button.Group>
                <Button
                  onClick={() => handleCreateSettlementSheet(selectedRows)}
                >
                  정산서 생성
                </Button>
                <Button
                  onClick={() => {
                    handleAddSettlementSheet(selectedRows);
                  }}
                >
                  기존 정산서에 추가
                </Button>
              </Button.Group>
            )}
          </div>
          <div className='flex'>
            <Button onClick={handleExcelDownload}>엑셀 다운로드</Button>
            <Select
              className=' [&_.ant-select-arrow]:text-primary [&_.ant-select-selection-item]:text-primary'
              bordered={false}
              value={searchParams.unknownAmountType || 'ALL'}
              onChange={handleUnknownAmountChange}
              options={SETTLEMENT_UNKNOWN_AMOUNT_OPTIONS}
              dropdownStyle={{ minWidth: '146px' }}
            />
            <Tooltip
              title={
                '샘플 / 이벤트 / 기타 등 출고건에 각 정산금액을 입력하지 않은 건들을 모아볼 수 있습니다.'
              }
              placement='top'
              arrow={{
                pointAtCenter: true,
              }}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        </div>
      </div>

      {addSettlementSheetModalOpen && (
        <AddSettlementSheetModal
          selectedSettlements={selectedRows}
          onClose={() => setAddSettlementSheetModalOpen(false)}
        />
      )}
      {createSettlementSheetModalOpen && (
        <CreateSettlementSheetModal
          selectedSettlements={selectedRows}
          onClose={() => setCreateSettlementSheetModalOpen(false)}
        />
      )}
    </>
  );
}

export default SettlementListTableHeader;
