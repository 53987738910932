import { Typography, Table, Tooltip, Tag } from 'antd';
import { useMemo, useState } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { EOrderClaimStatus, EOrderClaimType } from '../../../schema/types';
import { APP_HEADER_HEIGHT } from '../../../theme/const';
import OrderDetailModal from '../../order/component/OrderDetailModal';
import { ORDER_STATUS_TEXT, PAYMENT_METHOD_TEXT } from '../../order/const';
import { convertDate } from '../../order/helper';
import {
  AdminOrdersInClaimsQuery,
  useRedeliveryCompaniesQuery,
} from '../graphql/claims.generated';
import { toSalesChannelLabel } from '../helper/create';
import { ClaimListSearchParams, OrderClaimsTableData } from '../type';

import CreateClaimSelect from './ClaimStatusSelects/CreateClaimSelect';
import OrderSupportStatusCell from './ClaimStatusSelects/OrderSupportSelect';
import UpdateClaimSelect from './ClaimStatusSelects/UpdateClaimSelect';
import OrderSupportId from './OrderSupport/OrderSupportId';

type OrderClaimsTableProps = {
  orders: Exclude<
    AdminOrdersInClaimsQuery['adminOrders'],
    undefined | null
  >['edges'];
  total: number;
  claimStatus?: EOrderClaimStatus;
  tableType: EOrderClaimType | 'SUPPORT';
};
function OrderClaimsTable({
  total,
  orders,
  tableType,
  claimStatus,
}: OrderClaimsTableProps) {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<ClaimListSearchParams>();
  const { data: deliveryCompaniesData } = useRedeliveryCompaniesQuery();
  const deliveryCompanies = useMemo(() => {
    return (
      deliveryCompaniesData?.adminDeliveryCompanies.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur.name }),
        {} as { [k in number]: string }
      ) || {}
    );
  }, [deliveryCompaniesData]);

  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<number | undefined>();

  const pageSize = Number(searchParams.size) || 50;
  const showOrderStatus =
    tableType === 'SUPPORT' ||
    claimStatus === 'RETURN_COMPLETED' ||
    claimStatus === 'EXCHANGE_COMPLETED' ||
    claimStatus === 'CANCEL_COMPLETED';
  const hideClaimStatus =
    claimStatus === 'RETURN_COMPLETED' || claimStatus === 'CANCEL_COMPLETED';

  const handlePageChange = (page: number, size: number) => {
    if (size !== pageSize) {
      setSearchParams({ page: '1', size: size.toString() });
    } else {
      setSearchParams({ page: page.toString() });
    }
  };
  const handleOrderCodeClick = (orderId: number) => {
    setOpenDetailModal(true);
    setCurrentOrderId(orderId);
  };

  const dataSource = useMemo<OrderClaimsTableData[]>(() => {
    const data = orders.map((item) => {
      const order = item.node;
      const activeClaim = order.activeClaim || undefined;
      const partialClaim = ['PARTIAL_RETURN', 'PARTIAL_EXCHANGE'].includes(
        order.claims?.[0]?.claimType || ''
      )
        ? order.claims?.[0]
        : null;
      const orderGroup = order.group;

      return {
        key: order.id,
        claimType: activeClaim?.claimType,
        orderId: order.id,
        orderCode: order.code,
        orderGroupId: orderGroup.id,
        orderGroupCode: orderGroup.code,
        orderStatusLabel: ORDER_STATUS_TEXT[order.status],
        claimStatus: activeClaim?.status,
        marketName: `${orderGroup.marketName || ''}\n${
          orderGroup.marketId ? `(${orderGroup.marketId})` : ''
        }`,
        vendorName: orderGroup.vendorName || '',
        brandName: orderGroup.brandName || '',
        productGroupName: order.productGroupName,
        productName: order.productName,
        itemNames: order.items?.map((item) => item.name).join(', ') || '',
        salesChannelProductName: order.salesChannelProductName || '',
        productUseType: order.productUseType,
        claimQuantity: activeClaim?.claimQuantity?.toString() || '-',
        productQuantity: order.productQuantity.toLocaleString() || '-',
        orderAmount: order.orderAmount
          ? order.orderAmount.toLocaleString()
          : '-',
        marketEventId: order.marketEventId?.toString() || '',
        bundleDeliveryCode: order.bundleDeliveryCode || '-',
        deliveryFee: order.deliveryFee
          ? order.deliveryFee.toLocaleString()
          : '-',
        regionDeliveryFee: order.regionDeliveryFee
          ? order.regionDeliveryFee.toLocaleString()
          : '-',
        extraDiscount: order.extraDiscount
          ? order.extraDiscount.toLocaleString()
          : '-',
        deliveryCompanyCode: order.deliveryCompanyCode || null,
        deliveryCompanyName: order.deliveryCompanyName || '-',
        trackingNumber: order.trackingNumber || '-',
        redeliveryCompanyName: order.claims?.[0]?.promotionReleaseDelivery
          ?.deliveryCompanyId
          ? deliveryCompanies[
              order.claims?.[0]?.promotionReleaseDelivery?.deliveryCompanyId
            ]
          : '-',
        redeliveryTrackingNumber:
          order.claims?.[0]?.promotionReleaseDelivery?.trackingNumber || '-',
        receiverName: order.receiverName,
        receiverPhoneNumber: order.receiverPhoneNumber,
        receiverAddress: order.receiverAddress,
        receiverDetailAddress: order.receiverDetailAddress || '',
        receiverZipCode: order.receiverZipCode,
        receiverDeliveryMemo: order.receiverDeliveryMemo || '',
        salesChannelType: order.salesChannelType,
        salesChannel: order.salesChannel,
        salesChannelLabel: toSalesChannelLabel(
          order.salesChannelType,
          order.salesChannel
        ),
        orderer: `${orderGroup.ordererName || '-'} / ${
          orderGroup.ordererPhoneNumber || '-'
        }`,
        paymentMethod: orderGroup.paymentMethod
          ? PAYMENT_METHOD_TEXT[orderGroup.paymentMethod]
          : '-',
        pointDiscount: order.pointDiscount
          ? order.pointDiscount.toLocaleString()
          : '-',
        createdAt: convertDate(order.createdAt),
        paidAt: convertDate(orderGroup.paidAt),
        orderConvertedAt: convertDate(order.orderConvertedAt),
        updatedAt: convertDate(order.updatedAt),
        activeClaim,
        supportStatus: order.support?.status,
        supportId: order.support?.id,
        orderStatus: order.status,
        claims: order.claims,
        orderType: order.orderType,
        salesChannelOrderCode: order.salesChannelOrderCode || '',
        salesChannelOrderGroupCode:
          order.group.salesChannelOrderGroupCode || '',
        partialClaim,
        hasActiveExchangeReDeliveryPromotionRelease:
          !!order.hasActiveExchangeReDeliveryPromotionRelease,
        orderedAt: convertDate(order.group.orderedAt),
      };
    });

    return data;
  }, [orders, deliveryCompanies]);

  return (
    <>
      <Table
        scroll={{ x: 4608 }}
        tableLayout='fixed'
        dataSource={dataSource}
        pagination={{
          showSizeChanger: true,
          total,
          showTotal: (total) => `총 ${total}건`,
          pageSize,
          onChange: handlePageChange,
        }}
        sticky={{
          offsetHeader: APP_HEADER_HEIGHT,
        }}
      >
        {tableType === 'SUPPORT' && (
          <Table.Column<OrderClaimsTableData>
            title=''
            width={120}
            dataIndex={'supportId'}
            fixed={'left'}
            render={(_, record) => {
              return (
                <OrderSupportId
                  orderId={record.orderId}
                  orderGroupId={record.orderGroupId}
                />
              );
            }}
          />
        )}

        <Table.Column<OrderClaimsTableData>
          title='와이어드 품목주문코드'
          width={200}
          dataIndex={'orderCode'}
          fixed={'left'}
          render={(orderCode: string, record) => {
            return (
              <div className='text-center'>
                <Typography.Paragraph
                  className='m-0 cursor-pointer text-primary'
                  onClick={() => handleOrderCodeClick(record.orderId)}
                >
                  {orderCode}
                </Typography.Paragraph>
                {record.marketEventId && (
                  <div>
                    <Tag>{`이벤트ID: ${record.marketEventId}`}</Tag>
                  </div>
                )}
                <Tooltip
                  title='판매채널상품주문번호'
                  arrow={false}
                  placement='bottomLeft'
                >
                  <Typography.Paragraph className='m-0 text-description'>
                    {record.salesChannelOrderCode &&
                      `(${record.salesChannelOrderCode})`}
                  </Typography.Paragraph>
                </Tooltip>
              </div>
            );
          }}
        />
        <Table.Column<OrderClaimsTableData>
          title='와이어드 주문코드'
          width={200}
          dataIndex={'orderGroupCode'}
          fixed={'left'}
          render={(_, record) => {
            return (
              <>
                <Typography.Paragraph className='m-0'>
                  {record.orderGroupCode}
                </Typography.Paragraph>
                <Tooltip
                  title='판매채널주문번호'
                  arrow={false}
                  placement='bottomLeft'
                >
                  <Typography.Paragraph className='m-0  text-description'>
                    {record.salesChannelOrderGroupCode &&
                      `(${record.salesChannelOrderGroupCode})`}
                  </Typography.Paragraph>
                </Tooltip>
              </>
            );
          }}
        />
        {showOrderStatus && (
          <Table.Column<OrderClaimsTableData>
            title='주문상태'
            width={160}
            dataIndex={'orderStatusLabel'}
          />
        )}

        {!hideClaimStatus && (
          <Table.Column<OrderClaimsTableData>
            title='클레임상태'
            width={160}
            dataIndex={'claimStatus'}
            render={(_, record) => {
              if (tableType === 'SUPPORT') {
                return <OrderSupportStatusCell order={record} />;
              }

              if (!record.activeClaim) {
                return <CreateClaimSelect order={record} />;
              }

              const claimType = record.activeClaim.claimType;
              const claimStatus = record.activeClaim.status;

              return (
                <UpdateClaimSelect
                  order={record}
                  renderUpdateExchangeReceipt={
                    claimType === 'EXCHANGE' &&
                    claimStatus === 'EXCHANGE_RECEIPT'
                  }
                  renderUpdateReturnReceipt={
                    claimType === 'RETURN' && claimStatus === 'RETURN_RECEIPT'
                  }
                />
              );
            }}
          />
        )}

        <Table.Column
          title='마켓명 (마켓ID)'
          width={240}
          dataIndex={'marketName'}
          className={'whitespace-pre-wrap'}
        />
        <Table.Column title='업체명' width={240} dataIndex={'vendorName'} />
        <Table.Column title='브랜드명' width={240} dataIndex={'brandName'} />
        <Table.Column
          title='상품명'
          width={240}
          dataIndex={'productGroupName'}
        />
        <Table.Column<OrderClaimsTableData>
          title='품목명'
          width={240}
          render={(_, record) => {
            if (!record.productName) record.salesChannelProductName ?? '-';

            const ProductName = () => (
              <span>
                {record.productName}
                {record.productUseType === 'SELECT' ? (
                  <span> ({record.itemNames})</span>
                ) : (
                  ''
                )}
              </span>
            );

            return (
              <Tooltip title={ProductName} placement='bottomLeft'>
                <div className={'line-clamp-3'}>
                  <ProductName />
                </div>
              </Tooltip>
            );
          }}
        />
        <Table.Column title='수량' width={120} dataIndex={'productQuantity'} />
        <Table.Column
          title='상품주문금액'
          width={120}
          dataIndex={'orderAmount'}
        />
        <Table.Column
          title='이벤트ID'
          width={160}
          dataIndex={'marketEventId'}
        />
        <Table.Column
          title='묶음배송코드'
          width={160}
          dataIndex={'bundleDeliveryCode'}
        />
        <Table.Column title='배송비' width={120} dataIndex={'deliveryFee'} />
        <Table.Column
          title='지역별 배송비'
          width={120}
          dataIndex={'regionDeliveryFee'}
        />
        <Table.Column
          title='추가할인금액'
          width={120}
          dataIndex={'extraDiscount'}
        />
        <Table.Column<OrderClaimsTableData>
          title='택배사 / 송장번호'
          width={240}
          render={(_, record) => {
            return (
              <div className='flex flex-col gap-1'>
                <div>{record.deliveryCompanyName}</div>
                <div>{record.trackingNumber || ''}</div>
              </div>
            );
          }}
        />
        <Table.Column<OrderClaimsTableData>
          title='수령자정보'
          width={240}
          render={(_, record) => {
            return (
              <div>
                <Typography.Paragraph className='m-0' strong>
                  {record.receiverName} / {record.receiverPhoneNumber}
                </Typography.Paragraph>

                <Typography.Paragraph className='m-0'>
                  {record.receiverAddress}
                  {record.receiverDetailAddress} (우)
                  {record.receiverZipCode}
                </Typography.Paragraph>
                <Tooltip
                  title={record.receiverDeliveryMemo}
                  arrow={false}
                  placement='bottomLeft'
                >
                  <Typography.Paragraph className='m-0 w-[208px]' ellipsis>
                    {record.receiverDeliveryMemo}
                  </Typography.Paragraph>
                </Tooltip>
              </div>
            );
          }}
        />
        {(claimStatus === 'EXCHANGE_RE_DELIVERY' ||
          claimStatus === 'EXCHANGE_COMPLETED') && (
          <Table.Column<OrderClaimsTableData>
            title='재출고 택배사 / 송장번호'
            width={240}
            render={(_, record) => {
              return (
                <div className='flex flex-col gap-1'>
                  <div>{record.redeliveryCompanyName}</div>
                  <div>{record.redeliveryTrackingNumber || ''}</div>
                </div>
              );
            }}
          />
        )}
        <Table.Column
          title='판매채널'
          width={200}
          dataIndex={'salesChannelLabel'}
        />
        <Table.Column title='주문자정보' width={200} dataIndex={'orderer'} />
        <Table.Column
          title='결제수단'
          width={160}
          dataIndex={'paymentMethod'}
        />
        <Table.Column
          title='적립금사용금액'
          width={120}
          dataIndex={'pointDiscount'}
        />
        <Table.Column title='주문일시' width={170} dataIndex={'orderedAt'} />
        <Table.Column title='결제일시' width={170} dataIndex={'paidAt'} />
        <Table.Column
          title='주문서변환일시'
          width={170}
          dataIndex={'orderConvertedAt'}
        />
        <Table.Column
          title='주문최종업데이트일시'
          width={170}
          dataIndex={'updatedAt'}
        />
      </Table>
      {openDetailModal && currentOrderId && (
        <OrderDetailModal
          id={currentOrderId}
          onClose={() => {
            setOpenDetailModal(false);
            setCurrentOrderId(undefined);
          }}
        />
      )}
    </>
  );
}

export default OrderClaimsTable;
