import { MoreOutlined } from '@ant-design/icons';
import { App, Button, Dropdown, Image, MenuProps, Typography } from 'antd';
import { round } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Link, generatePath } from 'react-router-dom';

import useAntdTheme from '../../../../common/hooks/useAntdTheme';
import { FALLBACK_IMAGE } from '../../../../common/utils/const';
import { formatPriceWithComma } from '../../../../common/utils/price';
import { ROUTES } from '../../../../routes/const';
import { EProductGroupStatus } from '../../../../schema/types';
import {
  PRODUCT_GROUP_MENU,
  PRODUCT_GROUP_MENU_DISABLED,
  PRODUCT_GROUP_STATUSES,
} from '../../const';
import {
  useDeleteProductGroupMutation,
  usePatchProductGroupMutation,
} from '../../graphql/productGroup.generated';
import { ProductGroupsDocument } from '../../graphql/productGroups.generated';
import useDuplicateProductGroup from '../../hooks/useDuplicateProductGroup';
import { ProductCardType } from '../../types';
import MarketListPopover from '../MarketListPopover';
import ProductMarketListModal from '../ProductMarketListModal';
import ProposalModal from '../Proposal/ProposalModal';

import Paragraph from './Paragraph';
import ProductCardStatusSelect from './StatusSelect';

type ProductCardProps = ProductCardType;

export default function ProductCard({
  name,
  category,
  id,
  manager,
  vendor,
  brand,
  primaryProduct,
  primaryMultimedia,
  status,
  markets,
  editable,
}: ProductCardProps) {
  const { message, modal } = App.useApp();
  const { colorBorderSecondary } = useAntdTheme();
  const { duplicateProductGroup } = useDuplicateProductGroup();

  const [proposalOpen, setProposalOpen] = useState(false);
  const [marketModalOpen, setMarketModalOpen] = useState(false);
  // const [modal, contextHolder] = Modal.useModal();
  const [mutate] = useDeleteProductGroupMutation();
  const [patchProductGroup] = usePatchProductGroupMutation();

  const referencedMarket = markets?.map((market) => ({
    name: market.name,
    startedAt: market.startedAt,
    endedAt: market.endedAt,
  }));

  const productGroupMenu = useMemo(() => {
    if (!markets?.length) return PRODUCT_GROUP_MENU;

    const menuCopy = PRODUCT_GROUP_MENU.slice();

    return menuCopy.map((menu) => {
      if (menu.key === 'DELETE') {
        return {
          ...menu,
          className: '',
          disabled: true,
        };
      }

      return menu;
    });
  }, [markets]);

  const deleteProductGroup = async () => {
    await mutate({
      variables: {
        adminDeleteProductGroupId: id,
      },
      refetchQueries: [ProductGroupsDocument],
      onCompleted() {
        message.success('삭제되었습니다.');
      },
      onError() {
        message.error('삭제를 실패하였습니다.');
      },
    });
  };

  const confirmModal = () => {
    modal.confirm({
      title: '상품삭제',
      content: (
        <p className='break-keep'>
          상품삭제 시, 상품 정보를 되돌릴 수 없습니다.
          <br />
          삭제를 진행하시겠습니까?
        </p>
      ),
      onOk: deleteProductGroup,
      cancelText: '아니오',
      okText: '예',
      maskClosable: true,
    });
  };

  const handleChangeStatus = async (status: EProductGroupStatus) => {
    await patchProductGroup({
      variables: {
        input: {
          id,
          status,
        },
      },
      refetchQueries: [ProductGroupsDocument],
      onCompleted: () => {
        message.success('상태가 업데이트 되었어요');
      },
      onError() {
        message.error('상태가 업데이트를 실패하였습니다.');
      },
    });
  };

  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'PROPOSAL') setProposalOpen(true);
    if (key === 'DELETE') confirmModal();
    if (key === 'COPY') duplicateProductGroup(id);
    if (key === 'MARKET') setMarketModalOpen(true);
  };

  const { url } = primaryMultimedia;
  const { categoryName, upperCategoryName, uppermostCategoryName } = category;
  const categoryText = `${uppermostCategoryName} > ${upperCategoryName} > ${categoryName}`;
  const jointPurchasePrice = formatPriceWithComma(
    primaryProduct.jointPurchasePrice
  );
  const businessSellerMargin = formatPriceWithComma(
    primaryProduct.businessSellerMargin
  );
  const businessSellerMarginRate = round(
    primaryProduct.businessSellerMarginRate * 100,
    3
  );

  return (
    <>
      <Link
        to={generatePath(ROUTES.PRODUCT_DETAIL.path, { productGroupId: id })}
      >
        <div
          className='rounded border border-solid'
          style={{ borderColor: colorBorderSecondary }}
        >
          <MarketListPopover markets={referencedMarket ?? []}>
            <div className='flex flex-col gap-2 py-4'>
              <div className='px-4'>
                <Typography.Title level={5} className='mb-0' ellipsis>
                  {name}
                </Typography.Title>
                <Paragraph>{categoryText}</Paragraph>
              </div>
              <div
                className='flex cursor-default items-center justify-between px-4'
                onClick={(e) => e.preventDefault()}
              >
                <ProductCardStatusSelect
                  options={PRODUCT_GROUP_STATUSES}
                  value={status}
                  onChange={handleChangeStatus}
                  editable={editable}
                />
                <Dropdown
                  menu={{
                    items: editable
                      ? productGroupMenu
                      : PRODUCT_GROUP_MENU_DISABLED,
                    onClick: handleMenuClick,
                    className: 'min-w-[114px]',
                  }}
                  trigger={['click']}
                >
                  <Button icon={<MoreOutlined />} size='small' type='text' />
                </Dropdown>
              </div>
            </div>

            <Image
              src={url}
              width={'100%'}
              height={'100%'}
              fallback={FALLBACK_IMAGE}
              preview={false}
              className='aspect-[27/13] object-cover'
            />

            <div className='p-4'>
              <div className='flex justify-between'>
                <Typography.Text className='font-semibold'>
                  공동구매가
                </Typography.Text>
                <Typography.Text className='font-semibold'>
                  {jointPurchasePrice}
                </Typography.Text>
              </div>

              <div className='flex justify-between'>
                <Paragraph>셀러마진</Paragraph>
                <Paragraph>
                  {businessSellerMargin}
                  <span className='ml-1'>({businessSellerMarginRate}%)</span>
                </Paragraph>
              </div>
              <Paragraph>
                {vendor.name} | {brand.name}
              </Paragraph>
              <Paragraph>{manager.name}</Paragraph>
            </div>
          </MarketListPopover>
        </div>
      </Link>
      {proposalOpen && (
        <ProposalModal
          productGroupId={id}
          onClose={() => {
            setProposalOpen(false);
          }}
        />
      )}
      {marketModalOpen && (
        <ProductMarketListModal
          onClose={() => setMarketModalOpen(false)}
          productGroupId={id}
        />
      )}
    </>
  );
}
