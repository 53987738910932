import {
  Button,
  Divider,
  ButtonProps,
  DividerProps,
  Tag,
  TagProps,
  Typography,
  App,
} from 'antd';

type DividerWithButtonProps = DividerProps & {
  title: string;
  buttonProps?: ButtonProps;
  buttonLabel: string;
  tagProps?: TagProps & {
    text: string;
  };
};

function DividerWithButton({
  title,
  buttonProps,
  buttonLabel,
  tagProps,
  ...props
}: DividerWithButtonProps) {
  const { message } = App.useApp();

  return (
    <div className={'flex w-full items-center'}>
      <div className={'flex-1'}>
        <Divider orientation='left' className={'m-0'} {...props}>
          {title}
        </Divider>
      </div>
      <div className={'flex-0 flex items-center'}>
        {tagProps && (
          <Typography.Text
            copyable={{
              text: tagProps.text,
              icon: [<Tag {...tagProps} />, <Tag {...tagProps} />],
              onCopy() {
                message.success('클립보드에 복사를 완료하였습니다.');
              },
              tooltips: false,
            }}
          ></Typography.Text>
        )}
        <Button
          type={'text'}
          htmlType={'button'}
          className={'flex-0 basis-[92px]'}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}

export default DividerWithButton;
