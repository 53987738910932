import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageLoader from '../../../common/components/PageLoader';
import { authorization } from '../helper';

function AuthCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = searchParams.get('access-token');
    if (accessToken) {
      authorization.accessToken = accessToken;
      navigate('/', { replace: true });
    }
  }, [searchParams, navigate]);

  return <PageLoader />;
}

export default AuthCallback;
