import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useEffect, useMemo } from 'react';

import {
  UpperCategoriesQueryVariables,
  useUpperCategoriesLazyQuery,
} from '../../graphql/productGroup.generated';

type UpperCategorySelectProps = SelectProps & {
  uppermostCategoryId?: number | null;
};

const UpperCategorySelect = forwardRef<BaseSelectRef, UpperCategorySelectProps>(
  function ({ uppermostCategoryId, disabled, ...props }, ref) {
    const TAKE = 9999; // 모든 카테고리를 가져오기 위해 임의로 큰 값을 설정함

    const [fetchUpperCategory, { data }] = useUpperCategoriesLazyQuery({
      variables: {
        pagination: {
          skip: 0,
          take: TAKE,
        },
        filter: {
          uppermostCategoryId,
        },
      } as UpperCategoriesQueryVariables,
    });

    const categories = useMemo(
      () => data?.adminUpperCategories.edges,
      [data?.adminUpperCategories.edges]
    );

    const options = useMemo(
      () =>
        categories?.map(({ node }) => ({
          value: node.id,
          label: node.name,
        })),
      [categories]
    );

    useEffect(() => {
      if (uppermostCategoryId) {
        fetchUpperCategory();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uppermostCategoryId]);

    return (
      <Select
        ref={ref}
        options={options}
        disabled={disabled || !uppermostCategoryId}
        {...props}
        showSearch
        filterOption={(input, option) =>
          ((option?.label as string) ?? '').includes(input)
        }
      />
    );
  }
);

export default UpperCategorySelect;
