import {
  SolutionOutlined,
  CustomerServiceOutlined,
  FileDoneOutlined,
  TeamOutlined,
  DollarOutlined,
  FormOutlined,
  ShopOutlined,
  ShoppingOutlined,
  UserOutlined,
  BoxPlotOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { ROUTES } from '../../routes/const';

type MenuType = {
  label: React.ReactNode | string;
  key: string;
  icon: React.ReactNode;
  children?: {
    label: React.ReactNode;
    key: string;
  }[];
}[];

/** 배포된 항목만 메뉴에 노출시키기 위해 주석처리합니다. */
export const MENU: MenuType = [
  {
    label: (
      <Link to={ROUTES.MARKETS.path} reloadDocument>
        {ROUTES.MARKETS.title}
      </Link>
    ),
    key: ROUTES.MARKETS.path,
    icon: <ShopOutlined />,
  },
  {
    label: <Link to={ROUTES.PRODUCTS.path}>{ROUTES.PRODUCTS.title}</Link>,
    key: ROUTES.PRODUCTS.path,
    icon: <ShoppingOutlined />,
  },
  {
    label: (
      <Link to={ROUTES.SELLERS.path} reloadDocument>
        {ROUTES.SELLERS.title}
      </Link>
    ),
    key: ROUTES.SELLERS.path,
    icon: <TeamOutlined />,
  },
  {
    label: (
      <Link to={ROUTES.VENDORS.path} reloadDocument>
        {ROUTES.VENDORS.title}
      </Link>
    ),
    key: ROUTES.VENDORS.path,
    icon: <SolutionOutlined />,
  },
  {
    label: ROUTES.PURCHASE_ORDER.title,
    key: ROUTES.PURCHASE_ORDER.path,
    icon: <FileDoneOutlined />,
    children: [
      // {
      //   label: (
      //     <Link to={ROUTES.ORDERING_LIST.path}>
      //       {ROUTES.ORDERING_LIST.title}
      //     </Link>
      //   ),
      //   key: ROUTES.ORDERING_LIST.path,
      // },
      {
        label: (
          <Link to={ROUTES.ORDERING_TRANSFORM.path}>
            {ROUTES.ORDERING_TRANSFORM.title}
          </Link>
        ),
        key: ROUTES.ORDERING_TRANSFORM.path,
      },
    ],
  },
  {
    label: (
      <Link to={ROUTES.PROMOTION_RELEASES.path} reloadDocument>
        {ROUTES.PROMOTION_RELEASES.title}
      </Link>
    ),
    key: ROUTES.PROMOTION_RELEASES.path,
    icon: <BoxPlotOutlined />,
  },
  {
    label: (
      <Link to={ROUTES.ORDERS.path} reloadDocument>
        {ROUTES.ORDERS.title}
      </Link>
    ),
    key: ROUTES.ORDERS.path,
    icon: <FormOutlined />,
  },
  {
    label: ROUTES.CS.title,
    key: ROUTES.CS.path,
    icon: <CustomerServiceOutlined />,
    children: [
      {
        label: (
          <Link to={ROUTES.CS_ORDER_SUPPORTS.path}>
            {ROUTES.CS_ORDER_SUPPORTS.title}
          </Link>
        ),
        key: ROUTES.CS_ORDER_SUPPORTS.path,
      },
      {
        label: (
          <Link to={ROUTES.CS_RETURNS.path}>{ROUTES.CS_RETURNS.title}</Link>
        ),
        key: ROUTES.CS_RETURNS.path,
      },
      {
        label: (
          <Link to={ROUTES.CS_EXCHANGES.path}>{ROUTES.CS_EXCHANGES.title}</Link>
        ),
        key: ROUTES.CS_EXCHANGES.path,
      },
      {
        label: (
          <Link to={ROUTES.CS_CANCELS.path}>{ROUTES.CS_CANCELS.title}</Link>
        ),
        key: ROUTES.CS_CANCELS.path,
      },
    ],
  },
  {
    label: ROUTES.SETTLEMENTS.title,
    key: ROUTES.SETTLEMENTS.path,
    icon: <DollarOutlined />,
    children: [
      {
        label: (
          <Link to={ROUTES.SETTLEMENTS_DEPOSIT.path}>
            {ROUTES.SETTLEMENTS_DEPOSIT.title}
          </Link>
        ),
        key: ROUTES.SETTLEMENTS_DEPOSIT.path,
      },
      {
        label: (
          <Link
            to={{
              pathname: ROUTES.SETTLEMENTS_LIST.path,
              search: `?periodType=SOLD_AT&startedAt=${dayjs()
                .add(-1, 'month')
                .startOf('day')
                .toISOString()}&endedAt=${dayjs().endOf('day').toISOString()}`,
            }}
          >
            {ROUTES.SETTLEMENTS_LIST.title}
          </Link>
        ),
        key: ROUTES.SETTLEMENTS_LIST.path,
      },
      {
        label: (
          <Link to={ROUTES.SETTLEMENTS_SHEETS.path}>
            {ROUTES.SETTLEMENTS_SHEETS.title}
          </Link>
        ),
        key: ROUTES.SETTLEMENTS_SHEETS.path,
      },
    ],
  },
  {
    label: <Link to={ROUTES.OPERATORS.path}>{ROUTES.OPERATORS.title}</Link>,
    key: ROUTES.OPERATORS.path,
    icon: <UserOutlined />,
  },
];
