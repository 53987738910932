import { RcFile, UploadFile } from 'antd/es/upload';

import { EUploadResourcesPurpose } from '../../schema/types';

import { useFileUpload } from './useFileUpload';

type UseFileDndUploaderParams = {
  purpose: EUploadResourcesPurpose;
};

type UploadParams = {
  fileList: UploadFile[];
  endUpload?: (urls: string[], errorsFiles?: UploadFile[]) => void;
};

export default function useFileDndUploader({
  purpose,
}: UseFileDndUploaderParams) {
  const { upload: fileUpload, fetchUploadResources } = useFileUpload({
    purpose,
  });

  const upload = async ({ fileList, endUpload }: UploadParams) => {
    if (fileList.length === 0) return;

    const results = await Promise.allSettled(
      fileList.map(async (file) => {
        const resource = await fetchUploadResources(
          file.originFileObj as RcFile
        );

        return await fileUpload(resource, file.originFileObj as RcFile);
      })
    );

    const successUrls: string[] = [];
    const errorFiles: UploadFile[] = [];

    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        successUrls.push(result.value);
      }
      if (result.status === 'rejected') {
        const errorFile = Object.assign({}, fileList[index], {
          status: 'error',
        });
        errorFiles.push(errorFile);
      }
    });

    endUpload?.(successUrls, errorFiles);
  };

  return {
    upload,
  };
}
