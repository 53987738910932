import { App, Descriptions, Tooltip, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import Label from '../../../common/components/Fields/Label';
import SelectField from '../../../common/components/Fields/SelectField';
import TextField from '../../../common/components/Fields/TextField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { SELLING_CHANNELS } from '../const';
import { buildSettlementInfoLabel } from '../helper';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../types';

import SellersSelectField, { SellerOption } from './SellersSelectField';

function SellerInfoSelectField() {
  const { hasPermission } = usePermissions('MARKET_EDIT');
  const { message } = App.useApp();
  const { control, setValue, watch, resetField } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();

  const [sellerId, sellerName] = watch(['sellerId', 'sellerName']);
  const events = watch('events') || [];
  const hasEvents = !!events.length;
  const settlementInfos = watch('settlementInfos') || [];

  const sellerEditable = useMemo(() => {
    return events.every((event) => event.editable && event.deletable);
  }, [events]);

  const handleSellerChange = useCallback(
    (_: any, option: DefaultOptionType | DefaultOptionType[]) => {
      if (!Array.isArray(option)) {
        const sellerOption = option.data as SellerOption;
        const defaultOption = sellerOption.settlementInfos.find(
          (info) => info.isDefault
        )?.id;

        setValue('sellerName', sellerOption.name);
        setValue('sellerKemiId', sellerOption.kemiUserId);

        if (defaultOption) {
          setValue('sellerSettlementInfoId', defaultOption, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }

        if (hasEvents) {
          message.warning('변경사항이 있어 이벤트가 모두 삭제되었습니다.');
          setValue('events', [], {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        }

        setValue(
          'settlementInfos',
          sellerOption.settlementInfos.map((info) => ({
            id: info.id,
            type: info.type,
            name:
              (info.type === 'FREELANCER'
                ? info.sellerName
                : info.companyName) || '',
            bank: info.bank || null,
            accountHolderName: info.accountHolderName || '',
            accountNumber: info.accountNumber || '',
            isDefault: info.isDefault,
          }))
        );
      }
    },
    [setValue, hasEvents, message]
  );

  const settlementInfoOptions = useMemo(() => {
    return settlementInfos.map((info) => {
      const { isDefault, label } = buildSettlementInfoLabel(info);

      return {
        label: hasPermission ? (
          <Tooltip title={`${label} ${isDefault}`}>
            <Typography.Text
              className={'w-full'}
              ellipsis={{
                suffix: isDefault,
              }}
            >
              {label}
            </Typography.Text>
          </Tooltip>
        ) : (
          label
        ),
        value: info.id,
        data: info,
      };
    });
  }, [hasPermission, settlementInfos]);

  return (
    <div>
      <SellersSelectField
        required={hasPermission}
        disabled={!hasPermission || !sellerEditable}
        className={'w-marketCreateInputWidth @lg:w-updateFormWidth'}
        label={'셀러명'}
        name={'sellerId'}
        control={control}
        onChange={handleSellerChange}
        initialOption={
          sellerId && sellerName
            ? { value: sellerId, label: sellerName }
            : undefined
        }
      />
      <Descriptions
        bordered
        className={'mt-4 [&_table]:table-fixed'}
        column={1}
        labelStyle={{ width: 200 }}
        contentStyle={{ padding: '8px 24px', width: 'fit-content' }}
      >
        <Descriptions.Item label={'정산유형'}>
          {!hasPermission && (
            <span>
              {
                settlementInfoOptions.find(
                  (option) => option.value === watch('sellerSettlementInfoId')
                )?.label
              }
            </span>
          )}
          {hasPermission && (
            <div className={'w-[216px] @lg:w-[248px]'}>
              <SelectField
                disabled={!hasPermission}
                name={'sellerSettlementInfoId'}
                control={control}
                placeholder={'정산유형 선택'}
                options={settlementInfoOptions}
                onChange={(_, option) => {
                  if (!Array.isArray(option)) {
                    const data = option.data;

                    setValue('sellerSettlementInfoType', data.type);

                    if (hasEvents) {
                      events.forEach((event, eventIndex) => {
                        if (!event.editable) {
                          return;
                        }

                        event.products.forEach((product, productIndex) => {
                          resetField(
                            `events.${eventIndex}.products.${productIndex}`,
                            {
                              defaultValue: {
                                ...product,
                                // 이벤트 금액 초기화
                                inhouseBuyPrice: null,
                                isPrepayment: null,
                                isSellerBearing: false,
                                isWiredBearing: false,
                                sellerBearingCost: null,
                                wiredBearingCost: null,
                              },
                            }
                          );
                        });
                      });

                      message.warning(
                        '변경사항이 있어 이벤트 금액이 초기화되었습니다.'
                      );
                    }
                  }
                }}
              />
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Label required={hasPermission}>판매채널</Label>}
        >
          {!hasPermission && (
            <span>
              {
                SELLING_CHANNELS.find(
                  (option) => option.value === watch('sellingChannel')
                )?.label
              }
            </span>
          )}
          <div className={'flex gap-2'}>
            {hasPermission && (
              <div className={'w-[216px] @lg:w-[248px]'}>
                <SelectField
                  disabled={!hasPermission}
                  name={'sellingChannel'}
                  control={control}
                  placeholder={'판매채널 선택'}
                  options={SELLING_CHANNELS}
                />
              </div>
            )}
            {hasPermission && watch('sellingChannel') === 'ETC' && (
              <div className={'w-[216px] @lg:w-[248px]'}>
                <TextField
                  control={control}
                  name={'etcSellingChannel'}
                  placeholder={'판매채널명 입력*'}
                />
              </div>
            )}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={'케미회원아이디'}>
          <div className={'w-[216px] @lg:w-[248px]'}>
            {watch('sellerKemiId') || '-'}
          </div>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default SellerInfoSelectField;
