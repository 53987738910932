import { App } from 'antd';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import SelectField from '../../../common/components/Fields/SelectField';
import TextField from '../../../common/components/Fields/TextField';
import TextNumberField from '../../../common/components/Fields/TextNumberField';
import { formSetValueOption } from '../../../common/utils/const';
import useSellerReceiver, {
  PromotionSellerAddressItem,
  PromotionSellerItem,
  PromotionSellerSettlementItem,
} from '../hooks/useSellerReceiver';
import { PromotionFormValues, PromotionEventFormValues } from '../types';

import Dropdown from './Dropdown';
import SellerSelectField from './SellerSelectField';

function SellerReceiver() {
  const { message } = App.useApp();
  const { control, watch, setValue, resetField, getValues } = useFormContext<
    PromotionFormValues | PromotionEventFormValues
  >();
  const [sellerId, sellerSettlementInfoId] = watch([
    'sellerId',
    'sellerSettlementInfoId',
  ]);

  const sellerCallback = useCallback(
    (selectedSeller: PromotionSellerItem) => {
      setValue('sellerId', selectedSeller.id);
      setValue(
        'promotionReleases.0.receiverPhoneNumber',
        selectedSeller.phoneNumber,
        formSetValueOption
      );
    },
    [setValue]
  );

  const settlementCallback = useCallback(
    (selectedSettlement: PromotionSellerSettlementItem) => {
      setValue('sellerSettlementInfoId', selectedSettlement.id);
      setValue('settlementType', selectedSettlement.type);

      if (selectedSettlement.type === 'FREELANCER') {
        setValue(
          'promotionReleases.0.receiverName',
          selectedSettlement.sellerName || '',
          formSetValueOption
        );
      }
      const promotionProducts = getValues('promotionProducts');
      if (promotionProducts?.length) {
        resetField('promotionProducts', {
          defaultValue: promotionProducts.map((product) => ({
            ...product,
            isFreeProvided: true,
            inHouseBuyPrice: undefined,
            isSellerBearing: undefined,
            sellerBearingCost: undefined,
            isPrepayment: undefined,
            isWiredBearing: undefined,
            wiredBearingCost: undefined,
          })),
        });
        void message.info('변경사항이 있어 품목이 업체무상으로 초기화됩니다.');
      }
    },
    [setValue, message, getValues, resetField]
  );
  const addressCallback = useCallback(
    (selectedAddress: PromotionSellerAddressItem) => {
      setValue('promotionReleases.0.zipCode', selectedAddress.zipCode);
      setValue('promotionReleases.0.receiverAddress', selectedAddress.address);
      setValue(
        'promotionReleases.0.receiverDetailAddress',
        selectedAddress.detailAddress
      );
    },
    [setValue]
  );

  const {
    addressOptions,
    settlementOptions,
    handleAddressSelect,
    handleSettlementSelect,
    handleSellerSelect,
    sellerInfo,
  } = useSellerReceiver({
    sellerId,
    addressCallback,
    settlementCallback,
    sellerCallback,
  });

  useEffect(() => {
    if (sellerInfo) {
      // seller 를 변경시 정산유형, 수령지 정보를 default 값으로 수정해준다.
      const defaultSettlementInfo = sellerInfo.adminSeller.settlementInfos.find(
        ({ isDefault }) => isDefault
      );
      const defaultAddressInfo = sellerInfo.adminSeller.addresses.find(
        ({ isDefault }) => isDefault
      );
      if (defaultSettlementInfo) {
        setValue(
          'sellerSettlementInfoId',
          defaultSettlementInfo.id,
          formSetValueOption
        );
        setValue('settlementType', defaultSettlementInfo.type);
        if (defaultSettlementInfo.type === 'FREELANCER') {
          setValue(
            'promotionReleases.0.receiverName',
            defaultSettlementInfo.sellerName || ''
          );
        }
      }
      setValue(
        'promotionReleases.0.zipCode',
        defaultAddressInfo?.zipCode || '',
        formSetValueOption
      );
      setValue(
        'promotionReleases.0.receiverAddress',
        defaultAddressInfo?.address || '',
        formSetValueOption
      );
      setValue(
        'promotionReleases.0.receiverDetailAddress',
        defaultAddressInfo?.detailAddress || '',
        formSetValueOption
      );
      // seller 를 변경시 상세정보를 초기화한다.
      resetField('marketId');
      resetField('productGroupId');
      resetField('promotionProducts');
      resetField('isDeliveryFee');
      resetField('deliveryFeeBearer');
      resetField('deliveryFee');
    }
  }, [sellerInfo, setValue, resetField]);

  return (
    <div className='mt-4 flex w-[472px] flex-col gap-6'>
      <FieldWithLabel label='셀러명' required>
        <SellerSelectField
          control={control}
          name='sellerId'
          onChange={handleSellerSelect}
          value={sellerId}
          placeholder='셀러선택'
        />
      </FieldWithLabel>
      <FieldWithLabel label='정산유형' required>
        <SelectField
          control={control}
          name='sellerSettlementInfoId'
          options={settlementOptions}
          onChange={handleSettlementSelect}
          value={sellerSettlementInfoId}
          placeholder='정산유형 선택'
        />
      </FieldWithLabel>
      <FieldWithLabel label='수령자명' required>
        <TextField
          control={control}
          name='promotionReleases.0.receiverName'
          maxLength={30}
          placeholder='수령자명'
        />
      </FieldWithLabel>
      <FieldWithLabel label='휴대폰번호' required>
        <TextNumberField
          control={control}
          name='promotionReleases.0.receiverPhoneNumber'
          format='###-####-####'
          maxLength={30}
          placeholder='휴대폰번호'
        />
      </FieldWithLabel>
      <FieldWithLabel label='샘플수령지' required>
        <div className='flex flex-col gap-2'>
          <TextNumberField
            control={control}
            name='promotionReleases.0.zipCode'
            maxLength={20}
            placeholder='우편번호'
          />
          <TextField
            control={control}
            name='promotionReleases.0.receiverAddress'
            maxLength={100}
            placeholder='주소'
          />
          <TextField
            control={control}
            name='promotionReleases.0.receiverDetailAddress'
            maxLength={100}
            placeholder='상세주소'
          />
          <Dropdown
            label='다른 샘플수령지'
            menu={{
              style: { minWidth: 150, maxWidth: 360 },
              items: addressOptions,
              onClick: (info) => {
                const selectedId = Number(info.key);
                handleAddressSelect(selectedId);
              },
            }}
          />
        </div>
      </FieldWithLabel>
      <FieldWithLabel label='배송메시지'>
        <TextField
          control={control}
          name='promotionReleases.0.deliveryMessage'
          maxLength={250}
          placeholder='배송메시지'
        />
      </FieldWithLabel>
    </div>
  );
}

export default SellerReceiver;
