import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import ProductGroupSelect from '../../../../common/components/Fields/ProductGroupSelect';
import { useAdminProductGroupInSettlementListLazyQuery } from '../../graphql/productGroup.generated';

export type SelectFieldProps = SelectProps<number> & {
  initialLoad?: boolean;
};

const SettlementProductGroupSelect = forwardRef<
  BaseSelectRef,
  SelectFieldProps
>(function ({ initialLoad, ...props }, ref) {
  const [initialOption, setInitialOption] = useState<
    null | undefined | { value: number; label: string }
  >(null);

  const [fetch] = useAdminProductGroupInSettlementListLazyQuery();
  const fetchProductGroup = useCallback(async () => {
    if (!props.value) {
      setInitialOption(undefined);
      return;
    }

    const { data } = await fetch({
      variables: {
        adminProductGroupId: props.value,
      },
    });

    if (data?.adminProductGroup) {
      setInitialOption({
        value: data.adminProductGroup.id,
        label: data.adminProductGroup.name,
      });
    }
  }, [fetch, props.value]);

  useEffect(() => {
    fetchProductGroup();
  }, []);

  if (initialOption === null) return <Select className={props.className} />;

  return (
    <ProductGroupSelect
      ref={ref}
      initialLoad={initialLoad}
      className={'w-full'}
      initialOption={initialOption}
      {...props}
    />
  );
});

export default SettlementProductGroupSelect;
