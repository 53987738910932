import { ApolloCache } from '@apollo/client';
import { Modifier, Modifiers } from '@apollo/client/cache';
import { GraphQLErrors } from '@apollo/client/errors';

import { ERROR } from '../constants/error';

export const removeCache = (
  cache: ApolloCache<unknown>,
  { id, __typename }: { id: number | string; __typename: string }
) => {
  const cacheId = cache.identify({ id, __typename });
  cache.evict({ id: cacheId });
};

export const extractBadRequestError = (errors?: GraphQLErrors) => {
  if (!errors) {
    return null;
  }

  return errors.find(({ extensions }) => extensions.code === ERROR.BAD_REQUEST);
};

export const hasDetailErrorCode = (errors: GraphQLErrors, code: string) => {
  return errors.find(({ extensions }) => extensions.detailCode === code);
};

export const deleteCacheFields = (
  cache: ApolloCache<any>,
  fields: string[]
) => {
  const modifiedFields = fields.reduce(
    (acc, field) => {
      return {
        ...acc,
        [field]: (_, { DELETE }) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return DELETE;
        },
      };
    },
    {} as Modifiers | Modifier<any>
  );

  cache.modify({
    fields: modifiedFields,
  });
};
