import { QuestionCircleOutlined } from '@ant-design/icons';
import { App, Button, Table, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Link, generatePath } from 'react-router-dom';

import Modal from '../../../../common/components/Modal';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { ROUTES } from '../../../../routes/const';
import {
  ESellerSettlementType,
  SettlementSheetInputGraphqlType,
} from '../../../../schema/types';
import {
  SELLING_CHANNEL_LABEL,
  SETTLEMENT_MARKET_STATUS_LABEL,
} from '../../const';
import { useAdminMarketsForScheduledSettlementQuery } from '../../graphql/markets.generated';
import useCreateSettlementSheet from '../../hooks/useCreateSettlementSheet';
import { SettlementScheduledMarketModalTableDataType } from '../../types';

import CreateSettlementModal from './CreateSettlementSheetModal';

type SettlementScheduledMarketModalModalProps = {
  onClose: () => void;
};

const DEFAULT_PAGE_SIZE = 10;

function SettlementScheduledMarketModal({
  onClose,
}: SettlementScheduledMarketModalModalProps) {
  const { hasPermission } = usePermissions('SETTLEMENT_EDIT');
  const { modal } = App.useApp();
  const [createSettlementModalOpen, setCreateSettlementModalOpen] =
    useState(false);

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);

  const { data, loading } = useAdminMarketsForScheduledSettlementQuery({
    variables: {
      pagination: {
        skip: currentPage * pageSize,
        take: pageSize,
      },
    },
  });

  const { createSettlementSheet } = useCreateSettlementSheet();

  const totalCount = data?.adminMarketsForScheduledSettlement.totalCount || 0;
  const dataSource = data?.adminMarketsForScheduledSettlement.edges.map(
    ({ node }) => {
      return {
        key: node.id,
        name: node.name,
        id: node.id,
        managerName: node.manager?.name,
        status: node.status,
        startedAt: node.startedAt,
        endedAt: node.endedAt,
        expectedSettlementAt: node.expectedSettlementAt,
        sellingChannel: node.sellingChannel,
        comment: node.comment,
        sellerSettlementType: node.sellerSettlementInfo?.type,
      };
    }
  );

  const handlePageChange = (page: number, size: number) => {
    if (size !== pageSize) {
      setPageSize(size);
      setCurrentPage(0);
    } else {
      setCurrentPage(page - 1);
    }
  };

  const handleCreateSettlementSheet = async ({
    marketId,
    sellerSettlementType,
  }: {
    marketId: number;
    sellerSettlementType?: ESellerSettlementType;
  }) => {
    const input: SettlementSheetInputGraphqlType = {
      marketId,
      type: 'MARKET',
      sellerSettlementType: sellerSettlementType || 'FREELANCER',
    };

    await createSettlementSheet(input);
  };

  return (
    <>
      <Modal
        onCancel={onClose}
        title='정산예정 마켓'
        width={1000}
        footer={null}
      >
        <div className='py-4'>
          <Table
            dataSource={dataSource}
            scroll={{ x: 1450 }}
            loading={loading}
            pagination={{
              showSizeChanger: true,
              showTotal: (total) => `총 ${total}건`,
              pageSize,
              total: totalCount,
              pageSizeOptions: ['10', '20', '50', '100'],
              onChange: handlePageChange,
            }}
          >
            <Table.Column<SettlementScheduledMarketModalTableDataType>
              title='마켓명'
              width={320}
              render={(_, record) => {
                return (
                  <div className='flex items-center justify-center gap-4'>
                    <Link
                      to={generatePath(ROUTES.MARKET_DETAIL.path, {
                        marketId: record.id,
                      })}
                      target='_blank'
                    >
                      <Typography.Text
                        className='w-[190px] text-primary'
                        ellipsis
                      >
                        {record.name}
                      </Typography.Text>
                    </Link>
                    <Button
                      size='small'
                      href={`${ROUTES.SETTLEMENTS_LIST.path}?marketId=${record.id}`}
                    >
                      상세보기
                    </Button>
                  </div>
                );
              }}
            />
            <Table.Column<SettlementScheduledMarketModalTableDataType>
              title='담당자'
              width={120}
              render={(_, record) => record.managerName || '-'}
            />
            <Table.Column<SettlementScheduledMarketModalTableDataType>
              title='마켓상태'
              width={120}
              dataIndex={'status'}
              render={(_, record) =>
                SETTLEMENT_MARKET_STATUS_LABEL[record.status]
              }
            />
            <Table.Column<SettlementScheduledMarketModalTableDataType>
              title='마켓기간'
              width={200}
              render={(_, { startedAt, endedAt }) => {
                let date = `${dayjs(startedAt).format('YYYY.MM.DD')}`;
                if (endedAt) {
                  date += ` - ${dayjs(endedAt).format('YYYY.MM.DD')}`;
                }

                return <div className='flex flex-col'>{date}</div>;
              }}
            />
            <Table.Column<SettlementScheduledMarketModalTableDataType>
              title='정산예정일'
              width={140}
              render={(_, record) => {
                return dayjs(record.expectedSettlementAt).format('YYYY.MM.DD');
              }}
            />
            <Table.Column<SettlementScheduledMarketModalTableDataType>
              title={
                <>
                  <span className='mr-1'>정산서 생성</span>
                  <Tooltip
                    title={
                      '하나의 마켓에 여러 개의 정산서를 생성하시려면, 검색조건으로 조회하여 직접 정산서를 생성해주세요.'
                    }
                    placement='bottomLeft'
                    arrow={{
                      pointAtCenter: true,
                    }}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </>
              }
              width={140}
              render={(_, record) => (
                <Button
                  type='text'
                  className={hasPermission ? 'text-primary' : ''}
                  disabled={!hasPermission || !!record.isExistSettlementSheet}
                  onClick={() =>
                    modal.confirm({
                      title: '정산서 생성',
                      content: '정산서를 생성하시겠습니까?',
                      onOk: async () => {
                        await handleCreateSettlementSheet({
                          marketId: record.id,
                          sellerSettlementType: record.sellerSettlementType,
                        });
                      },
                      okText: '생성',
                    })
                  }
                >
                  정산서 생성
                </Button>
              )}
            />
            <Table.Column<SettlementScheduledMarketModalTableDataType>
              title='판매채널'
              width={170}
              dataIndex={'sellingChannel'}
              render={(_, { sellingChannel }) =>
                sellingChannel ? SELLING_CHANNEL_LABEL[sellingChannel] : '-'
              }
            />
            <Table.Column<SettlementScheduledMarketModalTableDataType>
              title='비고'
              width={240}
              render={(_, record) => {
                return (
                  <Typography.Text ellipsis className='w-52'>
                    {record.comment || '-'}
                  </Typography.Text>
                );
              }}
            />
          </Table>
        </div>
      </Modal>
      {createSettlementModalOpen && (
        <CreateSettlementModal
          selectedSettlements={[]} // 정책에 따라 수정될 수 있어 일단 빈 배열로 넘김
          onClose={() => setCreateSettlementModalOpen(false)}
          type={'MARKET'}
        />
      )}
    </>
  );
}

export default SettlementScheduledMarketModal;
