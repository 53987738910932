import { DatePicker, Descriptions, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { useCallback } from 'react';

import ManagerSelect from '../../../../common/components/Fields/ManagerSelect';
import useRouteSearchParams from '../../../../common/hooks/useRouteSearchParams';
import { EOrderGroupDepositStatus } from '../../../../schema/types';
import { DEPOSIT_STATUS_OPTIONS } from '../../const';
import { DepositListSearchParams } from '../../types';

function SearchForm() {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<DepositListSearchParams>();
  const creatorOperatorIds = searchParams.creatorOperatorIds?.map((id) =>
    Number(id)
  );

  const handleSearchKeyword = debounce(
    (ev: React.ChangeEvent<HTMLInputElement>) =>
      setSearchParams({
        searchKeyword: ev.target.value,
        currentPage: undefined,
        summaryStatus: undefined,
      }),
    200
  );

  const handleStartedAt = useCallback(
    (_: dayjs.Dayjs | null, startedAt: string) =>
      setSearchParams({
        startedAt,
        currentPage: undefined,
        summaryStatus: undefined,
      }),
    [setSearchParams]
  );
  const handleEndedAt = useCallback(
    (_: dayjs.Dayjs | null, endedAt: string) =>
      setSearchParams({
        endedAt,
        currentPage: undefined,
        summaryStatus: undefined,
      }),
    [setSearchParams]
  );

  const handleCreatorOperatorIds = useCallback(
    (creatorOperatorIds: number[]) =>
      setSearchParams({
        creatorOperatorIds,
        currentPage: undefined,
        summaryStatus: undefined,
      }),
    [setSearchParams]
  );
  const handleDepositorName = debounce(
    (ev: React.ChangeEvent<HTMLInputElement>) =>
      setSearchParams({
        depositorName: ev.target.value,
        currentPage: undefined,
        summaryStatus: undefined,
      }),
    200
  );
  const handleDepositStatus = useCallback(
    (statuses: EOrderGroupDepositStatus[]) =>
      setSearchParams({
        statuses,
        currentPage: undefined,
        summaryStatus: undefined,
      }),
    [setSearchParams]
  );

  return (
    <div className='flex w-[548px] flex-col gap-4'>
      <Input
        placeholder='입금코드, 연관 와이어드주문코드 입력'
        defaultValue={searchParams.searchKeyword}
        onChange={handleSearchKeyword}
      />
      <Descriptions
        bordered
        column={1}
        labelStyle={{ width: 200 }}
        size='small'
      >
        <Descriptions.Item label='요청일시'>
          <div className='flex items-center justify-between'>
            <DatePicker
              className='w-[150px]'
              value={
                searchParams.startedAt ? dayjs(searchParams.startedAt) : null
              }
              disabledDate={(date) =>
                searchParams.endedAt
                  ? date.isAfter(searchParams.endedAt)
                  : false
              }
              onChange={handleStartedAt}
            />
            ~
            <DatePicker
              className='w-[150px]'
              value={searchParams.endedAt ? dayjs(searchParams.endedAt) : null}
              disabledDate={(date) =>
                searchParams.startedAt
                  ? date.isBefore(searchParams.startedAt)
                  : false
              }
              onChange={handleEndedAt}
            />
          </div>
        </Descriptions.Item>
        <Descriptions.Item label='요청자'>
          <ManagerSelect
            mode='multiple'
            className='w-full'
            placeholder='요청자 선택'
            defaultValue={creatorOperatorIds}
            onChange={handleCreatorOperatorIds}
            valueType='operator'
          />
        </Descriptions.Item>
        <Descriptions.Item label='입금자명'>
          <Input
            placeholder='입금자명 입력'
            defaultValue={searchParams.depositorName}
            onChange={handleDepositorName}
          />
        </Descriptions.Item>
        <Descriptions.Item label='입금상태'>
          <Select
            mode='multiple'
            className='w-full'
            placeholder='입금상태 선택'
            defaultValue={searchParams.statuses}
            options={DEPOSIT_STATUS_OPTIONS}
            onChange={handleDepositStatus}
          />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default SearchForm;
