import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Table, Typography } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { round } from 'lodash';
import { useEffect, useState } from 'react';

import { formatPriceWithComma } from '../../../../common/utils/price';
import { ProposalDataType, SaleType } from '../../types';

import ProposalTableRow from './ProposalTableRow';

type ProductProposalTableProps = {
  data: ProposalDataType[];
  loading?: boolean;
  showLowestPriceOnline: boolean;
  salesType: SaleType;
};

export default function ProductProposalTable({
  data,
  loading = false,
  showLowestPriceOnline,
  salesType,
}: ProductProposalTableProps) {
  const columns: ColumnsType<ProposalDataType> = [
    {
      key: 'sort',
      className: 'w-0 p-0 border-0', // drag column 숨김
      align: 'center',
    },
    {
      title: '품목명',
      dataIndex: 'name',
      align: 'center',
      className: 'whitespace-pre-line',
    },
    {
      title: '옵션명',
      dataIndex: 'items',
      align: 'center',
      className: 'p-0 w-60',
      render(items: string[]) {
        return <div className='p-2'>{items.join(', ')}</div>;
      },
    },
    {
      title: '소비자가',
      dataIndex: 'retailPrice',
      align: 'center',
      render(price: number) {
        return formatPriceWithComma(price);
      },
    },
    {
      className: showLowestPriceOnline ? '' : 'hidden',
      title: '온라인최저가',
      dataIndex: 'onlineLowestPrice',
      align: 'center',
      render(_, { onlineLowestPrice }) {
        return onlineLowestPrice ? formatPriceWithComma(onlineLowestPrice) : '';
      },
    },
    {
      title: '공동구매가',
      dataIndex: 'jointPurchasePrice',
      align: 'center',
      render(price: number) {
        return formatPriceWithComma(price);
      },
    },
    {
      title: '셀러공급가',
      dataIndex:
        salesType === 'BUSINESS'
          ? 'businessSellerSupplyPrice'
          : 'freelanceSellerSupplyPrice',
      align: 'center',
      render(price: number) {
        return formatPriceWithComma(price);
      },
    },
    {
      title: '셀러마진',
      dataIndex:
        salesType === 'BUSINESS'
          ? 'businessSellerMargin'
          : 'freelanceSellerMargin',
      align: 'center',
      render(price: number) {
        return formatPriceWithComma(price);
      },
    },
    {
      title: '셀러마진율',
      dataIndex:
        salesType === 'BUSINESS'
          ? 'businessSellerMarginRate'
          : 'freelanceSellerMarginRate',
      align: 'center',
      render(rate: number) {
        return `${round(rate * 100, 1)}%`;
      },
    },
  ];

  const [dataSource, setDataSource] = useState<ProposalDataType[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    setDataSource(data || []);
  }, [data]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const tableProps: TableProps<ProposalDataType> = {
    components: {
      body: {
        row: ProposalTableRow,
      },
    },
    rowKey: 'key',
    columns,
    dataSource,
    rowSelection: loading
      ? undefined
      : {
          type: 'checkbox',
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        },
    bordered: true,
    pagination: false,
  };

  const handleDelete = () => {
    setDataSource((prev) =>
      prev.filter((i) => !selectedRowKeys.includes(i.key))
    );
  };

  return (
    <>
      <div className='flex h-12 items-center justify-between'>
        <div>
          {!loading && (
            <Button type='default' onClick={handleDelete}>
              선택삭제
            </Button>
          )}
        </div>
        <Typography.Paragraph className='mb-0'>*VAT포함</Typography.Paragraph>
      </div>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            {...tableProps}
            className='[&_tr:nth-child(even)]:bg-[#fafafa]'
          />
        </SortableContext>
      </DndContext>
    </>
  );
}
