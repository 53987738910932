import { Descriptions } from 'antd';
import { useMemo } from 'react';

import { VendorInSettlementListDataType } from '../../types';

import VendorSelect from './VendorSelect';

type VendorNameModalProps = {
  vendor?: VendorInSettlementListDataType;
  onChange: (vendorId: number) => void;
};

function VendorNameModal({ vendor, onChange }: VendorNameModalProps) {
  const initialOption = useMemo(() => {
    if (vendor) {
      return {
        value: vendor.id,
        label: vendor?.name,
      };
    }
  }, [vendor]);

  const settlementDates = useMemo(() => {
    if (vendor?.settlementDates && vendor.settlementDates.length) {
      return vendor.settlementDates.map((date) => `${date}일`).join(', ');
    }

    return '-';
  }, [vendor?.settlementDates]);

  return (
    <div>
      <VendorSelect
        onChange={onChange}
        value={vendor?.id}
        initialOption={initialOption}
        label={'마켓명'}
        required
      />
      {vendor && (
        <Descriptions
          bordered
          className='mt-2 [&_.ant-descriptions-view]:rounded-none'
          column={1}
          labelStyle={{ width: 160 }}
        >
          <Descriptions.Item label='정산일자 (매월)'>
            {settlementDates}
          </Descriptions.Item>
          <Descriptions.Item label='상호명'>
            {vendor.businessName || '-'}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
}

export default VendorNameModal;
