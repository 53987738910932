import { RightOutlined } from '@ant-design/icons';
import { MenuProps, Tag } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useCallback, useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';

import { bankLabels } from '../../../common/utils/const';
import { ROUTES } from '../../../routes/const';
import { SELLER_SETTLEMENT_LABELS } from '../../seller/const';
import {
  PromotionSellerOptionsQuery,
  PromotionSellerQuery,
  usePromotionSellerQuery,
} from '../graphql/sellers.generated';

export type PromotionSellerItem =
  PromotionSellerOptionsQuery['adminSellers']['sellers']['0'];
export type PromotionSellerSettlementItem =
  PromotionSellerQuery['adminSeller']['settlementInfos']['0'];
export type PromotionSellerAddressItem =
  PromotionSellerQuery['adminSeller']['addresses']['0'];

type UseSellerReceiverSelectParams = {
  sellerId?: number | null;
  settlementCallback: (settlement: PromotionSellerSettlementItem) => void;
  addressCallback: (address: PromotionSellerAddressItem) => void;
  sellerCallback: (seller: PromotionSellerItem) => void;
};

function useSellerReceiver({
  sellerId,
  settlementCallback,
  addressCallback,
  sellerCallback,
}: UseSellerReceiverSelectParams) {
  const { data: sellerInfo } = usePromotionSellerQuery({
    variables: { adminSellerId: sellerId! },
    skip: !sellerId,
  });

  const settlementOptions = useMemo(() => {
    if (!sellerInfo) return [];
    return sellerInfo.adminSeller.settlementInfos.map((info) => ({
      label: `${SELLER_SETTLEMENT_LABELS[info.type]} / ${
        (info.type === 'BUSINESS' ? info.companyName : info.sellerName) || ''
      } / ${info.bank ? bankLabels[info.bank] : ''} ${
        info.accountNumber ? info.accountNumber.slice(0, 4) : ''
      } ${info.isDefault ? '(대표)' : ''}`,
      value: info.id,
    }));
  }, [sellerInfo]);

  const addressOptions: MenuProps['items'] = useMemo(() => {
    if (!sellerInfo) return [];
    const addressList = sellerInfo.adminSeller.addresses.map((address) => ({
      key: address.id,
      label: (
        <div className='flex'>
          <div className='line-clamp-1'>
            {address.address} / (우) ${address.zipCode}
          </div>
          {address.isDefault && <Tag color='blue'>대표</Tag>}
        </div>
      ),
    }));
    const addressManage = {
      key: -1,
      label: (
        <Link
          className='flex items-center justify-between'
          target='_blank'
          rel='noreferer'
          to={generatePath(ROUTES.SELLER_DETAIL.path, {
            sellerId: sellerInfo.adminSeller.id,
          })}
        >
          샘플수령지 관리
          <RightOutlined />
        </Link>
      ),
    };
    if (!addressList.length) {
      return [addressManage];
    } else {
      return [...addressList, { type: 'divider' }, addressManage];
    }
  }, [sellerInfo]);

  const handleSettlementSelect = useCallback(
    (selectedId: number, option: DefaultOptionType | DefaultOptionType[]) => {
      if (!Array.isArray(option)) {
        const settlements = sellerInfo?.adminSeller.settlementInfos;
        const selectedSettlement = settlements?.find(
          ({ id }) => id === selectedId
        );
        if (selectedSettlement) {
          settlementCallback(selectedSettlement);
        }
      }
    },
    [sellerInfo?.adminSeller.settlementInfos, settlementCallback]
  );
  const handleAddressSelect = useCallback(
    (selectedId: number) => {
      const addresses = sellerInfo?.adminSeller.addresses;
      const selectedAddress = addresses?.find(({ id }) => id === selectedId);
      if (selectedAddress) {
        addressCallback(selectedAddress);
      }
    },
    [sellerInfo?.adminSeller.addresses, addressCallback]
  );
  const handleSellerSelect = useCallback(
    (_: any, option: DefaultOptionType | DefaultOptionType[]) => {
      if (!Array.isArray(option)) {
        const sellerOption = option.data as PromotionSellerItem;
        sellerCallback(sellerOption);
      }
    },
    [sellerCallback]
  );

  return {
    addressOptions,
    settlementOptions,
    handleAddressSelect,
    handleSettlementSelect,
    handleSellerSelect,
    sellerInfo,
  };
}

export default useSellerReceiver;
