import {
  Descriptions,
  Divider,
  Table,
  TableColumnsType,
  Typography,
} from 'antd';
import { Link, generatePath } from 'react-router-dom';

import { formatNumber } from '../../../common/utils/number';
import { ROUTES } from '../../../routes/const';
import { getOrderClaimStatus } from '../../cs/helper/create';
import { AdminOrderQuery } from '../graphql/order.generated';

type OrderDetailInformationProps = {
  order: AdminOrderQuery['adminOrder'];
};

type DataType = {
  key: React.Key;
  productCode: string;
  productName: string;
  productQuantity: number;
  hasItem: boolean;
};

type ExpandedDataType = {
  key: React.Key;
  itemCode: string;
  itemName: string;
  setCount: number;
};

export default function OrderDetailInformation({
  order,
}: OrderDetailInformationProps) {
  const columns: TableColumnsType<DataType> = [
    {
      title: '품목코드',
      dataIndex: 'productCode',
      key: 'productCode',
      width: 200,
    },
    { title: '품목명', dataIndex: 'productName', key: 'productName' },
    {
      title: '주문수량',
      dataIndex: 'productQuantity',
      key: 'productQuantity',
      width: 200,
    },
  ];

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      { title: '옵션코드', dataIndex: 'itemCode', key: 'itemCode', width: 200 },
      { title: '옵션명', dataIndex: 'itemName', key: 'itemName' },
      { title: '차감수량', dataIndex: 'setCount', key: 'setCount', width: 200 },
    ];

    const data =
      order.items?.map((item) => ({
        key: item.itemId,
        itemCode: item.itemCode ?? '-',
        itemName: item.name ?? '-',
        setCount: item.setCount ?? 0,
      })) ?? [];

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const data: DataType[] = [
    {
      key: order.id,
      productCode: order.productCode || '-',
      productName: order.productName,
      productQuantity: order.productQuantity,
      hasItem: !!(order.items && order.items?.length > 0),
    },
  ];

  const { claimType, status } = getOrderClaimStatus(order);

  return (
    <div>
      <Divider orientation='left' plain>
        <Typography.Title level={5} className='mb-0'>
          주문정보
        </Typography.Title>
      </Divider>
      <Descriptions
        bordered
        className='[&_.ant-descriptions-view]:rounded-none'
        column={4}
      >
        <Descriptions.Item
          label='주문상태'
          span={4}
          labelStyle={{ width: 200 }}
        >
          {status}
          {claimType && (
            <Typography.Text className='m-0 ml-1 text-description'>
              ({claimType})
            </Typography.Text>
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label='주문자명'
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
          span={2}
        >
          {order.group.ordererName}
        </Descriptions.Item>
        <Descriptions.Item
          label='주문자 휴대폰번호'
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
          span={2}
        >
          {order.group.ordererPhoneNumber
            ? formatNumber(order.group.ordererPhoneNumber, '###-####-####')
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label='상품명' span={2}>
          <Link
            to={generatePath(ROUTES.PRODUCT_DETAIL.path, {
              productGroupId: order.productGroupId,
            })}
            target='_blank'
          >
            {order.productGroupName || '-'}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label='상품 주문금액' span={2}>
          {order.orderAmount.toLocaleString()}원
        </Descriptions.Item>
      </Descriptions>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ['0'],
          rowExpandable: ({ hasItem }) => hasItem,
        }}
        dataSource={data}
        className='mt-4'
        pagination={false}
      />
    </div>
  );
}
