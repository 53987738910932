import { useApolloClient } from '@apollo/client';
import { App, Spin } from 'antd';
import { useState } from 'react';

import ManagerSelect from '../../../common/components/Fields/ManagerSelect';
import {
  extractBadRequestError,
  removeCache,
} from '../../../common/utils/apollo';
import { useUpdateSellerPartialMutation } from '../graphql/sellers.generated';

interface EditableManagerSelectCellProps {
  id: number;
  initialValue: number | null;
  name: string;
}

function EditableManagerSelectCell({
  initialValue,
  id,
  name,
}: EditableManagerSelectCellProps) {
  const { message } = App.useApp();

  const client = useApolloClient();
  const [updateSellerPartial, { loading }] = useUpdateSellerPartialMutation();

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async (value: number) => {
    setValue(value);

    const close = message.loading('처리 중입니다.');

    await updateSellerPartial({
      variables: { updateSellerPartialInput: { id, managerId: value } },
      onCompleted() {
        close();
        message.success('담당자가 업데이트되었습니다.');
        toggleEdit();
      },
      onError({ graphQLErrors }) {
        close();

        const noSellerError = extractBadRequestError(graphQLErrors);

        if (noSellerError) {
          message.error(noSellerError.message);

          removeCache(client.cache, {
            id,
            __typename: 'SellerGraphqlType',
          });
        } else {
          message.error('수정을 실패하였습니다.');
        }
      },
    });
  };

  if (editing) {
    return (
      <Spin spinning={loading} size={'small'}>
        <ManagerSelect
          ref={(ref) => {
            ref?.focus();
          }}
          className={'w-full'}
          value={value}
          onBlur={toggleEdit}
          onChange={save}
        />
      </Spin>
    );
  }

  return (
    <div
      className='editable-cell min-h-[30px] rounded-lg px-lg py-sm hover:cursor-pointer'
      style={{ paddingRight: 24 }}
      onClick={toggleEdit}
    >
      {name || '-'}
    </div>
  );
}

export default EditableManagerSelectCell;
