import { Typography } from 'antd';
import { get } from 'lodash-es';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { getErrorStatus } from '../../utils/form';
import TextInput, { TextInputProps } from '../Input/TextInput';

import FieldWithLabel from './FieldWithLabel';
import { FieldProps } from './types';

type TextFieldProps<TFieldValues extends FieldValues> = TextInputProps &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
    caption?: string;
  };

function TextField<TFieldValues extends FieldValues>({
  name,
  label,
  required,
  control,
  hideError,
  caption,
  hideErrorMessage,
  ...props
}: TextFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const error = get(formState.errors, name);
        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;

        const status = !hideError && errorMessage ? getErrorStatus(error) : '';

        return (
          <FieldWithLabel label={label} required={required}>
            <TextInput
              status={status}
              errorMessage={errorMessage}
              {...field}
              {...props}
            />
            {caption && (
              <Typography.Paragraph type='secondary' className='mb-0'>
                {caption}
              </Typography.Paragraph>
            )}
            {!hideErrorMessage && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}

export default TextField;
