import { Badge, Descriptions } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import MarketSelect from '../../../../common/components/Fields/MarketSelect';
import {
  SELLER_SETTLEMENT_TYPE_LABEL,
  SELLING_CHANNEL_LABEL,
  SETTLEMENT_MARKET_STATUS_LABEL,
} from '../../const';
import { MarketInSettlementListDataType } from '../../types';

type CreateSettlementSheetModalMarketNameProps = {
  market?: MarketInSettlementListDataType;
  onChange: (marketId: number) => void;
};

function CreateSettlementSheetModalMarketName({
  market,
  onChange,
}: CreateSettlementSheetModalMarketNameProps) {
  const initialOption = useMemo(() => {
    if (market) {
      return {
        value: market.id,
        label: market?.name,
      };
    }
  }, [market]);

  const expectedSettlementAt = useMemo(() => {
    if (market?.expectedSettlementAt) {
      return dayjs(market.expectedSettlementAt).format('YYYY.MM.DD');
    }

    return '-';
  }, [market?.expectedSettlementAt]);

  const settlementType = useMemo(() => {
    if (market?.sellerSettlementInfo?.type) {
      return SELLER_SETTLEMENT_TYPE_LABEL[market?.sellerSettlementInfo?.type];
    }

    return '-';
  }, [market?.sellerSettlementInfo?.type]);

  const sellingChannel = useMemo(() => {
    if (market?.sellingChannel) {
      return SELLING_CHANNEL_LABEL[market.sellingChannel];
    }

    return '-';
  }, [market?.sellingChannel]);

  const period = useMemo(() => {
    if (!market) return;

    const { startedAt, endedAt } = market;

    const startedAtFormat = dayjs(startedAt).format('YYYY.MM.DD');
    const endedAtFormat = endedAt
      ? dayjs(endedAt).format('YYYY.MM.DD')
      : '상시';

    return `${startedAtFormat} ~ ${endedAtFormat}`;
  }, [market]);

  const statusColor = useMemo(() => {
    if (!market) return;

    switch (market.status) {
      case 'ON_SALE':
        return 'green';
      case 'READY':
        return 'yellow';
      case 'ENDED':
        return 'navy';
      case 'CANCELED':
        return 'gray';
      case 'SUSPENDED':
        return 'red';
    }
  }, [market]);

  return (
    <div>
      <FieldWithLabel label={'마켓명'} required={true}>
        <MarketSelect
          onChange={onChange}
          value={market?.id}
          initialOption={initialOption}
          initialLoad
        />
      </FieldWithLabel>
      {market && (
        <Descriptions
          bordered
          className='mt-2 [&_.ant-descriptions-view]:rounded-none'
          column={1}
          labelStyle={{ width: 160 }}
        >
          <Descriptions.Item label='마켓기간'>
            <div className='flex justify-between'>
              {period}
              <div className='flex gap-2'>
                <Badge color={statusColor} />
                {SETTLEMENT_MARKET_STATUS_LABEL[market.status]}
              </div>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label='판매채널'>
            {sellingChannel}
          </Descriptions.Item>
          <Descriptions.Item label='정산유형'>
            {settlementType}
          </Descriptions.Item>
          <Descriptions.Item label='정산예정일'>
            {expectedSettlementAt}
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
}

export default CreateSettlementSheetModalMarketName;
