/**
 * onlyMenu true일 경우, route 계층에만 존재하고 실제 라우트는 존재하지 않는다.
 * 메뉴와 breadcrumb에서 계층만 보여주어야 하는 UI가 있어서 추가되었다.
 */
export const ROUTES = {
  MARKETS: { path: '/markets', title: '마켓관리' },
  MARKET_DETAIL: { path: '/markets/:marketId', title: '마켓상세' },
  PRODUCTS: { path: '/products', title: '상품관리' },
  PRODUCT_DETAIL: { path: '/products/:productGroupId', title: '상품상세' },
  PRODUCT_NEW: { path: '/products/new', title: '상품상세' },
  SELLERS: { path: '/sellers', title: '셀러관리' },
  SELLER_DETAIL: { path: '/sellers/:sellerId', title: '셀러상세' },
  VENDORS: { path: '/vendors', title: '업체 / 브랜드관리' },
  VENDOR_DETAIL: { path: '/vendors/:vendorId', title: '업체 / 브랜드상세' },
  SAMPLES: { path: '/samples', title: '샘플관리' },
  PURCHASE_ORDER: { path: '/po', title: '발주관리', onlyMenu: true }, // Purchase Order = 발주서
  PROMOTION_RELEASES: { path: '/promotion-releases', title: '출고요청' },
  PROMOTION_RELEASE_DETAIL: {
    path: '/promotion-releases/:releaseId',
    title: '출고요청 상세',
  },
  ORDERING_TRANSFORM: { path: '/orderings/transform', title: '주문서변환' },
  ORDERS: { path: '/orders', title: '주문관리' },
  CS: { path: '/cs', title: 'CS관리', onlyMenu: true },
  CS_RETURNS: { path: '/cs/returns', title: '반품관리' },
  CS_EXCHANGES: { path: '/cs/exchanges', title: '교환관리' },
  CS_CANCELS: { path: '/cs/cancels', title: '취소관리' },
  CS_ORDER_SUPPORTS: { path: '/cs/supports', title: '응대관리' },
  SETTLEMENTS: { path: '/settlement', title: '정산관리', onlyMenu: true },
  SETTLEMENTS_DEPOSIT: { path: '/settlement/deposit', title: '입금내역' },
  SETTLEMENTS_LIST: { path: '/settlement/list', title: '정산내역' },
  SETTLEMENTS_SHEETS: {
    path: '/settlement/sheets',
    title: '정산서관리',
  },
  OPERATORS: { path: '/operators', title: '운영자관리' },
  OPERATOR_DETAIL: { path: '/operators/:operatorId', title: '운영자상세' },
  OAUTH_CALLBACK: { path: '/oauth-callback', title: '' },
};
