import { ArrowRightOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import { useState } from 'react';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import Modal from '../../../../common/components/Modal';

type EditAmountModalProps = {
  onClose: () => void;
  title: string;
  currentAmount: number;
  onEdit: (amount: number) => Promise<void>;
};

function EditAmountModal({
  onClose,
  title,
  currentAmount,
  onEdit,
}: EditAmountModalProps) {
  const [modifiedAmount, setModifiedAmount] = useState<number | null>();
  const handleOk = async () => {
    modifiedAmount && (await onEdit(modifiedAmount));
    onClose();
  };

  return (
    <Modal
      onCancel={onClose}
      title={title}
      okText={'수정'}
      onOk={handleOk}
      okButtonProps={{ disabled: !modifiedAmount }}
    >
      <div className='flex items-end gap-4 pb-1 pt-4'>
        <FieldWithLabel label={'현재금액'}>
          <InputNumber
            className='w-full'
            value={currentAmount}
            formatter={(value) => (value ? Number(value).toLocaleString() : '')}
            disabled
          />
        </FieldWithLabel>
        <div className='flex h-8 items-center'>
          <ArrowRightOutlined
            className='flex h-6 w-6 justify-center'
            style={{ fontSize: '21px', color: '#00000073' }}
          />
        </div>
        <FieldWithLabel label={'수정금액'} required>
          <InputNumber
            className='w-full'
            formatter={(value) => (value ? Number(value).toLocaleString() : '')}
            value={modifiedAmount}
            placeholder='금액입력'
            onChange={(value) => setModifiedAmount(value)}
          />
        </FieldWithLabel>
      </div>
    </Modal>
  );
}

export default EditAmountModal;
