import { SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback } from 'react';

import { useProductGroupsInSelectFieldLazyQuery } from '../../graphql/productGroups.generated';
import AsyncSelect from '../Input/AsyncSelect';

export type SelectFieldProps = SelectProps & {
  initialOption?: { value: number; label: string };
  initialLoad?: boolean;
};

const ProductGroupSelect = forwardRef<BaseSelectRef, SelectFieldProps>(
  function ({ initialLoad, ...props }, ref) {
    const [fetchProductGroup] = useProductGroupsInSelectFieldLazyQuery({
      fetchPolicy: 'no-cache',
    });

    const fetchItems = useCallback(
      async (name: string) => {
        const { data } = await fetchProductGroup({
          variables: {
            filter: { productGroupName: name || undefined },
            pagination: { skip: 0, take: 20 },
          },
        });
        return (
          data?.adminProductGroups?.edges.map(({ node }) => ({
            label: node.name,
            value: node.id,
            data: node,
          })) || []
        );
      },
      [fetchProductGroup]
    );

    return (
      <AsyncSelect
        ref={ref}
        initialLoad={initialLoad}
        className={'w-full'}
        fetchItems={fetchItems}
        {...props}
      />
    );
  }
);

export default ProductGroupSelect;
