import { get } from 'lodash-es';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { getErrorStatus } from '../../utils/form';
import { formatNumber, parseNumber } from '../../utils/number';
import TextInput, { TextInputProps } from '../Input/TextInput';

import FieldWithLabel from './FieldWithLabel';
import { FieldProps } from './types';

type TextNumberField<TFieldValues extends FieldValues> = TextInputProps &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
    format?: string;
    formatStrict?: boolean;
    numberType?: 'number' | 'price';
  };

function TextNumberField<TFieldValues extends FieldValues>({
  name,
  label,
  tooltip,
  required,
  control,
  format,
  hideError,
  formatStrict,
  numberType,
  ...props
}: TextNumberField<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...lastField }, formState }) => {
        const error = get(formState.errors, name);

        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;
        const status = !hideError && errorMessage ? getErrorStatus(error) : '';

        const inputValue = formatNumber(value || '', format, formatStrict);

        return (
          <FieldWithLabel label={label} required={required} tooltip={tooltip}>
            <TextInput
              status={status}
              errorMessage={errorMessage}
              onChange={(e) => {
                e.target.value = parseNumber(
                  formatNumber(e.target.value, format, formatStrict)
                );
                onChange(e);
              }}
              value={
                numberType === 'price' && inputValue
                  ? Number(inputValue).toLocaleString()
                  : inputValue
              }
              {...lastField}
              {...props}
            />
            {errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}

export default TextNumberField;
