import { Select, SelectProps } from 'antd';
import { debounce } from 'lodash-es';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback, useEffect, useState } from 'react';

type OptionItem = {
  label: string;
  value: number;
  data?: Record<string, any>;
};

export type AsyncSelectProps = SelectProps & {
  initialOption?: { value: number; label: string };
  fetchItems: (value: string) => Promise<OptionItem[]>;
  initialLoad?: boolean;
  initialLoadTrigger?: any;
};

const AsyncSelect = forwardRef<BaseSelectRef, AsyncSelectProps>(function (
  { initialOption, initialLoad, fetchItems, initialLoadTrigger, ...props },
  ref
) {
  const [options, setOptions] = useState<
    {
      label: string;
      value: number;
      data?: Record<string, any>;
    }[]
  >(initialOption ? [initialOption] : []);
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(
    debounce(async (v: string) => {
      try {
        setLoading(true);
        const options = await fetchItems(v);

        setOptions(options);
      } catch (e) {
        setOptions([]);
      } finally {
        setLoading(false);
      }
    }, 500),
    [fetchItems]
  );

  useEffect(() => {
    if (initialLoad) {
      handleSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoad, initialLoadTrigger]);

  return (
    <Select
      showSearch
      ref={ref}
      options={options || []}
      loading={loading}
      placeholder='--선택--'
      onSearch={handleSearch}
      filterOption={(input, option) => {
        if (typeof option?.label !== 'string') {
          return false;
        }

        return option.label?.toLowerCase().includes(input.toLowerCase());
      }}
      onClear={async () => {
        await handleSearch('');
      }}
      {...props}
    />
  );
});

export default AsyncSelect;
