import { App, Button } from 'antd';
import { useCallback } from 'react';

import { usePermissions } from '../../../../common/hooks/usePermissions';
import {
  AdminCountOrderGroupDepositStatusesDocument,
  AdminOrderGroupDepositsDocument,
  useAdminDeleteOrderGroupDepositByIdMutation,
} from '../../graphql/deposit.generated';

function DepositDeleteCell({ id }: { id: number }) {
  const { message, modal } = App.useApp();
  const { hasPermission } = usePermissions(['SETTLEMENT_EDIT']);

  const [deleteDepositMutation, { loading }] =
    useAdminDeleteOrderGroupDepositByIdMutation();

  const handleDelete = useCallback(() => {
    void modal.confirm({
      title: '입금대기 삭제',
      content: (
        <>
          삭제하면 해당 입금 정보를 되돌릴 수 없습니다.
          <br />
          삭제하시겠습니까?
        </>
      ),
      okText: '네',
      cancelText: '아니오',
      onOk: async () =>
        await deleteDepositMutation({
          variables: { adminDeleteOrderGroupDepositByIdId: id },
          refetchQueries: [
            AdminCountOrderGroupDepositStatusesDocument,
            AdminOrderGroupDepositsDocument,
          ],
          onCompleted() {
            void message.success('삭제되었습니다.');
          },
          onError(error) {
            void message.error(error.message);
          },
        }),
    });
  }, [id, deleteDepositMutation, message, modal]);

  return (
    <Button
      loading={loading}
      danger
      type='text'
      onClick={handleDelete}
      disabled={!hasPermission}
    >
      삭제
    </Button>
  );
}

export default DepositDeleteCell;
