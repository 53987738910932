import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminListBrandsOptionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AdminListBrandsPageQueryFilter>;
  order?: Types.InputMaybe<Types.Scalars['Order']['input']>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminListBrandsOptionsQuery = { __typename?: 'Query', adminBrands: { __typename?: 'BrandPageGraphqlType', edges: Array<{ __typename?: 'PaginatedBrandGraphqlTypeEdge', node: { __typename?: 'BrandGraphqlType', id: number, name: string, productGroups: Array<{ __typename?: 'ProductGroupGraphqlType', id: number, name: string, deliveryFee?: number | null, deliveryFeeType: Types.EProductGroupDeliveryFeeType, freeDeliveryConditionFee?: number | null, isRegionFee: boolean, jejuIslandFee?: number | null, additionalFee?: number | null, releaseType: Types.EProductGroupReleaseType, releaseTime?: string | null, manager: { __typename?: 'ManagerGraphqlType', id: number, name: string }, products: Array<{ __typename?: 'ProductGraphqlType', id: number, name: string, businessSellerSupplyPrice: number, freelanceSellerSupplyPrice: number, inHousePurchasePrice: number }> }> } }> } };

export type AdminProductGroupsOptionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductGroupFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminProductGroupsOptionsQuery = { __typename?: 'Query', adminProductGroups?: { __typename?: 'ProductGroupPageGraphqlType', edges: Array<{ __typename?: 'PaginatedProductGroupGraphqlTypeEdge', node: { __typename?: 'ProductGroupGraphqlType', id: number, name: string, deliveryFee?: number | null, deliveryFeeType: Types.EProductGroupDeliveryFeeType, freeDeliveryConditionFee?: number | null, isRegionFee: boolean, jejuIslandFee?: number | null, additionalFee?: number | null, releaseType: Types.EProductGroupReleaseType, releaseTime?: string | null, manager: { __typename?: 'ManagerGraphqlType', id: number, name: string }, products: Array<{ __typename?: 'ProductGraphqlType', id: number, name: string, businessSellerSupplyPrice: number, freelanceSellerSupplyPrice: number, inHousePurchasePrice: number }> } }> } | null };

export type AdminProductsOptionsQueryVariables = Types.Exact<{
  filter: Types.ProductFilterQuery;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminProductsOptionsQuery = { __typename?: 'Query', adminProducts?: { __typename?: 'ProductPageGraphqlType', edges: Array<{ __typename?: 'PaginatedProductGraphqlTypeEdge', node: { __typename?: 'ProductGraphqlType', id: number, name: string, useType: Types.EProductUseType, businessSellerSupplyPrice: number, freelanceSellerSupplyPrice: number, inHousePurchasePrice: number, items?: Array<{ __typename?: 'ItemGraphqlType', id: number, name: string }> | null } }> } | null };

export type AdminMarketEventProductQueryVariables = Types.Exact<{
  adminProductId: Types.Scalars['Int']['input'];
}>;


export type AdminMarketEventProductQuery = { __typename?: 'Query', adminProduct?: { __typename?: 'ProductGraphqlType', id: number, name: string, useType: Types.EProductUseType, businessSellerSupplyPrice: number, freelanceSellerSupplyPrice: number, inHousePurchasePrice: number, items?: Array<{ __typename?: 'ItemGraphqlType', id: number, name: string }> | null } | null };


export const AdminListBrandsOptionsDocument = gql`
    query AdminListBrandsOptions($filter: AdminListBrandsPageQueryFilter, $order: Order, $pagination: Pagination) {
  adminBrands(filter: $filter, order: $order, pagination: $pagination) {
    edges {
      node {
        id
        name
        productGroups {
          id
          name
          manager {
            id
            name
          }
          deliveryFee
          deliveryFeeType
          freeDeliveryConditionFee
          isRegionFee
          jejuIslandFee
          additionalFee
          releaseType
          releaseTime
          products {
            id
            name
            businessSellerSupplyPrice
            freelanceSellerSupplyPrice
            inHousePurchasePrice
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAdminListBrandsOptionsQuery__
 *
 * To run a query within a React component, call `useAdminListBrandsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminListBrandsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminListBrandsOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminListBrandsOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminListBrandsOptionsQuery, AdminListBrandsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminListBrandsOptionsQuery, AdminListBrandsOptionsQueryVariables>(AdminListBrandsOptionsDocument, options);
      }
export function useAdminListBrandsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminListBrandsOptionsQuery, AdminListBrandsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminListBrandsOptionsQuery, AdminListBrandsOptionsQueryVariables>(AdminListBrandsOptionsDocument, options);
        }
export type AdminListBrandsOptionsQueryHookResult = ReturnType<typeof useAdminListBrandsOptionsQuery>;
export type AdminListBrandsOptionsLazyQueryHookResult = ReturnType<typeof useAdminListBrandsOptionsLazyQuery>;
export type AdminListBrandsOptionsQueryResult = Apollo.QueryResult<AdminListBrandsOptionsQuery, AdminListBrandsOptionsQueryVariables>;
export const AdminProductGroupsOptionsDocument = gql`
    query AdminProductGroupsOptions($filter: ProductGroupFilterQuery, $pagination: Pagination) {
  adminProductGroups(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
        manager {
          id
          name
        }
        deliveryFee
        deliveryFeeType
        freeDeliveryConditionFee
        isRegionFee
        jejuIslandFee
        additionalFee
        releaseType
        releaseTime
        products {
          id
          name
          businessSellerSupplyPrice
          freelanceSellerSupplyPrice
          inHousePurchasePrice
        }
      }
    }
  }
}
    `;

/**
 * __useAdminProductGroupsOptionsQuery__
 *
 * To run a query within a React component, call `useAdminProductGroupsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductGroupsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductGroupsOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminProductGroupsOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AdminProductGroupsOptionsQuery, AdminProductGroupsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductGroupsOptionsQuery, AdminProductGroupsOptionsQueryVariables>(AdminProductGroupsOptionsDocument, options);
      }
export function useAdminProductGroupsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductGroupsOptionsQuery, AdminProductGroupsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductGroupsOptionsQuery, AdminProductGroupsOptionsQueryVariables>(AdminProductGroupsOptionsDocument, options);
        }
export type AdminProductGroupsOptionsQueryHookResult = ReturnType<typeof useAdminProductGroupsOptionsQuery>;
export type AdminProductGroupsOptionsLazyQueryHookResult = ReturnType<typeof useAdminProductGroupsOptionsLazyQuery>;
export type AdminProductGroupsOptionsQueryResult = Apollo.QueryResult<AdminProductGroupsOptionsQuery, AdminProductGroupsOptionsQueryVariables>;
export const AdminProductsOptionsDocument = gql`
    query AdminProductsOptions($filter: ProductFilterQuery!, $pagination: Pagination) {
  adminProducts(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
        useType
        businessSellerSupplyPrice
        freelanceSellerSupplyPrice
        inHousePurchasePrice
        items {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useAdminProductsOptionsQuery__
 *
 * To run a query within a React component, call `useAdminProductsOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductsOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductsOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminProductsOptionsQuery(baseOptions: Apollo.QueryHookOptions<AdminProductsOptionsQuery, AdminProductsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductsOptionsQuery, AdminProductsOptionsQueryVariables>(AdminProductsOptionsDocument, options);
      }
export function useAdminProductsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductsOptionsQuery, AdminProductsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductsOptionsQuery, AdminProductsOptionsQueryVariables>(AdminProductsOptionsDocument, options);
        }
export type AdminProductsOptionsQueryHookResult = ReturnType<typeof useAdminProductsOptionsQuery>;
export type AdminProductsOptionsLazyQueryHookResult = ReturnType<typeof useAdminProductsOptionsLazyQuery>;
export type AdminProductsOptionsQueryResult = Apollo.QueryResult<AdminProductsOptionsQuery, AdminProductsOptionsQueryVariables>;
export const AdminMarketEventProductDocument = gql`
    query AdminMarketEventProduct($adminProductId: Int!) {
  adminProduct(id: $adminProductId) {
    id
    name
    useType
    businessSellerSupplyPrice
    freelanceSellerSupplyPrice
    inHousePurchasePrice
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useAdminMarketEventProductQuery__
 *
 * To run a query within a React component, call `useAdminMarketEventProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMarketEventProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMarketEventProductQuery({
 *   variables: {
 *      adminProductId: // value for 'adminProductId'
 *   },
 * });
 */
export function useAdminMarketEventProductQuery(baseOptions: Apollo.QueryHookOptions<AdminMarketEventProductQuery, AdminMarketEventProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMarketEventProductQuery, AdminMarketEventProductQueryVariables>(AdminMarketEventProductDocument, options);
      }
export function useAdminMarketEventProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMarketEventProductQuery, AdminMarketEventProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMarketEventProductQuery, AdminMarketEventProductQueryVariables>(AdminMarketEventProductDocument, options);
        }
export type AdminMarketEventProductQueryHookResult = ReturnType<typeof useAdminMarketEventProductQuery>;
export type AdminMarketEventProductLazyQueryHookResult = ReturnType<typeof useAdminMarketEventProductLazyQuery>;
export type AdminMarketEventProductQueryResult = Apollo.QueryResult<AdminMarketEventProductQuery, AdminMarketEventProductQueryVariables>;