import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductGroupOptionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductGroupFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type ProductGroupOptionsQuery = { __typename?: 'Query', adminProductGroups?: { __typename?: 'ProductGroupPageGraphqlType', edges: Array<{ __typename?: 'PaginatedProductGroupGraphqlTypeEdge', node: { __typename?: 'ProductGroupGraphqlType', id: number, name: string } }> } | null };

export type PromotionProductOptionsQueryVariables = Types.Exact<{
  filter: Types.ProductFilterQuery;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type PromotionProductOptionsQuery = { __typename?: 'Query', adminProducts?: { __typename?: 'ProductPageGraphqlType', edges: Array<{ __typename?: 'PaginatedProductGraphqlTypeEdge', node: { __typename?: 'ProductGraphqlType', id: number, name: string } }> } | null };

export type PromotionProductQueryVariables = Types.Exact<{
  adminProductId: Types.Scalars['Int']['input'];
}>;


export type PromotionProductQuery = { __typename?: 'Query', adminProduct?: { __typename?: 'ProductGraphqlType', useType: Types.EProductUseType, inHousePurchasePrice: number, businessSellerSupplyPrice: number, freelanceSellerSupplyPrice: number, items?: Array<{ __typename?: 'ItemGraphqlType', id: number, name: string }> | null } | null };


export const ProductGroupOptionsDocument = gql`
    query ProductGroupOptions($filter: ProductGroupFilterQuery, $pagination: Pagination) {
  adminProductGroups(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProductGroupOptionsQuery__
 *
 * To run a query within a React component, call `useProductGroupOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGroupOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGroupOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProductGroupOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ProductGroupOptionsQuery, ProductGroupOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupOptionsQuery, ProductGroupOptionsQueryVariables>(ProductGroupOptionsDocument, options);
      }
export function useProductGroupOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupOptionsQuery, ProductGroupOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupOptionsQuery, ProductGroupOptionsQueryVariables>(ProductGroupOptionsDocument, options);
        }
export type ProductGroupOptionsQueryHookResult = ReturnType<typeof useProductGroupOptionsQuery>;
export type ProductGroupOptionsLazyQueryHookResult = ReturnType<typeof useProductGroupOptionsLazyQuery>;
export type ProductGroupOptionsQueryResult = Apollo.QueryResult<ProductGroupOptionsQuery, ProductGroupOptionsQueryVariables>;
export const PromotionProductOptionsDocument = gql`
    query PromotionProductOptions($filter: ProductFilterQuery!, $pagination: Pagination) {
  adminProducts(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePromotionProductOptionsQuery__
 *
 * To run a query within a React component, call `usePromotionProductOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionProductOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionProductOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePromotionProductOptionsQuery(baseOptions: Apollo.QueryHookOptions<PromotionProductOptionsQuery, PromotionProductOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionProductOptionsQuery, PromotionProductOptionsQueryVariables>(PromotionProductOptionsDocument, options);
      }
export function usePromotionProductOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionProductOptionsQuery, PromotionProductOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionProductOptionsQuery, PromotionProductOptionsQueryVariables>(PromotionProductOptionsDocument, options);
        }
export type PromotionProductOptionsQueryHookResult = ReturnType<typeof usePromotionProductOptionsQuery>;
export type PromotionProductOptionsLazyQueryHookResult = ReturnType<typeof usePromotionProductOptionsLazyQuery>;
export type PromotionProductOptionsQueryResult = Apollo.QueryResult<PromotionProductOptionsQuery, PromotionProductOptionsQueryVariables>;
export const PromotionProductDocument = gql`
    query PromotionProduct($adminProductId: Int!) {
  adminProduct(id: $adminProductId) {
    useType
    inHousePurchasePrice
    businessSellerSupplyPrice
    freelanceSellerSupplyPrice
    items {
      id
      name
    }
  }
}
    `;

/**
 * __usePromotionProductQuery__
 *
 * To run a query within a React component, call `usePromotionProductQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionProductQuery({
 *   variables: {
 *      adminProductId: // value for 'adminProductId'
 *   },
 * });
 */
export function usePromotionProductQuery(baseOptions: Apollo.QueryHookOptions<PromotionProductQuery, PromotionProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionProductQuery, PromotionProductQueryVariables>(PromotionProductDocument, options);
      }
export function usePromotionProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionProductQuery, PromotionProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionProductQuery, PromotionProductQueryVariables>(PromotionProductDocument, options);
        }
export type PromotionProductQueryHookResult = ReturnType<typeof usePromotionProductQuery>;
export type PromotionProductLazyQueryHookResult = ReturnType<typeof usePromotionProductLazyQuery>;
export type PromotionProductQueryResult = Apollo.QueryResult<PromotionProductQuery, PromotionProductQueryVariables>;