import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { some } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import SelectField from '../../../common/components/Fields/SelectField';
import TextInput from '../../../common/components/Input/TextInput';
import { formSetValueOption } from '../../../common/utils/const';
import { usePromotionProductOptionsQuery } from '../graphql/productGroups.generated';
import { getPromotionProduct } from '../helper';
import usePromotionPermission from '../hooks/usePromotionPermission';
import { PromotionFormValues } from '../types';

type PromotionProductSelectCellProps = {
  index: number;
  disabled?: boolean;
};

function PromotionProductSelectCell({
  index,
  disabled,
}: PromotionProductSelectCellProps) {
  const hasPermission = usePromotionPermission();
  const { control, watch, setValue, resetField } =
    useFormContext<PromotionFormValues>();
  const [productGroupId, originalProductId] = watch([
    'productGroupId',
    `promotionProducts.${index}.originalProductId`,
  ]);

  const [addedValue, setAddedValue] = useState('');

  const { data } = usePromotionProductOptionsQuery({
    variables: {
      filter: { groupId: productGroupId || 0 },
      pagination: { skip: 0, take: 100 },
    },
    skip: !productGroupId,
  });
  const [customProductOptions, setCustomProductOptions] = useState<
    { label: string; value: number | string }[]
  >([]);

  const options = useMemo(() => {
    return (
      data?.adminProducts?.edges.map(({ node }) => ({
        label: node.name,
        value: node.id,
      })) || []
    );
  }, [data]);

  useEffect(() => {
    setCustomProductOptions(options);
  }, [options]);

  const hasAddedValue = some(
    customProductOptions,
    (item) => item.label === addedValue.trim()
  );

  const handleSelect = useCallback(
    (value: string | number) => {
      resetField(`promotionProducts.${index}`, {
        defaultValue: { ...getPromotionProduct(), originalProductId: value },
      });
    },
    [resetField, index]
  );

  const handleCustomOptionAdd = useCallback(() => {
    const newValue = addedValue.trim();

    if (hasAddedValue || !newValue) {
      return;
    }
    setCustomProductOptions([
      ...customProductOptions,
      { label: newValue, value: newValue },
    ]);
    setValue(
      `promotionProducts.${index}.customPromotionProductName`,
      newValue,
      formSetValueOption
    );
    setValue(
      `promotionProducts.${index}.originalProductId`,
      newValue,
      formSetValueOption
    );

    setAddedValue('');
  }, [addedValue, hasAddedValue, customProductOptions, setValue, index]);

  return (
    <SelectField
      className='w-full'
      disabled={disabled}
      control={control}
      name={`promotionProducts.${index}.originalProductId`}
      options={customProductOptions}
      onChange={handleSelect}
      value={originalProductId}
      popupMatchSelectWidth={false}
      placeholder={'품목선택'}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <div className={'flex gap-2 p-2'}>
            <TextInput
              disabled={!hasPermission}
              className={'f-1'}
              placeholder='추가할 품목을 입력해주세요.'
              value={addedValue}
              onPressEnter={(e) => {
                e.preventDefault(); // submit 방지
                e.stopPropagation(); // 셀렉트 선택 방지
                if (e.nativeEvent.isComposing) return; // 한글조합 중일 때는 무시
                handleCustomOptionAdd();
              }}
              onFocus={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
              onChange={(e) => setAddedValue(e.target.value)}
            />
            <Button
              type='text'
              disabled={!hasPermission || !addedValue.trim() || hasAddedValue}
              icon={<PlusOutlined />}
              onClick={handleCustomOptionAdd}
            >
              품목 생성
            </Button>
          </div>
        </>
      )}
    />
  );
}

export default PromotionProductSelectCell;
