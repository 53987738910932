import { App } from 'antd';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useFormError } from '../../../common/hooks/useFormError';
import {
  AdminPromotionReleaseDocument,
  AdminPromotionReleaseQuery,
  useAdminUpdatePromotionMutation,
} from '../graphql/promotionRelease.generated';
import { UpdatePromotionEventFormValues } from '../types';
import { updatePromotionResolver } from '../validator/updatePromotionValidator';

function useUpdatePromotion(
  promotionRelease: AdminPromotionReleaseQuery['adminPromotionRelease'],
  onClose: () => void
) {
  const { message, modal } = App.useApp();
  const { promotion, promotionProducts } = promotionRelease;
  const hasOrderConfirm = useMemo(
    () =>
      promotion.promotionReleases?.some(
        ({ releaseStatus }) => releaseStatus === 'ORDER_CONFIRMED'
      ),
    [promotion.promotionReleases]
  );
  const { onInvalid } = useFormError();
  const methods = useForm<UpdatePromotionEventFormValues>({
    defaultValues: {
      id: promotion.id,
      originalProductGroupId:
        promotionProducts[0].originalProductGroupId === null
          ? undefined
          : promotionProducts[0].originalProductGroupId,
      deliveryFee: promotion.deliveryFee,
      deliveryFeeBearer: promotion.deliveryFeeBearer,
      deliveryFeeSettlementPrice: promotion.deliveryFeeSettlementPrice,
      deliveryFeeSettlementTarget: promotion.deliveryFeeSettlementTarget,
      isDeliveryFee: promotion.isDeliveryFee,
      marketUndesignated: !promotion.market,
      marketId: promotion.market?.id,
      productGroupId: promotionProducts[0].originalProductGroupId,
      promotionProducts:
        promotionProducts.map((product) => ({
          customPromotionProductName: product.customPromotionProductName,
          id: product.id,
          inHouseBuyPrice: product.inHouseBuyPrice,
          isFreeProvided: product.isFreeProvided,
          isPrepayment: product.isPrepayment,
          isSellerBearing: product.isSellerBearing,
          isWiredBearing: product.isWiredBearing,
          originalProductId:
            product.originalProductId || product.customPromotionProductName,
          sellerBearingCost: product.sellerBearingCost,
          setCount: product.setCount,
          wiredBearingCost: product.wiredBearingCost,
          promotionItems: product.promotionItems.map(({ originalItem }) => ({
            originalItemId: originalItem?.id,
          })),
        })) || [],
    },
    resolver: updatePromotionResolver,
  });
  const [updatePromotionMutation] = useAdminUpdatePromotionMutation();
  const updatePromotion = useCallback(
    async (data: UpdatePromotionEventFormValues) => {
      await updatePromotionMutation({
        variables: {
          adminUpdatePromotionId: data.id,
          originalProductGroupId: data.productGroupId!,
          promotionData: {
            isDeliveryFee: data.isDeliveryFee,
            deliveryFee: data.deliveryFee,
            deliveryFeeBearer: data.deliveryFeeBearer,
            deliveryFeeSettlementPrice: data.deliveryFeeSettlementPrice,
            deliveryFeeSettlementTarget: data.deliveryFeeSettlementTarget,
            marketId: data.marketUndesignated ? undefined : data.marketId,
            promotionProducts: data.promotionProducts.map((product) =>
              product.isFreeProvided
                ? {
                    isFreeProvided: product.isFreeProvided,
                    promotionItems: product.promotionItems,
                    setCount: product.setCount,
                    customPromotionProductName:
                      product.customPromotionProductName,
                    originalProductId:
                      typeof product.originalProductId === 'number'
                        ? product.originalProductId
                        : null,
                  }
                : {
                    isFreeProvided: product.isFreeProvided,
                    promotionItems: product.promotionItems,
                    setCount: product.setCount,
                    customPromotionProductName:
                      product.customPromotionProductName,
                    originalProductId:
                      typeof product.originalProductId === 'number'
                        ? product.originalProductId
                        : null,
                    inHouseBuyPrice: product.inHouseBuyPrice,
                    isSellerBearing: product.isSellerBearing,
                    sellerBearingCost: product.sellerBearingCost,
                    isPrepayment: product.isPrepayment,
                    isWiredBearing: product.isWiredBearing,
                    wiredBearingCost: product.wiredBearingCost,
                  }
            ),
          },
        },
        refetchQueries: [AdminPromotionReleaseDocument],
        onCompleted() {
          void message.success('저장이 완료되었습니다.');
          onClose();
        },
        onError() {
          void message.error('저장을 실패하였습니다.');
        },
      });
    },
    [message, onClose, updatePromotionMutation]
  );
  const onSubmit = methods.handleSubmit((data) => {
    if (hasOrderConfirm) {
      void modal.confirm({
        title: '정보 변경',
        content:
          '발주확인 상태입니다. 물류팀 확인 후 정보 변경을 진행해 주세요.',
        onOk: () => updatePromotion(data),
        okText: '확인했습니다',
        cancelText: '취소',
      });
      return;
    }
    void modal.confirm({
      title: '상세정보 일괄수정',
      content:
        '수령자가 여러 명일 경우, 일괄로 수정사항이 적용됩니다. 상세정보를 수정하시겠습니까?',
      onOk: () => updatePromotion(data),
      okText: '네',
      cancelText: '아니오',
    });
  }, onInvalid);

  return { methods, onSubmit };
}

export default useUpdatePromotion;
