import { App, Spin } from 'antd';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CreateProductGroupForm from '../domain/product/components/CreateProductGroupForm';
import { useLocalProductOptionMutation } from '../domain/product/graphql/localProductOptions';
import { useTemporaryProductLazyQuery } from '../domain/product/graphql/productGroup.generated';
import { CreateProductGroupFormValues } from '../domain/product/types';
import { ROUTES } from '../routes/const';

export default function ProductNew() {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [temporaryValues, setTemporaryValues] = useState<
    CreateProductGroupFormValues | undefined
  >();
  const { setLocalProductOptions } = useLocalProductOptionMutation();
  const location = useLocation();
  const params = qs.parse(location.search.slice(1)) as { temporaryId: string };
  const [fetch] = useTemporaryProductLazyQuery();

  const setProductOptions = (values: CreateProductGroupFormValues) => {
    const productGroupName = values.name;

    const options = values.products.reduce(
      (acc, cur) => {
        cur.items?.forEach((item) => {
          if (!acc[item.code]) {
            acc[item.code] = {
              name: item.name,
              code: item.code,
              productGroupNames: [productGroupName],
            };
          }
        });

        return acc;
      },
      {} as {
        [key: string]: {
          name: string;
          code: string;
          productGroupNames: string[];
        };
      }
    );

    const productOptions = Object.values(options);
    setLocalProductOptions(productOptions);
  };

  const fetchTemporary = async () => {
    const { data } = await fetch({
      variables: { adminTemporaryProductId: Number(params.temporaryId) },
      onError: () => {
        message.error('임시저장된 상품을 불러오는데 실패했습니다.');
        navigate(ROUTES.PRODUCTS.path);
      },
    });

    if (data?.adminTemporaryProduct.json) {
      const values = data?.adminTemporaryProduct.json
        ? (JSON.parse(
            data.adminTemporaryProduct.json
          ) as CreateProductGroupFormValues)
        : undefined;

      if (values) {
        setProductOptions(values);
        setTemporaryValues(values);
      }
    }
  };

  useEffect(() => {
    if (params.temporaryId) fetchTemporary();
  }, [params.temporaryId]);

  if (params.temporaryId && !temporaryValues)
    return (
      <div className='flex h-[calc(100vh-171px)] w-full items-center justify-center bg-white'>
        <Spin />
      </div>
    );

  return <CreateProductGroupForm defaultValues={temporaryValues} />;
}
