import { Tabs } from 'antd';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { ESalesChannelType } from '../../../schema/types';
import { ClaimListSearchParams } from '../type';

const TAB_ITEMS: {
  key: ESalesChannelType;
  label: string;
  disabled?: boolean;
}[] = [
  { key: 'KEMI', label: '케미' },
  { key: 'SELLER_SHOP', label: '셀러쇼핑몰' },
  { key: 'PB_SHOP', label: 'PB몰' },
];

function SalesChannelTab({
  onChange,
}: {
  onChange: (key: 'KEMI' | 'SELLER_SHOP' | 'PB_SHOP') => void;
}) {
  const { searchParams } = useRouteSearchParams<ClaimListSearchParams>();
  return (
    <Tabs
      items={TAB_ITEMS}
      destroyInactiveTabPane
      activeKey={searchParams.salesChannelType || 'KEMI'}
      onChange={(v) => onChange(v as 'KEMI' | 'SELLER_SHOP' | 'PB_SHOP')}
    />
  );
}

export default SalesChannelTab;
