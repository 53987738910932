import { DownOutlined } from '@ant-design/icons';
import {
  App,
  Dropdown,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { uniq } from 'lodash-es';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { HistoriesDocument } from '../../../common/graphql/history.generated';
import { usePermissions } from '../../../common/hooks/usePermissions';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { PatchMarketDataGraphqlType } from '../../../schema/types';
import { APP_HEADER_HEIGHT } from '../../../theme/const';
import {
  EVENT_TYPES,
  MARKET_STATUS_MUTATION_SUCCESS_MESSAGES,
  SELLING_CHANNELS,
} from '../const';
import { useAdminPatchMarketMutation } from '../graphql/market.generated';
import { AdminMarketsQuery } from '../graphql/markets.generated';
import { getExpectedSettlementDate, getMarketStatusByRange } from '../helper';
import { MarketListSearchParams } from '../types';

import EditableMarketRangePickerCell from './EditableMarketRangePickerCell';
import EditableSettlementDateCell from './EditableSettlementDateCell';
import EditableStatusSelectCell from './EditableStatusSelectCell';
import MarketPreviewModal from './MarketPreviewModal';

type MarketsList = AdminMarketsQuery['adminMarkets']['edges'];
type MarketsTableProps = {
  data: MarketsList;
  editable: boolean;
  loading: boolean;
  onSearch: (params: MarketListSearchParams) => void;
};

function MarketsTable({ data, loading, onSearch }: MarketsTableProps) {
  const { message } = App.useApp();
  const { hasPermission: editable } = usePermissions('MARKET_EDIT');
  const { searchParams } = useRouteSearchParams<MarketListSearchParams>();
  const [mutate] = useAdminPatchMarketMutation();
  const [openedMarketId, setOpenedMarketId] = useState<null | number>(null);

  const handleChange = async (
    id: number,
    data: PatchMarketDataGraphqlType,
    messages: { success: string; error: string }
  ) => {
    // const messages = PATCH_MARKET_DATA_MESSAGES[messageKey];

    await mutate({
      variables: { patchMarketInput: { id, data } },
      onError() {
        messages?.error && message.error(messages.error);
      },
      onCompleted() {
        messages?.success && message.success(messages.success);
      },
      refetchQueries: [
        {
          query: HistoriesDocument,
          variables: {
            historiesInput: {
              originId: id,
              uiType: 'MARKET',
            },
          },
        },
      ],
    });
  };
  const handleSort = (sorter: SorterResult<MarketsList['0']>) => {
    switch (sorter.order) {
      case 'ascend':
        onSearch({ sortingField: 'sellerGrade', sortingOrder: 'ASC' });
        break;
      case 'descend':
        onSearch({ sortingField: 'sellerGrade', sortingOrder: 'DESC' });
        break;
      default:
        onSearch({ sortingField: undefined, sortingOrder: undefined });
    }
  };

  const columns = useMemo<ColumnsType<MarketsList['0']>>(() => {
    return [
      {
        title: '마켓기간',
        width: 280,
        key: 'range',
        fixed: 'left',
        render: (_, record) => (
          <EditableMarketRangePickerCell
            editable={editable}
            initialValue={[record.node.startedAt, record.node.endedAt || null]}
            onSubmit={(value) => {
              const [startedAt, endedAt] = value;
              const expectedSettlementAt = getExpectedSettlementDate(endedAt);
              const status = getMarketStatusByRange(
                startedAt,
                endedAt,
                record.node.status
              );

              return handleChange(
                record.node.id,
                {
                  startedAt,
                  endedAt,
                  expectedSettlementAt,
                  status,
                },
                {
                  success: '마켓기간이 업데이트되었습니다.',
                  error: '마켓기간 업데이트를 실패하였습니다.',
                }
              );
            }}
          />
        ),
      },
      {
        title: '마켓명',
        key: 'name',
        width: 240,
        fixed: 'left',
        render(_, record) {
          return (
            <Tooltip
              className={'break-all'}
              rootClassName={'break-all'}
              title={record.node.name}
            >
              <div className={'flex items-center'}>
                <div>
                  {record.node.isSoldOut && <Tag color={'error'}>품절</Tag>}
                </div>
                <Link to={`/markets/${record.node.id}`} state={searchParams}>
                  <span className={'line-clamp-2'}>{record.node.name}</span>
                </Link>
              </div>
            </Tooltip>
          );
        },
      },
      {
        title: '마켓상태',
        key: 'status',
        width: 160,
        fixed: 'left',
        render: (_, record) => {
          return (
            <EditableStatusSelectCell
              initialValue={record.node.status}
              startedAt={record.node.startedAt}
              endedAt={record.node.endedAt || null}
              onSubmit={(status) => {
                const success = MARKET_STATUS_MUTATION_SUCCESS_MESSAGES[status];
                const error = '마켓상태 업데이트를 실패하였습니다.';

                return handleChange(
                  record.node.id,
                  {
                    status,
                  },
                  { success, error }
                );
              }}
            />
          );
        },
      },
      {
        title: '체크리스트',
        key: 'checklist',
        width: 125,
        render(_, record) {
          return (
            <a
              onClick={(e) => {
                e.preventDefault();
                setOpenedMarketId(record.node.id);
              }}
            >
              체크리스트
            </a>
          );
        },
      },
      {
        title: '판매채널',
        key: 'sellingChannel',
        width: 191,
        ellipsis: true,
        render: (_, record) => {
          const status = SELLING_CHANNELS.find(
            ({ value }) => value === record.node.sellingChannel
          );
          const etcText = record.node.etcSellingChannel;

          return record.node.sellingChannel === 'ETC' ? (
            <Tooltip
              title={`${status?.label} - ${etcText || ''}`}
            >{`${status?.label} - ${etcText || ''}`}</Tooltip>
          ) : (
            status?.label
          );
        },
      },
      {
        title: '상품담당자',
        key: 'productGroups.manager',
        width: 120,
        ellipsis: true,
        render: (_, record) => {
          const managerNames = uniq(
            record.node.productGroups.map((group) => group.manager.name)
          );
          return managerNames.join(', ') || '-';
        },
      },
      {
        title: '마켓담당자',
        key: 'manager',
        width: 120,
        render: (_, record) => record.node.manager.name,
      },
      {
        title: (
          <span>
            예상매출액
            <Typography.Text type={'secondary'}>(백만)</Typography.Text>
          </span>
        ),
        key: 'expectedSales',
        width: 129,
        render(_, record) {
          return record.node.expectedSales
            ? //백만(10,000,000) 단위로 끊기
              (record.node.expectedSales / 1000000).toLocaleString()
            : '-';
        },
      },
      {
        title: '이벤트',
        key: 'event',
        width: 240,
        render(_, record) {
          const events = record.node.events?.map((event) => {
            const eventType = event.type;
            const type = EVENT_TYPES.find(({ value }) => value === eventType);
            const count = event.count
              ? `${event.count.toLocaleString()}명`
              : '';
            const isNotationRequired = event.isNotationRequired
              ? '(발주서 표기 필요)'
              : '';
            const comment = event.comment ? `/ ${event.comment}` : '';

            if (!type) return;

            return `${type.label} ${count} ${isNotationRequired} ${comment}`;
          });

          return (
            <Tooltip placement='topLeft' title={events?.join(', ')}>
              <span className={'line-clamp-1'}>
                {events?.join(', ') || '-'}
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: '마켓등록일',
        key: 'createdAt',
        width: 120,
        dataIndex: 'createdAt',
        render(_, record) {
          return dayjs(record.node.createdAt).format('YYYY-MM-DD');
        },
      },
      {
        title: '정산예정일',
        key: 'settledAt',
        width: 160,
        dataIndex: 'settledAt',
        render(_, record) {
          return (
            <EditableSettlementDateCell
              initialValue={record.node.expectedSettlementAt || null}
              onSubmit={(expectedSettlementAt) => {
                return handleChange(
                  record.node.id,
                  {
                    expectedSettlementAt,
                  },
                  {
                    success: '정산예정일이 업데이트되었습니다.',
                    error: '정산예정일 업데이트를 실패하였습니다.',
                  }
                );
              }}
            />
          );
        },
      },
      {
        title: '셀러명',
        key: 'seller.name',
        width: 240,
        render(_, record) {
          return record.node.seller?.name || '-';
        },
      },
      {
        title: '판매유형',
        key: 'sellerSettlementInfo.type',
        width: 120,
        render: (_, record) => {
          if (record.node.sellerSettlementInfo?.type === 'BUSINESS') {
            return '사업자';
          }
          if (record.node.sellerSettlementInfo?.type === 'FREELANCER') {
            return '프리랜서';
          }
          return '-';
        },
      },
      {
        title: '셀러링크',
        key: 'sellerLinks',
        width: 120,
        render: (_, record) => {
          if (!record.node.seller) {
            return '-';
          }

          const defaultLink = record.node.seller?.links.find(
            (link) => link.isDefault
          );
          const items = record.node.seller?.links.map((link) => ({
            label: (
              <a
                // key={link.id}
                href={link.url}
                rel='noopener noreferer'
                target={'_blank'}
              >
                {link.url}
              </a>
            ),
            key: link.id,
          }));

          if (record.node.seller?.links.length === 1) {
            return (
              <a href={defaultLink?.url} rel='noreferer' target='_blank'>
                바로가기
              </a>
            );
          }

          return (
            <>
              <Dropdown
                menu={{ items }}
                trigger={['click']}
                dropdownRender={(menus) => {
                  return (
                    <div className={'max-w-[400px] break-all'}>{menus}</div>
                  );
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    바로가기
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </>
          );
        },
      },
      {
        title: '셀러등급',
        key: 'seller.grade',
        sorter: true,
        width: 120,
        render: (_, record) => record.node.seller?.grade || '-',
      },
      {
        title: '업체명',
        key: 'vendor.name',
        width: 240,
        render(_, record) {
          return (
            <Tooltip title={record.node.vendor.name}>
              <span className={'line-clamp-1'}>{record.node.vendor.name}</span>
            </Tooltip>
          );
        },
      },
      {
        title: '브랜드명',
        key: 'brand.name',
        width: 240,
        render(_, record) {
          return (
            <Tooltip title={record.node.brand.name}>
              <span className={'line-clamp-1'}>{record.node.brand.name}</span>
            </Tooltip>
          );
        },
      },
      {
        title: '상품명',
        key: 'products',
        width: 240,
        render(_, record) {
          const productNames =
            record.node.productGroups.map(({ name }) => name).join(', ') || '-';
          return (
            <Tooltip title={productNames}>
              <span className={'line-clamp-1'}>{productNames}</span>
            </Tooltip>
          );
        },
      },
      {
        title: '비고',
        key: 'comment',
        width: 240,
        render(_, record) {
          if (!record.node.comment) {
            return '-';
          }

          return (
            <Tooltip placement='topLeft' title={record.node.comment}>
              <span className={'line-clamp-1'}>{record.node.comment}</span>
            </Tooltip>
          );
        },
      },
    ];
  }, [editable, searchParams]);

  return (
    <>
      <Spin tip='Loading...' spinning={loading}>
        <Table
          sticky={{
            offsetHeader: APP_HEADER_HEIGHT,
          }}
          size={'small'}
          className={'mt-1'}
          columns={columns}
          dataSource={data || []}
          scroll={{ x: 3500 }}
          onRow={() => ({
            className:
              '[&_.editable-cell]:border-[1px] [&_.editable-cell]:border-solid [&_.editable-cell]:border-transparent hover:[&_.editable-cell]:border-[1px] hover:[&_.editable-cell]:border-solid hover:[&_.editable-cell]:border-gray-400',
          })}
          pagination={false}
          rowKey={(v) => v.node.id}
          onChange={(_, __, sorter) => {
            handleSort(sorter as SorterResult<MarketsList['0']>);
          }}
        />
      </Spin>
      {openedMarketId && (
        <MarketPreviewModal
          open={!!openedMarketId}
          onCancel={() => setOpenedMarketId(null)}
          marketId={openedMarketId}
        />
      )}
    </>
  );
}

export default MarketsTable;
