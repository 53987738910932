import { Checkbox, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DefaultOptionType } from 'antd/es/select';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import DeliveryCompanySelectField from '../../../../common/components/Fields/DeliveryCompanySelectField';
import Label from '../../../../common/components/Fields/Label';
import SelectField from '../../../../common/components/Fields/SelectField';
import TimeField from '../../../../common/components/Fields/TimeField';
import { RELEASE_TYPE } from '../../const';
import { useProductGroupsByBrandIdLazyQuery } from '../../graphql/productGroups.generated';
import { ProductLogisticsFormValues } from '../../types';

type LogisticsFieldProps = {
  disabled?: boolean;
};

export default function LogisticsField({ disabled }: LogisticsFieldProps) {
  const { control, watch, setValue, resetField } =
    useFormContext<ProductLogisticsFormValues>();
  const [isGetBrand, setIsGetBrand] = useState(true);
  const [
    showBrandBeforeProductDeliveryInfo,
    setShowBrandBeforeProductDeliveryInfo,
  ] = useState(false);
  const brandName = watch('brandName');
  const brandId = watch('brandId');
  const releaseType = watch('releaseType');
  const formType = watch('formType');

  const [fetchProductsGroupsByBrandId] = useProductGroupsByBrandIdLazyQuery({
    variables: {
      filter: { brandId },
      pagination: {
        skip: 0,
        take: 1,
      },
    },
  });

  const fetchBrandBeforeProductDeliveryInfo = async () => {
    const { data } = await fetchProductsGroupsByBrandId();

    if (!data?.adminProductGroups?.edges[0]) {
      setShowBrandBeforeProductDeliveryInfo(false);
      setIsGetBrand(false);
      return;
    }

    setShowBrandBeforeProductDeliveryInfo(true);

    const {
      deliveryCompanyCode,
      deliveryCompanyName,
      releaseType,
      releaseTime,
    } = data.adminProductGroups.edges[0].node;

    deliveryCompanyName && setValue('deliveryCompanyName', deliveryCompanyName);
    deliveryCompanyCode && setValue('deliveryCompanyCode', deliveryCompanyCode);
    setValue('releaseType', releaseType);
    releaseTime && setValue('releaseTime', releaseTime);
  };

  const resetBrandBeforeProductDeliveryInfo = useCallback(() => {
    resetField('deliveryCompanyCode');
    resetField('deliveryCompanyName');
    resetField('releaseType');
    resetField('releaseTime');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formType === 'UPDATE') return;
    if (brandId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchBrandBeforeProductDeliveryInfo();
      setIsGetBrand(true);
    } else {
      resetBrandBeforeProductDeliveryInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId]);

  const handleChange = async (e: CheckboxChangeEvent) => {
    setIsGetBrand(e.target.checked);
    if (e.target.checked) {
      await fetchBrandBeforeProductDeliveryInfo();
    } else {
      resetBrandBeforeProductDeliveryInfo();
    }
  };

  return (
    <div>
      <div className='mb-md flex'>
        <Label required={!disabled} className='mr-1 whitespace-nowrap'>
          물류
        </Label>
        {formType === 'CREATE' &&
          showBrandBeforeProductDeliveryInfo &&
          brandId && (
            <Checkbox
              checked={isGetBrand}
              onChange={handleChange}
              disabled={disabled}
              className='whitespace-nowrap'
            >
              {brandName} 브랜드 직전 상품 내용 불러오기
            </Checkbox>
          )}
      </div>

      <div className='flex items-center gap-2'>
        <div className='flex items-baseline gap-2'>
          <div>
            <DeliveryCompanySelectField
              className='w-[152px]'
              control={control}
              name='deliveryCompanyCode'
              placeholder={'선택'}
              onChange={(_, option) => {
                const label = (option as DefaultOptionType).label as string;
                setValue('deliveryCompanyName', label);
              }}
              disabled={disabled}
              hideErrorMessage
            />
          </div>
          <div className='w-[152px]'>
            <SelectField
              control={control}
              options={RELEASE_TYPE}
              placeholder={'선택'}
              name='releaseType'
              disabled={disabled}
              hideErrorMessage
            />
          </div>
          {releaseType === 'TODAY' && (
            <div className='flex w-[152px] items-center gap-2'>
              <TimeField
                className='w-[152px]'
                control={control}
                name='releaseTime'
                format={'HH:mm'}
                showNow={false}
                changeOnBlur
                disabled={disabled}
                hideErrorMessage
              />
              <Typography.Text className='whitespace-nowrap'>
                이전 당일출고
              </Typography.Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
