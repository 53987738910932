import { Popover, Typography } from 'antd';
import dayjs from 'dayjs';
import { PropsWithChildren } from 'react';

type Market = {
  name: string;
  startedAt: string;
  endedAt: string | null | undefined;
};

type MarketListPopoverProps = {
  markets: Market[];
} & PropsWithChildren;

export default function MarketListPopover({
  markets,
  children,
}: MarketListPopoverProps) {
  const rootClassName = markets && markets.length > 0 ? '' : 'hidden';

  return (
    <Popover
      content={<MarketListPopoverContent markets={markets ?? []} />}
      placement={'rightBottom'}
      arrow={false}
      align={{ points: ['tl', 'cl'] }}
      rootClassName={rootClassName}
    >
      {children}
    </Popover>
  );
}

type MarketListPopoverContentProps = {
  markets: Market[];
};

const MarketListPopoverContent = ({
  markets,
}: MarketListPopoverContentProps) => {
  return (
    <div className='w-[496px] p-[18px]'>
      <Typography.Title level={5} className='mb-0'>
        참조된 마켓
      </Typography.Title>
      <Typography.Text className='text-xs'>
        마켓에 참조되어 있는 품목은 수정 및 삭제가 불가능합니다.
      </Typography.Text>
      <div className='mt-2 flex flex-col gap-2'>
        {markets.map(({ name, startedAt, endedAt }, index) => (
          <div key={index} className='flex justify-between gap-2'>
            <Typography.Text className='flex-1 text-sm'>{name}</Typography.Text>
            <Typography.Text className='w-[130px] text-right text-sm'>
              {dayjs(startedAt).format('YY.MM.DD')}
              {endedAt ? ` ~ ${dayjs(endedAt).format('YY.MM.DD')}` : ''}
            </Typography.Text>
          </div>
        ))}
      </div>
    </div>
  );
};
