import { Divider } from 'antd';
import { useFormContext } from 'react-hook-form';

import ArrayField from '../../../../common/components/Fields/ArrayField';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { MARKET_EVENTS_MAX_LENGTH } from '../../const';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../../types';

import EventItemField from './EventItemField';

const defaultData: Exclude<UpdateMarketFormValues['events'], undefined>['0'] = {
  isExtraDeliveryCharged: false,
  isNotationRequired: false,
  products: [],
  type: 'FIRST_SERVED',
  count: null,
  comment: null,
  shippingBearer: null,
  shippingCost: null,
  editable: true,
  deletable: true,
};

function MarketEventsField() {
  const { hasPermission } = usePermissions('MARKET_EDIT');

  const { control, watch } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();

  const events = watch('events') || [];

  return (
    <>
      {events?.length === 0 && (
        <Divider orientation='left' className={'m-0'}>
          이벤트 정보
        </Divider>
      )}

      <ArrayField
        name={'events'}
        control={control}
        maxLength={MARKET_EVENTS_MAX_LENGTH}
        disabled={!hasPermission}
        addButtonProps={{
          type: 'primary',
          children: '이벤트 추가',
          onClick({ append }) {
            append(defaultData);
          },
        }}
      >
        {({ fields, remove }) => {
          return (
            <ul>
              {fields.map((_, i) => {
                const event = events[i];

                return (
                  <EventItemField
                    key={i}
                    event={event}
                    index={i}
                    onRemove={() => remove(i)}
                  />
                );
              })}
            </ul>
          );
        }}
      </ArrayField>
    </>
  );
}

export default MarketEventsField;
