import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import ProductSelect from '../../../../common/components/Fields/ProductSelect';
import { useAdminProductInSettlementListLazyQuery } from '../../graphql/product.generated';

export type SelectFieldProps = SelectProps<number> & {
  initialLoad?: boolean;
};

const SettlementProductSelect = forwardRef<BaseSelectRef, SelectFieldProps>(
  function ({ initialLoad, ...props }, ref) {
    const [initialOption, setInitialOption] = useState<
      null | undefined | { value: number; label: string }
    >(null);

    const [fetch] = useAdminProductInSettlementListLazyQuery();
    const fetchProduct = useCallback(async () => {
      if (!props.value) {
        setInitialOption(undefined);
        return;
      }

      const { data } = await fetch({
        variables: {
          adminProductId: props.value,
        },
      });

      if (data?.adminProduct) {
        setInitialOption({
          value: data.adminProduct.id,
          label: data.adminProduct.name,
        });
      }
    }, [fetch, props.value]);

    useEffect(() => {
      fetchProduct();
    }, []);

    if (initialOption === null) return <Select className={props.className} />;

    return (
      <ProductSelect
        ref={ref}
        initialLoad={initialLoad}
        className={'w-full'}
        initialOption={initialOption}
        {...props}
      />
    );
  }
);

export default SettlementProductSelect;
