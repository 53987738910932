import { Typography } from 'antd';
import { FieldValues, useFormContext } from 'react-hook-form';

import NumberField, {
  NumberFieldProps,
} from '../../../common/components/Fields/NumberField';

function NumberWithUnknownField<TFieldValues extends FieldValues>({
  onUnknownClick,
  ...props
}: NumberFieldProps<TFieldValues> & {
  onUnknownClick: () => void;
}) {
  const { watch } = useFormContext();

  const hasValue = !!watch(props.name);

  return (
    <NumberField
      placeholder={'금액입력'}
      numberType={'price'}
      className={
        'w-full [&_.ant-input-number-input-wrap]:text-[12px] [&_.ant-input-number-prefix]:text-[12px]'
      }
      addonAfter={
        <Typography.Text
          type={hasValue ? 'secondary' : undefined}
          className={`cursor-pointer text-[12px] ${
            hasValue ? '' : 'text-primary'
          }`}
          onClick={onUnknownClick}
        >
          모름
        </Typography.Text>
      }
      {...props}
    />
  );
}

export default NumberWithUnknownField;
