import { Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { valueType } from 'antd/es/statistic/utils';
import type { ColumnsType } from 'antd/es/table';
import { useState, useEffect, useCallback, useMemo } from 'react';

import {
  ProductRelationStockCell,
  ProductRelationSetCountCell,
} from '../components/ProductRelationEditableCell';
import { useAdminProductRelationsQuery } from '../graphql/productRelations.generated';

export type ProductRelationTableDataType = {
  key: React.Key;
  id: number;
  productId: number;
  productCode: string;
  productName: string;
  itemId: number;
  itemCode: string;
  itemName: string;
  stock: number;
  isInfinityStock: boolean;
  setCount: number;
  isLinkedItem: boolean;
  linkedProductGroups?: { id: number; name: string }[];
  handleStockChange: (value: valueType | null) => void;
  handleSetCountChange: (value: valueType | null) => void;
  handleInfinity: (ev: CheckboxChangeEvent) => void;
};

function useProductRelationsTable(productGroupId: number) {
  const { data, loading } = useAdminProductRelationsQuery({
    variables: { productGroupId },
  });

  const [editItemId, setEditItemId] = useState<number | null>(null);
  const [dataSources, setDataSources] = useState<
    ProductRelationTableDataType[]
  >([]);

  const highlightRow = useCallback((itemId: number) => {
    setEditItemId(itemId);
  }, []);
  const resetHighlightRow = useCallback(() => setEditItemId(null), []);

  useEffect(() => {
    if (!loading) {
      const mappeddataSource: ProductRelationTableDataType[] =
        data?.adminProductRelations.edges.map(({ node }, i) => ({
          key: i,
          id: node.id,
          productId: node.product.id,
          productCode: node.product.code,
          productName: node.product.name,
          itemId: node.item.id,
          itemCode: node.item.code,
          itemName: node.item.name,
          stock: node.item.stock || 0,
          isInfinityStock: node.item.isInfinityStock,
          setCount: node.setCount,
          isLinkedItem: node.isLinkedItem,
          linkedProductGroups: node.linkedProductGroups?.map(
            ({ id, name }) => ({ id, name })
          ),
          handleInfinity: (ev) => {
            const isInfinityStock = ev.target.checked;
            setDataSources((prev) =>
              prev.map((prevItem) =>
                prevItem.itemId === node.item.id
                  ? {
                      ...prevItem,
                      isInfinityStock,
                      stock: isInfinityStock ? 0 : prevItem.stock,
                    }
                  : { ...prevItem }
              )
            );
          },
          handleStockChange: (stock: valueType | null) => {
            if (typeof stock === 'number') {
              setDataSources((prev) =>
                prev.map((prevItem) =>
                  prevItem.itemId === node.item.id
                    ? { ...prevItem, stock }
                    : { ...prevItem }
                )
              );
            }
          },
          handleSetCountChange: (setCount: valueType | null) => {
            if (typeof setCount === 'number') {
              setDataSources((prev) =>
                prev.map((prevItem) =>
                  prevItem.id === node.id
                    ? { ...prevItem, setCount }
                    : { ...prevItem }
                )
              );
            }
          },
        })) || [];
      setDataSources(mappeddataSource);
    }
  }, [data, loading]);

  const onCell = useCallback(
    (record: ProductRelationTableDataType) =>
      record.itemId === editItemId ? { className: 'bg-[#e6f4ff]' } : {},
    [editItemId]
  );

  const columns: ColumnsType<ProductRelationTableDataType> = useMemo(
    () => [
      {
        title: '품목코드 (품목ID)',
        dataIndex: 'productCode',
        width: 160,
        onCell,
        render: (_, record) => (
          <div>
            <Typography.Text
              copyable={{
                text: record.productCode,
                icon: [
                  <Typography.Text>{record.productCode}</Typography.Text>,
                  <Typography.Text>{record.productCode}</Typography.Text>,
                ],
              }}
            />
            <Typography.Text
              copyable={{
                text: record.productId.toString(),
                icon: [
                  <Typography.Text className='text-gray-400'>
                    ({record.productId})
                  </Typography.Text>,
                  <Typography.Text className='text-gray-400'>
                    ({record.productId})
                  </Typography.Text>,
                ],
              }}
            />
          </div>
        ),
      },
      {
        title: '품목명',
        dataIndex: 'productName',
        width: 240,
        onCell,
        render: (value) => (
          <Typography.Text className='line-clamp-2'>{value}</Typography.Text>
        ),
      },
      {
        title: '옵션코드 (옵션ID)',
        dataIndex: 'itemCode',
        width: 160,
        onCell,
        render: (_, record) => (
          <div>
            <Typography.Text
              copyable={{
                text: record.itemCode,
                icon: [
                  <Typography.Text>{record.itemCode}</Typography.Text>,
                  <Typography.Text>{record.itemCode}</Typography.Text>,
                ],
              }}
            />
            <Typography.Text
              copyable={{
                text: record.itemId.toString(),
                icon: [
                  <Typography.Text className='text-gray-400'>
                    ({record.itemId})
                  </Typography.Text>,
                  <Typography.Text className='text-gray-400'>
                    ({record.itemId})
                  </Typography.Text>,
                ],
              }}
            />
          </div>
        ),
      },
      {
        title: '옵션명',
        dataIndex: 'itemName',
        width: 240,
        onCell,
        render: (value) => (
          <Typography.Text className='line-clamp-2'>{value}</Typography.Text>
        ),
      },
      {
        title: '재고',
        dataIndex: 'stock',
        width: 160,
        onCell,
        render: (_, record) => (
          <ProductRelationStockCell
            record={record}
            productGroupId={productGroupId}
            highlight={highlightRow}
            resetHighlight={resetHighlightRow}
          />
        ),
      },
      {
        title: '판매단위',
        dataIndex: 'setCount',
        width: 160,
        onCell,
        render: (_, record) => <ProductRelationSetCountCell record={record} />,
      },
    ],
    [highlightRow, onCell, productGroupId, resetHighlightRow]
  );

  return { dataSources, columns, loading };
}

export default useProductRelationsTable;
