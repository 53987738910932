import { App } from 'antd';
import { GraphQLError } from 'graphql';

import { hasDetailErrorCode } from '../../../common/utils/apollo';
import { SettlementSheetInputGraphqlType } from '../../../schema/types';
import { AdminCountMarketsForScheduledSettlementDocument } from '../graphql/markets.generated';
import { useAdminCreateSettlementSheetMutation } from '../graphql/settlement.generated';
import { AdminSettlementsDocument } from '../graphql/settlements.generated';
import { AdminCountVendorsForScheduledSettlementDocument } from '../graphql/vendors.generated';

function useCreateSettlementSheet() {
  const { message } = App.useApp();

  const [mutateAdminCreateSettlementSheet] =
    useAdminCreateSettlementSheetMutation();

  const createSettlementSheet = async (
    input: SettlementSheetInputGraphqlType
  ) => {
    await mutateAdminCreateSettlementSheet({
      variables: {
        input,
      },
      refetchQueries: [
        AdminSettlementsDocument,
        AdminCountMarketsForScheduledSettlementDocument,
        AdminCountVendorsForScheduledSettlementDocument,
      ],
      onError({ graphQLErrors, message: errorMessage }) {
        const hasSettlementSheetError = hasDetailErrorCode(
          graphQLErrors,
          'SETTLEMENT_SHEET_ALREADY_EXIST'
        );

        if (hasSettlementSheetError) {
          showSettlementSheetErrorModal(hasSettlementSheetError);
          return;
        }

        const hasUnknownAmountSettlementError = hasDetailErrorCode(
          graphQLErrors,
          'UNKNOWN_AMOUNT_SETTLEMENT_EXIST'
        );

        if (hasUnknownAmountSettlementError) {
          showSettlementSheetErrorModal(hasUnknownAmountSettlementError);
          return;
        }

        if (errorMessage) {
          void message.error(errorMessage);
          return;
        }

        void message.error('정산서 생성에 실패했습니다.');
      },
      onCompleted() {
        const successMessage =
          '정산서가 생성되었습니다. 정산서관리에서 확인해주세요.';
        void message.success(successMessage);
      },
    });
  };

  const showSettlementSheetErrorModal = (error: GraphQLError) => {
    const { extensions, message: errorMessage } = error;
    const orderCodes = (extensions?.orderCodes as number[]) || [];

    let orderCodesText = orderCodes.slice(0, 3).join(', ');

    if (orderCodes.length > 3) {
      orderCodesText += ` 외 ${orderCodes.length - 3}건`;
    }

    void message.error({
      content: (
        <div className='inline-flex flex-col items-start'>
          <p>{errorMessage}</p>
          {orderCodes.length && (
            <p className='text-[#00000073]'>{orderCodesText}</p>
          )}
        </div>
      ),
    });
  };

  return {
    createSettlementSheet,
  };
}

export default useCreateSettlementSheet;
