import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminSettlementSheetByCodeQueryVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type AdminSettlementSheetByCodeQuery = { __typename?: 'Query', adminSettlementSheetByCode?: { __typename?: 'SettlementSheetGraphqlType', id: number, code: string, marketName?: string | null, vendorName?: string | null, settlementSheetType: Types.ESettlementSheetType } | null };

export type AdminCountSettlementSheetStatusesQueryVariables = Types.Exact<{
  filter: Types.SettlementSheetByStatusFilterQuery;
}>;


export type AdminCountSettlementSheetStatusesQuery = { __typename?: 'Query', adminCountSettlementSheetStatuses: Array<{ __typename?: 'CountSettlementSheetStatusesGraphqlType', count: number, status: string }> };

export type AdminSettlementSheetsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SettlementSheetFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminSettlementSheetsQuery = { __typename?: 'Query', adminSettlementSheets?: { __typename?: 'SettlementSheetPageGraphqlType', totalCount: number, edges: Array<{ __typename?: 'PaginatedSettlementSheetGraphqlTypeEdge', node: { __typename?: 'SettlementSheetGraphqlType', id: number, code: string, settlementSheetType: Types.ESettlementSheetType, marketName?: string | null, marketStartedAt?: string | null, marketEndedAt?: string | null, salesChannel?: Types.ESellingChannel | null, expectedSettlementAt?: string | null, sellerSettlementType?: Types.ESellerSettlementType | null, status: Types.ESettlementSheetStatus, salesAmount: number, settlementAmount: number, paymentFee: number, sampleAmount: number, eventAmount: number, etcAmount: number, refundDeliveryFeeAmount: number, exchangeDeliveryFeeAmount: number, withholdingTax?: number | null, finalSettlementAmount: number, adjustmentAmount?: number | null, isTaxInvoiceIssued: boolean, taxInvoiceIssuedAt?: string | null, createdAt: string, settlementFinishedAt?: string | null, bank?: Types.EBankCode | null, accountNumber?: string | null, accountHolderName?: string | null, vendorName?: string | null, deliveryFee: number, finalSettlementAmountLastChangedAt?: string | null, finalSettlementAmountLastChangedOperatorName?: string | null, productSalesRecords?: Array<{ __typename?: 'SettlementSheetProductSalesRecord', supplyPrice?: number | null }> | null } }> } | null };

export type AdminSettlementSheetQueryVariables = Types.Exact<{
  adminSettlementSheetId: Types.Scalars['Int']['input'];
}>;


export type AdminSettlementSheetQuery = { __typename?: 'Query', adminSettlementSheet: { __typename?: 'SettlementSheetGraphqlType', code: string, settlementSheetType: Types.ESettlementSheetType, vendorName?: string | null, marketName?: string | null, marketStartedAt?: string | null, marketEndedAt?: string | null, salesChannel?: Types.ESellingChannel | null, sellerSettlementType?: Types.ESellerSettlementType | null, expectedSettlementAt?: string | null, salesAmount: number, settlementAmount: number, nonSalesSettlementAmount?: number | null, paymentFee: number, withholdingTax?: number | null, finalSettlementAmount: number, adjustmentAmount?: number | null, refundDeliveryFeeAmount: number, refundDeliveryFeeCount?: number | null, exchangeDeliveryFeeAmount: number, exchangeDeliveryFeeCount?: number | null, normalDeliveryFee?: number | null, normalDeliveryOrderCount?: number | null, regionDeliveryFee?: number | null, regionDeliveryOrderCount?: number | null, bank?: Types.EBankCode | null, accountNumber?: string | null, accountHolderName?: string | null, productSalesRecords?: Array<{ __typename?: 'SettlementSheetProductSalesRecord', productName: string, jointPurchasePrice: number, sellerMargin?: number | null, quantity: number, supplyPrice?: number | null, salesAmount?: number | null, settlementAmount?: number | null, totalSupplyPrice?: number | null }> | null, sampleRecords?: Array<{ __typename?: 'SettlementSheetSampleSalesRecords', productName: string, settlementAmount: number, count: number }> | null, eventRecords?: Array<{ __typename?: 'SettlementSheetEventSalesRecords', productName: string, settlementAmount: number, count: number }> | null, etcRecords?: Array<{ __typename?: 'SettlementSheetEtcSalesRecords', productName: string, settlementAmount: number, count: number }> | null, paymentMethodRecords?: Array<{ __typename?: 'SettlementSheetPaymentMethodSalesRecords', paymentMethod: Types.ESettlementPaymentMethod, paymentFeeAmount: number, count: number }> | null } };

export type AdminBulkDeleteSettlementSheetMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type AdminBulkDeleteSettlementSheetMutation = { __typename?: 'Mutation', adminBulkDeleteSettlementSheet: { __typename?: 'BulkSettlementOutputGraphqlType', failCount?: number | null, message: string, result: boolean, successCount?: number | null } };

export type AdminBulkPatchSettlementSheetStatusMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
  settlementSheetData: Types.BulkUpdateSettlementSheetStatusInputGraphqlType;
}>;


export type AdminBulkPatchSettlementSheetStatusMutation = { __typename?: 'Mutation', adminBulkPatchSettlementSheetStatus: { __typename?: 'BulkSettlementOutputGraphqlType', failCount?: number | null, message: string, result: boolean, successCount?: number | null } };

export type AdminPatchSettlementSheetMutationVariables = Types.Exact<{
  adminPatchSettlementSheetId: Types.Scalars['Int']['input'];
  input: Types.UpdateSettlementSheetInputGraphqlType;
}>;


export type AdminPatchSettlementSheetMutation = { __typename?: 'Mutation', adminPatchSettlementSheet: { __typename?: 'SettlementSheetOutputGraphqlType', settlementSheet: { __typename?: 'SettlementSheetGraphqlType', id: number, adjustmentAmount?: number | null, finalSettlementAmount: number, isTaxInvoiceIssued: boolean, taxInvoiceIssuedAt?: string | null } } };


export const AdminSettlementSheetByCodeDocument = gql`
    query AdminSettlementSheetByCode($code: String!) {
  adminSettlementSheetByCode(code: $code) {
    id
    code
    marketName
    vendorName
    settlementSheetType
  }
}
    `;

/**
 * __useAdminSettlementSheetByCodeQuery__
 *
 * To run a query within a React component, call `useAdminSettlementSheetByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSettlementSheetByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSettlementSheetByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAdminSettlementSheetByCodeQuery(baseOptions: Apollo.QueryHookOptions<AdminSettlementSheetByCodeQuery, AdminSettlementSheetByCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSettlementSheetByCodeQuery, AdminSettlementSheetByCodeQueryVariables>(AdminSettlementSheetByCodeDocument, options);
      }
export function useAdminSettlementSheetByCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSettlementSheetByCodeQuery, AdminSettlementSheetByCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSettlementSheetByCodeQuery, AdminSettlementSheetByCodeQueryVariables>(AdminSettlementSheetByCodeDocument, options);
        }
export type AdminSettlementSheetByCodeQueryHookResult = ReturnType<typeof useAdminSettlementSheetByCodeQuery>;
export type AdminSettlementSheetByCodeLazyQueryHookResult = ReturnType<typeof useAdminSettlementSheetByCodeLazyQuery>;
export type AdminSettlementSheetByCodeQueryResult = Apollo.QueryResult<AdminSettlementSheetByCodeQuery, AdminSettlementSheetByCodeQueryVariables>;
export const AdminCountSettlementSheetStatusesDocument = gql`
    query AdminCountSettlementSheetStatuses($filter: SettlementSheetByStatusFilterQuery!) {
  adminCountSettlementSheetStatuses(filter: $filter) {
    count
    status
  }
}
    `;

/**
 * __useAdminCountSettlementSheetStatusesQuery__
 *
 * To run a query within a React component, call `useAdminCountSettlementSheetStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountSettlementSheetStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountSettlementSheetStatusesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminCountSettlementSheetStatusesQuery(baseOptions: Apollo.QueryHookOptions<AdminCountSettlementSheetStatusesQuery, AdminCountSettlementSheetStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCountSettlementSheetStatusesQuery, AdminCountSettlementSheetStatusesQueryVariables>(AdminCountSettlementSheetStatusesDocument, options);
      }
export function useAdminCountSettlementSheetStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCountSettlementSheetStatusesQuery, AdminCountSettlementSheetStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCountSettlementSheetStatusesQuery, AdminCountSettlementSheetStatusesQueryVariables>(AdminCountSettlementSheetStatusesDocument, options);
        }
export type AdminCountSettlementSheetStatusesQueryHookResult = ReturnType<typeof useAdminCountSettlementSheetStatusesQuery>;
export type AdminCountSettlementSheetStatusesLazyQueryHookResult = ReturnType<typeof useAdminCountSettlementSheetStatusesLazyQuery>;
export type AdminCountSettlementSheetStatusesQueryResult = Apollo.QueryResult<AdminCountSettlementSheetStatusesQuery, AdminCountSettlementSheetStatusesQueryVariables>;
export const AdminSettlementSheetsDocument = gql`
    query AdminSettlementSheets($filter: SettlementSheetFilterQuery, $pagination: Pagination) {
  adminSettlementSheets(filter: $filter, pagination: $pagination) {
    totalCount
    edges {
      node {
        id
        code
        settlementSheetType
        marketName
        marketStartedAt
        marketEndedAt
        salesChannel
        expectedSettlementAt
        sellerSettlementType
        status
        salesAmount
        settlementAmount
        paymentFee
        sampleAmount
        eventAmount
        etcAmount
        refundDeliveryFeeAmount
        exchangeDeliveryFeeAmount
        withholdingTax
        finalSettlementAmount
        adjustmentAmount
        isTaxInvoiceIssued
        taxInvoiceIssuedAt
        createdAt
        settlementFinishedAt
        bank
        accountNumber
        accountHolderName
        vendorName
        productSalesRecords {
          supplyPrice
        }
        deliveryFee
        finalSettlementAmountLastChangedAt
        finalSettlementAmountLastChangedOperatorName
      }
    }
  }
}
    `;

/**
 * __useAdminSettlementSheetsQuery__
 *
 * To run a query within a React component, call `useAdminSettlementSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSettlementSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSettlementSheetsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminSettlementSheetsQuery(baseOptions?: Apollo.QueryHookOptions<AdminSettlementSheetsQuery, AdminSettlementSheetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSettlementSheetsQuery, AdminSettlementSheetsQueryVariables>(AdminSettlementSheetsDocument, options);
      }
export function useAdminSettlementSheetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSettlementSheetsQuery, AdminSettlementSheetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSettlementSheetsQuery, AdminSettlementSheetsQueryVariables>(AdminSettlementSheetsDocument, options);
        }
export type AdminSettlementSheetsQueryHookResult = ReturnType<typeof useAdminSettlementSheetsQuery>;
export type AdminSettlementSheetsLazyQueryHookResult = ReturnType<typeof useAdminSettlementSheetsLazyQuery>;
export type AdminSettlementSheetsQueryResult = Apollo.QueryResult<AdminSettlementSheetsQuery, AdminSettlementSheetsQueryVariables>;
export const AdminSettlementSheetDocument = gql`
    query AdminSettlementSheet($adminSettlementSheetId: Int!) {
  adminSettlementSheet(id: $adminSettlementSheetId) {
    code
    settlementSheetType
    vendorName
    marketName
    marketStartedAt
    marketEndedAt
    salesChannel
    sellerSettlementType
    expectedSettlementAt
    salesAmount
    settlementAmount
    nonSalesSettlementAmount
    paymentFee
    withholdingTax
    finalSettlementAmount
    adjustmentAmount
    productSalesRecords {
      productName
      jointPurchasePrice
      sellerMargin
      quantity
      supplyPrice
      salesAmount
      settlementAmount
      totalSupplyPrice
    }
    sampleRecords {
      productName
      settlementAmount
      count
    }
    eventRecords {
      productName
      settlementAmount
      count
    }
    etcRecords {
      productName
      settlementAmount
      count
    }
    refundDeliveryFeeAmount
    refundDeliveryFeeCount
    exchangeDeliveryFeeAmount
    exchangeDeliveryFeeCount
    normalDeliveryFee
    normalDeliveryOrderCount
    regionDeliveryFee
    regionDeliveryOrderCount
    paymentMethodRecords {
      paymentMethod
      paymentFeeAmount
      count
    }
    bank
    accountNumber
    accountHolderName
  }
}
    `;

/**
 * __useAdminSettlementSheetQuery__
 *
 * To run a query within a React component, call `useAdminSettlementSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSettlementSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSettlementSheetQuery({
 *   variables: {
 *      adminSettlementSheetId: // value for 'adminSettlementSheetId'
 *   },
 * });
 */
export function useAdminSettlementSheetQuery(baseOptions: Apollo.QueryHookOptions<AdminSettlementSheetQuery, AdminSettlementSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSettlementSheetQuery, AdminSettlementSheetQueryVariables>(AdminSettlementSheetDocument, options);
      }
export function useAdminSettlementSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSettlementSheetQuery, AdminSettlementSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSettlementSheetQuery, AdminSettlementSheetQueryVariables>(AdminSettlementSheetDocument, options);
        }
export type AdminSettlementSheetQueryHookResult = ReturnType<typeof useAdminSettlementSheetQuery>;
export type AdminSettlementSheetLazyQueryHookResult = ReturnType<typeof useAdminSettlementSheetLazyQuery>;
export type AdminSettlementSheetQueryResult = Apollo.QueryResult<AdminSettlementSheetQuery, AdminSettlementSheetQueryVariables>;
export const AdminBulkDeleteSettlementSheetDocument = gql`
    mutation AdminBulkDeleteSettlementSheet($ids: [Int!]!) {
  adminBulkDeleteSettlementSheet(ids: $ids) {
    failCount
    message
    result
    successCount
  }
}
    `;
export type AdminBulkDeleteSettlementSheetMutationFn = Apollo.MutationFunction<AdminBulkDeleteSettlementSheetMutation, AdminBulkDeleteSettlementSheetMutationVariables>;

/**
 * __useAdminBulkDeleteSettlementSheetMutation__
 *
 * To run a mutation, you first call `useAdminBulkDeleteSettlementSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkDeleteSettlementSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkDeleteSettlementSheetMutation, { data, loading, error }] = useAdminBulkDeleteSettlementSheetMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAdminBulkDeleteSettlementSheetMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkDeleteSettlementSheetMutation, AdminBulkDeleteSettlementSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkDeleteSettlementSheetMutation, AdminBulkDeleteSettlementSheetMutationVariables>(AdminBulkDeleteSettlementSheetDocument, options);
      }
export type AdminBulkDeleteSettlementSheetMutationHookResult = ReturnType<typeof useAdminBulkDeleteSettlementSheetMutation>;
export type AdminBulkDeleteSettlementSheetMutationResult = Apollo.MutationResult<AdminBulkDeleteSettlementSheetMutation>;
export type AdminBulkDeleteSettlementSheetMutationOptions = Apollo.BaseMutationOptions<AdminBulkDeleteSettlementSheetMutation, AdminBulkDeleteSettlementSheetMutationVariables>;
export const AdminBulkPatchSettlementSheetStatusDocument = gql`
    mutation AdminBulkPatchSettlementSheetStatus($ids: [Int!]!, $settlementSheetData: BulkUpdateSettlementSheetStatusInputGraphqlType!) {
  adminBulkPatchSettlementSheetStatus(
    ids: $ids
    settlementSheetData: $settlementSheetData
  ) {
    failCount
    message
    result
    successCount
  }
}
    `;
export type AdminBulkPatchSettlementSheetStatusMutationFn = Apollo.MutationFunction<AdminBulkPatchSettlementSheetStatusMutation, AdminBulkPatchSettlementSheetStatusMutationVariables>;

/**
 * __useAdminBulkPatchSettlementSheetStatusMutation__
 *
 * To run a mutation, you first call `useAdminBulkPatchSettlementSheetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkPatchSettlementSheetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkPatchSettlementSheetStatusMutation, { data, loading, error }] = useAdminBulkPatchSettlementSheetStatusMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      settlementSheetData: // value for 'settlementSheetData'
 *   },
 * });
 */
export function useAdminBulkPatchSettlementSheetStatusMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkPatchSettlementSheetStatusMutation, AdminBulkPatchSettlementSheetStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkPatchSettlementSheetStatusMutation, AdminBulkPatchSettlementSheetStatusMutationVariables>(AdminBulkPatchSettlementSheetStatusDocument, options);
      }
export type AdminBulkPatchSettlementSheetStatusMutationHookResult = ReturnType<typeof useAdminBulkPatchSettlementSheetStatusMutation>;
export type AdminBulkPatchSettlementSheetStatusMutationResult = Apollo.MutationResult<AdminBulkPatchSettlementSheetStatusMutation>;
export type AdminBulkPatchSettlementSheetStatusMutationOptions = Apollo.BaseMutationOptions<AdminBulkPatchSettlementSheetStatusMutation, AdminBulkPatchSettlementSheetStatusMutationVariables>;
export const AdminPatchSettlementSheetDocument = gql`
    mutation AdminPatchSettlementSheet($adminPatchSettlementSheetId: Int!, $input: UpdateSettlementSheetInputGraphqlType!) {
  adminPatchSettlementSheet(id: $adminPatchSettlementSheetId, input: $input) {
    settlementSheet {
      id
      adjustmentAmount
      finalSettlementAmount
      isTaxInvoiceIssued
      taxInvoiceIssuedAt
    }
  }
}
    `;
export type AdminPatchSettlementSheetMutationFn = Apollo.MutationFunction<AdminPatchSettlementSheetMutation, AdminPatchSettlementSheetMutationVariables>;

/**
 * __useAdminPatchSettlementSheetMutation__
 *
 * To run a mutation, you first call `useAdminPatchSettlementSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPatchSettlementSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPatchSettlementSheetMutation, { data, loading, error }] = useAdminPatchSettlementSheetMutation({
 *   variables: {
 *      adminPatchSettlementSheetId: // value for 'adminPatchSettlementSheetId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminPatchSettlementSheetMutation(baseOptions?: Apollo.MutationHookOptions<AdminPatchSettlementSheetMutation, AdminPatchSettlementSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPatchSettlementSheetMutation, AdminPatchSettlementSheetMutationVariables>(AdminPatchSettlementSheetDocument, options);
      }
export type AdminPatchSettlementSheetMutationHookResult = ReturnType<typeof useAdminPatchSettlementSheetMutation>;
export type AdminPatchSettlementSheetMutationResult = Apollo.MutationResult<AdminPatchSettlementSheetMutation>;
export type AdminPatchSettlementSheetMutationOptions = Apollo.BaseMutationOptions<AdminPatchSettlementSheetMutation, AdminPatchSettlementSheetMutationVariables>;