import { yupResolver } from '@hookform/resolvers/yup';
import { App } from 'antd';
import { useForm } from 'react-hook-form';

import { useFormError } from '../../../common/hooks/useFormError';
import { CreateBrandInput } from '../../../schema/types';
import { DEFAULT_CONTACT_VALUE } from '../const';
import { useCreateBrandMutation } from '../graphql/brand.generated';
import { VendorWithBrandsDocument } from '../graphql/vendor.generated';
import { createBrandSchema } from '../validator';

function useBrandFormValues(vendorId: number) {
  const { message } = App.useApp();
  const { onInvalid } = useFormError();
  const [createBrand] = useCreateBrandMutation({
    refetchQueries: [VendorWithBrandsDocument],
  });
  const method = useForm<CreateBrandInput>({
    defaultValues: {
      vendorId,
      name: '',
      status: 'ACTIVE',
      brandContacts: [DEFAULT_CONTACT_VALUE],
    },
    resolver: yupResolver<CreateBrandInput>(createBrandSchema),
  });

  const onSubmit = (callback: () => void) =>
    method.handleSubmit(async (data) => {
      await createBrand({
        variables: { createBrandInput: data },
        onCompleted() {
          message.success('저장이 완료되었습니다.');
          callback();
        },
        onError() {
          message.error('저장을 실패하였습니다.');
        },
      });
    }, onInvalid);

  return { method, onSubmit };
}

export default useBrandFormValues;
