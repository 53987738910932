import mime from 'mime';

import {
  ALLOW_UPLOAD_IMAGE_MIME_TYPES,
  ALLOW_UPLOAD_VIDEO_MIME_TYPES,
} from '../../../common/utils/const';

export const validateMedia = (url: string) => {
  const extension = mime.getType(url);

  if (!extension) return 'FILE';
  if (ALLOW_UPLOAD_IMAGE_MIME_TYPES.includes(extension)) return 'IMAGE';
  if (ALLOW_UPLOAD_VIDEO_MIME_TYPES.includes(extension)) return 'VIDEO';
  return 'FILE';
};
