import { SelectProps } from 'antd';
import { get } from 'lodash-es';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import { FieldProps } from '../../../../common/components/Fields/types';

import CategorySelect from './CategorySelect';

type CategorySelectFieldProps<TFieldValues extends FieldValues> = SelectProps &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
    upperCategoryId?: number | null;
  };

export default function CategorySelectField<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  hideError,
  required,
  upperCategoryId,
  hideErrorMessage,
  ...props
}: CategorySelectFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const error = get(formState.errors, name);
        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;
        const status = !hideError && errorMessage ? 'error' : '';

        return (
          <FieldWithLabel label={label} required={required}>
            <CategorySelect
              status={status}
              upperCategoryId={upperCategoryId}
              {...field}
              {...props}
            />
            {!hideErrorMessage && !hideError && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}
