import { App, Button, Tag } from 'antd';
import { useCallback } from 'react';

import { usePermissions } from '../../../../common/hooks/usePermissions';
import { removeCache } from '../../../../common/utils/apollo';
import { ESettlementSheetType } from '../../../../schema/types';
import { SETTLEMENT_SHEET_TYPE_LABEL } from '../../const';
import { useAdminDeleteSettlementsFromSheetMutation } from '../../graphql/settlement.generated';
import { AdminSettlementsDocument } from '../../graphql/settlements.generated';

type SettlementSheetsCellProps = {
  settlementId: number;
  sheets: {
    id: number;
    code?: string;
    settlementSheetType?: ESettlementSheetType;
  }[];
};

function SettlementSheetsCell({
  settlementId,
  sheets,
}: SettlementSheetsCellProps) {
  const { hasPermission } = usePermissions('SETTLEMENT_EDIT');
  const { message, modal } = App.useApp();
  const [deleteMutation] = useAdminDeleteSettlementsFromSheetMutation();
  const deleteFromSheet = useCallback(
    (sheetId: number) => {
      void modal.confirm({
        title: '기존 정산서에서 제외',
        content: '선택한 마켓(업체)정산서에서 제외하시겠습니까?',
        onOk: async () => {
          await deleteMutation({
            variables: { input: { sheetId, settlementIds: [settlementId] } },
            onCompleted() {
              void message.success(
                '선택하신 항목을 기존 정산서에서 제외했습니다.'
              );
            },
            refetchQueries: [AdminSettlementsDocument],
            update(cache) {
              removeCache(cache, {
                id: sheetId,
                __typename: 'SettlementSheetGraphqlType',
              });
            },
            onError(error) {
              if (error.message) {
                void message.error(error.message);
              } else {
                void message.error('기존 정산서 제외 중 오류가 발생했습니다.');
              }
            },
          });
        },
      });
    },
    [deleteMutation, settlementId, message, modal]
  );
  return (
    <div className='flex flex-col gap-1'>
      {sheets.map(({ id, code, settlementSheetType }) => {
        if (!code) '-';

        const tagColor = settlementSheetType === 'MARKET' ? 'magenta' : 'blue';

        return (
          <div className='flex items-center justify-between' key={id}>
            <div className='flex items-center'>
              {settlementSheetType && (
                <Tag color={tagColor}>
                  {SETTLEMENT_SHEET_TYPE_LABEL[settlementSheetType]}
                </Tag>
              )}
              {code}
            </div>
            <Button
              type='link'
              size='small'
              disabled={!hasPermission}
              onClick={() => deleteFromSheet(id)}
            >
              제외
            </Button>
          </div>
        );
      })}
    </div>
  );
}

export default SettlementSheetsCell;
