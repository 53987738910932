import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminSettlementsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SettlementFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminSettlementsQuery = { __typename?: 'Query', adminSettlements?: { __typename?: 'SettlementPageGraphqlType', totalCount: number, edges: Array<{ __typename?: 'PaginatedSettlementGraphqlTypeEdge', node: { __typename?: 'SettlementGraphqlType', salesChannel: Types.ESettlementSalesChannel, sellerName?: string | null, marketId?: number | null, marketName?: string | null, vendorName?: string | null, vendorId?: number | null, brandName?: string | null, productGroupName?: string | null, id: number, orderGroupCode?: string | null, orderCode?: string | null, orderId?: number | null, receiverName?: string | null, settlementType: Types.ESettlementType, settlementDetailType: Types.ESettlementDetailType, salesAmount: number, soldAt: string, productName?: string | null, productId?: number | null, productQuantity?: number | null, sellerSettlementAmount?: number | null, vendorSettlementAmount?: number | null, deliveryBearingAmount?: number | null, productionAmount?: number | null, inHouseMargin?: number | null, comment?: string | null, releasedAt?: string | null, promotionReleaseCode?: string | null, sellerSettlementType?: Types.ESellerSettlementType | null, paymentMethod: Types.ESettlementPaymentMethod, sheets?: Array<{ __typename?: 'SettlementSheetGraphqlType', id: number, code: string, settlementSheetType: Types.ESettlementSheetType }> | null } }> } | null };

export type AdminTotalCountSettlementsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SettlementFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminTotalCountSettlementsQuery = { __typename?: 'Query', adminTotalCountSettlements?: { __typename?: 'CountSettlementsOutputGraphqlType', totalCount: number, totalDeliveryBearingAmount: number, totalInHouseMargin: number, totalProductionAmount: number, totalSalesAmount: number, totalSellerSettlementAmount: number, totalVendorSettlementAmount: number } | null };


export const AdminSettlementsDocument = gql`
    query AdminSettlements($filter: SettlementFilterQuery, $pagination: Pagination) {
  adminSettlements(filter: $filter, pagination: $pagination) {
    edges {
      node {
        salesChannel
        sellerName
        marketId
        marketName
        vendorName
        vendorId
        brandName
        productGroupName
        id
        orderGroupCode
        orderCode
        orderId
        receiverName
        settlementType
        settlementDetailType
        salesAmount
        soldAt
        productName
        productId
        productQuantity
        sellerSettlementAmount
        vendorSettlementAmount
        deliveryBearingAmount
        productionAmount
        inHouseMargin
        comment
        releasedAt
        sheets {
          id
          code
          settlementSheetType
        }
        promotionReleaseCode
        sellerSettlementType
        paymentMethod
      }
    }
    totalCount
  }
}
    `;

/**
 * __useAdminSettlementsQuery__
 *
 * To run a query within a React component, call `useAdminSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSettlementsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminSettlementsQuery(baseOptions?: Apollo.QueryHookOptions<AdminSettlementsQuery, AdminSettlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSettlementsQuery, AdminSettlementsQueryVariables>(AdminSettlementsDocument, options);
      }
export function useAdminSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSettlementsQuery, AdminSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSettlementsQuery, AdminSettlementsQueryVariables>(AdminSettlementsDocument, options);
        }
export type AdminSettlementsQueryHookResult = ReturnType<typeof useAdminSettlementsQuery>;
export type AdminSettlementsLazyQueryHookResult = ReturnType<typeof useAdminSettlementsLazyQuery>;
export type AdminSettlementsQueryResult = Apollo.QueryResult<AdminSettlementsQuery, AdminSettlementsQueryVariables>;
export const AdminTotalCountSettlementsDocument = gql`
    query AdminTotalCountSettlements($filter: SettlementFilterQuery, $pagination: Pagination) {
  adminTotalCountSettlements(filter: $filter, pagination: $pagination) {
    totalCount
    totalDeliveryBearingAmount
    totalInHouseMargin
    totalProductionAmount
    totalSalesAmount
    totalSellerSettlementAmount
    totalVendorSettlementAmount
  }
}
    `;

/**
 * __useAdminTotalCountSettlementsQuery__
 *
 * To run a query within a React component, call `useAdminTotalCountSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTotalCountSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTotalCountSettlementsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminTotalCountSettlementsQuery(baseOptions?: Apollo.QueryHookOptions<AdminTotalCountSettlementsQuery, AdminTotalCountSettlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminTotalCountSettlementsQuery, AdminTotalCountSettlementsQueryVariables>(AdminTotalCountSettlementsDocument, options);
      }
export function useAdminTotalCountSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminTotalCountSettlementsQuery, AdminTotalCountSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminTotalCountSettlementsQuery, AdminTotalCountSettlementsQueryVariables>(AdminTotalCountSettlementsDocument, options);
        }
export type AdminTotalCountSettlementsQueryHookResult = ReturnType<typeof useAdminTotalCountSettlementsQuery>;
export type AdminTotalCountSettlementsLazyQueryHookResult = ReturnType<typeof useAdminTotalCountSettlementsLazyQuery>;
export type AdminTotalCountSettlementsQueryResult = Apollo.QueryResult<AdminTotalCountSettlementsQuery, AdminTotalCountSettlementsQueryVariables>;