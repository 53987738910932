import { FilterOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

import {
  SEARCH_PERIOD_TYPE_OPTIONS,
  SEARCH_SUPPORT_PERIOD_TYPE_OPTIONS,
} from '../const';
import { ClaimListSearchParams } from '../type';

import SearchFilterModal from './SearchFilterModal';
import SearchFilterTags from './SearchFilterTags';

type SearchFormWithFilterModalProps = {
  isOrderSupport?: boolean;
  initialValue: ClaimListSearchParams;
  onSearch: (v: Partial<ClaimListSearchParams>) => void;
};

function SearchFormWithFilterModal({
  isOrderSupport,
  initialValue,
  onSearch,
}: SearchFormWithFilterModalProps) {
  const [searchFilterModal, setSearchFilterModal] = useState(false);

  const { startedAt, endedAt, searchKeyword, periodType } = initialValue;

  const periodOptions = isOrderSupport
    ? SEARCH_SUPPORT_PERIOD_TYPE_OPTIONS
    : SEARCH_PERIOD_TYPE_OPTIONS;

  return (
    <div className='flex flex-col gap-2'>
      <div>
        <Select
          className='mr-2 w-[132px]'
          placeholder='--선택--'
          options={periodOptions}
          value={periodType}
          onChange={(periodType) => onSearch({ periodType })}
        />
        <DatePicker
          className='w-[162px]'
          onChange={(value) =>
            onSearch({ startedAt: value?.startOf('date').toISOString() })
          }
          value={startedAt ? dayjs(startedAt) : null}
        />
        <span className='mx-2'>~</span>
        <DatePicker
          className='w-[162px]'
          onChange={(value) =>
            onSearch({ endedAt: value?.endOf('date').toISOString() })
          }
          value={endedAt ? dayjs(endedAt) : null}
        />
      </div>
      <div className='flex gap-2'>
        <Input.Search
          defaultValue={searchKeyword}
          placeholder='판매채널주문번호, 와이어드주문코드, 수령자명, 수령자휴대폰번호, 주문자명, 주문자휴대폰번호 입력'
          className='w-[616px]'
          onSearch={(value) => onSearch({ searchKeyword: value })}
        />
        <Button
          icon={<FilterOutlined />}
          onClick={() => setSearchFilterModal(true)}
        />
      </div>
      <SearchFilterTags />
      {searchFilterModal && (
        <SearchFilterModal
          onClose={() => setSearchFilterModal(false)}
          isOrderSupport={isOrderSupport}
        />
      )}
    </div>
  );
}

export default SearchFormWithFilterModal;
