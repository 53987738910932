import { Empty, Table } from 'antd';

import useProductMarketListTableColumns from '../hooks/useProductMarketListTableColumns';

function ProductMarketListEmptyTable() {
  const { columns } = useProductMarketListTableColumns();

  return (
    <Table
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'진행한 마켓이 없습니다'}
          />
        ),
      }}
      columns={columns}
    />
  );
}

export default ProductMarketListEmptyTable;
