import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import { boolean } from 'yup';
import * as yup from 'yup';

import { ERROR_MESSAGE_REQUIRED } from '../../../common/constants/error';
import { bankOptions } from '../../../common/utils/const';
import { addHttpProtocol } from '../../../common/utils/url';
import { EBankCode, ESellerSettlementType } from '../../../schema/types';
import {
  SELLER_ADDRESSES_MAX_LENGTH,
  SELLER_EMAILS_MAX_LENGTH,
  SELLER_LINKS_MAX_LENGTH,
  SELLER_LINKS_MIN_LENGTH,
  SELLER_SETTLEMENT_TYPES,
  SELLER_SETTLEMENTS_MAX_LENGTH,
  SELLER_SETTLEMENTS_MIN_LENGTH,
} from '../const';
import { CreateSellerFormValues, UpdateSellerFormValues } from '../types';

export const sellerSchema = {
  name: yup
    .string()
    .required(ERROR_MESSAGE_REQUIRED)
    .max(30, '30자 이내로 입력해주세요.'),
  managerId: yup.number().required(ERROR_MESSAGE_REQUIRED),
  grade: yup.number().nullable(),
  kemiUserId: yup.string().nullable().max(30, '30자 이내로 입력해주세요.'),
  links: yup
    .array()
    .of(
      yup.object({
        url: yup
          .string()
          .url('유효하지 않는 URL 입니다.')
          .required(ERROR_MESSAGE_REQUIRED),
        isDefault: yup.boolean().required(ERROR_MESSAGE_REQUIRED),
      })
    )
    .nullable()
    .max(
      SELLER_LINKS_MAX_LENGTH,
      `셀러링크는 ${SELLER_LINKS_MAX_LENGTH}개 이하로 등록할 수 있습니다.`
    )
    .min(1, `셀러 링크를 ${SELLER_LINKS_MIN_LENGTH}개 이상 등록해야 합니다.`),
  emails: yup
    .array()
    .of(
      yup.object({
        email: yup
          .string()
          .required(ERROR_MESSAGE_REQUIRED)
          .email('유효하지 않는 Email 입니다.'),
        isDefault: yup.boolean().required(ERROR_MESSAGE_REQUIRED),
      })
    )
    .nullable()
    .max(
      SELLER_EMAILS_MAX_LENGTH,
      `이메일은 ${SELLER_EMAILS_MAX_LENGTH}개 이하로 등록할 수 있습니다.`
    ),
  phoneNumber: yup
    .string()
    .required(ERROR_MESSAGE_REQUIRED)
    .length(11, '유효하지 않은 휴대폰 번호 양식입니다.'),
  isCheckedPhoneNumber: yup
    .boolean()
    .when('phoneNumber', {
      is: '',
      then: () => boolean().required(),
      otherwise: () => boolean().oneOf([true], '중복확인이 필요합니다.'),
    })
    .required(),
  isUniquePhoneNumber: yup
    .boolean()
    .when('phoneNumber', {
      is: '',
      then: () => boolean().required(),
      otherwise: () => boolean().oneOf([true], '중복된 셀러가 있어요!'),
    })
    .required(),
  addresses: yup
    .array()
    .of(
      yup.object({
        zipCode: yup.string().required(ERROR_MESSAGE_REQUIRED),
        address: yup.string().required(ERROR_MESSAGE_REQUIRED),
        detailAddress: yup.string().nullable(),
        isDefault: yup.boolean().required(ERROR_MESSAGE_REQUIRED),
      })
    )
    .max(
      SELLER_ADDRESSES_MAX_LENGTH,
      `주소는 ${SELLER_ADDRESSES_MAX_LENGTH}개 이하로 등록할 수 있습니다.`
    )
    .nullable(),
  settlementInfos: yup
    .array()
    .of(
      yup.object({
        isDefault: yup.boolean().required(ERROR_MESSAGE_REQUIRED),
        type: yup
          .mixed<ESellerSettlementType>()
          .oneOf(
            Object.values(SELLER_SETTLEMENT_TYPES.map((type) => type.value))
          )
          .required(ERROR_MESSAGE_REQUIRED),
        companyName: yup
          .string()
          .max(30, '30자 이내로 입력해주세요.')
          .nullable(),
        businessRegistrationNumber: yup
          .string()
          .nullable()
          .transform((_, value): value is string => {
            return value === '' ? null : value;
          })
          .length(10, '유효하지 않은 사업자 번호 양식입니다.'),
        businessRegistrationImageUrl: yup.string().nullable(),
        sellerName: yup.string().nullable().max(5, '5자 이내로 입력해주세요.'),
        residentRegistrationNumberFront: yup
          .string()
          .nullable()
          .transform((_, value): value is string => {
            return value === '' ? null : value;
          })
          .length(6, '6자를 입력해주세요.'),
        residentRegistrationNumberBack: yup
          .string()
          .nullable()
          .transform((_, value): value is string => {
            return value === '' ? null : value;
          })
          .length(7, '7자를 입력해주세요.'),
        residentRegistrationImageUrl: yup.string().nullable(),
        bank: yup
          .mixed<EBankCode>()
          .oneOf(bankOptions.map((option) => option.value))
          .nullable(),
        accountNumber: yup.string().nullable(),
        accountImageUrl: yup.string().nullable(),
        accountHolderName: yup.string().nullable(),
      })
    )
    .required(ERROR_MESSAGE_REQUIRED)
    .max(
      SELLER_SETTLEMENTS_MAX_LENGTH,
      `정산 정보는 ${SELLER_SETTLEMENTS_MAX_LENGTH}개 이하로 등록해야 합니다.`
    )
    .min(
      SELLER_SETTLEMENTS_MIN_LENGTH,
      `정산 정보는 ${SELLER_SETTLEMENTS_MIN_LENGTH}개 이상 등록해야 합니다.`
    ),
  memo: yup.string().nullable(),
};

export const createSellerSchema = yup.object<CreateSellerFormValues>().shape({
  ...sellerSchema,
});

export const updateSellerSchema = yup.object<UpdateSellerFormValues>().shape({
  id: yup.number().required(ERROR_MESSAGE_REQUIRED),
  ...sellerSchema,
});

const sellerFormResolver = <
  T extends CreateSellerFormValues | UpdateSellerFormValues,
>(
  data: T
): T => {
  const values = {
    ...data,
  };

  values.name = values.name.trim();
  values.kemiUserId = values.kemiUserId?.trim();

  //emails array는 필수값이 아니므로 작성되지 않은 array는 data에서 제거한다.
  values.emails = values.emails?.flatMap((email) => {
    if (!email.email) {
      return [];
    }

    return [
      {
        ...email,
        email: email.email.trim(),
      },
    ];
  });

  //대표값이 없는 배열이면 0번 인덱스가 대표값이 된다.
  if (values.emails?.[0] && !values.emails?.some((email) => email.isDefault)) {
    values.emails[0].isDefault = true;
  }

  //addresses array는 필수값이 아니므로 작성되지 않은 array는 data에서 제거한다.
  if (values?.addresses?.length === 1 && !values?.addresses[0].zipCode) {
    values.addresses = [];
  }

  if (!values.grade) {
    values.grade = undefined;
  }

  values.links = values.links?.map((link) => {
    if (link) {
      const url = link.url.trim();

      return {
        ...link,
        url: url ? addHttpProtocol(url) : url,
      };
    }
    return link;
  });

  //대표값이 없는 배열이면 0번 인덱스가 대표값이 된다.
  if (values.links?.[0] && !values.links?.some((link) => link.isDefault)) {
    values.links[0].isDefault = true;
  }

  /**
   * settlement type 별로 불필요한 데이터 초기화
   */
  values.settlementInfos = values.settlementInfos.map((settlementInfo) => {
    if (settlementInfo.type === 'BUSINESS') {
      return {
        ...settlementInfo,
        residentRegistrationImageUrl: null,
        residentRegistrationNumberBack: null,
        residentRegistrationNumberFront: null,
        sellerName: null,
      };
    }

    return {
      ...settlementInfo,
      businessRegistrationImageUrl: null,
      businessRegistrationNumber: null,
      companyName: null,
    };
  });

  return values;
};

export const createSellerFormResolver: Resolver<CreateSellerFormValues> = (
  data,
  context,
  options
) => {
  const values = sellerFormResolver(data);

  return yupResolver<CreateSellerFormValues>(createSellerSchema)(
    values,
    context,
    options
  );
};

export const updateSellerFormResolver: Resolver<UpdateSellerFormValues> = (
  data,
  context,
  options
) => {
  const values = sellerFormResolver(data);

  return yupResolver<UpdateSellerFormValues>(updateSellerSchema)(
    values,
    context,
    options
  );
};
