import { Checkbox, Divider, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import SelectField from '../../../../common/components/Fields/SelectField';
import SwitchField from '../../../../common/components/Fields/SwitchField';
import { ROUTES } from '../../../../routes/const';
import {
  DELIVERY_FEE_BEARER_OPTIONS,
  DELIVERY_FEE_SETTLEMENT_TARGET,
} from '../../const';
import { PromotionFormValues } from '../../types';
import MarketEventProductsDescription from '../MarketEventProductsDescription';
import MarketEventSelectField from '../MarketEventSelectField';
import MarketSelectField, { MarketOption } from '../MarketSelectField';
import NumberWithUnknownField from '../NumberWithUnknownField';
import ProductGroupSelectField from '../ProductGroupSelectField';
import PromotionProductsTable from '../PromotionProductsTable';

function DetailInformation() {
  const methods = useFormContext<PromotionFormValues>();
  const { setValue, watch, control, resetField } = methods;
  const [
    promotionType,
    marketId,
    productGroupId,
    isDeliveryFee,
    marketUndesignated,
    sellerId,
  ] = watch([
    'promotionType',
    'marketId',
    'productGroupId',
    'isDeliveryFee',
    'marketUndesignated',
    'sellerId',
  ]);
  const isEvent = promotionType === 'EVENT';
  const [marketProductGroups, setMarketProductGroups] = useState<
    MarketOption['productGroups']
  >([]);

  const handleMarketSelect = useCallback(
    (_: any, option: DefaultOptionType | DefaultOptionType[]) => {
      if (!Array.isArray(option)) {
        const marketOption = option.data as MarketOption;
        setValue('marketId', marketOption.id);
        setMarketProductGroups(marketOption.productGroups);
        // 마켓이 변경되면 상품과 프로모션 품목이 초기화 된다.
        resetField('productGroupId');
        resetField('promotionProducts');
      }
    },
    [setValue, resetField]
  );

  const marketProductGroupsOption = useMemo(() => {
    return marketProductGroups.map((group) => ({
      label: group.name,
      value: group.id,
    }));
  }, [marketProductGroups]);

  const handleProductGroupSelect = useCallback(
    (value: number) => {
      setValue('productGroupId', value);
      resetField('promotionProducts', { defaultValue: [] });
    },
    [setValue, resetField]
  );

  return (
    <div>
      <Divider orientation='left' className='m-0 py-4'>
        상세정보
      </Divider>
      <div className='flex flex-col gap-6'>
        <Typography.Text className='text-[#00000073]'>
          *한 명에게 발송되는 품목리스트입니다.
        </Typography.Text>
        <FieldWithLabel label='마켓명' required>
          <div className='flex items-center gap-2'>
            <div className='w-[472px]'>
              <MarketSelectField
                control={control}
                sellerId={sellerId}
                name='marketId'
                value={marketId}
                onChange={handleMarketSelect}
                disabled={!!marketUndesignated}
              />
            </div>
            <div className='flex-1'>
              <Checkbox
                onChange={(ev) => {
                  setValue('marketUndesignated', ev.target.checked);
                  resetField('marketId');
                  resetField('productGroupId');
                  resetField('promotionProducts');
                }}
                checked={!!marketUndesignated}
                disabled={isEvent}
              >
                마켓 미지정
              </Checkbox>
            </div>
          </div>
          <div className='mt-1 w-[472px] text-end'>
            <Link target='_blank' to={ROUTES.MARKETS.path}>
              [마켓관리로 이동]
            </Link>
          </div>
        </FieldWithLabel>
        <FieldWithLabel label={isEvent ? '이벤트' : '상품명'} required>
          <div className='w-[472px]'>
            {!isEvent &&
              (marketUndesignated ? (
                <ProductGroupSelectField
                  control={control}
                  name='productGroupId'
                  onChange={handleProductGroupSelect}
                  value={productGroupId}
                />
              ) : (
                <SelectField
                  control={control}
                  name='productGroupId'
                  options={marketProductGroupsOption}
                  onChange={handleProductGroupSelect}
                  value={productGroupId}
                  placeholder='--선택--'
                />
              ))}
            {isEvent && <MarketEventSelectField />}
          </div>
        </FieldWithLabel>
        {promotionType === 'EVENT' ? (
          <MarketEventProductsDescription />
        ) : (
          <>
            <PromotionProductsTable />
            <div className='flex flex-col gap-4'>
              <SwitchField
                label='배송비 별도부과'
                control={control}
                name='isDeliveryFee'
              />
              {isDeliveryFee && (
                <>
                  <FieldWithLabel label='배송비 부담주체' required>
                    <div className='flex w-[472px] gap-2'>
                      <SelectField
                        control={control}
                        name='deliveryFeeBearer'
                        options={DELIVERY_FEE_BEARER_OPTIONS.slice(0, 2)}
                      />
                      <NumberWithUnknownField
                        control={control}
                        name='deliveryFee'
                        onUnknownClick={() => setValue('deliveryFee', null)}
                      />
                    </div>
                  </FieldWithLabel>
                  <FieldWithLabel label='배송비 정산대상' required>
                    <div className='flex w-[472px] gap-2'>
                      <SelectField
                        control={control}
                        name='deliveryFeeSettlementTarget'
                        options={DELIVERY_FEE_SETTLEMENT_TARGET}
                      />
                      <NumberWithUnknownField
                        control={control}
                        name='deliveryFeeSettlementPrice'
                        onUnknownClick={() =>
                          setValue('deliveryFeeSettlementPrice', null)
                        }
                      />
                    </div>
                  </FieldWithLabel>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DetailInformation;
