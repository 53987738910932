import * as Types from '../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductGroupsInSelectFieldQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductGroupFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type ProductGroupsInSelectFieldQuery = { __typename?: 'Query', adminProductGroups?: { __typename?: 'ProductGroupPageGraphqlType', edges: Array<{ __typename?: 'PaginatedProductGroupGraphqlTypeEdge', node: { __typename?: 'ProductGroupGraphqlType', id: number, name: string } }> } | null };


export const ProductGroupsInSelectFieldDocument = gql`
    query ProductGroupsInSelectField($filter: ProductGroupFilterQuery, $pagination: Pagination) {
  adminProductGroups(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProductGroupsInSelectFieldQuery__
 *
 * To run a query within a React component, call `useProductGroupsInSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGroupsInSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGroupsInSelectFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProductGroupsInSelectFieldQuery(baseOptions?: Apollo.QueryHookOptions<ProductGroupsInSelectFieldQuery, ProductGroupsInSelectFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupsInSelectFieldQuery, ProductGroupsInSelectFieldQueryVariables>(ProductGroupsInSelectFieldDocument, options);
      }
export function useProductGroupsInSelectFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupsInSelectFieldQuery, ProductGroupsInSelectFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupsInSelectFieldQuery, ProductGroupsInSelectFieldQueryVariables>(ProductGroupsInSelectFieldDocument, options);
        }
export type ProductGroupsInSelectFieldQueryHookResult = ReturnType<typeof useProductGroupsInSelectFieldQuery>;
export type ProductGroupsInSelectFieldLazyQueryHookResult = ReturnType<typeof useProductGroupsInSelectFieldLazyQuery>;
export type ProductGroupsInSelectFieldQueryResult = Apollo.QueryResult<ProductGroupsInSelectFieldQuery, ProductGroupsInSelectFieldQueryVariables>;