import { Divider, Table } from 'antd';
import { useMemo } from 'react';

import { AdminOrderGroupQuery } from '../graphql/createClaim.generated';

type Order = AdminOrderGroupQuery['adminOrderGroup']['orders']['0'];

function PartialClaimsHistory({ order }: { order: Order }) {
  const partialClaims = useMemo(() => {
    if (!order?.claims) {
      return {
        return: 0,
        returnCount: 0,
        exchange: 0,
        exchangeCount: 0,
        hasClaims: false,
      };
    }

    const data = order?.claims?.reduce<{
      return: number;
      returnCount: number;
      exchange: number;
      exchangeCount: number;
      hasClaims: boolean;
    }>(
      (acc, claim) => {
        if (claim.claimType === 'PARTIAL_RETURN') {
          acc.return += claim.claimQuantity || 0;
          acc.returnCount += 1;
          acc.hasClaims = true;
        }

        if (claim.claimType === 'PARTIAL_EXCHANGE') {
          acc.exchange += claim.claimQuantity || 0;
          acc.exchangeCount += 1;
          acc.hasClaims = true;
        }

        return acc;
      },
      {
        return: 0,
        returnCount: 0,
        exchange: 0,
        exchangeCount: 0,
        hasClaims: false,
      }
    ) || {
      return: 0,
      returnCount: 0,
      exchange: 0,
      exchangeCount: 0,
      hasClaims: false,
    };

    return {
      partialReturn: `${data.return}${
        data.returnCount > 1 ? ` (${data.returnCount}회)` : ''
      }`,
      partialExchange: `${data.exchange}${
        data.exchangeCount > 1 ? ` (${data.exchangeCount}회)` : ''
      }`,
      hasClaims: data.hasClaims,
    };
  }, [order]);

  if (!partialClaims.hasClaims) {
    return null;
  }

  return (
    <>
      <Divider orientation={'left'} className={'mb-6'}>
        클레임내역
      </Divider>
      <Table
        pagination={false}
        columns={[
          {
            key: 'partialReturn',
            dataIndex: 'partialReturn',
            title: '부분반품',
          },
          {
            key: 'partialExchange',
            dataIndex: 'partialExchange',
            title: '부분교환',
          },
        ]}
        rowKey={'id'}
        dataSource={[partialClaims]}
      />
    </>
  );
}

export default PartialClaimsHistory;
