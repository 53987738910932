// TODO: 히스토리 테스트를 위한 데이터로 백엔드 히스토리 api 붙인 후 삭제 예정
const history1 = {
  name: '첫번째',
  kemiUserId: '강라쿤',
  grade: 1,
  links: [
    {
      isDefault: true,
      url: 'https://www.wired.company',
    },
  ],
  emails: [
    {
      isDefault: true,
      email: 'raccoon1@wired.company',
    },
  ],
  phoneNumber: '01044445555',
  addresses: [
    {
      isDefault: true,
      zipcode: '12345',
      address1: '서울특별시 송파구 올림픽로 424',
      address2: '올림픽공원 정문',
    },
  ],
  memo: '',
  settlementInfos: [
    {
      isDefault: 'true',
      type: 'BUSINESS',
      companyName: '와이어드컴퍼니',
      businessRegistrationNumber: '123123-123123',
      businessRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      sellerName: '강낙훈',
      residentRegistrationNumberFront: '850421',
      residentRegistrationNumberBack: '1010101',
      residentRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      bank: 'KOOKMIN',
      accountNumber: '987654321',
      accountImageUrl: 'https://somewhere.com/someimage.png',
      accountHolderName: '강낙훈',
    },
    {
      isDefault: 'true',
      type: 'BUSINESS',
      companyName: '와이어드컴퍼니',
      businessRegistrationNumber: '123123-123123',
      businessRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      sellerName: '강낙훈',
      residentRegistrationNumberFront: '850421',
      residentRegistrationNumberBack: '1010101',
      residentRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      bank: 'KOOKMIN',
      accountNumber: '987654321',
      accountImageUrl: 'https://somewhere.com/someimage.png',
      accountHolderName: '강낙훈',
    },
    {
      isDefault: 'true',
      type: 'BUSINESS',
      companyName: '와이어드컴퍼니',
      businessRegistrationNumber: '123123-123123',
      businessRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      sellerName: '강낙훈',
      residentRegistrationNumberFront: '850421',
      residentRegistrationNumberBack: '1010101',
      residentRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      bank: 'KOOKMIN',
      accountNumber: '987654321',
      accountImageUrl: 'https://somewhere.com/someimage.png',
      accountHolderName: '강낙훈',
    },
  ],
};

const history2 = {
  name: '두번째',
  kemiUserId: '강라쿤',
  grade: 1,
  links: [
    {
      isDefault: true,
      url: 'https://www.wired.company',
    },
  ],
  emails: [
    {
      isDefault: true,
      email: 'raccoon1@wired.company',
    },
  ],
  phoneNumber: '01044445555',
  addresses: [
    {
      isDefault: true,
      zipcode: '12345',
      address1: '서울특별시 송파구 올림픽로 300',
      address2: '월드타워',
    },
  ],
  memo: '',
  settlementInfos: [
    {
      isDefault: 'true',
      type: 'BUSINESS',
      companyName: '와이어드컴퍼니',
      businessRegistrationNumber: '123123-123123',
      businessRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      sellerName: '강낙훈',
      residentRegistrationNumberFront: '850421',
      residentRegistrationNumberBack: '1010101',
      residentRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      bank: 'KOOKMIN',
      accountNumber: '987654321',
      accountImageUrl: 'https://somewhere.com/someimage.png',
      accountHolderName: '강낙훈',
    },
  ],
};

const history3 = {
  name: '세번째',
  kemiUserId: '강라쿤',
  grade: 1,
  links: [
    {
      isDefault: true,
      url: 'https://www.wired.company',
    },
  ],
  emails: [
    {
      isDefault: true,
      email: 'raccoon1@wired.company',
    },
  ],
  phoneNumber: '01044445555',
  addresses: [
    {
      isDefault: true,
      zipcode: '12345',
      address1: '서울특별시 송파구 올림픽로 300',
      address2: '월드타워',
    },
  ],
  memo: '',
  settlementInfos: [
    {
      isDefault: 'true',
      type: 'BUSINESS',
      companyName: '와이어드컴퍼니',
      businessRegistrationNumber: '123123-123123',
      businessRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      sellerName: '강낙훈',
      residentRegistrationNumberFront: '850421',
      residentRegistrationNumberBack: '1010101',
      residentRegistrationImageUrl: 'https://somewhere.com/someimage.png',
      bank: 'KOOKMIN',
      accountNumber: '987654321',
      accountImageUrl: 'https://somewhere.com/someimage.png',
      accountHolderName: '강낙훈',
    },
  ],
};

// key - label 맵핑은 중복되는 key가 존재할 수 있어 dot으로 표현한다.
export const sellerLabel = {
  name: '셀러명',
  id: '셀러ID',
  kemiUserId: '케미회원아이디',
  grade: '등급',
  links: '셀러링크',
  'links.isDefault': '대표여부',
  'links.url': '셀러링크',
  emails: '이메일주소',
  'emails.isDefault': '대표여부',
  'emails.email': '이메일주소',
  phoneNumber: '휴대폰번호',
  addresses: '샘플수령지',
  'addresses.isDefault': '대표여부',
  'addresses.zipcode': '우편번호',
  'addresses.address1': '주소1',
  'addresses.address2': '주소2',
  memo: '비고',
  settlementInfos: '정산정보',
  'settlementInfos.isDefault': '대표여부',
  'settlementInfos.type': '판매유형',
  'settlementInfos.companyName': '회사명',
  'settlementInfos.businessRegistrationNumber': '사업자등록번호',
  'settlementInfos.businessRegistrationImageUrl': '사업자등록증 사본 이미지',
  'settlementInfos.sellerName': '셀러의 실명',
  'settlementInfos.residentRegistrationNumberFront': '주민등록번호 앞자리',
  'settlementInfos.residentRegistrationNumberBack': '주민등록번호 뒷자리',
  'settlementInfos.residentRegistrationImageUrl': '주민등록증 사본 이미지',
  'settlementInfos.bank': '은행 영문 코드',
  'settlementInfos.accountNumber': '계좌번호',
  'settlementInfos.accountImageUrl': '계좌사본 이미지',
  'settlementInfos.accountHolderName': '예금주명',
};

export const managerLabel = {
  name: '이름',
  email: '이메일주소',
  roles: '메뉴 수정권한',
};

export const testData1 = {
  객체: {
    원시타입문자: '문자',
    원시타입숫자: 1,
    원시타입빈문자: '',
    원시타입null: null,
    원시타입undefined: undefined,
    배열: [
      '배열 문자',
      {
        배열_객체_문자: '문자',
        배열_객체_숫자: 1,
        배열_객체_배열: ['배열 객체 배열 문자'],
      },
      [[['깊은 배열 값'], []], '배열 배열 문자', '배열 배열 숫자'],
      { 1: '키가 숫자' },
      1,
    ],
    객채_객체: {
      객채_객체_객체: {
        객채_객체_객체_객체: {
          객채_객체_객체_객체_숫자: 1,
        },
      },
      객채_객체_문자: '문자',
    },
  },
};

export const testData2 = {
  key0: [
    {
      key1: 'value1',
      key2: 11,
    },
    {
      key3: 'value2',
      key4: 22,
    },
    {
      key5: 'value3',
      key6: 33,
    },
    {
      key7: 'value4',
      key8: 44,
      key9: [
        {
          key10: 'value5',
          key11: [
            {
              key12: 'value6',
              key13: {
                key14: 'value7',
              },
            },
          ],
        },
      ],
    },
  ],
  key1: 'value1',
  key2: 42,
  key3: true,
  key4: [1, 2, 3],
  key5: [
    {
      key1: 'value1',
      key2: 42,
      key3: [
        {
          key1: 'value1',
        },
        {
          key2: 'value2',
        },
        {
          key3: 'value3',
        },
        {
          key4: 'value4',
        },
        'value5',
        [
          {
            key1: 'value1',
          },
          [
            false,
            {
              key1: 'value1',
            },
          ],
        ],
      ],
    },
    {
      key1: 'value1',
      key2: 42,
    },
    {
      key1: 'value1',
      key2: 42,
    },
  ],
  key6: {
    nestedKey1: 'nestedValue1',
    nestedKey2: [true, false],
  },
  key7: '',
  key8: [
    [
      [
        {
          key1: 'value1',
        },
        false,
      ],
      'value1',
      'value2',
    ],
    ['A'],
    ['B'],
    2,
    3,
  ],
  key9: {
    a: 'nestedValue1',
    b: {
      1: 'nestedValue1',
      2: 'nestedValue2',
    },
  },
  key10: [
    'a',
    'b',
    ['a'],
    {
      c: 'dd',
      f: 'ee',
    },
    'a',
    'b',
    ['a'],
    {
      c: 'dd',
      f: 'ee',
    },
    'a',
    'b',
    ['a'],
    {
      c: 'dd',
      f: 'ee',
    },
  ],
};

export const histories = [
  {
    id: '3',
    uiType: 'HISTORY',
    originId: '3',
    snapShot: history3,
    manager: '장명재',
    createdAt: '2023.08.23 20:00:00',
  },
  {
    id: '2',
    uiType: 'HISTORY',
    originId: '2',
    snapShot: history2,
    manager: '성해동',
    createdAt: '2023.08.20 20:00:00',
  },
  {
    id: '1',
    uiType: 'HISTORY',
    originId: '1',
    snapShot: history1,
    manager: '강낙훈',
    createdAt: '2023.07.19 20:00:00',
  },
];
