import { PlusOutlined } from '@ant-design/icons';
import { Button, Pagination, Table, Typography } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { useState } from 'react';

import EmptyTable from '../common/components/EmptyTable';
import { usePermissions } from '../common/hooks/usePermissions';
import CreateVendorModal from '../domain/vendor/components/CreateVendorModal';
import ListSearchForm from '../domain/vendor/components/ListSearchForm';
import useListSearchFormValues from '../domain/vendor/hooks/useListSearchFormValues';
import useVendorTable, {
  VendorTableDataType,
} from '../domain/vendor/hooks/useVendorTable';
import { APP_HEADER_HEIGHT } from '../theme/const';

function VendorList() {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { columns, dataSources, loading, total } = useVendorTable();
  const { currentPage, pageSize, ...handlers } = useListSearchFormValues();
  const { hasPermission } = usePermissions('BRAND_AND_VENDOR_EDIT');
  const noResult = !loading && !dataSources.length;
  return (
    <div className='flex flex-col gap-4'>
      <ListSearchForm />
      <div className='bg-white p-6'>
        <div className='flex justify-between'>
          <Typography.Title level={4}>업체 / 브랜드리스트</Typography.Title>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            disabled={!hasPermission}
            onClick={() => setCreateModalOpen(true)}
          >
            업체등록
          </Button>
        </div>
        {noResult ? (
          <EmptyTable />
        ) : (
          <>
            <Table
              sticky={{
                offsetHeader: APP_HEADER_HEIGHT,
              }}
              size='small'
              scroll={{ x: 1280 }}
              columns={columns}
              dataSource={dataSources}
              pagination={false}
              onChange={(_, __, sorter) => {
                handlers.handleSort(
                  sorter as SorterResult<VendorTableDataType>
                );
              }}
              loading={loading}
            />
            <div className='mt-4 flex items-center justify-end gap-4'>
              <div>총 {total}건</div>
              <Pagination
                showSizeChanger
                onChange={handlers.handlePageChange}
                current={currentPage}
                pageSize={pageSize}
                total={total}
              />
            </div>
          </>
        )}
      </div>
      {createModalOpen && (
        <CreateVendorModal onClose={() => setCreateModalOpen(false)} />
      )}
    </div>
  );
}

export default VendorList;
