import { Button, Modal } from 'antd';
import { cloneDeep, omit } from 'lodash-es';
import { useCallback, useRef } from 'react';
import {
  FieldValues,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form';

import SwitchField from '../../../common/components/Fields/SwitchField';
import useAntdTheme from '../../../common/hooks/useAntdTheme';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { PRODUCT_NAME_MAX_LENGTH } from '../const';
import { productIdGenerator } from '../helper/generator';
import useGetReferencedMarkets from '../hooks/useGetReferencedMarkets';
import { ProductGroupFormValues } from '../types';

import AdditionalProductField from './Fields/AdditionalProductField';

type ProductListItemExtraProps = {
  index: number;
  disabled?: boolean;
} & UseFieldArrayReturn<FieldValues, 'products', 'id'>;

export default function ProductListItemExtra({
  index,
  fields,
  remove,
  insert,
  update,
  move,
}: ProductListItemExtraProps) {
  const { hasPermission } = usePermissions('PRODUCT_EDIT');
  const [modal, contextHolder] = Modal.useModal();
  const { colorError } = useAntdTheme();

  const { editableProduct } = useGetReferencedMarkets();

  const { getValues, control, watch } =
    useFormContext<ProductGroupFormValues>();
  const isNew = getValues(`products.${index}.isNew`);

  const editable = hasPermission && (editableProduct || isNew);

  const disabledDelete = fields.length === 1;
  const isPrimary = watch(`products.${index}.isPrimary`);

  const handleDeleteProduct = useCallback(() => {
    remove(index);
  }, [index, remove]);

  const isCloning = useRef<boolean>(false);

  const confirmModal = () => {
    modal.confirm({
      title: '품목 삭제',
      content: (
        <p className='break-keep'>
          품목이 영구적으로 삭제되며, 다시 되돌릴 수 없습니다. 품목 삭제를
          진행하시겠습니까?
        </p>
      ),
      onOk: handleDeleteProduct,
      cancelText: '아니오',
      okText: '예',
      maskClosable: true,
    });
  };

  const handleClone = () => {
    if (!isCloning.current) {
      isCloning.current = true;

      const product = getValues(`products.${index}`);
      const cloned = omit(cloneDeep(product), ['code']);
      const name = cloned.name
        ? cloned.name.slice(0, PRODUCT_NAME_MAX_LENGTH - 3)
        : '';

      setTimeout(() => {
        insert(index + 1, {
          ...cloned,
          isPrimary: false,
          name: `${name} 복제`,
          id: productIdGenerator(),
          isNew: true,
        });

        isCloning.current = false;
      }, 0);
    }
  };

  const handleChangePrimary = (value: boolean) => {
    if (value) {
      const products = getValues(`products`);
      const current = products[index];
      const primaryArray = products.filter((product) => product.isPrimary);

      if (primaryArray.length) {
        primaryArray.forEach((product) => {
          if (current.id !== product.id) {
            const updateIndex = products.findIndex(
              ({ id }) => id === product.id
            );

            update(updateIndex, { ...product, isPrimary: false });
          }
        });
      }

      if (current.isAdditionalProduct) {
        update(index, { ...current, isAdditionalProduct: false });
      }

      move(index, 0);
    }
  };

  return (
    <>
      <div
        onClick={(e) => e.stopPropagation()}
        className='flex select-none items-center'
      >
        <div className='flex'>
          <span className='mr-1'>대표로 설정</span>
          <SwitchField
            control={control}
            name={`products.${index}.isPrimary`}
            onChange={handleChangePrimary}
            className='mr-2'
            disabled={!hasPermission || !editableProduct}
            size='small'
          />
        </div>
        {!isPrimary && (
          <div>
            <AdditionalProductField
              control={control}
              name={`products.${index}.isAdditionalProduct`}
              disabled={!editable}
            />
          </div>
        )}
        <Button
          type='text'
          onClick={handleClone}
          disabled={isCloning.current || !hasPermission}
        >
          복제
        </Button>
        <Button
          type='text'
          style={{ color: !editable ? undefined : colorError }}
          onClick={confirmModal}
          disabled={!editable || disabledDelete}
        >
          지우기
        </Button>
      </div>

      {contextHolder}
    </>
  );
}
