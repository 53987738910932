import { useState } from 'react';

import PageLayout from '../common/components/PageLayout';
import SearchForm from '../domain/settlement/components/settlement-list/SearchForm';
import SentLastMonthVendorModal from '../domain/settlement/components/settlement-list/SentLastMonthVendorModal';
import SettlementListTable from '../domain/settlement/components/settlement-list/SettlementListTable';
import SettlementScheduledMarketModal from '../domain/settlement/components/settlement-list/SettlementScheduledMarketModal';
import Summary, { SummaryProps } from '../domain/settlement/components/Summary';
import { useAdminCountMarketsForScheduledSettlementQuery } from '../domain/settlement/graphql/markets.generated';
import { useAdminCountVendorsForScheduledSettlementQuery } from '../domain/settlement/graphql/vendors.generated';

function SettlementList() {
  const [marketModalOpen, setMarketModalOpen] = useState(false);
  const [vendorModalOpen, setVendorModalOpen] = useState(false);

  const { data: marketData } =
    useAdminCountMarketsForScheduledSettlementQuery();

  const { data: vendorData } =
    useAdminCountVendorsForScheduledSettlementQuery();

  const marketCount =
    marketData?.adminCountMarketsForScheduledSettlement.count ?? 0;
  const vendorCount =
    vendorData?.adminCountVendorsForScheduledSettlement.count ?? 0;

  const summaryItems: SummaryProps['items'] = [
    {
      key: 'MARKET',
      label: '다가오는 정산예정 마켓',
      count: marketCount,
      onClick: () => setMarketModalOpen(true),
      buttonText: '마켓보기',
    },
    {
      key: 'VENDOR',
      label: '지난달 발송 업체',
      count: vendorCount,
      onClick: () => setVendorModalOpen(true),
      buttonText: '업체보기',
    },
  ];

  return (
    <>
      <PageLayout
        summary={
          <Summary label='정산예정 현황' items={summaryItems} type={'link'} />
        }
        search={<SearchForm />}
      >
        <div className='bg-white px-6'>
          <SettlementListTable />
        </div>
      </PageLayout>
      {marketModalOpen && (
        <SettlementScheduledMarketModal
          onClose={() => setMarketModalOpen(false)}
        />
      )}
      {vendorModalOpen && (
        <SentLastMonthVendorModal onClose={() => setVendorModalOpen(false)} />
      )}
    </>
  );
}

export default SettlementList;
