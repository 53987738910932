import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef } from 'react';

import {
  UppermostCategoriesQueryVariables,
  useUppermostCategoriesQuery,
} from '../../graphql/productGroup.generated';

const UppermostCategorySelect = forwardRef<BaseSelectRef, SelectProps>(
  function ({ ...props }, ref) {
    const TAKE = 9999; // 모든 카테고리를 가져오기 위해 임의로 큰 값을 설정함

    const { data } = useUppermostCategoriesQuery({
      variables: {
        pagination: {
          skip: 0,
          take: TAKE,
        },
      } as UppermostCategoriesQueryVariables,
    });

    const categories = data?.adminUppermostCategories.edges;
    const options = categories?.map(({ node }) => ({
      value: node.id,
      label: node.name,
    }));

    return (
      <Select
        ref={ref}
        options={options}
        {...props}
        showSearch
        filterOption={(input, option) =>
          ((option?.label as string) ?? '').includes(input)
        }
      />
    );
  }
);

export default UppermostCategorySelect;
