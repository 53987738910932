import { PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  App,
  Button,
  Divider,
  Pagination,
  Table,
  Tag,
  Typography,
} from 'antd';
import { sortBy } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import Modal from '../../../../common/components/Modal';
import { useMeQuery } from '../../../../common/graphql/me.generated';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { deleteCacheFields } from '../../../../common/utils/apollo';
import { shouldDeleteQueriesAfterClaimUpdate } from '../../const';
import { AdminOrderGroupQuery } from '../../graphql/createClaim.generated';
import {
  AdminCountOrderSupportStatusesDocument,
  useAdminOrderSupportMessagesQuery,
  useAdminPatchOrderSupportMessageMutation,
  useAdminPatchOrderSupportStatusMutation,
} from '../../graphql/orderSupport.generated';
import { getOrderClaimStatus } from '../../helper/create';
import ClaimOrderSummary from '../ClaimOrderSummary';

import CreateOrderSupportForm from './CreateOrderSupportForm';
import OrderSupportMessageItem from './OrderSupportMessageItem';
import UpdateOrderSupportMessageForm from './UpdateOrderSupportMessageForm';

type CreateOrderSupportModalProps = {
  onCancel: () => void;
  order?: AdminOrderGroupQuery['adminOrderGroup']['orders']['0'];
  orderGroup: AdminOrderGroupQuery['adminOrderGroup'];
  title?: string;
};

function CreateOrderSupportModal({
  title = '응대요청',
  onCancel,
  order,
  orderGroup,
}: CreateOrderSupportModalProps) {
  const { message, modal } = App.useApp();

  const { hasPermission } = usePermissions(['ORDER_EDIT']);

  const [size, setSize] = useState(10);
  const [page, setPage] = useState(1);

  const [openCreateModal, setOpenCreateModal] = useState(false);

  const orderSupportId = order?.support?.id || 0;
  const orderId = order?.id;

  const { data: meData } = useMeQuery();
  const [updateMessage, { loading }] =
    useAdminPatchOrderSupportMessageMutation();

  const [cancelSupport] = useAdminPatchOrderSupportStatusMutation();

  const { data } = useAdminOrderSupportMessagesQuery({
    variables: {
      pageQuery: {
        filter: {
          orderSupportId,
        },
        pagination: {
          skip: (page - 1) * size,
          take: size,
        },
      },
    },
    skip: !orderSupportId,
  });

  const orders = orderGroup.orders;
  const totalCount = data?.adminOrderSupportMessages.totalCount || 0;
  const supportMessages = data?.adminOrderSupportMessages.edges;
  const hasPreviousPage =
    !!data?.adminOrderSupportMessages.pageInfo?.hasPreviousPage;
  const hasNextPage = !!data?.adminOrderSupportMessages.pageInfo?.hasNextPage;
  const endCursor = data?.adminOrderSupportMessages.pageInfo?.endCursor;

  const channel = order?.support?.channel;
  const requestType = order?.support?.requestType;
  const supportStatus = order?.support?.status;

  const isFirstMessage = useCallback(
    (cursor: string) => {
      return !hasNextPage && cursor === endCursor;
    },
    [endCursor, hasNextPage]
  );

  const handleMessageSubmit = useCallback(
    ({
      messageId,
      content,
    }: {
      messageId: number;
      content: string;
    }): Promise<void> => {
      return new Promise((resolve, reject) => {
        void updateMessage({
          variables: {
            orderSupportMessageId: messageId,
            orderSupportMessageData: { content },
          },
          onCompleted() {
            message.success('응대내용을 업데이트했어요.');
            resolve();
          },
          onError(error) {
            message.error(error.message || '응대내용 업데이트에 실패했습니다.');
            reject();
          },
          update(cache) {
            deleteCacheFields(cache, [
              'adminOrders',
              'adminOrderGroup',
              'adminCountOrderSupportStatuses',
            ]);
          },
        });
      });
    },
    [message, updateMessage]
  );

  const dataSource = useMemo(() => {
    return sortBy(
      orders
        .filter(
          (order) =>
            order.orderType === 'PRODUCT' &&
            order.status !== 'CANCEL_COMPLETED' &&
            order.status !== 'RETURN_COMPLETED'
        )
        .map((order) => {
          const optionNames =
            order.productUseType === 'SELECT' && order?.items
              ? ` (${order.items.map((item) => item.name).join(', ')})`
              : '';

          const { status, claimType } = getOrderClaimStatus(order);

          return {
            id: order.id,
            code: order.code,
            codeWithTag: (
              <div className={'flex items-center gap-2'}>
                {order.code}
                {order.id === orderId && <Tag color={'blue'}>응대요청품목</Tag>}
              </div>
            ),
            productId: order.productId,
            productName: `${order.productName}${optionNames}`,
            orderAmount: order.orderAmount.toLocaleString(),
            productQuantity: order.productQuantity,
            status,
            claimType,
          };
        }),
      'bundleDeliveryCode'
    );
  }, [orders]);

  const handlePageChange = (page: number, size: number) => {
    setPage(page);
    setSize(size);
  };

  const handleCancelButtonClick = () => {
    modal.warning({
      content: '응대를 취소하시겠습니까?',
      centered: true,
      okText: '네',
      cancelText: '아니오',
      okCancel: true,
      onOk() {
        void cancelSupport({
          refetchQueries: [AdminCountOrderSupportStatusesDocument],
          variables: {
            orderSupportData: {
              status: 'SUPPORT_CANCELED',
              id: orderSupportId,
            },
          },
          onError(e) {
            message.error(e.message || '응대취소처리를 실패했습니다.');
          },
          onCompleted() {
            message.success('응대가 취소되었습니다.');
            onCancel();
          },
          update(cache) {
            deleteCacheFields(cache, shouldDeleteQueriesAfterClaimUpdate);
          },
        });
      },
    });
  };

  return (
    <Modal
      width={1000}
      title={title}
      onCancel={onCancel}
      footer={
        hasPermission && supportStatus === 'SUPPORT_RECEIPT' ? (
          <Button type={'default'} onClick={handleCancelButtonClick}>
            응대취소
          </Button>
        ) : (
          <Button type={'primary'} onClick={onCancel}>
            닫기
          </Button>
        )
      }
      // 모달 위에 뜨는 message, alert을 배치하기 위한 z-index 설정
      wrapProps={{
        style: { zIndex: 1000 },
      }}
      styles={{
        mask: { zIndex: 1000 },
      }}
    >
      <div className={'pt-4'}>
        <div className={'w-full bg-[#E6F4FF] px-4 py-3'}>
          <Typography.Title
            level={5}
            className={'m-0'}
          >{`와이어드 품목주문코드: ${order?.code || ''}`}</Typography.Title>
        </div>
      </div>

      <Alert
        showIcon
        type={'warning'}
        className={'mt-4 whitespace-pre-wrap'}
        description={
          'CS팀에 응대요청할 수 있습니다.\n동일 주문번호 건에 대해서 조회가 가능합니다.'
        }
      />

      <div className={'mt-6 grid gap-12'}>
        <ClaimOrderSummary order={order} orderGroup={orderGroup} />

        <div>
          <div className={'mb-4 flex items-center gap-4'}>
            <Divider orientation={'left'} className={'m-0 min-w-0 flex-1'}>
              {'동일 주문코드 조회'}
            </Divider>
            <Tag
              className={'flex-0'}
            >{`와이어드 주문코드: ${orderGroup.code}`}</Tag>
          </div>
          <Table
            tableLayout={'fixed'}
            rowKey={'id'}
            columns={[
              {
                title: '와이어드 품목주문코드',
                key: 'codeWithTag',
                dataIndex: 'codeWithTag',
                width: 260,
              },
              {
                title: '품목명',
                key: 'productName',
                dataIndex: 'productName',
              },
              {
                title: '주문수량',
                key: 'productQuantity',
                width: 120,
                render(_, record) {
                  return record.productQuantity.toLocaleString();
                },
              },
              {
                title: '주문상태',
                key: 'status',
                width: 120,
                render(_, record) {
                  if (record.claimType) {
                    return (
                      <>
                        <Typography.Paragraph className={'m-0'}>
                          {record.status}
                        </Typography.Paragraph>
                        <Typography.Paragraph
                          className={'m-0'}
                          type={'secondary'}
                        >
                          {record.claimType}
                        </Typography.Paragraph>
                      </>
                    );
                  }
                  return record.status;
                },
              },
              {
                title: '주문금액',
                key: 'orderAmount',
                dataIndex: 'orderAmount',
                width: 120,
              },
            ]}
            dataSource={dataSource || []}
            pagination={false}
          />
        </div>
        <div>
          <Divider orientation={'left'} className={'m-0 mb-6 min-w-0 flex-1'}>
            {'응대 내역'}
          </Divider>
          {!!supportMessages?.length && (
            <>
              <div className={'mb-6 [&>div]:mb-6 last:[&>div]:mb-0'}>
                {supportMessages?.map((message, i) => {
                  const isFirst = isFirstMessage((i + 1).toString());
                  return (
                    <OrderSupportMessageItem
                      editable={
                        hasPermission &&
                        !isFirst &&
                        !message.node.isStatusUpdate &&
                        meData?.adminMe.id === message.node.operatorId
                      }
                      onSubmit={handleMessageSubmit}
                      key={message.node.id}
                      supportMessage={message.node}
                      channel={isFirst ? channel : undefined}
                      requestType={isFirst ? requestType : undefined}
                      loading={loading}
                    />
                  );
                })}
              </div>
              {(hasNextPage || hasPreviousPage) && (
                <div className={'flex justify-end'}>
                  <Pagination
                    showSizeChanger
                    onChange={handlePageChange}
                    current={page}
                    pageSize={size}
                    total={totalCount}
                  />
                </div>
              )}
            </>
          )}
          {!supportMessages?.length && (
            <div className={'bg-[#00000005] p-6'}>
              <Typography.Title level={5} className={'mb-4'}>
                응대요청내역이 없습니다.
              </Typography.Title>
              <Button
                type={'primary'}
                icon={<PlusOutlined />}
                onClick={() => setOpenCreateModal(true)}
              >
                응대요청
              </Button>
            </div>
          )}
          {order && (
            <div className={'mt-4'}>
              <UpdateOrderSupportMessageForm
                orderSupportId={orderSupportId}
                order={order}
                hasMessages={!!supportMessages?.length}
                orderGroupId={orderGroup.id}
                disabled={!hasPermission}
              />
            </div>
          )}
        </div>
      </div>
      {order?.id && openCreateModal && (
        <CreateOrderSupportForm
          orderId={order.id}
          onCancel={() => setOpenCreateModal(false)}
        />
      )}
    </Modal>
  );
}

export default CreateOrderSupportModal;
