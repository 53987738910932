import { Button } from 'antd';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import SelectField from '../../../common/components/Fields/SelectField';
import TextField from '../../../common/components/Fields/TextField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { useAdminDeliveryCompaniesQuery } from '../graphql/orders.generated';
import { OrderListTableDeliveryFormValues, OrderTableDataType } from '../types';

import OrderTableDeliveryCellText from './OrderTableDeliveryCellText';

type OrderTableDeliveryCellProps = {
  record: OrderTableDataType;
  onCellSpanChange: (groupId: number) => void;

  isMergedSpan: boolean;
};

export default function OrderTableDeliveryCell({
  record,
  onCellSpanChange,
  isMergedSpan,
}: OrderTableDeliveryCellProps) {
  const [editing, setEditing] = useState(false);
  const { data } = useAdminDeliveryCompaniesQuery();
  const deliveryCompanyOptions = data?.adminDeliveryCompanies.map(
    ({ code, name }) => ({ label: name, value: code })
  );
  const { hasPermission } = usePermissions('ORDER_EDIT');

  const hasDelivery = record.deliveryCompanyCode && record.trackingNumber;
  const { control } = useFormContext<OrderListTableDeliveryFormValues>();

  if (!editing && hasDelivery) {
    return (
      <OrderTableDeliveryCellText
        onEditing={() => setEditing(true)}
        orderKey={record.key as number}
        deliveryCompanyOptions={deliveryCompanyOptions ?? []}
        disabled={!hasPermission}
      />
    );
  }

  return (
    <div className='flex flex-col items-end gap-1'>
      <SelectField
        control={control}
        name={`orders.${record.key as number}.deliveryCompanyCode`}
        options={deliveryCompanyOptions}
        placeholder='--택배사 선택--'
        disabled={!hasPermission}
      />
      <TextField
        allowClear
        placeholder='송장번호'
        control={control}
        name={`orders.${record.key as number}.trackingNumber`}
        disabled={!hasPermission}
      />
      {hasPermission && record.isFirstProduct && (
        <Button
          type='text'
          size='small'
          className='text-primary'
          onClick={() => onCellSpanChange(record.groupId)}
        >
          {isMergedSpan ? '개별 송장입력' : '통합 송장입력'}
        </Button>
      )}
    </div>
  );
}
