import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import MarketRangePicker from './MarketRangePicker';

interface EditableMarketRangePickerCellProps {
  onSubmit: (value: [string | null, string | null]) => Promise<void> | void;
  initialValue: [string | null, string | null];
  editable: boolean;
  noToggleEdit?: boolean;
  disabled?: boolean;
}

function EditableMarketRangePickerCell({
  onSubmit,
  editable,
  initialValue,
  noToggleEdit,
  disabled,
}: EditableMarketRangePickerCellProps) {
  const [initialStart, initialEnd] = initialValue;

  const [editing, setEditing] = useState(!!noToggleEdit);
  const [value, setValue] = useState(initialValue);
  const [open, setOpen] = useState(false);

  const [startAt, endAt] = value;
  const label = startAt
    ? `${dayjs(startAt).format('YYYY-MM-DD')} ~ ${
        endAt ? dayjs(endAt).format('YYYY-MM-DD') : '상시'
      }`
    : '-';

  const toggleEdit = useCallback(() => {
    setEditing((editing) => !!noToggleEdit || !editing);
  }, [setEditing]);

  const handleBlur = useCallback(() => {
    if (open) {
      return;
    }

    toggleEdit();
  }, [open, toggleEdit]);

  useEffect(() => {
    if (
      !open &&
      (!isEqual(startAt, initialStart) || !isEqual(endAt, initialEnd))
    ) {
      onSubmit(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, startAt, endAt, initialStart, initialEnd]);

  if (!editable || !editing) {
    return (
      <div
        className={`min-h-[30px] rounded-lg px-lg py-sm ${
          editable ? 'editable-cell hover:cursor-pointer' : ''
        }`}
        onClick={editable ? toggleEdit : undefined}
      >
        {label}
      </div>
    );
  }

  return (
    <MarketRangePicker
      open={open}
      changeOnBlur={false}
      allowEmpty={[false, true]}
      value={value}
      onChange={setValue}
      onBlur={handleBlur}
      onOpenChange={setOpen}
      disabled={disabled}
    />
  );
}

export default EditableMarketRangePickerCell;
