import { DeleteOutlined } from '@ant-design/icons';
import { App, Divider } from 'antd';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import NumberField from '../../../common/components/Fields/NumberField';
import SelectField from '../../../common/components/Fields/SelectField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { useAdminGetMarketsSessionLazyQuery } from '../graphql/market.generated';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../types';

import { BrandOption } from './BrandsSelectField';
import DividerWithButton from './DividerWithButton';

type ProductGroupOption = BrandOption['productGroups']['0'];

type MarketProductsFieldItemProps = {
  index: number;
  options: {
    label: string;
    value: number;
    data: ProductGroupOption;
  }[];
  onRemove: () => void;
  hideSession?: boolean;
  productEditable?: boolean;
};

function MarketProductsFieldItem({
  index,
  onRemove,
  options,
  hideSession,
  productEditable = true,
}: MarketProductsFieldItemProps) {
  const { hasPermission } = usePermissions('MARKET_EDIT');
  const { control, setValue, watch } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();
  const { message } = App.useApp();

  const [fetchMarkets] = useAdminGetMarketsSessionLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const isSellerAssigned = watch('isSellerAssigned');
  const productGroupId = watch(`productGroupIds.${index}.id`);
  const productGroupName = watch(`productGroupIds.${index}.name`);
  const initialOption =
    productGroupName && productGroupId
      ? [
          {
            label: productGroupName,
            value: productGroupId,
          },
        ]
      : [];

  const setValueWithValidate = useCallback(
    (...args: Parameters<typeof setValue>) => {
      setValue(args[0], args[1], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    },
    [setValue]
  );

  const fetchSession = useCallback(
    async ({
      sellerId,
      productGroupId,
    }: {
      sellerId: number;
      productGroupId: number;
    }) => {
      try {
        const { data } = await fetchMarkets({
          variables: {
            filter: {
              productGroupId,
              sellerId,
              statuses: ['ENDED'],
            },
          },
        });

        return data?.adminMarkets.totalCount || 0;
      } catch (e) {
        message.warning('상품 차수를 불러올 수 없습니다. 0으로 표기됩니다.');
        return 0;
      }
    },
    [fetchMarkets]
  );

  useEffect(() => {
    const subscription = watch(async (data, { name }) => {
      if (name === `productGroupIds.${index}.id` || name === 'sellerId') {
        const sellerId = data.sellerId;
        const productGroupId = data?.productGroupIds?.[index]?.id;

        if (!sellerId || !productGroupId) {
          return;
        }

        const session = await fetchSession({ sellerId, productGroupId });

        setValueWithValidate(`productGroupIds.${index}.session`, session);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch, index, fetchSession, setValueWithValidate]);

  return (
    <>
      {index === 0 && (
        <Divider plain orientation='left' className={'mb-4'}>
          상품
        </Divider>
      )}
      {!!index && (
        <DividerWithButton
          plain
          buttonLabel={'상품삭제'}
          buttonProps={{
            type: 'text',
            danger: true,
            icon: <DeleteOutlined />,
            onClick: onRemove,
            disabled: !hasPermission || !productEditable,
          }}
          title={`상품 ${index + 1}`}
          className={'mb-4'}
        />
      )}

      <div className={'grid grid-cols-2 gap-md @lg:grid-cols-productInfoField'}>
        <div className={'col-span-1 @lg:col-span-1'}>
          <SelectField<
            CreateMarketFormValues | UpdateMarketFormValues,
            number,
            {
              label: string;
              value: number;
              data?: ProductGroupOption;
            }
          >
            required={hasPermission}
            className={'w-full'}
            disabled={!hasPermission || !productEditable}
            name={`productGroupIds.${index}.id`}
            label={'상품명'}
            placeholder='상품선택'
            control={control}
            options={[...initialOption, ...options]}
            onChange={(_, option) => {
              if (!Array.isArray(option) && !!option.data) {
                const data = option.data;
                const isFirstProduct = index === 0;

                setValueWithValidate(
                  `productGroupIds.${index}.managerId`,
                  data.manager.id
                );

                setValueWithValidate(
                  `productGroupIds.${index}.name`,
                  data.name
                );

                if (isFirstProduct) {
                  setValueWithValidate('deliveryFeeType', data.deliveryFeeType);
                  setValueWithValidate('deliveryCost', data.deliveryFee);
                  setValueWithValidate('additionalFee', data.additionalFee);
                  setValueWithValidate('isRegionFee', data.isRegionFee);
                  setValueWithValidate('jejuIslandFee', data.jejuIslandFee);
                  setValueWithValidate(
                    'freeDeliveryConditionFee',
                    data.freeDeliveryConditionFee
                  );
                  setValueWithValidate('releaseType', data.releaseType);
                  setValueWithValidate('releaseTime', data.releaseTime);
                }
              }
            }}
          />
        </div>
        <div className={'col-span-1 @lg:hidden'} />
        <div className={'col-span-1 @lg:w-[232px]'}>
          <ManagerSelectField
            disabled
            name={`productGroupIds.${index}.managerId`}
            label={'담당자'}
            control={control}
            className={'w-full'}
          />
        </div>
        {isSellerAssigned && !hideSession && (
          <div className={'col-span-1'}>
            <NumberField
              disabled
              tooltip={'셀러와 상품이 1회라도 매칭된 이력이 있으면 집계됩니다.'}
              label={'상품 차수'}
              name={`productGroupIds.${index}.session`}
              control={control}
              placeholder={'숫자 입력'}
              suffix={'차'}
              numberType={'price'}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default MarketProductsFieldItem;
