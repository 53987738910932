import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { LabelHTMLAttributes, PropsWithChildren } from 'react';

type LabelProps = LabelHTMLAttributes<HTMLLabelElement> & {
  required?: boolean;
  tooltip?: string;
};

function Label({
  children,
  required,
  tooltip,
  ...props
}: PropsWithChildren<LabelProps>) {
  return (
    <label className={'inline-flex items-center'} {...props}>
      {required && <span className={'mr-sm text-red-400'}>*</span>}
      {children}
      {tooltip && (
        <Tooltip title={tooltip}>
          <span
            className={'ml-sm inline-flex h-4 w-4 items-center justify-center'}
          >
            <QuestionCircleOutlined
              className={'h-3.5 w-3.5'}
              style={{ color: '#00000073' }}
            />
          </span>
        </Tooltip>
      )}
    </label>
  );
}

export default Label;
