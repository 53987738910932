export const ERROR = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  UNAUTHORIZED: 'UNAUTHORIZED',
  BAD_REQUEST: 'BAD_REQUEST',
  NOT_FOUND: 'NOT_FOUND',
} as const;

export const AUTH_ERRORS: string[] = [
  ERROR.UNAUTHENTICATED,
  ERROR.UNAUTHORIZED,
];

export const ERROR_MESSAGE_REQUIRED = '필수항목이 입력되지 않았습니다.';
