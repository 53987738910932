export const formatNumber = (text: string, format?: string, strict = true) => {
  const textArr = text.split('').filter((s) => !isNaN(Number(s)));
  const result = [];

  if (!format) {
    return textArr.join('');
  }

  for (let i = 0; i < format.length; i++) {
    if (!textArr.length) {
      continue;
    }

    if (format[i] === '#') {
      result.push(textArr.shift());
    } else {
      result.push(format[i]);
    }
  }

  if (!strict) {
    return result
      .concat(textArr)
      .filter((text) => !!text?.trim())
      .join('');
  } else {
    return result.filter((text) => !!text?.trim()).join('');
  }
};

export const parseNumber = (text?: string) => {
  return (
    text
      ?.split('')
      .filter((s, index) => {
        if (index === 0 && s === '-') return true;
        return !isNaN(Number(s));
      })
      .join('') || ''
  );
};
