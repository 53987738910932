import {
  DeleteOutlined,
  EyeOutlined,
  UploadOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { App, Button, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { ButtonProps } from 'antd/lib/button';
import mime from 'mime';
import { useEffect, useMemo, useState } from 'react';

import { EUploadResourcesPurpose } from '../../schema/types';
import { useFileUpload } from '../hooks/useFileUpload';
import { ALLOW_UPLOAD_IMAGE_MIME_TYPES } from '../utils/const';

import PreviewModal from './PreviewModal';

type FileUploaderProps = Omit<UploadProps, 'onChange'> & {
  id: string;
  name: string;
  purpose: EUploadResourcesPurpose;
  value: string[];
  onChange: (value: string[]) => void;
};

function FileUploader({
  purpose,
  value,
  onChange,
  ...props
}: FileUploaderProps) {
  const { message } = App.useApp();

  const { upload, fetchUploadResources } = useFileUpload({
    purpose,
  });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const [fileList, setFileList] = useState<UploadFile[]>(() => {
    return value.map((fileUrl, i) => {
      return {
        uid: i.toString(),
        name: '',
        thumbUrl: fileUrl,
        status: 'done',
        url: fileUrl,
      };
    });
  });

  const addFile = (url: string, fileId: string) => {
    const name = '';

    setFileList((fileList) => {
      const newFile: UploadFile = {
        name,
        status: 'uploading',
        url,
        thumbUrl: url,
        uid: fileId,
      };

      const newFileList: UploadFile[] = [...fileList, newFile].slice(
        0,
        props.maxCount || undefined
      );

      return newFileList;
    });
  };

  const afterUpload = (url: string) => {
    setFileList((fileList) => {
      return fileList.map((file) => {
        if (file.url === url) {
          return {
            ...file,
            status: 'done',
          };
        }
        return file;
      });
    });
  };

  const removeFile = (id: string) => {
    setFileList((fileList) => {
      return fileList.filter((file) => {
        return file.uid !== id;
      });
    });
  };

  const handlePreview = (file: UploadFile) => {
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleError = (target: UploadFile) => {
    setFileList((fileList) => {
      return fileList.map((file) => {
        if (target.uid === file.uid) {
          return {
            ...file,
            status: 'error',
            error: {
              statusText: (
                <div>
                  <span>파일을 불러올 수 없습니다.</span>
                  <br />
                  <span>파일을 확인하여 다시 업로드해주세요.</span>
                  <br />
                  <span>(권장 확장자 : jpeg | jpg | png | webp | gif)</span>
                </div>
              ),
            },
          };
        }
        return file;
      });
    });
  };

  const fileListView = useMemo(() => {
    return fileList.map((file) => ({
      ...file,
      url: file.status === 'error' ? undefined : file.url,
      thumbUrl: file.status === 'error' ? undefined : file.thumbUrl,
    }));
  }, [fileList]);

  useEffect(() => {
    onChange(fileList.flatMap((file) => (file.url ? [file.url] : [])));
  }, [fileList]);

  return (
    <>
      <Upload
        className={'file-uploader-list pt-lg [&_.ant-upload-select]:hidden'}
        customRequest={async (options) => {
          const file = options.file as RcFile;

          try {
            const resource = await fetchUploadResources(file);
            const url =
              resource.adminGenerateUploadResources?.destinationUrl || '';

            addFile(url, file.uid);

            const data = await upload(resource, file);

            options.onSuccess?.(data);
            afterUpload(data);
          } catch (e) {
            removeFile(file.uid);
            message.error('파일 업로드를 실패하였습니다.');
          }
        }}
        listType='picture-card'
        fileList={fileListView}
        isImageUrl={(file) => {
          if (!file.url) {
            return false;
          }

          const extension = mime.getType(file.url);

          return extension
            ? ALLOW_UPLOAD_IMAGE_MIME_TYPES.includes(extension)
            : false;
        }}
        showUploadList={{
          removeIcon: <DeleteOutlined />,
          previewIcon: <EyeOutlined />,
        }}
        onRemove={(file) => {
          removeFile(file.uid);
        }}
        onPreview={handlePreview}
        iconRender={(file) => {
          const status = file.status;
          const color = status === 'error' ? 'red' : 'rgba(0, 0, 0, 0.45)';

          return (
            <div
              className={
                'absolute left-0 top-0 flex h-full w-full items-center justify-center'
              }
            >
              <FileTextOutlined style={{ fontSize: 24, color }} />
            </div>
          );
        }}
        itemRender={(originNode, file) => {
          return (
            <div
              className={'h-[96px] w-[96px]'}
              onError={() => handleError(file)}
            >
              {originNode}
            </div>
          );
        }}
        {...props}
      >
        {/* fileList만 보이게 하기 위한 빈 DOM 추가 */}
        <span />
      </Upload>
      {previewOpen && (
        <PreviewModal
          onClose={() => setPreviewOpen(false)}
          previewImage={previewImage}
          disabled={props.disabled}
        />
      )}
    </>
  );
}

export function UploadButton({
  id,
  label,
  disabled,
  ...props
}: ButtonProps & {
  id: string;
  label?: string;
  disabled?: boolean;
}) {
  const { message } = App.useApp();

  if (disabled) {
    return (
      <Button
        disabled={disabled}
        htmlType={'button'}
        onClick={() =>
          message.warning(
            '이미 등록된 파일이 존재합니다.\n삭제 후 업로드를 해주세요.'
          )
        }
        {...props}
      >
        <UploadOutlined className={'mr-sm'} />
        {label}
      </Button>
    );
  }

  return (
    <Button htmlType={'button'} className={'p-0'} {...props}>
      <label
        className={'h-full w-full px-[15px] py-[4px] hover:cursor-pointer'}
        htmlFor={id}
      >
        <UploadOutlined className={'mr-md'} />
        {label || '파일 업로드'}
      </label>
    </Button>
  );
}

export default FileUploader;
