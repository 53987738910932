import { App } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useMeQuery } from '../../../common/graphql/me.generated';
import { useFormError } from '../../../common/hooks/useFormError';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { deleteCacheFields } from '../../../common/utils/apollo';
import { ROUTES } from '../../../routes/const';
import {
  ItemsDocument,
  useCreateProductGroupWithProductMutation,
  useDeleteTemporaryProductMutation,
} from '../graphql/productGroup.generated';
import { productIdGenerator } from '../helper/generator';
import { createInputMapper } from '../helper/mapper';
import { createProductGroupFormResolver } from '../helper/validator';
import { CreateProductGroupFormValues } from '../types';

export default function useCreateProductGroupFormValues(
  defaultValues?: CreateProductGroupFormValues
) {
  const navigate = useNavigate();
  const { data: user } = useMeQuery();
  const { message } = App.useApp();
  const [createProductGroup] = useCreateProductGroupWithProductMutation();
  const [deleteTemporaryProduct] = useDeleteTemporaryProductMutation();
  const { searchParams } = useRouteSearchParams<{ temporaryId?: string }>();

  const methods = useForm<CreateProductGroupFormValues>({
    defaultValues: defaultValues ?? {
      formType: 'CREATE',
      managerId: user?.adminMe?.id,
      status: 'SELL',
      isPurchaseQuantityNoLimit: true, // submit할때 따로 핸들링
      deliveryFeeType: 'FREE',
      isRegionFee: true,
      productGroupType: 'DISTRIBUTION',
      products: [
        {
          useType: 'FIX',
          taxType: 'TAX',
          isAdditionalProduct: false,
          isPrimary: true,
          id: productIdGenerator(),
        },
      ],
    },
    /**
     * input change 시점에 validation 동작
     * default는 submit 시점에 validation 동작
     */
    mode: 'onChange',
    resolver: createProductGroupFormResolver,
  });

  const { handleSubmit } = methods;
  const { onInvalid } = useFormError();

  // 임시저장 삭제
  const deleteTemporary = async () => {
    const temporaryId = Number(searchParams.temporaryId);
    await deleteTemporaryProduct({
      variables: {
        adminDeleteTemporaryProductId: temporaryId,
      },
      update: (cache) => deleteCacheFields(cache, ['adminTemporaryProducts']),
    });
  };

  const onSubmit = handleSubmit(async (data: CreateProductGroupFormValues) => {
    const createProductGroupInput = createInputMapper(data);

    await createProductGroup({
      variables: { productGroupData: createProductGroupInput },
      refetchQueries: [ItemsDocument],
      async onCompleted() {
        message.success('저장이 완료되었습니다.');
        defaultValues && searchParams.temporaryId && (await deleteTemporary());
        navigate(ROUTES.PRODUCTS.path);
      },
      onError({ message: errorMessage }) {
        const text = errorMessage || '저장을 실패하였습니다.';
        message.error(text);
      },
      update: (cache) => deleteCacheFields(cache, ['adminProductGroups']),
    });
  }, onInvalid);
  return { methods, onSubmit };
}
