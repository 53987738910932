import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { OrderListTableDeliveryFormValues } from '../types';

type OrderTableDeliveryCellTextProps = {
  onEditing: () => void;
  deliveryCompanyOptions: { label: string; value: string }[];
  orderKey: number;
  disabled?: boolean;
};

export default function OrderTableDeliveryCellText({
  onEditing,
  orderKey,
  deliveryCompanyOptions,
  disabled,
}: OrderTableDeliveryCellTextProps) {
  const { control } = useFormContext<OrderListTableDeliveryFormValues>();

  const deliveryCompanyCode = useWatch({
    control,
    name: `orders.${orderKey}.deliveryCompanyCode`,
    defaultValue: '',
  });

  const trackingNumber = useWatch({
    control,
    name: `orders.${orderKey}.trackingNumber`,
    defaultValue: '',
  });

  const deliveryCompanyName = useMemo(
    () =>
      deliveryCompanyOptions?.find(({ value }) => value === deliveryCompanyCode)
        ?.label || '-',
    [deliveryCompanyCode, deliveryCompanyOptions]
  );

  return (
    <div className='flex flex-col gap-1'>
      <div>{deliveryCompanyName}</div>
      <div>{trackingNumber || ''}</div>

      {!disabled && (
        <div className='cursor-pointer px-2 text-primary' onClick={onEditing}>
          수정
        </div>
      )}
    </div>
  );
}
