import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ItemFilterQuery>;
}>;


export type AdminItemsQuery = { __typename?: 'Query', adminItems: { __typename?: 'ItemPageGraphqlType', edges: Array<{ __typename?: 'PaginatedItemGraphqlTypeEdge', node: { __typename?: 'ItemGraphqlType', id: number, stock?: number | null, name: string, code: string } }> } };

export type AdminItemsByMarketIdQueryVariables = Types.Exact<{
  marketId: Types.Scalars['Int']['input'];
}>;


export type AdminItemsByMarketIdQuery = { __typename?: 'Query', adminItemsByMarketId: Array<{ __typename?: 'ItemGraphqlType', id: number, name: string, itemQuantity?: number | null, code: string }> };


export const AdminItemsDocument = gql`
    query AdminItems($filter: ItemFilterQuery) {
  adminItems(filter: $filter) {
    edges {
      node {
        id
        stock
        name
        code
      }
    }
  }
}
    `;

/**
 * __useAdminItemsQuery__
 *
 * To run a query within a React component, call `useAdminItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminItemsQuery(baseOptions?: Apollo.QueryHookOptions<AdminItemsQuery, AdminItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminItemsQuery, AdminItemsQueryVariables>(AdminItemsDocument, options);
      }
export function useAdminItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminItemsQuery, AdminItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminItemsQuery, AdminItemsQueryVariables>(AdminItemsDocument, options);
        }
export type AdminItemsQueryHookResult = ReturnType<typeof useAdminItemsQuery>;
export type AdminItemsLazyQueryHookResult = ReturnType<typeof useAdminItemsLazyQuery>;
export type AdminItemsQueryResult = Apollo.QueryResult<AdminItemsQuery, AdminItemsQueryVariables>;
export const AdminItemsByMarketIdDocument = gql`
    query AdminItemsByMarketId($marketId: Int!) {
  adminItemsByMarketId(marketId: $marketId) {
    id
    name
    itemQuantity
    code
  }
}
    `;

/**
 * __useAdminItemsByMarketIdQuery__
 *
 * To run a query within a React component, call `useAdminItemsByMarketIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminItemsByMarketIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminItemsByMarketIdQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useAdminItemsByMarketIdQuery(baseOptions: Apollo.QueryHookOptions<AdminItemsByMarketIdQuery, AdminItemsByMarketIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminItemsByMarketIdQuery, AdminItemsByMarketIdQueryVariables>(AdminItemsByMarketIdDocument, options);
      }
export function useAdminItemsByMarketIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminItemsByMarketIdQuery, AdminItemsByMarketIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminItemsByMarketIdQuery, AdminItemsByMarketIdQueryVariables>(AdminItemsByMarketIdDocument, options);
        }
export type AdminItemsByMarketIdQueryHookResult = ReturnType<typeof useAdminItemsByMarketIdQuery>;
export type AdminItemsByMarketIdLazyQueryHookResult = ReturnType<typeof useAdminItemsByMarketIdLazyQuery>;
export type AdminItemsByMarketIdQueryResult = Apollo.QueryResult<AdminItemsByMarketIdQuery, AdminItemsByMarketIdQueryVariables>;