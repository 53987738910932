import { Select, SelectProps } from 'antd';
import { DefaultOptionType, BaseOptionType } from 'antd/es/select';
import { get } from 'lodash-es';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from './FieldWithLabel';
import { FieldProps } from './types';

export type SelectFieldProps<
  TFieldValues extends FieldValues,
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
> = SelectProps<ValueType, OptionType> &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
  };
function SelectField<
  TFieldValues extends FieldValues,
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  name,
  label,
  tooltip,
  required,
  control,
  hideError,
  hideErrorMessage,
  ...props
}: SelectFieldProps<TFieldValues, ValueType, OptionType>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const error = get(formState.errors, name);
        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;
        const status = !hideError && errorMessage ? 'error' : '';

        return (
          <FieldWithLabel label={label} required={required} tooltip={tooltip}>
            <Select
              className={'w-full'}
              filterOption={(input, option) => {
                if (!option?.label) {
                  return false;
                }

                if (typeof option.label === 'string') {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }

                return option.label === input;
              }}
              status={status}
              options={[]}
              {...field}
              {...props}
              onChange={(v, option) => {
                field.onChange(v);
                props.onChange?.(v, option);
              }}
            />

            {!hideErrorMessage && !hideError && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}

export default SelectField;
