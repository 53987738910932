import axios, { isAxiosError } from 'axios';

import { authorization } from '../../domain/auth/helper';
import { REISSUE_TOKEN_URL } from '../../domain/auth/url';

const VITE_SERVER_URL = import.meta.env.VITE_SERVER_URL;

export const axiosClient = axios.create({
  baseURL: VITE_SERVER_URL,
  withCredentials: true,
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // reissue 요청은 default 동작을 수행한다.
    if (
      isAxiosError(error) &&
      error.config &&
      error.config.url === REISSUE_TOKEN_URL
    ) {
      return Promise.reject(error);
    }

    if (isAxiosError(error) && error.config && error.response?.status === 401) {
      return authorization.reissue().then((value) => {
        if (error.config?.headers && value) {
          error.config.headers.Authorization = `Bearer ${value}`;
          return axiosClient.request(error.config);
        }
      });
    }

    return Promise.reject(error);
  }
);
