import { DownloadOutlined } from '@ant-design/icons';
import { App, Button, Typography, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useEffect, useState } from 'react';

import FileDnDUploader from '../../../common/components/FileDnDUploader';
import Modal from '../../../common/components/Modal';
import useExcelUpload from '../hooks/useExcelUpload';
import { ExcelUploadedDataType, UploadedDataType } from '../types';

type ExcelUploadModalProps = {
  onClose: () => void;
  endUpload: (fileList: UploadedDataType[]) => void;
};

export default function ExcelUploadModal({
  onClose,
  endUpload,
}: ExcelUploadModalProps) {
  const { message } = App.useApp();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadedList, setUploadedList] = useState<ExcelUploadedDataType[]>([]);
  const { normalizeExcelData } = useExcelUpload();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [disabled, setDisabled] = useState(true);

  const close = () => {
    setIsModalOpen(false);
  };

  const handleUpload = () => {
    try {
      const successData = uploadedList.reduce((acc, cur) => {
        if (cur.status === 'success') {
          acc.push(cur.data);
        }
        return acc;
      }, [] as UploadedDataType[]);
      endUpload(successData);
    } catch (error) {
      if (fileList[0]) {
        const errorFile = Object.assign({}, fileList[0], { status: 'error' });
        setFileList([errorFile]);
      }
    }
  };

  const setNormalizeExcelData = async (fileList: UploadFile[]) => {
    if (fileList[0].status === 'error') {
      void message.error('업로드중 에러가 발생하였습니다. 다시 시도해주세요.');
      setFileList([]);
      return;
    }

    const data = await normalizeExcelData(fileList[0].originFileObj as RcFile);

    if (data.length === 0) {
      void message.error('업로드할 데이터가 없습니다.');
      setFileList([]);
      return;
    }

    if (data.every((item) => item.status === 'error')) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    setUploadedList(data);
  };

  useEffect(() => {
    if (fileList[0]) {
      setNormalizeExcelData(fileList);
    }
  }, [fileList]);

  return (
    <Modal
      title={'엑셀 업로드'}
      open={isModalOpen}
      onOk={handleUpload}
      onCancel={close}
      afterClose={onClose}
      okButtonProps={{
        disabled,
      }}
    >
      <div className='my-6'>
        <Button href={'/form/품목업로드_양식.xlsx'} download>
          <DownloadOutlined /> 양식 다운로드
        </Button>
        <Typography.Text className='my-2 block text-xs' type='danger'>
          *엑셀 업로드 시, 기존 작성내용이 있을경우 덮어쓰게 됩니다.
        </Typography.Text>
        <FileDnDUploader
          fileList={fileList}
          onChange={setFileList}
          maxCount={1}
          description='엑셀파일을 양식에 맞게 업로드해주세요.'
          accept='.xlsx, .xls'
        />
        <div className='mt-2'>
          {uploadedList.map((data, index) => (
            <Typography.Paragraph
              className='m-0 text-xs'
              type={data.status === 'error' ? 'danger' : 'secondary'}
              key={index}
            >
              {data.status === 'error' ? data.message : data.data.name}
            </Typography.Paragraph>
          ))}
        </div>
      </div>
    </Modal>
  );
}
