type CalculatedPricesType = {
  jointPurchasePrice: number | null;
  businessSellerSupplyPrice: number | null;
  businessSellerMargin: number | null;
  businessSellerMarginRate: number | null;
  freelanceSellerSupplyPrice: number | null;
  freelanceSellerMargin: number | null;
  freelanceSellerMarginRate: number | null;
  inHousePurchasePrice: number | null;
  businessInHouseMargin: number | null;
  businessInHouseMarginRate: number | null;
  freelanceInHouseMargin: number | null;
  freelanceInHouseMarginRate: number | null;
};

export const calculateUploadPrices = ({
  jointPurchasePrice,
  businessSellerSupplyPrice,
  inHousePurchasePrice,
}: {
  jointPurchasePrice?: number;
  businessSellerSupplyPrice?: number;
  inHousePurchasePrice?: number;
}) => {
  const productPrices: CalculatedPricesType = {
    jointPurchasePrice: null, // 공동구매가
    businessSellerSupplyPrice: null, // 사업자셀러공급가
    inHousePurchasePrice: null, // 자사매입가
    businessSellerMargin: null, // 사업자셀러마진
    businessSellerMarginRate: null, // 사업자셀러마진율
    freelanceSellerSupplyPrice: null, // 프리랜서셀러공급가
    freelanceSellerMargin: null, // 프리랜서셀러마진
    freelanceSellerMarginRate: null, // 프리랜서셀러마진율
    businessInHouseMargin: null, // 사업자자사마진
    businessInHouseMarginRate: null, // 사업자자사마진율
    freelanceInHouseMargin: null, // 프리랜서자사마진
    freelanceInHouseMarginRate: null, // 프리랜서자사마진율
  };

  // 공동구매가가 없으면 계산 불가
  // 사업자셀러공급가가 없으면 계산 불가
  if (jointPurchasePrice && businessSellerSupplyPrice) {
    // 1. 사업자셀러마진 = 공동구매가 - 사업자셀러공급가
    const businessSellerMargin = jointPurchasePrice - businessSellerSupplyPrice;

    // 2. 사업자셀러마진율 = (공동구매가 - 사업자셀러공급가) / 사업자셀러공급가 * 100
    const businessSellerMarginRate =
      Math.round((businessSellerMargin / jointPurchasePrice) * 100000) / 100000;

    // 3. 프리랜서셀러마진 = 사업자셀러마진 * 1.1
    const freelanceSellerMargin = Math.round(businessSellerMargin / 1.1);

    // 4. 프리랜서셀러공급가 = 사업자셀러공급가 * 1.1
    const freelanceSellerSupplyPrice =
      jointPurchasePrice - freelanceSellerMargin;

    // 5. 프리랜서셀러마진율 = (공동구매가 - 프리랜서셀러공급가) / 프리랜서셀러공급가 * 100
    const freelanceSellerMarginRate =
      Math.round((freelanceSellerMargin / jointPurchasePrice) * 100000) /
      100000;

    productPrices.jointPurchasePrice = jointPurchasePrice;
    productPrices.businessSellerSupplyPrice = businessSellerSupplyPrice;
    productPrices.businessSellerMargin = businessSellerMargin;
    productPrices.businessSellerMarginRate = businessSellerMarginRate;
    productPrices.freelanceSellerSupplyPrice = freelanceSellerSupplyPrice;
    productPrices.freelanceSellerMargin = freelanceSellerMargin;
    productPrices.freelanceSellerMarginRate = freelanceSellerMarginRate;

    if (inHousePurchasePrice) {
      // 6. 사업자자사마진 = 사업자셀러공급가 - 자사매입가
      const businessInHouseMargin =
        businessSellerSupplyPrice - inHousePurchasePrice;

      // 7. 사업자자사마진율 = (사업자셀러공급가 - 자사매입가) / 사업자셀러공급가 * 100
      const businessInHouseMarginRate =
        Math.round((businessInHouseMargin / jointPurchasePrice) * 100000) /
        100000;

      // 8. 프리랜서자사마진 = 프리랜서셀러공급가 - 자사매입가
      const freelanceInHouseMargin =
        freelanceSellerSupplyPrice - inHousePurchasePrice;

      // 9. 프리랜서자사마진율 = (프리랜서셀러공급가 - 자사매입가) / 프리랜서셀러공급가 * 100
      const freelanceInHouseMarginRate =
        Math.round((freelanceInHouseMargin / jointPurchasePrice) * 100000) /
        100000;

      productPrices.inHousePurchasePrice = inHousePurchasePrice;
      productPrices.businessInHouseMargin = businessInHouseMargin;
      productPrices.businessInHouseMarginRate = businessInHouseMarginRate;
      productPrices.freelanceInHouseMargin = freelanceInHouseMargin;
      productPrices.freelanceInHouseMarginRate = freelanceInHouseMarginRate;
    }
  }

  return productPrices;
};
