import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminVendorsInOrderSearchQueryVariables = Types.Exact<{
  vendorsInput: Types.VendorsInput;
}>;


export type AdminVendorsInOrderSearchQuery = { __typename?: 'Query', adminVendors: { __typename?: 'VendorsPayload', vendors: Array<{ __typename?: 'VendorGraphqlType', id: number, name: string }> } };


export const AdminVendorsInOrderSearchDocument = gql`
    query AdminVendorsInOrderSearch($vendorsInput: VendorsInput!) {
  adminVendors(vendorsInput: $vendorsInput) {
    vendors {
      id
      name
    }
  }
}
    `;

/**
 * __useAdminVendorsInOrderSearchQuery__
 *
 * To run a query within a React component, call `useAdminVendorsInOrderSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminVendorsInOrderSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminVendorsInOrderSearchQuery({
 *   variables: {
 *      vendorsInput: // value for 'vendorsInput'
 *   },
 * });
 */
export function useAdminVendorsInOrderSearchQuery(baseOptions: Apollo.QueryHookOptions<AdminVendorsInOrderSearchQuery, AdminVendorsInOrderSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminVendorsInOrderSearchQuery, AdminVendorsInOrderSearchQueryVariables>(AdminVendorsInOrderSearchDocument, options);
      }
export function useAdminVendorsInOrderSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminVendorsInOrderSearchQuery, AdminVendorsInOrderSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminVendorsInOrderSearchQuery, AdminVendorsInOrderSearchQueryVariables>(AdminVendorsInOrderSearchDocument, options);
        }
export type AdminVendorsInOrderSearchQueryHookResult = ReturnType<typeof useAdminVendorsInOrderSearchQuery>;
export type AdminVendorsInOrderSearchLazyQueryHookResult = ReturnType<typeof useAdminVendorsInOrderSearchLazyQuery>;
export type AdminVendorsInOrderSearchQueryResult = Apollo.QueryResult<AdminVendorsInOrderSearchQuery, AdminVendorsInOrderSearchQueryVariables>;