import { cloneDeep } from '@apollo/client/utilities';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, App, Button, Typography } from 'antd';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Modal from '../../../common/components/Modal';
import { useFormError } from '../../../common/hooks/useFormError';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { deleteCacheFields } from '../../../common/utils/apollo';
import { EOrderClaimStatus, EOrderClaimType } from '../../../schema/types';
import {
  CREATE_CLAIM_TITLES,
  CLAIM_STATUS,
  INITIAL_ORDER_CLAIM_STATUS,
  shouldDeleteQueriesAfterClaimUpdate,
  UPDATE_CLAIM_DESCRIPTIONS,
  orderClaimCostBearerOptions,
  settlementTargetOptions,
} from '../const';
import {
  AdminOrderGroupQuery,
  useAdminBulkCreateOrderClaimMutation,
} from '../graphql/createClaim.generated';
import {
  useAdminBulkUpdateOrderClaimMutation,
  useAdminProductGroupClaimFeeQuery,
  useClaimDeliveryFeeQuery,
} from '../graphql/updateClaim.generated';
import { getClaimConfirmMessage, getMaxClaimQuantity } from '../helper/create';
import { getRejectReasonChangeClaim } from '../helper/orderClaim';
import { UpdateClaimFormValues } from '../type';
import { updateClaimSchema } from '../validator';

import ClaimOrderSummary from './ClaimOrderSummary';
import ClaimTargetOrders from './ClaimTargetOrders';
import ExchangeForm from './ExchangeForm';
import ExtraDeliveryForm from './ExtraDeliveryForm';
import PartialClaimForm from './PartialClaimForm';
import PartialClaimsHistory from './PartialClaimsHistory';
import ReturnForm from './ReturnForm';

type UpdateClaimModalProps = {
  targetClaimStatus: EOrderClaimStatus;
  targetClaimType: EOrderClaimType;
  onCancel: () => void;
  orderGroup: AdminOrderGroupQuery['adminOrderGroup'];
  order: AdminOrderGroupQuery['adminOrderGroup']['orders']['0'];
  title?: string;
  productsTitle?: string;
  productsSelectable?: boolean;
};

function UpdateClaimModal({
  targetClaimType,
  targetClaimStatus,
  orderGroup,
  order,
  onCancel,
  title,
  productsTitle,
  productsSelectable,
}: UpdateClaimModalProps) {
  const { hasPermission } = usePermissions(['ORDER_EDIT']);

  const { modal, message } = App.useApp();

  const [updateClaims, { data: updateData }] =
    useAdminBulkUpdateOrderClaimMutation();
  const [createClaims, { data: createData }] =
    useAdminBulkCreateOrderClaimMutation();

  const failureOrderCods =
    [
      ...(createData?.adminBulkCreateOrderClaim.failureOrderIds || []),
      ...(updateData?.adminBulkUpdateOrderClaim.failureOrderIds || []),
    ].map((id) => {
      return orders.find((order) => order.id === id)?.code || '';
    }) || [];

  const maxClaimQuantity = order
    ? getMaxClaimQuantity(order, targetClaimType)
    : 0;
  const activeClaim = order.activeClaim || order.claims?.[0] || null;

  const methods = useForm<UpdateClaimFormValues>({
    defaultValues: {
      reason: activeClaim?.reason || '',
      reasonType:
        activeClaim?.reasonType ||
        (targetClaimType === 'CANCEL' ? 'NULL' : 'ORDER_MISTAKE'),
      status: targetClaimStatus || INITIAL_ORDER_CLAIM_STATUS[targetClaimType],
      target: activeClaim?.target || 'BUYER',
      claimType: targetClaimType,
      isWiredOwnDeliveryFault: !!activeClaim?.isWiredOwnDeliveryFault,
      orders: order
        ? [
            {
              orderId: order.id,
              claimQuantity: maxClaimQuantity,
              activeClaimId: activeClaim?.id,
            },
          ]
        : [],
      hasExtraDelivery: true,
      extraDelivery:
        targetClaimStatus === 'EXCHANGE_COMPLETED' ||
        targetClaimStatus === 'RETURN_COMPLETED'
          ? {
              deliveryFee: 0,
              deliveryFeeBearer: undefined,
              deliveryFeeSettlementPrice: 0,
              deliveryFeeSettlementTarget: undefined,
            }
          : null,
    },
    mode: 'onChange',
    resolver: (data, context, options) => {
      const values = cloneDeep(data);

      if (!values.hasExtraDelivery) {
        values.extraDelivery = null;
      }

      return yupResolver<UpdateClaimFormValues>(updateClaimSchema)(
        values,
        context,
        options
      );
    },
  });
  const { handleSubmit, setValue, formState } = methods;

  const { onInvalid } = useFormError();

  const isDiffStatus = targetClaimStatus !== order.activeClaim?.status;

  const updateOrderClaim = useCallback(
    (orderClaim: UpdateClaimFormValues) => {
      try {
        const validatedOrders = orderClaim.orders.map(
          ({ orderId, claimQuantity, activeClaimId }, i) => {
            const order = orderGroup.orders.find(
              (order) => order.id === orderId
            );

            const rejectReason = order
              ? getRejectReasonChangeClaim(order, {
                  type: targetClaimType,
                  status: targetClaimStatus,
                })
              : '';

            if (rejectReason) {
              throw new Error(rejectReason);
            }

            if (claimQuantity > maxClaimQuantity) {
              setValue(`orders.${i}.claimQuantity`, maxClaimQuantity);
              throw new Error('반품수량이 잔여주문수량을 초과하였습니다.');
            }

            return {
              orderId,
              claimQuantity,
              activeClaimId,
            };
          }
        );

        const updateOrderClaims = validatedOrders
          .filter((order) => !!order.activeClaimId)
          .map(({ activeClaimId }) => ({
            id: activeClaimId as number,
            isWiredOwnDeliveryFault: orderClaim.isWiredOwnDeliveryFault,
            reason: orderClaim.reason,
            reasonType: orderClaim.reasonType,
            claimType: targetClaimType,
            status: targetClaimStatus,
            target: orderClaim.target,
            orderId: order.id,
          }));

        const createOrderClaims = validatedOrders
          .filter((order) => !order.activeClaimId)
          .map(({ orderId, claimQuantity }) => ({
            orderId,
            claimQuantity,
            isWiredOwnDeliveryFault: orderClaim.isWiredOwnDeliveryFault,
            reason: orderClaim.reason,
            reasonType: orderClaim.reasonType,
            claimType: targetClaimType,
            status: targetClaimStatus,
            target: orderClaim.target,
          }));

        const total = validatedOrders.length;
        const updateTotal = updateOrderClaims.length;
        const createTotal = createOrderClaims.length;

        void updateClaims({
          variables: {
            orderClaimsData: {
              updateOrderClaims,
              extraDelivery: orderClaim.extraDelivery
                ? {
                    ...orderClaim.extraDelivery,
                    deliveryFee: orderClaim.extraDelivery?.deliveryFee || 0,
                    deliveryFeeSettlementPrice:
                      orderClaim.extraDelivery?.deliveryFeeSettlementPrice || 0,
                  }
                : null,
            },
            salesChannelType: order.salesChannelType,
          },
          onCompleted(data) {
            if (createOrderClaims.length) {
              void createClaims({
                variables: {
                  orderClaimData: { orderClaims: createOrderClaims },
                },
                onCompleted(data) {
                  const failureOrderIds =
                    data.adminBulkCreateOrderClaim.failureOrderIds;
                  const successOrderIds =
                    data.adminBulkCreateOrderClaim.successOrderIds;

                  if (failureOrderIds.length) {
                    message.warning(
                      `${successOrderIds.length}건 성공/${failureOrderIds.length}건 실패`
                    );
                    return;
                  }

                  message.success(
                    `${CLAIM_STATUS[targetClaimStatus]} 처리가 완료되었습니다.`
                  );
                  onCancel();
                },
                onError(error) {
                  message.error(
                    `${updateTotal}건 성공 / ${createTotal}건 실패 {${
                      error.message ||
                      `${CLAIM_STATUS[targetClaimStatus]} 처리를 실패하였습니다.`
                    }}`
                  );
                },
                update(cache) {
                  deleteCacheFields(cache, shouldDeleteQueriesAfterClaimUpdate);
                },
              });
              return;
            }

            const failureOrderIds =
              data.adminBulkUpdateOrderClaim.failureOrderIds;
            const successOrderIds =
              data.adminBulkUpdateOrderClaim.successOrderIds;

            if (failureOrderIds.length) {
              message.warning(
                `${successOrderIds.length}건 성공/${failureOrderIds.length}건 실패`
              );
              return;
            }

            message.success(
              `${CLAIM_STATUS[targetClaimStatus]} 처리가 완료되었습니다.`
            );
            onCancel();
          },
          onError(error) {
            message.error(
              `0건 성공 / ${total}건 실패 {${
                error.message ||
                `${CLAIM_STATUS[targetClaimStatus]} 처리를 실패하였습니다.`
              }}`
            );
          },
          update(cache) {
            deleteCacheFields(cache, shouldDeleteQueriesAfterClaimUpdate);
          },
        });
      } catch (e: any) {
        message.error(e?.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderGroup.orders, updateClaims, targetClaimType]
  );

  const onSubmit = (orderClaim: UpdateClaimFormValues) => {
    const messages = getClaimConfirmMessage(
      targetClaimType,
      targetClaimStatus,
      order
    );

    modal.warning({
      title: messages.title,
      content: messages.message,
      className: 'whitespace-pre-line',
      centered: true,
      okText: '네',
      cancelText: '아니오',
      okCancel: true,
      onOk() {
        void updateOrderClaim(orderClaim);
      },
    });
  };

  const orders = orderGroup?.orders || [];
  const defaultTitle = CREATE_CLAIM_TITLES[targetClaimType];
  const description = UPDATE_CLAIM_DESCRIPTIONS[targetClaimStatus];

  const withProductSelect =
    targetClaimType === 'RETURN' ||
    targetClaimType === 'CANCEL' ||
    targetClaimType === 'EXCHANGE';
  const withPartialClaimHistory =
    targetClaimType === 'PARTIAL_CANCEL' ||
    targetClaimType === 'PARTIAL_EXCHANGE' ||
    targetClaimType === 'PARTIAL_RETURN';

  useAdminProductGroupClaimFeeQuery({
    variables: { adminProductGroupId: order.productGroupId },
    onCompleted(data) {
      const { returnFee, exchangeFee } = data.adminProductGroup;

      if (targetClaimStatus === 'EXCHANGE_COMPLETED') {
        setValue('extraDelivery', {
          deliveryFeeBearer: 'WIRED',
          deliveryFeeSettlementTarget: 'VENDOR',
          deliveryFee: exchangeFee || null,
          deliveryFeeSettlementPrice: exchangeFee || null,
        });
      }
      if (targetClaimStatus === 'RETURN_COMPLETED') {
        setValue('extraDelivery.deliveryFee', returnFee || null);
        setValue('extraDelivery.deliveryFeeSettlementPrice', returnFee || null);
      }
    },
    skip: targetClaimStatus !== 'RETURN_COMPLETED',
  });

  const { data: productReleaseData } = useClaimDeliveryFeeQuery({
    variables: {
      adminPromotionReleaseId: order.activeClaim?.promotionReleaseId || 0,
      adminProductGroupId: order.productGroupId,
    },
    onCompleted(data) {
      const { exchangeFee } = data.adminProductGroup;
      const { promotion } = data.adminPromotionRelease;
      const {
        deliveryFeeSettlementPrice,
        deliveryFeeSettlementTarget,
        deliveryFeeBearer,
        deliveryFee,
      } = promotion;

      setValue('extraDelivery', {
        deliveryFeeBearer: deliveryFeeBearer || 'WIRED',
        deliveryFeeSettlementTarget: deliveryFeeSettlementTarget || 'VENDOR',
        deliveryFee: deliveryFee || exchangeFee || null,
        deliveryFeeSettlementPrice:
          deliveryFeeSettlementPrice || exchangeFee || null,
      });
    },
    skip:
      targetClaimStatus !== 'EXCHANGE_COMPLETED' ||
      !order.activeClaim?.promotionReleaseId,
  });

  const isDistribution =
    productReleaseData?.adminProductGroup.productGroupType === 'DISTRIBUTION';

  return (
    <Modal
      width={1000}
      title={title || defaultTitle}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      wrapProps={{
        style: { zIndex: 1000 },
      }}
      styles={{
        mask: { zIndex: 1000 },
      }}
    >
      <div className={'pt-4'}>
        <div className={'w-full bg-[#E6F4FF] px-4 py-3'}>
          <Typography.Title
            level={5}
            className={'m-0'}
          >{`와이어드 품목주문코드: ${order?.code || ''}`}</Typography.Title>
        </div>

        {description && (
          <Alert
            showIcon
            type={'warning'}
            className={'mt-4 whitespace-pre-wrap'}
            description={description}
          />
        )}

        <div className={'mt-6 grid gap-12'}>
          <ClaimOrderSummary orderGroup={orderGroup} order={order} />

          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(onSubmit, onInvalid)}
              className={'grid gap-12'}
            >
              {withProductSelect && order?.id && (
                <>
                  {failureOrderCods?.length > 0 && (
                    <Alert
                      showIcon
                      type={'error'}
                      className={'mb-4 whitespace-pre-line'}
                      description={`${
                        failureOrderCods.length
                      }건 실패\n실패 주문 코드: (${failureOrderCods.join(
                        ', '
                      )})`}
                    />
                  )}

                  <ClaimTargetOrders
                    title={productsTitle || `${defaultTitle} 상품선택`}
                    orders={orders}
                    orderId={order?.id}
                    orderGroupCode={orderGroup.code}
                    claimType={targetClaimType}
                    claimStatus={targetClaimStatus}
                    selectable={productsSelectable}
                    onChange={(v) =>
                      setValue('orders', v, {
                        shouldTouch: true,
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  />
                </>
              )}

              {withPartialClaimHistory && order && (
                <>
                  <PartialClaimsHistory order={order} />
                  <PartialClaimForm
                    type={targetClaimType}
                    onCancel={onCancel}
                    submittable
                    maxClaimQuantity={maxClaimQuantity}
                  />
                </>
              )}

              {targetClaimType === 'RETURN' && <ReturnForm />}
              {targetClaimType === 'EXCHANGE' && <ExchangeForm />}

              {targetClaimType === 'RETURN' &&
                targetClaimStatus === 'RETURN_COMPLETED' && (
                  <ExtraDeliveryForm
                    deliveryFeeBearerOptions={
                      isDistribution
                        ? [
                            ...orderClaimCostBearerOptions,
                            { label: '고객', value: 'CUSTOMER' },
                            { label: '업체', value: 'VENDOR' },
                          ]
                        : [
                            ...orderClaimCostBearerOptions,
                            { label: '고객', value: 'CUSTOMER' },
                          ]
                    }
                    settlementTargetOptions={[
                      ...settlementTargetOptions,
                      { label: '고객', value: 'CUSTOMER' },
                    ]}
                  />
                )}

              {targetClaimType === 'EXCHANGE' &&
                targetClaimStatus === 'EXCHANGE_COMPLETED' && (
                  <ExtraDeliveryForm
                    deliveryFeeBearerOptions={orderClaimCostBearerOptions}
                    settlementTargetOptions={settlementTargetOptions}
                  />
                )}

              {(targetClaimType === 'EXCHANGE' ||
                targetClaimType === 'RETURN') && (
                <div className={'flex justify-end gap-2'}>
                  <Button onClick={onCancel}>취소</Button>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    disabled={
                      !hasPermission || !(formState.isDirty || isDiffStatus)
                    }
                  >
                    {title || defaultTitle}
                  </Button>
                </div>
              )}
            </form>
          </FormProvider>
        </div>

        {targetClaimType === 'CANCEL' && (
          <div className={'mt-4 flex justify-end gap-2'}>
            <Button onClick={onCancel}>취소</Button>
            <Button
              type={'primary'}
              htmlType={'submit'}
              onClick={handleSubmit(onSubmit, onInvalid)}
              disabled={!hasPermission}
            >
              취소처리
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default UpdateClaimModal;
