import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';
import { Control, Controller } from 'react-hook-form';

import TextField from '../../../common/components/Fields/TextField';
import usePostcodePopup from '../../../common/hooks/usePostcodePopup';
import { CreateSellerFormValues, UpdateSellerFormValues } from '../types';

type AddressItemFieldProps = {
  index: number;
  control: Control<CreateSellerFormValues | UpdateSellerFormValues>;
  onAddressLoad: (address: { zipCode: string; address: string }) => void;
  onRemove: () => void;
  onIsDefaultChange: () => void;
  hasIsDefaultRadio: boolean;
  disabled: boolean;
  canSetDefault: boolean;
};

function AddressItemField({
  index,
  control,
  onRemove,
  onAddressLoad,
  onIsDefaultChange,
  hasIsDefaultRadio,
  disabled,
  canSetDefault,
}: AddressItemFieldProps) {
  const open = usePostcodePopup((addressData) => {
    const zipCode = addressData.zoneCode;
    const address = addressData.fullAddress;

    onAddressLoad({ zipCode, address });
  });

  return (
    <div
      className={`grid gap-md ${
        hasIsDefaultRadio ? 'grid-cols-textArrayField' : 'grid-cols-1'
      }`}
    >
      <div className={'grid flex-1 gap-md '}>
        <TextField
          readOnly
          disabled={disabled}
          name={`addresses.${index}.zipCode`}
          control={control}
          suffix={
            <Button size={'small'} onClick={open} disabled={disabled}>
              주소검색
            </Button>
          }
          placeholder={'우편번호'}
        />
        <TextField
          readOnly
          disabled={disabled}
          name={`addresses.${index}.address`}
          control={control}
          placeholder={'주소'}
        />
        <TextField
          disabled={disabled}
          name={`addresses.${index}.detailAddress`}
          control={control}
          placeholder={'상세주소'}
        />
      </div>
      {hasIsDefaultRadio && (
        <div className={'flex h-[34px] items-center gap-md'}>
          {!disabled && (
            <Button
              disabled={disabled}
              size={'large'}
              type={'text'}
              htmlType={'button'}
              onClick={onRemove}
              className={
                'flex h-[24px] w-[24px] items-center justify-center border-none p-0 hover:bg-transparent'
              }
              icon={
                <MinusCircleOutlined
                  style={{ fontSize: 21 }}
                  className={'text-black/[.45] hover:text-primary'}
                />
              }
            />
          )}

          <Controller
            render={({ field: controllerField }) => (
              <Radio
                disabled={disabled || !canSetDefault}
                name={controllerField.name}
                checked={controllerField.value as boolean}
                className={'m-0 whitespace-nowrap'}
                onChange={onIsDefaultChange}
              >
                대표
              </Radio>
            )}
            name={`addresses.${index}.isDefault`}
          />
        </div>
      )}
    </div>
  );
}

export default AddressItemField;
