import { Descriptions, Table } from 'antd';

import { bankLabels } from '../../../../common/utils/const';
import { SELLER_SETTLEMENT_LABELS } from '../../../seller/const';
import useReleaseDetail from '../../hooks/useReleaseDetail';

const columns = [
  {
    title: '수령자명',
    dataIndex: 'receiverName',
    key: 'receiverName',
  },
  {
    title: '휴대폰번호',
    dataIndex: 'receiverPhoneNumber',
    key: 'receiverPhoneNumber',
    width: 160,
  },
  {
    title: '우편번호',
    dataIndex: 'zipCode',
    key: 'zipCode',
    width: 120,
  },
  {
    title: '배송지주소',
    dataIndex: 'receiverAddress',
    key: 'receiverAddress',
    width: 240,
  },
  {
    title: '배송메시지',
    dataIndex: 'deliveryMessage',
    key: 'deliveryMessage',
    width: 240,
  },
];

function PromotionReceiverInformation() {
  const { data } = useReleaseDetail();
  if (!data) return null;
  const { adminPromotionRelease } = data;
  const {
    seller,
    sellerSettlementInfo,
    receiverName,
    receiverPhoneNumber,
    receiverAddress,
    receiverDetailAddress,
    zipCode,
    deliveryMessage,
    promotion,
  } = adminPromotionRelease;
  const releaseList = promotion.promotionReleases?.map((release, i) => ({
    key: i,
    ...release,
  }));

  if (seller) {
    return (
      <Descriptions
        bordered
        layout='horizontal'
        column={2}
        labelStyle={{ width: 160 }}
      >
        <Descriptions.Item label='셀러명' contentStyle={{ width: 316 }}>
          {seller.name}
        </Descriptions.Item>
        <Descriptions.Item label='정산유형' contentStyle={{ width: 316 }}>
          {sellerSettlementInfo?.type
            ? SELLER_SETTLEMENT_LABELS[sellerSettlementInfo.type]
            : ''}{' '}
          /{' '}
          {sellerSettlementInfo?.bank
            ? bankLabels[sellerSettlementInfo?.bank]
            : ''}{' '}
          /{' '}
          {sellerSettlementInfo?.accountNumber
            ? sellerSettlementInfo.accountNumber.slice(0, 4)
            : ''}{' '}
          {!!sellerSettlementInfo?.isDefault && '(대표)'}
        </Descriptions.Item>
        <Descriptions.Item label='수령자명' contentStyle={{ width: 316 }}>
          {receiverName}
        </Descriptions.Item>
        <Descriptions.Item label='휴대폰번호' contentStyle={{ width: 316 }}>
          {receiverPhoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label='샘플수령지' contentStyle={{ width: 316 }}>
          {zipCode} {receiverAddress} {receiverDetailAddress}
        </Descriptions.Item>
        <Descriptions.Item label='배송메시지' contentStyle={{ width: 316 }}>
          {deliveryMessage}
        </Descriptions.Item>
      </Descriptions>
    );
  }
  return (
    <Table tableLayout='fixed' columns={columns} dataSource={releaseList} />
  );
}

export default PromotionReceiverInformation;
