import { Descriptions, Divider, Typography } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import {
  CLAIM_REASON_TEXT,
  CLAIM_TYPE_TEXT,
  ORDER_CLAIM_COST_BEARER_TEXT,
  ORDER_CLAIM_SETTLEMENT_TARGET_TEXT,
} from '../const';
import { ClaimType } from '../types';

type ClaimItemProps = ClaimType;

/**
 * TODO: PARTIAL_CANCEL 삭제 예정, 대응해야 함.
 */
export default function ClaimItem({
  claimQuantity,
  claimType,
  reasonType,
  createdAt,
  reason,
  extraDeliveryFee,
  extraDeliveryFeeBearer,
  extraDeliverySettlementPrice,
  extraDeliverySettlementTarget,
}: ClaimItemProps) {
  const reasonLabel = useMemo(() => {
    if (claimType === 'CANCEL' || claimType === 'PARTIAL_CANCEL')
      return '취소사유';
    if (claimType === 'EXCHANGE' || claimType === 'PARTIAL_EXCHANGE')
      return '교환사유';
    if (claimType === 'RETURN' || claimType === 'PARTIAL_RETURN')
      return '반품사유';
  }, [claimType]);

  const claimDateLabel = useMemo(() => {
    if (claimType === 'CANCEL' || claimType === 'PARTIAL_CANCEL')
      return '취소처리일시';
    if (claimType === 'EXCHANGE' || claimType === 'PARTIAL_EXCHANGE')
      return '교환처리일시';
    if (claimType === 'RETURN' || claimType === 'PARTIAL_RETURN')
      return '반품처리일시';
  }, [claimType]);

  const claimQuantityLabel = useMemo(() => {
    if (claimType === 'CANCEL' || claimType === 'PARTIAL_CANCEL')
      return '취소수량';
    if (claimType === 'EXCHANGE' || claimType === 'PARTIAL_EXCHANGE')
      return '교환수량';
    if (claimType === 'RETURN' || claimType === 'PARTIAL_RETURN')
      return '반품수량';
  }, [claimType]);

  const extraDeliveryFeeText = useMemo(() => {
    if (extraDeliveryFee && extraDeliveryFeeBearer) {
      return `${
        ORDER_CLAIM_COST_BEARER_TEXT[extraDeliveryFeeBearer]
      } / ${extraDeliveryFee.toLocaleString()}`;
    }

    return '-';
  }, [extraDeliveryFee, extraDeliveryFeeBearer]);

  const extraDeliverySettlementPriceText = useMemo(() => {
    if (extraDeliverySettlementPrice && extraDeliverySettlementTarget) {
      return `${
        ORDER_CLAIM_SETTLEMENT_TARGET_TEXT[extraDeliverySettlementTarget]
      } / ${extraDeliverySettlementPrice.toLocaleString()}`;
    }

    return '-';
  }, [extraDeliverySettlementPrice, extraDeliverySettlementTarget]);

  return (
    <div>
      <Divider orientation='left' plain className='mt-3'>
        <Typography.Title level={5} className='mb-0'>
          {`${CLAIM_TYPE_TEXT[claimType]} 정보`}
        </Typography.Title>
      </Divider>
      <Descriptions
        bordered
        className='[&_.ant-descriptions-view]:rounded-none'
        column={4}
      >
        <Descriptions.Item
          label={reasonLabel}
          span={2}
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
        >
          {CLAIM_REASON_TEXT[reasonType]}
        </Descriptions.Item>
        <Descriptions.Item
          label={claimDateLabel}
          span={2}
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
        >
          {dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
          label='상세사유'
        >
          {reason}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
          label={claimQuantityLabel}
        >
          {claimQuantity}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
          label='배송비 부담주체'
        >
          {extraDeliveryFeeText}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
          label='배송비 정산대상'
        >
          {extraDeliverySettlementPriceText}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
