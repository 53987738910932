import { RcFile } from 'antd/es/upload';

import { sheetToJson } from '../../../common/utils/excel';
import { EXCEL_DATA_FIELD_NAMES } from '../const';
import { ExcelDataType, ExcelUploadedDataType } from '../types';

export default function useExcelUpload() {
  const validateExcelData = (data: ExcelDataType[]) => {
    const validatedExcelData: {
      status: 'error' | 'success';
      data: ExcelDataType;
      message?: string;
    }[] = [];

    for (let i = 0; i < data.length; i++) {
      try {
        if (!data[i]['*품목명']) throw new Error('품목명은 필수입니다.');

        Object.keys(data[i]).forEach((key) => {
          if (!EXCEL_DATA_FIELD_NAMES.includes(key)) {
            throw new Error(`잘못된 필드명이 포함되어있습니다. : ${key}`);
          }
        });

        validatedExcelData.push({
          status: 'success',
          data: data[i],
        });
      } catch (error) {
        validatedExcelData.push({
          status: 'error',
          data: data[i],
          message: String(error),
        });

        continue;
      }
    }

    return validatedExcelData;
  };

  const normalizeExcelData = async (file: RcFile) => {
    const data = (await sheetToJson(file, {
      header: 0,
      blankrows: false,
      deleteRowNumber: 2,
    })) as ExcelDataType[];

    const validatedExcelData = validateExcelData(data);

    const uploadedData: ExcelUploadedDataType[] = validatedExcelData.map(
      (validatedData) => {
        if (validatedData.status === 'error') {
          return {
            status: 'error',
            message: validatedData.message ?? '알 수 없는 오류가 발생했습니다.',
          };
        }

        return {
          status: 'success',
          data: {
            name: validatedData.data['*품목명'],
            jointPurchasePrice: validatedData.data['공동구매가'],
            taxType: validatedData.data['과세/면세'],
            note: validatedData.data['비고'],
            businessSellerSupplyPrice: validatedData.data['셀러공급가'],
            businessSellerMargin: validatedData.data['셀러마진'],
            businessSellerMarginRate: validatedData.data['셀러마진율'],
            retailPrice: validatedData.data['소비자가'],
            onlineLowestPrice: validatedData.data['온라인최저가'],
            items: validatedData.data['옵션명'],
            businessInHouseMargin: validatedData.data['자사마진'],
            businessInHouseMarginRate: validatedData.data['자사마진율'],
            inHousePurchasePrice: validatedData.data['자사매입가'],
          },
        };
      }
    );

    return uploadedData;
  };

  return { normalizeExcelData };
}
