import { Typography } from 'antd';
import { ReactNode, useState } from 'react';

type SummaryCardProps = {
  label: string | ReactNode;
  count: number;
  onClick?: () => void;
  active?: boolean;
  color: 'blue' | 'green';
  className?: string;
  disabled?: boolean;
};

export default function SummaryCard({
  label,
  count,
  onClick,
  active,
  color,
  className = '',
  disabled,
}: SummaryCardProps) {
  const [focus, setFocus] = useState(false);
  const borderColor = color === 'blue' ? '#91CAFF' : '#B7EB8F';
  const bgColor = color === 'blue' ? '#E6F4FF' : '#F6FFED';
  return (
    <div
      className={`flex ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      } items-center justify-between rounded  p-4 ${className}`}
      style={{
        boxShadow: '0px 0px 4px 0px #0000001A',
        backgroundColor: active || focus ? bgColor : '#00000005',
        border:
          active || focus
            ? `2px solid ${borderColor}`
            : `2px solid transparent`,
      }}
      onMouseOver={disabled ? undefined : () => setFocus(true)}
      onMouseLeave={disabled ? undefined : () => setFocus(false)}
      onClick={disabled ? undefined : onClick}
    >
      <Typography.Text>{label}</Typography.Text>
      <Typography.Title level={3} className='m-0'>
        {disabled ? '-' : `${count}건`}
      </Typography.Title>
    </div>
  );
}
