import { PlusOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import ArrayField from '../../../../common/components/Fields/ArrayField';
import Label from '../../../../common/components/Fields/Label';
import NumberField from '../../../../common/components/Fields/NumberField';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { CreateMarketEventProductInputGraphqlType } from '../../../../schema/types';
import { MARKET_EVENT_PRODUCTS_MAX_LENGTH } from '../../const';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../../types';

import EventProductFreeProvidedSelectField from './EventProductFreeProvidedSelectField';
import EventProductItemsSelectField from './EventProductItemsSelectField';
import ProductPrices from './ProductPrices';
import ProductSelectField from './ProductSelectField';

const defaultEventProduct: Omit<
  CreateMarketEventProductInputGraphqlType,
  'name'
> & {
  name?: string | null;
} = {
  name: null,
  count: 1,
  inhouseBuyPrice: null,
  itemIds: [],
  productId: null,
  sellerBearingCost: null,
  wiredBearingCost: null,
  isWiredBearing: false,
  isSellerBearing: false,
  isFreeProvided: true,
  isPrepayment: null,
};

function EventProducts({ index }: { index: number }) {
  const { hasPermission } = usePermissions('MARKET_EDIT');

  const { control, formState, watch } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();
  const [selectedIndice, setSelectedIndice] = useState<number[]>([]);

  const productsError = formState.errors?.events?.[index]?.products?.message;
  const products = watch(`events.${index}.products`);
  const productKeys = products
    .map(({ isFreeProvided }, i) => (!isFreeProvided ? i : null))
    .filter((value) => typeof value === 'number');
  const eventEditable = hasPermission && watch(`events.${index}.editable`);

  return (
    <div>
      <ArrayField
        name={`events.${index}.products`}
        control={control}
        errorMessage={productsError}
      >
        {({ fields, append, remove }) => {
          return (
            <div>
              {selectedIndice.length > 0 && (
                <Button
                  disabled={!eventEditable}
                  className={'mb-4'}
                  onClick={() => {
                    remove(selectedIndice);
                    setSelectedIndice([]);
                  }}
                >
                  선택삭제
                </Button>
              )}
              <Table
                tableLayout={'fixed'}
                rowSelection={{
                  getCheckboxProps() {
                    return { disabled: !eventEditable || !hasPermission };
                  },
                  onChange(_, selectedRows) {
                    setSelectedIndice(selectedRows.map((row) => row.index));
                  },
                }}
                pagination={false}
                footer={() => (
                  <Button
                    type='text'
                    htmlType={'button'}
                    disabled={
                      !eventEditable ||
                      watch(`events.${index}.products`)?.length >=
                        MARKET_EVENT_PRODUCTS_MAX_LENGTH
                    }
                    onClick={() => {
                      append({
                        ...defaultEventProduct,
                      });
                    }}
                    icon={<PlusOutlined />}
                  >
                    품목추가
                  </Button>
                )}
                dataSource={fields.map((product, index) => ({
                  ...product,
                  index,
                }))}
                expandable={{
                  rowExpandable: () => true,
                  expandedRowKeys: fields
                    .filter((_, i) => productKeys.includes(i))
                    .map(({ key }) => key),
                  showExpandColumn: false,
                  expandedRowRender: ({ index: productIndex }) => {
                    return (
                      <ProductPrices
                        eventIndex={index}
                        productIndex={productIndex}
                      />
                    );
                  },
                }}
                columns={[
                  {
                    title: (
                      <Label
                        tooltip={'PB제품은 와이어드를 업체로 간주합니다.'}
                        required={hasPermission}
                      >
                        업체 유/무상
                      </Label>
                    ),
                    key: 'isFreeProvided',
                    dataIndex: 'isFreeProvided',
                    width: 160,
                    render: (_, record) => (
                      <EventProductFreeProvidedSelectField
                        productIndex={record.index}
                        eventIndex={index}
                        editable={eventEditable}
                      />
                    ),
                  },
                  {
                    title: (
                      <Label
                        tooltip={
                          '기존에 등록해둔 상품의 품목을 선택할 수 있습니다. 등록한 품목이 아닌 품목을 이벤트상품으로 등록하실 경우에는 새롭게 생성해주세요.'
                        }
                        required={eventEditable}
                      >
                        품목명
                      </Label>
                    ),
                    key: 'id',
                    dataIndex: 'id',
                    render: (_, record) => (
                      <ProductSelectField
                        eventIndex={index}
                        productIndex={record.index}
                        editable={eventEditable}
                      />
                    ),
                  },
                  {
                    title: <Label required={eventEditable}>옵션명</Label>,
                    key: 'items',
                    dataIndex: 'items',
                    render: (_, record) => (
                      <EventProductItemsSelectField
                        eventIndex={index}
                        productIndex={record.index}
                        editable={eventEditable}
                      />
                    ),
                  },
                  {
                    title: <Label required={eventEditable}>수량</Label>,
                    key: 'count',
                    dataIndex: 'count',
                    width: 160,
                    render(_, record) {
                      return (
                        <NumberField
                          required
                          controls
                          disabled={
                            !eventEditable ||
                            !watch(
                              `events.${index}.products.${record.index}.name`
                            )
                          }
                          control={control}
                          name={`events.${index}.products.${record.index}.count`}
                        />
                      );
                    },
                  },
                ]}
              />
            </div>
          );
        }}
      </ArrayField>
    </div>
  );
}

export default EventProducts;
