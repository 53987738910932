import { Button, Descriptions, Divider, Input, Select, Table } from 'antd';
import { FormProvider } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import Label from '../../../../common/components/Fields/Label';
import SelectField from '../../../../common/components/Fields/SelectField';
import SwitchField from '../../../../common/components/Fields/SwitchField';
import Modal from '../../../../common/components/Modal';
import {
  DELIVERY_FEE_BEARER_OPTIONS,
  DELIVERY_FEE_SETTLEMENT_TARGET,
  PROMOTION_TYPES,
} from '../../const';
import { usePromotionProductQuery } from '../../graphql/productGroups.generated';
import { AdminPromotionReleaseQuery } from '../../graphql/promotionRelease.generated';
import usePromotionPermission from '../../hooks/usePromotionPermission';
import useUpdateExchangePromotion from '../../hooks/useUpdateExchangePromotion';
import NumberWithUnknownField from '../NumberWithUnknownField';

function ExchangeRedeliveyUpdatePromotionModal({
  onClose,
  promotionRelease,
}: {
  onClose: () => void;
  promotionRelease: AdminPromotionReleaseQuery['adminPromotionRelease'];
}) {
  const {
    promotion,
    receiverName,
    receiverPhoneNumber,
    zipCode,
    receiverAddress,
    receiverDetailAddress,
    deliveryMessage,
    promotionProducts,
    releaseStatus,
  } = promotionRelease;
  const hasPermission = usePromotionPermission();
  const editDisabled = releaseStatus === 'RELEASE_COMPLETED' || !hasPermission;
  const product = promotionProducts[0];
  const productGroupName = product.originalProductGroup?.name;
  const { data: productData } = usePromotionProductQuery({
    variables: { adminProductId: product.originalProductId || 0 },
    skip: !product.originalProductId,
  });

  const { methods, onSubmit } = useUpdateExchangePromotion({
    onClose,
    promotionRelease,
  });
  const productItemOptions = productData?.adminProduct?.items?.map(
    ({ id, name }) => ({ label: name, value: id })
  );
  const {
    control,
    setValue,
    watch,
    formState: { isDirty },
  } = methods;
  const [isDeliveryFee, originalItemIds] = watch([
    'isDeliveryFee',
    'originalItemIds',
  ]);

  return (
    <Modal
      open
      onCancel={onClose}
      title='상세정보 일괄수정'
      width={1000}
      footer={null}
    >
      <div className='flex flex-col gap-4 py-4'>
        <FieldWithLabel label='구분'>
          <Select
            className='w-[120px]'
            options={PROMOTION_TYPES}
            value={promotion.promotionType}
            disabled
          />
        </FieldWithLabel>
        <FieldWithLabel label='교환대상 와이어드품목주문코드'>
          <Input
            className='w-[472px]'
            value={promotion.orderCode || ''}
            disabled
          />
        </FieldWithLabel>
      </div>
      <Divider orientation='left' className='m-0 py-4'>
        수령자정보
      </Divider>
      <Descriptions
        bordered
        layout='horizontal'
        column={2}
        labelStyle={{ width: 160 }}
      >
        <Descriptions.Item label='수령자명' contentStyle={{ width: 316 }}>
          {receiverName}
        </Descriptions.Item>
        <Descriptions.Item label='휴대폰번호' contentStyle={{ width: 316 }}>
          {receiverPhoneNumber}
        </Descriptions.Item>
        <Descriptions.Item label='배송지주소' contentStyle={{ width: 316 }}>
          {zipCode} {receiverAddress} {receiverDetailAddress}
        </Descriptions.Item>
        <Descriptions.Item label='배송메시지' contentStyle={{ width: 316 }}>
          {deliveryMessage}
        </Descriptions.Item>
      </Descriptions>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <Divider orientation='left' className='m-0 py-4'>
            상세정보
          </Divider>
          <div className='my-4 w-[472px]'>
            <FieldWithLabel label='상품명'>
              <Select
                className='w-full'
                options={[{ label: productGroupName, value: productGroupName }]}
                value={productGroupName}
                disabled
              />
            </FieldWithLabel>
          </div>
          <Table
            tableLayout='fixed'
            pagination={false}
            dataSource={[
              {
                key: 0,
                productName: product.originalProduct?.name,
                setCount: product.setCount,
              },
            ]}
          >
            <Table.Column title='품목명' dataIndex={'productName'} />
            {productData?.adminProduct?.useType === 'SELECT' && (
              <Table.Column
                title={<Label required>옵션명</Label>}
                render={() => (
                  <SelectField
                    mode='multiple'
                    disabled={editDisabled}
                    control={control}
                    name='originalItemIds'
                    value={originalItemIds}
                    options={productItemOptions}
                  />
                )}
              />
            )}
            <Table.Column title='수량' width={120} dataIndex={'setCount'} />
          </Table>
          <div className='mt-4 flex flex-col gap-4'>
            <SwitchField
              disabled={editDisabled}
              label='배송비 별도부과'
              control={control}
              name='isDeliveryFee'
            />
            {isDeliveryFee && (
              <>
                <FieldWithLabel label='배송비 부담주체' required>
                  <div className='flex w-[472px] gap-2'>
                    <SelectField
                      disabled={editDisabled}
                      control={control}
                      name='deliveryFeeBearer'
                      options={DELIVERY_FEE_BEARER_OPTIONS}
                    />
                    <NumberWithUnknownField
                      disabled={editDisabled}
                      control={control}
                      name='deliveryFee'
                      onUnknownClick={() => setValue('deliveryFee', null)}
                    />
                  </div>
                </FieldWithLabel>
                <FieldWithLabel label='배송비 정산대상' required>
                  <div className='flex w-[472px] gap-2'>
                    <SelectField
                      disabled={editDisabled}
                      control={control}
                      name='deliveryFeeSettlementTarget'
                      options={DELIVERY_FEE_SETTLEMENT_TARGET}
                    />
                    <NumberWithUnknownField
                      disabled={editDisabled}
                      control={control}
                      name='deliveryFeeSettlementPrice'
                      onUnknownClick={() =>
                        setValue('deliveryFeeSettlementPrice', null)
                      }
                    />
                  </div>
                </FieldWithLabel>
              </>
            )}
          </div>
          <div className='mt-4 flex justify-end gap-2'>
            <Button onClick={onClose}>취소</Button>
            <Button htmlType='submit' type='primary' disabled={!isDirty}>
              일괄수정
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default ExchangeRedeliveyUpdatePromotionModal;
