import { omit } from 'lodash-es';

import {
  CreateProductGroupInputGraphqlType,
  CreateProductGroupMultimediaInputGraphqlType,
  EProductGroupReleaseType,
  UpdateProductGroupInputGraphqlType,
} from '../../../schema/types';
import { ProductGroupQuery } from '../graphql/productGroup.generated';
import {
  CreateProductGroupFormValues,
  ProductGroupFormType,
  ProductGroupMultimediaType,
  ProposalDataType,
  UpdateProductGroupFormValues,
  UploadedDataType,
} from '../types';

import { calculateUploadPrices } from './calculate';
import { productIdGenerator } from './generator';
import { validateMedia } from './validateMedia';

const multimediaMapper = (
  data: CreateProductGroupFormValues | UpdateProductGroupFormValues
) => {
  const detail: CreateProductGroupMultimediaInputGraphqlType[] =
    data.multimedia.detail.map((url) => ({
      useType: 'DETAIL_PAGE',
      url,
      multimediaType: validateMedia(url),
    }));

  const thumbnail: CreateProductGroupMultimediaInputGraphqlType[] =
    data.multimedia.thumbnail.map((url) => ({
      useType: 'THUMBNAIL',
      url,
      multimediaType: validateMedia(url),
    }));

  const marketing: CreateProductGroupMultimediaInputGraphqlType[] =
    data.multimedia?.marketing?.map((url) => ({
      useType: 'MARKETING',
      url,
      multimediaType: validateMedia(url),
    })) ?? [];

  const multimedia = [...detail, ...thumbnail, ...marketing];

  if (data.productImage) {
    multimedia.unshift({
      useType: 'PRIMARY',
      url: data.productImage,
      multimediaType: validateMedia(data.productImage),
    });
  }

  return multimedia;
};

export const createInputMapper: (
  input: CreateProductGroupFormValues
) => CreateProductGroupInputGraphqlType = (input) => {
  const isExistPrimary = input.products.find((product) => product.isPrimary);

  const products = input.products.map((product, index) => ({
    businessInHouseMargin: product.businessInHouseMargin,
    businessInHouseMarginRate: product.businessInHouseMarginRate,
    businessSellerMargin: product.businessSellerMargin,
    businessSellerMarginRate: product.businessSellerMarginRate,
    businessSellerSupplyPrice: product.businessSellerSupplyPrice,
    description: product.description,
    freelanceInHouseMargin: product.freelanceInHouseMargin,
    freelanceInHouseMarginRate: product.freelanceInHouseMarginRate,
    freelanceSellerMargin: product.freelanceSellerMargin,
    freelanceSellerMarginRate: product.freelanceSellerMarginRate,
    freelanceSellerSupplyPrice: product.freelanceSellerSupplyPrice,
    inHousePurchasePrice: product.inHousePurchasePrice,
    items: product.items.map(({ code, name }) => ({
      code,
      name,
    })),
    jointPurchasePrice: product.jointPurchasePrice,
    name: product.name,
    note: product.note,
    onlineLowestPrice: product.onlineLowestPrice,
    retailPrice: product.retailPrice,
    taxType: product.taxType,
    useType: product.useType,
    isAdditionalProduct: product.isAdditionalProduct,
    isPrimary: isExistPrimary ? product.isPrimary : index === 0,
  }));

  return {
    brandId: input.brandId as number, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    categoryId: input.categoryId as number, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    deliveryCompanyCode: input.deliveryCompanyCode as string, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    deliveryCompanyName: input.deliveryCompanyName as string, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    deliveryFeeType: input.deliveryFeeType,
    exchangeFee: input.exchangeFee,
    isPurchaseQuantityLimit: !input.isPurchaseQuantityNoLimit,
    purchaseQuantityLimit: input.purchaseQuantityLimit,
    isRegionFee: input.isRegionFee,
    jejuIslandFee: input.jejuIslandFee,
    additionalFee: input.additionalFee,
    managerId: input.managerId,
    multimedia: multimediaMapper(input),
    name: input.name,
    note: input.note,
    notice: input.notice,
    productUrl: input.productUrl,
    productUsp: input.productUsp,
    products,
    deliveryFee: input.deliveryFee,
    freeDeliveryConditionFee: input.freeDeliveryConditionFee,
    releaseType: input.releaseType as EProductGroupReleaseType, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    releaseTime: input.releaseTime,
    returnFee: input.returnFee,
    sampleSupport: input.sampleSupport,
    status: input.status,
    vendorId: input.vendorId,
    productGroupType: input.productGroupType,
  };
};

export const updateInputMapper: (
  input: UpdateProductGroupFormValues
) => UpdateProductGroupInputGraphqlType = (input) => {
  const isExistPrimary = input.products.find((product) => product.isPrimary);

  const products = input.products.map((product, index) => ({
    businessInHouseMargin: product.businessInHouseMargin,
    businessInHouseMarginRate: product.businessInHouseMarginRate,
    businessSellerMargin: product.businessSellerMargin,
    businessSellerMarginRate: product.businessSellerMarginRate,
    businessSellerSupplyPrice: product.businessSellerSupplyPrice,
    description: product.description,
    freelanceInHouseMargin: product.freelanceInHouseMargin,
    freelanceInHouseMarginRate: product.freelanceInHouseMarginRate,
    freelanceSellerMargin: product.freelanceSellerMargin,
    freelanceSellerMarginRate: product.freelanceSellerMarginRate,
    freelanceSellerSupplyPrice: product.freelanceSellerSupplyPrice,
    id: product.isNew ? undefined : product.id,
    inHousePurchasePrice: product.inHousePurchasePrice,
    items: product.items.map(({ code, name }) => ({
      code,
      name,
    })),
    jointPurchasePrice: product.jointPurchasePrice,
    name: product.name,
    note: product.note,
    onlineLowestPrice: product.onlineLowestPrice,
    retailPrice: product.retailPrice,
    taxType: product.taxType,
    useType: product.useType,
    isAdditionalProduct: product.isAdditionalProduct,
    isPrimary: isExistPrimary ? product.isPrimary : index === 0,
  }));

  return {
    brandId: input.brandId as number, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    categoryId: input.categoryId as number, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    deliveryCompanyCode: input.deliveryCompanyCode as string, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    deliveryCompanyName: input.deliveryCompanyName as string, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    deliveryFeeType: input.deliveryFeeType,
    exchangeFee: input.exchangeFee,
    isPurchaseQuantityLimit: !input.isPurchaseQuantityNoLimit,
    purchaseQuantityLimit: input.purchaseQuantityLimit,
    isRegionFee: input.isRegionFee,
    jejuIslandFee: input.jejuIslandFee,
    additionalFee: input.additionalFee,
    managerId: input.managerId,
    multimedia: multimediaMapper(input),
    name: input.name,
    note: input.note,
    notice: input.notice,
    productUrl: input.productUrl,
    productUsp: input.productUsp,
    products,
    deliveryFee: input.deliveryFee,
    freeDeliveryConditionFee: input.freeDeliveryConditionFee,
    releaseType: input.releaseType as EProductGroupReleaseType, // form resetField를 위해 타입 null 추가되어 다시 타입 단언
    releaseTime: input.releaseTime,
    returnFee: input.returnFee,
    sampleSupport: input.sampleSupport,
    status: input.status,
    vendorId: input.vendorId,
    productGroupType: input.productGroupType,
  };
};

/**
 * 상품상세 페이지 진입시
 * ProductGroupQuery -> defaultValue로 변환
 */
export const defaultValuesMapper = (
  input: Exclude<ProductGroupQuery['adminProductGroup'], undefined | null>
) => {
  let productImage = '';

  const defaultMultimedia: ProductGroupMultimediaType = {
    thumbnail: [],
    detail: [],
    marketing: [],
  };

  const multimedia = input.multimedia.reduce((acc, cur) => {
    switch (cur.useType) {
      case 'PRIMARY':
        productImage = cur.url;
        break;
      case 'DETAIL_PAGE':
        acc.detail.push(cur.url);
        break;
      case 'THUMBNAIL':
        acc.thumbnail.push(cur.url);
        break;
      case 'MARKETING':
        if (acc.marketing) acc.marketing.push(cur.url);
        break;
      default:
        break;
    }

    return acc;
  }, defaultMultimedia);

  const products = input.products.map((product) => ({
    businessInHouseMargin: product.businessInHouseMargin,
    businessInHouseMarginRate: product.businessInHouseMarginRate,
    businessSellerMargin: product.businessSellerMargin,
    businessSellerMarginRate: product.businessSellerMarginRate,
    businessSellerSupplyPrice: product.businessSellerSupplyPrice,
    description: product.description ?? undefined,
    freelanceInHouseMargin: product.freelanceInHouseMargin,
    freelanceInHouseMarginRate: product.freelanceInHouseMarginRate,
    freelanceSellerMargin: product.freelanceSellerMargin,
    freelanceSellerMarginRate: product.freelanceSellerMarginRate,
    freelanceSellerSupplyPrice: product.freelanceSellerSupplyPrice,
    id: product.id,
    inHousePurchasePrice: product.inHousePurchasePrice,
    items: product.items?.map((item) => ({
      code: item.code,
      id: item.id,
      isInfinityStock: item.isInfinityStock,
      name: item.name,
      productGroupNames: item.productGroupNames ?? undefined,
      stock: item.stock ?? undefined,
    })),
    jointPurchasePrice: product.jointPurchasePrice,
    name: product.name,
    note: product.note ?? undefined,
    onlineLowestPrice: product.onlineLowestPrice,
    retailPrice: product.retailPrice,
    taxType: product.taxType,
    useType: product.useType,
    isAdditionalProduct: product.isAdditionalProduct, // isAdditionalProduct필드 추가 후 수정
    isPrimary: product.isPrimary,
    code: product.code,
  }));

  return {
    formType: 'UPDATE' as ProductGroupFormType,
    additionalFee: input.additionalFee ?? undefined,
    productImage,
    brandId: input.brandId,
    categoryId: input.category.categoryId,
    upperCategoryId: input.category.upperCategoryId,
    uppermostCategoryId: input.category.uppermostCategoryId,
    deliveryCompanyCode: input.deliveryCompanyCode ?? undefined,
    deliveryCompanyName: input.deliveryCompanyName ?? undefined,
    deliveryFee: input.deliveryFee ?? undefined,
    deliveryFeeType: input.deliveryFeeType,
    exchangeFee: input.exchangeFee ?? undefined,
    freeDeliveryConditionFee: input.freeDeliveryConditionFee ?? undefined,
    isPurchaseQuantityLimit: input.isPurchaseQuantityLimit,
    purchaseQuantityLimit: input.purchaseQuantityLimit,
    isRegionFee: input.isRegionFee,
    jejuIslandFee: input.jejuIslandFee ?? undefined,
    managerId: input.managerId,
    multimedia,
    name: input.name,
    note: input.note ?? undefined,
    notice: input.notice ?? undefined,
    productUrl: input.productUrl ?? undefined,
    productUsp: input.productUsp ?? undefined,
    products,
    isPurchaseQuantityNoLimit: !input.isPurchaseQuantityLimit,
    releaseTime: input.releaseTime ?? undefined,
    releaseType: input.releaseType,
    returnFee: input.returnFee ?? undefined,
    sampleSupport: input.sampleSupport ?? undefined,
    status: input.status,
    vendorId: input.vendorId,
    vendorName: input.vendor.name,
    brandName: input.brand.name,
    productGroupType: input.productGroupType,
  };
};

/**
 * productGroup복제시
 * ProductGroupQuery -> CreateProductGroupInputGraphqlType 변환
 */
export const duplicateMapper = (
  input: Exclude<ProductGroupQuery['adminProductGroup'], undefined | null>
) => {
  const products = input.products.map((product) => ({
    businessInHouseMargin: product.businessInHouseMargin,
    businessInHouseMarginRate: product.businessInHouseMarginRate,
    businessSellerMargin: product.businessSellerMargin,
    businessSellerMarginRate: product.businessSellerMarginRate,
    businessSellerSupplyPrice: product.businessSellerSupplyPrice,
    description: product.description ?? undefined,
    freelanceInHouseMargin: product.freelanceInHouseMargin,
    freelanceInHouseMarginRate: product.freelanceInHouseMarginRate,
    freelanceSellerMargin: product.freelanceSellerMargin,
    freelanceSellerMarginRate: product.freelanceSellerMarginRate,
    freelanceSellerSupplyPrice: product.freelanceSellerSupplyPrice,
    inHousePurchasePrice: product.inHousePurchasePrice,
    items:
      product.items?.map((item) => ({
        code: item.code,
        name: item.name,
      })) ?? [],
    jointPurchasePrice: product.jointPurchasePrice,
    name: product.name,
    note: product.note ?? undefined,
    onlineLowestPrice: product.onlineLowestPrice,
    retailPrice: product.retailPrice,
    taxType: product.taxType,
    useType: product.useType,
    isAdditionalProduct: product.isAdditionalProduct,
    isPrimary: product.isPrimary,
  }));

  return {
    additionalFee: input.additionalFee ?? undefined,
    brandId: input.brandId,
    categoryId: input.categoryId,
    deliveryCompanyCode: input.deliveryCompanyCode ?? '',
    deliveryCompanyName: input.deliveryCompanyName ?? '',
    deliveryFee: input.deliveryFee ?? undefined,
    deliveryFeeType: input.deliveryFeeType,
    exchangeFee: input.exchangeFee ?? undefined,
    freeDeliveryConditionFee: input.freeDeliveryConditionFee ?? undefined,
    isPurchaseQuantityLimit: input.isPurchaseQuantityLimit,
    isRegionFee: input.isRegionFee,
    jejuIslandFee: input.jejuIslandFee ?? undefined,
    managerId: input.managerId,
    multimedia: input.multimedia.map((media) => omit(media, ['__typename'])),
    name: `${input.name} (복사)`,
    note: input.note ?? undefined,
    notice: input.notice ?? undefined,
    productUrl: input.productUrl ?? undefined,
    productUsp: input.productUsp ?? undefined,
    products,
    releaseTime: input.releaseTime ?? undefined,
    releaseType: input.releaseType,
    returnFee: input.returnFee ?? undefined,
    sampleSupport: input.sampleSupport ?? undefined,
    status: input.status,
    vendorId: input.vendorId,
    productGroupType: input.productGroupType,
  };
};

export const proposalMapper: (
  input: Exclude<ProductGroupQuery['adminProductGroup'], undefined | null>
) => ProposalDataType[] = (
  input: Exclude<ProductGroupQuery['adminProductGroup'], undefined | null>
) => {
  return input.products.map((product) => ({
    key: product.id,
    name: product.name,
    items: product.items?.map((item) => item.name) ?? [],
    retailPrice: product.retailPrice,
    onlineLowestPrice: product.onlineLowestPrice,
    jointPurchasePrice: product.jointPurchasePrice,
    businessSellerSupplyPrice: product.businessSellerSupplyPrice,
    businessSellerMargin: product.businessSellerMargin,
    businessSellerMarginRate: product.businessSellerMarginRate,
    freelanceSellerSupplyPrice: product.freelanceSellerSupplyPrice,
    freelanceSellerMargin: product.freelanceSellerMargin,
    freelanceSellerMarginRate: product.freelanceSellerMarginRate,
  }));
};

export const uploadedProductMapper = (
  data: UploadedDataType[],
  productGroupName: string
) => {
  return data.map((product) => {
    const taxType = product.taxType === '면세' ? 'FREE' : 'TAX';

    const {
      jointPurchasePrice,
      businessSellerSupplyPrice,
      businessSellerMargin,
      businessSellerMarginRate,
      freelanceSellerSupplyPrice,
      freelanceSellerMargin,
      freelanceSellerMarginRate,
      inHousePurchasePrice,
      businessInHouseMargin,
      businessInHouseMarginRate,
      freelanceInHouseMargin,
      freelanceInHouseMarginRate,
    } = calculateUploadPrices({
      jointPurchasePrice: product.jointPurchasePrice,
      businessSellerSupplyPrice: product.businessSellerSupplyPrice,
      inHousePurchasePrice: product.inHousePurchasePrice,
    });

    return {
      retailPrice: product.retailPrice ?? null, // 소비자가
      onlineLowestPrice: product.onlineLowestPrice ?? null, // 온라인최저가
      jointPurchasePrice, //공동구매가
      businessSellerSupplyPrice, // 사업자셀러공급가
      businessSellerMargin, //사업자셀러마진
      businessSellerMarginRate, // 사업자셀러마진율
      freelanceSellerSupplyPrice, // 프리랜서셀러공급가
      freelanceSellerMargin, // 프리랜서셀러마진
      freelanceSellerMarginRate, // 프리랜서셀러마진율
      inHousePurchasePrice, // 자사매입가
      businessInHouseMargin, // 사업자자사마진
      businessInHouseMarginRate, // 사업자자사마진율
      freelanceInHouseMargin, // 프리랜서자사마진
      freelanceInHouseMarginRate, // 프리랜서자사마진율
      id: productIdGenerator(),
      description: '',
      isNew: true,
      items:
        product.items?.split('/')?.map((name) => ({
          name: name,
          productGroupNames: [productGroupName],
        })) ?? null,

      name: product.name ?? null,
      note: product.note ?? '',
      taxType,
      useType: 'FIX',
      isAdditionalProduct: false,
      isPrimary: false,
    };
  });
};

export const appendProductMapper = () => {
  return {
    useType: 'FIX',
    taxType: 'TAX',
    isAdditionalProduct: false,
    isPrimary: false,
    id: productIdGenerator(),
    isNew: true,
    businessInHouseMargin: null,
    businessInHouseMarginRate: null,
    businessSellerMargin: null,
    businessSellerMarginRate: null,
    businessSellerSupplyPrice: null,
    description: '',
    freelanceInHouseMargin: null,
    freelanceInHouseMarginRate: null,
    freelanceSellerMargin: null,
    freelanceSellerMarginRate: null,
    freelanceSellerSupplyPrice: null,
    inHousePurchasePrice: null,
    items: null,
    jointPurchasePrice: null,
    name: null,
    note: '',
    onlineLowestPrice: null,
    retailPrice: null,
  };
};
