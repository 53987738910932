import { useFormContext } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import NumberField from '../../../../common/components/Fields/NumberField';
import { OrderListTableFilterFormValues } from '../../types';

export default function OrderAmountField() {
  const { control, getValues, setValue } =
    useFormContext<OrderListTableFilterFormValues>();

  return (
    <FieldWithLabel label={'주문금액 범위'}>
      <div className='flex items-center gap-2'>
        <NumberField
          control={control}
          name='startOrderAmount'
          suffix={'원'}
          numberType='price'
          placeholder='최소금액'
          hideErrorMessage
          status={undefined}
          onBlur={() => {
            const { startOrderAmount } = getValues();

            if (!startOrderAmount) setValue('startOrderAmount', null);
          }}
        />
        <span className=''>~</span>
        <NumberField
          control={control}
          name='endOrderAmount'
          suffix={'원'}
          numberType='price'
          placeholder='최대금액'
          hideErrorMessage
          status={undefined}
          onBlur={() => {
            const { endOrderAmount } = getValues();

            if (!endOrderAmount) setValue('endOrderAmount', null);
          }}
        />
      </div>
    </FieldWithLabel>
  );
}
