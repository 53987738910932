import { omit, set } from 'lodash-es';

import {
  SellerAddressGraphqlInputType,
  SellerEmailGraphqlInputType,
} from '../../../schema/types';
import { SellerQuery } from '../graphql/seller.generated';
import { UpdateSellerFormValues } from '../types';

const removeTypename = (data: Record<string, any>) => {
  return omit(data, '__typename');
};

const defaultAddress: SellerAddressGraphqlInputType = {
  zipCode: '',
  address: '',
  detailAddress: '',
  isDefault: true,
};

const defaultEmail: SellerEmailGraphqlInputType = {
  email: '',
  isDefault: true,
};

export const defaultSettlement = {
  type: null,
  isDefault: true,
  accountHolderName: null,
  accountNumber: null,
  accountImageUrl: null,
  bank: null,
  businessRegistrationNumber: null,
  businessRegistrationImageUrl: null,
  companyName: null,
  residentRegistrationNumberBack: null,
  residentRegistrationNumberFront: null,
  residentRegistrationImageUrl: null,
  sellerName: null,
};

export const toSellerFormValues = (
  input: Exclude<SellerQuery['adminSeller'], undefined | null>
): UpdateSellerFormValues => {
  const managerId = input.manager?.id;
  const result = omit(input, ['__typename', 'manager']);

  set(
    result,
    'emails',
    result.emails?.length ? result.emails.map(removeTypename) : [defaultEmail]
  );
  set(
    result,
    'links',
    result.links?.length > 0
      ? result.links.map(removeTypename)
      : [{ url: '', isDefault: true }]
  );
  set(
    result,
    'addresses',
    result.addresses?.length
      ? result.addresses.map(removeTypename)
      : [defaultAddress]
  );
  set(result, 'settlementInfos', result.settlementInfos.map(removeTypename));

  return {
    ...result,
    managerId,
    isUniquePhoneNumber: !!result.phoneNumber,
    isCheckedPhoneNumber: !!result.phoneNumber,
  };
};
