import { SelectProps } from 'antd';
import { get } from 'lodash-es';
import { useCallback } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import { FieldProps } from '../../../common/components/Fields/types';
import AsyncSelect from '../../../common/components/Input/AsyncSelect';
import {
  SellerOptionsQuery,
  useSellerOptionsLazyQuery,
} from '../graphql/seller.generated';

export type SellerOption = SellerOptionsQuery['adminSellers']['sellers']['0'];

export type SelectFieldProps<TFieldValues extends FieldValues> = SelectProps &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
    initialOption?: { value: number; label: string };
  };

function SellersSelectField<TFieldValues extends FieldValues>({
  name,
  label,
  required,
  control,
  hideError,
  onChange,
  ...props
}: SelectFieldProps<TFieldValues>) {
  const [fetchSeller] = useSellerOptionsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const fetchItems = useCallback(
    async (v: string) => {
      const { data } = await fetchSeller({
        variables: {
          sellersInput: {
            search: { types: ['NAME'], keyword: v },
            pagination: { skip: 0, take: 20 },
          },
        },
      });

      return (data?.adminSellers.sellers || []).map((seller) => {
        return {
          label: seller.name,
          value: seller.id,
          data: seller,
        };
      });
    },
    [fetchSeller]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const error = get(formState.errors, name);
        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;
        const status = !hideError && errorMessage ? 'error' : '';

        return (
          <FieldWithLabel label={label} required={required}>
            <AsyncSelect
              initialLoad
              className={'w-full'}
              fetchItems={fetchItems}
              status={status}
              {...field}
              onChange={(v, option) => {
                field.onChange(v);

                onChange?.(v, option);
              }}
              {...props}
            />

            {!hideError && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}

export default SellersSelectField;
