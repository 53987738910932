import { DownloadOutlined } from '@ant-design/icons';
import { App, Button, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useEffect, useState } from 'react';

import FileDnDUploader from '../../../common/components/FileDnDUploader';
import Modal from '../../../common/components/Modal';
import { sheetToJson } from '../../../common/utils/excel';
import { InvoiceExcelDataType } from '../../product/types';
import {
  AdminCountOrderStatusesDocument,
  AdminOrdersDocument,
  useAdminBulkPatchOrderForInvoiceExcelMutation,
  useAdminDeliveryCompaniesLazyQuery,
} from '../graphql/orders.generated';
import { convertDeliveryCompanyCode } from '../helper';

type InvoiceExcelUploadModalProps = {
  onClose: () => void;
};

export default function InvoiceExcelUploadModal({
  onClose,
}: InvoiceExcelUploadModalProps) {
  const { message } = App.useApp();
  const [fetchDeliveryCompanies] = useAdminDeliveryCompaniesLazyQuery();
  const [mutateBulkPatchOrderForInvoiceExcelMutation] =
    useAdminBulkPatchOrderForInvoiceExcelMutation();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [disabled, setDisabled] = useState(true);

  const normalizeExcelData = async (file: RcFile) => {
    const { data: deliveryData } = await fetchDeliveryCompanies();
    const deliveryCompanies = deliveryData?.adminDeliveryCompanies ?? [];

    const data = (await sheetToJson(file, {
      removeCol: {
        start: 'D',
        end: 'C',
      },
    })) as InvoiceExcelDataType[];

    const isInvoiceExcel = data.every(
      (invoice) =>
        invoice.송장번호 && invoice.와이어드품목주문코드 && invoice.택배사코드
    );

    if (!isInvoiceExcel) throw new Error('잘못된 업로드 양식입니다.');

    return data.map((invoice) => {
      const deliveryCompanyCode = convertDeliveryCompanyCode(
        invoice.택배사코드
      );

      return {
        code: invoice.와이어드품목주문코드,
        trackingNumber: invoice.송장번호,
        deliveryCompanyCode: deliveryCompanyCode,
        deliveryCompanyName:
          deliveryCompanies.find((company) => {
            return company.code === deliveryCompanyCode;
          })?.name ?? '',
      };
    });
  };

  const handleUpload = async () => {
    try {
      const orders = await normalizeExcelData(
        fileList[0].originFileObj as RcFile
      );

      if (orders.length === 0) {
        void message.error('업로드할 데이터가 없습니다.');
        return;
      }

      await mutateBulkPatchOrderForInvoiceExcelMutation({
        variables: {
          orderData: {
            orders,
          },
        },
        refetchQueries: [AdminOrdersDocument, AdminCountOrderStatusesDocument],
        onError({ message: errorMessage }) {
          void message.error(errorMessage);
        },
        onCompleted(data) {
          const {
            message: failReason,
            failCount,
            successCount,
          } = data.adminBulkPatchOrderForInvoiceExcel;
          const successMessage = `${successCount ?? 0}건 성공`;
          const failMessage = failCount
            ? ` / ${failCount}건 실패. (${failReason})`
            : '';

          if (failMessage) {
            void message.warning(successMessage + failMessage);
          } else {
            void message.success(successMessage);
          }

          onClose();
        },
      });
    } catch (error) {
      const errorMessage = String(error) ?? '업로드에 실패했습니다.';
      void message.error(errorMessage);
    }
  };

  useEffect(() => {
    if (fileList[0]) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [fileList]);

  return (
    <Modal
      title='송장일괄업로드'
      onOk={handleUpload}
      onCancel={onClose}
      okButtonProps={{
        disabled,
      }}
    >
      <div className='my-6'>
        <Button href={'/form/송장업로드_양식.xlsx'} download>
          <DownloadOutlined /> 양식 다운로드
        </Button>
        <div className='my-6'>
          <FileDnDUploader
            fileList={fileList}
            onChange={setFileList}
            accept='.xlsx, .xls'
            maxCount={1}
            description='엑셀파일을 양식에 맞게 업로드해주세요.'
          />
        </div>
      </div>
    </Modal>
  );
}
