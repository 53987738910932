import * as Types from '../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketsInSelectFieldQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MarketPageQueryFilter>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type MarketsInSelectFieldQuery = { __typename?: 'Query', adminMarkets: { __typename?: 'AdminMarketPageGraphqlType', edges: Array<{ __typename?: 'PaginatedMarketGraphqlTypeEdge', node: { __typename?: 'MarketGraphqlType', id: number, name: string } }> } };


export const MarketsInSelectFieldDocument = gql`
    query MarketsInSelectField($filter: MarketPageQueryFilter, $pagination: Pagination) {
  adminMarkets(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useMarketsInSelectFieldQuery__
 *
 * To run a query within a React component, call `useMarketsInSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketsInSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketsInSelectFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMarketsInSelectFieldQuery(baseOptions?: Apollo.QueryHookOptions<MarketsInSelectFieldQuery, MarketsInSelectFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketsInSelectFieldQuery, MarketsInSelectFieldQueryVariables>(MarketsInSelectFieldDocument, options);
      }
export function useMarketsInSelectFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketsInSelectFieldQuery, MarketsInSelectFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketsInSelectFieldQuery, MarketsInSelectFieldQueryVariables>(MarketsInSelectFieldDocument, options);
        }
export type MarketsInSelectFieldQueryHookResult = ReturnType<typeof useMarketsInSelectFieldQuery>;
export type MarketsInSelectFieldLazyQueryHookResult = ReturnType<typeof useMarketsInSelectFieldLazyQuery>;
export type MarketsInSelectFieldQueryResult = Apollo.QueryResult<MarketsInSelectFieldQuery, MarketsInSelectFieldQueryVariables>;