import { Divider, Switch } from 'antd';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import SelectField from '../../../../common/components/Fields/SelectField';
import TextField from '../../../../common/components/Fields/TextField';
import TextNumberField from '../../../../common/components/Fields/TextNumberField';
import { formSetValueOption } from '../../../../common/utils/const';
import { AdminUpdatePromotionReleaseMutationVariables } from '../../graphql/promotionRelease.generated';
import usePromotionPermission from '../../hooks/usePromotionPermission';
import useReleaseDetail from '../../hooks/useReleaseDetail';
import useSellerReceiver, {
  PromotionSellerAddressItem,
  PromotionSellerItem,
  PromotionSellerSettlementItem,
} from '../../hooks/useSellerReceiver';
import Dropdown from '../Dropdown';
import SellerSelectField from '../SellerSelectField';

function ReceiverInformation() {
  const hasPermission = usePromotionPermission();
  const { control, setValue, watch } =
    useFormContext<AdminUpdatePromotionReleaseMutationVariables>();
  const [sellerId, sellerSettlementInfoId] = watch([
    'promotionReleaseData.sellerId',
    'promotionReleaseData.sellerSettlementInfoId',
  ]);
  const { data, isCompleted } = useReleaseDetail();
  const editable = hasPermission && !isCompleted;
  const sellerCallback = useCallback(
    (selectedSeller: PromotionSellerItem) => {
      setValue('promotionReleaseData.sellerId', selectedSeller.id);
      setValue(
        'promotionReleaseData.receiverPhoneNumber',
        selectedSeller.phoneNumber
      );

      const defaultSettlementInfo = selectedSeller.settlementInfos.find(
        ({ isDefault }) => isDefault
      );
      const defaultAddressInfo = selectedSeller.addresses.find(
        ({ isDefault }) => isDefault
      );
      if (defaultSettlementInfo) {
        setValue(
          'promotionReleaseData.sellerSettlementInfoId',
          defaultSettlementInfo.id
        );
      }

      setValue(
        'promotionReleaseData.zipCode',
        defaultAddressInfo?.zipCode || ''
      );
      setValue(
        'promotionReleaseData.receiverAddress',
        defaultAddressInfo?.address || ''
      );
      setValue(
        'promotionReleaseData.receiverDetailAddress',
        defaultAddressInfo?.detailAddress || ''
      );
    },
    [setValue]
  );
  const settlementCallback = useCallback(
    (selectedSettlement: PromotionSellerSettlementItem) => {
      setValue(
        'promotionReleaseData.sellerSettlementInfoId',
        selectedSettlement.id
      );

      if (selectedSettlement.type === 'FREELANCER') {
        setValue(
          'promotionReleaseData.receiverName',
          selectedSettlement.sellerName || ''
        );
      }
    },
    [setValue]
  );

  const addressCallback = useCallback(
    (selectedAddress: PromotionSellerAddressItem) => {
      setValue(
        'promotionReleaseData.zipCode',
        selectedAddress.zipCode,
        formSetValueOption
      );
      setValue(
        'promotionReleaseData.receiverAddress',
        selectedAddress.address,
        formSetValueOption
      );
      setValue(
        'promotionReleaseData.receiverDetailAddress',
        selectedAddress.detailAddress,
        formSetValueOption
      );
    },
    [setValue]
  );

  const {
    addressOptions,
    settlementOptions,
    handleAddressSelect,
    handleSettlementSelect,
    handleSellerSelect,
    sellerInfo,
  } = useSellerReceiver({
    sellerId,
    addressCallback,
    settlementCallback,
    sellerCallback,
  });
  useEffect(() => {
    if (sellerInfo && sellerId !== data?.adminPromotionRelease.seller?.id) {
      // seller 를 변경시 정산유형, 수령지 정보를 default 값으로 수정해준다.
      const defaultSettlementInfo = sellerInfo.adminSeller.settlementInfos.find(
        ({ isDefault }) => isDefault
      );
      const defaultAddressInfo = sellerInfo.adminSeller.addresses.find(
        ({ isDefault }) => isDefault
      );
      if (defaultSettlementInfo) {
        setValue(
          'promotionReleaseData.sellerSettlementInfoId',
          defaultSettlementInfo.id
        );
        if (defaultSettlementInfo.type === 'FREELANCER') {
          setValue(
            'promotionReleaseData.receiverName',
            defaultSettlementInfo.sellerName || ''
          );
        }
      }

      setValue(
        'promotionReleaseData.zipCode',
        defaultAddressInfo?.zipCode || ''
      );
      setValue(
        'promotionReleaseData.receiverAddress',
        defaultAddressInfo?.address || ''
      );
      setValue(
        'promotionReleaseData.receiverDetailAddress',
        defaultAddressInfo?.detailAddress || ''
      );
    }
  }, [sellerInfo, setValue, sellerId, data]);
  if (!data) return null;

  const { adminPromotionRelease } = data;
  const isSeller = !!adminPromotionRelease.seller;

  return (
    <div>
      <Divider orientation='left' className='m-0 py-4'>
        수령자정보
      </Divider>
      <div className='flex w-[472px] flex-col gap-6'>
        <div className='flex items-center gap-2'>
          <label>셀러</label>
          <Switch size='small' className='w-7' checked={isSeller} disabled />
        </div>
        {sellerId && isSeller && (
          <>
            <FieldWithLabel label='셀러명' required>
              <SellerSelectField
                disabled={!editable}
                control={control}
                name='promotionReleaseData.sellerId'
                onChange={handleSellerSelect}
                value={sellerId}
                placeholder='셀러선택'
                initialOption={{
                  label: data.adminPromotionRelease.seller?.name || '',
                  value: sellerId,
                }}
              />
            </FieldWithLabel>
            <FieldWithLabel label='정산유형' required>
              <SelectField
                disabled={!editable}
                control={control}
                name='promotionReleaseData.sellerSettlementInfoId'
                options={settlementOptions}
                onChange={handleSettlementSelect}
                value={sellerSettlementInfoId}
                placeholder='정산유형 선택'
              />
            </FieldWithLabel>
          </>
        )}
        <FieldWithLabel label='수령자명' required>
          <TextField
            maxLength={30}
            disabled={!editable}
            control={control}
            name='promotionReleaseData.receiverName'
            placeholder='수령자명'
          />
        </FieldWithLabel>
        <FieldWithLabel label='휴대폰번호' required>
          <TextNumberField
            maxLength={30}
            disabled={!editable}
            control={control}
            name='promotionReleaseData.receiverPhoneNumber'
            format='###-####-####'
            placeholder='휴대폰번호'
          />
        </FieldWithLabel>
        <FieldWithLabel label={isSeller ? '샘플수령지' : '배송지'} required>
          <div className='flex flex-col gap-2'>
            <TextNumberField
              maxLength={20}
              control={control}
              name='promotionReleaseData.zipCode'
              disabled={!editable}
              placeholder='우편번호'
            />
            <TextField
              maxLength={100}
              disabled={!editable}
              control={control}
              name='promotionReleaseData.receiverAddress'
              placeholder='주소'
            />
            <TextField
              maxLength={100}
              disabled={!editable}
              control={control}
              name='promotionReleaseData.receiverDetailAddress'
              placeholder='상세주소'
            />
            {isSeller && editable && (
              <Dropdown
                disabled={!editable}
                label='다른 샘플수령지'
                menu={{
                  style: { minWidth: 150, maxWidth: 360 },
                  items: addressOptions,
                  onClick: (info) => {
                    const selectedId = Number(info.key);
                    handleAddressSelect(selectedId);
                  },
                }}
              />
            )}
          </div>
        </FieldWithLabel>
        <FieldWithLabel label='배송메시지'>
          <TextField
            maxLength={250}
            control={control}
            name='promotionReleaseData.deliveryMessage'
            disabled={!editable}
            placeholder='배송메시지'
          />
        </FieldWithLabel>
      </div>
    </div>
  );
}

export default ReceiverInformation;
