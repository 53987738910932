import { Tag, Checkbox, Popover, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';

import NumberInput from '../../../common/components/Input/NumberInput';
import { parseNumber } from '../../../common/utils/number';
import { ProductRelationTableDataType } from '../hooks/useProductRelationsTable';

type ProductRelationStockCellProps = {
  record: ProductRelationTableDataType;
  productGroupId: number;
  highlight: (itemId: number) => void;
  resetHighlight: () => void;
};

export function ProductRelationStockCell({
  record,
  productGroupId,
  highlight,
  resetHighlight,
}: ProductRelationStockCellProps) {
  const [editing, setEditing] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setEditing(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [resetHighlight]);

  useEffect(() => {
    if (!editing) resetHighlight();
  }, [editing, resetHighlight]);

  return (
    <div
      ref={divRef}
      className='flex flex-col gap-2'
      onClick={() => {
        setEditing(true);
        highlight(record.itemId);
      }}
    >
      <div>
        {!record.isInfinityStock && record.stock === 0 && (
          <Tag color='red'>품절</Tag>
        )}
        {record.isLinkedItem && (
          <Popover
            placement='bottom'
            title={`연동 중인 상품 (${
              record.linkedProductGroups?.length || 0
            }건)`}
            content={
              <div className='flex flex-col gap-2 text-[#00000073]'>
                {record.linkedProductGroups?.map(({ id, name }) => (
                  <div key={id}>
                    {name} {productGroupId === id && '(현재상품)'}
                  </div>
                ))}
              </div>
            }
          >
            <Tag color='green' className='text-xs leading-5'>
              재고연동중
            </Tag>
          </Popover>
        )}
      </div>
      <div>
        {!editing && (record.isInfinityStock ? '무제한' : record.stock)}
        {editing &&
          (record.isInfinityStock ? (
            <Input value={'무제한'} disabled />
          ) : (
            <NumberInput
              controls={false}
              value={record.stock}
              onChange={record.handleStockChange}
              formatter={(value) => {
                if (value) {
                  return Number(value).toLocaleString();
                }
                return '';
              }}
              parser={(value) => {
                if (value) {
                  return Number(parseNumber(value));
                }
                return '';
              }}
            />
          ))}
      </div>
      {editing && (
        <Checkbox
          onChange={record.handleInfinity}
          checked={record.isInfinityStock}
        >
          무제한
        </Checkbox>
      )}
    </div>
  );
}

export function ProductRelationSetCountCell({
  record,
}: {
  record: ProductRelationTableDataType;
}) {
  const [editing, setEditing] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setEditing(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <div
      ref={divRef}
      className='flex flex-col gap-2'
      onClick={() => setEditing(true)}
    >
      {!editing && `x${record.setCount}`}
      {editing && (
        <NumberInput
          controls={false}
          value={record.setCount}
          onChange={record.handleSetCountChange}
          formatter={(value) => {
            if (value) {
              return Number(value).toLocaleString();
            }
            return '';
          }}
          parser={(value) => {
            if (value) {
              return Number(parseNumber(value));
            }
            return '';
          }}
        />
      )}
    </div>
  );
}
