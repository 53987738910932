import { LoadingOutlined } from '@ant-design/icons';
import { App, Button, Divider, Select, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';

import DateField from '../../../../common/components/Fields/DateField';
import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import Label from '../../../../common/components/Fields/Label';
import SelectField from '../../../../common/components/Fields/SelectField';
import SwitchField from '../../../../common/components/Fields/SwitchField';
import TextareaField from '../../../../common/components/Fields/TextareaField';
import TextField from '../../../../common/components/Fields/TextField';
import TextNumberField from '../../../../common/components/Fields/TextNumberField';
import SuffixIcon from '../../../../common/components/Input/SuffixIcon';
import {
  DELIVERY_FEE_BEARER_OPTIONS,
  DELIVERY_FEE_SETTLEMENT_TARGET,
} from '../../const';
import useCreateExchangeRedelivery from '../../hooks/useCreateExchangeRedelivery';
import NumberWithUnknownField from '../NumberWithUnknownField';

function ExchangeRedeliveryForm({
  onClose,
  orderCode,
}: {
  onClose: () => void;
  orderCode?: string;
}) {
  const { message } = App.useApp();
  const {
    methods,
    handleOrderCode,
    orderData,
    orderError,
    orderLoading,
    productItemOptions,
    onSubmit,
  } = useCreateExchangeRedelivery({ onClose, orderCode });
  const { control, watch, setValue } = methods;
  const [formOrderCode, isDeliveryFee, originalItemIds, scheduledReleaseAt] =
    watch([
      'orderCode',
      'isDeliveryFee',
      'originalItemIds',
      'scheduledReleaseAt',
    ]);
  const handleReleaseAt = useCallback(
    (date: dayjs.Dayjs | null) => {
      const isTodayPossible = dayjs().isBefore(dayjs().hour(11).minute(50));
      // 11:50 전에는 오늘부터 출고요청이 가능.
      // 11:50 후에는 내일부터 출고요청이 가능.
      const standard = isTodayPossible
        ? dayjs().startOf('day')
        : dayjs().add(1, 'day').startOf('day');

      if (date?.isBefore(standard)) {
        void message.warning('오늘 이후의 날짜를 선택해주세요.');
        return;
      }

      const scheduledReleaseAt = date?.toISOString() || null;
      setValue('scheduledReleaseAt', scheduledReleaseAt);
    },
    [setValue, message]
  );
  const productGroupName = orderData?.adminOrderByCode.productGroupName;
  const productName = orderData?.adminOrderByCode.productName;
  const productQuantity = orderData?.adminOrderByCode.productQuantity;
  const useType = orderData?.adminOrderByCode.productUseType;

  return (
    <FormProvider {...methods}>
      <form className='mt-6' onSubmit={onSubmit}>
        <FieldWithLabel label='교환대상 와이어드품목주문코드' required>
          <TextField
            control={control}
            name='orderCode'
            className='w-[472px]'
            onChange={handleOrderCode}
            value={formOrderCode}
            disabled={!!orderCode}
            suffix={
              orderLoading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                <SuffixIcon
                  status={
                    !formOrderCode
                      ? undefined
                      : orderError
                      ? 'error'
                      : 'success'
                  }
                />
              )
            }
          />
        </FieldWithLabel>
        <Divider orientation='left' className='m-0 py-4'>
          수령자정보
        </Divider>
        <div className='mt-4 flex w-[472px] flex-col gap-6'>
          <FieldWithLabel label='수령자명' required>
            <TextField
              control={control}
              name='receiverName'
              maxLength={30}
              placeholder='수령자명'
            />
          </FieldWithLabel>
          <FieldWithLabel label='휴대폰번호' required>
            <TextNumberField
              control={control}
              name='receiverPhoneNumber'
              maxLength={30}
              placeholder='휴대폰번호'
            />
          </FieldWithLabel>
          <FieldWithLabel label='배송지주소' required>
            <div className='flex flex-col gap-2'>
              <TextNumberField
                control={control}
                name='zipCode'
                maxLength={20}
                placeholder='우편번호'
              />
              <TextField
                control={control}
                name='receiverAddress'
                maxLength={100}
                placeholder='주소'
              />
              <TextField
                control={control}
                name='receiverDetailAddress'
                maxLength={100}
                placeholder='상세주소'
              />
            </div>
          </FieldWithLabel>
          <FieldWithLabel label='배송메시지'>
            <TextField
              control={control}
              name='deliveryMessage'
              maxLength={250}
              placeholder='배송메시지'
            />
          </FieldWithLabel>
        </div>
        <Divider orientation='left' className='m-0 py-4'>
          상세정보
        </Divider>
        <div className='my-4 w-[472px]'>
          <FieldWithLabel label='상품명'>
            <Select
              className='w-full'
              options={[{ label: productGroupName, value: productGroupName }]}
              value={productGroupName}
              disabled
            />
          </FieldWithLabel>
        </div>
        <Table
          tableLayout='fixed'
          pagination={false}
          dataSource={[
            {
              key: 0,
              productName,
              originalItemIds,
              productQuantity,
            },
          ]}
        >
          <Table.Column title='품목명' dataIndex={'productName'} />
          {useType === 'SELECT' && (
            <Table.Column
              title={<Label required>옵션명</Label>}
              width={416}
              render={() => (
                <SelectField
                  mode='multiple'
                  control={control}
                  name='originalItemIds'
                  value={originalItemIds}
                  options={productItemOptions}
                />
              )}
            />
          )}
          <Table.Column
            title='수량'
            width={120}
            dataIndex={'productQuantity'}
          />
        </Table>
        <div className='mt-4 flex flex-col gap-4'>
          <SwitchField
            label='배송비 별도부과'
            control={control}
            name='isDeliveryFee'
          />
          {isDeliveryFee && (
            <>
              <FieldWithLabel label='배송비 부담주체' required>
                <div className='flex w-[472px] gap-2'>
                  <SelectField
                    control={control}
                    name='deliveryFeeBearer'
                    options={DELIVERY_FEE_BEARER_OPTIONS}
                  />
                  <NumberWithUnknownField
                    control={control}
                    name='deliveryFee'
                    onUnknownClick={() => setValue('deliveryFee', null)}
                  />
                </div>
              </FieldWithLabel>
              <FieldWithLabel label='배송비 정산대상' required>
                <div className='flex w-[472px] gap-2'>
                  <SelectField
                    control={control}
                    name='deliveryFeeSettlementTarget'
                    options={DELIVERY_FEE_SETTLEMENT_TARGET}
                  />
                  <NumberWithUnknownField
                    control={control}
                    name='deliveryFeeSettlementPrice'
                    onUnknownClick={() =>
                      setValue('deliveryFeeSettlementPrice', null)
                    }
                  />
                </div>
              </FieldWithLabel>
            </>
          )}
        </div>
        <Divider orientation='left' className='m-0 py-4'>
          기타정보
        </Divider>
        <div className='flex w-[472px] flex-col gap-6'>
          <FieldWithLabel label='출고 희망일'>
            <DateField
              className='w-[232px]'
              control={control}
              value={scheduledReleaseAt ? dayjs(scheduledReleaseAt) : null}
              onChange={handleReleaseAt}
              name='scheduledReleaseAt'
            />
          </FieldWithLabel>
          <FieldWithLabel label='비고'>
            <TextareaField
              placeholder='내용입력'
              control={control}
              name='note'
            />
          </FieldWithLabel>
        </div>
        <div className='mt-4 flex justify-end gap-2'>
          <Button onClick={onClose}>취소</Button>
          <Button htmlType='submit' type='primary' disabled={!!orderError}>
            출고요청
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default ExchangeRedeliveryForm;
