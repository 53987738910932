import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useFormError } from '../../../common/hooks/useFormError';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import {
  OrderListSearchParams,
  OrderListTableFilterFormValues,
  OrderStatus,
} from '../types';

export default function useSearchFilterModal() {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<OrderListSearchParams>();

  const { onInvalid } = useFormError();

  const searchFilterSchema = yup
    .object<OrderListTableFilterFormValues>()
    .shape({
      code: yup.string(),
      groupCode: yup.string(),
      productGroupName: yup.string(),
      vendorName: yup.string(),
      brandName: yup.string(),
      marketName: yup.string(),
      startOrderAmount: yup
        .number()
        .nullable()
        .test({
          message: '주문금액 범위를 확인해주세요.',
          test: function (
            value,
            context: { parent: { endOrderAmount: number } }
          ) {
            const { endOrderAmount } = context.parent;

            if (value && endOrderAmount) {
              return value < endOrderAmount;
            }

            return true;
          },
        }),
      endOrderAmount: yup
        .number()
        .nullable()
        .test({
          message: '주문금액 범위를 확인해주세요.',
          test: function (
            value,
            context: { parent: { startOrderAmount: number } }
          ) {
            const { startOrderAmount } = context.parent;

            if (value && startOrderAmount) {
              return value > startOrderAmount;
            }

            return true;
          },
        }),
      orderStatuses: yup
        .array()
        .of(
          yup
            .mixed<OrderStatus>()
            .oneOf([
              'DELIVERY_COMPLETED',
              'DELIVERY_STARTED',
              'DELIVERY_WAIT',
              'PAYMENT_COMPLETED',
              'PAYMENT_WAIT',
              'PURCHASE_CONFIRMED',
            ])
            .required()
        ),
    });

  const methods = useForm<OrderListTableFilterFormValues>({
    defaultValues: {
      code: searchParams.code,
      groupCode: searchParams.groupCode,
      productGroupName: searchParams.productGroupName,
      vendorName: searchParams.vendorName,
      brandName: searchParams.brandName,
      marketName: searchParams.marketName,
      startOrderAmount: searchParams.startOrderAmount,
      endOrderAmount: searchParams.endOrderAmount,
      orderStatuses: searchParams.orderStatuses,
    },
    resolver: yupResolver<OrderListTableFilterFormValues>(searchFilterSchema),
  });

  const { handleSubmit } = methods;

  const onSubmit = (callback: () => void) =>
    handleSubmit((data) => {
      const params = {
        ...data,
        currentPage: undefined,
        summaryStatus: undefined,
        startOrderAmount: data.startOrderAmount ?? undefined,
        endOrderAmount: data.endOrderAmount ?? undefined,
      };
      setSearchParams(params);
      callback();
    }, onInvalid);

  return {
    onSubmit,
    methods,
  };
}
