import { ApolloCache } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { App } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { groupBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { HistoriesDocument } from '../../../common/graphql/history.generated';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { deleteCacheFields } from '../../../common/utils/apollo';
import { EPromotionReleaseStatus } from '../../../schema/types';
import {
  AdminBulkPatchPromotionReleaseStatusMutationVariables,
  AdminCountPromotionStatusesDocument,
  useAdminBulkPatchPromotionReleaseStatusMutation,
  useAdminPromotionReleasesQuery,
} from '../graphql/promotionRelease.generated';
import {
  PromotionReleaseListSearchParams,
  PromotionReleaseTableDataType,
} from '../types';

import usePromotionReleaseTableFilter from './usePromotionReleaseTableFilter';

export const sharedOnCell = ({ rowSpan }: PromotionReleaseTableDataType) => {
  return { rowSpan };
};

function usePromotionReleaseTable() {
  const { message, modal } = App.useApp();
  const { searchParams } =
    useRouteSearchParams<PromotionReleaseListSearchParams>();
  const [bulkPatchPromotionReleases] =
    useAdminBulkPatchPromotionReleaseStatusMutation();
  const currentPage = Number(searchParams.currentPage) - 1 || 0;
  const pageSize = Number(searchParams.pageSize) || 100;

  const filter = usePromotionReleaseTableFilter();

  const { data, loading } = useAdminPromotionReleasesQuery({
    variables: {
      filter,
      pagination: {
        skip: currentPage * pageSize,
        take: pageSize,
      },
    },
  });

  const dataSources: PromotionReleaseTableDataType[] = useMemo(() => {
    const dataSources: Omit<PromotionReleaseTableDataType, 'key'>[] = [];
    const nodes = data?.adminPromotionReleases?.edges.map(({ node }) => node);
    const releaseList = nodes?.map((release) => ({
      releaseId: release.id,
      releaseCode: release.releaseCode,
      promotionId: release.promotion.id,
      promotionType: release.promotion.promotionType,
      orderCode: release.promotion.orderCode,
      sampleStatus: release.promotion.sampleStatus,
      releaseStatus: release.releaseStatus,
      vendorName: release.promotion.vendor.name,
      promotionProductGroupId:
        release.promotionProducts[0].originalProductGroup?.id,
      promotionProductGroupName:
        release.promotionProducts[0].originalProductGroup?.name || '-',
      promotionProducts: release.promotionProducts,
      receiverName: release.receiverName,
      receiverPhoneNumber: release.receiverPhoneNumber,
      receiverAddress: release.receiverAddress,
      receiverDetailAddress: release.receiverDetailAddress || '',
      zipcode: release.zipCode || '',
      deliveryMessage: release.deliveryMessage || '',
      isDeliveryFee: release.promotion.isDeliveryFee,
      deliveryFeeBearer: release.promotion.deliveryFeeBearer,
      deliveryFee: release.promotion.deliveryFee,
      managerName: release.promotion.manager.name,
      createdAt: dayjs(release.createdAt).format('YYYY.MM.DD HH:mm:ss'),
      scheduledReleaseAt: release.scheduledReleaseAt
        ? dayjs(release.scheduledReleaseAt).format('YYYY.MM.DD')
        : '-',
      confirmedOrderAt: release.confirmedOrderAt
        ? dayjs(release.confirmedOrderAt).format('YYYY.MM.DD')
        : '-',
      completedReleaseAt: release.completedReleaseAt
        ? dayjs(release.completedReleaseAt).format('YYYY.MM.DD')
        : '-',
      sellerId: release.seller?.id,
      sellerName: release.seller?.name || '-',
      marketId: release.promotion.market?.id,
      marketName: release.promotion.market?.name || '-',
      note: release.note,
    }));

    releaseList?.forEach(({ promotionProducts, ...releaseData }) => {
      promotionProducts.forEach((product, index) => {
        dataSources.push({
          ...releaseData,
          product,
          // promotion id query 를 할 경우 cache override 로 버그발생하여 delivery 내부의 promotionId 사용
          promotionProductId: product.delivery?.promotionProductId || 0,
          deliveryCompanyId: product.delivery?.deliveryCompanyId,
          trackingNumber: product.delivery?.trackingNumber,
          rowSpan: index === 0 ? promotionProducts.length : 0,
          isFirstProduct: promotionProducts.length > 1 && index === 0,
        });
      });
    });
    return dataSources.map((data, index) => ({ ...data, key: index }));
  }, [data]);

  const [dataSourceState, setDataSourceState] = useState<
    PromotionReleaseTableDataType[]
  >([]);

  const [rowSpanReleaseIds, setRowSpanReleaseIds] = useState<number[]>([]);

  useEffect(() => {
    setDataSourceState(dataSources);
  }, [dataSources]);
  useEffect(() => {
    setSelectedRowKeys([]);
  }, [searchParams]);

  const handleDeliveryCompany = useCallback(
    (record: PromotionReleaseTableDataType, value: number) => {
      setDataSourceState((prev) => {
        const newDataSourceState = cloneDeep(prev);
        const index = record.key as number;
        newDataSourceState[index].deliveryCompanyId = value;

        return newDataSourceState;
      });
    },
    []
  );

  const handleDeliveryTrackingNumber = useCallback(
    (record: PromotionReleaseTableDataType, value: string) => {
      setDataSourceState((prev) => {
        const newDataSourceState = cloneDeep(prev);
        const index = record.key as number;
        newDataSourceState[index].trackingNumber = value;

        return newDataSourceState;
      });
    },
    []
  );

  const handleDeliveryCellSpan = useCallback((releaseId: number) => {
    setRowSpanReleaseIds((prev) =>
      prev.includes(releaseId)
        ? prev.filter((id) => id !== releaseId)
        : [...prev, releaseId]
    );
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection: TableRowSelection<PromotionReleaseTableDataType> =
    useMemo(
      () => ({
        type: 'checkbox',
        columnWidth: 48,
        fixed: true,
        onCell: sharedOnCell,
        selectedRowKeys,
        onChange: (_, selectedRows) => {
          const selectedRowDataIds = selectedRows
            .filter(({ rowSpan }) => rowSpan !== 0)
            .map((row) => row.releaseId);
          const rowKeys = dataSources
            .filter(({ releaseId }) => selectedRowDataIds.includes(releaseId))
            .map(({ key }) => key);
          setSelectedRowKeys(rowKeys);
        },
      }),
      [selectedRowKeys, dataSources]
    );

  const handleBulkPatchPromotionReleases = useCallback(
    async (releaseStatus: EPromotionReleaseStatus) => {
      // checkbox items
      const selectedDataSources = cloneDeep(dataSourceState).filter(({ key }) =>
        selectedRowKeys.includes(key)
      );

      // 통합 송장입력 처리
      rowSpanReleaseIds.forEach((releaseId) => {
        const { deliveryCompanyId, trackingNumber } = selectedDataSources.find(
          (data) => data.releaseId === releaseId && data.isFirstProduct
        )!;
        selectedDataSources.forEach((data) => {
          if (data.releaseId === releaseId) {
            data.deliveryCompanyId = deliveryCompanyId;
            data.trackingNumber = trackingNumber;
          }
        });
      });
      const groupByReleaseIds = Object.entries(
        groupBy(selectedDataSources, 'releaseId')
      ).map(([releaseId, data]) => ({ id: Number(releaseId), data }));

      const bulkPatchReleaseCompleted = async () => {
        const promotionReleases: AdminBulkPatchPromotionReleaseStatusMutationVariables['promotionReleaseData']['promotionReleases'] =
          groupByReleaseIds.map(({ id, data }) => ({
            id,
            promotionReleaseProductRelations: data.map(
              ({ promotionProductId, deliveryCompanyId, trackingNumber }) => ({
                promotionProductId,
                deliveryCompanyId,
                trackingNumber,
              })
            ),
          }));

        await bulkPatchPromotionReleases({
          variables: {
            promotionReleaseData: {
              releaseStatus,
              promotionReleases,
            },
          },
          update: (cache: ApolloCache<any>) =>
            deleteCacheFields(cache, ['adminPromotionReleases']),
          refetchQueries: [
            AdminCountPromotionStatusesDocument,
            HistoriesDocument,
          ],
          onCompleted(data) {
            setSelectedRowKeys([]);
            const { successCaseCount, failCases } =
              data.adminBulkPatchPromotionReleaseStatus;
            const successMessage = `${successCaseCount}건 성공`;
            const failMessage = failCases.length
              ? ` / ${failCases.length}건 실패. 출고상태를 확인해주세요.`
              : '';
            if (failMessage) {
              void modal.error({
                title: successMessage + failMessage,
                content: (
                  <>
                    {failCases.map(({ reason, releaseCode }) => (
                      <div>
                        {releaseCode}: {reason}
                      </div>
                    ))}
                  </>
                ),
              });
            } else {
              void message.success(successMessage);
            }
          },
          onError() {
            void message.error(
              '알 수 없는 에러가 발생했습니다. 개발팀에 문의해주세요.'
            );
          },
        });
      };

      const bulkPatchReleasWithoutDelivery = async () => {
        const promotionReleases: AdminBulkPatchPromotionReleaseStatusMutationVariables['promotionReleaseData']['promotionReleases'] =
          groupByReleaseIds.map(({ id }) => ({
            id,
          }));
        await bulkPatchPromotionReleases({
          variables: {
            promotionReleaseData: {
              releaseStatus,
              promotionReleases,
            },
          },
          update: (cache: ApolloCache<any>) =>
            deleteCacheFields(cache, ['adminPromotionReleases']),
          refetchQueries: [
            AdminCountPromotionStatusesDocument,
            HistoriesDocument,
          ],
          onCompleted(data) {
            setSelectedRowKeys([]);
            const { successCaseCount, failCases } =
              data.adminBulkPatchPromotionReleaseStatus;
            const successMessage = `${successCaseCount}건 성공`;
            const failMessage = failCases.length
              ? ` / ${failCases.length}건 실패.`
              : '';
            if (failMessage) {
              void modal.error({
                title: successMessage + failMessage,
                content: (
                  <>
                    {failCases.map(({ reason, releaseCode }) => (
                      <div>
                        {releaseCode}: {reason}
                      </div>
                    ))}
                  </>
                ),
              });
            } else {
              void message.success(successMessage);
            }
          },
          onError() {
            void message.error(
              '알 수 없는 에러가 발생했습니다. 개발팀에 문의해주세요.'
            );
          },
        });
      };
      if (releaseStatus === 'RELEASE_COMPLETED') {
        const isAlldeliveryInfoFilled = selectedDataSources.every(
          ({ deliveryCompanyId, trackingNumber }) =>
            !!deliveryCompanyId && !!trackingNumber
        );
        if (!isAlldeliveryInfoFilled) {
          void modal.confirm({
            title: '출고처리',
            content:
              '택배사와 송장번호가 등록되지 않은 요청 건이 있습니다. 출고처리를 진행하시겠습니까?',
            onOk: () => bulkPatchReleaseCompleted(),
          });
        } else {
          await bulkPatchReleaseCompleted();
        }
      }
      if (releaseStatus === 'ORDER_CONFIRMED') {
        await bulkPatchReleasWithoutDelivery();
      }
      if (releaseStatus === 'RELEASE_CANCEL') {
        const isConfirmOrCompletedIncluded = selectedDataSources.some(
          ({ releaseStatus }) =>
            releaseStatus === 'ORDER_CONFIRMED' ||
            releaseStatus === 'RELEASE_COMPLETED'
        );

        if (isConfirmOrCompletedIncluded) {
          void modal.confirm({
            title: '요청철회',
            content:
              '발주확인, 출고완료 상태의 요청 건이 포함되어 있습니다. 물류팀 확인 후 요청 철회를 진행해 주세요.',
            onOk: () => bulkPatchReleasWithoutDelivery(),
          });
        } else {
          await bulkPatchReleasWithoutDelivery();
        }
      }
      if (releaseStatus === 'RELEASE_HOLD') {
        const isConfirmIncluded = selectedDataSources.some(
          ({ releaseStatus }) => releaseStatus === 'ORDER_CONFIRMED'
        );
        if (isConfirmIncluded) {
          void modal.confirm({
            title: '출고보류',
            content:
              '발주확인 상태의 요청 건이 포함되어 있습니다. 물류팀 확인 후 진행해주세요.',
            onOk: () => bulkPatchReleasWithoutDelivery(),
          });
        } else {
          await bulkPatchReleasWithoutDelivery();
        }
      }
    },
    [
      bulkPatchPromotionReleases,
      dataSourceState,
      rowSpanReleaseIds,
      selectedRowKeys,
      message,
      modal,
    ]
  );

  return {
    rowSelection,
    loading,
    dataSourceState,
    handleDeliveryCompany,
    handleDeliveryTrackingNumber,
    handleDeliveryCellSpan,
    rowSpanReleaseIds,
    handleBulkPatchPromotionReleases,
    total: data?.adminPromotionReleases?.totalCount || 0,
    selectedRowKeys,
  };
}

export default usePromotionReleaseTable;
