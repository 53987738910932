import { Descriptions, Divider, Typography } from 'antd';

import { EVENT_SHIPPING_BEARER_TEXT, EVENT_TYPE_TEXT } from '../const';
import { useAdminMarketEventInOrderQuery } from '../graphql/order.generated';

import OrderDetailEventProductTable from './OrderDetailEventProductTable';

type OrderDetailEventProps = {
  eventId: number;
};

export default function OrderDetailEvent({ eventId }: OrderDetailEventProps) {
  const { data } = useAdminMarketEventInOrderQuery({
    variables: {
      adminMarketEventId: eventId,
    },
  });

  if (!data?.adminMarketEvent) return null;

  const { type, count, comment, shippingBearer, shippingCost, products } =
    data.adminMarketEvent;

  const eventTypeText = EVENT_TYPE_TEXT[type];
  const eventCountText =
    type === 'FREE_GIFT' || type === 'ETC' ? '' : ` / ${count ?? 0}명`;

  return (
    <div>
      <Divider orientation='left' plain>
        <Typography.Title level={5} className='mb-0'>
          이벤트정보
        </Typography.Title>
      </Divider>
      <Descriptions
        bordered
        className='[&_.ant-descriptions-view]:rounded-none'
        column={4}
      >
        <Descriptions.Item
          label='이벤트ID'
          span={2}
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
        >
          {eventId}
        </Descriptions.Item>
        <Descriptions.Item
          label='이벤트유형 / 인원'
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
          span={2}
        >
          {`${eventTypeText}${eventCountText}`}
        </Descriptions.Item>
        <Descriptions.Item label='이벤트내용' span={4}>
          {comment ?? '-'}
        </Descriptions.Item>
        {shippingBearer && (
          <Descriptions.Item label='배송비 별도부과' span={4}>
            {`${EVENT_SHIPPING_BEARER_TEXT[shippingBearer]} / ${
              shippingCost ? shippingCost?.toLocaleString() : 0
            }원`}
          </Descriptions.Item>
        )}
      </Descriptions>
      <OrderDetailEventProductTable products={products} />
    </div>
  );
}
