import dayjs from 'dayjs';

import { EOrderClaimStatus, EOrderClaimType } from '../../../schema/types';
import { ClaimListSearchParams } from '../type';

export const defaultSearchParam: ClaimListSearchParams = {
  startedAt: dayjs().subtract(3, 'month').startOf('date').toISOString(),
  endedAt: dayjs().endOf('date').toISOString(),
  periodType: 'CLAIM_UPDATED_AT',
  salesChannelType: 'KEMI',
};

export const defaultClaimStatus: {
  [key in EOrderClaimType]?: EOrderClaimStatus;
} = {
  RETURN: 'RETURN_REQUESTED',
  EXCHANGE: 'EXCHANGE_REQUESTED',
  CANCEL: 'CANCEL_COMPLETED',
};
