import { Collapse } from 'antd';
import { useEffect, useState } from 'react';
import {
  FieldValues,
  UseFieldArrayReturn,
  useFormContext,
  useFormState,
} from 'react-hook-form';

import { ProductGroupFormValues } from '../types';

import ProductField from './ProductField';
import ProductListItemExtra from './ProductListItemExtra';
import ProductListItemLabel from './ProductLIstItemLabel';

type ProductListItemProps = {
  index: number;
} & UseFieldArrayReturn<FieldValues, 'products', 'id'>;

export default function ProductListItem({
  index,
  ...props
}: ProductListItemProps) {
  const { control, trigger } = useFormContext<ProductGroupFormValues>();
  const { isSubmitting } = useFormState({
    control,
    name: `products.${index}`,
  });

  const [activeKey, setActiveKey] = useState<number | undefined>(1);

  const submitTrigger = async () => {
    const result = await trigger(`products.${index}`);
    if (!result) setActiveKey(1);
  };

  useEffect(() => {
    if (isSubmitting && !activeKey) submitTrigger();
  }, [isSubmitting]);

  return (
    <>
      <Collapse
        defaultActiveKey={1}
        activeKey={activeKey}
        className='[&_.ant-collapse-content-box]:p-6 [&_.ant-collapse-header]:items-center'
        onChange={(key) => setActiveKey(key.length > 0 ? 1 : undefined)}
        items={[
          {
            key: '1',
            label: <ProductListItemLabel index={index} />,
            extra: <ProductListItemExtra index={index} {...props} />,
            children: <ProductField index={index} />,
          },
        ]}
      />
    </>
  );
}
