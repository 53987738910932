import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import BrandSelect from '../../../../common/components/Fields/BrandSelect';
import { useAdminBrandInSettlementListLazyQuery } from '../../graphql/brand.generated';

export type SelectFieldProps = SelectProps<number> & {
  initialLoad?: boolean;
};

const SettlementBrandSelect = forwardRef<BaseSelectRef, SelectFieldProps>(
  function ({ initialLoad, ...props }, ref) {
    const [initialOption, setInitialOption] = useState<
      null | undefined | { value: number; label: string }
    >(null);

    const [fetch] = useAdminBrandInSettlementListLazyQuery();
    const fetchBrand = useCallback(async () => {
      if (!props.value) {
        setInitialOption(undefined);
        return;
      }

      const { data } = await fetch({
        variables: {
          adminBrandId: props.value,
        },
      });

      if (data?.adminBrand) {
        setInitialOption({
          value: data.adminBrand.id,
          label: data.adminBrand.name,
        });
      }
    }, [fetch, props.value]);

    useEffect(() => {
      fetchBrand();
    }, []);

    if (initialOption === null) return <Select className={props.className} />;

    return (
      <BrandSelect
        ref={ref}
        initialLoad={initialLoad}
        className={'w-full'}
        initialOption={initialOption}
        {...props}
      />
    );
  }
);

export default SettlementBrandSelect;
