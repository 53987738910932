import { type Sheet2JSONOpts, type WorkSheet, read, utils } from 'xlsx';

type SheetToJsonOptions = Sheet2JSONOpts & {
  deleteRowNumber?: number;
  removeCol?: {
    start: string;
    end: string;
  };
};

const deleteRow = (ws: WorkSheet, row: number) => {
  const range = utils.decode_range(ws['!ref']!);
  range.s.r = row;
  ws['!ref'] = utils.encode_range(range);
};

export const sheetToJson = async (file: File, options?: SheetToJsonOptions) => {
  const readFile = await file.arrayBuffer();
  const wb = read(readFile);
  const ws = wb.Sheets[wb.SheetNames[0]];
  if (options?.removeCol) {
    ws['!ref'] = ws['!ref']?.replace(
      options.removeCol.start,
      options.removeCol.end
    );
  }

  if (options?.deleteRowNumber) deleteRow(ws, options?.deleteRowNumber);

  return utils.sheet_to_json(ws, options);
};
