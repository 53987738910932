import { Spin } from 'antd';
import { useState } from 'react';

import SimpleEditor from '../../../common/components/SimpleEditor';

function EditableMarketCheckListMemoCell({
  initialValue,
  onChange,
  editable = true,
}: {
  initialValue: string;
  onChange: (v: string) => void;
  editable?: boolean;
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const handleBlur = () => {
    onChange(value);
    toggleEdit();
  };

  if (!editable || !editing) {
    return (
      <div
        className={`${
          editable
            ? 'editable-cell hover:cursor-pointer hover:border-[1px] hover:border-solid hover:border-gray-400'
            : ''
        } min-h-[30px] rounded-lg px-lg py-sm [&_p]:min-h-[22px]`}
        onClick={toggleEdit}
        dangerouslySetInnerHTML={{ __html: value || '-' }}
      />
    );
  }

  return (
    <Spin spinning={false} size={'small'}>
      <div className={'w-full'}>
        <SimpleEditor
          editable
          focusOnMount
          wrapperClassName={
            'resize-y overflow-scroll rounded-md border border-solid bg-white px-[11px] py-1'
          }
          content={value}
          // hasError={isOverSized}
          onChange={setValue}
          onBlur={handleBlur}
          placeholder={'메모입력'}
        />
      </div>
    </Spin>
  );
}

export default EditableMarketCheckListMemoCell;
