import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminSellerInSettlementListQueryVariables = Types.Exact<{
  adminSellerId: Types.Scalars['Int']['input'];
}>;


export type AdminSellerInSettlementListQuery = { __typename?: 'Query', adminSeller: { __typename?: 'SellerGraphqlType', id: number, name: string } };


export const AdminSellerInSettlementListDocument = gql`
    query AdminSellerInSettlementList($adminSellerId: Int!) {
  adminSeller(id: $adminSellerId) {
    id
    name
  }
}
    `;

/**
 * __useAdminSellerInSettlementListQuery__
 *
 * To run a query within a React component, call `useAdminSellerInSettlementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSellerInSettlementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSellerInSettlementListQuery({
 *   variables: {
 *      adminSellerId: // value for 'adminSellerId'
 *   },
 * });
 */
export function useAdminSellerInSettlementListQuery(baseOptions: Apollo.QueryHookOptions<AdminSellerInSettlementListQuery, AdminSellerInSettlementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSellerInSettlementListQuery, AdminSellerInSettlementListQueryVariables>(AdminSellerInSettlementListDocument, options);
      }
export function useAdminSellerInSettlementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSellerInSettlementListQuery, AdminSellerInSettlementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSellerInSettlementListQuery, AdminSellerInSettlementListQueryVariables>(AdminSellerInSettlementListDocument, options);
        }
export type AdminSellerInSettlementListQueryHookResult = ReturnType<typeof useAdminSellerInSettlementListQuery>;
export type AdminSellerInSettlementListLazyQueryHookResult = ReturnType<typeof useAdminSellerInSettlementListLazyQuery>;
export type AdminSellerInSettlementListQueryResult = Apollo.QueryResult<AdminSellerInSettlementListQuery, AdminSellerInSettlementListQueryVariables>;