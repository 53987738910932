import { Divider, Table } from 'antd';

import { AdminSettlementSheetQuery } from '../../../graphql/settlementSheet.generated';

import Block from './Block';

const onHeaderCell = () => ({ className: 'text-center' });
const onCell = () => ({ className: 'text-center py-5' });

const DATA_SOURCES: TableDataType[] = [
  {
    key: 'CONSTANT',
    businessName: '와이어드컴퍼니 주식회사',
    businessNumber: '631-87-01013',
    email: 'fin@wired.company',
  },
];

type TableDataType = {
  key: React.Key;
  businessName: string;
  businessNumber: string;
  email: string;
};

function SupplyInfo({
  settlementSheetType,
}: {
  settlementSheetType?: AdminSettlementSheetQuery['adminSettlementSheet']['settlementSheetType'];
}) {
  return (
    <Block>
      <Divider orientation='left' className='mt-0'>
        {settlementSheetType === 'VENDOR' ? '파트너정보' : '공급자정보'}
      </Divider>
      <Table size='small' bordered pagination={false} dataSource={DATA_SOURCES}>
        <Table.Column<TableDataType>
          title='상호명'
          width={'33.3%'}
          dataIndex={'businessName'}
          onHeaderCell={onHeaderCell}
          onCell={onCell}
          render={(_, record) => {
            return (
              <div className='flex justify-center gap-4'>
                <div>{record.businessName}</div>
                <div className='relative'>
                  (인)
                  <img
                    src='/stamp.svg'
                    className='absolute left-[50%] top-[50%] h-16 w-16 translate-x-[-50%] translate-y-[-50%]'
                  />
                </div>
              </div>
            );
          }}
        />
        <Table.Column<TableDataType>
          title='사업자번호'
          width={'33.3%'}
          dataIndex={'businessNumber'}
          onHeaderCell={onHeaderCell}
          onCell={onCell}
        />
        <Table.Column<TableDataType>
          title='문의메일'
          width={'33.3%'}
          dataIndex={'email'}
          onHeaderCell={onHeaderCell}
          onCell={onCell}
        />
      </Table>
    </Block>
  );
}

export default SupplyInfo;
