import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import NumberField from '../../../common/components/Fields/NumberField';
import SelectField from '../../../common/components/Fields/SelectField';
import SwitchField from '../../../common/components/Fields/SwitchField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import {
  EOrderClaimCostBearer,
  EOrderClaimSettlementTarget,
} from '../../../schema/types';
import { UpdateClaimFormValues } from '../type';

type ExtraDeliveryFormProps = {
  deliveryFeeBearerOptions: {
    label: string;
    value: EOrderClaimCostBearer;
  }[];
  settlementTargetOptions: {
    value: EOrderClaimSettlementTarget;
    label: string;
  }[];
};

function ExtraDeliveryForm({
  deliveryFeeBearerOptions,
  settlementTargetOptions,
}: ExtraDeliveryFormProps) {
  const { hasPermission } = usePermissions(['ORDER_EDIT']);

  const { control, watch, resetField } =
    useFormContext<UpdateClaimFormValues>();

  const [hasExtraDelivery, deliveryFeeBearer] = watch([
    'hasExtraDelivery',
    'extraDelivery.deliveryFeeBearer',
  ]);

  useEffect(() => {
    const subscribe = watch((_, { name }) => {
      if (name === 'extraDelivery.deliveryFeeBearer') {
        resetField('extraDelivery.deliveryFeeSettlementTarget');
      }
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, [resetField, watch]);
  const deliveryFeeSettlementTargetOptions = useMemo(
    () =>
      settlementTargetOptions.filter(
        (item) => item.value !== deliveryFeeBearer
      ),
    [deliveryFeeBearer, settlementTargetOptions]
  );
  return (
    <div>
      <div className={'mb-4'}>
        <SwitchField
          control={control}
          name={'hasExtraDelivery'}
          label={'배송비 별도부과'}
        />
      </div>
      {hasExtraDelivery && (
        <div className={'grid w-[472px] gap-6'}>
          <FieldWithLabel label={'배송비 부담주체'} required={hasPermission}>
            <div className={'flex gap-2'}>
              <SelectField
                placeholder='배송비 부담주체 선택'
                control={control}
                name={'extraDelivery.deliveryFeeBearer'}
                options={deliveryFeeBearerOptions}
              />
              <NumberField
                numberType={'price'}
                control={control}
                name={'extraDelivery.deliveryFee'}
                suffix={'원'}
                placeholder={'금액 입력'}
              />
            </div>
          </FieldWithLabel>
          <FieldWithLabel label={'배송비 정산대상'} required={hasPermission}>
            <div className={'flex gap-2'}>
              <SelectField
                placeholder='배송비 정산대상 선택'
                control={control}
                name={'extraDelivery.deliveryFeeSettlementTarget'}
                options={deliveryFeeSettlementTargetOptions}
              />
              <NumberField
                numberType={'price'}
                control={control}
                name={'extraDelivery.deliveryFeeSettlementPrice'}
                suffix={'원'}
                placeholder={'금액 입력'}
              />
            </div>
          </FieldWithLabel>
        </div>
      )}
    </div>
  );
}

export default ExtraDeliveryForm;
