import { Select, UploadProps } from 'antd';
import { get } from 'lodash-es';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form';

import { EBankCode, EUploadResourcesPurpose } from '../../../schema/types';
import { bankOptions } from '../../utils/const';
import { formatNumber, parseNumber } from '../../utils/number';
import FileUploader, { UploadButton } from '../FileUploader';
import TextInput from '../Input/TextInput';
import { TextNumberInputProps } from '../Input/TextNumberInput';

import FieldWithLabel from './FieldWithLabel';
import { SelectFieldProps } from './SelectField';
import { FieldProps } from './types';

type BankAccountFieldProps<TFieldValues extends FieldValues> = {
  selectProps: Omit<
    SelectFieldProps<TFieldValues, EBankCode, (typeof bankOptions)[0]>,
    'control'
  >;
  inputProps: TextNumberInputProps & {
    name: Path<TFieldValues>;
  };
  uploadProps: Omit<UploadProps, 'onChange' | 'value'> & {
    id: string;
    name: Path<TFieldValues>;
    purpose: EUploadResourcesPurpose;
  };
} & FieldProps & {
    control: Control<TFieldValues>;
    selectPosition?: 'before' | 'after';
  };

function BankAccountField<TFieldValues extends FieldValues>({
  label,
  required,
  inputProps,
  selectProps,
  uploadProps,
  control,
}: BankAccountFieldProps<TFieldValues>) {
  const { formState } = useFormContext<TFieldValues>();
  const inputError = get(formState.errors, inputProps.name);
  const selectError = get(formState.errors, selectProps.name);
  const uploadError = get(formState.errors, uploadProps.name);
  const errorMessage =
    (typeof inputError?.message === 'string' && inputError.message) ||
    (typeof selectError?.message === 'string' && selectError.message) ||
    (typeof uploadError?.message === 'string' && uploadError.message);
  return (
    <FieldWithLabel label={label || '정산계좌'} required={required}>
      <div className={'flex items-end gap-sm'}>
        <Controller
          name={inputProps.name}
          control={control}
          render={({ field: { onChange, value, ...lastField }, formState }) => {
            const inputError = get(formState.errors, inputProps.name);
            const selectError = get(formState.errors, selectProps.name);
            const isErrorExist = inputError?.message || selectError?.message;
            const status = isErrorExist ? 'error' : '';

            return (
              <TextInput
                status={status}
                placeholder='계좌번호'
                value={formatNumber(value || '')}
                onChange={(e) => {
                  e.target.value = parseNumber(formatNumber(e.target.value));
                  onChange(e);
                }}
                {...lastField}
                {...inputProps}
                addonBefore={
                  <Controller
                    name={selectProps.name}
                    control={control}
                    render={({ field, formState }) => {
                      const error = get(formState.errors, selectProps.name);
                      const status = error ? 'error' : '';

                      return (
                        <Select
                          className='w-[120px]'
                          options={bankOptions}
                          placeholder='은행선택'
                          status={status}
                          filterOption={(input, option) => {
                            if (!option?.label) {
                              return false;
                            }

                            if (typeof option.label === 'string') {
                              return option.label
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }

                            return option.label === input;
                          }}
                          {...field}
                          {...selectProps}
                        />
                      );
                    }}
                  />
                }
              />
            );
          }}
        />
        <Controller
          render={({ field, formState }) => {
            const error = get(formState.errors, uploadProps.name);
            return (
              <UploadButton
                id={uploadProps.id}
                label={'통장사본'}
                disabled={!!field.value || uploadProps.disabled}
                danger={!!error}
              />
            );
          }}
          name={uploadProps.name}
        />
      </div>
      {errorMessage && (
        <p className={'m-0 text-xs text-red-500'}>{errorMessage}</p>
      )}
      <Controller
        render={({ field }) => {
          const url = field.value ? [field.value] : [];

          return (
            <FileUploader
              multiple={false}
              maxCount={1}
              value={url}
              onChange={([url]) => field.onChange(url || null)}
              {...uploadProps}
            />
          );
        }}
        name={uploadProps.name}
      />
    </FieldWithLabel>
  );
}

export default BankAccountField;
