import { Button } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';

import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import Modal from '../../../common/components/Modal';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import BrandAutoCompleteField from '../../order/component/Fields/BrandAutoCompleteField';
import MarketAutoCompleteField from '../../order/component/Fields/MarketAutoCompleteField';
import ProductAutoCompleteField from '../../order/component/Fields/ProductGroupAutoCompleteField';
import VenderAutoCompleteField from '../../order/component/Fields/VenderAutoCompleteField';
import { ClaimListSearchParams } from '../type';

type SearchFilterModalProps = {
  isOrderSupport?: boolean;
  onClose: () => void;
};

function SearchFilterModal({
  onClose,
  isOrderSupport,
}: SearchFilterModalProps) {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<ClaimListSearchParams>();
  const methods = useForm<
    Omit<
      ClaimListSearchParams,
      | 'claimAssigneeOperatorIds'
      | 'supportCreatorOperatorIds'
      | 'supportAssigneeOperatorIds'
    > & {
      claimAssigneeOperatorIds?: number[];
      supportCreatorOperatorIds?: number[];
      supportAssigneeOperatorIds?: number[];
    }
  >({
    defaultValues: {
      vendorName: searchParams.vendorName,
      brandName: searchParams.brandName,
      productName: searchParams.productName,
      marketName: searchParams.marketName,

      claimAssigneeOperatorIds: searchParams.claimAssigneeOperatorIds?.map(
        (id) => Number(id)
      ),
      supportCreatorOperatorIds: searchParams.supportCreatorOperatorIds?.map(
        (id) => Number(id)
      ),
      supportAssigneeOperatorIds: searchParams.supportAssigneeOperatorIds?.map(
        (id) => Number(id)
      ),
    },
  });
  const { control, handleSubmit } = methods;

  const onSubmit = handleSubmit((data) => {
    setSearchParams({
      ...data,
      claimAssigneeOperatorIds: data.claimAssigneeOperatorIds?.map((id) =>
        id.toString()
      ),
      supportCreatorOperatorIds: data.supportCreatorOperatorIds?.map((id) =>
        id.toString()
      ),
      supportAssigneeOperatorIds: data.supportAssigneeOperatorIds?.map((id) =>
        id.toString()
      ),
    });
    onClose();
  });

  return (
    <Modal title='검색설정' footer={null} onCancel={onClose}>
      <FormProvider {...methods}>
        <form className='flex flex-col gap-6 py-2' onSubmit={onSubmit}>
          <VenderAutoCompleteField
            label='업체명'
            name='vendorName'
            control={control}
            placeholder={'업체명'}
          />
          <BrandAutoCompleteField
            label='브랜드명'
            name='brandName'
            control={control}
            placeholder={'브랜드명'}
          />
          <ProductAutoCompleteField
            label='상품명'
            name='productName'
            control={control}
            placeholder={'상품명'}
          />
          <MarketAutoCompleteField
            label='마켓명'
            name='marketName'
            control={control}
            placeholder={'마켓명'}
          />
          {!isOrderSupport && (
            <FieldWithLabel label='접수자'>
              <ManagerSelectField
                mode='multiple'
                control={control}
                name='claimAssigneeOperatorIds'
              />
            </FieldWithLabel>
          )}
          {isOrderSupport && (
            <>
              <FieldWithLabel label='응대요청자'>
                <ManagerSelectField
                  mode='multiple'
                  control={control}
                  name='supportCreatorOperatorIds'
                />
              </FieldWithLabel>
              <FieldWithLabel label='응대담당자'>
                <ManagerSelectField
                  mode='multiple'
                  control={control}
                  name='supportAssigneeOperatorIds'
                />
              </FieldWithLabel>
            </>
          )}
          <div className='mt-4 flex justify-end gap-2'>
            <Button onClick={onClose}>취소</Button>
            <Button htmlType='submit' type='primary'>
              검색
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default SearchFilterModal;
