import { isNumber, isString } from 'lodash-es';
import { useFormContext } from 'react-hook-form';

type ProductPriceCalculateParams = {
  index: number;
};

type ValueType = number | string | null;

export type ProductPriceFormValues = {
  products: {
    businessSellerSupplyPrice: number | null;
    businessSellerMargin: number | null;
    businessSellerMarginRate: number | null;
    freelanceSellerSupplyPrice: number | null;
    freelanceSellerMargin: number | null;
    freelanceSellerMarginRate: number | null;
    inHousePurchasePrice: number | null;
    businessInHouseMargin: number | null;
    businessInHouseMarginRate: number | null;
    freelanceInHouseMargin: number | null;
    freelanceInHouseMarginRate: number | null;
    jointPurchasePrice: number | null;
    onlineLowestPrice: number | null;
    retailPrice: number | null;
  }[];
};

type ProductKey = keyof ProductPriceFormValues['products'][0];

export default function useProductPriceCalculate({
  index,
}: ProductPriceCalculateParams) {
  const { setValue, getValues } = useFormContext<ProductPriceFormValues>();

  const setPrice = (name: ProductKey, value: number | null) => {
    setValue(`products.${index}.${name}`, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  // 사업자 셀러공급가 변경
  const businessSellerSupplyPriceChange = (value: ValueType) => {
    const price = isString(value) ? Number(value) : value;
    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    if (!(isNumber(price) && isNumber(jointPurchasePrice))) {
      setPrice('businessSellerMargin', null);
      setPrice('businessSellerMarginRate', null);
      setPrice('businessSellerSupplyPrice', null);
      return;
    }

    const margin = jointPurchasePrice - price;
    const marginRate =
      Math.round((margin / jointPurchasePrice) * 100000) / 100000;

    setPrice('businessSellerMargin', margin);
    setPrice('businessSellerMarginRate', marginRate);
    setPrice('businessSellerSupplyPrice', price);

    const inHousePurchasePrice = getValues(
      `products.${index}.inHousePurchasePrice`
    );

    if (!inHousePurchasePrice) return;
    const inHouseMargin = price - inHousePurchasePrice;
    setPrice('businessInHouseMargin', inHouseMargin);

    if (!jointPurchasePrice) return;
    setPrice(
      'businessInHouseMarginRate',
      Math.round((inHouseMargin / jointPurchasePrice) * 100000) / 100000
    );
  };

  // 프리랜서 셀러공급가 변경(마진 * 1.1 기준으로)
  const setFreelanceSellerSupplyPrice = () => {
    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    const businessSellerSupplyPrice = getValues(
      `products.${index}.businessSellerSupplyPrice`
    );

    if (!(isNumber(jointPurchasePrice) && isNumber(businessSellerSupplyPrice)))
      return;

    const margin = jointPurchasePrice - businessSellerSupplyPrice;

    const freelanceSupplyPrice = jointPurchasePrice - Math.round(margin / 1.1);

    const freelanceSellerSupplyPrice = getValues(
      `products.${index}.freelanceSellerSupplyPrice`
    );

    if (freelanceSupplyPrice !== freelanceSellerSupplyPrice) {
      freelanceSellerSupplyPriceChange(freelanceSupplyPrice);
    }
  };

  // 사업자 셀러공급가 변경
  const handleBusinessSellerSupplyPriceChange = () => {
    const businessSellerSupplyPrice = getValues(
      `products.${index}.businessSellerSupplyPrice`
    );

    businessSellerSupplyPriceChange(businessSellerSupplyPrice);
    setFreelanceSellerSupplyPrice();
  };

  // 사업자 셀러마진 변경
  const handleBusinessSellerMarginChange = () => {
    const value = getValues(`products.${index}.businessSellerMargin`);

    const price = isString(value) ? Number(value) : value;

    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    if (!(isNumber(price) && isNumber(jointPurchasePrice))) {
      setPrice('businessSellerSupplyPrice', null);
      return;
    }

    const supplyPrice = jointPurchasePrice - price;
    businessSellerSupplyPriceChange(supplyPrice);
    setFreelanceSellerSupplyPrice();
  };

  // 사업자 셀러마진율 변경
  const handleBusinessSellerMarginRateChange = () => {
    const value = getValues(`products.${index}.businessSellerMarginRate`);

    const rate = isString(value) ? Number(value) : value;

    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    if (!(isNumber(rate) && isNumber(jointPurchasePrice))) {
      setPrice('businessSellerSupplyPrice', null);
      return;
    }

    const margin = Math.round(jointPurchasePrice * rate);
    const supplyPrice = jointPurchasePrice - margin;

    businessSellerSupplyPriceChange(supplyPrice);
    setFreelanceSellerSupplyPrice();
  };

  // 프리랜서 셀러공급가 변경
  const freelanceSellerSupplyPriceChange = (value: ValueType) => {
    const price = isString(value) ? Number(value) : value;
    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    if (!(isNumber(price) && isNumber(jointPurchasePrice))) {
      setPrice('freelanceSellerMargin', null);
      setPrice('freelanceSellerMarginRate', null);
      setPrice('freelanceSellerSupplyPrice', null);
      return;
    }

    const margin = jointPurchasePrice - price;
    const marginRate =
      Math.round((margin / jointPurchasePrice) * 100000) / 100000;

    setPrice('freelanceSellerMargin', margin);
    setPrice('freelanceSellerMarginRate', marginRate);
    setPrice('freelanceSellerSupplyPrice', price);

    const inHousePurchasePrice = getValues(
      `products.${index}.inHousePurchasePrice`
    );

    if (!inHousePurchasePrice) return;
    const inHouseMargin = price - inHousePurchasePrice;
    setPrice('freelanceInHouseMargin', inHouseMargin);

    if (!jointPurchasePrice) return;
    setPrice(
      'freelanceInHouseMarginRate',
      Math.round((inHouseMargin / jointPurchasePrice) * 100000) / 100000
    );
  };

  // 사업자 셀러공급가 변경(마진 / 1.1 기준으로)
  const setBusinessSellerSupplyPrice = () => {
    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    const freelanceSellerSupplyPrice = getValues(
      `products.${index}.freelanceSellerSupplyPrice`
    );

    if (!(isNumber(jointPurchasePrice) && isNumber(freelanceSellerSupplyPrice)))
      return;

    const margin = jointPurchasePrice - freelanceSellerSupplyPrice;

    const businessSupplyPrice = jointPurchasePrice - Math.round(margin * 1.1);

    const businessSellerSupplyPrice = getValues(
      `products.${index}.freelanceSellerSupplyPrice`
    );

    if (businessSupplyPrice !== businessSellerSupplyPrice) {
      businessSellerSupplyPriceChange(businessSupplyPrice);
    }
  };

  // 프리랜서 셀러공급가 변경
  const handleFreelanceSellerSupplyPriceChange = () => {
    const freelanceSellerSupplyPrice = getValues(
      `products.${index}.freelanceSellerSupplyPrice`
    );

    freelanceSellerSupplyPriceChange(freelanceSellerSupplyPrice);
    setBusinessSellerSupplyPrice();
  };

  // 프리랜서 셀러마진 변경
  const handleFreelanceSellerMarginChange = () => {
    const value = getValues(`products.${index}.freelanceSellerMargin`);

    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    const price = isString(value) ? Number(value) : value;

    if (!(isNumber(price) && jointPurchasePrice)) {
      setPrice('freelanceSellerSupplyPrice', null);
      return;
    }

    const supplyPrice = jointPurchasePrice - price;
    freelanceSellerSupplyPriceChange(supplyPrice);
    setBusinessSellerSupplyPrice();
  };

  // 프리랜서 셀러마진율 변경
  const handleFreelanceSellerMarginRateChange = () => {
    const value = getValues(`products.${index}.freelanceSellerMarginRate`);

    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    const rate = isString(value) ? Number(value) : value;

    if (!(isNumber(rate) && jointPurchasePrice)) {
      setPrice('freelanceSellerSupplyPrice', null);
      return;
    }

    const margin = Math.round(jointPurchasePrice * rate);
    const supplyPrice = jointPurchasePrice - margin;

    freelanceSellerSupplyPriceChange(supplyPrice);
    setBusinessSellerSupplyPrice();
  };

  const inHousePurchasePriceChange = (value: ValueType) => {
    const price = isString(value) ? Number(value) : value;

    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    const businessSellerSupplyPrice = getValues(
      `products.${index}.businessSellerSupplyPrice`
    );

    const freelanceSellerSupplyPrice = getValues(
      `products.${index}.freelanceSellerSupplyPrice`
    );

    if (
      !(
        isNumber(price) &&
        isNumber(jointPurchasePrice) &&
        isNumber(businessSellerSupplyPrice) &&
        isNumber(freelanceSellerSupplyPrice)
      )
    ) {
      setPrice('businessInHouseMargin', null);
      setPrice('businessInHouseMarginRate', null);
      setPrice('freelanceInHouseMargin', null);
      setPrice('freelanceInHouseMarginRate', null);
      return;
    }

    const businessInHouseMargin = businessSellerSupplyPrice - price;

    setPrice('businessInHouseMargin', businessInHouseMargin);
    const businessInHouseMarginRate =
      Math.round((businessInHouseMargin / jointPurchasePrice) * 100000) /
      100000;
    setPrice('businessInHouseMarginRate', businessInHouseMarginRate);

    const freelanceInHouseMargin = freelanceSellerSupplyPrice - price;
    setPrice('freelanceInHouseMargin', freelanceInHouseMargin);
    const freelanceInHouseMarginRate =
      Math.round((freelanceInHouseMargin / jointPurchasePrice) * 100000) /
      100000;
    setPrice('freelanceInHouseMarginRate', freelanceInHouseMarginRate);

    setPrice('inHousePurchasePrice', price);
  };

  // 자사매입가 변경
  const handleInHousePurchasePriceChange = () => {
    const value = getValues(`products.${index}.inHousePurchasePrice`);
    inHousePurchasePriceChange(value);
  };

  // 판매자 자사마진 변경
  const handleBusinessInHouseSellerMargin = () => {
    const value = getValues(`products.${index}.businessInHouseMargin`);

    const businessSellerSupplyPrice = getValues(
      `products.${index}.businessSellerSupplyPrice`
    );

    const price = isString(value) ? Number(value) : value;

    if (!(isNumber(price) && isNumber(businessSellerSupplyPrice))) {
      setPrice('inHousePurchasePrice', null);
      return;
    }

    const inHousePurchasePrice = businessSellerSupplyPrice - price;
    inHousePurchasePriceChange(inHousePurchasePrice);
  };

  // 판매자 자사마진율 변경
  const handleBusinessInHouseMarginRateRateChange = () => {
    const value = getValues(`products.${index}.businessInHouseMarginRate`);

    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    const businessSellerSupplyPrice = getValues(
      `products.${index}.businessSellerSupplyPrice`
    );

    const rate = isString(value) ? Number(value) : value;

    if (
      !(
        isNumber(rate) &&
        isNumber(jointPurchasePrice) &&
        isNumber(businessSellerSupplyPrice)
      )
    ) {
      setPrice('inHousePurchasePrice', null);
      return;
    }

    const margin = Math.round(jointPurchasePrice * rate);
    inHousePurchasePriceChange(businessSellerSupplyPrice - margin);
  };

  // 프리랜서 자사마진 변경
  const handleFreelanceInHouseSellerMargin = () => {
    const value = getValues(`products.${index}.freelanceInHouseMargin`);
    const price = isString(value) ? Number(value) : value;

    const freelanceSellerSupplyPrice = getValues(
      `products.${index}.freelanceSellerSupplyPrice`
    );

    if (!(isNumber(price) && isNumber(freelanceSellerSupplyPrice))) {
      setPrice('inHousePurchasePrice', null);
      return;
    }

    const inHousePurchasePrice = freelanceSellerSupplyPrice - price;
    inHousePurchasePriceChange(inHousePurchasePrice);
  };

  // 프리랜서 자사마진율 변경
  const handleFreelanceInHouseMarginRateRateChange = () => {
    const value = getValues(`products.${index}.freelanceInHouseMarginRate`);

    const rate = isString(value) ? Number(value) : value;

    const jointPurchasePrice = getValues(
      `products.${index}.jointPurchasePrice`
    );

    const freelanceSellerSupplyPrice = getValues(
      `products.${index}.freelanceSellerSupplyPrice`
    );

    if (
      !(
        isNumber(rate) &&
        isNumber(jointPurchasePrice) &&
        isNumber(freelanceSellerSupplyPrice)
      )
    ) {
      setPrice('inHousePurchasePrice', null);
      return;
    }

    const margin = Math.round(jointPurchasePrice * rate);
    inHousePurchasePriceChange(freelanceSellerSupplyPrice - margin);
  };

  const handleRetailPriceChange = () => {
    const value = getValues(`products.${index}.retailPrice`);

    const price = isString(value) ? Number(value) : value;
    setPrice('retailPrice', price);
  };

  const handleOnlineLowestPriceChange = () => {
    const value = getValues(`products.${index}.onlineLowestPrice`);

    const price = isString(value) ? Number(value) : value;
    setPrice('onlineLowestPrice', price);
  };

  // 공동구매가 변경
  const handleJointPurchasePriceChange = () => {
    const value = getValues(`products.${index}.jointPurchasePrice`);
    const price = isString(value) ? Number(value) : value;

    if (!isNumber(price)) {
      setPrice('businessSellerMargin', null);
      setPrice('businessSellerMarginRate', null);
      setPrice('freelanceSellerMargin', null);
      setPrice('freelanceSellerMarginRate', null);

      return;
    }

    const businessSellerSupplyPrice = getValues(
      `products.${index}.businessSellerSupplyPrice`
    );

    if (!isNumber(businessSellerSupplyPrice)) {
      setPrice('businessSellerMargin', null);
      setPrice('businessSellerMarginRate', null);
    } else {
      // 사업자 셀러 마진 변경
      const businessSellerMargin = price - businessSellerSupplyPrice;
      // 사업자 셀러 마진율 변경
      const businessSellerMarginRate =
        Math.round((businessSellerMargin / price) * 100000) / 100000;

      setPrice('businessSellerMargin', businessSellerMargin);
      setPrice('businessSellerMarginRate', businessSellerMarginRate);
    }

    const freelanceSellerSupplyPrice = getValues(
      `products.${index}.freelanceSellerSupplyPrice`
    );

    if (!isNumber(freelanceSellerSupplyPrice)) {
      setPrice('freelanceSellerMargin', null);
      setPrice('freelanceSellerMarginRate', null);
    } else {
      // 프리랜서 셀러 마진 변경
      const freelanceSellerMargin = price - freelanceSellerSupplyPrice;
      // 프리랜서 셀러 마진율 변경
      const freelanceSellerMarginRate =
        Math.round((freelanceSellerMargin / price) * 100000) / 100000;

      setPrice('freelanceSellerMargin', freelanceSellerMargin);
      setPrice('freelanceSellerMarginRate', freelanceSellerMarginRate);
    }
  };

  return {
    handleRetailPriceChange,
    handleOnlineLowestPriceChange,
    handleJointPurchasePriceChange,
    handleBusinessSellerSupplyPriceChange,
    handleBusinessSellerMarginChange,
    handleBusinessSellerMarginRateChange,
    handleFreelanceSellerSupplyPriceChange,
    handleFreelanceSellerMarginChange,
    handleFreelanceSellerMarginRateChange,
    handleInHousePurchasePriceChange,
    handleBusinessInHouseSellerMargin,
    handleBusinessInHouseMarginRateRateChange,
    handleFreelanceInHouseSellerMargin,
    handleFreelanceInHouseMarginRateRateChange,
  };
}
