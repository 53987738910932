import { DeleteOutlined } from '@ant-design/icons';
import { Button, Descriptions, InputProps, SelectProps } from 'antd';
import { useMemo } from 'react';
import { Control, FieldValues, Path, useFormContext } from 'react-hook-form';

import TextField from '../../../common/components/Fields/TextField';
import TextNumberField from '../../../common/components/Fields/TextNumberField';
import TextWithSelectField from '../../../common/components/Fields/TextWithSelectField';
import { CONTACT_ROLES } from '../const';

type ContactFormProps<TFieldValues extends FieldValues> = {
  typeSelectProps: SelectProps & {
    name: Path<TFieldValues>;
  };
  nameInputProps: InputProps & {
    name: Path<TFieldValues>;
  };
  emailInputProps: InputProps & {
    name: Path<TFieldValues>;
  };
  phoneInputProps: InputProps & {
    name: Path<TFieldValues>;
  };
  type?: 'default' | 'row';
  onRemove: () => void;
  disabled?: boolean;
} & {
  control: Control<TFieldValues>;
};

function ContactForm<TFieldValues extends FieldValues>({
  control,
  typeSelectProps,
  nameInputProps,
  emailInputProps,
  phoneInputProps,
  type = 'default',
  onRemove,
  disabled,
}: ContactFormProps<TFieldValues>) {
  const { watch } = useFormContext<TFieldValues>();
  const phone = watch(phoneInputProps.name) as string | undefined;

  const [format, maxLength] = useMemo(() => {
    if (phone) {
      if (phone.startsWith('02')) {
        return [phone.length < 10 ? '##-###-####' : '##-####-####', 12];
      } else {
        return [phone.length < 11 ? '###-###-####' : '###-####-####', 13];
      }
    } else {
      return [undefined, undefined];
    }
  }, [phone]);

  const descriptionsProps = useMemo(
    () =>
      type === 'default'
        ? {
            column: 1,
            className: 'vendor__descriptions mt-4',
          }
        : {
            column: 4,
            labelStyle: { width: 120 },
            className: 'vendor__descriptions',
          },
    [type]
  );

  return (
    <>
      <Descriptions
        bordered
        contentStyle={{ paddingTop: 0, paddingBottom: 0 }}
        {...descriptionsProps}
      >
        <Descriptions.Item label='담당자'>
          <TextWithSelectField
            control={control}
            selectProps={{
              showSearch: false,
              options: CONTACT_ROLES,
              style: { width: 68 },
              disabled,
              ...typeSelectProps,
            }}
            inputProps={{
              placeholder: '담당자명',
              disabled,
              ...nameInputProps,
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label='이메일주소'>
          <TextField
            placeholder='이메일주소'
            control={control}
            disabled={disabled}
            {...emailInputProps}
          />
        </Descriptions.Item>
        <Descriptions.Item label='전화번호'>
          <TextNumberField
            placeholder='전화번호'
            control={control}
            maxLength={maxLength}
            format={format}
            formatStrict={false}
            disabled={disabled}
            {...phoneInputProps}
          />
        </Descriptions.Item>
        {type === 'row' && (
          <Descriptions.Item
            labelStyle={{ display: 'none' }}
            contentStyle={{
              width: 60,
              padding: 0,
              textAlign: 'center',
            }}
          >
            <Button
              type='text'
              icon={<DeleteOutlined />}
              onClick={onRemove}
              disabled={disabled}
            />
          </Descriptions.Item>
        )}
      </Descriptions>
      {type === 'default' && (
        <div className='mt-2 text-right'>
          <Button
            icon={<DeleteOutlined />}
            size='small'
            onClick={onRemove}
            disabled={disabled}
          >
            연락처삭제
          </Button>
        </div>
      )}
    </>
  );
}

export default ContactForm;
