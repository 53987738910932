import { Button, Typography } from 'antd';
import { useState } from 'react';
import {
  FieldValues,
  UseFieldArrayAppend,
  useFormContext,
} from 'react-hook-form';

import {
  getLocalProductOptions,
  useLocalProductOptionMutation,
  useLocalProductOptionsQuery,
} from '../graphql/localProductOptions';
import { itemCodeGenerator } from '../helper/generator';
import { uploadedProductMapper } from '../helper/mapper';
import useGetReferencedMarkets from '../hooks/useGetReferencedMarkets';
import { ProductGroupFormValues, UploadedDataType } from '../types';

import ExcelUploadModal from './ExcelUploadModal';
import PriceInformationReferencedMarket from './PriceInformationReferencedMarket';

type ProductPriceInformationHeaderProps = {
  disabled?: boolean;
  append: UseFieldArrayAppend<FieldValues, 'products'>;
};

export default function ProductPriceInformationHeader({
  disabled,
  append,
}: ProductPriceInformationHeaderProps) {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const { getValues } = useFormContext<ProductGroupFormValues>();
  const { setLocalProductOptions } = useLocalProductOptionMutation();
  const { data: localOptions } = useLocalProductOptionsQuery();

  const { markets } = useGetReferencedMarkets();

  const formType = getValues('formType');

  const handleEndUpload = (uploadedData: UploadedDataType[]) => {
    const productGroupName = getValues('name');

    const mappedProducts = uploadedProductMapper(
      uploadedData,
      productGroupName
    );

    const options = mappedProducts.reduce(
      (acc, cur) => {
        cur.items?.forEach((item) => {
          if (!acc[item.name]) {
            acc[item.name] = {
              name: item.name,
              code: itemCodeGenerator(),
              productGroupNames: [productGroupName],
            };
          }
        });

        return acc;
      },
      {} as {
        [key: string]: {
          name: string;
          code: string;
          productGroupNames: string[];
        };
      }
    );

    const newOptions = Object.values(options).filter((option) => {
      const exist = localOptions?.localProductOptions.find(
        (localOptions) => localOptions.name === option.name
      );

      return !exist;
    });

    newOptions.length && setLocalProductOptions(newOptions);

    const localProductOptions = getLocalProductOptions();

    mappedProducts.forEach((product) => {
      const items = product.items?.map((item) => {
        return localProductOptions.find(
          (localOption) => localOption.name === item.name
        );
      });

      append({
        ...product,
        items,
      });
    });

    setUploadModalOpen(false);
  };

  return (
    <div>
      {formType === 'UPDATE' && markets.length > 0 && (
        <div className='mb-6'>
          <PriceInformationReferencedMarket markets={markets} />
        </div>
      )}
      <div className='flex items-center justify-between'>
        <Typography.Title level={4} className={'m-0'}>
          품목리스트
        </Typography.Title>
        <Button
          type='primary'
          onClick={() => setUploadModalOpen(true)}
          disabled={disabled}
        >
          품목 일괄업로드
        </Button>
      </div>
      {uploadModalOpen && (
        <ExcelUploadModal
          onClose={() => setUploadModalOpen(false)}
          endUpload={handleEndUpload}
        />
      )}
    </div>
  );
}
