import { UploadOutlined } from '@ant-design/icons';
import { App, Button, Upload, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useState } from 'react';

import { useFileUpload } from '../../../../common/hooks/useFileUpload';
import { ALLOW_UPLOAD_IMAGE_MIME_TYPES } from '../../../../common/utils/const';
import { EUploadResourcesPurpose } from '../../../../schema/types';

type ImageUploaderProps = {
  endUpload: (url: string) => void;
  purpose: EUploadResourcesPurpose;
};

const UPLOADER_ID = 'imageFileUpload';

export default function ImageUploader({
  endUpload,
  purpose,
}: ImageUploaderProps) {
  const { message } = App.useApp();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { upload, fetchUploadResources } = useFileUpload({
    purpose,
  });

  return (
    <Upload
      className='absolute'
      accept={ALLOW_UPLOAD_IMAGE_MIME_TYPES.join(',')}
      fileList={fileList}
      onChange={(e) => {
        setFileList(e.fileList);
      }}
      maxCount={1}
      multiple={false}
      itemRender={() => {
        // 따로 대표이미지 컴퍼넌트에서 보여주기 위해 render를 비워둠
        return null;
      }}
      id={UPLOADER_ID}
      customRequest={async (options) => {
        const file = options.file as RcFile;

        try {
          const resource = await fetchUploadResources(file);
          const url = await upload(resource, file);

          endUpload(url);
        } catch (e) {
          void message.error('파일 업로드를 실패하였습니다.');
        }
      }}
    >
      {/* fileList만 보이게 하기 위한 빈 DOM 추가 */}
      <span />
    </Upload>
  );
}

type ImageUploaderButtonProps = {
  disabled?: boolean;
};

export function ImageUploaderButton({ disabled }: ImageUploaderButtonProps) {
  return (
    <Button htmlType='button' className='p-0' disabled={disabled}>
      <label
        htmlFor={UPLOADER_ID}
        className='block h-8 w-[30px] cursor-pointer justify-center pt-1'
      >
        <UploadOutlined className='text-base' />
      </label>
    </Button>
  );
}
