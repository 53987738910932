import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminProductGroupsInOrderSearchQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductGroupFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminProductGroupsInOrderSearchQuery = { __typename?: 'Query', adminProductGroups?: { __typename?: 'ProductGroupPageGraphqlType', edges: Array<{ __typename?: 'PaginatedProductGroupGraphqlTypeEdge', node: { __typename?: 'ProductGroupGraphqlType', id: number, name: string } }> } | null };


export const AdminProductGroupsInOrderSearchDocument = gql`
    query AdminProductGroupsInOrderSearch($filter: ProductGroupFilterQuery, $pagination: Pagination) {
  adminProductGroups(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAdminProductGroupsInOrderSearchQuery__
 *
 * To run a query within a React component, call `useAdminProductGroupsInOrderSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductGroupsInOrderSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductGroupsInOrderSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminProductGroupsInOrderSearchQuery(baseOptions?: Apollo.QueryHookOptions<AdminProductGroupsInOrderSearchQuery, AdminProductGroupsInOrderSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductGroupsInOrderSearchQuery, AdminProductGroupsInOrderSearchQueryVariables>(AdminProductGroupsInOrderSearchDocument, options);
      }
export function useAdminProductGroupsInOrderSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductGroupsInOrderSearchQuery, AdminProductGroupsInOrderSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductGroupsInOrderSearchQuery, AdminProductGroupsInOrderSearchQueryVariables>(AdminProductGroupsInOrderSearchDocument, options);
        }
export type AdminProductGroupsInOrderSearchQueryHookResult = ReturnType<typeof useAdminProductGroupsInOrderSearchQuery>;
export type AdminProductGroupsInOrderSearchLazyQueryHookResult = ReturnType<typeof useAdminProductGroupsInOrderSearchLazyQuery>;
export type AdminProductGroupsInOrderSearchQueryResult = Apollo.QueryResult<AdminProductGroupsInOrderSearchQuery, AdminProductGroupsInOrderSearchQueryVariables>;