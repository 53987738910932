import * as Types from '../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SellersInSelectFieldQueryVariables = Types.Exact<{
  sellersInput: Types.SellersGraphqlInputType;
}>;


export type SellersInSelectFieldQuery = { __typename?: 'Query', adminSellers: { __typename?: 'SellersPayload', sellers: Array<{ __typename?: 'SellerGraphqlType', id: number, name: string }> } };


export const SellersInSelectFieldDocument = gql`
    query SellersInSelectField($sellersInput: SellersGraphqlInputType!) {
  adminSellers(sellersInput: $sellersInput) {
    sellers {
      id
      name
    }
  }
}
    `;

/**
 * __useSellersInSelectFieldQuery__
 *
 * To run a query within a React component, call `useSellersInSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersInSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersInSelectFieldQuery({
 *   variables: {
 *      sellersInput: // value for 'sellersInput'
 *   },
 * });
 */
export function useSellersInSelectFieldQuery(baseOptions: Apollo.QueryHookOptions<SellersInSelectFieldQuery, SellersInSelectFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellersInSelectFieldQuery, SellersInSelectFieldQueryVariables>(SellersInSelectFieldDocument, options);
      }
export function useSellersInSelectFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellersInSelectFieldQuery, SellersInSelectFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellersInSelectFieldQuery, SellersInSelectFieldQueryVariables>(SellersInSelectFieldDocument, options);
        }
export type SellersInSelectFieldQueryHookResult = ReturnType<typeof useSellersInSelectFieldQuery>;
export type SellersInSelectFieldLazyQueryHookResult = ReturnType<typeof useSellersInSelectFieldLazyQuery>;
export type SellersInSelectFieldQueryResult = Apollo.QueryResult<SellersInSelectFieldQuery, SellersInSelectFieldQueryVariables>;