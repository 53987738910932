import { Pagination, Table, Tooltip } from 'antd';
import { useCallback } from 'react';

import EmptyTable from '../../../../common/components/EmptyTable';
import Label from '../../../../common/components/Fields/Label';
import useRouteSearchParams from '../../../../common/hooks/useRouteSearchParams';
import { APP_HEADER_HEIGHT } from '../../../../theme/const';
import useDepositTable from '../../hooks/useDepositTable';
import { DepositListSearchParams, DepositListTableDataType } from '../../types';

import DepositDeleteCell from './DepositDeleteCell';
import DepositStatusCell from './DepositStatusCell';

function DepositTable() {
  const { dataSources, loading, total } = useDepositTable();
  const { searchParams, setSearchParams } =
    useRouteSearchParams<DepositListSearchParams>();
  const pageSize = searchParams.pageSize ? Number(searchParams.pageSize) : 50;
  const currentPage = searchParams.currentPage
    ? Number(searchParams.currentPage)
    : 1;
  const handlePagination = useCallback(
    (page: number, size: number) => {
      if (size !== pageSize) {
        setSearchParams({ currentPage: 1, pageSize: size });
      } else {
        setSearchParams({ currentPage: page });
      }
    },
    [pageSize, setSearchParams]
  );

  if (!loading && dataSources.length === 0) return <EmptyTable />;

  return (
    <>
      <Table<DepositListTableDataType>
        sticky={{ offsetHeader: APP_HEADER_HEIGHT }}
        size='small'
        tableLayout='fixed'
        scroll={{ x: 2010 }}
        pagination={false}
        loading={loading}
        dataSource={dataSources}
      >
        <Table.Column<DepositListTableDataType>
          title='입금코드'
          width={160}
          dataIndex='depositCode'
        />
        <Table.Column<DepositListTableDataType>
          title={
            <Label tooltip='입금상태는 단 1회만 변경가능합니다. 수정이 필요한 경우, 입금대기를 새로 등록해주세요.'>
              입금상태
            </Label>
          }
          width={140}
          dataIndex='status'
          render={(_, record) => (
            <DepositStatusCell id={record.depositId} status={record.status} />
          )}
        />
        <Table.Column<DepositListTableDataType>
          title='비용상세'
          width={140}
          dataIndex='chargeDetail'
        />
        <Table.Column<DepositListTableDataType>
          title='금액'
          width={140}
          dataIndex='depositAmount'
        />
        <Table.Column<DepositListTableDataType>
          title='입금자명'
          width={140}
          dataIndex='depositorName'
        />
        <Table.Column<DepositListTableDataType>
          title='정산대상'
          width={240}
          dataIndex='settlementTarget'
          render={(_, record) => {
            let settlementText = record.settlementTarget;
            if (record.settlementTargetName) {
              settlementText += ` - ${record.settlementTargetName}`;
            }
            return (
              <Tooltip title={settlementText}>
                <div className='line-clamp-1'>{settlementText}</div>
              </Tooltip>
            );
          }}
        />
        <Table.Column<DepositListTableDataType>
          title='와이어드주문코드'
          width={170}
          dataIndex='orderCode'
        />
        <Table.Column<DepositListTableDataType>
          title='요청자'
          width={120}
          dataIndex='creatorOperatorName'
        />
        <Table.Column<DepositListTableDataType>
          title='담당자'
          width={120}
          dataIndex='assigneeOperatorName'
          render={(_, record) => record.assigneeOperatorName || '-'}
        />
        <Table.Column<DepositListTableDataType>
          title='요청일시'
          width={170}
          dataIndex='createdAt'
        />
        <Table.Column<DepositListTableDataType>
          title='입금일자'
          width={120}
          dataIndex='depositedAt'
        />
        <Table.Column<DepositListTableDataType>
          title='비고'
          width={240}
          dataIndex='comment'
          render={(_, record) =>
            record.comment ? (
              <Tooltip title={record.comment}>
                <div className='line-clamp-1'>{record.comment}</div>
              </Tooltip>
            ) : (
              '-'
            )
          }
        />
        <Table.Column<DepositListTableDataType>
          title='삭제'
          width={120}
          render={(_, record) => <DepositDeleteCell id={record.depositId} />}
        />
      </Table>
      <div className='mt-4 flex items-center justify-end gap-4'>
        <div>총 {total}건</div>
        <Pagination
          showSizeChanger
          onChange={handlePagination}
          current={currentPage}
          pageSize={pageSize}
          total={total}
        />
      </div>
    </>
  );
}

export default DepositTable;
