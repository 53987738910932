import { isPlainObject, isArray } from 'lodash-es';

type HistoryLabelType = Record<string, string>;

export class HistoryMapper {
  private readonly INDENT = 4; // 기본 들여쓰기
  private readonly DASH = '- '.repeat(12); // 배열 구분 라인
  private readonly LINE_BREAK = '\n'; // 줄바꿈

  private label: HistoryLabelType;

  constructor(label: HistoryLabelType) {
    this.label = label;
  }

  private indentSpaces(indent: number): string {
    return ' '.repeat(indent);
  }

  private removeQuotes(str: string): string {
    return str.replace(/"/g, '');
  }

  private getArrayNumber(num: number): string {
    // 배열 카운팅 두자리까지 고려
    return num > 9 ? `${num}: ` : `${num} : `;
  }

  private getArrayDash(spaces: string): string {
    return `${this.LINE_BREAK}${spaces}${this.indentSpaces(this.INDENT)}${
      this.DASH
    }`;
  }

  private stringify(data: unknown, indent: number, key: string): string {
    // 최초진입
    if (isPlainObject(data)) {
      const stringified = this.objectStringify(data, indent, key);

      // 히스토리 첫라인 줄바꿈 제거
      if (indent === 0) return stringified.replace(this.LINE_BREAK, '');

      return stringified;
    }

    // TODO: object가 아닌 나머지 타입은 indent와 key가 필요없음. data로 판단하여 indent, key 필수 여부를 판단하도록 수정필요
    if (isArray(data)) return this.arrayStringify(data, indent, key);

    if (typeof data === 'string') return data;

    if (data === undefined || data === null || data === '') return '';

    return String(data);
  }

  private objectStringify(
    data: unknown,
    indent: number,
    key: string,
    parentArray?: boolean // 부모가 배열인지 아닌지 체크
  ): string {
    const items = Object.entries(data as Record<string, unknown>).map(
      ([itemKey, value], index) => {
        let spaces = this.indentSpaces(indent); // 들여쓰기 공백
        let addedIndent = indent + this.INDENT; // 들여쓰기

        const currentKey = key ? `${key}.${itemKey}` : itemKey;
        const mappedKey = this.label[currentKey] ?? itemKey;
        let br = this.LINE_BREAK;

        if (parentArray) {
          // 부모가 배열이고 첫번째 라인이 아닐때 배열 카운트(4) 만큼 들여써야함
          addedIndent += this.INDENT;
          spaces += this.indentSpaces(this.INDENT);

          // 부모가 배열인 객체의 첫번째 값일때 배열카운트 바로 옆으로 위치해야 하므로 들여쓰기와 줄바꿈을 삭제해준다.
          if (index === 0) {
            br = '';
            spaces = '';
          }
        }

        const stringified = this.stringify(value, addedIndent, currentKey);

        return `${br}${spaces}${mappedKey}: ${stringified}`;
      }
    );

    return items.join('');
  }

  private arrayStringify(data: unknown, indent: number, key: string): string {
    const spaces = this.indentSpaces(indent);

    const items = (data as unknown[]).map((item, index) => {
      const arrayNum = this.getArrayNumber(index + 1); // 배열 카운트
      const dash = index > 0 ? this.getArrayDash(spaces) : '';

      let stringified = '';

      if (isPlainObject(item)) {
        stringified = this.objectStringify(item, indent, key, true);
      } else {
        stringified = this.stringify(item, indent + this.INDENT, key);
      }

      return `${dash}${this.LINE_BREAK}${spaces}${arrayNum}${stringified}`;
    });

    return items.join('');
  }

  getHistory(snapshot: string): string {
    const data = (() => {
      try {
        return JSON.parse(snapshot) as object;
      } catch (e) {
        return '';
      }
    })();

    const stringified = this.stringify(data, 0, '');
    return this.removeQuotes(stringified);
  }
}
