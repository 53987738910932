import { Input, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { SETTLEMENT_CODE_OPTIONS } from '../../const';
import useSettlementListSearchForm from '../../hooks/useSettlementListSearchForm';
import { SettlementCodeOption, SettlementListSearchParams } from '../../types';

function CodeSelect() {
  const { searchParams, setSearchParams, handleSettlementCodeChange } =
    useSettlementListSearchForm();

  const codeOptionValue = useMemo(() => {
    if (searchParams.orderCode) return 'orderCode';

    if (searchParams.promotionReleaseCode) return 'promotionReleaseCode';
    if (searchParams.settlementSheetCode) return 'settlementSheetCode';

    return 'orderGroupCode';
  }, [
    searchParams.orderCode,
    searchParams.promotionReleaseCode,
    searchParams.settlementSheetCode,
  ]);

  const [option, setOption] = useState<SettlementCodeOption>(codeOptionValue);
  const [code, setCode] = useState(searchParams[option] || undefined);

  useEffect(() => {
    handleSettlementCodeChange({ code, option });
  }, [code]);

  useEffect(() => {
    const params: SettlementListSearchParams = {
      orderGroupCode: undefined,
      orderCode: undefined,
      promotionReleaseCode: undefined,
      settlementSheetCode: undefined,
      currentPage: undefined,
    };

    params[option] = code;

    setSearchParams(params);
  }, [option]);

  return (
    <div className='flex gap-2'>
      <Select
        options={SETTLEMENT_CODE_OPTIONS}
        className='w-[180px]'
        value={option}
        placeholder='코드 선택'
        onChange={setOption}
      />
      <Input
        className='w-[345px]'
        placeholder='코드 입력'
        onChange={(event) => setCode(event.target.value)}
        value={code}
      />
    </div>
  );
}

export default CodeSelect;
