import type { ColumnsType } from 'antd/es/table';

import Label from '../../common/components/Fields/Label';
import {
  EPromotionType,
  ECostBearer,
  EPromotionReleaseStatus,
  ESampleStatus,
  EPromotionReleasePeriodFilter,
  EPromotionBearerTypeFilter,
  EShippingBearer,
  ESettlementTarget,
} from '../../schema/types';

import { EditableTableDataType } from './types';

export const PROMOTION_TYPES: { label: string; value: EPromotionType }[] = [
  { label: '샘플', value: 'SAMPLE' },
  { label: '이벤트', value: 'EVENT' },
  { label: '기타', value: 'EXTRA' },
  { label: '교환재출고', value: 'EXCHANGE_RE_DELIVERY' },
];

export const PROMTION_TYPES_LABEL: { [k in EPromotionType]: string } = {
  SAMPLE: '샘플',
  EVENT: '이벤트',
  EXTRA: '기타',
  EXCHANGE_RE_DELIVERY: '교환재출고',
};

export const DELIVERY_FEE_BEARER_OPTIONS: {
  label: string;
  value: ECostBearer;
}[] = [
  { label: '셀러', value: 'SELLER' },
  { label: '와이어드', value: 'WIRED' },
  { label: '물류', value: 'DELIVERY' },
];

export const DELIVERY_FEE_BEARER_LABEL: {
  [k in ECostBearer | EShippingBearer]: string;
} = DELIVERY_FEE_BEARER_OPTIONS.reduce(
  (acc, cur) => ({
    ...acc,
    [cur.value]: cur.label,
  }),
  {} as { [k in ECostBearer | EShippingBearer]: string }
);

export const DELIVERY_FEE_SETTLEMENT_TARGET: {
  label: string;
  value: ESettlementTarget;
}[] = [
  { label: '셀러', value: 'SELLER' },
  { label: '와이어드', value: 'WIRED' },
  { label: '업체', value: 'VENDOR' },
];

export const DELIVERY_FEE_SETTLEMENT_TARGET_LABEL: {
  [k in ESettlementTarget]: string;
} = DELIVERY_FEE_SETTLEMENT_TARGET.reduce(
  (acc, cur) => ({
    ...acc,
    [cur.value]: cur.label,
  }),
  {} as { [k in ESettlementTarget]: string }
);

export const PRE_PAYMENT_OPTIONS = [
  { label: '정산차감', value: false },
  { label: '선결제', value: true },
];

export const PERIOD_OPTIONS: {
  label: string;
  value: EPromotionReleasePeriodFilter;
}[] = [
  { label: '요청일', value: 'RELEASE_REQUEST_DATE' },
  { label: '발주확인일', value: 'ORDER_CONFIRMED_DATE' },
  { label: '출고희망일', value: 'RELEASE_SCHEDULED_DATE' },
  { label: '출고완료일', value: 'RELEASE_COMPLETED_DATE' },
];

export const PERIOD_OPTION_LABEL: {
  [k in EPromotionReleasePeriodFilter]: string;
} = PERIOD_OPTIONS.reduce(
  (acc, cur) => ({
    ...acc,
    [cur.value]: cur.label,
  }),
  {} as { [k in EPromotionReleasePeriodFilter]: string }
);

export const RELEASE_STATUS_OPTIONS: {
  label: string;
  value: EPromotionReleaseStatus;
}[] = [
  { label: '출고요청', value: 'RELEASE_REQUEST' },
  { label: '출고예약', value: 'RELEASE_SCHEDULED' },
  { label: '발주확인', value: 'ORDER_CONFIRMED' },
  { label: '출고완료', value: 'RELEASE_COMPLETED' },
  { label: '요청철회', value: 'RELEASE_CANCEL' },
  { label: '출고보류', value: 'RELEASE_HOLD' },
];

export const RELEASE_STATUS_LABEL: { [k in EPromotionReleaseStatus]: string } =
  RELEASE_STATUS_OPTIONS.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.value]: cur.label,
    }),
    {} as { [k in EPromotionReleaseStatus]: string }
  );

export const SAMPLE_STATUS_OPTIONS: {
  label: string;
  value: ESampleStatus;
}[] = [
  { label: '테스트대기', value: 'TEST_PENDING' },
  { label: '테스트중', value: 'TEST_INPROGRESS' },
  { label: '마켓확정', value: 'MARKET_CONFIRMED' },
  { label: '마켓미진행', value: 'MARKET_CANCEL' },
  { label: '미응답', value: 'NO_RESPONSE' },
];

export const SAMPLE_STATUS_LABEL: {
  [k in ESampleStatus | 'MARKET_CANCEL']: string;
} = SAMPLE_STATUS_OPTIONS.reduce(
  (acc, cur) => ({ ...acc, [cur.value]: cur.label }),
  {} as { [k in ESampleStatus | 'MARKET_CANCEL']: string }
);

export const IS_FREE_PROVIDED_SEARCH_FILTER_OPTIONS = [
  { label: '전체', value: '' },
  { label: '업체 무상', value: 'true' },
  { label: '업체 유상', value: 'false' },
];

export const IS_FREE_PROVIDED_OPTIONS: { label: string; value: number }[] = [
  { label: '유상', value: 0 },
  { label: '무상', value: 1 },
];

export const EDITABLE_TABLE_COLUMNS: ColumnsType<EditableTableDataType> = [
  {
    title: <Label required>수령자명</Label>,
    key: 'receiverName',
    width: 100,
  },
  {
    title: <Label required>휴대폰번호</Label>,
    key: 'receiverPhoneNumber',
    width: 160,
  },
  {
    title: '우편번호',
    key: 'zipCode',
    width: 100,
  },
  {
    title: <Label required>배송지주소</Label>,
    key: 'receiverAddress',
    width: 200,
  },
  {
    title: '배송메시지',
    key: 'deliveryMessage',
    width: 200,
  },
];

export const TABLE_NEW_CELL_CLASSNAME = 'animate-editableTableCellChange';
export const TABLE_LAST_ROW_CLASSNAME = 'last-row';

const getStatusSet = (param: EPromotionReleaseStatus[]) =>
  new Set<EPromotionReleaseStatus>(param);

export const UPDATE_PROMOTION_RELEASE_STATUS_TO_FROM = new Map<
  EPromotionReleaseStatus,
  Set<EPromotionReleaseStatus>
>([
  ['RELEASE_REQUEST', getStatusSet([])],
  [
    'RELEASE_SCHEDULED',
    getStatusSet([
      'RELEASE_REQUEST',
      'ORDER_CONFIRMED',
      'RELEASE_CANCEL',
      'RELEASE_HOLD',
      'RELEASE_SCHEDULED',
    ]),
  ],
  [
    'ORDER_CONFIRMED',
    getStatusSet(['RELEASE_REQUEST', 'RELEASE_HOLD', 'RELEASE_SCHEDULED']),
  ],
  [
    'RELEASE_COMPLETED',
    getStatusSet([
      'RELEASE_REQUEST',
      'ORDER_CONFIRMED',
      'RELEASE_COMPLETED',
      'RELEASE_HOLD',
      'RELEASE_SCHEDULED',
    ]),
  ],
  [
    'RELEASE_CANCEL',
    getStatusSet([
      'RELEASE_REQUEST',
      'ORDER_CONFIRMED',
      'RELEASE_COMPLETED',
      'RELEASE_HOLD',
      'RELEASE_SCHEDULED',
    ]),
  ],
  [
    'RELEASE_HOLD',
    getStatusSet([
      'RELEASE_REQUEST',
      'ORDER_CONFIRMED',
      'RELEASE_CANCEL',
      'RELEASE_SCHEDULED',
    ]),
  ],
]);

export const FILTER_BEARER_TYPES: {
  label: string;
  value: EPromotionBearerTypeFilter;
}[] = [
  { label: '셀러부담(정산차감)', value: 'SELLER_PREPAYMENT' },
  { label: '셀러부담(선결제)', value: 'SELLER_POST_PAYMENT' },
  { label: '와이어드 부담', value: 'WIRED' },
];

export const FILTER_BEARER_TYPES_LABEL: {
  [k in EPromotionBearerTypeFilter]: string;
} = FILTER_BEARER_TYPES.reduce(
  (acc, cur) => ({
    ...acc,
    [cur.value]: cur.label,
  }),
  {} as { [k in EPromotionBearerTypeFilter]: string }
);
