import { App, DatePicker, Spin } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';

import { useAdminPatchSettlementSheetMutation } from '../../graphql/settlementSheet.generated';
import useSettlementPermission from '../../hooks/useSettlementPermission';

function TaxInvoiceInssuedAtCell({
  settlementSheetId,
  issuedAt,
}: {
  settlementSheetId: number;
  issuedAt?: string | null;
}) {
  const { message } = App.useApp();
  const hasPermission = useSettlementPermission();
  const [_issuedAt, setIssuedAt] = useState<dayjs.Dayjs | null>(
    typeof issuedAt === 'string' ? dayjs(issuedAt) : null
  );
  const [mutate, { loading }] = useAdminPatchSettlementSheetMutation();

  const handleChange = useCallback(
    async (value: dayjs.Dayjs | null) => {
      await mutate({
        variables: {
          input: { taxInvoiceIssuedAt: value ? value.toISOString() : null },
          adminPatchSettlementSheetId: settlementSheetId,
        },
        onCompleted() {
          setIssuedAt(value);
          void message.success('발행일이 수정되었습니다.');
        },
        onError() {
          void message.error('수정실패하였습니다.');
        },
      });
    },
    [mutate, settlementSheetId, message]
  );

  if (!hasPermission) {
    return <>{issuedAt ? dayjs(issuedAt).format('YYYY.MM.DD') : '-'}</>;
  }
  return (
    <Spin spinning={loading}>
      <DatePicker value={_issuedAt} onChange={handleChange} />
    </Spin>
  );
}

export default TaxInvoiceInssuedAtCell;
