import * as Types from '../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BrandsInSelectFieldQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.AdminListBrandsPageQueryFilter>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type BrandsInSelectFieldQuery = { __typename?: 'Query', adminBrands: { __typename?: 'BrandPageGraphqlType', edges: Array<{ __typename?: 'PaginatedBrandGraphqlTypeEdge', node: { __typename?: 'BrandGraphqlType', name: string, id: number } }> } };


export const BrandsInSelectFieldDocument = gql`
    query BrandsInSelectField($filter: AdminListBrandsPageQueryFilter, $pagination: Pagination) {
  adminBrands(filter: $filter, pagination: $pagination) {
    edges {
      node {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useBrandsInSelectFieldQuery__
 *
 * To run a query within a React component, call `useBrandsInSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsInSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsInSelectFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBrandsInSelectFieldQuery(baseOptions?: Apollo.QueryHookOptions<BrandsInSelectFieldQuery, BrandsInSelectFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandsInSelectFieldQuery, BrandsInSelectFieldQueryVariables>(BrandsInSelectFieldDocument, options);
      }
export function useBrandsInSelectFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsInSelectFieldQuery, BrandsInSelectFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandsInSelectFieldQuery, BrandsInSelectFieldQueryVariables>(BrandsInSelectFieldDocument, options);
        }
export type BrandsInSelectFieldQueryHookResult = ReturnType<typeof useBrandsInSelectFieldQuery>;
export type BrandsInSelectFieldLazyQueryHookResult = ReturnType<typeof useBrandsInSelectFieldLazyQuery>;
export type BrandsInSelectFieldQueryResult = Apollo.QueryResult<BrandsInSelectFieldQuery, BrandsInSelectFieldQueryVariables>;