import { Descriptions, Divider, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { AdminOrderGroupQuery } from '../graphql/createClaim.generated';
import { toSalesChannelLabel } from '../helper/create';

function ClaimOrderSummary({
  order,
  orderGroup,
  title,
  showOrder,
}: {
  order?: AdminOrderGroupQuery['adminOrderGroup']['orders']['0'];
  orderGroup: AdminOrderGroupQuery['adminOrderGroup'];
  title?: string;
  showOrder?: boolean;
}) {
  const optionNames = showOrder
    ? order?.items
        ?.map((item) => {
          return item.name;
        })
        .join(', ') || '-'
    : null;

  const productName = showOrder ? order?.productName || '-' : null;

  return (
    <div>
      <Divider orientation={'left'} className={'mb-4 mt-0'}>
        {title || '주문정보'}
      </Divider>
      <Descriptions
        bordered
        column={2}
        labelStyle={{ width: 200 }}
        className={'[&_table]:table-fixed'}
      >
        <Descriptions.Item label={'주문자명'}>
          {orderGroup?.ordererName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={'주문자 휴대폰번호'}>
          {orderGroup?.ordererPhoneNumber || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={'판매채널'}>
          {order?.salesChannelType && order?.salesChannel
            ? toSalesChannelLabel(order.salesChannelType, order.salesChannel)
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label={'마켓명'}>
          <Tooltip title={orderGroup?.marketName || '-'}>
            <span className={'line-clamp-1'}>
              {orderGroup?.marketName || '-'}
            </span>
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label={'판매채널 주문번호'}>
          {orderGroup?.salesChannelOrderGroupCode || '-'}
        </Descriptions.Item>
        <Descriptions.Item label={'판매채널 상품주문번호'}>
          {order?.salesChannelOrderCode || '-'}
        </Descriptions.Item>
        {showOrder && (
          <>
            <Descriptions.Item label={'품목명'}>
              <Tooltip title={productName}>
                <span className={'line-clamp-2'}>{productName}</span>
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label={'옵션명'}>
              <Tooltip title={optionNames}>
                <span className={'line-clamp-2'}>{optionNames}</span>
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label={'주문일시'}>
              {dayjs(orderGroup?.orderedAt).format('YYYY-MM-DD HH:mm:ss') ||
                '-'}
            </Descriptions.Item>
            <Descriptions.Item label={'주문수량'}>
              {order?.productQuantity.toLocaleString() || '-'}
            </Descriptions.Item>
          </>
        )}
        {!showOrder && (
          <Descriptions.Item span={2} label={'주문일시'}>
            {dayjs(orderGroup?.orderedAt).format('YYYY-MM-DD HH:mm:ss') || '-'}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
}

export default ClaimOrderSummary;
