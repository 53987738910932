import { Tag } from 'antd';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import useAntdTheme from '../../../../common/hooks/useAntdTheme';

type AdditionalProductFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  disabled?: boolean;
};

export default function AdditionalProductField<
  TFieldValues extends FieldValues,
>({ control, name, disabled }: AdditionalProductFieldProps<TFieldValues>) {
  const { colorBgContainerDisabled, colorTextDisabled } = useAntdTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <Tag
            color={field.value ? 'blue' : undefined}
            className={'cursor-pointer'}
            onClick={() => field.onChange(!field.value)}
            style={{
              backgroundColor: disabled ? colorBgContainerDisabled : undefined,
              color: disabled ? colorTextDisabled : undefined,
            }}
          >
            추가상품
          </Tag>
        );
      }}
    />
  );
}
