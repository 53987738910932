import { WarningOutlined } from '@ant-design/icons';
import { Tabs, Typography } from 'antd';
import { get } from 'lodash-es';
import { useFormContext, useFormState } from 'react-hook-form';

import { ALLOW_UPLOAD_IMAGE_MIME_TYPES } from '../../../../common/utils/const';
import { ProductGroupMultimediaType } from '../../types';
import ArchiveUploadListField from '../Fields/ArchiveUploadListField';

type ProductArchiveProps = {
  editable?: boolean;
  hideErrorMessage?: boolean;
};

const ProductArchive = ({
  editable,
  hideErrorMessage,
}: ProductArchiveProps) => {
  const { control } = useFormContext<{
    multimedia: ProductGroupMultimediaType;
  }>();

  const marketingAccept = `${ALLOW_UPLOAD_IMAGE_MIME_TYPES.join(',')},.pdf`;

  const items: {
    title: string;
    key: 'thumbnail' | 'detail' | 'marketing';
    accept?: string;
    required?: boolean;
    description?: string;
  }[] = [
    {
      title: '썸네일',
      key: 'thumbnail',
      required: editable,
      description:
        '케미 결제링크 혹은 셀러에게 전송되는 상품 제안서에 사용될 수 있습니다.',
    },
    {
      title: '상세페이지',
      key: 'detail',
      required: editable,
      description: '케미 결제링크에 사용될 수 있습니다.',
    },
    {
      title: '마케팅자료',
      key: 'marketing',
      accept: marketingAccept,
    },
  ];

  const { errors } = useFormState({ name: 'multimedia' });

  return (
    <Tabs
      className='mt-10'
      defaultActiveKey='1'
      type='card'
      items={items.map(({ key, title, ...props }) => {
        const label = get(errors, `multimedia.${key}`) ? (
          <Typography.Text type='danger'>
            {title} <WarningOutlined className='mr-0' />
          </Typography.Text>
        ) : (
          title
        );

        return {
          label,
          key: key,
          children: (
            <ArchiveUploadListField
              control={control}
              name={`multimedia.${key}`}
              title={title}
              editable={editable}
              hideErrorMessage={hideErrorMessage}
              {...props}
            />
          ),
        };
      })}
    />
  );
};

export default ProductArchive;
