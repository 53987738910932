import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminUpdateSettlementMutationVariables = Types.Exact<{
  adminUpdateSettlementId: Types.Scalars['Int']['input'];
  updateSettlementData: Types.UpdateSettlementInputGraphqlType;
}>;


export type AdminUpdateSettlementMutation = { __typename?: 'Mutation', adminUpdateSettlement: { __typename?: 'SettlementOutputGraphqlType', settlement: { __typename?: 'SettlementGraphqlType', id: number } } };

export type AdminCreateSettlementSheetMutationVariables = Types.Exact<{
  input: Types.SettlementSheetInputGraphqlType;
}>;


export type AdminCreateSettlementSheetMutation = { __typename?: 'Mutation', adminCreateSettlementSheet?: { __typename?: 'SettlementSheetOutputGraphqlType', settlementSheet: { __typename?: 'SettlementSheetGraphqlType', id: number } } | null };

export type AdminAddSettlementsIntoSheetMutationVariables = Types.Exact<{
  input: Types.AddSettlementsIntoSheetInputGraphqlType;
}>;


export type AdminAddSettlementsIntoSheetMutation = { __typename?: 'Mutation', adminAddSettlementsIntoSheet: { __typename?: 'SettlementSheetOutputGraphqlType', settlementSheet: { __typename?: 'SettlementSheetGraphqlType', id: number } } };

export type AdminDeleteSettlementsFromSheetMutationVariables = Types.Exact<{
  input: Types.DeleteSettlementsFromSheetInputGraphqlType;
}>;


export type AdminDeleteSettlementsFromSheetMutation = { __typename?: 'Mutation', adminDeleteSettlementsFromSheet: { __typename?: 'DeleteSettlementFromSheetOutputGraphqlType', result: boolean } };

export type AdminSettlementQueryVariables = Types.Exact<{
  adminSettlementId: Types.Scalars['Int']['input'];
}>;


export type AdminSettlementQuery = { __typename?: 'Query', adminSettlement?: { __typename?: 'SettlementGraphqlType', marketId?: number | null, vendorId?: number | null } | null };


export const AdminUpdateSettlementDocument = gql`
    mutation AdminUpdateSettlement($adminUpdateSettlementId: Int!, $updateSettlementData: UpdateSettlementInputGraphqlType!) {
  adminUpdateSettlement(
    id: $adminUpdateSettlementId
    updateSettlementData: $updateSettlementData
  ) {
    settlement {
      id
    }
  }
}
    `;
export type AdminUpdateSettlementMutationFn = Apollo.MutationFunction<AdminUpdateSettlementMutation, AdminUpdateSettlementMutationVariables>;

/**
 * __useAdminUpdateSettlementMutation__
 *
 * To run a mutation, you first call `useAdminUpdateSettlementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateSettlementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateSettlementMutation, { data, loading, error }] = useAdminUpdateSettlementMutation({
 *   variables: {
 *      adminUpdateSettlementId: // value for 'adminUpdateSettlementId'
 *      updateSettlementData: // value for 'updateSettlementData'
 *   },
 * });
 */
export function useAdminUpdateSettlementMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdateSettlementMutation, AdminUpdateSettlementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdateSettlementMutation, AdminUpdateSettlementMutationVariables>(AdminUpdateSettlementDocument, options);
      }
export type AdminUpdateSettlementMutationHookResult = ReturnType<typeof useAdminUpdateSettlementMutation>;
export type AdminUpdateSettlementMutationResult = Apollo.MutationResult<AdminUpdateSettlementMutation>;
export type AdminUpdateSettlementMutationOptions = Apollo.BaseMutationOptions<AdminUpdateSettlementMutation, AdminUpdateSettlementMutationVariables>;
export const AdminCreateSettlementSheetDocument = gql`
    mutation AdminCreateSettlementSheet($input: SettlementSheetInputGraphqlType!) {
  adminCreateSettlementSheet(input: $input) {
    settlementSheet {
      id
    }
  }
}
    `;
export type AdminCreateSettlementSheetMutationFn = Apollo.MutationFunction<AdminCreateSettlementSheetMutation, AdminCreateSettlementSheetMutationVariables>;

/**
 * __useAdminCreateSettlementSheetMutation__
 *
 * To run a mutation, you first call `useAdminCreateSettlementSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateSettlementSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateSettlementSheetMutation, { data, loading, error }] = useAdminCreateSettlementSheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreateSettlementSheetMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateSettlementSheetMutation, AdminCreateSettlementSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateSettlementSheetMutation, AdminCreateSettlementSheetMutationVariables>(AdminCreateSettlementSheetDocument, options);
      }
export type AdminCreateSettlementSheetMutationHookResult = ReturnType<typeof useAdminCreateSettlementSheetMutation>;
export type AdminCreateSettlementSheetMutationResult = Apollo.MutationResult<AdminCreateSettlementSheetMutation>;
export type AdminCreateSettlementSheetMutationOptions = Apollo.BaseMutationOptions<AdminCreateSettlementSheetMutation, AdminCreateSettlementSheetMutationVariables>;
export const AdminAddSettlementsIntoSheetDocument = gql`
    mutation AdminAddSettlementsIntoSheet($input: AddSettlementsIntoSheetInputGraphqlType!) {
  adminAddSettlementsIntoSheet(input: $input) {
    settlementSheet {
      id
    }
  }
}
    `;
export type AdminAddSettlementsIntoSheetMutationFn = Apollo.MutationFunction<AdminAddSettlementsIntoSheetMutation, AdminAddSettlementsIntoSheetMutationVariables>;

/**
 * __useAdminAddSettlementsIntoSheetMutation__
 *
 * To run a mutation, you first call `useAdminAddSettlementsIntoSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminAddSettlementsIntoSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminAddSettlementsIntoSheetMutation, { data, loading, error }] = useAdminAddSettlementsIntoSheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminAddSettlementsIntoSheetMutation(baseOptions?: Apollo.MutationHookOptions<AdminAddSettlementsIntoSheetMutation, AdminAddSettlementsIntoSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminAddSettlementsIntoSheetMutation, AdminAddSettlementsIntoSheetMutationVariables>(AdminAddSettlementsIntoSheetDocument, options);
      }
export type AdminAddSettlementsIntoSheetMutationHookResult = ReturnType<typeof useAdminAddSettlementsIntoSheetMutation>;
export type AdminAddSettlementsIntoSheetMutationResult = Apollo.MutationResult<AdminAddSettlementsIntoSheetMutation>;
export type AdminAddSettlementsIntoSheetMutationOptions = Apollo.BaseMutationOptions<AdminAddSettlementsIntoSheetMutation, AdminAddSettlementsIntoSheetMutationVariables>;
export const AdminDeleteSettlementsFromSheetDocument = gql`
    mutation AdminDeleteSettlementsFromSheet($input: DeleteSettlementsFromSheetInputGraphqlType!) {
  adminDeleteSettlementsFromSheet(input: $input) {
    result
  }
}
    `;
export type AdminDeleteSettlementsFromSheetMutationFn = Apollo.MutationFunction<AdminDeleteSettlementsFromSheetMutation, AdminDeleteSettlementsFromSheetMutationVariables>;

/**
 * __useAdminDeleteSettlementsFromSheetMutation__
 *
 * To run a mutation, you first call `useAdminDeleteSettlementsFromSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDeleteSettlementsFromSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDeleteSettlementsFromSheetMutation, { data, loading, error }] = useAdminDeleteSettlementsFromSheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminDeleteSettlementsFromSheetMutation(baseOptions?: Apollo.MutationHookOptions<AdminDeleteSettlementsFromSheetMutation, AdminDeleteSettlementsFromSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminDeleteSettlementsFromSheetMutation, AdminDeleteSettlementsFromSheetMutationVariables>(AdminDeleteSettlementsFromSheetDocument, options);
      }
export type AdminDeleteSettlementsFromSheetMutationHookResult = ReturnType<typeof useAdminDeleteSettlementsFromSheetMutation>;
export type AdminDeleteSettlementsFromSheetMutationResult = Apollo.MutationResult<AdminDeleteSettlementsFromSheetMutation>;
export type AdminDeleteSettlementsFromSheetMutationOptions = Apollo.BaseMutationOptions<AdminDeleteSettlementsFromSheetMutation, AdminDeleteSettlementsFromSheetMutationVariables>;
export const AdminSettlementDocument = gql`
    query AdminSettlement($adminSettlementId: Int!) {
  adminSettlement(id: $adminSettlementId) {
    marketId
    vendorId
  }
}
    `;

/**
 * __useAdminSettlementQuery__
 *
 * To run a query within a React component, call `useAdminSettlementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSettlementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSettlementQuery({
 *   variables: {
 *      adminSettlementId: // value for 'adminSettlementId'
 *   },
 * });
 */
export function useAdminSettlementQuery(baseOptions: Apollo.QueryHookOptions<AdminSettlementQuery, AdminSettlementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSettlementQuery, AdminSettlementQueryVariables>(AdminSettlementDocument, options);
      }
export function useAdminSettlementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSettlementQuery, AdminSettlementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSettlementQuery, AdminSettlementQueryVariables>(AdminSettlementDocument, options);
        }
export type AdminSettlementQueryHookResult = ReturnType<typeof useAdminSettlementQuery>;
export type AdminSettlementLazyQueryHookResult = ReturnType<typeof useAdminSettlementLazyQuery>;
export type AdminSettlementQueryResult = Apollo.QueryResult<AdminSettlementQuery, AdminSettlementQueryVariables>;