import { isEmpty } from 'lodash-es';
import { FunctionComponent, useEffect } from 'react';

import useRouteSearchParams from '../hooks/useRouteSearchParams';

function WithDefaultSearchParam<T extends object>(
  C: FunctionComponent,
  defaultParams: T
) {
  const Component = () => {
    const { searchParams, setSearchParams } = useRouteSearchParams<T>();
    const hasSearchParam = !isEmpty(searchParams);

    useEffect(() => {
      if (!hasSearchParam) {
        setSearchParams(defaultParams);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSearchParam]);

    if (!hasSearchParam) {
      return null;
    }

    return <C />;
  };

  return Component;
}

export default WithDefaultSearchParam;
