import { ExclamationCircleFilled } from '@ant-design/icons';
import { App, Button, Divider, Tabs } from 'antd';
import { isEmpty } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';

import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import Modal from '../../../common/components/Modal';
import { useFormError } from '../../../common/hooks/useFormError';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { CreateMarketInputGraphqlType } from '../../../schema/types';
import { toCreateMarketVariables } from '../helper';
import { CreateMarketFormValues, MarketType } from '../types';
import { createMarketFormResolver } from '../validator';

import MarketEventsField from './MarketEventsField';
import MarketInfoFields from './MarketInfoFields';
import MarketProductsField from './MarketProductsField';
import SellerInfoSelectField from './SellerInfoSelectField';

type CreateMarketFormProps = {
  close: () => void;
  managerId: number;
  submit: (value: CreateMarketInputGraphqlType) => Promise<void>;
};
function CreateMarketForm({ close, managerId, submit }: CreateMarketFormProps) {
  const { hasPermission } = usePermissions('MARKET_EDIT');
  const { modal } = App.useApp();
  const methods = useForm<CreateMarketFormValues>({
    defaultValues: {
      managerId,
      isSellerAssigned: true,
      productGroupIds: [],
      isRegionFee: true,
    },
    mode: 'onChange',
    resolver: createMarketFormResolver,
  });
  const { onInvalid } = useFormError({
    afterInValid(errors) {
      /* 발주팀 자체표기 필요 상태이면 1개 이상의 품목이 있어야 한다. validation 후 필드 focus를 위한 사후처리*/
      const eventIndexWithProductsError = Array.isArray(errors.events)
        ? errors.events.findIndex((event) => !!event?.products?.message)
        : undefined;

      if (typeof eventIndexWithProductsError === 'number') {
        methods.setFocus(
          `events.${eventIndexWithProductsError}.isNotationRequired`
        );
      }
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { dirtyFields, isSubmitting },
  } = methods;

  const isSellerAssigned = watch('isSellerAssigned');
  const type: MarketType = isSellerAssigned ? 'ASSIGNED' : 'UNASSIGNED';
  const onSubmit = async (data: CreateMarketFormValues) => {
    const createMarketInput = toCreateMarketVariables(data);
    await submit(createMarketInput);
  };

  const handleCancel = () => {
    if (isDirty) {
      modal.confirm({
        icon: <ExclamationCircleFilled />,
        title: '작성 취소',
        content: (
          <>
            <p>변경사항이 저장되지 않습니다.</p>
            <p>작성을 취소하시겠습니까?</p>
          </>
        ),
        onOk: close,
        okText: '네',
        cancelText: '아니오',
        centered: true,
      });

      return;
    }
    close();
  };

  const isDirty = !isEmpty(dirtyFields);

  return (
    <Modal
      maskClosable={false}
      onCancel={handleCancel}
      title={'마켓등록'}
      footer={null}
      width={816}
      style={{
        overflow: 'hidden',
        borderRadius: 8,
        paddingBottom: 0,
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
          <div
            className={
              'h-[calc(100vh-320px)] overflow-x-hidden overflow-y-scroll [&_.ant-divider-inner-text]:font-semibold'
            }
          >
            <Tabs
              accessKey={type}
              items={items}
              onChange={(key: string) => {
                setValue('isSellerAssigned', key === 'ASSIGNED', {
                  shouldTouch: true,
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
            <div className={'mb-8 grid w-full gap-xl'}>
              <ManagerSelectField
                required
                disabled={!hasPermission}
                label={'담당자'}
                name={'managerId'}
                control={control}
                className={'w-marketCreateInputWidth'}
              />
              {isSellerAssigned && <SellerInfoSelectField />}

              <MarketProductsField />

              <Divider orientation='left' className={'m-0'}>
                {'마켓정보'}
              </Divider>
              <MarketInfoFields />

              {isSellerAssigned && <MarketEventsField />}
            </div>
          </div>
          <div className={'flex justify-end gap-md pt-lg'}>
            <Button htmlType={'button'} onClick={handleCancel}>
              취소
            </Button>
            <Button
              htmlType={'submit'}
              type={'primary'}
              loading={isSubmitting}
              disabled={!isDirty}
            >
              등록
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

const items: { key: MarketType; label: string }[] = [
  {
    key: 'ASSIGNED',
    label: '지정셀러',
  },
  {
    key: 'UNASSIGNED',
    label: '미지정셀러',
  },
];

export default CreateMarketForm;
