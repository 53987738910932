import { ConfigProvider, App as AntdApp } from 'antd';
import koKR from 'antd/locale/ko_KR';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { StrictMode, useEffect, useRef, useState } from 'react';
import { RouterProvider } from 'react-router-dom';

import ApolloProvider from './common/apollo';
import PageLoader from './common/components/PageLoader';
import { authorization } from './domain/auth/helper';
import { routerConfig } from './routes/routerConfig';
import theme from './theme';

import 'dayjs/locale/ko';

dayjs.locale('ko');
dayjs.extend(isBetween);

function App() {
  const [tokenLoading, setTokenLoading] = useState(true);
  const tokenLoadOnce = useRef(false);
  useEffect(() => {
    if (!authorization.accessToken && !tokenLoadOnce.current) {
      tokenLoadOnce.current = true;
      void authorization.reissue().then(() => setTokenLoading(false));
    }
  }, []);
  if (tokenLoading) return <PageLoader />;
  return (
    <StrictMode>
      <ConfigProvider theme={theme} locale={koKR}>
        <AntdApp>
          <ApolloProvider>
            <RouterProvider router={routerConfig} />
          </ApolloProvider>
        </AntdApp>
      </ConfigProvider>
    </StrictMode>
  );
}

export default App;
