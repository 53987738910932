import { Button, Pagination, Table, Tooltip } from 'antd';
import { PropsWithChildren } from 'react';
import { Link, generatePath } from 'react-router-dom';

import EmptyTable from '../../../common/components/EmptyTable';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { ROUTES } from '../../../routes/const';
import { APP_HEADER_HEIGHT } from '../../../theme/const';
import {
  DELIVERY_FEE_BEARER_LABEL,
  PROMTION_TYPES_LABEL,
  RELEASE_STATUS_LABEL,
} from '../const';
import { numberWithUnknownString } from '../helper';
import usePromotionPermission from '../hooks/usePromotionPermission';
import usePromotionReleaseTable, {
  sharedOnCell,
} from '../hooks/usePromotionReleaseTable';
import {
  PromotionReleaseListSearchParams,
  PromotionReleaseTableDataType,
} from '../types';

import PromotionReleaseTableDeliveryCell from './PromotionReleaseTableDeliveryCell';
import PromotionReleaseTableSampleStatusCell from './PromotionReleaseTableSampleStatusCell';

function PromotionReleaseTable({ children }: PropsWithChildren) {
  const hasPermission = usePromotionPermission();
  const { searchParams, setSearchParams } =
    useRouteSearchParams<PromotionReleaseListSearchParams>();
  const promotionType = searchParams.promotionType || 'ALL';
  const {
    dataSourceState,
    rowSelection,
    loading,
    handleDeliveryCompany,
    handleDeliveryTrackingNumber,
    handleDeliveryCellSpan,
    rowSpanReleaseIds,
    handleBulkPatchPromotionReleases,
    total,
    selectedRowKeys,
  } = usePromotionReleaseTable();
  const hasSelectedItem = !!selectedRowKeys.length;
  const currentPage = searchParams.currentPage
    ? Number(searchParams.currentPage)
    : 1;
  const pageSize = searchParams.pageSize ? Number(searchParams.pageSize) : 100;
  const handlePageChange = (page: number, size: number) => {
    if (size !== pageSize) {
      setSearchParams({ currentPage: 1, pageSize: size });
    } else {
      setSearchParams({ currentPage: page });
    }
  };

  if (!loading && dataSourceState.length === 0) return <EmptyTable />;
  return (
    <>
      <div className='flex justify-between'>
        <div>
          {hasPermission && hasSelectedItem && (
            <Button.Group>
              <Button
                onClick={() =>
                  handleBulkPatchPromotionReleases('RELEASE_COMPLETED')
                }
              >
                출고처리
              </Button>
              <Button
                onClick={() =>
                  handleBulkPatchPromotionReleases('ORDER_CONFIRMED')
                }
              >
                발주확인
              </Button>
              <Button
                onClick={() => handleBulkPatchPromotionReleases('RELEASE_HOLD')}
              >
                출고보류
              </Button>
              <Button
                onClick={() =>
                  handleBulkPatchPromotionReleases('RELEASE_CANCEL')
                }
              >
                요청철회
              </Button>
            </Button.Group>
          )}
        </div>
        {children}
      </div>
      <Table<PromotionReleaseTableDataType>
        sticky={{
          offsetHeader: APP_HEADER_HEIGHT,
        }}
        size='small'
        loading={loading}
        tableLayout='fixed'
        dataSource={dataSourceState}
        scroll={{ x: 3968 }}
        rowSelection={hasPermission ? rowSelection : undefined}
        pagination={false}
      >
        <Table.Column<PromotionReleaseTableDataType>
          title='출고코드'
          onCell={sharedOnCell}
          width={160}
          shouldCellUpdate={() => false}
          render={(_, record) => (
            <Link
              to={generatePath(ROUTES.PROMOTION_RELEASE_DETAIL.path, {
                releaseId: record.releaseId,
              })}
            >
              {record.releaseCode}
            </Link>
          )}
        />

        {promotionType === 'ALL' && (
          <Table.Column<PromotionReleaseTableDataType>
            title='구분'
            width={120}
            dataIndex='promotionType'
            onCell={sharedOnCell}
            shouldCellUpdate={() => false}
            render={(_, record) => PROMTION_TYPES_LABEL[record.promotionType]}
          />
        )}
        {/* 샘플탭일 경우 보인다 */}
        {promotionType === 'SAMPLE' && (
          <Table.Column<PromotionReleaseTableDataType>
            title='샘플상태'
            width={160}
            dataIndex='sampleStatus'
            onCell={sharedOnCell}
            shouldCellUpdate={(record, prevRecord) =>
              record.sampleStatus !== prevRecord.sampleStatus
            }
            render={(_, record) => (
              <PromotionReleaseTableSampleStatusCell record={record} />
            )}
          />
        )}
        {/* 교환재출고탭일 경우 보인다 */}
        {promotionType === 'EXCHANGE_RE_DELIVERY' && (
          <Table.Column<PromotionReleaseTableDataType>
            title='와이어드품목주문코드'
            width={160}
            dataIndex='orderCode'
            onCell={sharedOnCell}
            shouldCellUpdate={(record, prevRecord) =>
              record.orderCode !== prevRecord.orderCode
            }
          />
        )}
        <Table.Column<PromotionReleaseTableDataType>
          title='출고상태'
          width={160}
          dataIndex='releaseStatus'
          onCell={sharedOnCell}
          shouldCellUpdate={(record, prevRecord) =>
            record.releaseStatus !== prevRecord.releaseStatus
          }
          render={(_, record) => RELEASE_STATUS_LABEL[record.releaseStatus]}
        />
        {/* 샘플탭일 경우 보인다 */}
        {promotionType === 'SAMPLE' && (
          <Table.Column<PromotionReleaseTableDataType>
            title='셀러명'
            width={240}
            dataIndex='sellerName'
            onCell={sharedOnCell}
            shouldCellUpdate={() => false}
          />
        )}
        {/* 이벤트탭일 경우 보인다 */}
        {promotionType === 'EVENT' && (
          <Table.Column<PromotionReleaseTableDataType>
            title='마켓명'
            width={240}
            dataIndex='marketName'
            onCell={sharedOnCell}
            shouldCellUpdate={() => false}
          />
        )}
        <Table.Column<PromotionReleaseTableDataType>
          title='업체명'
          width={240}
          dataIndex='vendorName'
          onCell={sharedOnCell}
          shouldCellUpdate={() => false}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='상품명'
          width={240}
          dataIndex='promotionProductGroupName'
          onCell={sharedOnCell}
          shouldCellUpdate={() => false}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='품목명'
          width={240}
          shouldCellUpdate={() => false}
          render={(_, { product }) => {
            if (!product.originalProduct) {
              return product.customPromotionProductName;
            } else {
              return `${product.originalProduct.name} ${
                product.promotionItems.length
                  ? `(${product.promotionItems
                      .map((item) => item.originalItem?.name)
                      .join(', ')})`
                  : ''
              }`;
            }
          }}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='수량'
          width={160}
          shouldCellUpdate={() => false}
          render={(_, record) => record.product.setCount.toLocaleString()}
        />
        {/* 교환재출고탭이 아닐 경우에만 노출 */}
        {promotionType !== 'EXCHANGE_RE_DELIVERY' && (
          <>
            <Table.Column<PromotionReleaseTableDataType>
              title='업체 무/유상'
              width={160}
              shouldCellUpdate={() => false}
              render={(_, record) =>
                record.product.isFreeProvided ? '업체무상' : '업체유상'
              }
            />
            <Table.Column<PromotionReleaseTableDataType>
              title='부담주체/금액'
              width={240}
              className='whitespace-pre-line'
              shouldCellUpdate={() => false}
              render={(_, { product }) => {
                const bearing: string[] = [];
                if (product.isSellerBearing) {
                  bearing.push(
                    `셀러 / ${numberWithUnknownString(
                      product.sellerBearingCost
                    )} (${product.isPrepayment ? '선결제' : '정산차감'})`
                  );
                }
                if (product.isWiredBearing) {
                  bearing.push(
                    `와이어드 / ${numberWithUnknownString(
                      product.wiredBearingCost
                    )}`
                  );
                }
                return bearing.join('\n');
              }}
            />
          </>
        )}
        <Table.Column<PromotionReleaseTableDataType>
          title='택배사/송장번호'
          width={240}
          onCell={(record) =>
            rowSpanReleaseIds.includes(record.releaseId)
              ? sharedOnCell(record)
              : {}
          }
          shouldCellUpdate={(record, prevRecord) =>
            record.deliveryCompanyId !== prevRecord.deliveryCompanyId ||
            record.trackingNumber !== prevRecord.trackingNumber
          }
          render={(_, record) => (
            <PromotionReleaseTableDeliveryCell
              record={record}
              onDeliveryCompanyChange={handleDeliveryCompany}
              onTrackingNumberChange={handleDeliveryTrackingNumber}
              onCellSpanChange={handleDeliveryCellSpan}
              isMergedSpan={rowSpanReleaseIds.includes(record.releaseId)}
            />
          )}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='수령자정보'
          width={240}
          onCell={sharedOnCell}
          shouldCellUpdate={(record, prevRecord) =>
            record.receiverName !== prevRecord.receiverName ||
            record.receiverAddress !== prevRecord.receiverAddress ||
            record.receiverDetailAddress !== prevRecord.receiverDetailAddress ||
            record.zipcode !== prevRecord.zipcode ||
            record.deliveryMessage !== prevRecord.deliveryMessage
          }
          render={(_, record) => (
            <div>
              <span className='font-bold'>
                {record.receiverName} / {record.receiverPhoneNumber}
              </span>
              <br />
              <span className='text-gray-400'>
                {record.receiverAddress}
                {record.receiverDetailAddress} (우){record.zipcode}
              </span>
              <br />
              <Tooltip title={record.deliveryMessage}>
                <span className='line-clamp-1'>{record.deliveryMessage}</span>
              </Tooltip>
            </div>
          )}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='배송비 별도부과'
          width={160}
          onCell={sharedOnCell}
          shouldCellUpdate={() => false}
          render={(_, record) => {
            if (!record.isDeliveryFee) return '-';
            return `${
              record.deliveryFeeBearer
                ? DELIVERY_FEE_BEARER_LABEL[record.deliveryFeeBearer]
                : ''
            } / ${
              typeof record.deliveryFee === 'number'
                ? record.deliveryFee.toLocaleString()
                : '-'
            } `;
          }}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='요청자'
          width={120}
          onCell={sharedOnCell}
          dataIndex='managerName'
          shouldCellUpdate={() => false}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='요청일시'
          width={240}
          onCell={sharedOnCell}
          dataIndex='createdAt'
          shouldCellUpdate={() => false}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='출고희망일'
          width={160}
          onCell={sharedOnCell}
          dataIndex='scheduledReleaseAt'
          shouldCellUpdate={() => false}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='발주확인일'
          width={160}
          onCell={sharedOnCell}
          dataIndex='confirmedOrderAt'
          shouldCellUpdate={() => false}
        />
        <Table.Column<PromotionReleaseTableDataType>
          title='출고완료일'
          width={160}
          onCell={sharedOnCell}
          dataIndex='completedReleaseAt'
          shouldCellUpdate={() => false}
        />
        {/* 샘플탭이 아닐경우에만 보인다 */}
        {promotionType !== 'SAMPLE' && (
          <Table.Column<PromotionReleaseTableDataType>
            title='연관 셀러'
            width={240}
            onCell={sharedOnCell}
            dataIndex='sellerName'
            shouldCellUpdate={() => false}
          />
        )}
        {/* 이벤트탭이 아닐경우에만 보인다 */}
        {promotionType !== 'EVENT' && (
          <Table.Column<PromotionReleaseTableDataType>
            title='연관 마켓'
            width={240}
            onCell={sharedOnCell}
            dataIndex='marketName'
            shouldCellUpdate={() => false}
          />
        )}
        <Table.Column<PromotionReleaseTableDataType>
          title='비고'
          width={240}
          onCell={sharedOnCell}
          dataIndex='note'
          shouldCellUpdate={() => false}
        />
      </Table>
      <div className='mt-4 flex items-center justify-end gap-4'>
        <div>총 {total}건</div>
        <Pagination
          showSizeChanger
          onChange={handlePageChange}
          current={currentPage}
          pageSize={pageSize}
          total={total}
        />
      </div>
    </>
  );
}

export default PromotionReleaseTable;
