import { DeleteOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useFormContext } from 'react-hook-form';

import CheckboxField from '../../../../common/components/Fields/CheckboxField';
import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import Label from '../../../../common/components/Fields/Label';
import NumberField from '../../../../common/components/Fields/NumberField';
import SelectField from '../../../../common/components/Fields/SelectField';
import SwitchField from '../../../../common/components/Fields/SwitchField';
import TextareaField from '../../../../common/components/Fields/TextareaField';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { EVENT_TYPES, SHIPPING_BEARER } from '../../const';
import { hasParticipantsEventType } from '../../helper';
import {
  CreateMarketEventFormValues,
  CreateMarketFormValues,
  UpdateMarketEventFormValues,
  UpdateMarketFormValues,
} from '../../types';
import DividerWithButton from '../DividerWithButton';
import NumberWithUnknownField from '../NumberWithUnknownField';

import EventProducts from './EventProducts';

type EventItemFieldProps = {
  onRemove: () => void;
  index: number;
  event?:
    | (CreateMarketEventFormValues & { id?: number })
    | UpdateMarketEventFormValues;
};

function EventItemField({ index, onRemove, event }: EventItemFieldProps) {
  const { hasPermission } = usePermissions('MARKET_EDIT');

  const { control, setValue, watch } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();

  const type = event?.type;
  const isExtraDeliveryCharged = event?.isExtraDeliveryCharged;
  const defaultDeliveryFee = watch('deliveryCost');
  const eventEditable = hasPermission && event?.editable;

  return (
    <li className={'mb-4 grid gap-4'} key={index}>
      <DividerWithButton
        buttonLabel={'이벤트 삭제'}
        buttonProps={{
          type: 'text',
          danger: true,
          icon: <DeleteOutlined />,
          onClick: onRemove,
          disabled: !hasPermission || !event?.deletable,
        }}
        tagProps={
          event?.id
            ? {
                children: `이벤트ID:${event.id || ''}`,
                className: 'hover:cursor-pointer',
                text: event.id.toString(),
              }
            : undefined
        }
        title={`이벤트 정보${index ? index + 1 : ''}`}
      />
      <div className={'flex w-full gap-md @lg:w-updateFormWidth'}>
        <SelectField
          disabled={!eventEditable}
          label={'유형'}
          control={control}
          name={`events.${index}.type`}
          options={EVENT_TYPES}
          placeholder={'유형선택'}
        />
        {type && hasParticipantsEventType(type) ? (
          <NumberField
            required={eventEditable}
            disabled={!eventEditable}
            label={'인원'}
            control={control}
            name={`events.${index}.count`}
            placeholder={'숫자입력'}
            suffix={'명'}
          />
        ) : (
          <div className={'w-full'} />
        )}
      </div>

      <TextareaField
        disabled={!eventEditable}
        autoSize={{ minRows: 2 }}
        label={'이벤트 내용'}
        control={control}
        name={`events.${index}.comment`}
        placeholder={'내용 입력 (최대 150자)'}
        maxLength={150}
        className={'w-full @lg:w-updateFormWidth'}
      />

      <CheckboxField
        disabled={!eventEditable}
        control={control}
        name={`events.${index}.isNotationRequired`}
      >
        <Label
          tooltip={
            '이벤트 건을 업체에 발주할 때, 발주팀이 직접 이벤트 당첨자들을 선정해야하는 경우 체크해주세요.'
          }
        >
          발주팀 자체 표기 필요
        </Label>
      </CheckboxField>

      <Typography.Text type={'secondary'}>
        *한 명의 이벤트 당첨자에게 발송되는 품목리스트입니다. 해당 이벤트와 다른
        이벤트조건이 있을 경우 이벤트를 새로 추가해주세요.
      </Typography.Text>

      <EventProducts index={index} />

      <SwitchField
        disabled={!hasPermission}
        control={control}
        name={`events.${index}.isExtraDeliveryCharged`}
        onChange={(v) => {
          if (v) {
            setValue(`events.${index}.shippingCost`, defaultDeliveryFee, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
          }
        }}
        label={'배송비 별도부과'}
      />

      {isExtraDeliveryCharged && (
        <FieldWithLabel required={hasPermission} label={'배송비 부담주체'}>
          <div className={'flex w-full gap-md @lg:w-updateFormWidth'}>
            <SelectField
              disabled={!hasPermission}
              control={control}
              name={`events.${index}.shippingBearer`}
              options={SHIPPING_BEARER}
              placeholder={'배송비 부담주체 선택'}
            />
            <NumberWithUnknownField
              control={control}
              disabled={!hasPermission}
              name={`events.${index}.shippingCost`}
              placeholder={'금액입력'}
              onUnknownClick={() =>
                setValue(`events.${index}.shippingCost`, null, {
                  shouldValidate: true,
                  shouldTouch: true,
                  shouldDirty: true,
                })
              }
            />
          </div>
        </FieldWithLabel>
      )}
    </li>
  );
}

export default EventItemField;
