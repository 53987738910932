import * as Types from '../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HistoriesQueryVariables = Types.Exact<{
  historiesInput: Types.HistoriesInput;
}>;


export type HistoriesQuery = { __typename?: 'Query', adminHistories: Array<{ __typename?: 'HistoryGraphqlType', id: number, snapShot: string, createdAt: string, manager: { __typename?: 'ManagerGraphqlType', id: number, name: string } }> };


export const HistoriesDocument = gql`
    query Histories($historiesInput: historiesInput!) {
  adminHistories(historiesInput: $historiesInput) {
    id
    manager {
      id
      name
    }
    snapShot
    createdAt
  }
}
    `;

/**
 * __useHistoriesQuery__
 *
 * To run a query within a React component, call `useHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoriesQuery({
 *   variables: {
 *      historiesInput: // value for 'historiesInput'
 *   },
 * });
 */
export function useHistoriesQuery(baseOptions: Apollo.QueryHookOptions<HistoriesQuery, HistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoriesQuery, HistoriesQueryVariables>(HistoriesDocument, options);
      }
export function useHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoriesQuery, HistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoriesQuery, HistoriesQueryVariables>(HistoriesDocument, options);
        }
export type HistoriesQueryHookResult = ReturnType<typeof useHistoriesQuery>;
export type HistoriesLazyQueryHookResult = ReturnType<typeof useHistoriesLazyQuery>;
export type HistoriesQueryResult = Apollo.QueryResult<HistoriesQuery, HistoriesQueryVariables>;