import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminProductInSettlementListQueryVariables = Types.Exact<{
  adminProductId: Types.Scalars['Int']['input'];
}>;


export type AdminProductInSettlementListQuery = { __typename?: 'Query', adminProduct?: { __typename?: 'ProductGraphqlType', id: number, name: string, useType: Types.EProductUseType, jointPurchasePrice: number, freelanceSellerMargin: number, businessSellerMargin: number, businessSellerSupplyPrice: number, freelanceSellerSupplyPrice: number, inHousePurchasePrice: number, items?: Array<{ __typename?: 'ItemGraphqlType', id: number, name: string }> | null } | null };


export const AdminProductInSettlementListDocument = gql`
    query AdminProductInSettlementList($adminProductId: Int!) {
  adminProduct(id: $adminProductId) {
    items {
      id
      name
    }
    id
    name
    useType
    jointPurchasePrice
    freelanceSellerMargin
    businessSellerMargin
    businessSellerSupplyPrice
    freelanceSellerSupplyPrice
    inHousePurchasePrice
  }
}
    `;

/**
 * __useAdminProductInSettlementListQuery__
 *
 * To run a query within a React component, call `useAdminProductInSettlementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminProductInSettlementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminProductInSettlementListQuery({
 *   variables: {
 *      adminProductId: // value for 'adminProductId'
 *   },
 * });
 */
export function useAdminProductInSettlementListQuery(baseOptions: Apollo.QueryHookOptions<AdminProductInSettlementListQuery, AdminProductInSettlementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminProductInSettlementListQuery, AdminProductInSettlementListQueryVariables>(AdminProductInSettlementListDocument, options);
      }
export function useAdminProductInSettlementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminProductInSettlementListQuery, AdminProductInSettlementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminProductInSettlementListQuery, AdminProductInSettlementListQueryVariables>(AdminProductInSettlementListDocument, options);
        }
export type AdminProductInSettlementListQueryHookResult = ReturnType<typeof useAdminProductInSettlementListQuery>;
export type AdminProductInSettlementListLazyQueryHookResult = ReturnType<typeof useAdminProductInSettlementListLazyQuery>;
export type AdminProductInSettlementListQueryResult = Apollo.QueryResult<AdminProductInSettlementListQuery, AdminProductInSettlementListQueryVariables>;