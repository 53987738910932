import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';

function ItemDeleteButton(props: ButtonProps) {
  return (
    <Button
      size={'large'}
      type={'text'}
      htmlType={'button'}
      className={
        'flex h-[24px] w-[24px] items-center justify-center border-none p-0 hover:bg-transparent'
      }
      icon={
        <MinusCircleOutlined
          style={{ fontSize: 21 }}
          className={'text-black/[.45] hover:text-primary'}
        />
      }
      {...props}
    />
  );
}

export default ItemDeleteButton;
