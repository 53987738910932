import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSellerMutationVariables = Types.Exact<{
  createSellerInput: Types.CreateSellerGraphqlInput;
}>;


export type CreateSellerMutation = { __typename?: 'Mutation', adminCreateSeller: { __typename?: 'CreateSellerPayload', createdSeller: { __typename?: 'SellerGraphqlType', id: number, name: string, grade?: number | null, kemiUserId?: string | null, phoneNumber: string, memo?: string | null, manager: { __typename?: 'ManagerGraphqlType', id: number, email: string, name: string, createdAt: string, updatedAt: string }, links: Array<{ __typename?: 'SellerLinkGraphqlType', id: number, isDefault: boolean, url: string }>, emails: Array<{ __typename?: 'SellerEmailGraphqlType', id: number, isDefault: boolean, email: string }>, addresses: Array<{ __typename?: 'SellerAddressGraphqlType', id: number, isDefault: boolean, zipCode: string, address: string, detailAddress?: string | null }>, settlementInfos: Array<{ __typename?: 'SellerSettlementInfoGraphqlType', id: number, isDefault: boolean, type: Types.ESellerSettlementType, companyName?: string | null, businessRegistrationNumber?: string | null, businessRegistrationImageUrl?: string | null, sellerName?: string | null, residentRegistrationNumberFront?: string | null, residentRegistrationNumberBack?: string | null, residentRegistrationImageUrl?: string | null, bank?: Types.EBankCode | null, accountNumber?: string | null, accountImageUrl?: string | null, accountHolderName?: string | null }> } } };

export type UpdateSellerMutationVariables = Types.Exact<{
  updateSellerInput: Types.UpdateSellerGraphqlInput;
}>;


export type UpdateSellerMutation = { __typename?: 'Mutation', adminUpdateSeller: { __typename?: 'UpdateSellerPayload', updatedSeller: { __typename?: 'SellerGraphqlType', id: number, name: string, grade?: number | null, kemiUserId?: string | null, phoneNumber: string, memo?: string | null, manager: { __typename?: 'ManagerGraphqlType', id: number, email: string, name: string, createdAt: string, updatedAt: string }, links: Array<{ __typename?: 'SellerLinkGraphqlType', id: number, isDefault: boolean, url: string }>, emails: Array<{ __typename?: 'SellerEmailGraphqlType', id: number, isDefault: boolean, email: string }>, addresses: Array<{ __typename?: 'SellerAddressGraphqlType', id: number, isDefault: boolean, zipCode: string, address: string, detailAddress?: string | null }>, settlementInfos: Array<{ __typename?: 'SellerSettlementInfoGraphqlType', id: number, isDefault: boolean, type: Types.ESellerSettlementType, companyName?: string | null, businessRegistrationNumber?: string | null, businessRegistrationImageUrl?: string | null, sellerName?: string | null, residentRegistrationNumberFront?: string | null, residentRegistrationNumberBack?: string | null, residentRegistrationImageUrl?: string | null, bank?: Types.EBankCode | null, accountNumber?: string | null, accountImageUrl?: string | null, accountHolderName?: string | null }> } } };

export type SellerQueryVariables = Types.Exact<{
  adminSellerId: Types.Scalars['Int']['input'];
}>;


export type SellerQuery = { __typename?: 'Query', adminSeller: { __typename?: 'SellerGraphqlType', id: number, name: string, grade?: number | null, kemiUserId?: string | null, phoneNumber: string, memo?: string | null, manager: { __typename?: 'ManagerGraphqlType', id: number, name: string }, links: Array<{ __typename?: 'SellerLinkGraphqlType', id: number, isDefault: boolean, url: string }>, emails: Array<{ __typename?: 'SellerEmailGraphqlType', id: number, isDefault: boolean, email: string }>, addresses: Array<{ __typename?: 'SellerAddressGraphqlType', id: number, isDefault: boolean, zipCode: string, address: string, detailAddress?: string | null }>, settlementInfos: Array<{ __typename?: 'SellerSettlementInfoGraphqlType', id: number, isDefault: boolean, type: Types.ESellerSettlementType, companyName?: string | null, businessRegistrationNumber?: string | null, businessRegistrationImageUrl?: string | null, sellerName?: string | null, residentRegistrationNumberFront?: string | null, residentRegistrationNumberBack?: string | null, residentRegistrationImageUrl?: string | null, bank?: Types.EBankCode | null, accountNumber?: string | null, accountImageUrl?: string | null, accountHolderName?: string | null }> } };

export type CheckIsPhoneNumberUniqueQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']['input'];
  sellerId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type CheckIsPhoneNumberUniqueQuery = { __typename?: 'Query', adminCheckIsPhoneNumberUnique: boolean };

export type DeleteSellerMutationVariables = Types.Exact<{
  sellerId: Types.Scalars['Int']['input'];
}>;


export type DeleteSellerMutation = { __typename?: 'Mutation', adminDeleteSeller: { __typename?: 'DeleteSellerPayload', id: number } };


export const CreateSellerDocument = gql`
    mutation CreateSeller($createSellerInput: CreateSellerGraphqlInput!) {
  adminCreateSeller(createSellerInput: $createSellerInput) {
    createdSeller {
      id
      name
      manager {
        id
        email
        name
        createdAt
        updatedAt
      }
      grade
      kemiUserId
      links {
        id
        isDefault
        url
      }
      emails {
        id
        isDefault
        email
      }
      phoneNumber
      addresses {
        id
        isDefault
        zipCode
        address
        detailAddress
      }
      settlementInfos {
        id
        isDefault
        type
        companyName
        businessRegistrationNumber
        businessRegistrationImageUrl
        sellerName
        residentRegistrationNumberFront
        residentRegistrationNumberBack
        residentRegistrationImageUrl
        bank
        accountNumber
        accountImageUrl
        accountHolderName
      }
      memo
    }
  }
}
    `;
export type CreateSellerMutationFn = Apollo.MutationFunction<CreateSellerMutation, CreateSellerMutationVariables>;

/**
 * __useCreateSellerMutation__
 *
 * To run a mutation, you first call `useCreateSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSellerMutation, { data, loading, error }] = useCreateSellerMutation({
 *   variables: {
 *      createSellerInput: // value for 'createSellerInput'
 *   },
 * });
 */
export function useCreateSellerMutation(baseOptions?: Apollo.MutationHookOptions<CreateSellerMutation, CreateSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSellerMutation, CreateSellerMutationVariables>(CreateSellerDocument, options);
      }
export type CreateSellerMutationHookResult = ReturnType<typeof useCreateSellerMutation>;
export type CreateSellerMutationResult = Apollo.MutationResult<CreateSellerMutation>;
export type CreateSellerMutationOptions = Apollo.BaseMutationOptions<CreateSellerMutation, CreateSellerMutationVariables>;
export const UpdateSellerDocument = gql`
    mutation UpdateSeller($updateSellerInput: UpdateSellerGraphqlInput!) {
  adminUpdateSeller(updateSellerInput: $updateSellerInput) {
    updatedSeller {
      id
      name
      manager {
        id
        email
        name
        createdAt
        updatedAt
      }
      grade
      kemiUserId
      links {
        id
        isDefault
        url
      }
      emails {
        id
        isDefault
        email
      }
      phoneNumber
      addresses {
        id
        isDefault
        zipCode
        address
        detailAddress
      }
      settlementInfos {
        id
        isDefault
        type
        companyName
        businessRegistrationNumber
        businessRegistrationImageUrl
        sellerName
        residentRegistrationNumberFront
        residentRegistrationNumberBack
        residentRegistrationImageUrl
        bank
        accountNumber
        accountImageUrl
        accountHolderName
      }
      memo
    }
  }
}
    `;
export type UpdateSellerMutationFn = Apollo.MutationFunction<UpdateSellerMutation, UpdateSellerMutationVariables>;

/**
 * __useUpdateSellerMutation__
 *
 * To run a mutation, you first call `useUpdateSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSellerMutation, { data, loading, error }] = useUpdateSellerMutation({
 *   variables: {
 *      updateSellerInput: // value for 'updateSellerInput'
 *   },
 * });
 */
export function useUpdateSellerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSellerMutation, UpdateSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSellerMutation, UpdateSellerMutationVariables>(UpdateSellerDocument, options);
      }
export type UpdateSellerMutationHookResult = ReturnType<typeof useUpdateSellerMutation>;
export type UpdateSellerMutationResult = Apollo.MutationResult<UpdateSellerMutation>;
export type UpdateSellerMutationOptions = Apollo.BaseMutationOptions<UpdateSellerMutation, UpdateSellerMutationVariables>;
export const SellerDocument = gql`
    query Seller($adminSellerId: Int!) {
  adminSeller(id: $adminSellerId) {
    id
    name
    grade
    kemiUserId
    manager {
      id
      name
    }
    links {
      id
      isDefault
      url
    }
    emails {
      id
      isDefault
      email
    }
    phoneNumber
    addresses {
      id
      isDefault
      zipCode
      address
      detailAddress
    }
    settlementInfos {
      id
      isDefault
      type
      companyName
      businessRegistrationNumber
      businessRegistrationImageUrl
      sellerName
      residentRegistrationNumberFront
      residentRegistrationNumberBack
      residentRegistrationImageUrl
      bank
      accountNumber
      accountImageUrl
      accountHolderName
    }
    memo
  }
}
    `;

/**
 * __useSellerQuery__
 *
 * To run a query within a React component, call `useSellerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerQuery({
 *   variables: {
 *      adminSellerId: // value for 'adminSellerId'
 *   },
 * });
 */
export function useSellerQuery(baseOptions: Apollo.QueryHookOptions<SellerQuery, SellerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerQuery, SellerQueryVariables>(SellerDocument, options);
      }
export function useSellerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerQuery, SellerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerQuery, SellerQueryVariables>(SellerDocument, options);
        }
export type SellerQueryHookResult = ReturnType<typeof useSellerQuery>;
export type SellerLazyQueryHookResult = ReturnType<typeof useSellerLazyQuery>;
export type SellerQueryResult = Apollo.QueryResult<SellerQuery, SellerQueryVariables>;
export const CheckIsPhoneNumberUniqueDocument = gql`
    query CheckIsPhoneNumberUnique($phoneNumber: String!, $sellerId: Int) {
  adminCheckIsPhoneNumberUnique(phoneNumber: $phoneNumber, sellerId: $sellerId)
}
    `;

/**
 * __useCheckIsPhoneNumberUniqueQuery__
 *
 * To run a query within a React component, call `useCheckIsPhoneNumberUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsPhoneNumberUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsPhoneNumberUniqueQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useCheckIsPhoneNumberUniqueQuery(baseOptions: Apollo.QueryHookOptions<CheckIsPhoneNumberUniqueQuery, CheckIsPhoneNumberUniqueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckIsPhoneNumberUniqueQuery, CheckIsPhoneNumberUniqueQueryVariables>(CheckIsPhoneNumberUniqueDocument, options);
      }
export function useCheckIsPhoneNumberUniqueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIsPhoneNumberUniqueQuery, CheckIsPhoneNumberUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckIsPhoneNumberUniqueQuery, CheckIsPhoneNumberUniqueQueryVariables>(CheckIsPhoneNumberUniqueDocument, options);
        }
export type CheckIsPhoneNumberUniqueQueryHookResult = ReturnType<typeof useCheckIsPhoneNumberUniqueQuery>;
export type CheckIsPhoneNumberUniqueLazyQueryHookResult = ReturnType<typeof useCheckIsPhoneNumberUniqueLazyQuery>;
export type CheckIsPhoneNumberUniqueQueryResult = Apollo.QueryResult<CheckIsPhoneNumberUniqueQuery, CheckIsPhoneNumberUniqueQueryVariables>;
export const DeleteSellerDocument = gql`
    mutation DeleteSeller($sellerId: Int!) {
  adminDeleteSeller(sellerId: $sellerId) {
    id
  }
}
    `;
export type DeleteSellerMutationFn = Apollo.MutationFunction<DeleteSellerMutation, DeleteSellerMutationVariables>;

/**
 * __useDeleteSellerMutation__
 *
 * To run a mutation, you first call `useDeleteSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSellerMutation, { data, loading, error }] = useDeleteSellerMutation({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useDeleteSellerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSellerMutation, DeleteSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSellerMutation, DeleteSellerMutationVariables>(DeleteSellerDocument, options);
      }
export type DeleteSellerMutationHookResult = ReturnType<typeof useDeleteSellerMutation>;
export type DeleteSellerMutationResult = Apollo.MutationResult<DeleteSellerMutation>;
export type DeleteSellerMutationOptions = Apollo.BaseMutationOptions<DeleteSellerMutation, DeleteSellerMutationVariables>;