import { DatePicker, Descriptions, Input, Radio, Typography } from 'antd';
import dayjs from 'dayjs';

import ManagerSelect from '../../../common/components/Fields/ManagerSelect';
import { VENDOR_STATUS } from '../const';
import useListSearchFormValues from '../hooks/useListSearchFormValues';

function ListSearchForm() {
  const { searchParams, ...handlers } = useListSearchFormValues();
  return (
    <div className='flex flex-col gap-4 bg-white p-6'>
      <Typography.Title level={4} className='m-0'>
        검색
      </Typography.Title>
      <Descriptions
        bordered
        column={1}
        labelStyle={{ width: '200px' }}
        size='middle'
      >
        <Descriptions.Item label='업체명 / 업체코드'>
          <Input
            placeholder='업체명 혹은 업체코드 입력'
            style={{ width: 326 }}
            defaultValue={searchParams.vendorNameOrCode}
            onChange={handlers.handleVendor}
          />
        </Descriptions.Item>
        <Descriptions.Item label='브랜드명 / 브랜드코드'>
          <Input
            placeholder='브랜드명 혹은 브랜드코드 입력'
            style={{ width: 326 }}
            defaultValue={searchParams.brandNameOrCode}
            onChange={handlers.handleBrand}
          />
        </Descriptions.Item>
        <Descriptions.Item label='담당자'>
          <ManagerSelect
            style={{ width: 326 }}
            mode='multiple'
            showSearch
            defaultActiveFirstOption={false}
            suffixIcon={null}
            notFoundContent={null}
            onChange={handlers.handleManager}
            defaultValue={searchParams.managerIds?.map((id) => Number(id))}
          />
        </Descriptions.Item>
        <Descriptions.Item label='등록일'>
          <DatePicker
            style={{ width: 150 }}
            defaultValue={
              searchParams.startDate ? dayjs(searchParams.startDate) : undefined
            }
            onChange={handlers.handleStartDate}
            disabledDate={(currentDate) => {
              return (
                !!searchParams.endDate &&
                currentDate.isAfter(dayjs(searchParams.endDate))
              );
            }}
          />{' '}
          ~{' '}
          <DatePicker
            style={{ width: 150 }}
            defaultValue={
              searchParams.endDate ? dayjs(searchParams.endDate) : undefined
            }
            onChange={handlers.handleEndDate}
            disabledDate={(currentDate) => {
              return (
                !!searchParams.startDate &&
                currentDate.isBefore(dayjs(searchParams.startDate))
              );
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label='업체거래상태'>
          <Radio.Group
            defaultValue={searchParams.status || VENDOR_STATUS[0].value}
            onChange={handlers.handleStatus}
            options={VENDOR_STATUS}
          />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default ListSearchForm;
