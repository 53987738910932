import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminBulkCreateOrderClaimMutationVariables = Types.Exact<{
  orderClaimData: Types.BulkCreateOrderClaimInputGraphqlType;
}>;


export type AdminBulkCreateOrderClaimMutation = { __typename?: 'Mutation', adminBulkCreateOrderClaim: { __typename?: 'BulkOrderClaimOutputGraphqlType', failureOrderIds: Array<number>, successOrderIds: Array<number> } };

export type AdminOrderGroupQueryVariables = Types.Exact<{
  adminOrderGroupId: Types.Scalars['Int']['input'];
}>;


export type AdminOrderGroupQuery = { __typename?: 'Query', adminOrderGroup: { __typename?: 'OrderGroupGraphqlType', id: number, code: string, ordererName?: string | null, ordererPhoneNumber?: string | null, marketName?: string | null, marketId?: number | null, salesChannelOrderGroupCode?: string | null, orderedAt?: string | null, orders: Array<{ __typename?: 'OrderGraphqlType', id: number, code: string, bundleDeliveryCode: string, salesChannel: Types.ESalesChannel, salesChannelType: Types.ESalesChannelType, salesChannelOrderCode?: string | null, status: Types.EOrderStatus, orderAmount: number, productQuantity: number, productId: number, productName: string, productUseType: Types.EProductUseType, orderType: Types.EOrderType, deliveryFee?: number | null, regionDeliveryFee?: number | null, productGroupId: number, hasActiveExchangeReDeliveryPromotionRelease?: boolean | null, activeClaim?: { __typename?: 'OrderClaimGraphqlType', id: number, status: Types.EOrderClaimStatus, reason?: string | null, reasonType: Types.EOrderClaimReasonType, isWiredOwnDeliveryFault?: boolean | null, target: Types.EOrderClaimTarget, claimQuantity?: number | null, claimType: Types.EOrderClaimType, promotionReleaseId?: number | null } | null, claims?: Array<{ __typename?: 'OrderClaimGraphqlType', id: number, status: Types.EOrderClaimStatus, reason?: string | null, reasonType: Types.EOrderClaimReasonType, isWiredOwnDeliveryFault?: boolean | null, target: Types.EOrderClaimTarget, claimQuantity?: number | null, claimType: Types.EOrderClaimType }> | null, items?: Array<{ __typename?: 'OrderItemGraphqlType', id: number, itemQuantity: number, name: string, itemId: number, salesChannelItemCode?: string | null, salesChannelItemName?: string | null }> | null, support?: { __typename?: 'OrderSupportGraphqlType', id: number, status: Types.EOrderSupportStatus, channel: Types.EOrderSupportChannelType, requestType: Types.EOrderSupportRequestType } | null }> } };


export const AdminBulkCreateOrderClaimDocument = gql`
    mutation AdminBulkCreateOrderClaim($orderClaimData: BulkCreateOrderClaimInputGraphqlType!) {
  adminBulkCreateOrderClaim(orderClaimData: $orderClaimData) {
    failureOrderIds
    successOrderIds
  }
}
    `;
export type AdminBulkCreateOrderClaimMutationFn = Apollo.MutationFunction<AdminBulkCreateOrderClaimMutation, AdminBulkCreateOrderClaimMutationVariables>;

/**
 * __useAdminBulkCreateOrderClaimMutation__
 *
 * To run a mutation, you first call `useAdminBulkCreateOrderClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkCreateOrderClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkCreateOrderClaimMutation, { data, loading, error }] = useAdminBulkCreateOrderClaimMutation({
 *   variables: {
 *      orderClaimData: // value for 'orderClaimData'
 *   },
 * });
 */
export function useAdminBulkCreateOrderClaimMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkCreateOrderClaimMutation, AdminBulkCreateOrderClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkCreateOrderClaimMutation, AdminBulkCreateOrderClaimMutationVariables>(AdminBulkCreateOrderClaimDocument, options);
      }
export type AdminBulkCreateOrderClaimMutationHookResult = ReturnType<typeof useAdminBulkCreateOrderClaimMutation>;
export type AdminBulkCreateOrderClaimMutationResult = Apollo.MutationResult<AdminBulkCreateOrderClaimMutation>;
export type AdminBulkCreateOrderClaimMutationOptions = Apollo.BaseMutationOptions<AdminBulkCreateOrderClaimMutation, AdminBulkCreateOrderClaimMutationVariables>;
export const AdminOrderGroupDocument = gql`
    query AdminOrderGroup($adminOrderGroupId: Int!) {
  adminOrderGroup(id: $adminOrderGroupId) {
    id
    code
    ordererName
    ordererPhoneNumber
    marketName
    marketId
    salesChannelOrderGroupCode
    orderedAt
    orders {
      id
      code
      bundleDeliveryCode
      salesChannel
      salesChannelType
      salesChannelOrderCode
      status
      orderAmount
      productQuantity
      productId
      productName
      productUseType
      orderType
      deliveryFee
      regionDeliveryFee
      productGroupId
      hasActiveExchangeReDeliveryPromotionRelease
      activeClaim {
        id
        status
        reason
        reasonType
        isWiredOwnDeliveryFault
        target
        claimQuantity
        claimType
        promotionReleaseId
      }
      claims {
        id
        status
        reason
        reasonType
        isWiredOwnDeliveryFault
        target
        claimQuantity
        claimType
      }
      items {
        id
        itemQuantity
        name
        itemId
        salesChannelItemCode
        salesChannelItemName
      }
      support {
        id
        status
        channel
        requestType
      }
    }
  }
}
    `;

/**
 * __useAdminOrderGroupQuery__
 *
 * To run a query within a React component, call `useAdminOrderGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrderGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrderGroupQuery({
 *   variables: {
 *      adminOrderGroupId: // value for 'adminOrderGroupId'
 *   },
 * });
 */
export function useAdminOrderGroupQuery(baseOptions: Apollo.QueryHookOptions<AdminOrderGroupQuery, AdminOrderGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOrderGroupQuery, AdminOrderGroupQueryVariables>(AdminOrderGroupDocument, options);
      }
export function useAdminOrderGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOrderGroupQuery, AdminOrderGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOrderGroupQuery, AdminOrderGroupQueryVariables>(AdminOrderGroupDocument, options);
        }
export type AdminOrderGroupQueryHookResult = ReturnType<typeof useAdminOrderGroupQuery>;
export type AdminOrderGroupLazyQueryHookResult = ReturnType<typeof useAdminOrderGroupLazyQuery>;
export type AdminOrderGroupQueryResult = Apollo.QueryResult<AdminOrderGroupQuery, AdminOrderGroupQueryVariables>;