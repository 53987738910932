import { SelectProps } from 'antd';
import { isNull } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { Control, FieldValues, Path, useFormContext } from 'react-hook-form';

import SelectField from '../../../../common/components/Fields/SelectField';
import { FieldProps } from '../../../../common/components/Fields/types';
import { useBrandsInProductGroupLazyQuery } from '../../graphql/productGroup.generated';
import { ProductGroupFormValues } from '../../types';

type BrandSelectFieldProps<TFieldValues extends FieldValues> = SelectProps &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
  };

export default function BrandSelectField<TFieldValues extends FieldValues>({
  name,
  control,
  hideErrorMessage,
  ...props
}: BrandSelectFieldProps<TFieldValues>) {
  const { watch } = useFormContext<ProductGroupFormValues>();
  const vendorId = watch('vendorId');
  const brandId = watch('brandId');
  const brandName = watch('brandName');

  const [fetchBrands, { data }] = useBrandsInProductGroupLazyQuery({
    variables: {
      filter: {
        vendorId,
      },
    },
  });

  useEffect(() => {
    if (vendorId) {
      fetchBrands();
    }
  }, [fetchBrands, vendorId]);

  const options = useMemo(() => {
    if (!vendorId) return [];

    if (data?.adminBrands.edges) {
      return data?.adminBrands.edges.map(({ node }) => ({
        label: node.name,
        value: node.id,
      }));
    } else {
      return [
        {
          label: brandName,
          value: isNull(brandId) ? undefined : brandId,
        },
      ];
    }
  }, [vendorId, data?.adminBrands.edges, brandName, brandId]);

  return (
    <SelectField
      name={name}
      control={control}
      options={options}
      {...props}
      disabled={!options.length || props.disabled}
      showSearch
      hideErrorMessage={hideErrorMessage}
    />
  );
}
