import { Tabs, TabsProps, Typography } from 'antd';
import { useMemo, useState } from 'react';

import Modal from '../../../common/components/Modal';
import { useAdminCountMarketByProductGroupIdForProductGroupQuery } from '../graphql/market.generated';
import { ProductMarketListModalStatusType } from '../types';

import ProductMarketList from './ProductMarketList';
import ProductMarketListStockTable from './ProductMarketListStockTable';

type ProductMarketListModalProps = {
  onClose: () => void;
  productGroupId: number;
};

function ProductMarketListModal({
  onClose,
  productGroupId,
}: ProductMarketListModalProps) {
  const { data } = useAdminCountMarketByProductGroupIdForProductGroupQuery({
    variables: {
      productGroupId,
    },
  });

  const marketCounts = data?.adminCountMarketByProductGroupIdForProductGroup;

  const onSaleCount = useMemo(
    () => marketCounts?.find(({ status }) => status === 'ON_SALE')?.count || 0,
    [marketCounts]
  );

  const totalCount = useMemo(
    () =>
      marketCounts?.reduce((acc, { status, count }) => {
        if (status === 'CANCELED') return acc; // 취소는 제외
        return acc + count;
      }, 0) || 0,
    [marketCounts]
  );

  const items: TabsProps['items'] = useMemo(
    () => [
      {
        key: 'ALL',
        label: <p>전체({totalCount})</p>,
        children: (
          <ProductMarketList
            type='ALL'
            productGroupId={productGroupId}
            hasMarket={!!totalCount}
          />
        ),
      },
      {
        key: 'ON_SALE',
        label: <p>마켓중({onSaleCount})</p>,
        children: (
          <ProductMarketList
            type='ON_SALE'
            productGroupId={productGroupId}
            hasMarket={!!totalCount}
          />
        ),
      },
    ],
    [onSaleCount, productGroupId, totalCount]
  );

  const [currentTab, setCurrentTab] =
    useState<ProductMarketListModalStatusType>('ON_SALE');

  return (
    <Modal
      title='상품별 마켓 조회'
      width={1100}
      footer={null}
      onCancel={onClose}
    >
      <div className='mt-6'>
        {currentTab === 'ON_SALE' && (
          <div className='mb-10'>
            <Typography.Paragraph strong className='m-0 p-2'>
              옵션별 재고 현황
            </Typography.Paragraph>
            <ProductMarketListStockTable productGroupId={productGroupId} />
          </div>
        )}
        <Tabs
          items={items}
          activeKey={currentTab}
          onChange={(activeKey) =>
            setCurrentTab(activeKey as ProductMarketListModalStatusType)
          }
        />
      </div>
    </Modal>
  );
}

export default ProductMarketListModal;
