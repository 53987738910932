import { Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import PageLoader from '../../../../common/components/PageLoader';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { EOrderClaimStatus, EOrderClaimType } from '../../../../schema/types';
import { useAdminOrderGroupLazyQuery } from '../../graphql/createClaim.generated';
import { getCreateClaimSelectOptions } from '../../helper/updateStatus';
import { OrderForClaimStatus } from '../../type';
import CreateClaimModal from '../CreateClaimModal';

function CreateClaimSelect({ order }: { order: OrderForClaimStatus }) {
  const { hasPermission } = usePermissions('ORDER_EDIT');

  const [status, setStatus] = useState(order.activeClaim?.status || null);
  const [targetClaim, setTargetClaim] = useState<
    [EOrderClaimType, EOrderClaimStatus] | null
  >(null);
  const [openModal, setOpenModal] = useState(false);

  const [fetchOrderGroup, { data, loading }] = useAdminOrderGroupLazyQuery({
    variables: { adminOrderGroupId: order.orderGroupId },
  });

  const adminOrderGroup = data?.adminOrderGroup;
  const currentOrder = data?.adminOrderGroup.orders.find(
    (groupOrder) => groupOrder.id === order.orderId
  );

  const options = useMemo(() => {
    return getCreateClaimSelectOptions(order);
  }, [order]);

  const handleChange = (
    claimType: EOrderClaimType,
    claimStatus: EOrderClaimStatus
  ) => {
    void fetchOrderGroup();
    setTargetClaim([claimType, claimStatus]);
    setOpenModal(true);
  };

  useEffect(() => {
    if (order.activeClaim?.status) {
      setStatus(order.activeClaim.status);
    }
  }, [order.activeClaim?.status]);

  return (
    <div>
      <Select
        disabled={!hasPermission || !options.length}
        className={'w-[128px]'}
        options={options}
        value={status || null}
        onChange={(_, option) => {
          if (!Array.isArray(option)) {
            const claimType = option.data.claimType;
            const status = option.data.claimStatus;

            handleChange(claimType, status);
          }
        }}
        placeholder={'선택'}
      />
      {openModal && adminOrderGroup && targetClaim && currentOrder && (
        <CreateClaimModal
          type={targetClaim[0]}
          onCancel={() => setOpenModal(false)}
          orderGroup={adminOrderGroup}
          order={currentOrder}
        />
      )}
      {loading && <PageLoader />}
    </div>
  );
}

export default CreateClaimSelect;
