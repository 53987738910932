import * as jose from 'jose';

import { authorization } from '../../domain/auth/helper';
import { ERole } from '../../schema/types';

export const usePermissions = (
  permissions: ERole | ERole[]
): { hasPermission: boolean } => {
  try {
    const payload = jose.decodeJwt<{ roles: ERole[] }>(
      authorization.accessToken
    );
    const roles = payload.roles;

    if (Array.isArray(permissions)) {
      return {
        hasPermission: permissions.every((permission) =>
          roles.includes(permission)
        ),
      };
    }

    return { hasPermission: !!roles.includes(permissions) };
  } catch (e) {
    return { hasPermission: false };
  }
};
