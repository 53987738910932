import { Descriptions, Tag, Typography } from 'antd';

import {
  PROMTION_TYPES_LABEL,
  RELEASE_STATUS_LABEL,
  SAMPLE_STATUS_LABEL,
} from '../../const';
import useReleaseDetail from '../../hooks/useReleaseDetail';

function BasicInformation() {
  const { data } = useReleaseDetail();

  if (!data) return null;

  const { adminPromotionRelease } = data;
  const promotionType = adminPromotionRelease.promotion.promotionType;
  const code = adminPromotionRelease.releaseCode || '';
  const releaseStatus = adminPromotionRelease.releaseStatus;
  const sampleStatus = adminPromotionRelease.promotion.sampleStatus;
  const orderCode = adminPromotionRelease.promotion.orderCode;
  return (
    <div>
      <Tag className={'mb-sm'}>{`출고코드: ${code}`}</Tag>
      <Typography.Title level={4} className='m-0 py-6'>
        기본정보
      </Typography.Title>
      <Descriptions
        bordered
        layout='horizontal'
        column={1}
        labelStyle={{ width: 240 }}
      >
        <Descriptions.Item label='구분'>
          {PROMTION_TYPES_LABEL[promotionType]}
        </Descriptions.Item>
        <Descriptions.Item label='출고상태'>
          {RELEASE_STATUS_LABEL[releaseStatus]}
        </Descriptions.Item>
        {promotionType === 'SAMPLE' && (
          <Descriptions.Item label='샘플상태'>
            {SAMPLE_STATUS_LABEL[sampleStatus]}
          </Descriptions.Item>
        )}
        {promotionType === 'EXCHANGE_RE_DELIVERY' && (
          <Descriptions.Item label='교환대상 와이어드품목주문코드'>
            {orderCode}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
}

export default BasicInformation;
