import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import SimpleEditor, {
  SimpleEditorProps,
} from '../../../../common/components/SimpleEditor';

type SimpleEditorFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  label: string;
  required?: boolean;
  disabled?: boolean;
} & Omit<SimpleEditorProps, 'content' | 'onChange'>;

export default function SimpleEditorField<TFieldValues extends FieldValues>({
  control,
  name,
  label,
  required,
  disabled,
  ...props
}: SimpleEditorFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <>
            <FieldWithLabel label={label} required={required}>
              <SimpleEditor
                content={field.value}
                onChange={field.onChange}
                editable={!disabled}
                {...props}
              />
            </FieldWithLabel>
          </>
        );
      }}
    />
  );
}
