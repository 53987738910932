import { blue } from '@ant-design/colors';
import { Select } from 'antd';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import TextNumberInput from '../../../common/components/Input/TextNumberInput';
import { useAdminDeliveryCompaniesQuery } from '../graphql/promotionRelease.generated';
import usePromotionPermission from '../hooks/usePromotionPermission';
import { PromotionReleaseTableDataType } from '../types';

type PromotionReleaseTableDeliveryCellProps = {
  record: PromotionReleaseTableDataType;
  onDeliveryCompanyChange: (
    record: PromotionReleaseTableDataType,
    value: number
  ) => void;
  onTrackingNumberChange: (
    record: PromotionReleaseTableDataType,
    value: string
  ) => void;
  onCellSpanChange: (releaseId: number) => void;
  isMergedSpan: boolean;
};

function PromotionReleaseTableDeliveryCell({
  record,
  onDeliveryCompanyChange,
  onTrackingNumberChange,
  onCellSpanChange,
  isMergedSpan,
}: PromotionReleaseTableDeliveryCellProps) {
  const [trackingNumber, setTrackingNumber] = useState(
    record.trackingNumber || ''
  );
  useEffect(() => {
    setTrackingNumber(record.trackingNumber || '');
  }, [record.trackingNumber]);

  const hasPermission = usePromotionPermission();
  const [editing, setEditing] = useState(false);
  const { data } = useAdminDeliveryCompaniesQuery();
  const deliveryCompanyOptions = useMemo(
    () =>
      data?.adminDeliveryCompanies.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [data]
  );

  const handleTrackingNumber = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setTrackingNumber(ev.target.value);
    },
    []
  );
  const handleTrackingNumberBlur = useCallback(() => {
    onTrackingNumberChange(record, trackingNumber);
  }, [record, trackingNumber, onTrackingNumberChange]);

  const deliveryCompanyName = useMemo(
    () =>
      deliveryCompanyOptions?.find(
        ({ value }) => value === record.deliveryCompanyId
      )?.label || '-',
    [deliveryCompanyOptions, record.deliveryCompanyId]
  );

  if (record.releaseStatus === 'RELEASE_COMPLETED' && !editing) {
    return (
      <div className='flex flex-col gap-1'>
        <div>{deliveryCompanyName}</div>
        <div>{trackingNumber}</div>
        {hasPermission && (
          <div
            className='cursor-pointer px-2'
            style={{ color: blue.primary }}
            onClick={() => setEditing(true)}
          >
            수정
          </div>
        )}
      </div>
    );
  }
  return (
    <div className='flex flex-col gap-1'>
      <Select
        disabled={!hasPermission}
        className='w-full'
        placeholder='--택배사 선택--'
        options={deliveryCompanyOptions}
        value={record.deliveryCompanyId}
        onChange={(value) => onDeliveryCompanyChange(record, value)}
      />
      <TextNumberInput
        disabled={!hasPermission}
        allowClear
        value={trackingNumber}
        onChange={handleTrackingNumber}
        onBlur={handleTrackingNumberBlur}
        placeholder='송장번호'
      />
      {hasPermission &&
        record.releaseStatus !== 'RELEASE_COMPLETED' &&
        record.isFirstProduct && (
          <div
            className='cursor-pointer text-right'
            style={{ color: blue.primary }}
            onClick={() => onCellSpanChange(record.releaseId)}
          >
            {isMergedSpan ? '개별 송장입력' : '통합 송장입력'}
          </div>
        )}
    </div>
  );
}

export default PromotionReleaseTableDeliveryCell;
