import { Spin, Typography } from 'antd';
import { useMemo } from 'react';

import EmptyTable from '../common/components/EmptyTable';
import WithDefaultSearchParam from '../common/hoc/WithDefaultSearchParam';
import OrderClaimsTable from '../domain/cs/components/OrderClaimsTable';
import SalesChannelTab from '../domain/cs/components/SalesChannelTab';
import SearchFormWithFilterModal from '../domain/cs/components/SearchFormWithFilterModal';
import Summary, { SummaryProps } from '../domain/cs/components/Summary';
import {
  defaultClaimStatus,
  defaultSearchParam,
} from '../domain/cs/helper/list';
import { useClaimStatusCount } from '../domain/cs/hooks/useClaimStatusCount';
import { useSearchClaimsParams } from '../domain/cs/hooks/useSearchClaimsParams';

function CsCancelList() {
  const { setSearchParams, response, searchParams } =
    useSearchClaimsParams('CANCEL');
  const { data, loading } = response;
  const {
    startedAt,
    endedAt,
    periodType,
    searchKeyword,
    claimStatus,
    salesChannelType,
  } = searchParams;
  const total = data?.adminOrders?.totalCount || 0;

  const { statuses, loading: statusCountLoading } =
    useClaimStatusCount('CANCEL');

  const items: SummaryProps['items'] = useMemo(() => {
    const summaryItems: SummaryProps['items'] = [
      {
        key: 'CANCEL_REQUESTED',
        label: '취소요청',
        count: statuses.CANCEL_REQUESTED || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: 'CANCEL_REQUESTED',
            claimStatus: 'CANCEL_REQUESTED',
            orderStatus: undefined,
          }),
      },
      {
        key: 'CANCEL_NOT_APPROVED',
        label: '취소미승인',
        count: statuses.CANCEL_NOT_APPROVED || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: undefined,
            claimStatus: 'CANCEL_NOT_APPROVED',
            orderStatus: undefined,
          }),
      },
      {
        key: 'CANCEL_COMPLETED',
        label: '취소완료',
        count: statuses.CANCEL_COMPLETED || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: undefined,
            claimStatus: 'CANCEL_COMPLETED',
            orderStatus: 'CANCEL_COMPLETED',
          }),
      },
    ];

    const isPBShop = salesChannelType === 'PB_SHOP';

    return summaryItems.filter((item) => {
      if (!isPBShop) {
        return item.key === 'CANCEL_COMPLETED';
      }

      return true;
    });
  }, [statuses, setSearchParams, salesChannelType]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='bg-white p-6'>
        <SalesChannelTab
          onChange={(v) => {
            setSearchParams({
              salesChannelType: v,
              claimStatus:
                v === 'PB_SHOP' ? 'CANCEL_REQUESTED' : 'CANCEL_COMPLETED',
            });
          }}
        />
        <Summary
          loading={statusCountLoading}
          label='취소현황'
          activeKey={claimStatus}
          items={items}
        />
      </div>
      <div className='bg-white p-6'>
        <Typography.Title level={5}>검색</Typography.Title>
        <SearchFormWithFilterModal
          onSearch={setSearchParams}
          initialValue={{
            startedAt,
            endedAt,
            periodType,
            searchKeyword,
          }}
        />
      </div>
      <div className='bg-white p-6'>
        <Typography.Title level={4} className='m-0'>
          주문리스트
        </Typography.Title>
        <div className='mt-4'>
          <Spin spinning={loading}>
            {!data?.adminOrders?.edges.length && <EmptyTable />}
            {!!data?.adminOrders?.edges.length && (
              <OrderClaimsTable
                orders={data.adminOrders?.edges}
                total={total}
                claimStatus={claimStatus}
                tableType={'CANCEL'}
              />
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default WithDefaultSearchParam(CsCancelList, {
  ...defaultSearchParam,
  claimStatus: defaultClaimStatus.CANCEL,
  orderStatus: 'CANCEL_COMPLETED',
});
