import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';

import RootLayout from '../common/layout';
import AuthCallback from '../domain/auth/components/AuthCallback';
import Index from '../pages';
import CsCancelList from '../pages/CsCancelList';
import CsExchangeList from '../pages/CsExchangeList';
import CsOrderSupportList from '../pages/CsOrderSupportList';
import CsReturnList from '../pages/CsReturnList';
import MarketDetail from '../pages/MarketDetail';
import MarketList from '../pages/MarketList';
import OperatorDetail from '../pages/OperatorDetail';
import OperatorList from '../pages/OperatorList';
import OrderList from '../pages/OrderList';
import ProductDetail from '../pages/ProductDetail';
import ProductList from '../pages/ProductList';
import ProductNew from '../pages/ProductNew';
import PromotionReleaseDetail from '../pages/PromotionReleaseDetail';
import PromotionReleaseList from '../pages/PromotionReleaseList';
import SellerDetails from '../pages/SellerDetails';
import SellerList from '../pages/SellerList';
import SettlementDepositList from '../pages/SettlementDepositList';
import SettlementList from '../pages/SettlementList';
import SettlementSheetList from '../pages/SettlementSheetList';
import VendorDetail from '../pages/VendorDetail';
import VendorList from '../pages/VendorList';

import { ROUTES } from './const';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routerConfig = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { index: true, element: <Index /> },
      {
        path: ROUTES.MARKETS.path,
        element: <MarketList />,
      },
      {
        path: ROUTES.MARKET_DETAIL.path,
        element: <MarketDetail />,
      },
      {
        path: ROUTES.PRODUCTS.path,
        element: <ProductList />,
      },
      {
        path: ROUTES.PRODUCT_DETAIL.path,
        element: <ProductDetail />,
      },
      {
        path: ROUTES.PRODUCT_NEW.path,
        element: <ProductNew />,
      },
      {
        path: ROUTES.SELLERS.path,
        element: <SellerList />,
      },
      {
        path: ROUTES.SELLER_DETAIL.path,
        element: <SellerDetails />,
      },
      {
        path: ROUTES.VENDORS.path,
        element: <VendorList />,
      },
      {
        path: ROUTES.VENDOR_DETAIL.path,
        element: <VendorDetail />,
      },
      {
        path: ROUTES.SAMPLES.path,
        element: (
          <div>
            {ROUTES.SAMPLES.path} {ROUTES.SAMPLES.title}
          </div>
        ),
      },
      {
        path: ROUTES.PROMOTION_RELEASE_DETAIL.path,
        element: <PromotionReleaseDetail />,
      },
      {
        path: ROUTES.PROMOTION_RELEASES.path,
        element: <PromotionReleaseList />,
      },
      {
        path: ROUTES.ORDERING_TRANSFORM.path,
        element: (
          <div>
            {ROUTES.ORDERING_TRANSFORM.path} {ROUTES.ORDERING_TRANSFORM.title}
          </div>
        ),
      },
      {
        path: ROUTES.ORDERS.path,
        element: <OrderList />,
      },
      {
        path: ROUTES.CS_RETURNS.path,
        element: <CsReturnList />,
      },
      {
        path: ROUTES.CS_EXCHANGES.path,
        element: <CsExchangeList />,
      },
      {
        path: ROUTES.CS_CANCELS.path,
        element: <CsCancelList />,
      },
      {
        path: ROUTES.CS_ORDER_SUPPORTS.path,
        element: <CsOrderSupportList />,
      },
      {
        path: ROUTES.SETTLEMENTS_DEPOSIT.path,
        element: <SettlementDepositList />,
      },
      {
        path: ROUTES.SETTLEMENTS_LIST.path,
        element: <SettlementList />,
      },
      {
        path: ROUTES.SETTLEMENTS_SHEETS.path,
        element: <SettlementSheetList />,
      },
      {
        path: ROUTES.OPERATORS.path,
        element: <OperatorList />,
      },
      {
        path: ROUTES.OPERATOR_DETAIL.path,
        element: <OperatorDetail />,
      },
    ],
  },
  {
    path: ROUTES.OAUTH_CALLBACK.path,
    element: <AuthCallback />,
  },
]);
