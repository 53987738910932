import { Checkbox, Descriptions, Input, Select } from 'antd';

import { ESettlementPeriodFilter } from '../../../../schema/types';
import {
  SETTLEMENT_PERIOD_OPTIONS,
  SETTLEMENT_SELLING_CHANNEL_OPTIONS,
  SETTLEMENT_TYPE_OPTIONS,
} from '../../const';
import useSettlementListSearchForm from '../../hooks/useSettlementListSearchForm';
import CodeSelect from '../fields/CodeSelect';
import PeriodSelect from '../fields/PeriodSelect';
import SettlementBrandSelect from '../fields/SettlementBrandSelect';
import SettlementMarketSelect from '../fields/SettlementMarketSelect';
import SettlementProductGroupSelect from '../fields/SettlementProductGroupSelect';
import SettlementProductSelect from '../fields/SettlementProductSelect';
import SettlementSellerSelect from '../fields/SettlementSellerSelect';
import SettlementVendorSelect from '../fields/SettlementVendorSelect';

function SearchForm() {
  const {
    handlePeriodChange,
    handleSalesChannelChange,
    handleSettlementTypeChange,
    handleMarketChange,
    handleSellerChange,
    handleVendorChange,
    handleBrandChange,
    handleProductGroupChange,
    handleProductChange,
    handleReceiverNameChange,
    handleNoSettlementCheck,
    searchParams,
  } = useSettlementListSearchForm();

  return (
    <>
      <Descriptions
        bordered
        column={2}
        labelStyle={{ width: 170 }}
        contentStyle={{ padding: '8px 24px' }}
        size='small'
      >
        <Descriptions.Item label='기간' span={2}>
          <PeriodSelect<ESettlementPeriodFilter>
            periodType={searchParams.periodType}
            startedAt={searchParams.startedAt}
            endedAt={searchParams.endedAt}
            periodOptions={SETTLEMENT_PERIOD_OPTIONS}
            onChange={handlePeriodChange}
          />
        </Descriptions.Item>
        <Descriptions.Item label='코드검색' span={2}>
          <CodeSelect />
        </Descriptions.Item>
        <Descriptions.Item label='판매채널'>
          <Select
            className='w-[334px]'
            placeholder='판매채널 선택'
            options={SETTLEMENT_SELLING_CHANNEL_OPTIONS}
            onChange={handleSalesChannelChange}
            value={searchParams.salesChannel}
            allowClear
          />
        </Descriptions.Item>
        <Descriptions.Item label='구분'>
          <Select
            mode='multiple'
            className='w-[334px]'
            placeholder='구분 선택'
            options={SETTLEMENT_TYPE_OPTIONS}
            onChange={handleSettlementTypeChange}
            value={searchParams.settlementTypes}
            allowClear
          />
        </Descriptions.Item>
        <Descriptions.Item label='마켓명'>
          <SettlementMarketSelect
            className='w-[334px]'
            placeholder='마켓명 선택'
            initialLoad
            onChange={handleMarketChange}
            value={
              searchParams.marketId ? Number(searchParams.marketId) : undefined
            }
            allowClear
          />
        </Descriptions.Item>
        <Descriptions.Item label='셀러명'>
          <SettlementSellerSelect
            className='w-[334px]'
            placeholder='셀러명 선택'
            initialLoad
            onChange={handleSellerChange}
            value={
              searchParams.sellerId ? Number(searchParams.sellerId) : undefined
            }
            allowClear
          />
        </Descriptions.Item>
        <Descriptions.Item label='업체명'>
          <SettlementVendorSelect
            className='w-[334px]'
            placeholder='업체명 선택'
            initialLoad
            onChange={handleVendorChange}
            value={
              searchParams.vendorId ? Number(searchParams.vendorId) : undefined
            }
            allowClear
          />
        </Descriptions.Item>
        <Descriptions.Item label='브랜드명'>
          <SettlementBrandSelect
            className='w-[334px]'
            placeholder='브랜드명 선택'
            initialLoad
            onChange={handleBrandChange}
            value={
              searchParams.brandId ? Number(searchParams.brandId) : undefined
            }
            allowClear
          />
        </Descriptions.Item>
        <Descriptions.Item label='상품명'>
          <SettlementProductGroupSelect
            className='w-[334px]'
            placeholder='상품명 선택'
            initialLoad
            onChange={handleProductGroupChange}
            value={
              searchParams.productGroupId
                ? Number(searchParams.productGroupId)
                : undefined
            }
            allowClear
          />
        </Descriptions.Item>
        <Descriptions.Item label='품목명'>
          <SettlementProductSelect
            className='w-[334px]'
            placeholder='품목명 선택'
            initialLoad
            onChange={handleProductChange}
            value={
              searchParams.productId
                ? Number(searchParams.productId)
                : undefined
            }
            allowClear
          />
        </Descriptions.Item>
        <Descriptions.Item label='수령자명'>
          <Input
            className='w-[334px]'
            placeholder='수령자명 입력'
            onChange={handleReceiverNameChange}
            value={searchParams.receiverName}
          />
        </Descriptions.Item>
      </Descriptions>
      <div className='mt-4 flex justify-end'>
        <Checkbox
          onChange={handleNoSettlementCheck}
          checked={searchParams.noSettlement === 'true' ? true : false}
        >
          <span>정산서 없음</span>
        </Checkbox>
      </div>
    </>
  );
}

export default SearchForm;
