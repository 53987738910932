import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Radio } from 'antd';
import { Control, Controller, useWatch } from 'react-hook-form';

import BankAccountField from '../../../common/components/Fields/BankAccountField';
import BusinessRegistrationField from '../../../common/components/Fields/BusinessRestrationField';
import ResidentRegistrationField from '../../../common/components/Fields/ResidentRestrationField';
import SelectField from '../../../common/components/Fields/SelectField';
import TextField from '../../../common/components/Fields/TextField';
import { CreateSellerFormValues, UpdateSellerFormValues } from '../types';

type SettlementItemFieldProps = {
  index: number;
  control: Control<UpdateSellerFormValues | CreateSellerFormValues>;
  onRemove: () => void;
  onIsDefaultChange: () => void;
  hasIsDefaultRadio: boolean;
  disabled: boolean;
};

function SettlementItemField({
  index,
  control,
  hasIsDefaultRadio,
  disabled,
  onRemove,
  onIsDefaultChange,
}: SettlementItemFieldProps) {
  const type = useWatch({ control, name: `settlementInfos.${index}.type` });

  return (
    <div>
      {hasIsDefaultRadio && (
        <Controller
          render={({ field: controllerField }) => (
            <Radio
              disabled={disabled}
              name={controllerField.name}
              checked={controllerField.value as boolean}
              className={'mb-lg whitespace-nowrap'}
              onChange={onIsDefaultChange}
            >
              대표
            </Radio>
          )}
          name={`settlementInfos.${index}.isDefault`}
        />
      )}

      <div className={'grid w-updateFormWidth gap-xl'}>
        <SelectField
          disabled={disabled}
          control={control}
          name={`settlementInfos.${index}.type`}
          label={'판매유형'}
          required
          placeholder={'-- 선택 --'}
          options={[
            { label: '사업자', value: 'BUSINESS' },
            { label: '프리랜서', value: 'FREELANCER' },
          ]}
        />

        {type === 'BUSINESS' && (
          <>
            <TextField
              disabled={disabled}
              control={control}
              name={`settlementInfos.${index}.companyName`}
              label={'사업자명'}
              placeholder={'사업자명'}
              maxLength={30}
            />

            <BusinessRegistrationField
              control={control}
              inputProps={{
                name: `settlementInfos.${index}.businessRegistrationNumber`,
                placeholder: '000-00-00000',
                disabled,
              }}
              uploadProps={{
                id: `business-registration-image-${index}`,
                name: `settlementInfos.${index}.businessRegistrationImageUrl`,
                purpose: 'SELLERS',
                disabled,
              }}
            />
          </>
        )}

        {type === 'FREELANCER' && (
          <>
            <TextField
              disabled={disabled}
              control={control}
              name={`settlementInfos.${index}.sellerName`}
              label={'실명'}
              placeholder={'실명'}
              maxLength={5}
            />

            <ResidentRegistrationField
              control={control}
              prevInputProps={{
                name: `settlementInfos.${index}.residentRegistrationNumberFront`,
                placeholder: '000000',
                disabled,
              }}
              nextInputProps={{
                name: `settlementInfos.${index}.residentRegistrationNumberBack`,
                placeholder: '0000000',
                disabled,
              }}
              uploadProps={{
                id: `resident-registration-number-copy-image-${index}`,
                name: `settlementInfos.${index}.residentRegistrationImageUrl`,
                purpose: 'SELLERS',
                disabled,
              }}
            />
          </>
        )}
        <BankAccountField
          selectProps={{
            name: `settlementInfos.${index}.bank`,
            disabled,
          }}
          inputProps={{
            name: `settlementInfos.${index}.accountNumber`,
            disabled,
          }}
          uploadProps={{
            id: `bank-account-copy-upload-${index}`,
            name: `settlementInfos.${index}.accountImageUrl`,
            purpose: 'SELLERS',
            disabled,
          }}
          control={control}
        />

        <TextField
          control={control}
          name={`settlementInfos.${index}.accountHolderName`}
          label={'예금주명'}
          placeholder={'예금주명'}
          disabled={disabled}
        />
      </div>

      <div className={'mt-xl text-right'}>
        <Popconfirm
          title='정산정보를 삭제하시겠습니까?'
          onConfirm={onRemove}
          okText='네'
          cancelText='아니오'
        >
          <Button
            htmlType={'button'}
            size={'small'}
            icon={<DeleteOutlined />}
            disabled={disabled}
          >
            정산정보 삭제
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
}

export default SettlementItemField;
