import dayjs from 'dayjs';
import { useMemo } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { OrderGroupDepositFilterQuery } from '../../../schema/types';
import { useAdminOrderGroupDepositsQuery } from '../graphql/deposit.generated';
import { DepositListSearchParams } from '../types';

function useDepositListQuery() {
  const { searchParams } = useRouteSearchParams<DepositListSearchParams>();

  const filter: OrderGroupDepositFilterQuery = useMemo(() => {
    if (searchParams.summaryStatus) {
      return {
        statuses: [searchParams.summaryStatus],
      };
    } else {
      return {
        code: searchParams.code,
        depositorName: searchParams.depositorName,
        searchKeyword: searchParams.searchKeyword,
        statuses: searchParams.statuses,
        creatorOperatorIds: searchParams.creatorOperatorIds?.map((id) =>
          Number(id)
        ),
        period:
          searchParams.startedAt || searchParams.endedAt
            ? {
                type: 'REQUESTED_AT',
                startedAt: searchParams.startedAt
                  ? dayjs(searchParams.startedAt).startOf('date').toISOString()
                  : undefined,
                endedAt: searchParams.endedAt
                  ? dayjs(searchParams.endedAt).endOf('date').toISOString()
                  : undefined,
              }
            : undefined,
      };
    }
  }, [searchParams]);

  const currentPage = Number(searchParams.currentPage) - 1 || 0;
  const pageSize = Number(searchParams.pageSize) || 50;

  return useAdminOrderGroupDepositsQuery({
    variables: {
      filter,
      pagination: {
        skip: currentPage * pageSize,
        take: pageSize,
      },
    },
  });
}

export default useDepositListQuery;
