import { useEffect, useState } from 'react';

import { useAdminMarketInSettlementListLazyQuery } from '../graphql/market.generated';
import { useAdminSettlementLazyQuery } from '../graphql/settlement.generated';
import { useAdminVendorInSettlementListLazyQuery } from '../graphql/vendor.generated';
import {
  MarketInSettlementListDataType,
  SettlementListTableDataType,
  VendorInSettlementListDataType,
} from '../types';

type useCreateSettlementSheetModalProps = {
  selectedSettlements: SettlementListTableDataType[];
};

function useCreateSettlementSheetModal({
  selectedSettlements,
}: useCreateSettlementSheetModalProps) {
  const selectedSettlementId = selectedSettlements[0].id;
  const [marketId, setMarketId] = useState<number>();
  const [market, setMarket] = useState<MarketInSettlementListDataType>();
  const [vendorId, setVendorId] = useState<number>();
  const [vendor, setVendor] = useState<VendorInSettlementListDataType>();

  const [fetchMarket] = useAdminMarketInSettlementListLazyQuery();
  const [fetchVendor] = useAdminVendorInSettlementListLazyQuery();
  const [fetchSettlement] = useAdminSettlementLazyQuery();

  const initSettlementModal = async (selectedSettlementId: number) => {
    const { data } = await fetchSettlement({
      variables: {
        adminSettlementId: selectedSettlementId,
      },
    });

    const settlement = data?.adminSettlement;

    if (settlement) {
      const { marketId, vendorId } = settlement;

      marketId && setMarketId(marketId);
      vendorId && setVendorId(vendorId);
    }
  };

  const fetchMarketData = async (marketId: number) => {
    const { data } = await fetchMarket({
      variables: {
        marketId,
      },
    });

    if (data?.adminMarket) {
      setMarket(data.adminMarket);
    }
  };

  const fetchVendorData = async (vendorId: number) => {
    const { data } = await fetchVendor({
      variables: {
        vendorId,
      },
    });

    if (data?.adminVendorWithBrands) {
      setVendor(data.adminVendorWithBrands);
    }
  };

  useEffect(() => {
    if (selectedSettlementId) {
      initSettlementModal(selectedSettlementId);
    }
  }, []);

  useEffect(() => {
    if (marketId) {
      fetchMarketData(marketId);
    }
  }, [marketId]);

  useEffect(() => {
    if (vendorId) {
      fetchVendorData(vendorId);
    }
  }, [vendorId]);

  return {
    setMarketId,
    market,
    setVendorId,
    vendor,
  };
}

export default useCreateSettlementSheetModal;
