import { ExclamationCircleFilled } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { App } from 'antd';
import qs from 'qs';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import NotFound from '../common/components/404';
import HistoryModal from '../common/components/History/HistoryModal';
import PageLoader from '../common/components/PageLoader';
import { KEMIST_BASE_URL } from '../common/constants/env';
import { HistoriesDocument } from '../common/graphql/history.generated';
import {
  extractBadRequestError,
  hasDetailErrorCode,
  removeCache,
} from '../common/utils/apollo';
import MarketPreviewModal from '../domain/market/components/MarketPreviewModal';
import UpdateMarketForm from '../domain/market/components/UpdateMarketForm';
import {
  useAdminCreateKemistProductFromMarketMutation,
  useAdminDeleteMarketMutation,
  useAdminKemistProductsQuery,
  useAdminMarketQuery,
  useUpdateMarketMutation,
} from '../domain/market/graphql/market.generated';
import { AdminMarketsDocument } from '../domain/market/graphql/markets.generated';
import { useAdminSellerSettlementsQuery } from '../domain/market/graphql/seller.generated';
import {
  toMarketsVariables,
  toUpdateMarketFormValues,
  toUpdateMarketVariables,
} from '../domain/market/helper';
import {
  MarketListStringifySearchParams,
  UpdateMarketFormValues,
} from '../domain/market/types';

function MarketDetail() {
  const { message, modal } = App.useApp();
  const params = useParams();
  const navigate = useNavigate();
  const client = useApolloClient();
  const location = useLocation();

  const [openCheckList, setOpenCheckList] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const marketId = Number(params.marketId);
  const marketSearchParams = location?.state as MarketListStringifySearchParams;

  const [createKemistProduct] = useAdminCreateKemistProductFromMarketMutation();
  const [updateMarket, { loading: updateLoading }] = useUpdateMarketMutation();
  const [deleteMarket, { data: deleteRes, loading: isDeleteLoading }] =
    useAdminDeleteMarketMutation();

  const {
    data: marketData,
    error,
    loading,
  } = useAdminMarketQuery({
    variables: { marketId },
    // 삭제 중, 후에는 쿼리하지 않도록 막는다.
    skip: isDeleteLoading || !!deleteRes,
  });

  const { data: kemistProductsData } = useAdminKemistProductsQuery({
    variables: { marketId },
  });

  const MarketsVariables = useMemo(() => {
    return toMarketsVariables(marketSearchParams);
  }, [marketSearchParams]);
  const everyEventEditable = useMemo(() => {
    return marketData?.adminMarket.events?.every((event) => event.editable);
  }, [marketData]);
  const everyEventDeletable = useMemo(() => {
    return marketData?.adminMarket.events?.every((event) => event.deletable);
  }, [marketData]);

  const kemistProducts = kemistProductsData?.adminKemistProducts.products;
  const hasKemistProduct = !!kemistProducts?.length;
  const hasSeller = marketData?.adminMarket.seller?.id;
  const { data: seller, loading: sellerLoading } =
    useAdminSellerSettlementsQuery({
      variables: { adminSellerId: marketData?.adminMarket.seller?.id || 0 },
      skip: !hasSeller,
    });

  const market = marketData?.adminMarket;

  const goToMarkets = useCallback(() => {
    if (marketSearchParams) {
      navigate({
        pathname: '/markets',
        search: qs.stringify(marketSearchParams),
      });
      return;
    }
    navigate('/markets');
  }, [marketSearchParams, navigate]);

  const afterKemiMarketSubmit = useCallback(() => {
    return new Promise((resolve, reject) => {
      modal.success({
        centered: true,
        title: '마켓수정완료',
        content: '마켓이 수정되었습니다. 바로 케미에 상품을 등록하시겠습니까?',
        okText: '네',
        cancelText: '나중에',
        okCancel: true,
        onOk() {
          createKemistProduct({
            variables: { marketId },
            onCompleted(data) {
              data.adminCreateKemistProductFromMarket.productId &&
                window.open(
                  `${KEMIST_BASE_URL}/products/${data.adminCreateKemistProductFromMarket.productId}`,
                  '_blank'
                );
              resolve(true);
            },
            onError() {
              reject({ message: '케미스트 상품등록을 실패하였습니다.' });
            },
          });
        },
        onCancel() {
          resolve(true);
        },
      });
    });
  }, [createKemistProduct, marketId, modal]);

  const handleSubmit = async (
    data: UpdateMarketFormValues,
    onCompleted?: () => void
  ) => {
    const payload = toUpdateMarketVariables(data);

    /**
     * 케미스트 등록 알럿은 판매채널이 케미로 변경되었을 때만 노출한다.
     */
    const isSellingChannelToKemi =
      data.sellingChannel === 'KEMI' && market?.sellingChannel !== 'KEMI';

    await updateMarket({
      variables: { updateMarketInput: { id: marketId, data: payload } },
      async onCompleted() {
        try {
          message.success('저장이 완료되었습니다.');

          if (isSellingChannelToKemi) {
            await afterKemiMarketSubmit();
          }
          (onCompleted || goToMarkets)();
        } catch (e: any) {
          message.error(e?.message);
        }
      },
      refetchQueries: marketSearchParams
        ? [
            HistoriesDocument,
            { query: AdminMarketsDocument, variables: MarketsVariables },
          ]
        : [HistoriesDocument],
      onError({ graphQLErrors }) {
        const noMarketError = extractBadRequestError(graphQLErrors);

        if (noMarketError) {
          message.error(noMarketError.message);

          const id = client.cache.identify({
            id: marketId,
            __typename: 'MarketGraphqlType',
          });
          client.cache.evict({ id });
        } else {
          message.error('저장을 실패하였습니다.');
        }
      },
    });
  };

  const removeMarket = () => {
    if (!everyEventDeletable) {
      modal.error({
        title: '마켓삭제 실패',
        width: 400,
        content: '해당마켓을 참조한 출고요청건이 있어 마켓삭제가 불가능합니다.',
        okText: '확인',
        centered: true,
      });

      return;
    }

    if (hasKemistProduct) {
      modal.error({
        title: '마켓삭제 실패',
        width: 400,
        content:
          '해당마켓을 참조한 케미스트 상품이 있어 마켓삭제가 불가능합니다.',
        okText: '확인',
        centered: true,
      });

      return;
    }

    modal.confirm({
      icon: <ExclamationCircleFilled />,
      title: '마켓삭제',
      content: (
        <>
          <p>마켓 삭제 시, 마켓 정보를 되돌릴 수 없습니다.</p>
          <p>삭제를 진행하시겠습니까?</p>
        </>
      ),
      async onOk() {
        await deleteMarket({
          variables: { marketId },
          onCompleted() {
            message.success('마켓이 삭제되었습니다.');

            goToMarkets();
          },
          update(cache) {
            const cacheId = cache.identify({
              id: marketId,
              __typename: 'MarketGraphqlType',
            });
            cache.evict({ id: cacheId });
          },
          refetchQueries: marketSearchParams
            ? [
                HistoriesDocument,
                { query: AdminMarketsDocument, variables: MarketsVariables },
              ]
            : [HistoriesDocument],
          onError({ graphQLErrors }) {
            const hasPromotionError = hasDetailErrorCode(
              graphQLErrors,
              'MARKET_HAS_PROMOTION'
            );

            if (hasPromotionError) {
              modal.error({
                title: '마켓삭제 실패',
                content:
                  '해당마켓을 참조한 출고요청건이 있어 마켓삭제가 불가능합니다.',
                centered: true,
              });
              return;
            }

            const badRequestError = extractBadRequestError(graphQLErrors);

            if (badRequestError) {
              message.error(badRequestError.message);

              removeCache(client.cache, {
                id: marketId,
                __typename: 'MarketGraphqlType',
              });
            } else {
              message.error('마켓 삭제를 실패하였습니다.');
            }
          },
        });
      },
      okText: '네',
      cancelText: '아니오',
      centered: true,
    });
  };

  if (error) {
    return <NotFound subTitle={'마켓을 찾을 수 없습니다.'} />;
  }

  if (loading || sellerLoading) {
    return <PageLoader />;
  }

  return (
    <div className={'bg-white'}>
      <div>
        {market && (!hasSeller || seller) && (
          <UpdateMarketForm
            warningMessage={
              !everyEventDeletable
                ? everyEventEditable
                  ? '해당마켓을 참조한 출고요청건이 있어 셀러명, 브랜드명 변경 및 일부 이벤트 삭제가 불가능합니다.'
                  : '해당마켓을 참조한 출고요청완료건이 있어 셀러명, 브랜드명 변경 및 일부 이벤트 변경이 불가능합니다.'
                : ''
            }
            onSubmit={handleSubmit}
            defaultValues={toUpdateMarketFormValues(
              market,
              seller?.adminSeller
            )}
            onHistoryClick={() => setOpenHistory(true)}
            onCheckListClick={() => setOpenCheckList(true)}
            marketId={market.id}
            onRemoveClick={removeMarket}
            goToMarkets={goToMarkets}
            kemistProducts={kemistProducts || []}
          />
        )}
      </div>
      <HistoryModal
        uiType={'MARKET'}
        id={marketId}
        onClose={() => setOpenHistory(false)}
        open={openHistory}
      />
      <MarketPreviewModal
        open={openCheckList}
        onCancel={() => setOpenCheckList(false)}
        marketId={marketId}
      />
      {(updateLoading || isDeleteLoading) && <PageLoader />}
    </div>
  );
}

export default MarketDetail;
