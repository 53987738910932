import { Descriptions, Input, Select, Typography } from 'antd';
import { debounce } from 'lodash-es';
import { ChangeEvent } from 'react';

import ManagerSelect from '../../../common/components/Fields/ManagerSelect';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { EProductGroupStatus, EProductGroupType } from '../../../schema/types';
import { PRODUCT_GROUP_TYPE } from '../const';
import { Categories, ProductGroupsListSearchParams } from '../types';

import SearchCategoriesSelect from './Fields/SearchCategoriesSelect';
import ProductStatusesSelect from './ProductStatusesSelect';

type ListSearchFormProps = {
  onSearch: (params: ProductGroupsListSearchParams) => void;
};

export default function ListSearchForm({ onSearch }: ListSearchFormProps) {
  const { searchParams } =
    useRouteSearchParams<ProductGroupsListSearchParams>();

  const {
    managerIds: managerIdsInParams,
    categoryId: categoryIdInParams,
    upperCategoryId: upperCategoryIdInParams,
    uppermostCategoryId: uppermostCategoryIdInParams,
    statuses: statusesInParams,
    productGroupType: productGroupTypeInParams,
  } = searchParams;

  const managerIds = managerIdsInParams?.map((manager) => Number(manager));

  const categoryId = categoryIdInParams
    ? Number(categoryIdInParams)
    : undefined;
  const upperCategoryId = upperCategoryIdInParams
    ? Number(upperCategoryIdInParams)
    : undefined;
  const uppermostCategoryId = uppermostCategoryIdInParams
    ? Number(uppermostCategoryIdInParams)
    : undefined;

  const handleManager = (value: number[]) => {
    onSearch({
      search: undefined,
      managerIds: value,
      currentPage: undefined,
    });
  };

  const handleCategories = ({
    uppermostCategoryId,
    upperCategoryId,
    categoryId,
  }: Categories) => {
    onSearch({
      search: undefined,
      uppermostCategoryId,
      upperCategoryId,
      categoryId,
      currentPage: undefined,
    });
  };

  const handleStatuses = (statuses: EProductGroupStatus[]) => {
    onSearch({
      search: undefined,
      statuses: statuses,
      currentPage: undefined,
    });
  };

  const handleProductGroupType = (type: EProductGroupType) => {
    onSearch({
      search: undefined,
      productGroupType: type,
      currentPage: undefined,
    });
  };

  const handleSearch = debounce((ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;

    onSearch({
      search: value.trim(),
      uppermostCategoryId,
      upperCategoryId,
      categoryId,
      currentPage: undefined,
    });
  }, 500);

  return (
    <div className='flex flex-col gap-4 bg-white p-6'>
      <Typography.Title level={4} className='m-0'>
        검색
      </Typography.Title>
      <Input
        placeholder='상품명, 상품ID, 업체명, 브랜드명 입력'
        style={{ width: 540 }}
        defaultValue={searchParams.search}
        onChange={handleSearch}
      />
      {!searchParams.search && (
        <Descriptions
          bordered
          column={1}
          labelStyle={{ width: '200px' }}
          size='middle'
          contentStyle={{ padding: '8px 24px' }}
        >
          <Descriptions.Item label='담당자'>
            <ManagerSelect
              style={{ width: 326 }}
              mode='multiple'
              allowClear
              defaultActiveFirstOption={false}
              suffixIcon={null}
              notFoundContent={null}
              onChange={handleManager}
              value={managerIds}
            />
          </Descriptions.Item>
          <Descriptions.Item label='거래상태'>
            <ProductStatusesSelect
              onChange={handleStatuses}
              statuses={statusesInParams}
            />
          </Descriptions.Item>
          <Descriptions.Item label='상품구분'>
            <Select
              options={PRODUCT_GROUP_TYPE}
              value={productGroupTypeInParams}
              onChange={handleProductGroupType}
              className='w-[240px]'
              placeholder='상품구분 선택'
              allowClear
            />
          </Descriptions.Item>
          <Descriptions.Item label='카테고리'>
            <SearchCategoriesSelect
              onChange={handleCategories}
              categoryId={categoryId}
              upperCategoryId={upperCategoryId}
              uppermostCategoryId={uppermostCategoryId}
            />
          </Descriptions.Item>
        </Descriptions>
      )}
    </div>
  );
}
