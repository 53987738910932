import { Button, Typography } from 'antd';
import { useState } from 'react';

import { LOGOUT_URL } from '../../domain/auth/url';

export default function LogoutButton() {
  const [isNavigating, setIsNavigating] = useState(false);

  return (
    <Button
      size={'large'}
      type='link'
      title='로그아웃'
      href={LOGOUT_URL}
      onClick={() => setIsNavigating(true)}
      disabled={isNavigating}
    >
      <Typography.Text className={'text-white'}>로그아웃</Typography.Text>
    </Button>
  );
}
