import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import CheckboxField from '../../../common/components/Fields/CheckboxField';
import Label from '../../../common/components/Fields/Label';
import RadioField from '../../../common/components/Fields/RadioField';
import { PRE_PAYMENT_OPTIONS } from '../const';
import { usePromotionProductQuery } from '../graphql/productGroups.generated';
import { PromotionFormValues } from '../types';

import NumberWithUnknownField from './NumberWithUnknownField';

function PromotionProductExpandable({
  index,
  className,
  disabled,
}: {
  index: number;
  className: string;
  disabled?: boolean;
}) {
  const { control, setValue, watch, resetField } =
    useFormContext<PromotionFormValues>();
  const [promotionProduct, settlementType] = watch([
    `promotionProducts.${index}`,
    'settlementType',
  ]);

  const setCount = promotionProduct.setCount;

  const { data: productData } = usePromotionProductQuery({
    variables: {
      adminProductId:
        typeof promotionProduct.originalProductId === 'number'
          ? promotionProduct.originalProductId
          : 0,
    },
    skip: typeof promotionProduct.originalProductId !== 'number',
  });

  const sellerSupplyPrice = useMemo(() => {
    if (!settlementType) return undefined;
    if (settlementType === 'BUSINESS') {
      return (
        (productData?.adminProduct?.businessSellerSupplyPrice || 0) *
        promotionProduct.setCount
      );
    } else {
      return (
        (productData?.adminProduct?.freelanceSellerSupplyPrice || 0) *
        promotionProduct.setCount
      );
    }
  }, [settlementType, productData, promotionProduct.setCount]);

  const wiredSupplyPrice = useMemo(() => {
    if (!promotionProduct.inHouseBuyPrice) return undefined;

    return (promotionProduct.inHouseBuyPrice || 0) * promotionProduct.setCount;
  }, [promotionProduct.setCount, promotionProduct.inHouseBuyPrice]);

  useEffect(() => {
    if (productData) {
      setValue(
        `promotionProducts.${index}.inHouseBuyPrice`,
        (productData.adminProduct?.inHousePurchasePrice || 0) * setCount
      );
    }
  }, [productData, setValue, setCount]);

  useEffect(() => {
    if (promotionProduct.isFreeProvided) {
      resetField(`promotionProducts.${index}.inHouseBuyPrice`);

      resetField(`promotionProducts.${index}.isSellerBearing`);
      resetField(`promotionProducts.${index}.sellerBearingCost`);
      resetField(`promotionProducts.${index}.isPrepayment`);

      resetField(`promotionProducts.${index}.isWiredBearing`);
      resetField(`promotionProducts.${index}.wiredBearingCost`);
    }
  }, [promotionProduct.isFreeProvided, resetField]);

  return (
    <div className={`w-[608px] ${className}`}>
      <NumberWithUnknownField
        disabled={disabled}
        control={control}
        name={`promotionProducts.${index}.inHouseBuyPrice`}
        className='w-[186px]'
        prefix='자사매입가'
        placeholder='금액입력'
        onUnknownClick={() =>
          setValue(`promotionProducts.${index}.inHouseBuyPrice`, null, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
          })
        }
      />
      <div className='mt-2 grid grid-cols-3 gap-6'>
        <div className='flex flex-col gap-2'>
          <CheckboxField
            disabled={disabled}
            control={control}
            name={`promotionProducts.${index}.isSellerBearing`}
            onChange={(ev) => {
              if (ev.target.checked) {
                setValue(
                  `promotionProducts.${index}.sellerBearingCost`,
                  sellerSupplyPrice
                );
              } else {
                setValue(
                  `promotionProducts.${index}.sellerBearingCost`,
                  undefined
                );
              }
            }}
            value={promotionProduct.isSellerBearing}
          >
            <Label
              tooltip={
                '기존에 등록된 품목을 선택한 경우에는 셀러공급가가 자동으로 기입됩니다.'
              }
            >
              셀러 부담
            </Label>
          </CheckboxField>
          {promotionProduct.isSellerBearing && (
            <>
              <NumberWithUnknownField
                disabled={disabled}
                control={control}
                name={`promotionProducts.${index}.sellerBearingCost`}
                placeholder='금액입력'
                onUnknownClick={() =>
                  setValue(
                    `promotionProducts.${index}.sellerBearingCost`,
                    null,
                    {
                      shouldValidate: true,
                      shouldTouch: true,
                      shouldDirty: true,
                    }
                  )
                }
              />
              <RadioField
                disabled={disabled}
                control={control}
                name={`promotionProducts.${index}.isPrepayment`}
                options={PRE_PAYMENT_OPTIONS}
              />
            </>
          )}
        </div>
        <div className='flex flex-col gap-2'>
          <CheckboxField
            disabled={disabled}
            control={control}
            name={`promotionProducts.${index}.isWiredBearing`}
            onChange={(ev) => {
              setValue(
                `promotionProducts.${index}.wiredBearingCost`,
                ev.target.checked ? wiredSupplyPrice : undefined
              );
            }}
            value={promotionProduct.isWiredBearing}
          >
            <Label
              tooltip={
                '기존에 등록한 품목을 선택한 경우에는 자사매입가가 자동으로 기입됩니다.'
              }
            >
              와이어드 부담
            </Label>
          </CheckboxField>
          {promotionProduct.isWiredBearing && (
            <NumberWithUnknownField
              disabled={disabled}
              control={control}
              name={`promotionProducts.${index}.wiredBearingCost`}
              placeholder='금액입력'
              onUnknownClick={() =>
                setValue(`promotionProducts.${index}.wiredBearingCost`, null, {
                  shouldValidate: true,
                  shouldTouch: true,
                  shouldDirty: true,
                })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PromotionProductExpandable;
