import { ApolloCache } from '@apollo/client';
import { App } from 'antd';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useMeQuery } from '../../../common/graphql/me.generated';
import { useFormError } from '../../../common/hooks/useFormError';
import { deleteCacheFields } from '../../../common/utils/apollo';
import { CreatePromotionEventInputGraphqlType } from '../../../schema/types';
import {
  AdminCountPromotionStatusesDocument,
  AdminPromotionReleasesDocument,
  useAdminCreatePromotionEventMutation,
} from '../graphql/promotionRelease.generated';
import { getDefaultEventValues } from '../helper';
import { PromotionEventFormValues } from '../types';
import { promotionEventResolver } from '../validator/createPromotionValidator';

function useCreatePromotionEvent({ onClose }: { onClose: () => void }) {
  const { message, modal } = App.useApp();
  const { data: userData } = useMeQuery();

  const [createPromotionEventMutation] = useAdminCreatePromotionEventMutation();
  const { onInvalid } = useFormError();
  const methods = useForm<PromotionEventFormValues>({
    defaultValues: {
      ...getDefaultEventValues(),
    },
    resolver: promotionEventResolver,
  });
  const { setValue } = methods;

  useEffect(() => {
    if (userData) {
      setValue('managerId', userData.adminMe.id);
    }
  }, [userData, setValue]);

  const createPromotionEvent = useCallback(
    async (data: PromotionEventFormValues) => {
      const promotionReleases: CreatePromotionEventInputGraphqlType['promotionReleases'] =
        data.promotionReleases.map((release) => ({
          ...release,
          note: data.note,
          scheduledReleaseAt: data.scheduledReleaseAt || null,
          sellerId: data.sellerId,
          sellerSettlementInfoId: data.sellerSettlementInfoId,
        }));

      await createPromotionEventMutation({
        variables: {
          promotionEventData: {
            managerId: data.managerId,
            marketId: data.marketId,
            marketEventId: data.marketEventId,
            promotionType: data.promotionType,
            promotionReleases,
          },
        },
        refetchQueries: [
          AdminPromotionReleasesDocument,
          AdminCountPromotionStatusesDocument,
        ],
        update: (cache: ApolloCache<any>) =>
          deleteCacheFields(cache, ['adminPromotionReleases']),
        onCompleted() {
          void message.success('저장이 완료되었습니다.');
          onClose();
        },
        onError() {
          void message.error('저장을 실패하였습니다.');
        },
      });
    },
    [createPromotionEventMutation, message, onClose]
  );

  const onSubmit = methods.handleSubmit((data) => {
    void modal.confirm({
      title: '출고요청',
      content: '출고요청을 진행하시겠습니까?',
      onOk: () => createPromotionEvent(data),
    });
  }, onInvalid);

  return { methods, onSubmit };
}

export default useCreatePromotionEvent;
