import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useProductGroupLazyQuery } from '../graphql/productGroup.generated';
import { Market } from '../types';

export default function useGetReferencedMarkets() {
  const params = useParams();
  const productGroupId = Number(params.productGroupId);
  const [markets, setMarkets] = useState<Market[]>([]);

  const [fetch] = useProductGroupLazyQuery({
    variables: {
      adminProductGroupId: productGroupId,
    },
  });

  const setMarketsAfterFetch = useCallback(async () => {
    const { data } = await fetch();

    setMarkets(data?.adminProductGroup.markets ?? []);
  }, [fetch]);

  const isReferencedCanceled = useMemo(
    () => markets.every((market) => market.status === 'CANCELED'),
    [markets]
  );

  const editableProduct = !markets.length || isReferencedCanceled;

  useEffect(() => {
    if (productGroupId) {
      setMarketsAfterFetch();
    } else {
      setMarkets([]);
    }
  }, [productGroupId, setMarketsAfterFetch]);

  return {
    markets,
    editableProduct,
  };
}
