import { App } from 'antd';

import {
  useCreateProductGroupWithProductMutation,
  useProductGroupLazyQuery,
} from '../graphql/productGroup.generated';
import { ProductGroupsDocument } from '../graphql/productGroups.generated';
import { duplicateMapper } from '../helper/mapper';

export default function useDuplicateProductGroup() {
  const { message } = App.useApp();
  const [mutate] = useCreateProductGroupWithProductMutation();
  const [fetch] = useProductGroupLazyQuery();

  const duplicateProductGroup = async (id: number) => {
    const { data } = await fetch({
      variables: {
        adminProductGroupId: id,
      },
    });

    if (!data) return;

    const { adminProductGroup } = data;

    const createProductGroupInput = duplicateMapper(adminProductGroup);

    await mutate({
      variables: { productGroupData: createProductGroupInput },
      refetchQueries: [ProductGroupsDocument],
      onCompleted() {
        message.success('복제되었습니다.');
      },
      onError() {
        message.error('복제를 실패하였습니다.');
      },
    });
  };

  return {
    duplicateProductGroup,
  };
}
