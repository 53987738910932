import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SellersQueryVariables = Types.Exact<{
  sellersInput: Types.SellersGraphqlInputType;
}>;


export type SellersQuery = { __typename?: 'Query', adminSellers: { __typename?: 'SellersPayload', totalCount: number, sellers: Array<{ __typename?: 'SellerGraphqlType', id: number, name: string, grade?: number | null, kemiUserId?: string | null, phoneNumber: string, settlementInfos: Array<{ __typename?: 'SellerSettlementInfoGraphqlType', type: Types.ESellerSettlementType, sellerName?: string | null, companyName?: string | null, isDefault: boolean }>, emails: Array<{ __typename?: 'SellerEmailGraphqlType', email: string, id: number, isDefault: boolean }>, links: Array<{ __typename?: 'SellerLinkGraphqlType', id: number, url: string, isDefault: boolean }>, manager: { __typename?: 'ManagerGraphqlType', id: number, name: string } }> } };

export type SellersByFormQueryVariables = Types.Exact<{
  sellersInput: Types.SellersGraphqlInputType;
}>;


export type SellersByFormQuery = { __typename?: 'Query', adminSellers: { __typename?: 'SellersPayload', sellers: Array<{ __typename?: 'SellerGraphqlType', id: number, name: string }> } };

export type UpdateSellerPartialMutationVariables = Types.Exact<{
  updateSellerPartialInput: Types.UpdateSellerPartialGraphqlInputType;
}>;


export type UpdateSellerPartialMutation = { __typename?: 'Mutation', adminUpdateSellerPartial: { __typename?: 'UpdateSellerPartialPayload', updatedSeller: { __typename?: 'SellerGraphqlType', id: number, grade?: number | null, kemiUserId?: string | null, manager: { __typename?: 'ManagerGraphqlType', id: number, name: string } } } };


export const SellersDocument = gql`
    query Sellers($sellersInput: SellersGraphqlInputType!) {
  adminSellers(sellersInput: $sellersInput) {
    totalCount
    sellers {
      id
      name
      grade
      settlementInfos {
        type
        sellerName
        companyName
        isDefault
      }
      kemiUserId
      phoneNumber
      emails {
        email
        id
        isDefault
      }
      links {
        id
        url
        isDefault
      }
      manager {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useSellersQuery__
 *
 * To run a query within a React component, call `useSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersQuery({
 *   variables: {
 *      sellersInput: // value for 'sellersInput'
 *   },
 * });
 */
export function useSellersQuery(baseOptions: Apollo.QueryHookOptions<SellersQuery, SellersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellersQuery, SellersQueryVariables>(SellersDocument, options);
      }
export function useSellersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellersQuery, SellersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellersQuery, SellersQueryVariables>(SellersDocument, options);
        }
export type SellersQueryHookResult = ReturnType<typeof useSellersQuery>;
export type SellersLazyQueryHookResult = ReturnType<typeof useSellersLazyQuery>;
export type SellersQueryResult = Apollo.QueryResult<SellersQuery, SellersQueryVariables>;
export const SellersByFormDocument = gql`
    query SellersByForm($sellersInput: SellersGraphqlInputType!) {
  adminSellers(sellersInput: $sellersInput) {
    sellers {
      id
      name
    }
  }
}
    `;

/**
 * __useSellersByFormQuery__
 *
 * To run a query within a React component, call `useSellersByFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellersByFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellersByFormQuery({
 *   variables: {
 *      sellersInput: // value for 'sellersInput'
 *   },
 * });
 */
export function useSellersByFormQuery(baseOptions: Apollo.QueryHookOptions<SellersByFormQuery, SellersByFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellersByFormQuery, SellersByFormQueryVariables>(SellersByFormDocument, options);
      }
export function useSellersByFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellersByFormQuery, SellersByFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellersByFormQuery, SellersByFormQueryVariables>(SellersByFormDocument, options);
        }
export type SellersByFormQueryHookResult = ReturnType<typeof useSellersByFormQuery>;
export type SellersByFormLazyQueryHookResult = ReturnType<typeof useSellersByFormLazyQuery>;
export type SellersByFormQueryResult = Apollo.QueryResult<SellersByFormQuery, SellersByFormQueryVariables>;
export const UpdateSellerPartialDocument = gql`
    mutation UpdateSellerPartial($updateSellerPartialInput: UpdateSellerPartialGraphqlInputType!) {
  adminUpdateSellerPartial(updateSellerPartialInput: $updateSellerPartialInput) {
    updatedSeller {
      id
      grade
      kemiUserId
      manager {
        id
        name
      }
    }
  }
}
    `;
export type UpdateSellerPartialMutationFn = Apollo.MutationFunction<UpdateSellerPartialMutation, UpdateSellerPartialMutationVariables>;

/**
 * __useUpdateSellerPartialMutation__
 *
 * To run a mutation, you first call `useUpdateSellerPartialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSellerPartialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSellerPartialMutation, { data, loading, error }] = useUpdateSellerPartialMutation({
 *   variables: {
 *      updateSellerPartialInput: // value for 'updateSellerPartialInput'
 *   },
 * });
 */
export function useUpdateSellerPartialMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSellerPartialMutation, UpdateSellerPartialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSellerPartialMutation, UpdateSellerPartialMutationVariables>(UpdateSellerPartialDocument, options);
      }
export type UpdateSellerPartialMutationHookResult = ReturnType<typeof useUpdateSellerPartialMutation>;
export type UpdateSellerPartialMutationResult = Apollo.MutationResult<UpdateSellerPartialMutation>;
export type UpdateSellerPartialMutationOptions = Apollo.BaseMutationOptions<UpdateSellerPartialMutation, UpdateSellerPartialMutationVariables>;