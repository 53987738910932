import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SorterResult } from 'antd/es/table/interface';
import { Link, generatePath } from 'react-router-dom';

import { formatNumber } from '../../../common/utils/number';
import { ROUTES } from '../../../routes/const';
import { APP_HEADER_HEIGHT } from '../../../theme/const';
import { SELLER_SETTLEMENT_TYPES } from '../const';
import { SellersQuery } from '../graphql/sellers.generated';
import { SellerListSearchParams } from '../types';

import EditableGradeSelectCell from './EditableGradeSelectCell';
import EditableKemiUserIdCell from './EditableKemiUserIdCell';
import EditableManagerSelectCell from './EditableManagerSelectCell';

type Sellers = Exclude<SellersQuery['adminSellers'], undefined | null>;
type DataType = Exclude<
  Exclude<Sellers['sellers'], undefined | null>['0'],
  undefined | null
>;

type SellersTableProps = {
  data: DataType[];
  editable: boolean;
  loading: boolean;
  onSearch: (params: SellerListSearchParams) => void;
};

function SellersTable({
  data,
  editable,
  loading,
  onSearch,
}: SellersTableProps) {
  const handleSort = (sorter: SorterResult<DataType>) => {
    switch (sorter.order) {
      case 'ascend':
        onSearch({ sortingField: 'grade', sortingOrder: 'ASC' });
        break;
      case 'descend':
        onSearch({ sortingField: 'grade', sortingOrder: 'DESC' });
        break;
      default:
        onSearch({ sortingField: undefined, sortingOrder: undefined });
    }
  };

  return (
    <Spin tip='Loading...' spinning={loading}>
      <Table
        sticky={{
          offsetHeader: APP_HEADER_HEIGHT,
        }}
        size='small'
        className={'mt-1'}
        columns={columns(editable)}
        dataSource={data || []}
        pagination={false}
        scroll={{ x: 1800 }}
        onRow={() => ({
          className:
            '[&_.editable-cell]:w-full [&_.editable-cell]:border-[1px] [&_.editable-cell]:border-solid [&_.editable-cell]:border-transparent hover:[&_.editable-cell]:border-[1px] hover:[&_.editable-cell]:border-solid hover:[&_.editable-cell]:border-gray-400',
        })}
        onChange={(_, __, sorter) => {
          handleSort(sorter as SorterResult<DataType>);
        }}
      />
    </Spin>
  );
}

const columns: (editable?: boolean) => ColumnsType<DataType> = (editable) => [
  {
    title: '셀러ID',
    key: 'id',
    dataIndex: 'id',
    fixed: 'left',
    width: 150,
  },
  {
    title: '셀러명',
    width: 240,
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
    render: (_, record) => (
      <Link
        to={generatePath(ROUTES.SELLER_DETAIL.path, { sellerId: record.id })}
      >
        {record.name}
      </Link>
    ),
  },
  {
    title: '판매유형',
    key: 'type',
    width: 150,
    dataIndex: 'type',
    render(_, record) {
      const settlementInfo = record.settlementInfos.find(
        (info) => info.isDefault
      );
      return (
        SELLER_SETTLEMENT_TYPES.find(
          ({ value }) => value === settlementInfo?.type
        )?.label || ''
      );
    },
  },
  {
    title: '담당매니저',
    key: 'manager',
    width: 150,
    dataIndex: 'manager',
    render: (_, record) =>
      editable ? (
        <EditableManagerSelectCell
          initialValue={record.manager.id}
          id={record.id}
          name={record.manager.name}
        />
      ) : (
        record.manager.name
      ),
  },
  {
    title: '등급',
    key: 'grade',
    dataIndex: 'grade',
    sorter: true,
    width: 150,
    render: (_, record) =>
      editable ? (
        <EditableGradeSelectCell
          id={record.id}
          initialValue={record.grade || null}
        />
      ) : (
        record.grade || '-'
      ),
  },
  {
    title: '케미회원아이디',
    key: 'kemiUserId',
    dataIndex: 'kemiUserId',
    width: 240,
    render(_, record) {
      return editable ? (
        <EditableKemiUserIdCell
          id={record.id}
          initialValue={record.kemiUserId || ''}
        />
      ) : (
        record.kemiUserId || '-'
      );
    },
  },
  {
    title: '휴대폰번호',
    key: 'phoneNumber',
    dataIndex: 'phoneNumber',
    width: 150,
    render(_, record) {
      return formatNumber(record.phoneNumber, '###-####-####');
    },
  },
  {
    title: '이메일주소',
    dataIndex: 'emails',
    key: 'emails',
    width: 240,
    render(_, record) {
      const email = record.emails.find(({ isDefault }) => isDefault);
      return email?.email || '-';
    },
  },
  {
    title: '실명',
    key: 'sellerName',
    width: 150,
    dataIndex: 'sellerName',
    render(_, record) {
      const freelancerInfos = record.settlementInfos.filter(
        (info) => info.type === 'FREELANCER' && !!info.sellerName
      );

      if (!freelancerInfos.length) {
        return '-';
      }

      return freelancerInfos.map((info) => info.sellerName).join(', ');
    },
  },
  {
    title: '사업자명',
    key: 'companyName',
    width: 240,
    dataIndex: 'companyName',
    render(_, record) {
      const companyInfos = record.settlementInfos.filter(
        (info) => info.type === 'BUSINESS' && !!info.companyName
      );

      if (!companyInfos.length) {
        return '-';
      }

      return companyInfos.map((info) => info.companyName).join(', ');
    },
  },
  {
    title: '셀러링크',
    key: 'sellerLink',
    width: 150,
    dataIndex: 'sellerLink',
    render: (_, record) => {
      const defaultLink = record.links.find((link) => link.isDefault);
      const items = record.links.map((link) => ({
        label: (
          <a href={link.url} rel='noopener noreferer' target={'_blank'}>
            {link.url}
          </a>
        ),
        key: link.id,
      }));

      if (record.links.length === 1) {
        return (
          <a href={defaultLink?.url} rel='noreferer' target='_blank'>
            바로가기
          </a>
        );
      }

      return (
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          dropdownRender={(menus) => {
            return <div className={'max-w-[400px] break-all'}>{menus}</div>;
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              바로가기
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
      );
    },
  },
];

export default SellersTable;
