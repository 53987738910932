import { PropsWithChildren } from 'react';

import { SETTLEMENT_SHEET_BLOCK_CLASSNAME } from '../../../const';

function Block({
  children,
  padding = true,
}: PropsWithChildren<{ padding?: boolean }>) {
  return (
    <div className={SETTLEMENT_SHEET_BLOCK_CLASSNAME}>
      <div className={padding ? 'pt-10' : undefined}>{children}</div>
    </div>
  );
}

export default Block;
