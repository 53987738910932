import { CheckOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  App,
  Button,
  Divider,
  Dropdown,
  Space,
  Tag,
  Typography,
} from 'antd';
import { isEmpty, noop } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';

import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import TextField from '../../../common/components/Fields/TextField';
import { KEMIST_BASE_URL } from '../../../common/constants/env';
import { useFormError } from '../../../common/hooks/useFormError';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { extractBadRequestError } from '../../../common/utils/apollo';
import {
  AdminKemistProductsDocument,
  AdminKemistProductsQuery,
  useAdminCreateKemistProductFromMarketMutation,
} from '../graphql/market.generated';
import { UpdateMarketFormValues } from '../types';
import { updateMarketFormResolver } from '../validator';

import MarketEventsField from './MarketEventsField';
import MarketInfoFields from './MarketInfoFields';
import MarketProductsField from './MarketProductsField';
import MarketStatusSelectField from './MarketStatusSelectField';
import SellerInfoSelectField from './SellerInfoSelectField';

type UpdateMarketFormProps = {
  defaultValues: UpdateMarketFormValues;
  onSubmit: (
    values: UpdateMarketFormValues,
    onCompleted?: () => void
  ) => Promise<void>;
  onHistoryClick: () => void;
  onCheckListClick: () => void;
  marketId: number;
  onRemoveClick: () => void;
  goToMarkets: () => void;
  warningMessage?: string;
  kemistProducts?: AdminKemistProductsQuery['adminKemistProducts']['products'];
};
function UpdateMarketForm({
  defaultValues,
  onSubmit,
  onHistoryClick,
  onCheckListClick,
  marketId,
  onRemoveClick,
  goToMarkets,
  warningMessage,
  kemistProducts,
}: UpdateMarketFormProps) {
  const { hasPermission } = usePermissions('MARKET_EDIT');
  const { modal, message } = App.useApp();

  const [mutate] = useAdminCreateKemistProductFromMarketMutation({
    refetchQueries: [AdminKemistProductsDocument],
  });

  const { onInvalid } = useFormError({
    afterInValid(errors) {
      /* 발주팀 자체표기 필요 상태이면 1개 이상의 품목이 있어야 한다. validation 후 필드 focus를 위한 사후처리*/
      const eventIndexWithProductsError = Array.isArray(errors.events)
        ? errors.events.findIndex((event) => !!event?.products?.message)
        : undefined;

      if (typeof eventIndexWithProductsError === 'number') {
        methods.setFocus(
          `events.${eventIndexWithProductsError}.isNotationRequired`
        );
      }
    },
  });

  const methods = useForm<UpdateMarketFormValues>({
    defaultValues,
    mode: 'onChange',
    resolver: updateMarketFormResolver,
  });
  const {
    control,
    watch,
    formState: { dirtyFields, isValid },
    handleSubmit,
    reset,
  } = methods;

  const isDirty = !isEmpty(dirtyFields);

  const isSellerAssigned = watch('isSellerAssigned');
  const marketName = watch('name');
  const hasKemistProduct = !!kemistProducts?.length;
  const kemistItems = (hasKemistProduct &&
    kemistProducts.map((product) => ({
      label: (
        <a
          href={`${KEMIST_BASE_URL}/products/${product.id}`}
          rel='noopener noreferer'
          target={'_blank'}
          className={'line-clamp-1'}
        >
          {product.title}
        </a>
      ),
      key: product.id,
    }))) || [{ label: '등록된 상품이 없습니다.', key: 'null' }];

  const handleKemistRegisterClick = () => {
    modal.success({
      centered: true,
      title: '케미스트 상품등록',
      content: '마켓정보를 저장하고 케미스트에 바로 상품을 등록하시겠습니까?',
      okText: '네',
      cancelText: '나중에',
      okCancel: true,
      async onOk() {
        if (isDirty) {
          await handleSubmit(async (data) => {
            // 마켓 저장
            await onSubmit(data, noop);

            // 마켓 폼 리셋
            reset(data);
          }, onInvalid)();
        }

        // 마켓 폼 valid 한지 확인
        if (isValid) {
          // 케미스트 상품등록
          await mutate({
            variables: { marketId },
            onCompleted(data) {
              window.open(
                `${KEMIST_BASE_URL}/products/${data.adminCreateKemistProductFromMarket.productId}`,
                '_blank'
              );
              // 마켓 저장 새롭게 하면 마켓관리로 이동
              if (isDirty) {
                goToMarkets();
              }
            },
            onError({ graphQLErrors }) {
              const badRequestError = extractBadRequestError(graphQLErrors);

              if (badRequestError) {
                message.error(badRequestError.message);
              }

              message.error('케미스트 상품등록을 실패하였습니다.');
            },
          });
        }
      },
    });
  };

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmit(data), onInvalid)}>
          <div className={'flex p-6'}>
            <div className={'flex-1'}>
              <Tag className={'mb-sm'}>{`마켓ID: ${marketId}`}</Tag>
              <Typography.Title
                className={'m-0 flex items-center gap-sm'}
                level={3}
              >
                {marketName || ''}
              </Typography.Title>
            </div>

            <div className={'flex items-center'}>
              <Dropdown
                menu={{ items: kemistItems }}
                trigger={['click']}
                dropdownRender={(menus) => {
                  return (
                    <div className={'max-w-[320px] break-all'}>{menus}</div>
                  );
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    케미스트 등록확인
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
              <Button
                type={'primary'}
                icon={<PlusOutlined />}
                className={'ml-4'}
                onClick={handleKemistRegisterClick}
              >
                케미스트 상품등록
              </Button>
              <Button
                danger
                disabled={!hasPermission}
                onClick={onRemoveClick}
                className={'ml-2'}
              >
                마켓삭제
              </Button>
            </div>
          </div>
          {!!warningMessage && (
            <div className={'mb-4 px-6'}>
              <Alert message={warningMessage} type='warning' showIcon />
            </div>
          )}
          {hasKemistProduct && (
            <div className={'mb-4 px-6'}>
              <Alert
                message={
                  '해당마켓을 참조한 케미스트 상품이 있어 상품, 브랜드 변경 및 마켓 삭제가 불가능합니다.'
                }
                type='warning'
                showIcon
              />
            </div>
          )}
          <div
            className={
              'grid gap-xl px-6 @container/main [&_.ant-divider-inner-text]:font-semibold'
            }
          >
            <Typography.Title level={4} className={'m-0'}>
              마켓 기본정보
            </Typography.Title>
            <div
              className={'grid w-updateFormWidth grid-cols-2 gap-x-2 gap-y-6'}
            >
              <ManagerSelectField
                required={hasPermission}
                label={'담당자'}
                name={'managerId'}
                control={control}
                disabled={!hasPermission}
              />

              <MarketStatusSelectField
                required={hasPermission}
                label={'마켓상태'}
                control={control}
                name={'status'}
                initialStatus={defaultValues.status}
                disabled={!hasPermission}
                startedAt={watch('startedAt')}
                endedAt={watch('endedAt') || null}
              />

              <div className={'col-span-2'}>
                <TextField
                  control={control}
                  name={'name'}
                  label={'마켓명'}
                  required={hasPermission}
                  maxLength={100}
                  placeholder={'마켓명 입력(100자 이내)'}
                  disabled={!hasPermission}
                />
              </div>
            </div>

            {isSellerAssigned && <SellerInfoSelectField />}

            <Typography.Title level={4} className={'m-0'}>
              마켓 상세정보
            </Typography.Title>

            <MarketProductsField
              hideSession
              productEditable={!hasKemistProduct}
            />

            <Divider orientation='left' className={'m-0'}>
              {'마켓정보'}
            </Divider>

            <MarketInfoFields />

            {isSellerAssigned && <MarketEventsField />}

            <Divider className={'m-0 mb-4'} />
          </div>
          <div className={'sticky bottom-0 z-10 h-16 w-full'}>
            <div className='flex w-full justify-between bg-white px-6 py-4 drop-shadow-topside'>
              <div className={'flex gap-4'}>
                <Button htmlType={'button'} onClick={onHistoryClick}>
                  변경 히스토리
                </Button>
                <Button
                  type={'primary'}
                  htmlType={'button'}
                  onClick={onCheckListClick}
                  icon={<CheckOutlined />}
                >
                  체크리스트
                </Button>
              </div>

              <Button type={'primary'} htmlType={'submit'} disabled={!isDirty}>
                저장
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default UpdateMarketForm;
