import { RcFile } from 'antd/es/upload';
import { useCallback } from 'react';

import { EUploadResourcesPurpose } from '../../schema/types';
import {
  GenerateUploadResourcesMutation,
  useGenerateUploadResourcesMutation,
} from '../graphql/file.generated';

type UseFileUploadArgs = {
  purpose: EUploadResourcesPurpose;
};

export const useFileUpload = ({ purpose }: UseFileUploadArgs) => {
  const [mutate] = useGenerateUploadResourcesMutation();

  const fetchUploadResources = useCallback(
    async (file: RcFile | Blob) => {
      const { data, errors } = await mutate({
        variables: {
          generateUploadResourcesInput: { purpose, fileName: file.name },
        },
      });

      if (!data || errors) {
        throw new Error('failed upload');
      }

      return data;
    },
    [mutate, purpose]
  );

  const upload = useCallback(
    async (data: GenerateUploadResourcesMutation, file: RcFile | Blob) => {
      const generateUploadResources = data.adminGenerateUploadResources;

      if (!generateUploadResources) {
        throw new Error('invalid resources');
      }

      const url = generateUploadResources.uploadUrl;
      const uploadFormData = generateUploadResources.uploadFormDataJSON;
      const destinationUrl = generateUploadResources.destinationUrl;

      const fields = JSON.parse(uploadFormData) as Record<string, string>;

      const formData = new FormData();
      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('failed upload');
      }

      return destinationUrl;
    },
    []
  );

  return { upload, fetchUploadResources };
};
