import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OperatorQueryVariables = Types.Exact<{
  adminOperatorId: Types.Scalars['Int']['input'];
}>;


export type OperatorQuery = { __typename?: 'Query', adminOperator: { __typename?: 'OperatorGraphqlType', email: string, id: number, name: string, roles: Array<Types.ERole | null> } };

export type UpdateOperatorMutationVariables = Types.Exact<{
  adminPatchOperatorId: Types.Scalars['Int']['input'];
  operatorData: Types.PatchOperatorGraphqlInputType;
}>;


export type UpdateOperatorMutation = { __typename?: 'Mutation', adminPatchOperator: { __typename?: 'OperatorOutputGraphqlType', operator: { __typename?: 'OperatorGraphqlType', id: number } } };


export const OperatorDocument = gql`
    query Operator($adminOperatorId: Int!) {
  adminOperator(id: $adminOperatorId) {
    email
    id
    name
    roles
  }
}
    `;

/**
 * __useOperatorQuery__
 *
 * To run a query within a React component, call `useOperatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorQuery({
 *   variables: {
 *      adminOperatorId: // value for 'adminOperatorId'
 *   },
 * });
 */
export function useOperatorQuery(baseOptions: Apollo.QueryHookOptions<OperatorQuery, OperatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperatorQuery, OperatorQueryVariables>(OperatorDocument, options);
      }
export function useOperatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperatorQuery, OperatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperatorQuery, OperatorQueryVariables>(OperatorDocument, options);
        }
export type OperatorQueryHookResult = ReturnType<typeof useOperatorQuery>;
export type OperatorLazyQueryHookResult = ReturnType<typeof useOperatorLazyQuery>;
export type OperatorQueryResult = Apollo.QueryResult<OperatorQuery, OperatorQueryVariables>;
export const UpdateOperatorDocument = gql`
    mutation UpdateOperator($adminPatchOperatorId: Int!, $operatorData: PatchOperatorGraphqlInputType!) {
  adminPatchOperator(id: $adminPatchOperatorId, operatorData: $operatorData) {
    operator {
      id
    }
  }
}
    `;
export type UpdateOperatorMutationFn = Apollo.MutationFunction<UpdateOperatorMutation, UpdateOperatorMutationVariables>;

/**
 * __useUpdateOperatorMutation__
 *
 * To run a mutation, you first call `useUpdateOperatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperatorMutation, { data, loading, error }] = useUpdateOperatorMutation({
 *   variables: {
 *      adminPatchOperatorId: // value for 'adminPatchOperatorId'
 *      operatorData: // value for 'operatorData'
 *   },
 * });
 */
export function useUpdateOperatorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOperatorMutation, UpdateOperatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOperatorMutation, UpdateOperatorMutationVariables>(UpdateOperatorDocument, options);
      }
export type UpdateOperatorMutationHookResult = ReturnType<typeof useUpdateOperatorMutation>;
export type UpdateOperatorMutationResult = Apollo.MutationResult<UpdateOperatorMutation>;
export type UpdateOperatorMutationOptions = Apollo.BaseMutationOptions<UpdateOperatorMutation, UpdateOperatorMutationVariables>;