import { DatePicker, Descriptions, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { ChangeEvent, useCallback } from 'react';

import ManagerSelect from '../../../../common/components/Fields/ManagerSelect';
import MarketSelect from '../../../../common/components/Fields/MarketSelect';
import VendorSelect from '../../../../common/components/Fields/VendorSelect';
import useRouteSearchParams from '../../../../common/hooks/useRouteSearchParams';
import { SETTLEMENT_SHEET_LIST_PERIOD_OPTIONS } from '../../const';
import { SheetListSearchParams } from '../../types';

function SearchForm() {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<SheetListSearchParams>();
  const managerIds = searchParams.managerIds?.map((id) => Number(id));
  const handleManager = useCallback(
    (managerIds: number[]) =>
      setSearchParams({ managerIds, currentPage: undefined }),
    []
  );
  const handlePeriodTarget = useCallback(
    (periodTarget: SheetListSearchParams['periodTarget']) =>
      setSearchParams({ periodTarget, currentPage: undefined }),
    []
  );
  const handleStartedAt = useCallback(
    (_: dayjs.Dayjs | null, startedAt: string) =>
      setSearchParams({
        startedAt,
        currentPage: undefined,
        summaryStatus: undefined,
      }),
    [setSearchParams]
  );
  const handleEndedAt = useCallback(
    (_: dayjs.Dayjs | null, endedAt: string) =>
      setSearchParams({
        endedAt,
        currentPage: undefined,
        summaryStatus: undefined,
      }),
    [setSearchParams]
  );
  const handleMarketId = useCallback(
    (marketId: number) => setSearchParams({ marketId, vendorId: undefined }),
    [setSearchParams]
  );
  const handleVendorId = useCallback(
    (vendorId: number) => setSearchParams({ vendorId, marketId: undefined }),
    [setSearchParams]
  );

  const handleCode = useCallback(
    debounce(
      (ev: ChangeEvent<HTMLInputElement>) =>
        setSearchParams({ code: ev.target.value, currentPage: undefined }),
      200
    ),
    []
  );
  return (
    <div>
      <Descriptions
        bordered
        column={1}
        labelStyle={{ width: 170 }}
        size='small'
      >
        <Descriptions.Item label='기간'>
          <div className='flex items-center gap-2'>
            <Select
              className='w-[163px]'
              options={SETTLEMENT_SHEET_LIST_PERIOD_OPTIONS}
              defaultValue={SETTLEMENT_SHEET_LIST_PERIOD_OPTIONS[0].value}
              onChange={handlePeriodTarget}
            />
            <DatePicker
              className='w-[163px]'
              onChange={handleStartedAt}
              value={
                searchParams.startedAt ? dayjs(searchParams.startedAt) : null
              }
            />
            ~
            <DatePicker
              className='w-[163px]'
              onChange={handleEndedAt}
              value={searchParams.endedAt ? dayjs(searchParams.endedAt) : null}
            />
          </div>
        </Descriptions.Item>
        {searchParams.type === 'MARKET' && (
          <Descriptions.Item label='마켓명'>
            <MarketSelect
              className='w-[334px]'
              placeholder='전체'
              initialLoad
              allowClear
              onChange={handleMarketId}
              value={
                searchParams.marketId
                  ? Number(searchParams.marketId)
                  : undefined
              }
            />
          </Descriptions.Item>
        )}
        {searchParams.type === 'VENDOR' && (
          <Descriptions.Item label='업체명'>
            <VendorSelect
              className='w-[334px]'
              placeholder='전체'
              initialLoad
              allowClear
              onChange={handleVendorId}
              value={
                searchParams.vendorId
                  ? Number(searchParams.vendorId)
                  : undefined
              }
            />
          </Descriptions.Item>
        )}
        <Descriptions.Item label='정산서코드'>
          <Input
            className='w-[334px]'
            placeholder='정산서코드 입력'
            defaultValue={searchParams.code}
            onChange={handleCode}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={searchParams.type === 'VENDOR' ? '업체담당자' : '마켓담당자'}
        >
          <ManagerSelect
            mode='multiple'
            className='w-[334px]'
            placeholder='담당자 입력'
            value={managerIds}
            onChange={handleManager}
          />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default SearchForm;
