import { Tag } from 'antd';
import dayjs from 'dayjs';

import { useManagersQuery } from '../../../common/graphql/managers.generated';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import {
  FILTER_BEARER_TYPES_LABEL,
  PERIOD_OPTION_LABEL,
  RELEASE_STATUS_LABEL,
} from '../const';
import { PromotionReleaseListSearchParams } from '../types';

function SearchTags() {
  const { data } = useManagersQuery();
  const { searchParams, setSearchParams } =
    useRouteSearchParams<PromotionReleaseListSearchParams>();
  const managers = searchParams.managerIds?.map((id) => Number(id)) || [];
  const searchManagers = data?.adminManagers.filter(({ id }) =>
    managers.includes(id)
  );
  const isPeriod =
    searchParams.periodType && (searchParams.startedAt || searchParams.endedAt);
  const releaseStatuses = searchParams.promotionReleaseStatuses;
  const receiverPhoneNumber = searchParams.receiverPhoneNumber;
  const isFreeProvided = searchParams.isFreeProvided;
  const bearerTypes = searchParams.bearerTypes;
  const marketUndesignated = searchParams.marketUndesignated;

  return (
    <div className='mt-2'>
      {!!searchManagers?.length &&
        searchManagers.map(({ id, name }) => (
          <Tag
            key={id}
            closable
            onClose={() =>
              setSearchParams({
                managerIds: managers.filter((managerId) => managerId !== id),
              })
            }
          >
            {name}
          </Tag>
        ))}
      {isPeriod && (
        <Tag
          closable
          onClose={() =>
            setSearchParams({
              periodType: undefined,
              startedAt: undefined,
              endedAt: undefined,
            })
          }
        >
          {searchParams.periodType &&
            PERIOD_OPTION_LABEL[searchParams.periodType]}{' '}
          {searchParams.startedAt &&
            dayjs(searchParams.startedAt).format('YYYY.MM.DD')}{' '}
          ~{' '}
          {searchParams.endedAt &&
            dayjs(searchParams.endedAt).format('YYYY.MM.DD')}
        </Tag>
      )}
      {releaseStatuses &&
        releaseStatuses.map((status) => (
          <Tag
            key={status}
            closable
            onClose={() =>
              setSearchParams({
                promotionReleaseStatuses:
                  searchParams.promotionReleaseStatuses?.filter(
                    (item) => item !== status
                  ),
              })
            }
          >
            {RELEASE_STATUS_LABEL[status]}
          </Tag>
        ))}
      {receiverPhoneNumber && (
        <Tag
          closable
          onClose={() =>
            setSearchParams({
              receiverPhoneNumber: undefined,
            })
          }
        >
          {receiverPhoneNumber}
        </Tag>
      )}
      {isFreeProvided &&
        (isFreeProvided === 'true' ? (
          <Tag
            closable
            onClose={() => setSearchParams({ isFreeProvided: undefined })}
          >
            업체무상
          </Tag>
        ) : (
          bearerTypes?.map((type) => (
            <Tag
              closable
              key={type}
              onClose={() => {
                if (bearerTypes.length === 1) {
                  setSearchParams({
                    isFreeProvided: undefined,
                    bearerTypes: undefined,
                  });
                } else {
                  setSearchParams({
                    bearerTypes: bearerTypes.filter((item) => item !== type),
                  });
                }
              }}
            >
              {FILTER_BEARER_TYPES_LABEL[type]}
            </Tag>
          ))
        ))}

      {marketUndesignated === 'true' && (
        <Tag
          closable
          onClose={() => setSearchParams({ marketUndesignated: undefined })}
        >
          마켓 미지정 모아보기
        </Tag>
      )}
    </div>
  );
}

export default SearchTags;
