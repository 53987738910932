import { Spin, Switch, Typography } from 'antd';
import { useMemo } from 'react';

import EmptyTable from '../common/components/EmptyTable';
import WithDefaultSearchParam from '../common/hoc/WithDefaultSearchParam';
import OrderClaimsTable from '../domain/cs/components/OrderClaimsTable';
import SalesChannelTab from '../domain/cs/components/SalesChannelTab';
import SearchFormWithFilterModal from '../domain/cs/components/SearchFormWithFilterModal';
import Summary, { SummaryProps } from '../domain/cs/components/Summary';
import {
  defaultClaimStatus,
  defaultSearchParam,
} from '../domain/cs/helper/list';
import { useClaimStatusCount } from '../domain/cs/hooks/useClaimStatusCount';
import { useSearchClaimsParams } from '../domain/cs/hooks/useSearchClaimsParams';

function CsExchangeList() {
  const { setSearchParams, response, searchParams } =
    useSearchClaimsParams('EXCHANGE');
  const { data, loading } = response;
  const {
    startedAt,
    endedAt,
    salesChannelType,
    periodType,
    searchKeyword,
    claimStatus,
    hasActiveExchangeReDeliveryPromotionRelease,
  } = searchParams;

  const total = data?.adminOrders?.totalCount || 0;

  const { statuses, loading: statusCountLoading } =
    useClaimStatusCount('EXCHANGE');

  const items: SummaryProps['items'] = useMemo(() => {
    const summaryItems: SummaryProps['items'] = [
      {
        key: 'EXCHANGE_REQUESTED',
        label: '교환요청',
        count: statuses.EXCHANGE_REQUESTED || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: 'EXCHANGE_REQUESTED',
            claimStatus: 'EXCHANGE_REQUESTED',
            orderStatus: undefined,
            hasActiveExchangeReDeliveryPromotionRelease: undefined,
          }),
      },
      {
        key: 'EXCHANGE_RECEIPT',
        label: '교환접수',
        count: statuses.EXCHANGE_RECEIPT || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: 'EXCHANGE_RECEIPT',
            claimStatus: 'EXCHANGE_RECEIPT',
            orderStatus: undefined,
            hasActiveExchangeReDeliveryPromotionRelease: 0,
          }),
      },
      {
        key: 'EXCHANGE_RE_DELIVERY',
        label: '교환재출고',
        count: statuses.EXCHANGE_RE_DELIVERY || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: 'EXCHANGE_RE_DELIVERY',
            claimStatus: 'EXCHANGE_RE_DELIVERY',
            orderStatus: undefined,
            hasActiveExchangeReDeliveryPromotionRelease: undefined,
          }),
      },
      {
        key: 'EXCHANGE_COMPLETED',
        label: '교환완료',
        count: statuses.EXCHANGE_COMPLETED || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: undefined,
            claimStatus: 'EXCHANGE_COMPLETED',
            orderStatus: 'EXCHANGE_COMPLETED',
            hasActiveExchangeReDeliveryPromotionRelease: undefined,
          }),
      },
    ];

    const isSellerShop = salesChannelType === 'SELLER_SHOP';

    return summaryItems.map((item) => {
      if (isSellerShop) {
        return {
          ...item,
          disabled: item.key === 'EXCHANGE_REQUESTED',
        };
      }

      return item;
    });
  }, [statuses, setSearchParams, salesChannelType]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='bg-white p-6'>
        <SalesChannelTab
          onChange={(v) => {
            setSearchParams({
              salesChannelType: v,
              claimStatus:
                v === 'SELLER_SHOP' ? 'EXCHANGE_RECEIPT' : 'EXCHANGE_REQUESTED',
              hasActiveExchangeReDeliveryPromotionRelease: undefined,
            });
          }}
        />
        <Summary
          loading={statusCountLoading}
          label='교환현황'
          activeKey={claimStatus}
          items={items}
        />
      </div>
      <div className='bg-white p-6'>
        <Typography.Title level={5}>검색</Typography.Title>
        <SearchFormWithFilterModal
          onSearch={setSearchParams}
          initialValue={{
            startedAt,
            endedAt,
            periodType,
            searchKeyword,
          }}
        />
      </div>
      <div className='bg-white p-6'>
        <Typography.Title level={4} className='m-0'>
          주문리스트
        </Typography.Title>
        {claimStatus === 'EXCHANGE_RECEIPT' && (
          <label className={'mt-4 flex w-64 cursor-pointer gap-2'}>
            <Switch
              checked={!hasActiveExchangeReDeliveryPromotionRelease}
              onChange={(v) =>
                setSearchParams({
                  hasActiveExchangeReDeliveryPromotionRelease: v ? 0 : 1,
                })
              }
            />
            <span>교환재출고 등록 안된 주문만 보기</span>
          </label>
        )}
        <div className='mt-4'>
          <Spin spinning={loading}>
            {!data?.adminOrders?.edges.length && <EmptyTable />}
            {!!data?.adminOrders?.edges.length && (
              <OrderClaimsTable
                orders={data.adminOrders?.edges}
                total={total}
                claimStatus={claimStatus}
                tableType={'EXCHANGE'}
              />
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default WithDefaultSearchParam(CsExchangeList, {
  ...defaultSearchParam,
  claimStatus: defaultClaimStatus.EXCHANGE,
  activeClaimStatus: defaultClaimStatus.EXCHANGE,
});
