import { UploadOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';

import Modal from './Modal';

type PreviewModalProps = {
  onClose: () => void;
  previewImage: string;
  disabled?: boolean;
};

export default function PreviewModal({
  onClose,
  previewImage,
  disabled,
}: PreviewModalProps) {
  const [hasPreviewError, setHasPreviewError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const close = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      title={'미리보기'}
      width={hasPreviewError ? undefined : 520}
      onOk={close}
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      afterClose={onClose}
    >
      <div className={'pt-xl text-center'}>
        <div className={'mb-lg max-h-[calc(100vh-388px)] overflow-auto'}>
          {hasPreviewError && (
            <>
              <div className={'mb-md'}>
                <FileTextOutlined style={{ fontSize: 42 }} />
              </div>
              <p>이미지 형식이 아닙니다. 파일을 다운받아 확인해주세요.</p>
              <p>( *권장 확장자 : jpg | jpeg | png | webp| gif | bmp )</p>
            </>
          )}
          {!hasPreviewError && (
            <img
              alt='example'
              style={{ width: '100%' }}
              src={previewImage}
              className={'w-full'}
              onError={() => setHasPreviewError(true)}
            />
          )}
        </div>

        <a href={previewImage} download>
          <Button htmlType={'button'} disabled={disabled}>
            <UploadOutlined className={'mr-sm'} />
            다운로드
          </Button>
        </a>
      </div>
    </Modal>
  );
}
