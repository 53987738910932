import { DownOutlined } from '@ant-design/icons';
import { App, Button, Dropdown } from 'antd';
import { groupBy } from 'lodash-es';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useContext, useState } from 'react';

import { EOrderStatus } from '../../../schema/types';
import ClaimActionDropdown from '../../cs/components/ClaimActionDropdown';
import { ETC_ACTIONS } from '../const';
import { OrderListTableSelectedRowContext } from '../contexts/OrderListTableSelectedRowContext';
import { useAdminMarketInOrderLazyQuery } from '../graphql/markets.generated';
import {
  AdminCountOrderStatusesDocument,
  AdminOrdersDocument,
  useAdminBulkDeleteOrderEventMutation,
} from '../graphql/orders.generated';
import useOrderListAction from '../hooks/useOrderListAction';
import {
  EtcActionType,
  OrderTableDataType,
  SelectedRowKeyType,
} from '../types';

import EventCreateModal from './EventCreateModal';

type OrderListActionButtonsProps = {
  dataSourceState: OrderTableDataType[];
};

export default function OrderListActionButtons({
  dataSourceState,
}: OrderListActionButtonsProps) {
  const { modal, message } = App.useApp();
  const [marketId, setMarketId] = useState<number>();
  const { releaseOrders, bulkPatchOrderStatus } = useOrderListAction();
  const { selectedRowKeys, setSelectedRowKeys, setRowSpanGroupIds } =
    useContext(OrderListTableSelectedRowContext);
  const [mutateBulkDeleteOrderEvent] = useAdminBulkDeleteOrderEventMutation();
  const [fetchAdminMarket] = useAdminMarketInOrderLazyQuery();

  const claimTargetOrder = selectedRowKeys[0];

  const handleEtcAction = async (e: MenuInfo) => {
    if (!e) return;
    const { key } = e as { key: EtcActionType };

    switch (key) {
      case 'FORCED_DELIVERY_COMPLETED':
        await changeOrderStatus({
          selectedRowKeys,
          status: 'DELIVERY_COMPLETED',
        });
        break;
      case 'EVENT_PROCESSING':
        await processEvent();
        break;
      case 'EVENT_CANCEL':
        cancelEvent();
        break;
      case 'CHANGE_TO_PAYMENT_COMPLETED':
        await changeOrderStatus({
          selectedRowKeys,
          status: 'PAYMENT_COMPLETED',
        });
        break;
      case 'MARKET_CONNECTION':
        break;
    }
  };

  const etcMenu = {
    items: ETC_ACTIONS,
    onClick: handleEtcAction,
  };

  const handleRelease = () => {
    void modal.confirm({
      title: '출고처리',
      content: '선택된 주문을 출고처리 하시겠습니까?',
      cancelText: '아니오',
      okText: '네',
      onOk: async () => {
        await releaseOrders();
        clearTableState();
      },
      maskClosable: true,
    });
  };

  const handleDeliveryWait = async () => {
    await bulkPatchOrderStatus({ status: 'DELIVERY_WAIT' });
    clearTableState();
  };

  const changeOrderStatus = async ({
    status,
  }: {
    selectedRowKeys: SelectedRowKeyType[];
    status: EOrderStatus;
  }) => {
    await bulkPatchOrderStatus({ status });
    clearTableState();
  };

  const clearTableState = () => {
    setSelectedRowKeys([]);
    setRowSpanGroupIds([]);
  };

  const processEvent = async () => {
    const groupByGroupId = groupBy(selectedRowKeys, 'groupId');

    if (Object.values(groupByGroupId).some((group) => group.length > 1)) {
      void message.error(
        '주문이 중복으로 선택되었습니다. 중복되는 와이어드주문코드가 없게 다시 선택해주세요.'
      );
      return;
    }

    const groupByMarketId = groupBy(selectedRowKeys, 'marketId');

    if (!selectedRowKeys.length || groupByMarketId['undefined']) {
      errorModal('마켓코드가 없습니다. 주문에 마켓코드를 입력해주세요.');
      return;
    }

    const marketIds = Object.keys(groupByMarketId);

    if (marketIds.length > 1) {
      errorModal('하나의 마켓에 포함된 주문들만 선택해주세요.');
      return;
    }

    const hasEvent = selectedRowKeys.find((row) => {
      const rowState = dataSourceState[row.key as number];

      return rowState.orderType === 'EVENT';
    });

    if (hasEvent) {
      errorModal('이미 처리된 주문이 있습니다.');
      return;
    }

    if (!selectedRowKeys[0].marketId) {
      errorModal('마켓아이디가 없습니다.');
      return;
    }

    const { data } = await fetchAdminMarket({
      variables: {
        marketId: selectedRowKeys[0].marketId,
      },
    });

    if (!data?.adminMarket?.events?.length) {
      errorModal('마켓에 이벤트가 없습니다.');
      return;
    }

    setMarketId(selectedRowKeys[0].marketId);
  };

  const errorModal = (content: string) => {
    void modal.error({
      title: '이벤트 처리',
      content,
      maskClosable: true,
    });
  };

  const cancelEvent = () => {
    const isEvent = selectedRowKeys.every((row) => {
      const rowState = dataSourceState[row.key as number];

      return rowState.orderType === 'EVENT';
    });

    if (!isEvent) {
      errorModal('이벤트 주문이 아닙니다.');
      return;
    }

    void modal.confirm({
      title: '이벤트 취소',
      content: '선택된 주문을 이벤트 취소 하시겠습니까?',
      cancelText: '아니오',
      okText: '네',
      onOk: deleteOrderEvent,
      maskClosable: true,
    });
  };

  const deleteOrderEvent = async () => {
    const groupIds = Object.keys(groupBy(selectedRowKeys, 'groupId'));

    await mutateBulkDeleteOrderEvent({
      variables: {
        orderData: {
          groupIds: groupIds.map((groupId) => Number(groupId)),
        },
      },
      refetchQueries: [AdminOrdersDocument, AdminCountOrderStatusesDocument],
      onError({ message: errorMessage }) {
        void message.error(errorMessage);
      },
      onCompleted() {
        setSelectedRowKeys([]);
        setRowSpanGroupIds([]);
        void message.success('이벤트가 취소되었습니다.');
      },
    });
  };

  return (
    <>
      <Button.Group>
        <Button onClick={handleRelease}>출고처리</Button>
        <Button onClick={handleDeliveryWait}>배송준비</Button>
        <ClaimActionDropdown
          disabled={selectedRowKeys.length !== 1}
          orderId={claimTargetOrder.id}
          orderGroupId={claimTargetOrder.groupId}
        />
        <Dropdown menu={etcMenu}>
          <Button>
            <DownOutlined />
            기타 주문처리
          </Button>
        </Dropdown>
      </Button.Group>
      {marketId && (
        <EventCreateModal
          marketId={marketId}
          onClose={() => setMarketId(undefined)}
        />
      )}
    </>
  );
}
