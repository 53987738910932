import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Typography } from 'antd';
import { useState } from 'react';

type DropdownItemProps = {
  onChange: (name: string) => void;
} & React.PropsWithChildren;

export default function DropdownItem({
  onChange,
  children,
}: DropdownItemProps) {
  const [name, setName] = useState('');

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = () => {
    if (!name.trim()) return;
    onChange(name.trim());
    setName('');
  };

  const handleClickAddItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    addItem();
  };

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault(); // submit 방지
    e.stopPropagation(); // 셀렉트 선택 방지
    if (e.nativeEvent.isComposing) return; // 한글조합 중일 때는 무시
    addItem();
  };

  return (
    <div className='p-1'>
      <Typography.Text className='block px-3 py-[6px] text-xs text-[#00000073]'>
        아래 옵션 선택 시, 해당 옵션과 재고가 연동됩니다
      </Typography.Text>
      {children}
      <Divider className='m-0' />
      <div className='mt-2 px-3 pb-2 pt-[6px]'>
        <Typography.Text className='block text-xs text-[#00000073]'>
          옵션 생성 시, 재고가 독립적으로 동작합니다
        </Typography.Text>
        <div className='mt-[6px] flex justify-between'>
          <Input
            placeholder='Please enter item'
            value={name}
            onChange={onNameChange}
            onPressEnter={handlePressEnter}
            onFocus={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            maxLength={50}
          />
          <Button
            type='text'
            icon={<PlusOutlined />}
            htmlType='button'
            onClick={handleClickAddItem}
          >
            옵션 생성
          </Button>
        </div>
      </div>
    </div>
  );
}
