import { App, DatePicker, Select, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import Modal from '../../../../common/components/Modal';
import { useMeQuery } from '../../../../common/graphql/me.generated';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { EOrderGroupDepositStatus } from '../../../../schema/types';
import { DEPOSIT_STATUS_LABEL, DEPOSIT_STATUS_OPTIONS } from '../../const';
import {
  AdminCountOrderGroupDepositStatusesDocument,
  useAdminPatchOrderGroupDepositByIdMutation,
} from '../../graphql/deposit.generated';

type DepositStatusCellProps = {
  id: number;
  status: EOrderGroupDepositStatus;
};

function DepositStatusCell({ id, status }: DepositStatusCellProps) {
  const { hasPermission } = usePermissions(['SETTLEMENT_EDIT']);
  const { message } = App.useApp();
  const [value, setValue] = useState(status);
  const [depositedAt, setDepositedAt] = useState<dayjs.Dayjs | null>();
  const [modal, setModal] = useState(false);
  const [patchDepositMutation, { loading }] =
    useAdminPatchOrderGroupDepositByIdMutation();
  const { data } = useMeQuery();
  const userName = data?.adminMe.name;

  useEffect(() => {
    setValue(status);
  }, [status]);

  const handleChange = useCallback((newValue: EOrderGroupDepositStatus) => {
    setValue(newValue);
    setModal(true);
  }, []);

  const patchDepositStatus = useCallback(async () => {
    if (!depositedAt) {
      void message.error('입금날짜를 입력해주세요.');
      return;
    }
    const close = message.loading('처리 중입니다.');
    await patchDepositMutation({
      variables: {
        adminPatchOrderGroupDepositByIdId: id,
        orderGroupDepositData: {
          depositedAt: depositedAt.toISOString(),
          status: value,
        },
      },
      refetchQueries: [AdminCountOrderGroupDepositStatusesDocument],
      onCompleted() {
        close();
        setModal(false);
        void message.success('입금상태가 변경되었습니다.');
      },
      onError(error) {
        setValue(status);
        void message.error(error.message);
      },
    });
  }, [depositedAt, id, message, patchDepositMutation, status, value]);

  if (status === 'CONFIRM' || !hasPermission) {
    return <div>{DEPOSIT_STATUS_LABEL[status]}</div>;
  }

  return (
    <Spin spinning={loading}>
      <Select
        className='w-full'
        options={DEPOSIT_STATUS_OPTIONS}
        value={value}
        onChange={handleChange}
      />
      {modal && (
        <Modal
          title='입금확인으로 상태변경'
          okText='상태변경'
          onOk={patchDepositStatus}
          onCancel={() => {
            setModal(false);
            setValue(status);
          }}
        >
          <div className='flex flex-col gap-2 py-2'>
            <div>
              <DatePicker
                value={depositedAt}
                onChange={(value) => setDepositedAt(value)}
              />{' '}
              에 입금된 내역을 확인했으며,
            </div>
            <div>
              <Tag className='m-0'>{userName}</Tag> 님이{' '}
              {DEPOSIT_STATUS_LABEL[value]} 상태로 변경합니다.
            </div>
            <span className='text-red-400'>
              *상태변경 후에는 다시 되돌릴 수 없습니다.
            </span>
          </div>
        </Modal>
      )}
    </Spin>
  );
}

export default DepositStatusCell;
