import { EOrderClaimStatus, EOrderClaimType } from '../../../schema/types';
import { CLAIM_STATUS } from '../const';
import { OrderForClaimStatus } from '../type';

import {
  getConvertableCancelClaim,
  getConvertableExchangeClaim,
  getConvertableReturnClaim,
} from './orderClaim';

export const getCreateClaimSelectOptions = (
  order: OrderForClaimStatus
): {
  label: string;
  value: EOrderClaimType;
  data: { claimStatus: EOrderClaimStatus; claimType: EOrderClaimType };
}[] => {
  let options = [...defaultCreateClaimSelectOptions];

  const isClaimCreatableOrderStatus = [
    'DELIVERY_COMPLETED',
    'DELIVERY_STARTED',
    'DELIVERY_WAIT',
    'PAYMENT_COMPLETED',
    'PAYMENT_WAIT',
    'PURCHASE_CONFIRMED',
    'EXCHANGE_COMPLETED',
  ].includes(order.orderStatus);

  if (!isClaimCreatableOrderStatus) {
    return [];
  }

  /**
   * 셀러쇼핑몰만 취소처리 가능
   */
  if (order.salesChannelType === 'SELLER_SHOP') {
    options.push(cancelOption);
  }

  /**
   * 케미 주문은 부분반품불가
   */
  if (order.salesChannelType === 'KEMI') {
    options = options.filter((option) => option.value !== 'PARTIAL_RETURN');
  }

  /**
   * 부분클레임이 있으면 일반 클레임 등록 불가
   */
  if (
    order.claims?.some(
      (claim) =>
        claim.claimType === 'PARTIAL_EXCHANGE' ||
        claim.claimType === 'PARTIAL_RETURN'
    )
  ) {
    options = options.filter(
      (option) =>
        option.value !== 'RETURN' &&
        option.value !== 'EXCHANGE' &&
        option.value !== 'CANCEL'
    );
  }

  /**
   * 배송준비 이전 주문상태는 취소만 가능
   * 배송준비 이후 주문상태는 취소 불가
   * 배송준비는 둘 다 가능
   */
  if (['PAYMENT_COMPLETED', 'PAYMENT_WAIT'].includes(order.orderStatus)) {
    return options.filter((option) => option.value === 'CANCEL');
  }
  if (
    [
      'DELIVERY_COMPLETED',
      'DELIVERY_STARTED',
      'PURCHASE_CONFIRMED',
      'EXCHANGE_COMPLETED',
    ].includes(order.orderStatus)
  ) {
    return options.filter((option) => option.value !== 'CANCEL');
  }

  return options;
};

const cancelOption: {
  label: string;
  value: EOrderClaimType;
  data: { claimStatus: EOrderClaimStatus; claimType: EOrderClaimType };
} = {
  label: '취소처리',
  value: 'CANCEL' as const,
  data: {
    claimStatus: 'CANCEL_COMPLETED' as const,
    claimType: 'CANCEL' as const,
  },
};

const defaultCreateClaimSelectOptions: {
  label: string;
  value: EOrderClaimType;
  data: { claimStatus: EOrderClaimStatus; claimType: EOrderClaimType };
}[] = [
  {
    label: '반품접수',
    value: 'RETURN',
    data: { claimStatus: 'RETURN_RECEIPT', claimType: 'RETURN' },
  },
  {
    label: '교환접수',
    value: 'EXCHANGE',
    data: { claimStatus: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
  },
  // 부분시리즈 스펙아웃: https://www.notion.so/wiredcompany/12-22-CS-Event-c4dc56459462406fbe70d47fb806e837
  // {
  //   label: '부분반품',
  //   value: 'PARTIAL_RETURN',
  //   data: { claimStatus: 'RETURN_COMPLETED', claimType: 'PARTIAL_RETURN' },
  // },
  // {
  //   label: '부분교환',
  //   value: 'PARTIAL_EXCHANGE',
  //   data: { claimStatus: 'EXCHANGE_COMPLETED', claimType: 'PARTIAL_EXCHANGE' },
  // },
];

export const getUpdateClaimSelectOptions = (
  order: OrderForClaimStatus
): {
  label: string;
  value: EOrderClaimStatus;
  data: { claimStatus: EOrderClaimStatus; claimType: EOrderClaimType };
}[] => {
  if (!order.activeClaim) {
    return [];
  }

  const claimType = order.activeClaim.claimType;
  const claimStatus = order.activeClaim.status;
  const salesChannelType = order.salesChannelType;

  const possibleClaims = (() => {
    if (claimType === 'RETURN') {
      return getConvertableReturnClaim;
    }
    if (claimType === 'EXCHANGE') {
      return getConvertableExchangeClaim;
    }

    if (claimType === 'CANCEL') {
      return getConvertableCancelClaim;
    }

    return null;
  })();

  if (!possibleClaims) {
    return [];
  }

  const statuses = possibleClaims(claimStatus, salesChannelType);

  return statuses
    .map(({ status, claimType }) => {
      return {
        label: CLAIM_STATUS[status],
        value: status,
        data: { claimStatus: status, claimType },
      };
    })
    .filter(
      (
        status
      ): status is {
        label: string;
        value: EOrderClaimStatus;
        data: { claimStatus: EOrderClaimStatus; claimType: EOrderClaimType };
      } => !!status
    );
};
