import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { removeCache } from '../../../common/utils/apollo';
import { useVendorWithBrandsQuery } from '../graphql/vendor.generated';

function useVendorDetailQuery() {
  const client = useApolloClient();

  const params = useParams<{ vendorId: string }>();
  const vendorId = Number(params.vendorId);
  return useVendorWithBrandsQuery({
    variables: { vendorId },
    onError() {
      removeCache(client.cache, {
        id: vendorId,
        __typename: 'VendorGraphqlType',
      });
    },
  });
}

export default useVendorDetailQuery;
