import { Button } from 'antd';
import { useState } from 'react';

import { usePermissions } from '../../../../common/hooks/usePermissions';

import EditAmountModal from './EditAmountModal';

type SellerSettlementAmountCellProps = {
  amount?: number | null;
  hasSheet: boolean;
  onEdit: (amount: number) => Promise<void>;
};

function SellerSettlementAmountCell({
  amount,
  hasSheet,
  onEdit,
}: SellerSettlementAmountCellProps) {
  const [amountModalOpen, setAmountModalOpen] = useState(false);
  const { hasPermission } = usePermissions('SETTLEMENT_EDIT');
  if (amount === undefined) return <span>-</span>;

  const disabled = !hasPermission || hasSheet;

  return (
    <>
      <div className='flex items-center justify-between'>
        <span>
          {amount === null ? (
            <span className='text-[#FF4D4F]'>모름</span>
          ) : (
            amount.toLocaleString()
          )}
        </span>
        <Button
          type={'text'}
          disabled={disabled}
          className={disabled ? '' : 'text-primary'}
          onClick={() => setAmountModalOpen(true)}
        >
          수정
        </Button>
      </div>
      {amountModalOpen && (
        <EditAmountModal
          title={'셀러정산금액 수정'}
          currentAmount={amount || 0}
          onClose={() => setAmountModalOpen(false)}
          onEdit={onEdit}
        />
      )}
    </>
  );
}

export default SellerSettlementAmountCell;
