import { App } from 'antd';
import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';
import { useCallback } from 'react';

import { axiosClient } from '../axios/axiosClient';

type ExcelDownloadParams = {
  url: string;
  config?: AxiosRequestConfig<any>;
  fileName: string;
};

function useExcelDownload() {
  const { message } = App.useApp();
  const excelDownload = useCallback(
    async ({ url, config, fileName }: ExcelDownloadParams) => {
      try {
        const response = await axiosClient.get(url, {
          responseType: 'blob',
          responseEncoding: 'binary',
          ...config,
        });
        const downloadHref = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'] as string,
          })
        );
        const link = document.createElement('a');
        link.href = downloadHref;
        link.setAttribute(
          'download',
          `${fileName}_${dayjs().format('YYYYMMDD_HHmmss')}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        void message.error('엑셀다운로드에 실패했습니다.');
      }
    },
    [message]
  );

  return { excelDownload };
}

export default useExcelDownload;
