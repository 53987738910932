import { noop } from 'lodash-es';
import { createContext, useState } from 'react';

import { SelectedRowKeyType } from '../types';

export const OrderListTableSelectedRowContext = createContext<{
  selectedRowKeys: SelectedRowKeyType[];
  setSelectedRowKeys: (selectedRowKeys: SelectedRowKeyType[]) => void;
  rowSpanGroupIds: number[];
  setRowSpanGroupIds: (rowSpanGroupIds: number[]) => void;
}>({
  selectedRowKeys: [],
  setSelectedRowKeys: noop,
  rowSpanGroupIds: [],
  setRowSpanGroupIds: noop,
});

export function OrderListTableSelectedRowProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeyType[]>(
    []
  );
  const [rowSpanGroupIds, setRowSpanGroupIds] = useState<number[]>([]);

  return (
    <OrderListTableSelectedRowContext.Provider
      value={{
        selectedRowKeys,
        setSelectedRowKeys,
        rowSpanGroupIds,
        setRowSpanGroupIds,
      }}
    >
      {children}
    </OrderListTableSelectedRowContext.Provider>
  );
}
