import { yupResolver } from '@hookform/resolvers/yup';
import { App, Typography } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';

import SelectField from '../../../../common/components/Fields/SelectField';
import Modal from '../../../../common/components/Modal';
import { useFormError } from '../../../../common/hooks/useFormError';
import { deleteCacheFields } from '../../../../common/utils/apollo';
import { CreateOrderSupportWithMessageInputGraphqlType } from '../../../../schema/types';
import SimpleEditorField from '../../../product/components/Fields/SimpleEditorField';
import {
  orderSupportChannelOptions,
  orderSupportRequestTypeOptions,
} from '../../const';
import { AdminOrderGroupDocument } from '../../graphql/createClaim.generated';
import { useAdminCreateOrderSupportWithMessageMutation } from '../../graphql/orderSupport.generated';
import { createOrderSupportMessageSchema } from '../../validator';

function CreateOrderSupportForm({
  orderId,
  onCancel,
}: {
  orderId: number;
  onCancel: () => void;
}) {
  const { message, modal } = App.useApp();

  const [createOrderSupport] = useAdminCreateOrderSupportWithMessageMutation();

  const methods = useForm<CreateOrderSupportWithMessageInputGraphqlType>({
    defaultValues: {
      message: {
        content: '',
      },
      channel: 'INTERNAL',
      requestType: 'DEFECT',
      orderId,
    },
    resolver: yupResolver<CreateOrderSupportWithMessageInputGraphqlType>(
      createOrderSupportMessageSchema
    ),
  });

  const { onInvalid } = useFormError();

  const { handleSubmit, control, formState } = methods;

  const onSubmit = (values: CreateOrderSupportWithMessageInputGraphqlType) => {
    modal.warning({
      title: '응대요청',
      content: '선택하신 주문을 응대요청하시겠습니까?',
      centered: true,
      okText: '네',
      cancelText: '아니오',
      okCancel: true,
      onOk() {
        void createOrderSupport({
          variables: {
            orderSupportData: { ...values, orderId },
          },
          refetchQueries: [AdminOrderGroupDocument],
          onError(error) {
            message.error(error.message || '응대 생성을 실패하였습니다.');
          },
          onCompleted() {
            message.success('응대를 생성하였습니다.');
            onCancel();
          },
          update(cache) {
            // 메시지를 쿼리하지 않는 경우가 있으므로 캐시 클리어를 해준다.
            deleteCacheFields(cache, [
              'adminOrderSupportMessages',
              'adminCountOrderSupportStatuses',
            ]);
          },
        });
      },
    });
  };

  return (
    <Modal
      title={'응대요청'}
      onCancel={onCancel}
      centered
      okText={'응대요청'}
      onOk={handleSubmit(onSubmit, onInvalid)}
      // 모달 위에 뜨는 message, alert을 배치하기 위한 z-index 설정
      wrapProps={{
        style: { zIndex: 1000 },
      }}
      styles={{
        mask: { zIndex: 1000 },
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={'grid gap-2 py-2'}>
          <div className={'flex gap-2'}>
            <div className={'flex-1'}>
              <SelectField
                control={control}
                name={'channel'}
                label={'인입채널'}
                required
                options={orderSupportChannelOptions}
              />
            </div>
            <div className={'flex-1'}>
              <SelectField
                control={control}
                name={'requestType'}
                label={'문의유형'}
                required
                options={orderSupportRequestTypeOptions}
              />
            </div>
          </div>
          <div>
            <SimpleEditorField
              control={control}
              name={'message.content'}
              label={'상세내용'}
              required
              placeholder={'텍스트 입력'}
              hasError={!!formState.errors.message?.content}
            />
            {formState.errors?.message?.content && (
              <Typography.Text type={'danger'}>
                {formState.errors?.message?.content.message}
              </Typography.Text>
            )}
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default CreateOrderSupportForm;
