import { ApolloCache } from '@apollo/client';
import { App } from 'antd';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { HistoriesDocument } from '../../../common/graphql/history.generated';
import { deleteCacheFields } from '../../../common/utils/apollo';
import { ROUTES } from '../../../routes/const';
import {
  AdminCountPromotionStatusesDocument,
  AdminPromotionReleasesDocument,
  AdminUpdatePromotionReleaseMutationVariables,
  useAdminUpdatePromotionReleaseMutation,
} from '../graphql/promotionRelease.generated';
import { getUpdateResolver } from '../validator/updateReleaseValidator';

import useReleaseDetail from './useReleaseDetail';

function useUpdateRelease() {
  const navigate = useNavigate();

  const { message, modal } = App.useApp();
  const { releaseId, data, isCompleted } = useReleaseDetail();
  const [updateReleaseMutation] = useAdminUpdatePromotionReleaseMutation();
  const methods = useForm<AdminUpdatePromotionReleaseMutationVariables>({
    defaultValues: {
      adminUpdatePromotionReleaseId: releaseId,
      promotionReleaseData: {
        deliveryMessage: data?.adminPromotionRelease.deliveryMessage,
        note: data?.adminPromotionRelease.note,
        receiverName: data?.adminPromotionRelease.receiverName,
        receiverAddress: data?.adminPromotionRelease.receiverAddress,
        receiverDetailAddress:
          data?.adminPromotionRelease.receiverDetailAddress,
        receiverPhoneNumber: data?.adminPromotionRelease.receiverPhoneNumber,
        scheduledReleaseAt: data?.adminPromotionRelease.scheduledReleaseAt,
        sellerId: data?.adminPromotionRelease.seller?.id,
        sellerSettlementInfoId:
          data?.adminPromotionRelease.sellerSettlementInfoId,
        zipCode: data?.adminPromotionRelease.zipCode,
      },
    },
    resolver: getUpdateResolver(!!data?.adminPromotionRelease.seller),
  });

  const updateRelease = useCallback(
    async (
      adminUpdatePromotionReleaseId: AdminUpdatePromotionReleaseMutationVariables['adminUpdatePromotionReleaseId'],
      promotionReleaseData: AdminUpdatePromotionReleaseMutationVariables['promotionReleaseData'],
      skipStatusUpdate: boolean
    ) => {
      await updateReleaseMutation({
        variables: {
          adminUpdatePromotionReleaseId,
          promotionReleaseData: isCompleted
            ? {
                receiverName: promotionReleaseData.receiverName,
                receiverPhoneNumber: promotionReleaseData.receiverPhoneNumber,
                receiverAddress: promotionReleaseData.receiverAddress,
                note: promotionReleaseData.note,
                skipStatusUpdate,
              }
            : {
                ...promotionReleaseData,
                scheduledReleaseAt:
                  promotionReleaseData.scheduledReleaseAt || null,
                skipStatusUpdate,
              },
        },
        refetchQueries: [
          HistoriesDocument,
          AdminCountPromotionStatusesDocument,
          AdminPromotionReleasesDocument,
        ],
        update: (cache: ApolloCache<any>) =>
          deleteCacheFields(cache, ['adminPromotionReleases']),
        onCompleted() {
          void message.success('저장되었습니다.');
          navigate(ROUTES.PROMOTION_RELEASES.path);
        },
        onError() {
          void message.error('저장을 실패하였습니다.');
        },
      });
    },
    []
  );

  const onSubmit = methods.handleSubmit(
    async ({ adminUpdatePromotionReleaseId, promotionReleaseData }) => {
      const isFuture = dayjs().isBefore(
        promotionReleaseData.scheduledReleaseAt
      );

      if (
        isFuture &&
        data?.adminPromotionRelease.releaseStatus !== 'RELEASE_SCHEDULED'
      ) {
        void modal.confirm({
          type: 'warning',
          title: '출고요청',
          content:
            '출고희망일이 미래입니다. 출고상태를 출고예약으로 변경하시겠습니까?',
          okText: '아니오. 지금의 상태를 유지합니다.',
          cancelText: '네',
          onOk: () =>
            updateRelease(
              adminUpdatePromotionReleaseId,
              promotionReleaseData,
              true
            ),
          onCancel: () =>
            updateRelease(
              adminUpdatePromotionReleaseId,
              promotionReleaseData,
              false
            ),
        });
      } else {
        await updateRelease(
          adminUpdatePromotionReleaseId,
          promotionReleaseData,
          true
        );
      }
    }
  );
  return {
    methods,
    onSubmit,
  };
}

export default useUpdateRelease;
