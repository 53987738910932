import { Descriptions } from 'antd';

import { AdminOrderQuery } from '../graphql/order.generated';
import { convertDate } from '../helper';

type OrderDetailDateProps = {
  order: AdminOrderQuery['adminOrder'];
};

export default function OrderDetailDate({ order }: OrderDetailDateProps) {
  return (
    <Descriptions bordered className='[&_.ant-descriptions-view]:rounded-none'>
      <Descriptions.Item
        label='주문일시'
        span={2}
        labelStyle={{ width: 200 }}
        contentStyle={{ width: 276 }}
      >
        {convertDate(order.group.orderedAt)}
      </Descriptions.Item>
      <Descriptions.Item
        label='결제일시'
        labelStyle={{ width: 200 }}
        contentStyle={{ width: 276 }}
      >
        {convertDate(order.group.paidAt)}
      </Descriptions.Item>
      <Descriptions.Item label='발송일시' span={2}>
        {convertDate(order.initialReleasedAt)}
      </Descriptions.Item>
      <Descriptions.Item label='주문서 변환일시'>
        {convertDate(order.orderConvertedAt)}
      </Descriptions.Item>
    </Descriptions>
  );
}
