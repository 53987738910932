import {
  Button,
  Descriptions,
  Divider,
  Image,
  Radio,
  Select,
  Typography,
} from 'antd';
import { useMemo, useState } from 'react';

import Modal from '../../../../common/components/Modal';
import PageLoader from '../../../../common/components/PageLoader';
import useScreenCapture from '../../../../common/hooks/useScreenCapture';
import { SALES_TYPE_OPTIONS } from '../../const';
import { useProductGroupQuery } from '../../graphql/productGroup.generated';
import { proposalMapper } from '../../helper/mapper';
import { SaleType } from '../../types';

import ProposalShippingTable from './ProposalShippingTable';
import ProductProposalTable from './ProposalTable';

type ProposalModalProps = {
  onClose: () => void;
  productGroupId: number;
};

export default function ProposalModal({
  onClose,
  productGroupId,
}: ProposalModalProps) {
  const [showLowestPriceOnline, setShowLowestPriceOnline] = useState(true);
  const [salesType, setSalesType] = useState<SaleType>('BUSINESS');
  const { captureImage, captureRef, loading } = useScreenCapture();
  const { data } = useProductGroupQuery({
    variables: { adminProductGroupId: productGroupId },
  });

  const productGroup = data?.adminProductGroup;
  const proposalProductData = useMemo(
    () => (productGroup ? proposalMapper(productGroup) : []),
    [productGroup]
  );

  const multimedia = productGroup?.multimedia;
  const thumbnail = (
    useMemo(
      () =>
        multimedia
          ?.filter((media) => media.useType === 'THUMBNAIL')
          .map((thumbnail) => thumbnail.url),
      [multimedia]
    ) ?? []
  ).slice(0, 13);

  const mainThumbnail = thumbnail.shift();

  return (
    <>
      <Modal
        title='썸네일 전체보기'
        footer={null}
        width={1200}
        onCancel={onClose}
        className='relative bg-white [&_.ant-modal-content]:p-0 [&_.ant-modal-header]:px-6 [&_.ant-modal-header]:pt-5'
      >
        <div ref={captureRef} className='relative bg-white px-6 pb-5 pt-4'>
          {loading && <div className='h-8' />}
          <div className='mb-6 flex gap-6'>
            <Image
              width={480}
              height={480}
              src={mainThumbnail}
              className='object-cover'
              preview={false}
            />
            <div className='flex flex-1 flex-col justify-between'>
              <div>
                <Typography.Title level={2} className='mb-4'>
                  {productGroup?.name}
                </Typography.Title>
                {!loading && (
                  <Descriptions
                    bordered
                    column={1}
                    className='[&>.ant-descriptions-view]:rounded-none'
                    labelStyle={{ width: 160 }}
                    size={'middle'}
                  >
                    <Descriptions.Item key={'type'} label={'판매유형'}>
                      <Select
                        className='w-full max-w-[326px]'
                        options={SALES_TYPE_OPTIONS}
                        defaultValue={'BUSINESS'}
                        onChange={(value: SaleType) => setSalesType(value)}
                      />
                    </Descriptions.Item>
                    <Descriptions.Item key={'online'} label={'온라인최저가'}>
                      <Radio.Group
                        defaultValue={true}
                        onChange={(e) => {
                          setShowLowestPriceOnline(e.target.value as boolean);
                        }}
                      >
                        <Radio value={true}>노출</Radio>
                        <Radio value={false}>비노출</Radio>
                      </Radio.Group>
                    </Descriptions.Item>
                  </Descriptions>
                )}
              </div>
              {thumbnail.length > 1 && (
                <div>
                  <Typography.Title level={5}>상품이미지</Typography.Title>
                  <ul className='flex flex-wrap gap-2'>
                    {thumbnail.map((image, index) => (
                      <li key={index} className='h-[100px] w-[100px]'>
                        <Image
                          src={image}
                          width={'100%'}
                          height={'100%'}
                          className='object-cover'
                          preview={false}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className='mb-4'>
            <ProductProposalTable
              data={proposalProductData}
              loading={loading}
              showLowestPriceOnline={showLowestPriceOnline}
              salesType={salesType}
            />
          </div>
          <div className='mt-6'>
            <Divider orientation='left'>배송정보</Divider>
            <ProposalShippingTable
              deliveryFeeType={productGroup?.deliveryFeeType ?? 'FREE'}
              deliveryFee={productGroup?.deliveryFee ?? 0}
              freeDeliveryConditionFee={
                productGroup?.freeDeliveryConditionFee ?? 0
              }
              jejuIslandFee={productGroup?.jejuIslandFee ?? 0}
              additionalFee={productGroup?.additionalFee ?? 0}
            />
          </div>
          {loading && <div className='h-8' />}
          {!loading && (
            <div className='mt-4 h-8'>
              <div className='flex justify-end gap-2'>
                <Button onClick={onClose}>취소</Button>
                <Button
                  type='primary'
                  onClick={captureImage}
                  disabled={loading}
                >
                  이미지 다운로드
                </Button>
              </div>
            </div>
          )}
        </div>
        {loading && <PageLoader overlay />}
      </Modal>
    </>
  );
}
