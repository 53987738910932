import {
  EBrandStatus,
  EVendorContactType,
  EVendorStatus,
} from '../../schema/types';

export const CONTACT_ROLES: {
  label: string;
  value: EVendorContactType;
}[] = [
  { label: '총괄', value: 'OVERALL' },
  { label: 'CS', value: 'CS' },
  { label: '발주', value: 'ORDER' },
  { label: '정산', value: 'SETTLEMENT' },
  { label: 'MD', value: 'MD' },
  { label: '기타', value: 'ETC' },
];

export const VENDOR_STATUS: {
  label: string;
  value: EVendorStatus | '';
}[] = [
  { label: '전체', value: '' },
  { label: '거래중', value: 'ACTIVE' },
  { label: '거래중단', value: 'INACTIVE' },
];

export const DEFAULT_CONTACT_VALUE = {
  type: CONTACT_ROLES[0].value,
  name: '',
  email: '',
  phone: '',
};

export const DEFAULT_BRAND_VALUE = {
  name: '',
  status: 'ACTIVE' as EBrandStatus,
  brandContacts: [DEFAULT_CONTACT_VALUE],
};

export const DATE_ITEMS = Array(31)
  .fill(true)
  .map((_, i) => ({ label: `${i + 1}일`, value: `${i + 1}` }))
  .concat([{ label: '말일', value: 'LAST' }]);

export const SETTLEMENT_WARNING_DATES = ['29', '30', '31'];

export const DEFAULT_VENDORS_QUERY_VARIABLE = {
  vendorsInput: {
    pagination: {
      skip: 0,
      take: 50,
    },
    search: {},
    sortings: [{ field: 'UPDATED_AT', order: 'DESC' }],
  },
};
