import { Descriptions, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useFormContext } from 'react-hook-form';

import Label from '../../../common/components/Fields/Label';
import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import SelectField from '../../../common/components/Fields/SelectField';
import TextField from '../../../common/components/Fields/TextField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { PRODUCT_GROUP_TYPE } from '../const';

import BrandSelectField from './Fields/BrandSelectField';
import CategoriesSelectField from './Fields/CategoriesSelectField';
import StatusSelectField from './Fields/StatusSelectField';
import VendorSelectField from './Fields/VendorSelectField';

export default function ProductBasicInformation() {
  const { hasPermission } = usePermissions('PRODUCT_EDIT');
  const { control, setValue } = useFormContext();

  return (
    <div className={'flex flex-col gap-xl'}>
      <Typography.Title level={4} className={'m-0'}>
        상품 기본정보
      </Typography.Title>
      <Descriptions
        bordered
        column={2}
        labelStyle={{ width: 200 }}
        contentStyle={{ padding: '12px 24px' }}
      >
        <Descriptions.Item
          label={<Label required={hasPermission}>담당자</Label>}
          span={2}
        >
          <ManagerSelectField
            className={'w-[310px]'}
            name={'managerId'}
            control={control}
            disabled={!hasPermission}
            hideErrorMessage
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={<Label required={hasPermission}>상품명</Label>}
        >
          <TextField
            className={'w-[310px]'}
            control={control}
            name={'name'}
            placeholder={'상품명'}
            maxLength={50}
            disabled={!hasPermission}
            hideErrorMessage
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={<Label required={hasPermission}>상품구분</Label>}
        >
          <SelectField
            control={control}
            name={'productGroupType'}
            options={PRODUCT_GROUP_TYPE}
            defaultValue={'DISTRIBUTION'}
            hideErrorMessage
            disabled={!hasPermission}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={<Label required={hasPermission}>거래상태</Label>}
          span={2}
        >
          <StatusSelectField
            className={'w-[310px]'}
            control={control}
            name={'status'}
            disabled={!hasPermission}
            hideErrorMessage
          />
        </Descriptions.Item>
        <Descriptions.Item label={<Label required={hasPermission}>업체</Label>}>
          <VendorSelectField
            className={'w-[310px]'}
            name={'vendorId'}
            control={control}
            placeholder={'업체명'}
            disabled={!hasPermission}
            hideErrorMessage
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={<Label required={hasPermission}>브랜드</Label>}
        >
          <BrandSelectField
            className={'w-[310px]'}
            name={'brandId'}
            control={control}
            placeholder={'브랜드명'}
            onChange={(_, option) => {
              const label = (option as DefaultOptionType).label as string;
              setValue('brandName', label);
            }}
            disabled={!hasPermission}
            hideErrorMessage
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={<Label required={hasPermission}>카테고리</Label>}
        >
          <CategoriesSelectField disabled={!hasPermission} hideErrorMessage />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
