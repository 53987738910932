import { DragOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button } from 'antd';
import React from 'react';

import useAntdTheme from '../../../../common/hooks/useAntdTheme';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

export default function Row({ children, ...props }: RowProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      className='group h-[104px]'
    >
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <div ref={setActivatorNodeRef} {...listeners}>
                <DragButton />
              </div>
            ),
            className: 'tooltip-button w-0 p-0 border-0 relative', // drag column 숨김
          });
        }

        return child;
      })}
    </tr>
  );
}

const DragButton = () => {
  return (
    <div className='absolute -left-10 top-2 z-10 hidden flex-col items-center group-hover:flex'>
      <Button
        style={{ touchAction: 'none', cursor: 'move' }}
        icon={<DragOutlined />}
        className='peer'
      />
      <div className='pointer-events-none absolute -top-9 left-[50%] translate-x-[-50%] opacity-0 transition peer-hover:opacity-100'>
        <Tooltip />
      </div>
    </div>
  );
};

const Tooltip = () => {
  const { colorBgSpotlight } = useAntdTheme();

  return (
    <div
      className='flex h-[32px] min-w-[32px] items-center rounded-md px-2 py-1.5 text-white'
      style={{ backgroundColor: colorBgSpotlight }}
    >
      move
    </div>
  );
};
