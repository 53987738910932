import { SelectProps } from 'antd';
import { useCallback } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import { FieldProps } from '../../../../common/components/Fields/types';
import AsyncSelect from '../../../../common/components/Input/AsyncSelect';
import { useCommonVendorsLazyQuery } from '../../graphql/vendor.generated';
import { ProductGroupFormValues } from '../../types';

type VendorSelectFieldProps<TFieldValues extends FieldValues> = SelectProps &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
  };

export default function VendorSelectField<TFieldValues extends FieldValues>({
  label,
  required,
  hideError,
  hideErrorMessage,
  ...props
}: VendorSelectFieldProps<TFieldValues>) {
  const { control, getValues, setValue } =
    useFormContext<ProductGroupFormValues>();
  const [fetchVendor] = useCommonVendorsLazyQuery({});

  const fetchItems = useCallback(
    async (v: string) => {
      const { data } = await fetchVendor({
        variables: {
          vendorsInput: {
            sortings: [{ field: 'UPDATED_AT', order: 'DESC' }],
            search: {
              vendorNameOrCode: v,
            },
            pagination: { skip: 0, take: 20 },
          },
        },
      });

      return (data?.adminVendors.vendors || []).map((vendor) => {
        return {
          label: vendor.name,
          value: vendor.id,
          data: vendor,
        };
      });
    },
    [fetchVendor]
  );

  return (
    <Controller
      name={'vendorId'}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const name = getValues('vendorName') ?? String(field.value);

        const initialOption = field.value
          ? {
              label: name,
              value: field.value,
            }
          : undefined;

        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;
        const status = !hideError && errorMessage ? 'error' : '';

        return (
          <FieldWithLabel label={label} required={required}>
            <AsyncSelect
              initialLoad
              className={'w-full'}
              initialOption={initialOption}
              fetchItems={fetchItems}
              status={status}
              {...field}
              {...props}
              onChange={(v, option) => {
                const label = (option as { label: string })?.label;
                setValue('brandId', null);
                setValue('brandName', null);
                setValue('vendorName', label);

                field.onChange(v);
                props.onChange?.(v, option);
              }}
            />

            {!hideErrorMessage && !hideError && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}
