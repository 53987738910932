import { LinkOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { sumBy } from 'lodash-es';

import { bankLabels } from '../../../../common/utils/const';
import { ROUTES } from '../../../../routes/const';
import PdfButton from '../../components/settlement-sheet-list/PdfButton';
import { SETTLEMENT_SHEET_STATUS_LABEL } from '../../const';
import { SettlementSheetListTableDataType } from '../../types';

import TaxInvoiceInssuedAtCell from './TaxInvoiceInssuedAtCell';
import TaxInvoiceIssuedCell from './TaxInvoiceIssuedCell';
import VendorFinalSettlementAmountCell from './VendorFinalSettlementAmountCell';

export const columns: ColumnsType<SettlementSheetListTableDataType> = [
  {
    title: '정산서코드',
    dataIndex: 'code',
    width: 180,
    render: (_, record) => (
      <div className='flex items-center justify-between'>
        {record.code}
        <Button
          type='link'
          icon={<LinkOutlined />}
          href={`${ROUTES.SETTLEMENTS_LIST.path}?settlementSheetCode=${record.code}`}
          target='_blank'
          rel='noopener noreferer'
        />
      </div>
    ),
  },
  { title: '업체명', dataIndex: 'vendorName', width: 240 },
  { title: '정산일자', dataIndex: '정산일자', width: 120 },
  {
    title: '정산상태',
    dataIndex: 'status',
    width: 170,
    render: (_, record) => SETTLEMENT_SHEET_STATUS_LABEL[record.status],
  },
  {
    title: '매출금액',
    dataIndex: 'salesAmount',
    width: 120,
    render: (_, record) => record.salesAmount.toLocaleString(),
  },
  { title: '상품공급가', dataIndex: 'supplyPrice', width: 120 },
  {
    title: '배송비',
    dataIndex: 'deliveryFee',
    width: 120,
    render: (_, record) => record.deliveryFee.toLocaleString(),
  },
  {
    title: '샘플금액',
    dataIndex: 'sampleAmount',
    width: 120,
    render: (_, record) => record.sampleAmount.toLocaleString(),
  },
  {
    title: '이벤트금액',
    dataIndex: 'eventAmount',
    width: 120,
    render: (_, record) => record.eventAmount.toLocaleString(),
  },
  {
    title: '기타금액',
    dataIndex: 'etcAmount',
    width: 120,
    render: (_, record) => record.etcAmount.toLocaleString(),
  },
  {
    title: '반품배송비',
    dataIndex: 'refundDeliveryFeeAmount',
    width: 120,
    render: (_, record) => record.refundDeliveryFeeAmount.toLocaleString(),
  },
  {
    title: '교환배송비',
    dataIndex: 'exchangeDeliveryFeeAmount',
    width: 120,
    render: (_, record) => record.exchangeDeliveryFeeAmount.toLocaleString(),
  },
  {
    title: '최종정산금액',
    dataIndex: 'finalSettlementAmount',
    width: 200,
    render: (_, record) => (
      <VendorFinalSettlementAmountCell
        settlementSheetId={record.id}
        lastChangedAt={
          record.finalSettlementAmountLastChangedAt
            ? dayjs(record.finalSettlementAmountLastChangedAt).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : null
        }
        lastChangedOperatorName={
          record.finalSettlementAmountLastChangedOperatorName
        }
        finalSettlementAmount={
          typeof record.adjustmentAmount === 'number'
            ? record.adjustmentAmount
            : record.finalSettlementAmount
        }
      />
    ),
  },
  {
    title: '계산서 발행',
    dataIndex: 'isTaxInvoiceIssued',
    width: 120,
    render: (_, record) => (
      <TaxInvoiceIssuedCell
        settlementSheetId={record.id}
        isIssued={record.isTaxInvoiceIssued}
      />
    ),
  },
  {
    title: '계산서 발행일',
    dataIndex: 'taxInvoiceIssuedAt',
    width: 160,
    render: (_, record) => (
      <TaxInvoiceInssuedAtCell
        settlementSheetId={record.id}
        issuedAt={record.taxInvoiceIssuedAt}
      />
    ),
  },
  {
    title: '정산서 생성일',
    dataIndex: 'createdAt',
    width: 120,
    render: (_, record) => dayjs(record.createdAt).format('YYYY.MM.DD'),
  },
  {
    title: '정산완료일',
    dataIndex: 'settlementFinishedAt',
    width: 120,
    render: (_, record) =>
      record.settlementFinishedAt
        ? dayjs(record.settlementFinishedAt).format('YYYY.MM.DD')
        : '-',
  },
  {
    title: '입금은행',
    dataIndex: 'bank',
    width: 120,
    render: (_, record) => (record.bank ? bankLabels[record.bank] : '-'),
  },
  { title: '입금계좌번호', dataIndex: 'accountNumber', width: 160 },
  { title: '예금주명', dataIndex: 'accountHolderName', width: 160 },
  {
    width: 120,
    title: '정산서PDF',
    key: 'pdf',
    render: (_, record) => <PdfButton settlementSheetId={record.id} />,
  },
];

export const summaryRenderer = (
  dataSource: readonly SettlementSheetListTableDataType[],
  hasPermission: boolean
) => {
  const salesAmount = sumBy(dataSource, (item) => item.salesAmount);
  const supplyPrice = sumBy(dataSource, (item) => item.supplyPrice || 0);
  const deliveryFee = sumBy(dataSource, (item) => item.deliveryFee);
  const sampleAmount = sumBy(dataSource, (item) => item.sampleAmount);
  const eventAmount = sumBy(dataSource, (item) => item.eventAmount);
  const etcAmount = sumBy(dataSource, (item) => item.etcAmount);
  const refundDeliveryFeeAmount = sumBy(
    dataSource,
    (item) => item.refundDeliveryFeeAmount
  );
  const exchangeDeliveryFeeAmount = sumBy(
    dataSource,
    (item) => item.exchangeDeliveryFeeAmount
  );
  const finalSettlementAmount = sumBy(dataSource, (item) =>
    typeof item.adjustmentAmount === 'number'
      ? item.adjustmentAmount
      : item.finalSettlementAmount
  );
  return (
    <Table.Summary fixed='top'>
      <Table.Summary.Row className='bg-[#fafafa] font-semibold'>
        {hasPermission && <Table.Summary.Cell index={-1} colSpan={1} />}
        <Table.Summary.Cell index={0} colSpan={4}>
          총계
        </Table.Summary.Cell>
        <Table.Summary.Cell index={1}>
          {salesAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}>
          {supplyPrice.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          {deliveryFee.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4}>
          {sampleAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          {eventAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}>
          {etcAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={7}>
          {refundDeliveryFeeAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={8}>
          {exchangeDeliveryFeeAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={9}>
          {finalSettlementAmount.toLocaleString()}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={0} colSpan={8}></Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};
