import { Switch, SwitchProps } from 'antd';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type SwitchFieldProps<TFieldValues extends FieldValues> = SwitchProps & {
  label?: string;
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
};

function SwitchField<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  onChange,
  ...props
}: SwitchFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className='flex items-center gap-2'>
          {label && <label>{label}</label>}
          <Switch
            /**
             * size = small을 default로 하고 덮어쓰기해서 시용
             */
            size={'small'}
            checked={field.value}
            {...field}
            {...props}
            onChange={(value, option) => {
              field.onChange(value);
              onChange?.(value, option);
            }}
          />
        </div>
      )}
    />
  );
}

export default SwitchField;
