import { App, Button, Table, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Modal from '../../../../common/components/Modal';
import { ERROR } from '../../../../common/constants/error';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { deleteCacheFields } from '../../../../common/utils/apollo';
import { ROUTES } from '../../../../routes/const';
import { useDeleteTemporaryProductMutation } from '../../graphql/productGroup.generated';
import { useTemporaryProductsQuery } from '../../graphql/productGroups.generated';
import { TemporaryProductDataType } from '../../types';

type TemporaryModalProps = {
  onClose: () => void;
};

const PAGE_SIZE = 15;

export default function TemporaryModal({ onClose }: TemporaryModalProps) {
  const { hasPermission } = usePermissions('PRODUCT_EDIT');
  const { message } = App.useApp();
  const [currentPage, setCurrentPage] = useState(1);
  const { data, refetch } = useTemporaryProductsQuery({
    variables: {
      input: {
        pagination: {
          skip: PAGE_SIZE * (currentPage - 1),
          take: PAGE_SIZE,
        },
      },
    },
  });

  const temporaryProductData = data?.adminTemporaryProducts.edges.map(
    ({ node }) => {
      return {
        key: node.id,
        id: node.id,
        name: node.name,
        updatedAt: node.updatedAt,
        managerName: node.managerName,
      };
    }
  );

  const handleTableChange = (pagination: TablePaginationConfig) => {
    pagination.current && setCurrentPage(pagination.current);
  };

  const [deleteTemporaryProduct] = useDeleteTemporaryProductMutation();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleDeleteTemporary = async (temporaryId: number) => {
    await deleteTemporaryProduct({
      variables: {
        adminDeleteTemporaryProductId: temporaryId,
      },
      onCompleted() {
        message.success('임시저장 상품이 삭제되었어요');
      },
      update: (cache) => deleteCacheFields(cache, ['adminTemporaryProducts']),
      onError({ graphQLErrors, message: errorMessage }) {
        if (graphQLErrors[0].extensions.code === ERROR.NOT_FOUND) {
          message.error('이미 삭제된 임시저장 상품입니다');
        } else {
          const text = errorMessage || '임시저장 상품 삭제에 실패했습니다';
          message.error(text);
        }
      },
    });
  };

  const columns: ColumnsType<TemporaryProductDataType> = [
    {
      title: '상품명',
      dataIndex: 'title',
      render: (_, record) => (
        <Link to={`${ROUTES.PRODUCT_NEW.path}?temporaryId=${record.id}`}>
          {record.name}
        </Link>
      ),
      width: 600,
    },
    {
      title: '최종 업데이트',
      dataIndex: 'updatedAt',
      render: (_, record) => (
        <Typography.Text type='secondary'>
          {dayjs(record.updatedAt).format('YYYY.MM.DD')} 업데이트 (
          {record.managerName})
        </Typography.Text>
      ),
      width: 280,
    },
    {
      title: '삭제',
      render: (_, record) => (
        <Button
          danger
          type={'text'}
          onClick={() => handleDeleteTemporary(record.id)}
          disabled={!hasPermission}
        >
          삭제
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title='임시저장 리스트'
      footer={null}
      width={1048}
      onCancel={onClose}
    >
      <Table
        dataSource={temporaryProductData}
        columns={columns}
        className='pt-6'
        pagination={{
          pageSize: PAGE_SIZE,
          current: currentPage,
          total: data?.adminTemporaryProducts.totalCount,
        }}
        onChange={handleTableChange}
      />
    </Modal>
  );
}
