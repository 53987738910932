import { FilterOutlined } from '@ant-design/icons';
import { App, Button, DatePicker, Input, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { ChangeEvent, useState } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { EOrderPeriodFilter } from '../../../schema/types';
import { PERIOD_OPTIONS } from '../const';
import { OrderListSearchParams } from '../types';

import SearchFilterModal from './SearchFilterModal';
import SearchTag from './SearchTag';

export default function Search() {
  const { message } = App.useApp();
  const [filterModal, setFilterModal] = useState(false);

  const { searchParams, setSearchParams } =
    useRouteSearchParams<OrderListSearchParams>();

  const { endedAt, startedAt, periodType, searchKeyword } = searchParams;

  const handleSearchKeyword = debounce((ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    setSearchParams({
      searchKeyword: value,
      currentPage: undefined,
      summaryStatus: undefined,
    });
  }, 500);

  const handlePeriodType = (v: EOrderPeriodFilter) => {
    const startedAtInPeriodType =
      startedAt || endedAt ? startedAt : dayjs().add(-7, 'day').toISOString();
    const endedAtInPeriodType =
      startedAt || endedAt ? endedAt : dayjs().toISOString();

    setSearchParams({
      periodType: v,
      startedAt: startedAtInPeriodType,
      endedAt: endedAtInPeriodType,
      currentPage: undefined,
      summaryStatus: undefined,
    });
  };

  const handleStartedAt = (v: dayjs.Dayjs | null) => {
    if (!v) {
      if (!endedAt) {
        setSearchParams({
          periodType: undefined,
          startedAt: undefined,
          endedAt: undefined,
          currentPage: undefined,
          summaryStatus: undefined,
        });

        return;
      }

      setSearchParams({
        periodType,
        startedAt: undefined,
        endedAt,
        currentPage: undefined,
        summaryStatus: undefined,
      });

      return;
    }

    let newStartedAt = v.toISOString();
    let newEndedAt = endedAt ?? dayjs().toISOString();

    if (endedAt) {
      const maxStartedAt = v.add(3, 'month').toISOString();
      const isBetween = dayjs(endedAt).isBetween(v, maxStartedAt, 'day', '[]');

      if (v.isAfter(endedAt)) {
        newStartedAt = endedAt;
      } else if (!isBetween) {
        void message.warning('최대 3개월까지 조회 가능합니다.');
        newEndedAt = maxStartedAt;
      }
    }

    setSearchParams({
      periodType: periodType ? periodType : 'ORDERED_AT',
      startedAt: newStartedAt,
      endedAt: newEndedAt,
      currentPage: undefined,
      summaryStatus: undefined,
    });
  };

  const handleEndedAt = (v: dayjs.Dayjs | null) => {
    if (!v) {
      if (!startedAt) {
        setSearchParams({
          periodType: undefined,
          startedAt: undefined,
          endedAt: undefined,
          currentPage: undefined,
          summaryStatus: undefined,
        });

        return;
      }

      setSearchParams({
        periodType,
        startedAt,
        endedAt: undefined,
        currentPage: undefined,
        summaryStatus: undefined,
      });

      return;
    }

    let newEndedAt = v.toISOString();
    let newStartedAt = startedAt;

    if (startedAt) {
      const minEndedAt = v.add(-3, 'month').toISOString();
      const isBetween = dayjs(startedAt).isBetween(minEndedAt, v, 'day', '[]');

      if (v.isBefore(startedAt)) {
        newEndedAt = startedAt;
      } else if (!isBetween) {
        void message.warning('최대 3개월까지 조회 가능합니다.');
        newStartedAt = minEndedAt;
      }
    }

    setSearchParams({
      periodType: periodType ? periodType : 'ORDERED_AT',
      startedAt: newStartedAt,
      endedAt: newEndedAt,
      currentPage: undefined,
      summaryStatus: undefined,
    });
  };

  return (
    <>
      <Typography.Title level={5}>검색</Typography.Title>
      <div className='mb-4'>
        <Select
          options={PERIOD_OPTIONS}
          className='mr-2 w-[132px]'
          value={periodType}
          onChange={handlePeriodType}
          placeholder='--선택--'
        />
        <DatePicker
          className='w-[162px]'
          onChange={handleStartedAt}
          value={startedAt ? dayjs(startedAt) : undefined}
        />
        <span className='mx-2'>~</span>
        <DatePicker
          className='w-[162px]'
          onChange={handleEndedAt}
          value={endedAt ? dayjs(endedAt) : undefined}
        />
      </div>
      <div className='flex gap-2'>
        <Input
          placeholder='판매채널주문번호, 판매채널상품주문번호, 주문자명, 수령자명, 수령자 휴대폰번호 입력'
          className='w-[616px]'
          onChange={handleSearchKeyword}
          defaultValue={searchKeyword}
        />
        <Button
          icon={<FilterOutlined />}
          className='flex items-center justify-center'
          onClick={() => setFilterModal(true)}
        />
      </div>
      <SearchTag />
      {filterModal && (
        <SearchFilterModal onClose={() => setFilterModal(false)} />
      )}
    </>
  );
}
