import { FormProvider } from 'react-hook-form';

import useCreateProductGroupFormValues from '../hooks/useCreateProductGroupFormValues';
import { CreateProductGroupFormValues } from '../types';

import ProductGroupForm from './ProductGroupForm';

type CreateProductGroupFormProps = {
  defaultValues?: CreateProductGroupFormValues;
};

export default function CreateProductGroupForm({
  defaultValues,
}: CreateProductGroupFormProps) {
  const { methods, onSubmit } = useCreateProductGroupFormValues(defaultValues);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ProductGroupForm />
      </form>
    </FormProvider>
  );
}
