import { Image } from '@tiptap/extension-image';
import { useEditor, EditorContent } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';

type HomeContentsProps = {
  content: string;
};

const extensions = [StarterKit, Image];

export default function HomeContents({ content }: HomeContentsProps) {
  const editor = useEditor({
    extensions,
    content,
    editorProps: {
      attributes: {
        class: 'prose focus:outline-none',
      },
    },
    editable: false,
  });

  return <EditorContent editor={editor} className='w-full max-w-[708px]' />;
}
