import { Radio, RadioGroupProps } from 'antd';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from './FieldWithLabel';
import { FieldProps } from './types';

type RadioFieldProps<TFieldValues extends FieldValues> = RadioGroupProps &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
  };

function RadioField<TFieldValues extends FieldValues>({
  name,
  label,
  required,
  control,
  ...props
}: RadioFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FieldWithLabel label={label} required={required}>
          <Radio.Group {...field} {...props} />
        </FieldWithLabel>
      )}
    />
  );
}

export default RadioField;
