import { Typography } from 'antd';
import { useFormContext } from 'react-hook-form';

import CheckboxField from '../../../common/components/Fields/CheckboxField';
import NumberField from '../../../common/components/Fields/NumberField';
import TextareaField from '../../../common/components/Fields/TextareaField';
import TextField from '../../../common/components/Fields/TextField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { ProductGroupFormValues } from '../types';

import ProductArchive from './Archive';
import ScreenShotAndImageUploadField from './Fields/ScreenShotAndImageUploadField';
import SimpleEditorField from './Fields/SimpleEditorField';
import ProductShippingInformation from './ProductShippingInformation';

export default function ProductBasicInformation() {
  const { control, watch, setValue } = useFormContext<ProductGroupFormValues>();
  const isPurchaseQuantityNoLimit = watch('isPurchaseQuantityNoLimit');
  const { hasPermission } = usePermissions('PRODUCT_EDIT');

  return (
    <div>
      <Typography.Title level={4} className={'mb-6'}>
        상품 상세정보
      </Typography.Title>
      <div className='flex gap-xl'>
        <div className='w-[200px]'>
          <ScreenShotAndImageUploadField
            control={control}
            name='productImage'
            disabled={!hasPermission}
            hideErrorMessage
          />
        </div>
        <div className='flex flex-1 flex-col gap-xl'>
          <div className='w-[472px]'>
            <SimpleEditorField
              control={control}
              name='notice'
              label='공지사항'
              disabled={!hasPermission}
            />
          </div>
          <div className='w-[472px]'>
            <TextField
              name='productUrl'
              control={control}
              label='상품 URL'
              placeholder={'링크입력'}
              disabled={!hasPermission}
            />
          </div>
          <div className='w-[472px]'>
            <SimpleEditorField
              control={control}
              name='productUsp'
              label='상품 USP'
              disabled={!hasPermission}
            />
          </div>
          <TextareaField
            placeholder={'200만원 이상 샘플지원 가능'}
            control={control}
            name='sampleSupport'
            label='샘플지원'
            className='h-8 w-[472px]'
            disabled={!hasPermission}
          />
          <div className='flex items-end gap-2'>
            <div className='w-[472px]'>
              <NumberField
                required={hasPermission}
                placeholder={isPurchaseQuantityNoLimit ? '무제한' : '수량입력'}
                control={control}
                name='purchaseQuantityLimit'
                label='구매수량제한'
                disabled={!hasPermission || !!isPurchaseQuantityNoLimit}
                hideErrorMessage
              />
            </div>
            <div className='flex h-8 items-center'>
              <CheckboxField
                control={control}
                name='isPurchaseQuantityNoLimit'
                onChange={(value) => {
                  if (value.target.checked) {
                    setValue('purchaseQuantityLimit', null);
                  }
                }}
                disabled={!hasPermission}
              >
                제한없음
              </CheckboxField>
            </div>
          </div>

          <TextareaField
            placeholder={'내용입력'}
            control={control}
            name='note'
            label='비고'
            className='h-8 w-[472px]'
            disabled={!hasPermission}
          />
        </div>
      </div>
      <div className='mt-6'>
        <Typography.Title level={4} className={'mb-6'}>
          배송정보
        </Typography.Title>
        <div className='w-[472px]'>
          <ProductShippingInformation />
        </div>
        <ProductArchive editable={hasPermission} />
      </div>
    </div>
  );
}
