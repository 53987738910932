import { PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Empty,
  Pagination,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { usePermissions } from '../common/hooks/usePermissions';
import useRouteSearchParams from '../common/hooks/useRouteSearchParams';
import ListSearchForm from '../domain/product/components/ListSearchForm';
import ProductCardList from '../domain/product/components/ProductCardList';
import TemporaryModal from '../domain/product/components/Temporary/TemporaryModal';
import { PRODUCT_GROUP_ORDER } from '../domain/product/const';
import {
  useProductGroupsQuery,
  useTemporaryProductsTotalCountQuery,
} from '../domain/product/graphql/productGroups.generated';
import {
  ProductGroupOrder,
  ProductGroupsListSearchParams,
} from '../domain/product/types';

export default function ProductList() {
  const { hasPermission } = usePermissions('PRODUCT_EDIT');
  const [open, setOpen] = useState(false);

  const { searchParams, setSearchParams } =
    useRouteSearchParams<ProductGroupsListSearchParams>();

  const categoryId = searchParams.categoryId
    ? Number(searchParams.categoryId)
    : undefined;
  const upperCategoryId = searchParams.upperCategoryId
    ? Number(searchParams.upperCategoryId)
    : undefined;
  const uppermostCategoryId = searchParams.uppermostCategoryId
    ? Number(searchParams.uppermostCategoryId)
    : undefined;
  const managerIds = searchParams.managerIds?.map((manager) => Number(manager));
  const currentPage = Number(searchParams.currentPage || 1);
  const pageSize = Number(searchParams.pageSize || 40);
  const productGroupType = searchParams.productGroupType;

  const sortingField = searchParams.sortingField;

  const filter = searchParams.search
    ? {
        searchKeyword: searchParams.search,
      }
    : {
        statuses: searchParams.statuses,
        managerIds,
        categoryId: categoryId,
        upperCategoryId: categoryId ? undefined : upperCategoryId,
        uppermostCategoryId:
          categoryId || upperCategoryId ? undefined : uppermostCategoryId,
        productGroupType,
      };

  const getOrder = (sortingField: ProductGroupOrder, sortingOrder: string) => {
    if (sortingField === 'updatedAt') {
      return {
        [sortingField]: sortingOrder,
      };
    } else {
      return {
        products: {
          isPrimary: sortingOrder,
          [sortingField]: sortingOrder,
        },
      };
    }
  };

  const order =
    searchParams.sortingField && searchParams.sortingOrder
      ? getOrder(
          searchParams.sortingField as ProductGroupOrder,
          searchParams.sortingOrder
        )
      : {
          updatedAt: 'DESC',
        };

  const handlePageChange = (page: number, size: number) => {
    if (size !== pageSize) {
      handleSearch({ currentPage: '1', pageSize: size.toString() });
    } else {
      handleSearch({ currentPage: page.toString() });
    }
  };

  const handleSearch = (params: ProductGroupsListSearchParams) => {
    setSearchParams(params);
  };

  const { data, loading } = useProductGroupsQuery({
    variables: {
      filter,
      order,
      pagination: {
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      },
    },
  });

  const productGroups = data?.adminProductGroups?.edges.map(({ node }) => ({
    ...node,
    key: node.id,
    editable: hasPermission,
  }));

  const totalCount = data?.adminProductGroups?.totalCount || 0;

  const isNoResult = !loading && !productGroups?.length;

  const { data: TemporaryData } = useTemporaryProductsTotalCountQuery({
    variables: {
      input: {
        pagination: {
          skip: 0,
          take: 1,
        },
      },
    },
  });

  const temporaryTotalCount =
    TemporaryData?.adminTemporaryProducts.totalCount || 0;

  const handleOrder = (orderField: ProductGroupOrder) => {
    handleSearch({
      search: undefined,
      currentPage: undefined,
      sortingField: orderField,
      sortingOrder: 'DESC',
    });
  };

  return (
    <>
      <div className='flex flex-col gap-4 pb-xl'>
        <ListSearchForm onSearch={handleSearch} />
        {!!temporaryTotalCount && (
          <Alert
            message={`임시저장 리스트 (${temporaryTotalCount}건)`}
            type='info'
            showIcon
            action={
              <Button type='primary' onClick={() => setOpen(true)}>
                보러가기
              </Button>
            }
          />
        )}
        <div className='bg-white p-6'>
          <div className='mb-4 flex items-center justify-between'>
            <Typography.Title level={4} className='mb-0'>
              상품리스트
            </Typography.Title>
            <Link to={'/products/new'}>
              <Button
                type='primary'
                icon={<PlusOutlined />}
                disabled={!hasPermission}
              >
                상품등록
              </Button>
            </Link>
          </div>
          {!isNoResult && (
            <div className='flex h-[38px] items-center justify-end'>
              <Space wrap>
                <Select
                  defaultValue='updatedAt'
                  bordered={false}
                  className='[&_span]:text-[#1677FF]'
                  options={PRODUCT_GROUP_ORDER}
                  onChange={handleOrder}
                  value={sortingField as ProductGroupOrder}
                />
              </Space>
            </div>
          )}
          {isNoResult && (
            <div className={'flex h-[500px] items-center justify-center'}>
              <Empty
                description={
                  <Typography.Paragraph type={'secondary'}>
                    검색결과가 없어요
                  </Typography.Paragraph>
                }
              />
            </div>
          )}
          {!isNoResult && (
            <Spin tip='Loading...' spinning={loading}>
              <ProductCardList data={productGroups || []} />
              <div className='mt-4 flex items-center justify-end gap-4'>
                <div>{`총 ${totalCount}건`}</div>
                <Pagination
                  showSizeChanger
                  onChange={handlePageChange}
                  current={currentPage}
                  pageSize={pageSize}
                  total={totalCount}
                  pageSizeOptions={[20, 40, 100]}
                />
              </div>
            </Spin>
          )}
        </div>
      </div>
      {open && <TemporaryModal onClose={() => setOpen(false)} />}
    </>
  );
}
