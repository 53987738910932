import { ApolloCache } from '@apollo/client';
import { App } from 'antd';
import { debounce } from 'lodash-es';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { useMeQuery } from '../../../common/graphql/me.generated';
import { useFormError } from '../../../common/hooks/useFormError';
import { deleteCacheFields } from '../../../common/utils/apollo';
import { shouldDeleteQueriesAfterClaimUpdate } from '../../cs/const';
import {
  AdminCountPromotionStatusesDocument,
  AdminPromotionReleasesDocument,
  useAdminCreatePromotionMutation,
} from '../graphql/promotionRelease.generated';
import {
  useRedeliveryOrderByCodeLazyQuery,
  useRedeliveryProductItemsQuery,
} from '../graphql/redelivery.generated';
import { getDefaultScheduledReleasedAt } from '../helper';
import { ExchangeRedeliveryFormValues } from '../types';
import { exchangeRedeliveryResolver } from '../validator/createExchangeRedeliveryValidator';

function useCreateExchangeRedelivery({
  onClose,
  orderCode,
}: {
  onClose: () => void;
  orderCode?: string;
}) {
  const { modal, message } = App.useApp();
  const methods = useForm<ExchangeRedeliveryFormValues>({
    defaultValues: {
      promotionType: 'EXCHANGE_RE_DELIVERY',
      orderCode,
      isDeliveryFee: false,
      scheduledReleaseAt: getDefaultScheduledReleasedAt(),
    },
    resolver: exchangeRedeliveryResolver,
  });
  const { onInvalid } = useFormError();
  const { data: meData } = useMeQuery();
  const { setValue, handleSubmit, reset, setError, clearErrors, formState } =
    methods;

  const [fetchOrder, { data: orderData, loading: orderLoading }] =
    useRedeliveryOrderByCodeLazyQuery({
      onError() {
        setError('orderCode', {
          message: '일치하는 와이어드품목주문코드가 없습니다.',
          type: 'required', // 빨간테두리를 위한 error type 주입
        });
      },
      onCompleted({ adminOrderByCode }) {
        const activeClaim = adminOrderByCode.activeClaim;

        /**
         * 교환재출고 조건
         * 1. active claim이 있음.
         * 2. active claim이 교환 타입.
         * 3. active claim이 교환 접수 상태.
         */
        if (
          !activeClaim ||
          activeClaim.status !== 'EXCHANGE_RECEIPT' ||
          activeClaim.claimType !== 'EXCHANGE'
        ) {
          setError('orderCode', {
            message: '교환접수상태의 주문만 출고요청이 가능합니다.',
            type: 'required', // 빨간테두리를 위한 error type 주입
          });

          return;
        }

        if (adminOrderByCode.hasActiveExchangeReDeliveryPromotionRelease) {
          setError('orderCode', {
            message: '이미 출고가 처리중입니다.',
            type: 'required', // 빨간테두리를 위한 error type 주입
          });
          return;
        }

        reset({
          promotionType: 'EXCHANGE_RE_DELIVERY',
          isDeliveryFee: false,
          orderCode: adminOrderByCode.code,
          orderId: adminOrderByCode.id,
          receiverName: adminOrderByCode.receiverName,
          receiverPhoneNumber: adminOrderByCode.receiverPhoneNumber,
          zipCode: adminOrderByCode.receiverZipCode,
          receiverAddress: adminOrderByCode.receiverAddress,
          receiverDetailAddress: adminOrderByCode.receiverDetailAddress,
          deliveryMessage: adminOrderByCode.receiverDeliveryMemo,
          productGroupId: adminOrderByCode.productGroupId,
          originalProductId: adminOrderByCode.productId,
          originalItemIds: adminOrderByCode.items?.map(({ itemId }) => itemId),
          setCount: adminOrderByCode.productQuantity,
          useType: adminOrderByCode.productUseType,
        });
      },
    });
  const { data: productData } = useRedeliveryProductItemsQuery({
    variables: {
      adminProductGroupId: orderData?.adminOrderByCode.productGroupId || 0,
      adminProductId: orderData?.adminOrderByCode.productId || 0,
    },
    skip: !orderData,
    onCompleted({ adminProductGroup }) {
      setValue('deliveryFee', adminProductGroup.exchangeFee);
      setValue('deliveryFeeSettlementPrice', adminProductGroup.exchangeFee);
    },
  });

  const [createExchangeRedeliveryMutation] = useAdminCreatePromotionMutation();

  useEffect(() => {
    if (orderCode) {
      void fetchOrder({ variables: { code: orderCode } });
    }
  }, [orderCode]);

  const productItemOptions = useMemo(
    () =>
      productData?.adminProduct?.items?.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [productData]
  );
  const debounceQuery = useCallback(debounce(fetchOrder, 300), []);
  const handleOrderCode = useCallback(
    async (ev: ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;

      setValue('orderCode', value);
      clearErrors('orderCode');

      if (value.trim()) {
        await debounceQuery({ variables: { code: value } });
      }
    },
    [debounceQuery, setValue]
  );

  const createExchangeRedelivery = useCallback(
    async (data: ExchangeRedeliveryFormValues) => {
      if (meData?.adminMe) {
        await createExchangeRedeliveryMutation({
          variables: {
            promotionData: {
              promotionType: 'EXCHANGE_RE_DELIVERY',
              deliveryFee: data.isDeliveryFee ? data.deliveryFee : undefined,
              deliveryFeeBearer: data.isDeliveryFee
                ? data.deliveryFeeBearer
                : undefined,
              deliveryFeeSettlementPrice: data.isDeliveryFee
                ? data.deliveryFeeSettlementPrice
                : undefined,
              deliveryFeeSettlementTarget: data.isDeliveryFee
                ? data.deliveryFeeSettlementTarget
                : undefined,
              isDeliveryFee: data.isDeliveryFee,
              managerId: meData.adminMe.id,
              orderCode: data.orderCode,
              orderId: data.orderId,
              productGroupId: data.productGroupId,
              promotionProducts: [
                {
                  isFreeProvided: true,
                  originalProductId: data.originalProductId,
                  setCount: data.setCount,
                  promotionItems: data.originalItemIds?.map(
                    (originalItemId) => ({
                      originalItemId,
                    })
                  ),
                },
              ],
              promotionReleases: [
                {
                  deliveryMessage: data.deliveryMessage,
                  note: data.note,
                  receiverAddress: data.receiverAddress,
                  receiverDetailAddress: data.receiverDetailAddress,
                  receiverName: data.receiverName,
                  receiverPhoneNumber: data.receiverPhoneNumber,
                  scheduledReleaseAt: data.scheduledReleaseAt,
                  zipCode: data.zipCode,
                },
              ],
            },
          },
          refetchQueries: [
            AdminPromotionReleasesDocument,
            AdminCountPromotionStatusesDocument,
          ],
          update: (cache: ApolloCache<any>) =>
            deleteCacheFields(cache, [
              'adminPromotionReleases',
              'adminCountPromotionStatuses',
              ...shouldDeleteQueriesAfterClaimUpdate,
            ]),
          onCompleted() {
            void message.success('저장이 완료되었습니다.');
            onClose();
          },
          onError() {
            void message.error('저장을 실패하였습니다.');
          },
        });
      }
    },
    [createExchangeRedeliveryMutation, meData?.adminMe, message, onClose]
  );

  const onSubmit = handleSubmit((data) => {
    void modal.confirm({
      title: '출고요청',
      content: '출고요청을 진행하시겠습니까?',
      onOk: () => createExchangeRedelivery(data),
      // 모달 2개위에 띄워도 최상단에 위치시키기 위함.
      zIndex: 5000,
    });
  }, onInvalid);

  return {
    methods,
    handleOrderCode,
    orderData,
    orderLoading,
    orderError: formState.errors.orderCode?.message,
    productItemOptions,
    onSubmit,
  };
}

export default useCreateExchangeRedelivery;
