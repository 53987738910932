import { Button } from 'antd';
import { useState } from 'react';

import PdfModal from './PdfModal';

function PdfButton({ settlementSheetId }: { settlementSheetId: number }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        type='text'
        className='text-blue-500'
        onClick={() => setOpen(true)}
      >
        보기
      </Button>
      {open && (
        <PdfModal
          settlementSheetId={settlementSheetId}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
}

export default PdfButton;
