import { cloneDeep } from '@apollo/client/utilities';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver } from 'react-hook-form';
import * as yup from 'yup';

import { ERROR_MESSAGE_REQUIRED } from '../../common/constants/error';
import {
  EBankCode,
  EMarketEventType,
  EMarketStatus,
  EProductGroupDeliveryFeeType,
  EProductGroupReleaseType,
  EPurchaseOrderType,
  ESellingChannel,
  ESellerSettlementType,
  EShippingBearer,
} from '../../schema/types';

import {
  DELIVERY_FEE_TYPES,
  EVENT_TYPES,
  MARKET_EVENTS_MAX_LENGTH,
  RELEASE_TYPES,
  SELLING_CHANNELS,
  SHIPPING_BEARER,
} from './const';
import { hasParticipantsEventType } from './helper';
import { CreateMarketFormValues, UpdateMarketFormValues } from './types';

export const marketSchema = {
  /* 기본 정보 */
  name: yup
    .string()
    .max(100, '마켓명은 100자 이내로 입력 가능합니다.')
    .required(ERROR_MESSAGE_REQUIRED),
  isSellerAssigned: yup.boolean().required(ERROR_MESSAGE_REQUIRED),
  managerId: yup.number().required(ERROR_MESSAGE_REQUIRED),
  sellerId: yup.number().when('isSellerAssigned', {
    is: true,
    then: (schema) => schema.required(ERROR_MESSAGE_REQUIRED),
    otherwise: (schema) => schema.nullable(),
  }),
  sellerSettlementInfoId: yup.number().nullable().optional(),

  sellingChannel: yup
    .mixed<ESellingChannel>()
    .oneOf(SELLING_CHANNELS.map((type) => type.value))
    .when('isSellerAssigned', {
      is: true,
      then: (schema) => schema.required(ERROR_MESSAGE_REQUIRED),
    })
    .optional()
    .nullable(),
  etcSellingChannel: yup
    .string()
    .when('sellingChannel', {
      is: 'ETC',
      then: (schema) => schema.required(ERROR_MESSAGE_REQUIRED),
    })
    .trim()
    .max(20, '20자 이내로 작성해주세요.')
    .nullable()
    .optional(),

  /* 상품 정보 */
  brandId: yup.number().required(ERROR_MESSAGE_REQUIRED),
  productGroupIds: yup
    .array()
    .of(
      /* 임시필드 추가*/
      yup.object().shape({
        id: yup.number().required(ERROR_MESSAGE_REQUIRED),
        managerId: yup.number().optional().nullable(),
        session: yup.number().optional().nullable(),
      })
    )
    .required(ERROR_MESSAGE_REQUIRED)
    .min(1, '상품이 1개 이상 등록되어야 합니다.'),

  /* 마켓 정보 */
  startedAt: yup.string().required(ERROR_MESSAGE_REQUIRED),
  endedAt: yup.string().optional().nullable(),
  session: yup.number().min(1, '1이상 입력해야 합니다.').optional().nullable(),
  expectedSettlementAt: yup
    .string()
    .when('endedAt', {
      is: null,
      then: (schema) => schema.optional().nullable(),
      otherwise: (schema) => schema.required(ERROR_MESSAGE_REQUIRED),
    })
    .required(ERROR_MESSAGE_REQUIRED),
  expectedSales: yup.number().optional().nullable(),
  comment: yup.string().trim().optional().nullable(),

  /* 배송비 정보 */
  deliveryFeeType: yup
    .mixed<EProductGroupDeliveryFeeType>()
    .oneOf(DELIVERY_FEE_TYPES.map((type) => type.value))

    .required(ERROR_MESSAGE_REQUIRED),
  isRegionFee: yup.boolean().required(ERROR_MESSAGE_REQUIRED),
  deliveryCost: yup
    .number()
    .min(1, '0원은 입력할 수 없습니다.')
    .optional()
    .nullable(),
  freeDeliveryConditionFee: yup
    .number()
    .when('deliveryFeeType', {
      is: 'CONDITIONAL_FREE',
      then: (schema) => schema.required(ERROR_MESSAGE_REQUIRED),
    })
    .min(1, '0원은 입력할 수 없습니다.')
    .optional()
    .nullable(),
  additionalFee: yup
    .number()
    .when('isRegionFee', {
      is: true,
      then: (schema) => schema.required(ERROR_MESSAGE_REQUIRED),
    })
    .optional()
    .nullable(),
  jejuIslandFee: yup
    .number()
    .when('isRegionFee', {
      is: true,
      then: (schema) => schema.required(ERROR_MESSAGE_REQUIRED),
    })
    .optional()
    .nullable(),
  releaseType: yup
    .mixed<EProductGroupReleaseType>()
    .oneOf(RELEASE_TYPES.map((type) => type.value))
    .required(ERROR_MESSAGE_REQUIRED),
  releaseTime: yup
    .string()
    .when('releaseType', {
      is: 'TODAY',
      then: (schema) => schema.required(ERROR_MESSAGE_REQUIRED),
    })
    .optional()
    .nullable(),
  purchaseOrderType: yup
    .mixed<EPurchaseOrderType>()
    .when('releaseType', {
      is: 'BULK',
      then: (schema) => schema.required(ERROR_MESSAGE_REQUIRED),
    })
    .optional()
    .nullable(),

  /* 이벤트 정보 */
  events: yup
    .array()
    .of(
      yup.object().shape({
        type: yup
          .mixed<EMarketEventType>()
          .oneOf(EVENT_TYPES.map((type) => type.value))
          .required('이벤트 유형을 선택해주세요.'),
        count: yup
          .number()
          .when(['type'], (values, schema) => {
            const type = values[0] as EMarketEventType;
            if (hasParticipantsEventType(type)) {
              return schema
                .min(1, '1명 이상 입력해주세요.')
                .required('이벤트 인원을 기재해주세요.');
            }

            return schema.optional().nullable();
          })
          .optional()
          .nullable(),
        comment: yup.string().trim().optional().nullable(),
        isExtraDeliveryCharged: yup.boolean().required(ERROR_MESSAGE_REQUIRED),
        isNotationRequired: yup.boolean().required(ERROR_MESSAGE_REQUIRED),
        shippingBearer: yup
          .mixed<EShippingBearer>()
          .oneOf(SHIPPING_BEARER.map((type) => type.value))
          .when('isExtraDeliveryCharged', {
            is: true,
            then: (schema) =>
              schema.required('배송비 부담 내용을 입력해주세요.'),
          })
          .optional()
          .nullable(),
        shippingCost: yup.number().optional().nullable(),
        products: yup
          .array()
          .of(
            yup.object().shape({
              isFreeProvided: yup.boolean().required(ERROR_MESSAGE_REQUIRED),
              name: yup.string().required(ERROR_MESSAGE_REQUIRED),
              count: yup.number().required('품목 수량을 입력해주세요.'),

              inhouseBuyPrice: yup.number().optional().nullable(),
              isSellerBearing: yup.boolean().optional().nullable(),
              sellerBearingCost: yup.number().optional().nullable(),
              isCustomerBearing: yup.boolean().optional().nullable(),
              customerBearingCost: yup.number().optional().nullable(),
              isWiredBearing: yup.boolean().optional().nullable(),
              wiredBearingCost: yup.number().optional().nullable(),

              isPrepayment: yup.boolean().optional().nullable(),
              itemIds: yup
                .array()
                .of(yup.number().required())
                .when('useType', {
                  is: 'SELECT',
                  then: (schema) =>
                    schema.min(1, '옵션을 선택해주세요.').required(),
                })
                .optional()
                .nullable(),
            })
          )
          .when('isNotationRequired', {
            is: true,
            then: (schema) => schema.min(1, '최소 1개 품목을 추가해주세요.'),
          })

          .required(),
      })
    )
    .max(MARKET_EVENTS_MAX_LENGTH, '최대 5개까지만 등록이 가능합니다.'),

  /* 임시필드 추가*/
  brandName: yup.string().optional().nullable(),
  sellerName: yup.string().optional().nullable(),
  sellerKemiId: yup.string().optional().nullable(),
  sellerSettlementInfoType: yup
    .mixed<ESellerSettlementType>()
    .optional()
    .nullable(),
  productOptions: yup.array().of(
    yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
      businessSellerSupplyPrice: yup.number().required(),
      freelanceSellerSupplyPrice: yup.number().required(),
      inHousePurchasePrice: yup.number().required(),
    })
  ),
  settlementInfos: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        type: yup.mixed<ESellerSettlementType>().required(),
        name: yup.string(),
        bank: yup.mixed<EBankCode>().nullable(),
        accountNumber: yup.string(),
        accountHolderName: yup.string(),
        isDefault: yup.boolean().optional(),
      })
    )
    .optional()
    .nullable(),
};

export const createMarketSchema = yup.object<CreateMarketFormValues>().shape({
  ...marketSchema,
});

export const updateMarketSchema = yup.object<UpdateMarketFormValues>().shape({
  ...marketSchema,
  status: yup.mixed<EMarketStatus>().required(),
});

const marketFormResolver = <
  T extends CreateMarketFormValues | UpdateMarketFormValues,
>(
  data: T
): T => {
  const values = cloneDeep(data);

  /**
   * 판매채널 정리
   */
  if (values.sellingChannel !== 'ETC') {
    values.etcSellingChannel = null;
  }

  /**
   * 배송비 정리
   */
  if (values.deliveryFeeType === 'FREE') {
    values.deliveryCost = null;
    values.freeDeliveryConditionFee = null;
  }
  if (values.deliveryFeeType === 'CHARGED') {
    values.freeDeliveryConditionFee = null;
  }

  /**
   * 지역별 배송비 정리
   */

  if (!values.isRegionFee) {
    values.additionalFee = null;
    values.jejuIslandFee = null;
  }

  /**
   * 출고유형 정리
   */
  if (values.releaseType !== 'TODAY') {
    values.releaseTime = null;
  }
  if (values.releaseType !== 'BULK') {
    values.purchaseOrderType = null;
  }
  if (values.releaseType === 'BULK' && !values.purchaseOrderType) {
    values.purchaseOrderType = 'NEXT_DAY';
  }

  /**
   * 이벤트 정리
   */
  values.events = values.events?.map((event) => {
    return {
      ...event,
      count: hasParticipantsEventType(event.type) ? event.count : null,
      shippingBearer: event.isExtraDeliveryCharged
        ? event.shippingBearer
        : null,
      shippingCost: event.isExtraDeliveryCharged ? event.shippingCost : null,
      products: event.products.map((product) => {
        const isCustomerBearing = product.isFreeProvided
          ? null
          : product.isCustomerBearing;
        const isSellerBearing = product.isFreeProvided
          ? null
          : product.isSellerBearing;
        const isWiredBearing = product.isFreeProvided
          ? null
          : product.isWiredBearing;

        return {
          ...product,
          inhouseBuyPrice: product.isFreeProvided
            ? null
            : product.inhouseBuyPrice,
          customerBearingCost: isCustomerBearing
            ? product.customerBearingCost
            : null,
          sellerBearingCost: isSellerBearing ? product.sellerBearingCost : null,
          isPrepayment: isSellerBearing ? product.isPrepayment : null,
          wiredBearingCost: isWiredBearing ? product.wiredBearingCost : null,
        };
      }),
    };
  });

  return values;
};

export const createMarketFormResolver: Resolver<CreateMarketFormValues> = (
  data,
  context,
  options
) => {
  const values = marketFormResolver(data);

  if (values.isSellerAssigned) {
    /**
     * 마켓명 생성 로직(지정셀러일 때)
     */
    const shouldProductName = (values.productGroupIds || []).length === 1;
    const product = shouldProductName
      ? values.productGroupIds?.[0]?.name || ''
      : values.brandName || '';
    const session = values?.session || 0;
    const sessionString = session > 1 ? ` ${session}차` : '';

    values.name = `${values.sellerName || ''} X ${product}${sessionString}`;
  } else {
    /**
     * 미지정 셀러일 때, 불필요한 정보 삭제
     */
    delete values.sellerName;
    delete values.events;
    delete values.sellerId;
    delete values.sellingChannel;
    delete values.etcSellingChannel;
    delete values.sellerSettlementInfoId;
    delete values.sellerKemiId;
    delete values.session;
  }

  return yupResolver<CreateMarketFormValues>(createMarketSchema)(
    values,
    context,
    options
  );
};

export const updateMarketFormResolver: Resolver<UpdateMarketFormValues> = (
  data,
  context,
  options
) => {
  const values = marketFormResolver(data);

  if (!values.isSellerAssigned) {
    /**
     * 미지정 셀러일 때, 불필요한 정보 삭제
     */
    delete values.sellerName;
    delete values.events;
    delete values.sellerId;
    delete values.sellingChannel;
    delete values.etcSellingChannel;
    delete values.sellerSettlementInfoId;
    delete values.sellerKemiId;
    delete values.session;
  }

  return yupResolver<UpdateMarketFormValues>(updateMarketSchema)(
    values,
    context,
    options
  );
};
