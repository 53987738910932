import { ExclamationCircleFilled } from '@ant-design/icons';
import { ApolloError, useApolloClient } from '@apollo/client';
import { App } from 'antd';
import { useNavigate } from 'react-router-dom';

import {
  extractBadRequestError,
  removeCache,
} from '../../../common/utils/apollo';
import { ROUTES } from '../../../routes/const';
import { DEFAULT_VENDORS_QUERY_VARIABLE } from '../const';
import { useDeleteBrandMutation } from '../graphql/brand.generated';
import {
  VendorWithBrandsDocument,
  VendorsDocument,
  useDeleteVendorMutation,
} from '../graphql/vendor.generated';

type UseRemoveVendorParams = {
  type: 'VENDOR' | 'BRAND';
  id?: number;
};

function useRemoveVendorOrBrand({ id, type }: UseRemoveVendorParams) {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [mutateVendor] = useDeleteVendorMutation({
    refetchQueries: [VendorsDocument],
  });
  const [mutateBrand] = useDeleteBrandMutation({
    refetchQueries: [VendorWithBrandsDocument],
  });
  const { modal, message } = App.useApp();
  const onCompleted = () => {
    message.success(
      `${type === 'VENDOR' ? '업체' : '브랜드'}가 삭제되었습니다.`
    );
    if (type === 'VENDOR') navigate(ROUTES.VENDORS.path);
  };
  const onError = ({ graphQLErrors }: ApolloError) => {
    const noVendorError = extractBadRequestError(graphQLErrors);

    if (noVendorError && id) {
      const __typename =
        type === 'VENDOR' ? 'VendorGraphqlType' : 'BrandGraphqlType';
      message.error(noVendorError.message);
      removeCache(client.cache, { id, __typename });
    } else {
      message.error(
        `${type === 'VENDOR' ? '업체' : '브랜드'} 삭제를 실패하였습니다.`
      );
    }
  };
  const remove = () => {
    if (typeof id !== 'number') return;

    modal.confirm({
      icon: <ExclamationCircleFilled />,
      title: type === 'VENDOR' ? '업체 삭제' : '브랜드 삭제',
      content: (
        <>
          {type === 'VENDOR'
            ? '업체와 연동된 브랜드와 상품정보까지 삭제됩니다.'
            : '브랜드와 연동된 상품의 정보까지 삭제됩니다.'}
          <br />
          삭제한 내용은 되돌릴 수 없습니다. 삭제하시겠습니까?
        </>
      ),
      async onOk() {
        if (type === 'VENDOR') {
          await mutateVendor({
            variables: { deleteVendorId: id },
            refetchQueries: [
              {
                query: VendorsDocument,
                variables: DEFAULT_VENDORS_QUERY_VARIABLE,
              },
            ],
            onCompleted,
            onError,
            update(cache) {
              removeCache(cache, { id, __typename: 'VendorGraphqlType' });
            },
          });
        }
        if (type === 'BRAND') {
          await mutateBrand({
            variables: { deleteBrandId: id },
            onCompleted,
            onError,
          });
        }
      },
      okText: '네',
      cancelText: '아니오',
      centered: true,
    });
  };

  return remove;
}

export default useRemoveVendorOrBrand;
