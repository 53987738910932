import { Collapse, CollapseProps, Typography } from 'antd';

import { ClaimType } from '../types';

import ClaimItem from './OrderDetailClaimItem';

type OrderDetailClaimProps = {
  claims: ClaimType[];
};

export default function OrderDetailClaim({ claims }: OrderDetailClaimProps) {
  const items: CollapseProps['items'] = [
    {
      key: '1',
      headerClass: 'items-center p-0 h-10',
      label: (
        <Typography.Title level={5} className='m-0'>
          클레임내역
        </Typography.Title>
      ),
      children: (
        <div className='flex flex-col gap-10'>
          {claims.map((claim) => (
            <ClaimItem {...claim} />
          ))}
        </div>
      ),
    },
  ];

  return (
    <>
      <Collapse
        items={items}
        ghost
        size='large'
        className='mt-4 [&_.ant-collapse-content-box]:p-0'
      />
    </>
  );
}
