import { Table } from 'antd';
import { useMemo } from 'react';

import { useAdminItemsQuery } from '../graphql/items.generated';

type ProductMarketListStockTableProps = {
  productGroupId: number;
};

type DataSourceType = {
  key: number;
  id: number;
  name: string;
  stock: number;
  code: string;
};

function ProductMarketListStockTable({
  productGroupId,
}: ProductMarketListStockTableProps) {
  const { data } = useAdminItemsQuery({
    variables: {
      filter: {
        productGroupId,
      },
    },
  });

  const dataSource = useMemo(
    () =>
      data?.adminItems.edges.map(({ node: { id, name, stock, code } }) => ({
        key: id,
        id,
        name,
        stock: stock || 0,
        code,
      })) || [],
    [data?.adminItems.edges]
  );

  return (
    <Table<DataSourceType>
      size='small'
      dataSource={dataSource}
      pagination={false}
      scroll={{ y: 195 }}
    >
      <Table.Column<DataSourceType>
        title='옵션코드 (옵션ID)'
        width={240}
        render={(_, { code, id }) => {
          return (
            <p>
              {code} <span className='text-black/[0.45]'>({id})</span>
            </p>
          );
        }}
      />
      <Table.Column title='옵션명' width={704} dataIndex='name' />
      <Table.Column<DataSourceType>
        title='잔여재고'
        width={108}
        render={(_, { stock }) => {
          if (stock > 10) return stock;

          return <span className='text-[#FF4D4F]'>{stock}</span>;
        }}
      />
    </Table>
  );
}

export default ProductMarketListStockTable;
