import { DeleteOutlined } from '@ant-design/icons';
import { App, Button, Image, Input, Typography } from 'antd';
import { ClipboardEvent, useEffect, useState } from 'react';

import EmptyImage from '../../../../common/components/EmptyImage';
import useAntdTheme from '../../../../common/hooks/useAntdTheme';
import useClipboardImage from '../../../../common/hooks/useClipboardImage';
import { EUploadResourcesPurpose } from '../../../../schema/types';

import ImageUploader, { ImageUploaderButton } from './ImageUploader';
import ScreenShotModal from './ScreenShotModal';

type ScreenShotAndImageUploadProps = {
  endUpload: (url: string) => void;
  purpose: EUploadResourcesPurpose;
  image?: string;
  error?: string;
  editable?: boolean;
  hideErrorMessage?: boolean;
};

export default function ScreenShotAndImageUpload({
  endUpload,
  purpose,
  image,
  error,
  editable,
  hideErrorMessage,
}: ScreenShotAndImageUploadProps) {
  const { colorBorder } = useAntdTheme();
  const { message } = App.useApp();
  const { getClipboardImageFile } = useClipboardImage();

  const [screenShotModalOpen, setScreenShotModalOpen] = useState(false);
  const [mainImage, setMainImage] = useState<string>();
  const [clipboardImageFile, setClipboardImageFile] = useState<File>();

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    try {
      const clipboardImageFile = getClipboardImageFile(e);

      if (clipboardImageFile) {
        setClipboardImageFile(clipboardImageFile);
        setScreenShotModalOpen(true);
      }
    } catch (error) {
      void message.error(
        '클립보드에 이미지가 없거나 지원하지 않는 형식입니다.'
      );
    }
  };

  useEffect(() => {
    image && setMainImage(image);
  }, [image]);

  const handleEndUpload = (url: string) => {
    endUpload(url);
    setMainImage(url);
  };

  const handleDelete = () => {
    endUpload('');
    setMainImage('');
  };

  const handleImageError = () => {
    handleDelete();
    void message.error(
      '잘못된 이미지거나 지원하지 않는 형식입니다. 확인 후 다시 시도해 주세요.'
    );
  };

  return (
    <div className='flex flex-col gap-2'>
      <div
        className='h-[200px] w-[200px] rounded-lg border border-solid p-4'
        style={{ borderColor: error ? '#ff7875' : colorBorder }}
      >
        {mainImage && (
          <Image
            width={'100%'}
            height={'100%'}
            src={mainImage}
            className='object-cover'
            onError={handleImageError}
          />
        )}
        {!mainImage && <EmptyImage />}
        <ImageUploader endUpload={handleEndUpload} purpose={purpose} />
      </div>
      <div className='flex justify-between gap-1'>
        <div className='flex-1'>
          <Input
            placeholder={'스크린샷붙여넣기'}
            onPaste={handlePaste}
            value={''} // 입력을 막기위해 빈값을 넣어줌
            disabled={!editable}
          />
        </div>
        <ImageUploaderButton disabled={!editable} />
        {mainImage && (
          <Button
            icon={<DeleteOutlined />}
            onClick={handleDelete}
            disabled={!editable}
          />
        )}
      </div>
      {!hideErrorMessage && error && (
        <Typography.Text className='text-xs' type='danger'>
          {error}
        </Typography.Text>
      )}
      {clipboardImageFile && screenShotModalOpen && (
        <ScreenShotModal
          clipboardImageFile={clipboardImageFile}
          onClose={() => setScreenShotModalOpen(false)}
          endUpload={handleEndUpload}
          purpose={purpose}
        />
      )}
    </div>
  );
}
