import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  ECostBearer,
  EProductUseType,
  EPromotionType,
  ESettlementTarget,
} from '../../../schema/types';
import {
  DELIVERY_FEE_BEARER_OPTIONS,
  DELIVERY_FEE_SETTLEMENT_TARGET,
  PROMOTION_TYPES,
} from '../const';
import { ExchangeRedeliveryFormValues } from '../types';

const ERROR_MESSAGE = '필수항목이 입력되지 않았습니다.';

const exchangeRedeliverySchema = yup.object().shape({
  promotionType: yup
    .mixed<EPromotionType>()
    .oneOf(PROMOTION_TYPES.map(({ value }) => value))
    .required(),
  orderCode: yup.string().required(ERROR_MESSAGE),
  orderId: yup.number().required(ERROR_MESSAGE),

  isDeliveryFee: yup.boolean().required(ERROR_MESSAGE),
  deliveryFee: yup.number().optional().nullable(),
  deliveryFeeBearer: yup
    .mixed<ECostBearer>()
    .oneOf(DELIVERY_FEE_BEARER_OPTIONS.map(({ value }) => value))
    .when('isDeliveryFee', {
      is: true,
      then: (schema) => schema.required(ERROR_MESSAGE),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  deliveryFeeSettlementPrice: yup.number().optional().nullable(),
  deliveryFeeSettlementTarget: yup
    .mixed<ESettlementTarget>()
    .oneOf(DELIVERY_FEE_SETTLEMENT_TARGET.map(({ value }) => value))
    .when('isDeliveryFee', {
      is: true,
      then: (schema) => schema.required(ERROR_MESSAGE),
      otherwise: (schema) => schema.optional().nullable(),
    }),

  productGroupId: yup.number().required(ERROR_MESSAGE),
  originalProductId: yup.number().required(ERROR_MESSAGE),
  originalItemIds: yup
    .array()
    .of(yup.number().required())
    .when('useType', {
      is: 'SELECT',
      then: (schema) => schema.min(1, ERROR_MESSAGE).required(ERROR_MESSAGE),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  setCount: yup.number().required(ERROR_MESSAGE),

  receiverName: yup.string().required(ERROR_MESSAGE).trim(),
  receiverPhoneNumber: yup.string().required(ERROR_MESSAGE).trim(),
  zipCode: yup.string().optional().nullable(),
  receiverAddress: yup.string().required(ERROR_MESSAGE).trim(),
  receiverDetailAddress: yup.string().optional().nullable(),
  deliveryMessage: yup.string().optional().nullable(),
  scheduledReleaseAt: yup.string().optional().nullable(),
  note: yup.string().optional().nullable(),

  useType: yup.mixed<EProductUseType>().optional().nullable(),
});

export const exchangeRedeliveryResolver =
  yupResolver<ExchangeRedeliveryFormValues>(exchangeRedeliverySchema);
