import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';

import Modal from '../../../../common/components/Modal';
import multiDownload from '../../../../common/utils/multiDownload';

import ContentsSortable from './ContentsSortable';

type ProductArchiveContentsModalProps = {
  urls: string[];
  onChange: (urls: string[]) => void;
  onClose: () => void;
  disabled?: boolean;
};

export default function ProductArchiveContentsModal({
  onChange,
  onClose,
  urls,
  disabled,
}: ProductArchiveContentsModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(true);

  return (
    <Modal
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => setIsModalOpen(false)}
      title={'썸네일 전체보기'}
      open={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      afterClose={onClose}
    >
      <div className='flex flex-wrap gap-2'>
        <ContentsSortable
          onDragEnd={(newUrls: string[]) => {
            onChange(newUrls);
          }}
          items={urls}
          disabled={disabled}
        />
      </div>
      <div className='mt-4 flex justify-center'>
        <Button onClick={() => multiDownload(urls)}>
          <DownloadOutlined />
          다운로드
        </Button>
      </div>
    </Modal>
  );
}
