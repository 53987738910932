import { Select } from 'antd';

import { EProductGroupStatus } from '../../../schema/types';
import { PRODUCT_GROUP_STATUSES } from '../const';

type ProductStatusesSelectProps = {
  onChange: (value: EProductGroupStatus[]) => void;
  statuses?: EProductGroupStatus[];
};

export default function ProductStatusesSelect({
  onChange,
  statuses,
}: ProductStatusesSelectProps) {
  return (
    <Select
      style={{ width: 326 }}
      mode='multiple'
      allowClear
      suffixIcon={null}
      options={PRODUCT_GROUP_STATUSES}
      placeholder='거래상태 선택'
      onChange={onChange}
      value={statuses}
    />
  );
}
