import { Alert, Button, Checkbox, Divider, Switch, Typography } from 'antd';
import Select, { DefaultOptionType } from 'antd/es/select';
import { useCallback, useState, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import SelectField from '../../../../common/components/Fields/SelectField';
import SwitchField from '../../../../common/components/Fields/SwitchField';
import Modal from '../../../../common/components/Modal';
import { formSetValueOption } from '../../../../common/utils/const';
import {
  DELIVERY_FEE_BEARER_OPTIONS,
  DELIVERY_FEE_SETTLEMENT_TARGET,
  PROMOTION_TYPES,
} from '../../const';
import { AdminPromotionReleaseQuery } from '../../graphql/promotionRelease.generated';
import usePromotionPermission from '../../hooks/usePromotionPermission';
import useUpdatePromotion from '../../hooks/useUpdatePromotion';
import MarketSelectField, { MarketOption } from '../MarketSelectField';
import NumberWithUnknownField from '../NumberWithUnknownField';
import ProductGroupSelectField from '../ProductGroupSelectField';
import PromotionProductsTable from '../PromotionProductsTable';

import PromotionReceiverInformation from './PromotionReceiverInformation';

type UpdatePromotionModalProps = {
  promotionRelease: AdminPromotionReleaseQuery['adminPromotionRelease'];
  onClose: () => void;
};

function UpdatePromotionModal({
  onClose,
  promotionRelease,
}: UpdatePromotionModalProps) {
  const { methods, onSubmit } = useUpdatePromotion(promotionRelease, onClose);
  const {
    control,
    watch,
    setValue,
    resetField,
    formState: { isDirty },
  } = methods;
  const { seller, promotionProducts, promotion } = promotionRelease;
  const hasReleaseCompleted = promotion.promotionReleases?.some(
    ({ releaseStatus }) => releaseStatus === 'RELEASE_COMPLETED'
  );
  const hasPermission = usePromotionPermission();
  const editDisabled = hasReleaseCompleted || !hasPermission;
  const marketName = promotion.market?.name || '';
  const promotionProductGroupName =
    promotionProducts[0].originalProductGroup?.name || '';
  const [isDeliveryFee, marketId, marketUndesignated, productGroupId] = watch([
    'isDeliveryFee',
    'marketId',
    'marketUndesignated',
    'productGroupId',
  ]);

  const [marketProductGroups, setMarketProductGroups] = useState<
    MarketOption['productGroups']
  >(promotion.market?.productGroups || []);

  const handleMarketSelect = useCallback(
    (_: any, option: DefaultOptionType | DefaultOptionType[]) => {
      if (!Array.isArray(option)) {
        const marketOption = option.data as MarketOption;
        setValue('marketId', marketOption.id);
        setMarketProductGroups(marketOption.productGroups);
        // 마켓이 변경되면 상품과 프로모션 품목이 초기화 된다.
        resetField('productGroupId', { defaultValue: null });
        resetField('promotionProducts', { defaultValue: [] });
      }
    },
    [setValue, resetField]
  );

  const marketProductGroupsOption = useMemo(() => {
    return marketProductGroups.map((group) => ({
      label: group.name,
      value: group.id,
    }));
  }, [marketProductGroups]);

  const handleProductGroupSelect = useCallback(
    (value: number) => {
      setValue('productGroupId', value);
      resetField('promotionProducts', { defaultValue: [] });
    },
    [setValue, resetField]
  );

  return (
    <Modal
      open
      onCancel={onClose}
      title='상세정보 일괄수정'
      width={1000}
      footer={null}
    >
      <div className='flex flex-col gap-4 py-4'>
        {hasReleaseCompleted && (
          <Alert
            message='출고완료된 수령자가 있어 품목의 가격정보만 수정이 가능합니다.'
            type='warning'
            showIcon
          />
        )}
        <FieldWithLabel label='구분'>
          <Select
            className='w-[120px]'
            options={PROMOTION_TYPES}
            value={promotion.promotionType}
            disabled
          />
        </FieldWithLabel>
      </div>
      <Divider orientation='left' className='m-0 py-4'>
        수령자정보
      </Divider>
      <div className='flex flex-col gap-6'>
        <div className='flex items-center gap-2'>
          <label>셀러</label>
          <Switch size='small' className='w-7' checked={!!seller} disabled />
        </div>
        <PromotionReceiverInformation />
      </div>
      <Divider orientation='left' className='m-0 py-4'>
        상세정보
      </Divider>
      <FormProvider {...methods}>
        <form className='flex flex-col gap-6' onSubmit={onSubmit}>
          <FieldWithLabel label='마켓명' required>
            <div className='flex items-center gap-2'>
              <div className='w-[472px]'>
                <MarketSelectField
                  control={control}
                  name='marketId'
                  sellerId={seller?.id}
                  value={marketId}
                  onChange={handleMarketSelect}
                  disabled={!!marketUndesignated || editDisabled}
                  initialOption={
                    marketId
                      ? { value: marketId, label: marketName }
                      : undefined
                  }
                />
              </div>
              <div className='flex-1'>
                <Checkbox
                  onChange={(ev) => {
                    setValue('marketUndesignated', ev.target.checked);
                    resetField('marketId', { defaultValue: null });
                    resetField('productGroupId', { defaultValue: null });
                    resetField('promotionProducts', { defaultValue: [] });
                  }}
                  checked={!!marketUndesignated}
                  disabled={editDisabled}
                >
                  마켓 미지정
                </Checkbox>
              </div>
            </div>
          </FieldWithLabel>
          <FieldWithLabel label='상품명' required>
            <div className='w-[472px]'>
              {marketUndesignated ? (
                <ProductGroupSelectField
                  disabled={editDisabled}
                  control={control}
                  name='productGroupId'
                  onChange={handleProductGroupSelect}
                  value={productGroupId}
                  initialOption={
                    productGroupId
                      ? {
                          value: productGroupId,
                          label: promotionProductGroupName,
                        }
                      : undefined
                  }
                />
              ) : (
                <SelectField
                  disabled={editDisabled}
                  control={control}
                  name='productGroupId'
                  options={marketProductGroupsOption}
                  onChange={handleProductGroupSelect}
                  value={productGroupId}
                  placeholder='--선택--'
                />
              )}
            </div>
          </FieldWithLabel>
          <Typography.Text className='text-[#00000073]'>
            *한 명에게 발송되는 품목리스트입니다.
          </Typography.Text>
          <PromotionProductsTable disabled={editDisabled} />
          <div className='flex flex-col gap-4'>
            <SwitchField
              label='배송비 별도부과'
              disabled={editDisabled}
              control={control}
              name='isDeliveryFee'
            />
            {isDeliveryFee && (
              <>
                <FieldWithLabel label='배송비 부담주체' required>
                  <div className='flex w-[472px] gap-2'>
                    <SelectField
                      disabled={editDisabled}
                      control={control}
                      name='deliveryFeeBearer'
                      options={DELIVERY_FEE_BEARER_OPTIONS.slice(0, 2)}
                    />
                    <NumberWithUnknownField
                      disabled={editDisabled}
                      control={control}
                      name='deliveryFee'
                      onUnknownClick={() =>
                        setValue('deliveryFee', null, formSetValueOption)
                      }
                    />
                  </div>
                </FieldWithLabel>
                <FieldWithLabel label='배송비 정산대상' required>
                  <div className='flex w-[472px] gap-2'>
                    <SelectField
                      disabled={editDisabled}
                      control={control}
                      name='deliveryFeeSettlementTarget'
                      options={DELIVERY_FEE_SETTLEMENT_TARGET}
                    />
                    <NumberWithUnknownField
                      disabled={editDisabled}
                      control={control}
                      name='deliveryFeeSettlementPrice'
                      onUnknownClick={() =>
                        setValue(
                          'deliveryFeeSettlementPrice',
                          null,
                          formSetValueOption
                        )
                      }
                    />
                  </div>
                </FieldWithLabel>
              </>
            )}
          </div>
          <div className='mt-4 flex justify-end gap-2'>
            <Button onClick={onClose}>취소</Button>
            <Button htmlType='submit' type='primary' disabled={!isDirty}>
              일괄수정
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default UpdatePromotionModal;
