import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { SETTLEMENT_SHEET_BLOCK_CLASSNAME } from './const';

const PX_TO_MM_RATE = 0.2645; // px -> mm

const A4_WIDTH = 210; // mm
const A4_HEIGHT = 297; // mm
const PADDING = 40 * PX_TO_MM_RATE; // mm
const CONTENT_WIDTH = A4_WIDTH - 2 * PADDING; //mm

export async function createPdf(fileName: string) {
  const elements = [
    ...document.querySelectorAll(`.${SETTLEMENT_SHEET_BLOCK_CLASSNAME}`),
  ];
  const doc = new jsPDF('p', 'mm', 'a4');

  // element -> image canvas 변환 + height(mm) 계산
  const htmlImages = await Promise.all(
    elements.map(async (element) => {
      const { width: elementWidth, height: elementHeight } =
        element.getBoundingClientRect();
      const ratio = elementHeight / elementWidth;
      const height = Math.floor(CONTENT_WIDTH * ratio);

      const canvas = await html2canvas(element as HTMLElement);
      const image = canvas.toDataURL();

      return { image, height };
    })
  );

  // image height 계산 후 page 분리 및 생성
  let accHeight = PADDING;
  htmlImages.forEach(({ height, image }) => {
    if (accHeight + height > A4_HEIGHT - PADDING * 2) {
      doc.addPage();
      accHeight = PADDING;
      doc.addImage(image, 'jpg', PADDING, accHeight, CONTENT_WIDTH, height);
      accHeight += height;
    } else {
      doc.addImage(image, 'jpg', PADDING, accHeight, CONTENT_WIDTH, height);
      accHeight += height;
    }
  });

  doc.save(`${fileName}.pdf`);
}
