import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { App, Button, Tag, Typography } from 'antd';
import { omit } from 'lodash-es';
import { useNavigate, useParams } from 'react-router-dom';

import NotFound from '../common/components/404';
import PageLoader from '../common/components/PageLoader';
import { HistoriesDocument } from '../common/graphql/history.generated';
import { usePermissions } from '../common/hooks/usePermissions';
import { extractBadRequestError, removeCache } from '../common/utils/apollo';
import UpdateSellerForm from '../domain/seller/components/UpdateSellerForm';
import { defaultSellersVariables } from '../domain/seller/const';
import {
  useDeleteSellerMutation,
  useSellerQuery,
  useUpdateSellerMutation,
} from '../domain/seller/graphql/seller.generated';
import { SellersDocument } from '../domain/seller/graphql/sellers.generated';
import { toSellerFormValues } from '../domain/seller/helper/sellerForm';
import { UpdateSellerFormValues } from '../domain/seller/types';
import { UpdateSellerSettlementInfoGraphqlInput } from '../schema/types';

function SellerDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const { modal, message } = App.useApp();

  const client = useApolloClient();

  const sellerId = Number(params.sellerId);
  const { data, error, loading } = useSellerQuery({
    variables: { adminSellerId: sellerId },
    onError() {
      removeCache(client.cache, {
        id: sellerId,
        __typename: 'SellerGraphqlType',
      });
    },
  });

  const [deleteSeller] = useDeleteSellerMutation();
  const [updateSeller] = useUpdateSellerMutation();

  const { hasPermission } = usePermissions('SELLER_EDIT');

  const seller = data?.adminSeller;

  const removeSeller = (id: number) => {
    modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: '셀러삭제',
      content:
        '삭제하면 셀러의 정보를 되돌릴 수 없습니다.\n삭제를 진행하시겠습니까?',
      async onOk() {
        await deleteSeller({
          variables: { sellerId: id },
          onCompleted() {
            message.success('셀러가 삭제되었습니다.');
            navigate('/sellers');
          },
          update(cache) {
            const cacheId = cache.identify({
              id,
              __typename: 'SellerGraphqlType',
            });
            cache.evict({ id: cacheId });
          },
          refetchQueries: [
            HistoriesDocument,
            {
              query: SellersDocument,
              variables: defaultSellersVariables,
            },
          ],
          onError({ graphQLErrors }) {
            const noSellerError = extractBadRequestError(graphQLErrors);
            if (noSellerError) {
              message.error(noSellerError.message);

              removeCache(client.cache, {
                id: sellerId,
                __typename: 'SellerGraphqlType',
              });
            } else {
              message.error('셀러삭제를 실패하였습니다.');
            }
          },
        });
      },
      okText: '네',
      cancelText: '아니오',
      centered: true,
    });
  };

  const handleSubmit = async (data: UpdateSellerFormValues) => {
    const updateSellerInput = omit(
      {
        ...data,
        settlementInfos: data.settlementInfos.filter(
          // info.type = null 인 상황을 제거하고 SellerSettlementInfoInput으로 타입 가드
          (info): info is UpdateSellerSettlementInfoGraphqlInput => !!info.type
        ),
      },
      ['isUniquePhoneNumber', 'isCheckedPhoneNumber']
    );

    await updateSeller({
      variables: { updateSellerInput },
      onCompleted() {
        message.success('저장이 완료되었습니다.');
        navigate('/sellers');
      },
      refetchQueries: [
        HistoriesDocument,
        {
          query: SellersDocument,
          variables: defaultSellersVariables,
        },
      ],
      onError({ graphQLErrors }) {
        const noSellerError = extractBadRequestError(graphQLErrors);

        if (noSellerError) {
          message.error(noSellerError.message);

          const id = client.cache.identify({
            id: sellerId,
            __typename: 'SellerGraphqlType',
          });
          client.cache.evict({ id });
        } else {
          message.error('저장을 실패하였습니다.');
        }
      },
    });
  };

  if (error) {
    return <NotFound subTitle={'셀러를 찾을 수 없습니다.'} />;
  }

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className={'mb-lg bg-white p-lg'}>
      <div className={'mb-xl flex'}>
        <div className={'flex-1'}>
          <Tag className={'mb-sm'}>{`셀러ID: ${params.sellerId || ''}`}</Tag>
          <Typography.Title
            className={'m-0 flex items-center gap-sm'}
            level={3}
          >
            {data?.adminSeller?.name}
          </Typography.Title>
        </div>
        <div>
          <Button
            danger
            onClick={() => seller?.id && removeSeller(seller?.id)}
            disabled={!hasPermission}
          >
            셀러삭제
          </Button>
        </div>
      </div>
      <div className={'mb-[80px]'}>
        {seller && (
          <UpdateSellerForm
            initialValues={toSellerFormValues(seller)}
            onSubmitOk={handleSubmit}
            editable={hasPermission}
          />
        )}
      </div>
    </div>
  );
}

export default SellerDetails;
