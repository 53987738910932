import { ExclamationCircleFilled } from '@ant-design/icons';
import { Alert, Spin, Typography } from 'antd';
import { useMemo } from 'react';

import EmptyTable from '../common/components/EmptyTable';
import WithDefaultSearchParam from '../common/hoc/WithDefaultSearchParam';
import OrderClaimsTable from '../domain/cs/components/OrderClaimsTable';
import SalesChannelTab from '../domain/cs/components/SalesChannelTab';
import SearchFormWithFilterModal from '../domain/cs/components/SearchFormWithFilterModal';
import Summary, { SummaryProps } from '../domain/cs/components/Summary';
import {
  defaultClaimStatus,
  defaultSearchParam,
} from '../domain/cs/helper/list';
import { useClaimStatusCount } from '../domain/cs/hooks/useClaimStatusCount';
import { useSearchClaimsParams } from '../domain/cs/hooks/useSearchClaimsParams';

function CsReturnList() {
  const { setSearchParams, response, searchParams } =
    useSearchClaimsParams('RETURN');
  const { data, loading } = response;
  const {
    startedAt,
    endedAt,
    salesChannelType,
    periodType,
    searchKeyword,
    claimStatus,
  } = searchParams;

  const { statuses, loading: statusCountLoading } =
    useClaimStatusCount('RETURN');

  const total = data?.adminOrders?.totalCount || 0;

  const items: SummaryProps['items'] = useMemo(() => {
    const summaryItems: SummaryProps['items'] = [
      {
        key: 'RETURN_REQUESTED',
        label: '반품요청',
        count: statuses.RETURN_REQUESTED || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: 'RETURN_REQUESTED',
            claimStatus: 'RETURN_REQUESTED',
            orderStatus: undefined,
          }),
      },
      {
        key: 'RETURN_RECEIPT',
        label: '반품접수',
        count: statuses.RETURN_RECEIPT || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: 'RETURN_RECEIPT',
            claimStatus: 'RETURN_RECEIPT',
            orderStatus: undefined,
          }),
      },
      {
        key: 'RETURN_RECLAIM_COMPLETED',
        label: '반품회수완료',
        count: statuses.RETURN_RECLAIM_COMPLETED || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: 'RETURN_RECLAIM_COMPLETED',
            claimStatus: 'RETURN_RECLAIM_COMPLETED',
            orderStatus: undefined,
          }),
      },
      {
        key: 'RETURN_HOLD_COMPLETED',
        label: (
          <span className={'inline-flex items-center'}>
            반품완료대기
            {!!statuses.RETURN_HOLD_COMPLETED && (
              <Typography.Text
                type={'warning'}
                className={'ml-2 inline-flex items-center justify-center'}
              >
                <ExclamationCircleFilled className={'text-xl'} />
              </Typography.Text>
            )}
          </span>
        ),
        count: statuses.RETURN_HOLD_COMPLETED || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: 'RETURN_HOLD_COMPLETED',
            claimStatus: 'RETURN_HOLD_COMPLETED',
            orderStatus: undefined,
          }),
      },
      {
        key: 'RETURN_COMPLETED',
        label: '반품완료',
        count: statuses.RETURN_COMPLETED || 0,
        onClick: () =>
          setSearchParams({
            activeClaimStatus: undefined,
            claimStatus: 'RETURN_COMPLETED',
            orderStatus: 'RETURN_COMPLETED',
          }),
      },
    ];

    const isSellerShop = salesChannelType === 'SELLER_SHOP';
    const isPBShop = salesChannelType === 'PB_SHOP';

    return summaryItems.map((item) => {
      if (isSellerShop) {
        return {
          ...item,
          disabled: ['RETURN_HOLD_COMPLETED', 'RETURN_REQUESTED'].includes(
            item.key
          ),
        };
      }

      if (isPBShop) {
        return {
          ...item,
          disabled: item.key === 'RETURN_HOLD_COMPLETED',
        };
      }

      return item;
    });
  }, [statuses, setSearchParams, salesChannelType]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='bg-white p-6'>
        <SalesChannelTab
          onChange={(v) => {
            setSearchParams({
              salesChannelType: v,
              claimStatus:
                v === 'SELLER_SHOP' ? 'RETURN_RECEIPT' : 'RETURN_REQUESTED',
            });
          }}
        />
        <Summary
          label='반품현황'
          activeKey={claimStatus}
          items={items}
          loading={statusCountLoading}
        />
      </div>
      <div className='bg-white p-6'>
        <Typography.Title level={5}>검색</Typography.Title>
        <SearchFormWithFilterModal
          onSearch={setSearchParams}
          initialValue={{
            startedAt,
            endedAt,
            periodType,
            searchKeyword,
          }}
        />
      </div>
      <div className='bg-white p-6'>
        <Typography.Title level={4} className='m-0'>
          주문리스트
        </Typography.Title>
        {claimStatus === 'RETURN_HOLD_COMPLETED' && (
          <Alert
            message={
              '케미스트에서 반품완료 후 와이어드민에서도 반품완료 처리를 꼭 진행해 주세요. 반품완료대기 상태의 주문건은 정산이 불가능합니다.'
            }
            type='warning'
            showIcon
            className={'mt-4 p-4'}
          />
        )}
        <div className='mt-4'>
          <Spin spinning={loading}>
            {!data?.adminOrders?.edges.length && <EmptyTable />}
            {!!data?.adminOrders?.edges.length && (
              <OrderClaimsTable
                orders={data.adminOrders.edges}
                total={total}
                claimStatus={claimStatus}
                tableType={'RETURN'}
              />
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default WithDefaultSearchParam(CsReturnList, {
  ...defaultSearchParam,
  claimStatus: defaultClaimStatus.RETURN,
  activeClaimStatus: defaultClaimStatus.RETURN,
});
