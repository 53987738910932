import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminSummaryMarketForProductGroupQueryVariables = Types.Exact<{
  filter: Types.MarketForProductGroupPageQueryFilter;
}>;


export type AdminSummaryMarketForProductGroupQuery = { __typename?: 'Query', adminSummaryMarketForProductGroup: { __typename?: 'MarketSummaryGraphqlType', totalOrderAmount: number } };

export type AdminCountMarketByProductGroupIdForProductGroupQueryVariables = Types.Exact<{
  productGroupId: Types.Scalars['Int']['input'];
}>;


export type AdminCountMarketByProductGroupIdForProductGroupQuery = { __typename?: 'Query', adminCountMarketByProductGroupIdForProductGroup: Array<{ __typename?: 'CountMarketStatusesGraphqlType', count: number, status: string }> };

export type AdminMarketsForProductGroupQueryVariables = Types.Exact<{
  filter: Types.MarketForProductGroupPageQueryFilter;
  order?: Types.InputMaybe<Types.Scalars['Order']['input']>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminMarketsForProductGroupQuery = { __typename?: 'Query', adminMarketsForProductGroup: { __typename?: 'AdminMarketPageGraphqlType', count: number, edges: Array<{ __typename?: 'PaginatedMarketGraphqlTypeEdge', node: { __typename?: 'MarketGraphqlType', name: string, session?: number | null, sellingChannel?: Types.ESellingChannel | null, totalOrderAmount?: number | null, endedAt?: string | null, startedAt: string, totalItemQuantity?: number | null, id: number } }> } };


export const AdminSummaryMarketForProductGroupDocument = gql`
    query AdminSummaryMarketForProductGroup($filter: MarketForProductGroupPageQueryFilter!) {
  adminSummaryMarketForProductGroup(filter: $filter) {
    totalOrderAmount
  }
}
    `;

/**
 * __useAdminSummaryMarketForProductGroupQuery__
 *
 * To run a query within a React component, call `useAdminSummaryMarketForProductGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSummaryMarketForProductGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSummaryMarketForProductGroupQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminSummaryMarketForProductGroupQuery(baseOptions: Apollo.QueryHookOptions<AdminSummaryMarketForProductGroupQuery, AdminSummaryMarketForProductGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSummaryMarketForProductGroupQuery, AdminSummaryMarketForProductGroupQueryVariables>(AdminSummaryMarketForProductGroupDocument, options);
      }
export function useAdminSummaryMarketForProductGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSummaryMarketForProductGroupQuery, AdminSummaryMarketForProductGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSummaryMarketForProductGroupQuery, AdminSummaryMarketForProductGroupQueryVariables>(AdminSummaryMarketForProductGroupDocument, options);
        }
export type AdminSummaryMarketForProductGroupQueryHookResult = ReturnType<typeof useAdminSummaryMarketForProductGroupQuery>;
export type AdminSummaryMarketForProductGroupLazyQueryHookResult = ReturnType<typeof useAdminSummaryMarketForProductGroupLazyQuery>;
export type AdminSummaryMarketForProductGroupQueryResult = Apollo.QueryResult<AdminSummaryMarketForProductGroupQuery, AdminSummaryMarketForProductGroupQueryVariables>;
export const AdminCountMarketByProductGroupIdForProductGroupDocument = gql`
    query AdminCountMarketByProductGroupIdForProductGroup($productGroupId: Int!) {
  adminCountMarketByProductGroupIdForProductGroup(productGroupId: $productGroupId) {
    count
    status
  }
}
    `;

/**
 * __useAdminCountMarketByProductGroupIdForProductGroupQuery__
 *
 * To run a query within a React component, call `useAdminCountMarketByProductGroupIdForProductGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountMarketByProductGroupIdForProductGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountMarketByProductGroupIdForProductGroupQuery({
 *   variables: {
 *      productGroupId: // value for 'productGroupId'
 *   },
 * });
 */
export function useAdminCountMarketByProductGroupIdForProductGroupQuery(baseOptions: Apollo.QueryHookOptions<AdminCountMarketByProductGroupIdForProductGroupQuery, AdminCountMarketByProductGroupIdForProductGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCountMarketByProductGroupIdForProductGroupQuery, AdminCountMarketByProductGroupIdForProductGroupQueryVariables>(AdminCountMarketByProductGroupIdForProductGroupDocument, options);
      }
export function useAdminCountMarketByProductGroupIdForProductGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCountMarketByProductGroupIdForProductGroupQuery, AdminCountMarketByProductGroupIdForProductGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCountMarketByProductGroupIdForProductGroupQuery, AdminCountMarketByProductGroupIdForProductGroupQueryVariables>(AdminCountMarketByProductGroupIdForProductGroupDocument, options);
        }
export type AdminCountMarketByProductGroupIdForProductGroupQueryHookResult = ReturnType<typeof useAdminCountMarketByProductGroupIdForProductGroupQuery>;
export type AdminCountMarketByProductGroupIdForProductGroupLazyQueryHookResult = ReturnType<typeof useAdminCountMarketByProductGroupIdForProductGroupLazyQuery>;
export type AdminCountMarketByProductGroupIdForProductGroupQueryResult = Apollo.QueryResult<AdminCountMarketByProductGroupIdForProductGroupQuery, AdminCountMarketByProductGroupIdForProductGroupQueryVariables>;
export const AdminMarketsForProductGroupDocument = gql`
    query AdminMarketsForProductGroup($filter: MarketForProductGroupPageQueryFilter!, $order: Order, $pagination: Pagination) {
  adminMarketsForProductGroup(
    filter: $filter
    order: $order
    pagination: $pagination
  ) {
    count
    edges {
      node {
        name
        session
        sellingChannel
        totalOrderAmount
        endedAt
        startedAt
        totalItemQuantity
        id
      }
    }
  }
}
    `;

/**
 * __useAdminMarketsForProductGroupQuery__
 *
 * To run a query within a React component, call `useAdminMarketsForProductGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMarketsForProductGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMarketsForProductGroupQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminMarketsForProductGroupQuery(baseOptions: Apollo.QueryHookOptions<AdminMarketsForProductGroupQuery, AdminMarketsForProductGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMarketsForProductGroupQuery, AdminMarketsForProductGroupQueryVariables>(AdminMarketsForProductGroupDocument, options);
      }
export function useAdminMarketsForProductGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMarketsForProductGroupQuery, AdminMarketsForProductGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMarketsForProductGroupQuery, AdminMarketsForProductGroupQueryVariables>(AdminMarketsForProductGroupDocument, options);
        }
export type AdminMarketsForProductGroupQueryHookResult = ReturnType<typeof useAdminMarketsForProductGroupQuery>;
export type AdminMarketsForProductGroupLazyQueryHookResult = ReturnType<typeof useAdminMarketsForProductGroupLazyQuery>;
export type AdminMarketsForProductGroupQueryResult = Apollo.QueryResult<AdminMarketsForProductGroupQuery, AdminMarketsForProductGroupQueryVariables>;