import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketOptionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MarketPageQueryFilter>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type MarketOptionsQuery = { __typename?: 'Query', adminMarkets: { __typename?: 'AdminMarketPageGraphqlType', edges: Array<{ __typename?: 'PaginatedMarketGraphqlTypeEdge', node: { __typename?: 'MarketGraphqlType', id: number, name: string, productGroups: Array<{ __typename?: 'ProductGroupGraphqlType', id: number, name: string, products: Array<{ __typename?: 'ProductGraphqlType', id: number, name: string, useType: Types.EProductUseType, items?: Array<{ __typename?: 'ItemGraphqlType', id: number, name: string }> | null }> }> } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage?: boolean | null } | null } };

export type PromotionMarketEventsQueryVariables = Types.Exact<{
  marketId: Types.Scalars['Int']['input'];
}>;


export type PromotionMarketEventsQuery = { __typename?: 'Query', adminMarket: { __typename?: 'MarketGraphqlType', id: number, events?: Array<{ __typename?: 'MarketEventGraphqlType', id: number, type: Types.EMarketEventType, count?: number | null, shippingBearer?: Types.EShippingBearer | null, shippingCost?: number | null, products: Array<{ __typename?: 'MarketEventProductGraphqlType', id: number, name: string, productId?: number | null, count: number, customerBearingCost?: number | null, inhouseBuyPrice?: number | null, isCustomerBearing?: boolean | null, isFreeProvided: boolean, isPrepayment?: boolean | null, isSellerBearing?: boolean | null, isWiredBearing?: boolean | null, sellerBearingCost?: number | null, wiredBearingCost?: number | null, items?: Array<{ __typename?: 'ItemGraphqlType', id: number, name: string, isInfinityStock: boolean }> | null }> }> | null } };


export const MarketOptionsDocument = gql`
    query MarketOptions($filter: MarketPageQueryFilter, $pagination: Pagination) {
  adminMarkets(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
        productGroups {
          id
          name
          products {
            id
            name
            useType
            items {
              id
              name
            }
          }
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;

/**
 * __useMarketOptionsQuery__
 *
 * To run a query within a React component, call `useMarketOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketOptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useMarketOptionsQuery(baseOptions?: Apollo.QueryHookOptions<MarketOptionsQuery, MarketOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketOptionsQuery, MarketOptionsQueryVariables>(MarketOptionsDocument, options);
      }
export function useMarketOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketOptionsQuery, MarketOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketOptionsQuery, MarketOptionsQueryVariables>(MarketOptionsDocument, options);
        }
export type MarketOptionsQueryHookResult = ReturnType<typeof useMarketOptionsQuery>;
export type MarketOptionsLazyQueryHookResult = ReturnType<typeof useMarketOptionsLazyQuery>;
export type MarketOptionsQueryResult = Apollo.QueryResult<MarketOptionsQuery, MarketOptionsQueryVariables>;
export const PromotionMarketEventsDocument = gql`
    query PromotionMarketEvents($marketId: Int!) {
  adminMarket(marketId: $marketId) {
    id
    events {
      id
      type
      count
      shippingBearer
      shippingCost
      products {
        id
        name
        productId
        count
        customerBearingCost
        inhouseBuyPrice
        isCustomerBearing
        isFreeProvided
        isPrepayment
        isSellerBearing
        isWiredBearing
        sellerBearingCost
        wiredBearingCost
        inhouseBuyPrice
        items {
          id
          name
          isInfinityStock
        }
      }
    }
  }
}
    `;

/**
 * __usePromotionMarketEventsQuery__
 *
 * To run a query within a React component, call `usePromotionMarketEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionMarketEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionMarketEventsQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function usePromotionMarketEventsQuery(baseOptions: Apollo.QueryHookOptions<PromotionMarketEventsQuery, PromotionMarketEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromotionMarketEventsQuery, PromotionMarketEventsQueryVariables>(PromotionMarketEventsDocument, options);
      }
export function usePromotionMarketEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionMarketEventsQuery, PromotionMarketEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromotionMarketEventsQuery, PromotionMarketEventsQueryVariables>(PromotionMarketEventsDocument, options);
        }
export type PromotionMarketEventsQueryHookResult = ReturnType<typeof usePromotionMarketEventsQuery>;
export type PromotionMarketEventsLazyQueryHookResult = ReturnType<typeof usePromotionMarketEventsLazyQuery>;
export type PromotionMarketEventsQueryResult = Apollo.QueryResult<PromotionMarketEventsQuery, PromotionMarketEventsQueryVariables>;