import { SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback } from 'react';

import { useProductsInSelectFieldLazyQuery } from '../../graphql/products.generated';
import AsyncSelect from '../Input/AsyncSelect';

export type SelectFieldProps = SelectProps & {
  initialOption?: { value: number; label: string };
  initialLoad?: boolean;
};

const ProductSelect = forwardRef<BaseSelectRef, SelectFieldProps>(function (
  { initialLoad, ...props },
  ref
) {
  const [fetchProduct] = useProductsInSelectFieldLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const fetchItems = useCallback(
    async (name: string) => {
      const { data } = await fetchProduct({
        variables: {
          filter: { searchKeyword: name },
          pagination: { skip: 0, take: 20 },
        },
      });
      return (
        data?.adminProducts?.edges.map(({ node }) => ({
          label: node.name,
          value: node.id,
          data: node,
        })) || []
      );
    },
    [fetchProduct]
  );

  return (
    <AsyncSelect
      ref={ref}
      initialLoad={initialLoad}
      className={'w-full'}
      fetchItems={fetchItems}
      {...props}
    />
  );
});

export default ProductSelect;
