import { ApolloCache } from '@apollo/client';
import { App } from 'antd';
import { groupBy } from 'lodash-es';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { deleteCacheFields } from '../../../common/utils/apollo';
import { EOrderStatus } from '../../../schema/types';
import { OrderListTableSelectedRowContext } from '../contexts/OrderListTableSelectedRowContext';
import {
  AdminCountOrderStatusesDocument,
  AdminOrdersDocument,
  useAdminBulkPatchOrderForReleaseMutation,
  useAdminBulkPatchOrderStatusMutation,
} from '../graphql/orders.generated';
import { OrderListTableDeliveryFormValues } from '../types';

type BulkPatchOrderStatusType = {
  status: EOrderStatus;
};

export default function useOrderListAction() {
  const { message } = App.useApp();
  const { getValues } = useFormContext<OrderListTableDeliveryFormValues>();
  const [mutateRelease] = useAdminBulkPatchOrderForReleaseMutation();
  const [mutatePatchOrderStatus] = useAdminBulkPatchOrderStatusMutation();

  const { selectedRowKeys, rowSpanGroupIds } = useContext(
    OrderListTableSelectedRowContext
  );

  const releaseOrders = async () => {
    const orders = getValues('orders');

    if (!orders.length) {
      void message.error('주문을 선택해주세요.');
      return;
    }

    const groupByOrderGroupId = groupBy(selectedRowKeys, 'groupId');
    const updatedOrders = Object.keys(groupByOrderGroupId).reduce(
      (acc, groupId) => {
        const group = groupByOrderGroupId[groupId];

        if (rowSpanGroupIds.includes(Number(groupId))) {
          const firstOrder = group[0]; // 첫번째 주문 배송 정보가 통합 배송 정보
          const { deliveryCompanyCode, trackingNumber } =
            orders[firstOrder.key as number];
          const updatedGroupOrders = group.map(({ id }) => ({
            id,
            deliveryCompanyCode,
            trackingNumber,
          }));

          return [...acc, ...updatedGroupOrders];
        }

        const updatedSingleOrders = group.map(({ id, key }) => {
          const deliveryCompanyCode = orders[key as number].deliveryCompanyCode;
          const trackingNumber = orders[key as number].trackingNumber;

          return {
            id,
            deliveryCompanyCode,
            trackingNumber,
          };
        });

        return [...acc, ...updatedSingleOrders];
      },
      [] as {
        id: number;
        deliveryCompanyCode?: string;
        trackingNumber?: string;
      }[]
    );

    if (updatedOrders.length === 0) {
      void message.error('변경 가능한 주문이 없습니다. 다시 확인해주세요.');
      return;
    }

    const badOrders = updatedOrders.filter(
      (order) => !order.deliveryCompanyCode || !order.trackingNumber
    );

    if (badOrders.length > 0) {
      void message.error('택배사와 송장번호를 확인해주세요.');
      return;
    }

    await mutateRelease({
      variables: {
        orderData: {
          orders: updatedOrders,
        },
      },
      refetchQueries: [AdminOrdersDocument, AdminCountOrderStatusesDocument],
      onError({ message: errorMessage }) {
        void message.error(errorMessage);
      },
      onCompleted() {
        void message.success('정상적으로 처리되었습니다.');
      },
    });
  };

  const getChangedStatusMessage = (status: EOrderStatus) => {
    switch (status) {
      case 'PAYMENT_COMPLETED':
        return '결제완료로 변경되었습니다.';
      case 'DELIVERY_WAIT':
        return '배송준비로 변경되었습니다.';
      case 'DELIVERY_COMPLETED':
        return '배송완료로 강제 변경되었습니다.';
      default:
        return '정상적으로 처리되었습니다.';
    }
  };

  const bulkPatchOrderStatus = async ({ status }: BulkPatchOrderStatusType) => {
    await mutatePatchOrderStatus({
      variables: {
        orderData: {
          orders: selectedRowKeys.map(({ id }) => ({ id })),
          status,
        },
      },
      update: (cache: ApolloCache<any>) =>
        deleteCacheFields(cache, ['adminOrders']),
      refetchQueries: [AdminCountOrderStatusesDocument],
      onError({ message: errorMessage }) {
        void message.error(errorMessage);
      },
      onCompleted() {
        const completedMessage = getChangedStatusMessage(status);
        void message.success(completedMessage);
      },
    });
  };

  return {
    releaseOrders,
    bulkPatchOrderStatus,
  };
}
