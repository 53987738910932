import { Select, SelectProps } from 'antd';
import { get } from 'lodash-es';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import { FieldProps } from '../../../../common/components/Fields/types';
import { PRODUCT_GROUP_STATUSES } from '../../const';

type StatusSelectFieldProps<TFieldValues extends FieldValues> = SelectProps &
  FieldProps & {
    control: Control<TFieldValues>;
    name: Path<TFieldValues>;
  };

export default function StatusSelectField<TFieldValues extends FieldValues>({
  name,
  label,
  required,
  control,
  hideError,
  hideErrorMessage,
  ...props
}: StatusSelectFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const error = get(formState.errors, name);
        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;
        const status = !hideError && errorMessage ? 'error' : '';

        return (
          <FieldWithLabel label={label} required={required}>
            <Select
              options={PRODUCT_GROUP_STATUSES}
              status={status}
              {...props}
              {...field}
            />

            {!hideErrorMessage && !hideError && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}
