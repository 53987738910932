import dayjs from 'dayjs';
import { useMemo } from 'react';

import { CHARGE_DETAIL_LABEL, SETTLEMENT_TARGET_LABEL } from '../const';
import { DepositListTableDataType } from '../types';

import useDepositListQuery from './useDepositListQuery';

function useDepositTable() {
  const { data, loading } = useDepositListQuery();

  const dataSources: DepositListTableDataType[] =
    useMemo(
      () =>
        data?.adminOrderGroupDeposits?.edges.map(({ node }) => ({
          key: node.id,
          depositId: node.id,
          depositCode: node.code,
          status: node.status,
          chargeDetail: CHARGE_DETAIL_LABEL[node.chargeDetail],
          depositAmount: node.depositAmount.toLocaleString(),
          depositorName: node.depositorName,
          settlementTarget: SETTLEMENT_TARGET_LABEL[node.settlementTarget],
          settlementTargetName: node.settlementTargetName || '',
          orderCode: node.group?.code || '',
          creatorOperatorName: node.creatorOperatorName, // 요청자
          assigneeOperatorName: node.assigneeOperatorName, // 담당자
          createdAt: dayjs(node.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          depositedAt: node.depositedAt
            ? dayjs(node.depositedAt).format('YYYY.MM.DD')
            : '-',
          comment: node.comment,
        })),
      [data]
    ) || [];

  return {
    dataSources,
    loading,
    total: data?.adminOrderGroupDeposits?.totalCount || 0,
  };
}

export default useDepositTable;
