import { MENU } from './const';

export function findActiveMenuKeys(pathname: string) {
  let keys: string[] = [];
  MENU.forEach((item) => {
    if (item.children) {
      item.children.forEach((sub) => {
        if (pathname.startsWith(sub.key)) {
          keys = [item.key, sub.key];
        }
      });
    } else {
      if (pathname.startsWith(item.key)) {
        keys = [item.key];
      }
    }
  });
  return keys;
}
