import { Button, Result } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

type NotFoundProps = {
  subTitle?: string;
};

function NotFound({ subTitle }: NotFoundProps) {
  const navigate = useNavigate();
  return (
    <Result
      status='404'
      title='404'
      subTitle={subTitle || '페이지를 찾을 수 없습니다.'}
      extra={
        <div className={'inline-flex gap-lg'}>
          <Button onClick={() => navigate(-1)} type='primary'>
            뒤로가기
          </Button>
          <Link to={'/'}>
            <Button type='primary'>홈으로</Button>
          </Link>
        </div>
      }
    />
  );
}

export default NotFound;
