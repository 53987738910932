import { Input, InputProps, SelectProps } from 'antd';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from './FieldWithLabel';
import SelectField from './SelectField';
import { FieldProps } from './types';

type SelectFieldProps<TFieldValues extends FieldValues> = {
  selectProps: SelectProps & {
    name: Path<TFieldValues>;
  };
  inputProps: InputProps & {
    name: Path<TFieldValues>;
  };
} & FieldProps & {
    control: Control<TFieldValues>;
    selectPosition?: 'before' | 'after';
  };

function TextWithSelectField<TFieldValues extends FieldValues>({
  label,
  required,
  selectPosition = 'before',
  selectProps,
  inputProps,
  control,
}: SelectFieldProps<TFieldValues>) {
  const Select = <SelectField showSearch control={control} {...selectProps} />;

  return (
    <Controller
      name={inputProps.name}
      control={control}
      render={({ field }) => (
        <FieldWithLabel label={label} required={required}>
          <Input
            addonBefore={selectPosition === 'before' ? Select : undefined}
            addonAfter={selectPosition === 'after' ? Select : undefined}
            {...field}
            {...inputProps}
          />
        </FieldWithLabel>
      )}
    />
  );
}

export default TextWithSelectField;
