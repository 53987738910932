import { assign, omit, pickBy } from 'lodash-es';
import qs from 'qs';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate, useLocation, NavigateOptions } from 'react-router-dom';

import { RouteScrollContext } from '../contexts/RouteScrollContext';

function useRouteSearchParams<T>() {
  const { preventScrollTop } = useContext(RouteScrollContext);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => qs.parse(location.search.slice(1)),
    [location.search]
  );

  const setSearchParams = useCallback(
    (params: Partial<T>, options?: NavigateOptions) => {
      const undefinedKeys = Object.keys(
        pickBy(params, (value) => {
          if (value === undefined) return true;
          if (Array.isArray(value) && value.length === 0) return true;
          if (typeof value === 'string' && value.length === 0) return true;
          return false;
        })
      );
      const paramsWithoutUndefined = omit(params, undefinedKeys);
      const searchParamsWithoutUndefined = omit(searchParams, undefinedKeys);
      const newSearchParams = assign(
        searchParamsWithoutUndefined,
        paramsWithoutUndefined
      );
      const queryString = qs.stringify(newSearchParams);

      if (options?.preventScrollReset) preventScrollTop();

      navigate(`${location.pathname}?${queryString}`, {
        replace: true,
        ...options,
      });
    },
    [searchParams, navigate, location.pathname]
  );

  const replaceSearchParams = useCallback(
    (params: Partial<T>, options?: NavigateOptions) => {
      const undefinedKeys = Object.keys(
        pickBy(params, (value) => {
          if (value === undefined) return true;
          if (Array.isArray(value) && value.length === 0) return true;
          if (typeof value === 'string' && value.length === 0) return true;
          return false;
        })
      );
      const paramsWithoutUndefined = omit(params, undefinedKeys);

      const queryString = qs.stringify(paramsWithoutUndefined);

      if (options?.preventScrollReset) preventScrollTop();

      navigate(`${location.pathname}?${queryString}`, {
        replace: true,
        ...options,
      });
    },
    [navigate, location.pathname]
  );

  return useMemo(
    () => ({
      searchParams: searchParams as T,
      setSearchParams,
      replaceSearchParams,
    }),
    [searchParams, setSearchParams, replaceSearchParams]
  );
}

export default useRouteSearchParams;
