import { LoadingOutlined } from '@ant-design/icons';
import { App, Button, Divider, Input, Spin } from 'antd';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import NumberField from '../../../../common/components/Fields/NumberField';
import SelectField from '../../../../common/components/Fields/SelectField';
import TextareaField from '../../../../common/components/Fields/TextareaField';
import TextField from '../../../../common/components/Fields/TextField';
import SuffixIcon from '../../../../common/components/Input/SuffixIcon';
import Modal from '../../../../common/components/Modal';
import { useFormError } from '../../../../common/hooks/useFormError';
import { CHARGE_DETAIL_OPTIONS, SETTLEMENT_TARGET_OPTIONS } from '../../const';
import {
  AdminCountOrderGroupDepositStatusesDocument,
  AdminOrderGroupDepositsDocument,
  useAdminCreateOrderGroupDepositMutation,
} from '../../graphql/deposit.generated';
import useCreateDeposit from '../../hooks/useCreateDeposit';

type CreateDepositModalProps = {
  onClose: () => void;
};

function CreateDepositModal({ onClose }: CreateDepositModalProps) {
  const {
    methods,
    handleOrderGroupCode,
    handleSettlementTarget,
    orderGroupCode,
    orderGroupData,
    orderGroupLoading,
    orderGroupError,
  } = useCreateDeposit();
  const { message, modal } = App.useApp();
  const { onInvalid } = useFormError();
  const { control, watch, handleSubmit } = methods;
  const [createDepositMutation] = useAdminCreateOrderGroupDepositMutation();
  const [settlementTarget, settlementTargetName] = watch([
    'settlementTarget',
    'settlementTargetName',
  ]);

  const onCancel = useCallback(() => {
    void modal.confirm({
      title: '작성취소',
      content: (
        <>
          변경사항이 저장되지 않습니다.
          <br />
          취소하시겠습니까?
        </>
      ),
      okText: '네',
      cancelText: '아니오',
      onOk: onClose,
    });
  }, [modal, onClose]);

  const onSubmit = handleSubmit((orderGroupDepositData) => {
    void modal.confirm({
      title: '입금대기 등록',
      content: (
        <>
          등록 후에는 수정이 불가합니다.
          <br />
          작성하신 사항을 등록하시겠습니까?
        </>
      ),
      okText: '네',
      cancelText: '아니오',
      onOk: async () =>
        await createDepositMutation({
          variables: {
            orderGroupDepositData,
          },
          refetchQueries: [
            AdminCountOrderGroupDepositStatusesDocument,
            AdminOrderGroupDepositsDocument,
          ],
          onCompleted() {
            void message.success('등록되었습니다.');
            onClose();
          },
          onError() {
            void message.error('등록을 실패했습니다.');
          },
        }),
    });
  }, onInvalid);

  return (
    <Modal title='입금대기 등록' onCancel={onCancel} footer={null}>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <div className='flex flex-col gap-6 py-4'>
            <FieldWithLabel required label='비용상세'>
              <SelectField
                className='w-[120px]'
                control={control}
                name='chargeDetail'
                options={CHARGE_DETAIL_OPTIONS}
                placeholder='--선택--'
              />
            </FieldWithLabel>
            <FieldWithLabel required label='연관 와이어드주문코드'>
              <Input
                onChange={handleOrderGroupCode}
                value={orderGroupCode}
                status={orderGroupCode && orderGroupError ? 'error' : undefined}
                suffix={
                  orderGroupLoading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  ) : (
                    <SuffixIcon
                      status={
                        !orderGroupCode
                          ? undefined
                          : orderGroupError
                          ? 'error'
                          : 'success'
                      }
                    />
                  )
                }
              />
              {orderGroupCode && orderGroupError && (
                <p className={'text-xs text-red-500'}>
                  일치하는 와이어드주문코드가 없습니다.
                </p>
              )}
            </FieldWithLabel>
            {orderGroupData && (
              <>
                <Divider className='m-0' orientation='left'>
                  상세정보
                </Divider>
                <FieldWithLabel required label='금액'>
                  <NumberField
                    control={control}
                    name='depositAmount'
                    numberType='price'
                    placeholder='금액입력'
                  />
                </FieldWithLabel>
                <FieldWithLabel required label='입금자명'>
                  <TextField
                    control={control}
                    name='depositorName'
                    maxLength={15}
                    placeholder='입금자명 또는 예금주명 입력'
                  />
                </FieldWithLabel>
                <div className='grid grid-cols-[120px_1fr] gap-2'>
                  <FieldWithLabel required label='정산대상'>
                    <SelectField
                      control={control}
                      name='settlementTarget'
                      options={SETTLEMENT_TARGET_OPTIONS}
                      onChange={handleSettlementTarget}
                      value={settlementTarget}
                      placeholder='선택'
                    />
                  </FieldWithLabel>
                  {(settlementTarget === 'SELLER' ||
                    settlementTarget === 'VENDOR') && (
                    <FieldWithLabel
                      label={
                        settlementTarget === 'VENDOR' ? '업체명' : '셀러명'
                      }
                    >
                      <Input value={settlementTargetName || ''} disabled />
                    </FieldWithLabel>
                  )}
                </div>
                <FieldWithLabel label='비고'>
                  <TextareaField
                    control={control}
                    name='comment'
                    placeholder='내용입력'
                  />
                </FieldWithLabel>
              </>
            )}
          </div>
          <div className='mt-4 flex justify-end gap-2'>
            <Button onClick={onCancel}>취소</Button>
            <Button type='primary' htmlType='submit'>
              등록
            </Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}

export default CreateDepositModal;
