import { uniqBy } from 'lodash-es';
import { FieldValues } from 'react-hook-form';

import SelectField, {
  SelectFieldProps,
} from '../../../common/components/Fields/SelectField';
import { EMarketStatus } from '../../../schema/types';
import { MARKET_STATUS } from '../const';
import { getMarketStatusOptions } from '../helper';

function MarketStatusSelectField<TFieldValues extends FieldValues>({
  initialStatus,
  startedAt,
  endedAt,
  ...props
}: SelectFieldProps<
  TFieldValues,
  EMarketStatus,
  { label: string; value: EMarketStatus }
> & {
  initialStatus: EMarketStatus;
  startedAt: string;
  endedAt: string | null;
}) {
  const initialOption = MARKET_STATUS.find(
    (option) => option.value === initialStatus
  );

  const options = getMarketStatusOptions(startedAt, endedAt).concat(
    initialOption ?? []
  );
  return <SelectField options={uniqBy(options, 'value')} {...props} />;
}

export default MarketStatusSelectField;
