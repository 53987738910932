import { Checkbox, CheckboxProps } from 'antd';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

type CheckboxFieldProps<TFieldValues extends FieldValues> = CheckboxProps & {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
};

export default function CheckboxField<TFieldValues extends FieldValues>({
  name,
  control,
  disabled,
  children,
  onChange,
  ...props
}: CheckboxFieldProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Checkbox
          {...field}
          checked={field.value}
          disabled={disabled}
          children={children}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
          {...props}
        />
      )}
    />
  );
}
