import { ProductCardType } from '../types';

import ProductCard from './Card';

type ProductCardListProps = {
  data: ProductCardType[];
};

export default function ProductCardList({ data }: ProductCardListProps) {
  return (
    <div className='-mx-1 -my-2 flex flex-wrap'>
      {data.map((product, index) => (
        <div className='max-w-[25%] basis-1/4 px-1 py-2' key={index}>
          <ProductCard {...product} />
        </div>
      ))}
    </div>
  );
}
