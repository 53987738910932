import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminVendorInSettlementListQueryVariables = Types.Exact<{
  vendorId: Types.Scalars['Int']['input'];
}>;


export type AdminVendorInSettlementListQuery = { __typename?: 'Query', adminVendorWithBrands: { __typename?: 'VendorGraphqlType', id: number, name: string, businessName?: string | null, settlementDates?: Array<string> | null } };


export const AdminVendorInSettlementListDocument = gql`
    query AdminVendorInSettlementList($vendorId: Int!) {
  adminVendorWithBrands(vendorId: $vendorId) {
    id
    name
    businessName
    settlementDates
  }
}
    `;

/**
 * __useAdminVendorInSettlementListQuery__
 *
 * To run a query within a React component, call `useAdminVendorInSettlementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminVendorInSettlementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminVendorInSettlementListQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useAdminVendorInSettlementListQuery(baseOptions: Apollo.QueryHookOptions<AdminVendorInSettlementListQuery, AdminVendorInSettlementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminVendorInSettlementListQuery, AdminVendorInSettlementListQueryVariables>(AdminVendorInSettlementListDocument, options);
      }
export function useAdminVendorInSettlementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminVendorInSettlementListQuery, AdminVendorInSettlementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminVendorInSettlementListQuery, AdminVendorInSettlementListQueryVariables>(AdminVendorInSettlementListDocument, options);
        }
export type AdminVendorInSettlementListQueryHookResult = ReturnType<typeof useAdminVendorInSettlementListQuery>;
export type AdminVendorInSettlementListLazyQueryHookResult = ReturnType<typeof useAdminVendorInSettlementListLazyQuery>;
export type AdminVendorInSettlementListQueryResult = Apollo.QueryResult<AdminVendorInSettlementListQuery, AdminVendorInSettlementListQueryVariables>;