import { ErrorBoundary } from '@sentry/react';
import { Layout, Menu, Result, Spin } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from '../../routes/const';
import { SIDER_COLLAPSED_WIDTH, SIDER_WIDTH } from '../../theme/const';
import { AUTH_ERRORS } from '../constants/error';
import RouteScrollProvider from '../contexts/RouteScrollContext';
import { useMeQuery } from '../graphql/me.generated';

import { MENU } from './const';
import Dev from './Dev';
import Header from './Header';
import { findActiveMenuKeys } from './helper';
import PageTitle from './PageTitle';

const { Content, Sider } = Layout;

const AVAILABLE_MENUS = [
  ROUTES.MARKETS.path,
  ROUTES.PRODUCTS.path,
  ROUTES.SELLERS.path,
  ROUTES.VENDORS.path,
  ROUTES.PROMOTION_RELEASES.path,
  ROUTES.OPERATORS.path,
  ROUTES.ORDERS.path,
  ROUTES.CS.path,
  ROUTES.SETTLEMENTS.path,
];

export default function RootLayout() {
  const location = useLocation();
  const { loading, error } = useMeQuery();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState<string[]>(
    findActiveMenuKeys(location.pathname)
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>(() =>
    findActiveMenuKeys(location.pathname)
  );

  const contentWidthClassName = useMemo(
    () => (collapsed ? 'w-min-content-collapsed' : 'w-min-content-expanded'),
    [collapsed]
  );

  useEffect(() => {
    setSelectedKeys(findActiveMenuKeys(location.pathname));
    setOpenKeys(findActiveMenuKeys(location.pathname));
  }, [location.pathname]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    const isAuthError = (error?.graphQLErrors || []).some((error) => {
      return AUTH_ERRORS.includes(error.extensions.code as string);
    });

    // 401 error 발생시 Apollo Link 레벨에서 로그인 페이지로 이동시키기에 여기서는 로딩만 보여준다.
    if (isAuthError) {
      return <Loading />;
    }

    return (
      <Result
        status={'500'}
        title={'알 수 없는 에러가 발생했습니다.'}
        subTitle={'새로고침 또는 플랫폼조직에 문의해주세요.'}
      />
    );
  }

  return (
    <RouteScrollProvider>
      <Layout className='min-h-screen min-w-app'>
        <Header />
        <Layout hasSider>
          <Sider
            className='sticky left-0 top-appHeader z-20 h-[calc(100vh-64px)]'
            width={SIDER_WIDTH}
            collapsed={collapsed}
            collapsible
            onCollapse={(value) => setCollapsed(value)}
            collapsedWidth={SIDER_COLLAPSED_WIDTH}
          >
            <Menu
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              mode='inline'
              className='h-full border-r-0'
              items={MENU.filter((menu) => AVAILABLE_MENUS.includes(menu.key))}
              onOpenChange={(openKeys) => {
                setOpenKeys(openKeys);
              }}
            />
          </Sider>
          <Layout className='z-10 flex-1'>
            <Content
              className={`w-full px-5 py-4 ${contentWidthClassName} m-auto`}
            >
              <PageTitle />
              <ErrorBoundary
                fallback={
                  <Result
                    status={'500'}
                    title={'알 수 없는 에러가 발생했습니다.'}
                    subTitle={'새로고침 또는 플랫폼조직에 문의해주세요.'}
                  />
                }
              >
                <Outlet />
              </ErrorBoundary>
            </Content>
            <Dev />
          </Layout>
        </Layout>
      </Layout>
    </RouteScrollProvider>
  );
}

const Loading = () => {
  return (
    <div className='flex min-h-screen items-center justify-center'>
      <Spin />
    </div>
  );
};
