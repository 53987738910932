import { Collapse } from 'antd';
import dayjs from 'dayjs';
import { has } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import DateField from '../../../common/components/Fields/DateField';
import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import NumberField from '../../../common/components/Fields/NumberField';
import RadioField from '../../../common/components/Fields/RadioField';
import SelectField from '../../../common/components/Fields/SelectField';
import SwitchField from '../../../common/components/Fields/SwitchField';
import TextareaField from '../../../common/components/Fields/TextareaField';
import TextField from '../../../common/components/Fields/TextField';
import TimeField from '../../../common/components/Fields/TimeField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { getErrorStatus } from '../../../common/utils/form';
import {
  DELIVERY_FEE_TYPES,
  PURCHASE_ORDER_TYPES,
  RELEASE_TYPES,
} from '../const';
import { getExpectedSettlementDate } from '../helper';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../types';

import MarketRangePicker from './MarketRangePicker';

function MarketInfoFields() {
  const { hasPermission } = usePermissions('MARKET_EDIT');

  const [openDeliveryFields, setOpenDeliveryFields] = useState(false);
  const { control, watch, setValue, formState } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();

  const { errors } = formState;

  const start = watch('startedAt');
  const end = watch('endedAt') || null;
  const isSellerAssigned = watch('isSellerAssigned');
  const deliveryType = watch('deliveryFeeType');
  const isRegionFee = watch('isRegionFee');
  const releaseType = watch('releaseType');

  useEffect(() => {
    const hasDeliveryError = [
      'deliveryFeeType',
      'deliveryCost',
      'freeDeliveryConditionFee',
      'isRegionFee',
      'additionalFee',
      'jejuIslandFee',
    ].some((key) => has(errors, key));

    if (hasDeliveryError) {
      setOpenDeliveryFields(true);
    }
  }, [errors]);

  useEffect(() => {
    const subscribe = watch((data, { name }) => {
      if (
        name === 'releaseType' &&
        data.releaseType === 'BULK' &&
        !data.purchaseOrderType
      ) {
        setValue('purchaseOrderType', 'NEXT_DAY');
      }
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, [setValue, watch]);

  return (
    <>
      <div className={'grid gap-xl'}>
        {!isSellerAssigned && (
          <TextField
            required={hasPermission}
            disabled={!hasPermission}
            control={control}
            name={'name'}
            label={'마켓명'}
            placeholder={'마켓명 입력(100자 이내)'}
            maxLength={100}
            className={'w-marketCreateInputWidth @lg:w-updateFormWidth'}
          />
        )}

        <FieldWithLabel required={hasPermission} label={'마켓기간'}>
          <MarketRangePicker
            className={'w-marketCreateInputWidth @lg:w-updateFormWidth'}
            disabled={!hasPermission}
            allowEmpty={[false, true]}
            placeholder={['시작일', '상시']}
            onChange={([start, end]) => {
              setValue('startedAt', start || dayjs().toISOString(), {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              });
              setValue('endedAt', end, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              });

              const expectedSettlementAt = getExpectedSettlementDate(end);

              setValue('expectedSettlementAt', expectedSettlementAt, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              });
            }}
            value={[start, end]}
            status={
              getErrorStatus(errors.startedAt) || getErrorStatus(errors.endedAt)
            }
            errorMessages={[errors.startedAt?.message, errors.endedAt?.message]}
          />
        </FieldWithLabel>

        <div className={'flex w-full gap-md @lg:w-updateFormWidth'}>
          {isSellerAssigned && (
            <div className={'w-full'}>
              <NumberField
                disabled={!hasPermission}
                label={'마켓 차수'}
                name={'session'}
                control={control}
                placeholder={'숫자 입력'}
                suffix={'차'}
                numberType={'price'}
              />
            </div>
          )}

          <DateField
            disabled={!hasPermission || !watch('endedAt')}
            required={hasPermission && !!watch('endedAt')}
            label={'정산예정일'}
            control={control}
            name={'expectedSettlementAt'}
            className={'w-marketCreateInputWidth @lg:w-full'}
            placeholder={'미정'}
          />
        </div>

        {isSellerAssigned && (
          <NumberField
            className={'w-marketCreateInputWidth @lg:w-updateFormWidth'}
            disabled={!hasPermission}
            label={'예상매출'}
            name={'expectedSales'}
            control={control}
            placeholder={'숫자만 입력'}
            suffix={'원'}
            numberType={'price'}
          />
        )}

        <TextareaField
          className={'w-marketCreateInputWidth @lg:w-updateFormWidth'}
          disabled={!hasPermission}
          autoSize={{ minRows: 2 }}
          label={'비고(특이사항)'}
          name={'comment'}
          placeholder={'텍스트 입력'}
          control={control}
        />

        <Collapse
          activeKey={openDeliveryFields ? 'delivery' : undefined}
          ghost
          size={'large'}
          onChange={(key) => setOpenDeliveryFields(!!key.length)}
          className={
            'p-0 [&_.ant-collapse-content-box]:p-0 [&_.ant-collapse-content]:text-sm [&_.ant-collapse-header]:px-0 [&_.ant-collapse-header]:py-2'
          }
          items={[
            {
              key: 'delivery',
              label: <span className={'font-semibold'}>배송정보</span>,
              children: (
                <div className={'grid gap-xl py-3'}>
                  <SelectField
                    required={hasPermission}
                    className={'w-marketCreateInputWidth @lg:w-updateFormWidth'}
                    disabled={!hasPermission}
                    label={'대표배송비'}
                    tooltip={
                      '*첫번째 상품의 배송비가 기본으로 설정됩니다. 상품을 변경하는 경우 배송비를 반드시 확인해주세요.'
                    }
                    name={'deliveryFeeType'}
                    control={control}
                    options={DELIVERY_FEE_TYPES}
                    placeholder={'선택'}
                  />

                  {deliveryType !== 'FREE' && (
                    <FieldWithLabel required={hasPermission} label={'배송비'}>
                      <div
                        className={
                          'flex w-full gap-md @lg:w-updateFormWidth @lg:flex-col'
                        }
                      >
                        <div className={'w-marketCreateInputWidth @lg:w-full'}>
                          <NumberField
                            required={hasPermission}
                            disabled={!hasPermission}
                            numberType={'price'}
                            name={'deliveryCost'}
                            control={control}
                            placeholder={'숫자 입력'}
                            suffix={'원'}
                          />
                        </div>
                        {deliveryType === 'CONDITIONAL_FREE' && (
                          <div
                            className={
                              'flex w-marketCreateInputWidth gap-md @lg:w-full'
                            }
                          >
                            <div className={'w-[132px] @lg:w-full'}>
                              <NumberField
                                required={hasPermission}
                                disabled={!hasPermission}
                                numberType={'price'}
                                name={'freeDeliveryConditionFee'}
                                control={control}
                                placeholder={'숫자 입력'}
                                suffix={'원'}
                              />
                            </div>

                            <div className={'flex h-8 items-center @lg:w-full'}>
                              <span>이상 무료배송</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </FieldWithLabel>
                  )}
                  <div className={'w-full'}>
                    <SwitchField
                      disabled={!hasPermission}
                      control={control}
                      name={'isRegionFee'}
                      label={'지역별 배송비'}
                    />
                  </div>
                  {isRegionFee && (
                    <div
                      className={
                        'grid grid-cols-2 gap-md @lg:w-updateFormWidth'
                      }
                    >
                      <NumberField
                        required={hasPermission}
                        disabled={!hasPermission}
                        numberType={'price'}
                        label={'제주도 배송비'}
                        name={'jejuIslandFee'}
                        control={control}
                        placeholder={'숫자 입력'}
                        suffix={'원'}
                      />
                      <NumberField
                        required={hasPermission}
                        disabled={!hasPermission}
                        numberType={'price'}
                        label={'도서산간 배송비'}
                        name={'additionalFee'}
                        control={control}
                        placeholder={'숫자 입력'}
                        suffix={'원'}
                      />
                    </div>
                  )}
                </div>
              ),
            },
          ]}
        />
      </div>
      <FieldWithLabel required={hasPermission} label={'출고유형'}>
        <div className={'flex gap-md @lg:grid @lg:grid-cols-productInfoField'}>
          <div
            className={`flex gap-md ${
              releaseType === 'BULK' ? '@lg:col-span-2' : '@lg:col-span-1'
            } @lg:flex-1`}
          >
            <div
              className={`w-marketCreateInputWidth ${
                releaseType === 'BULK' ? '@lg:w-updateFormWidth' : '@lg:w-full'
              }`}
            >
              <SelectField
                disabled={!hasPermission}
                name={'releaseType'}
                control={control}
                options={RELEASE_TYPES}
                placeholder={'선택'}
              />
            </div>

            {releaseType === 'TODAY' && (
              <div className={'w-[132px] @lg:w-full'}>
                <TimeField
                  disabled={!hasPermission}
                  control={control}
                  name={'releaseTime'}
                  changeOnBlur
                  showNow={false}
                  format={'HH:mm'}
                  className={'w-[132px] @lg:w-full'}
                />
              </div>
            )}

            {releaseType === 'BULK' && (
              <div className={'flex h-8 items-center'}>
                <RadioField
                  control={control}
                  name={'purchaseOrderType'}
                  options={PURCHASE_ORDER_TYPES}
                  className={'w-full'}
                />
              </div>
            )}
          </div>
          <div
            className={
              '@lg:flex-0 flex h-8 basis-[80px] items-center @lg:col-span-1'
            }
          >
            {releaseType === 'TODAY' && <span>이전 당일출고</span>}
          </div>
        </div>
      </FieldWithLabel>
    </>
  );
}

export default MarketInfoFields;
