import dayjs from 'dayjs';
import { useMemo } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { SettlementFilterQuery } from '../../../schema/types';
import { SettlementListSearchParams } from '../types';

function useSettlementListSearchParams() {
  const { searchParams } = useRouteSearchParams<SettlementListSearchParams>();

  const period = useMemo(() => {
    if (!searchParams.periodType) return undefined;

    return {
      type: searchParams.periodType,
      startedAt: searchParams.startedAt
        ? dayjs(searchParams.startedAt).startOf('day').toISOString()
        : undefined,
      endedAt: searchParams.endedAt
        ? dayjs(searchParams.endedAt).endOf('day').toISOString()
        : undefined,
    };
  }, [searchParams.endedAt, searchParams.periodType, searchParams.startedAt]);

  const amounts = useMemo(() => {
    const unknownAmountType = searchParams.unknownAmountType;

    const params: SettlementFilterQuery = {
      sellerSettlementAmount: undefined,
      vendorSettlementAmount: undefined,
      deliveryBearingAmount: undefined,
    };

    if (unknownAmountType === 'DELIVERY_BEARING_AMOUNT') {
      params['deliveryBearingAmount'] = null;
    }

    if (unknownAmountType === 'SELLER_SETTLEMENT_AMOUNT') {
      params['sellerSettlementAmount'] = null;
    }

    if (unknownAmountType === 'VENDOR_SETTLEMENT_AMOUNT') {
      params['vendorSettlementAmount'] = null;
    }

    return params;
  }, [searchParams.unknownAmountType]);

  const filter = {
    period,
    orderCode: searchParams.orderCode,
    orderGroupCode: searchParams.orderGroupCode,
    promotionReleaseCode: searchParams.promotionReleaseCode,
    settlementSheetCode: searchParams.settlementSheetCode,
    salesChannel: searchParams.salesChannel,
    settlementTypes: searchParams.settlementTypes,
    marketId: searchParams.marketId ? Number(searchParams.marketId) : undefined,
    sellerId: searchParams.sellerId ? Number(searchParams.sellerId) : undefined,
    vendorId: searchParams.vendorId ? Number(searchParams.vendorId) : undefined,
    brandId: searchParams.brandId ? Number(searchParams.brandId) : undefined,
    productGroupId: searchParams.productGroupId
      ? Number(searchParams.productGroupId)
      : undefined,
    productId: searchParams.productId
      ? Number(searchParams.productId)
      : undefined,
    receiverName: searchParams.receiverName,
    isExistSettlementSheet:
      searchParams.noSettlement === 'true' ? false : undefined,
    ...amounts,
  };

  return {
    filter,
  };
}

export default useSettlementListSearchParams;
