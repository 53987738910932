import { InputNumber, InputNumberProps, Tooltip } from 'antd';
import { forwardRef } from 'react';

import SuffixIcon from './SuffixIcon';

export type NumberInputProps = Omit<InputNumberProps, 'status'> & {
  status?: 'error' | 'warning' | 'success' | '';
  errorMessage?: string;
  numberType?: 'number' | 'price' | 'percent';
};

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ status, errorMessage, numberType, ...props }, ref) => {
    return (
      <div className={'relative'}>
        <InputNumber
          ref={ref}
          status={status === 'success' ? undefined : status}
          className={'w-full'}
          {...props}
        />
        {/**
         * input의 suffix로 붙이면 hover event가 발생하지 않아서
         * absolute 처리
         */}
        <div
          className={
            `absolute inline-flex h-full items-center` +
            (numberType === 'percent' ? ' right-7' : ' right-md')
          }
        >
          <Tooltip title={errorMessage} color={status}>
            {/**
             * suffix icon이 dynamic하게 변경될 때 포커스를 잃는 이슈가 있어 icon을 span으로 감싼다.
             * https://ant.design/components/input#why-input-lose-focus-when-change-prefixsuffixshowcount
             */}
            <span>
              <SuffixIcon status={status} />
            </span>
          </Tooltip>
        </div>
      </div>
    );
  }
);

export default NumberInput;
