import { cloneDeep } from '@apollo/client/utilities';
import { inRange } from 'lodash-es';
import { useMemo, useCallback, useState, ClipboardEvent } from 'react';
import { useFormContext } from 'react-hook-form';

import { parseNumber } from '../../../common/utils/number';
import { EDITABLE_TABLE_COLUMNS, TABLE_NEW_CELL_CLASSNAME } from '../const';
import {
  EditableTableDataType,
  EditableTableDataTypeKeys,
  PromotionEventFormValues,
  PromotionFormValues,
} from '../types';

const columnByIndex: { [k in number]: EditableTableDataTypeKeys } = {
  0: 'receiverName',
  1: 'receiverPhoneNumber',
  2: 'zipCode',
  3: 'receiverAddress',
  4: 'deliveryMessage',
};

function useEditableTable() {
  const methods = useFormContext<
    PromotionFormValues | PromotionEventFormValues
  >();
  const [newLineRange, setNewLineRange] = useState<number[]>([]);
  const handleOnCell = useCallback(
    (_: any, rowIndex?: number) =>
      inRange(rowIndex!, newLineRange[0], newLineRange[1])
        ? {
            className: TABLE_NEW_CELL_CLASSNAME,
          }
        : {},
    [newLineRange]
  );

  const { watch, resetField, getValues } = methods;
  const promotionReleases = watch('promotionReleases');

  const arrayData = useMemo(() => {
    return promotionReleases.map(
      ({
        receiverName,
        receiverPhoneNumber,
        zipCode,
        receiverAddress,
        deliveryMessage,
      }) => [
        receiverName,
        receiverPhoneNumber,
        zipCode,
        receiverAddress,
        deliveryMessage,
      ]
    );
  }, [promotionReleases]);

  const dataSources = useMemo(
    () =>
      arrayData.map((array, i) =>
        array.reduce(
          (acc, cur, i) => {
            const key = EDITABLE_TABLE_COLUMNS[i].key!;
            return { ...acc, [key]: cur };
          },
          { key: i }
        )
      ) as unknown as EditableTableDataType[],
    [arrayData]
  );
  const handleCellPaste = useCallback(
    (
      ev: ClipboardEvent<HTMLInputElement>,
      index: number,
      columnIndex: number
    ) => {
      ev.preventDefault();
      if (!ev.clipboardData) return;

      const pasteText = ev.clipboardData.getData('text/plain');
      const pasteData = pasteText
        .split(/\r\n|\n|\r/)
        .map((row) => row.split('\t'));
      const promotionReleases = cloneDeep(getValues('promotionReleases'));
      const mapDataToReleases = pasteData.map((data) =>
        data.reduce(
          (acc, cur, curIndex) => {
            if (columnByIndex[curIndex + columnIndex]) {
              let value = cur;
              if (
                columnByIndex[curIndex + columnIndex] ===
                  'receiverPhoneNumber' ||
                columnByIndex[curIndex + columnIndex] === 'zipCode'
              ) {
                value = parseNumber(cur);
              }
              return {
                ...acc,
                [columnByIndex[curIndex + columnIndex]]: value,
              };
            } else {
              return acc;
            }
          },
          {
            receiverName: '',
            receiverPhoneNumber: '',
            zipCode: undefined,
            receiverAddress: '',
            receiverDetailAddress: undefined,
            deliveryMessage: undefined,
          }
        )
      );
      const newReleaseList = [
        ...promotionReleases.slice(0, index),
        ...mapDataToReleases,
        ...promotionReleases.slice(index + mapDataToReleases.length),
      ].slice(0, 100);
      setNewLineRange([index, index + mapDataToReleases.length]);
      resetField('promotionReleases', {
        defaultValue: newReleaseList,
      });
      setTimeout(() => {
        // 복시 붙여넣기 시 스크롤 위치 마지막 셀로 보내기 위한 코드
        const elements = document.querySelectorAll(
          `.${TABLE_NEW_CELL_CLASSNAME}`
        );
        elements[elements.length - 1].scrollIntoView({
          block: 'end',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }, 50);
      setTimeout(() => {
        setNewLineRange([]);
      }, 1000);
    },
    [getValues, resetField]
  );

  return {
    dataSources,
    handleOnCell,
    handleCellPaste,
  };
}

export default useEditableTable;
