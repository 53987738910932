import { Pagination, Table } from 'antd';
import { useCallback } from 'react';

import EmptyTable from '../../../../common/components/EmptyTable';
import useRouteSearchParams from '../../../../common/hooks/useRouteSearchParams';
import {
  APP_HEADER_HEIGHT,
  TABLE_ACTION_HEADER_HEIGHT,
} from '../../../../theme/const';
import useSettlementPermission from '../../hooks/useSettlementPermission';
import useSettlementSheetList from '../../hooks/useSettlementSheetList';
import {
  SettlementSheetListTableDataType,
  SheetListSearchParams,
} from '../../types';

import {
  columns,
  summaryRenderer,
} from './market-settlement-sheet-table-config';

type MarketSettlementSheetTableProps = {
  selectedRowKeys: number[];
  handleSelectedRows: (
    selectedRowKeys: React.Key[],
    selectedRows: SettlementSheetListTableDataType[]
  ) => void;
};

function MarketSettlementSheetTable(props: MarketSettlementSheetTableProps) {
  const hasPermission = useSettlementPermission();
  const { dataSource, loading, total } = useSettlementSheetList();
  const { searchParams, setSearchParams } =
    useRouteSearchParams<SheetListSearchParams>();
  const pageSize = searchParams.pageSize ? Number(searchParams.pageSize) : 50;
  const currentPage = searchParams.currentPage
    ? Number(searchParams.currentPage)
    : 1;
  const handlePagination = useCallback(
    (page: number, size: number) => {
      if (size !== pageSize) {
        setSearchParams({ currentPage: 1, pageSize: size });
      } else {
        setSearchParams({ currentPage: page });
      }
    },
    [pageSize, setSearchParams]
  );

  if (!loading && !dataSource?.length) return <EmptyTable />;
  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 3458 }}
        pagination={false}
        rowSelection={
          hasPermission
            ? {
                selectedRowKeys: props.selectedRowKeys,
                onChange: props.handleSelectedRows,
              }
            : undefined
        }
        sticky={{
          offsetHeader: APP_HEADER_HEIGHT + TABLE_ACTION_HEADER_HEIGHT,
        }}
        size='small'
        tableLayout='fixed'
        summary={(dataSource) => summaryRenderer(dataSource, hasPermission)}
      />
      <div className='my-4 flex items-center justify-end gap-4'>
        <div>총 {total}건</div>
        <Pagination
          showSizeChanger
          onChange={handlePagination}
          current={currentPage}
          pageSize={pageSize}
          total={total}
        />
      </div>
    </>
  );
}

export default MarketSettlementSheetTable;
