import { Divider } from 'antd';
import { useFormContext } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import { SELLER_SETTLEMENTS_MAX_LENGTH } from '../const';
import { defaultSettlement } from '../helper/sellerForm';
import { CreateSellerFormValues, UpdateSellerFormValues } from '../types';

import SettlementItemField from './SettlementItemField';

type SettlementsFieldProps = {
  disabled: boolean;
};

function SettlementsField({ disabled }: SettlementsFieldProps) {
  const { control, setValue, watch, formState } = useFormContext<
    UpdateSellerFormValues | CreateSellerFormValues
  >();

  const settlementInfos = watch('settlementInfos') || [];
  const errorMessage = formState.errors.settlementInfos?.message || '';

  return (
    <ArrayField
      name={'settlementInfos'}
      control={control}
      disabled={disabled}
      errorMessage={errorMessage}
      addButtonProps={
        settlementInfos.length < SELLER_SETTLEMENTS_MAX_LENGTH
          ? {
              type: 'primary',
              children: '정산정보추가',
              onClick({ append }) {
                append(
                  {
                    ...defaultSettlement,
                    isDefault: false,
                  },
                  {
                    focusName: `settlementInfos.${settlementInfos.length}.type`,
                  }
                );
              },
            }
          : undefined
      }
    >
      {({ fields, remove, update, replace }) => {
        return (
          <ul className={'mb-md'}>
            {fields.map((field, index) => {
              return (
                <li key={field.key}>
                  {index > 0 && (
                    <Divider orientation='left'>
                      {`정산정보${index ? index + 1 : ''}`}
                    </Divider>
                  )}

                  <SettlementItemField
                    index={index}
                    disabled={disabled}
                    control={control}
                    hasIsDefaultRadio={fields.length > 1}
                    onRemove={() => {
                      if (fields.length === 1) {
                        replace(defaultSettlement);
                        return;
                      }

                      const settlementInfo = settlementInfos[index];

                      if (settlementInfo.isDefault) {
                        const target = index === 0 ? 1 : index - 1;

                        update(target, {
                          ...settlementInfos[target],
                          isDefault: true,
                        });
                      }

                      remove(index);
                    }}
                    onIsDefaultChange={() => {
                      setValue(
                        'settlementInfos',
                        fields.map((eachField, i) => ({
                          ...settlementInfos?.[i],
                          isDefault: eachField.key === field.key,
                        })),
                        {
                          shouldTouch: true,
                          shouldValidate: true,
                          shouldDirty: true,
                        }
                      );
                    }}
                  />
                </li>
              );
            })}
          </ul>
        );
      }}
    </ArrayField>
  );
}

export default SettlementsField;
