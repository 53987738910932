import { PlusOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { FunctionComponent } from 'react';
import {
  ArrayPath,
  Control,
  FieldValues,
  useFieldArray,
  UseFieldArrayReturn,
} from 'react-hook-form';

import FieldWithLabel from './FieldWithLabel';
import { FieldProps } from './types';

type ChildrenProps<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues>,
> = UseFieldArrayReturn<TFieldValues, TName, 'key'>;

interface ArrayFieldProps<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues>,
> extends FieldProps {
  control: Control<TFieldValues>;
  name: TName;
  children: FunctionComponent<ChildrenProps<TFieldValues, TName>>;
  maxLength?: number;
  disabled?: boolean;
  errorMessage?: string;
  addButtonProps?: Omit<ButtonProps, 'onClick'> & {
    onClick?: (args: ChildrenProps<TFieldValues, TName>) => void;
  };
}

function ArrayField<
  TFieldValues extends FieldValues,
  TName extends ArrayPath<TFieldValues>,
>({
  name,
  control,
  children,
  label,
  errorMessage,
  required,
  disabled,
  maxLength = Infinity,
  addButtonProps,
}: ArrayFieldProps<TFieldValues, TName>) {
  const fieldArray = useFieldArray({
    control,
    name,
    // id 필드와 겹칠 가능성이 있어서 임의의 array key 생성
    keyName: 'key',
  });

  const active = fieldArray.fields.length < maxLength;

  return (
    <FieldWithLabel label={label} required={required}>
      {children(fieldArray)}
      {errorMessage && (
        <p className={'mb-sm text-xs text-red-500'}>{errorMessage}</p>
      )}
      {addButtonProps && (
        <Button
          htmlType={'button'}
          icon={<PlusOutlined />}
          disabled={disabled || !active}
          {...addButtonProps}
          onClick={() => addButtonProps?.onClick?.(fieldArray)}
        />
      )}
    </FieldWithLabel>
  );
}

export default ArrayField;
