import { Descriptions, Divider, Typography } from 'antd';
import { Link, generatePath } from 'react-router-dom';

import { ROUTES } from '../../../../routes/const';
import { EVENT_TYPES_LABEL } from '../../../market/const';
import {
  DELIVERY_FEE_BEARER_LABEL,
  DELIVERY_FEE_SETTLEMENT_TARGET_LABEL,
} from '../../const';
import { numberWithUnknownString } from '../../helper';
import useReleaseDetail from '../../hooks/useReleaseDetail';

function DetailInformation() {
  const { data } = useReleaseDetail();

  if (!data) return null;

  const { adminPromotionRelease } = data;
  const market = adminPromotionRelease.promotion.market;
  const eventCount = adminPromotionRelease.promotion.marketEvent?.count;
  const eventType = adminPromotionRelease.promotion.marketEvent?.type;
  const eventName =
    eventType &&
    eventCount &&
    (typeof eventCount === 'number'
      ? `${EVENT_TYPES_LABEL[eventType]} / ${eventCount}명`
      : EVENT_TYPES_LABEL[eventType]);
  const promotionType = adminPromotionRelease.promotion.promotionType;
  const productGroupName =
    adminPromotionRelease.promotionProducts[0].originalProductGroup?.name ||
    '-';
  const promotionProducts = adminPromotionRelease.promotionProducts;
  const freeProvidedPromotionProducts = promotionProducts.filter(
    ({ isFreeProvided }) => isFreeProvided
  );
  const chargedPromotionProducts = promotionProducts.filter(
    ({ isFreeProvided }) => !isFreeProvided
  );
  const isDeliveryFee = adminPromotionRelease.promotion.isDeliveryFee;
  const deliveryFee = adminPromotionRelease.promotion.deliveryFee;
  const deliveryFeeBearer = adminPromotionRelease.promotion.deliveryFeeBearer;
  const deliveryFeeSettlementTarget =
    adminPromotionRelease.promotion.deliveryFeeSettlementTarget;
  const deliveryFeeSettlementPrice =
    adminPromotionRelease.promotion.deliveryFeeSettlementPrice;

  return (
    <div>
      <Divider orientation='left' className='m-0 py-4'>
        상세정보
      </Divider>
      <Typography.Text className='text-[#00000073]'>
        *한 명에게 발송되는 품목리스트입니다.
      </Typography.Text>
      <Descriptions
        className='mt-4'
        bordered
        layout='horizontal'
        column={1}
        labelStyle={{ width: 200 }}
      >
        <Descriptions.Item label='마켓명'>
          <div className='flex items-center gap-4'>
            {market ? market.name : '마켓미지정'}
            {market && (
              <Link
                to={generatePath(ROUTES.MARKET_DETAIL.path, {
                  marketId: market.id,
                })}
                target={'_blank'}
              >
                [마켓상세로가기]
              </Link>
            )}
          </div>
        </Descriptions.Item>
        <Descriptions.Item
          label={promotionType === 'EVENT' ? '이벤트명' : '상품명'}
        >
          {promotionType === 'EVENT' ? eventName : productGroupName}
        </Descriptions.Item>
        <Descriptions.Item label='업체무상품목' contentStyle={{ padding: 0 }}>
          <Descriptions
            bordered
            layout='horizontal'
            className='promotion__descriptions'
            column={1}
            labelStyle={{ width: 0 }}
          >
            {freeProvidedPromotionProducts.map((product, i) => (
              <Descriptions.Item key={i}>
                {product.originalProduct
                  ? product.originalProduct.name
                  : product.customPromotionProductName}{' '}
                ({product.setCount.toLocaleString()}개)
                {!!product.promotionItems.length &&
                  ` / ${product.promotionItems
                    .map((item) => item.originalItem?.name)
                    .join(', ')}`}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Descriptions.Item>
        <Descriptions.Item label='업체유상품목' contentStyle={{ padding: 0 }}>
          <Descriptions
            bordered
            layout='horizontal'
            className='promotion__descriptions'
            column={1}
            labelStyle={{ width: 0 }}
          >
            {chargedPromotionProducts.map((product, i) => {
              const bearing: string[] = [];
              if (product.isSellerBearing) {
                bearing.push(
                  `셀러부담: ${numberWithUnknownString(
                    product.sellerBearingCost
                  )} (${product.isPrepayment ? '선결제' : '정산차감'})`
                );
              }
              if (product.isWiredBearing) {
                bearing.push(
                  `와이어드 부담: ${numberWithUnknownString(
                    product.wiredBearingCost
                  )}`
                );
              }

              return (
                <Descriptions.Item key={i}>
                  {product.originalProduct
                    ? product.originalProduct.name
                    : product.customPromotionProductName}{' '}
                  ({product.setCount.toLocaleString()}개)
                  {!!product.promotionItems.length &&
                    ` / ${product.promotionItems
                      .map((item) => item.originalItem?.name)
                      .join(', ')}`}
                  <br />
                  <span className='text-[#00000073]'>
                    자사매입가:{' '}
                    {numberWithUnknownString(product.inHouseBuyPrice)} /{' '}
                    {bearing.join(', ')}
                  </span>
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        </Descriptions.Item>
        <Descriptions.Item label='배송비 별도부과'>
          {isDeliveryFee
            ? `부담: ${DELIVERY_FEE_BEARER_LABEL[deliveryFeeBearer!]} / ${
                typeof deliveryFee === 'number'
                  ? deliveryFee.toLocaleString()
                  : '-'
              }`
            : '-'}
          <br />
          {isDeliveryFee && deliveryFeeSettlementTarget
            ? `정산: ${
                DELIVERY_FEE_SETTLEMENT_TARGET_LABEL[
                  deliveryFeeSettlementTarget
                ]
              } / ${
                typeof deliveryFeeSettlementPrice === 'number'
                  ? deliveryFeeSettlementPrice.toLocaleString()
                  : '-'
              }`
            : '-'}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default DetailInformation;
