import { Button, Tabs, Tag, Typography } from 'antd';
import { useMemo } from 'react';

import NotFound from '../common/components/404';
import PageLoader from '../common/components/PageLoader';
import { usePermissions } from '../common/hooks/usePermissions';
import useRouteSearchParams from '../common/hooks/useRouteSearchParams';
import BrandDetailFormList from '../domain/vendor/components/BrandDetailFormList';
import VendorDetailForm from '../domain/vendor/components/VendorDetailForm';
import {
  UpdateFormDirtyContextProvider,
  useUpdateFormDirtyContext,
} from '../domain/vendor/contexts/UpdateFormDirtyContext';
import useCancelConfirmModal from '../domain/vendor/hooks/useCancelConfirmModal';
import useRemoveVendorOrBrand from '../domain/vendor/hooks/useRemoveVendorOrBrand';
import useVendorDetailQuery from '../domain/vendor/hooks/useVendorDetailQuery';

function VendorDetail() {
  const { searchParams, setSearchParams } = useRouteSearchParams<{
    tab?: string;
  }>();
  const { isDirty, setIsDirty } = useUpdateFormDirtyContext();
  const activeKey = searchParams.tab || 'company';
  const { data, loading, error } = useVendorDetailQuery();
  const vendorId = data?.adminVendorWithBrands?.id;
  const vendorName = data?.adminVendorWithBrands?.name;
  const { hasPermission } = usePermissions('BRAND_AND_VENDOR_EDIT');
  const removeVendor = useRemoveVendorOrBrand({ type: 'VENDOR', id: vendorId });
  const tabItems = useMemo(
    () => [
      { key: 'company', label: '업체정보', children: <VendorDetailForm /> },
      { key: 'brands', label: '브랜드정보', children: <BrandDetailFormList /> },
    ],
    []
  );
  const openCancelConfirm = useCancelConfirmModal();

  const handleTab = (key: string) => {
    if (isDirty) {
      openCancelConfirm(() => {
        setIsDirty(false);
        if (key === 'company') {
          setSearchParams({ tab: 'company' });
        }
        if (key === 'brands') {
          setSearchParams({ tab: 'brands' });
        }
      });
    } else {
      if (key === 'company') {
        setSearchParams({ tab: 'company' });
      }
      if (key === 'brands') {
        setSearchParams({ tab: 'brands' });
      }
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <NotFound subTitle={'업체/브랜드를 찾을 수 없습니다.'} />;
  }

  return (
    <div className={'mb-lg bg-white p-lg'}>
      <div className={'mb-xl flex'}>
        <div className={'flex-1'}>
          <Tag className={'mb-sm'}>{`업체ID: ${vendorId || ''}`}</Tag>
          <Typography.Title
            className={'m-0 flex items-center gap-sm'}
            level={3}
          >
            {vendorName}
          </Typography.Title>
        </div>
        <div>
          <Button danger onClick={removeVendor} disabled={!hasPermission}>
            업체삭제
          </Button>
        </div>
      </div>
      <div className={'mb-[80px]'}>
        <Tabs
          items={tabItems}
          destroyInactiveTabPane
          activeKey={activeKey}
          onChange={handleTab}
        />
      </div>
    </div>
  );
}

function WithDirtyContext() {
  return (
    <UpdateFormDirtyContextProvider>
      <VendorDetail />
    </UpdateFormDirtyContextProvider>
  );
}

export default WithDirtyContext;
