import { Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { groupBy, mapValues } from 'lodash-es';
import { useMemo } from 'react';

import EmptyTable from '../common/components/EmptyTable';
import WithDefaultSearchParam from '../common/hoc/WithDefaultSearchParam';
import useRouteSearchParams from '../common/hooks/useRouteSearchParams';
import OrderClaimsTable from '../domain/cs/components/OrderClaimsTable';
import SalesChannelTab from '../domain/cs/components/SalesChannelTab';
import SearchFormWithFilterModal from '../domain/cs/components/SearchFormWithFilterModal';
import Summary from '../domain/cs/components/Summary';
import { orderSupportStatus } from '../domain/cs/const';
import { useAdminOrdersInClaimsQuery } from '../domain/cs/graphql/claims.generated';
import { useAdminCountOrderSupportStatusesQuery } from '../domain/cs/graphql/orderSupport.generated';
import { ClaimListSearchParams } from '../domain/cs/type';
import {
  EOrderSupportStatus,
  ESalesChannelType,
  OrderFilterQuery,
} from '../schema/types';

const defaultSearchParam: ClaimListSearchParams = {
  startedAt: dayjs().subtract(3, 'month').startOf('date').toISOString(),
  endedAt: dayjs().endOf('date').toISOString(),
  periodType: 'SUPPORT_MESSAGE_UPDATED_AT',
  salesChannelType: 'KEMI',
  supportStatus: 'SUPPORT_RECEIPT',
};

function CsOrderSupportList() {
  const { searchParams, setSearchParams } =
    useRouteSearchParams<ClaimListSearchParams>();

  const {
    startedAt,
    endedAt,
    searchKeyword,
    periodType,

    brandName,
    productName,
    vendorName,
    marketName,

    supportAssigneeOperatorIds,
    supportCreatorOperatorIds,

    supportStatus,
    salesChannelType,
  } = searchParams;

  const page = searchParams.page ? Number(searchParams.page) : 1;
  const size = searchParams.size ? Number(searchParams.size) : 50;
  const salesChannelTypes: ESalesChannelType[] =
    salesChannelType === 'PB_SHOP'
      ? ['PB_SHOP', 'PB_SHOP_IMWEB', 'PB_SHOP_SMART_STORE']
      : salesChannelType
      ? [salesChannelType]
      : [];

  const filter: OrderFilterQuery = {
    searchKeyword,

    salesChannelTypes,

    brandName,
    productName,
    vendorName,
    marketName,

    supportAssigneeOperatorIds: supportAssigneeOperatorIds
      ?.filter((id) => !isNaN(Number(id)))
      .map((id) => Number(id)),
    supportCreatorOperatorIds: supportCreatorOperatorIds
      ?.filter((id) => !isNaN(Number(id)))
      .map((id) => Number(id)),
    period: periodType
      ? {
          type: periodType,
          startedAt,
          endedAt,
        }
      : undefined,
  };

  const { data: statuses, loading: statusCountLoading } =
    useAdminCountOrderSupportStatusesQuery({
      variables: {
        orderFilter: filter,
      },
    });

  const { data, loading } = useAdminOrdersInClaimsQuery({
    variables: {
      filter: {
        ...filter,
        supportStatuses: supportStatus
          ? supportStatus === 'SUPPORT_COMPLETED'
            ? ['SUPPORT_COMPLETED', 'SUPPORT_CANCELED']
            : [supportStatus]
          : undefined, // @elliot-wired 커밋을 위해 임의로 수정했습니다.
      },
      pagination: {
        skip: size * (page - 1),
        take: size,
      },
      order: {
        supports: {
          createdAt: 'DESC',
        },
      },
    },
  });
  const total = data?.adminOrders?.totalCount || 0;

  const statusCount = useMemo<{ [key in EOrderSupportStatus]?: number }>(() => {
    return mapValues(
      groupBy(statuses?.adminCountOrderSupportStatuses || [], 'status'),
      (v) => v?.[0]?.count || 0
    );
  }, [statuses]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='bg-white p-6'>
        <SalesChannelTab
          onChange={(v) => {
            setSearchParams({
              salesChannelType: v,
              supportStatus: 'SUPPORT_RECEIPT',
            });
          }}
        />
        <Summary
          label='응대현황'
          loading={statusCountLoading}
          items={[
            {
              key: 'SUPPORT_RECEIPT',
              label: orderSupportStatus.SUPPORT_RECEIPT,
              count: statusCount.SUPPORT_RECEIPT || 0,
              onClick: () =>
                setSearchParams({ supportStatus: 'SUPPORT_RECEIPT' }),
            },
            {
              key: 'SUPPORT_PROGRESS',
              label: orderSupportStatus.SUPPORT_PROGRESS,
              count: statusCount.SUPPORT_PROGRESS || 0,
              onClick: () =>
                setSearchParams({ supportStatus: 'SUPPORT_PROGRESS' }),
            },
            {
              key: 'SUPPORT_COMPLETED',
              label: orderSupportStatus.SUPPORT_COMPLETED,
              count:
                (statusCount.SUPPORT_COMPLETED || 0) +
                (statusCount.SUPPORT_CANCELED || 0),
              onClick: () =>
                setSearchParams({ supportStatus: 'SUPPORT_COMPLETED' }),
            },
          ]}
          activeKey={supportStatus}
        />
      </div>
      <div className='bg-white p-6'>
        <Typography.Title level={5}>검색</Typography.Title>
        <SearchFormWithFilterModal
          isOrderSupport
          onSearch={setSearchParams}
          initialValue={{
            startedAt,
            endedAt,
            periodType,
            searchKeyword,
          }}
        />
      </div>
      <div className='bg-white p-6'>
        <Typography.Title level={4} className='m-0'>
          주문리스트
        </Typography.Title>
        <div className='mt-4'>
          <Spin spinning={loading}>
            {!data?.adminOrders?.edges.length && <EmptyTable />}
            {!!data?.adminOrders?.edges.length && (
              <OrderClaimsTable
                orders={data.adminOrders?.edges}
                total={total}
                tableType={'SUPPORT'}
              />
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default WithDefaultSearchParam(CsOrderSupportList, defaultSearchParam);
