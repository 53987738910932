import {
  EMarketStatus,
  EOrderGroupDepositChargeDetail,
  EOrderGroupDepositSettlementTarget,
  EOrderGroupDepositStatus,
  ESellerSettlementType,
  ESellingChannel,
  ESettlementDetailType,
  ESettlementPaymentMethod,
  ESettlementPeriodFilter,
  ESettlementSalesChannel,
  ESettlementSheetPeriodFilter,
  ESettlementSheetStatus,
  ESettlementSheetType,
  ESettlementType,
} from '../../schema/types';

import {
  SettlementCodeOption,
  SettlementSalesChannelOption,
  SettlementUnknownAmountOption,
} from './types';

export const SETTLEMENT_SHEET_TAB_ITEMS = [
  { key: 'MARKET', label: '마켓' },
  { key: 'VENDOR', label: '업체' },
  { key: 'CREATOR', label: '크리에이터', disabled: true },
];

export const DEPOSIT_STATUS_OPTIONS: {
  label: string;
  value: EOrderGroupDepositStatus;
}[] = [
  { label: '입금대기', value: 'WAITING' },
  { label: '입금확인', value: 'CONFIRM' },
];

export const DEPOSIT_STATUS_LABEL: { [k in EOrderGroupDepositStatus]: string } =
  {
    WAITING: '입금대기',
    CONFIRM: '입금확인',
  };

export const CHARGE_DETAIL_OPTIONS: {
  label: string;
  value: EOrderGroupDepositChargeDetail;
}[] = [
  { label: '반품배송비', value: 'RETURN_DELIVERY_FEE' },
  { label: '교환배송비', value: 'EXCHANGE_DELIVERY_FEE' },
];

export const CHARGE_DETAIL_LABEL: {
  [k in EOrderGroupDepositChargeDetail]: string;
} = {
  RETURN_DELIVERY_FEE: '반품배송비',
  EXCHANGE_DELIVERY_FEE: '교환배송비',
};

export const SETTLEMENT_TARGET_OPTIONS: {
  label: string;
  value: EOrderGroupDepositSettlementTarget;
}[] = [
  { label: '와이어드', value: 'WIRED' },
  { label: '셀러', value: 'SELLER' },
  { label: '업체', value: 'VENDOR' },
];

export const SETTLEMENT_TARGET_LABEL: {
  [k in EOrderGroupDepositSettlementTarget]: string;
} = {
  WIRED: '와이어드',
  SELLER: '셀러',
  VENDOR: '업체',
};

export const SELLER_SETTLEMENT_TYPE_LABEL: Record<
  ESellerSettlementType,
  string
> = {
  BUSINESS: '사업자',
  FREELANCER: '프리랜서',
};

export const SELLING_CHANNEL_LABEL: Record<ESellingChannel, string> = {
  KEMI: '케미',
  PB_IMWEB: 'PB몰 - 아임웹',
  PB_SMART_STORE: 'PB몰 - 스마트스토어',
  SELLER_SHOP: '셀러쇼핑몰',
  ETC: '기타',
};

export const SETTLEMENT_SHEET_LIST_PERIOD_OPTIONS: {
  label: string;
  value: ESettlementSheetPeriodFilter;
}[] = [
  { label: '정산예정일', value: 'EXPECTED_SETTLEMENT_AT' },
  { label: '계산서발행일', value: 'TAX_INVOICE_ISSUED_AT' },
  { label: '정산완료일', value: 'SETTLEMENT_FINISHED_AT' },
  { label: '정산서생성일', value: 'SETTLEMENT_CREATED_AT' },
];

export const SETTLEMENT_SHEET_BLOCK_CLASSNAME = 'SETTLEMENT_SHEET_BLOCK';

export const SETTLEMENT_PERIOD_OPTIONS: {
  value: ESettlementPeriodFilter;
  label: string;
}[] = [
  { label: '매출일', value: 'SOLD_AT' },
  { label: '발송일', value: 'RELEASED_AT' },
];

export const SETTLEMENT_SELLING_CHANNEL_OPTIONS: {
  value: SettlementSalesChannelOption;
  label: string;
}[] = [
  { label: '케미', value: 'KEMI' },
  { label: '셀러쇼핑몰', value: 'SELLER_SHOP' },
  { label: 'PB몰', value: 'PB_SHOP' },
  { label: 'PB몰 - 스마트스토어', value: 'PB_SHOP_SMART_STORE' },
  { label: 'PB몰 - 아임웹', value: 'PB_SHOP_IMWEB' },
];

export const SETTLEMENT_TYPE_OPTIONS: {
  value: ESettlementType;
  label: string;
}[] = [
  { label: '상품주문', value: 'PRODUCT_ORDER' },
  { label: '배송비', value: 'DELIVERY_FEE' },
  { label: '샘플', value: 'SAMPLE' },
  { label: '이벤트', value: 'EVENT' },
  { label: '기타', value: 'EXTRA' },
  { label: '입금', value: 'DEPOSIT' },
];

export const SETTLEMENT_UNKNOWN_AMOUNT_OPTIONS: {
  label: string;
  value: SettlementUnknownAmountOption;
}[] = [
  { label: '전체', value: 'ALL' },
  { label: '셀러정산금액 모름', value: 'SELLER_SETTLEMENT_AMOUNT' },
  { label: '업체정산금액 모름', value: 'VENDOR_SETTLEMENT_AMOUNT' },
  { label: '물류부담금액 모름', value: 'DELIVERY_BEARING_AMOUNT' },
];

export const SETTLEMENT_SALES_CHANNEL_LABEL: Record<
  ESettlementSalesChannel,
  string
> = {
  NULL: '-',
  KEMI: '케미',
  SELLER_SHOP: '셀러쇼핑몰',
  PB_SHOP: 'PB몰',
  PB_SHOP_SMART_STORE: 'PB몰 - 스마트스토어',
  PB_SHOP_IMWEB: 'PB몰 - 아임웹',
};

export const SETTLEMENT_TYPE_LABEL: Record<ESettlementType, string> =
  SETTLEMENT_TYPE_OPTIONS.reduce(
    (acc, cur) => {
      acc[cur.value] = cur.label;
      return acc;
    },
    {} as Record<ESettlementType, string>
  );

export const SETTLEMENT_DETAIL_TYPE_LABEL: Record<
  ESettlementDetailType,
  string
> = {
  PAYMENT: '결제',
  REFUND: '환불',
  NORMAL_DELIVERY_PAYMENT: '일반배송 결제',
  NORMAL_DELIVERY_REFUND: '일반배송 환불',
  REGION_DELIVERY_PAYMENT: '지역배송 결제',
  REGION_DELIVERY_REFUND: '지역배송 환불',
  EXCHANGE_DELIVERY_FEE: '교환배송비',
  RETURN_DELIVERY_FEE: '반품배송비',
  PRODUCT: '품목',
  DELIVERY_FEE: '배송비',
};

export const SETTLEMENT_SHEET_TYPE_LABEL: Record<ESettlementSheetType, string> =
  {
    MARKET: '마켓',
    VENDOR: '업체',
    CREATOR: '크리에이터',
  };

export const SETTLEMENT_SHEET_TYPE_OPTIONS: {
  value: ESettlementSheetType;
  label: string;
}[] = [
  { label: '마켓 정산서', value: 'MARKET' },
  { label: '업체 정산서', value: 'VENDOR' },
];

export const SETTLEMENT_MARKET_STATUS_LABEL: Record<EMarketStatus, string> = {
  READY: '마켓준비',
  ON_SALE: '마켓중',
  ENDED: '마켓종료',
  CANCELED: '마켓취소',
  SUSPENDED: '마켓중지',
};

export const SETTLEMENT_CODE_OPTIONS: {
  label: string;
  value: SettlementCodeOption;
}[] = [
  { label: '와이어드주문코드', value: 'orderGroupCode' },
  { label: '와이어드품목주문코드', value: 'orderCode' },
  { label: '정산서코드', value: 'settlementSheetCode' },
  { label: '출고코드/입금코드', value: 'promotionReleaseCode' },
];

export const SETTLEMENT_SHEET_STATUS_LABEL: {
  [k in ESettlementSheetStatus]: string;
} = {
  SETTLEMENT_COMPLETED: '정산완료',
  SETTLEMENT_CONFIRMED: '정산서 확인',
  SETTLEMENT_PENDING: '정산보류',
  SETTLEMENT_UNCOLLECTED: '정산미수',
  SETTLEMENT_WAITING: '정산대기',
};

export const SETTLEMENT_PAYMENT_METHOD_TEXT: {
  [key in ESettlementPaymentMethod]: string;
} = {
  BANK_TRANSFER: '무통장 입금',
  CARD: '카드',
  ETC: '기타',
  FREE: '무료',
  KAKAO_PAY: '카카오페이',
  NAVER_PAY: '네이버페이',
  PAYCO_PAY: '페이코페이',
  PHONE_PAY: '휴대폰결제',
  VIRTUAL_ACCOUNT: '가상계좌',
};

export const SETTLEMENT_SHEET_STATUS_UPDATE_TO: {
  [k in ESettlementSheetStatus]: ESettlementSheetStatus[];
} = {
  SETTLEMENT_WAITING: [], // 정산대기로 상태변경을 할 수 없다.
  SETTLEMENT_CONFIRMED: [
    'SETTLEMENT_WAITING',
    'SETTLEMENT_PENDING',
    'SETTLEMENT_UNCOLLECTED',
  ], // 정산대기, 정산보류, 정산미수 -> 정산서확인으로 상태변경 가능
  SETTLEMENT_COMPLETED: [
    'SETTLEMENT_WAITING',
    'SETTLEMENT_CONFIRMED',
    'SETTLEMENT_PENDING',
    'SETTLEMENT_UNCOLLECTED',
  ], // 정산대기, 정산서확인, 정산보류, 정산미수 -> 정산완료로 상태변경가능
  SETTLEMENT_PENDING: [
    'SETTLEMENT_WAITING',
    'SETTLEMENT_CONFIRMED',
    'SETTLEMENT_UNCOLLECTED',
  ], // 정산대기, 정산서확인, 정산미수 -> 정산보류로 상태변경가능
  SETTLEMENT_UNCOLLECTED: [
    'SETTLEMENT_WAITING',
    'SETTLEMENT_CONFIRMED',
    'SETTLEMENT_UNCOLLECTED',
  ], // 정산대기, 정산서확인, 정산보류 -> 정산미수로 상태변경가능
};
