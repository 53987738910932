import {
  PlusOutlined,
  CalendarOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { Alert, App, Button, Pagination, Segmented, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import EmptyTable from '../common/components/EmptyTable';
import { KEMIST_BASE_URL } from '../common/constants/env';
import { useMeQuery } from '../common/graphql/me.generated';
import { usePermissions } from '../common/hooks/usePermissions';
import useRouteSearchParams from '../common/hooks/useRouteSearchParams';
import CreateMarketForm from '../domain/market/components/CreateMarketForm';
import MarketCalendar from '../domain/market/components/MarketCalendar';
import MarketsSearchForm from '../domain/market/components/MarketsSearchForm';
import MarketsTable from '../domain/market/components/MarketsTable';
import {
  useAdminCreateKemistProductFromMarketMutation,
  useCreateMarketMutation,
} from '../domain/market/graphql/market.generated';
import {
  AdminMarketsDocument,
  AdminMarketsQuery,
  useAdminMarketsQuery,
} from '../domain/market/graphql/markets.generated';
import { toMarketsVariables } from '../domain/market/helper';
import { MarketListSearchParams } from '../domain/market/types';
import { CreateMarketInputGraphqlType } from '../schema/types';

function MarketList() {
  const { data: me } = useMeQuery();
  const { hasPermission } = usePermissions('MARKET_EDIT');
  const { message, modal } = App.useApp();

  const [openCreateMarket, setOpenCreateMarket] = useState(false);
  const [markets, setMarkets] =
    useState<AdminMarketsQuery['adminMarkets']['edges']>();

  const { searchParams, setSearchParams } =
    useRouteSearchParams<MarketListSearchParams>();

  const view = searchParams.view || 'table';
  const { marketEndFilter, marketStartFilter } = searchParams;

  const managerId = me?.adminMe.id;

  const { variables, size, page } = toMarketsVariables(searchParams);

  const [mutate, { data: createdMarket }] = useCreateMarketMutation({
    refetchQueries: [AdminMarketsDocument],
  });
  const [createKemistProduct] = useAdminCreateKemistProductFromMarketMutation();

  const { data, loading, fetchMore } = useAdminMarketsQuery({
    variables,
    skip: view === 'calendar' && (!marketStartFilter || !marketEndFilter),

    async onCompleted(data) {
      setMarkets(data.adminMarkets.edges);

      if (view !== 'calendar') return;
      if (!data.adminMarkets.pageInfo?.hasNextPage) return;

      const endCursor = Number(data.adminMarkets.pageInfo.endCursor);

      await fetchMore({
        variables: {
          ...variables,
          pagination: {
            skip: endCursor,
            take: size,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const edges = [
            ...previousResult.adminMarkets.edges,
            ...fetchMoreResult.adminMarkets.edges,
          ];

          return {
            adminMarkets: {
              ...fetchMoreResult.adminMarkets,
              edges,
            },
          };
        },
      });
    },
  });

  const totalCount = data?.adminMarkets.totalCount || 0;
  const isNoResult = !loading && !markets?.length;

  const handleSearch = (params: MarketListSearchParams) => {
    setSearchParams(params);
  };

  const handlePageChange = (page: number, pageSize: number) => {
    if (size !== pageSize) {
      handleSearch({ currentPage: '1', pageSize: pageSize.toString() });
    } else {
      handleSearch({ currentPage: page.toString() });
    }
  };

  const afterKemiMarketSubmit = useCallback(
    (marketId: number) => {
      return new Promise((resolve, reject) => {
        modal.success({
          centered: true,
          title: '마켓수정완료',
          content:
            '마켓이 수정되었습니다. 바로 케미에 상품을 등록하시겠습니까?',
          okText: '네',
          cancelText: '나중에',
          okCancel: true,
          onOk() {
            createKemistProduct({
              variables: { marketId },
              onCompleted(data) {
                window.open(
                  `${KEMIST_BASE_URL}/products/${data.adminCreateKemistProductFromMarket.productId}`,
                  '_blank'
                );
                resolve(true);
              },
              onError() {
                reject({ message: '케미스트 상품등록을 실패하였습니다.' });
              },
            });
          },
          onCancel() {
            resolve(true);
          },
        });
      });
    },
    [modal]
  );

  const submitCreateMarket = async (
    createMarketInput: CreateMarketInputGraphqlType
  ) => {
    await mutate({
      variables: { createMarketInput },
      async onCompleted(data) {
        try {
          message.success('저장이 완료되었습니다.');

          if (createMarketInput.sellingChannel === 'KEMI') {
            await afterKemiMarketSubmit(data.adminCreateMarket.market.id);
          }
        } catch (e: any) {
          message.error(e?.message);
        } finally {
          setOpenCreateMarket(false);
        }
      },
      onError() {
        message.error('저장을 실패하였습니다.');
      },
    });
  };

  return (
    <div>
      <MarketsSearchForm />

      {createdMarket && (
        <Alert
          className={'mt-xl [&_.ant-alert-action]:self-center'}
          message={
            <Typography.Title level={5}>
              마켓등록이 완료되었어요!
            </Typography.Title>
          }
          description={createdMarket.adminCreateMarket.market.name}
          type='success'
          action={
            <Link
              to={`/markets/${createdMarket.adminCreateMarket.market.id}`}
              state={searchParams}
            >
              <Button type={'default'}>마켓상세보기</Button>
            </Link>
          }
          showIcon
        />
      )}

      <div className='mt-xl flex flex-col gap-4 bg-white p-6'>
        <Typography.Title level={4} className='m-0 flex justify-between'>
          마켓캘린더
          <Button
            disabled={!hasPermission}
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={() => setOpenCreateMarket(true)}
          >
            마켓 등록
          </Button>
        </Typography.Title>
        <div>
          <Segmented
            onChange={(view) => {
              setSearchParams({
                marketEndFilter: undefined,
                marketStartFilter: undefined,
                currentPage: undefined,
                pageSize: undefined,
                sortingField: undefined,
                sortingOrder: undefined,
                view: view as MarketListSearchParams['view'],
              });
            }}
            value={view}
            options={[
              {
                label: '캘린더뷰',
                value: 'calendar',
                icon: <CalendarOutlined />,
              },
              {
                label: '테이블뷰',
                value: 'table',
                icon: <TableOutlined />,
              },
            ]}
          />
        </div>

        {view === 'table' && isNoResult && <EmptyTable />}
        {view === 'table' && !isNoResult && (
          <>
            <MarketsTable
              data={markets || []}
              editable={hasPermission}
              onSearch={handleSearch}
              loading={loading}
            />

            <div className='mt-4 flex items-center justify-end gap-4'>
              <div>{`총 ${totalCount}건`}</div>
              <Pagination
                showSizeChanger
                onChange={handlePageChange}
                current={page}
                pageSize={size}
                total={totalCount}
              />
            </div>
          </>
        )}
        {view === 'calendar' && (
          <>
            <MarketCalendar data={markets || []} loading={loading} />
            <div>{`총 ${totalCount}건`}</div>
          </>
        )}
      </div>

      {openCreateMarket && managerId && (
        <CreateMarketForm
          managerId={managerId}
          close={() => setOpenCreateMarket(false)}
          submit={submitCreateMarket}
        />
      )}
    </div>
  );
}

export default MarketList;
