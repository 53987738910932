import { Select, App, Spin } from 'antd';
import { size } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import Modal from '../../../common/components/Modal';
import { ESampleStatus } from '../../../schema/types';
import { SAMPLE_STATUS_LABEL, SAMPLE_STATUS_OPTIONS } from '../const';
import { useMarketOptionsQuery } from '../graphql/markets.generated';
import {
  AdminCountPromotionStatusesDocument,
  AdminPromotionReleasesDocument,
  useAdminPatchSampleStatusPromotionByIdMutation,
} from '../graphql/promotionRelease.generated';
import usePromotionPermission from '../hooks/usePromotionPermission';
import { PromotionReleaseTableDataType } from '../types';

function PromotionReleaseTableSampleStatusCell({
  record,
}: {
  record: PromotionReleaseTableDataType;
}) {
  const hasPermission = usePromotionPermission();
  const { message } = App.useApp();
  const [value, setValue] = useState(record.sampleStatus);
  const [marketModal, setMarketModal] = useState(false);
  const [marketId, setMarketId] = useState<number>();
  const [updateSampleStatusMutation, { loading }] =
    useAdminPatchSampleStatusPromotionByIdMutation();

  useEffect(() => {
    setValue(record.sampleStatus);
  }, [record.sampleStatus]);

  useEffect(() => {
    if (!marketModal) {
      setMarketId(undefined);
    }
  }, [marketModal]);

  const updateSampleStatus = useCallback(
    async (newValue: ESampleStatus) => {
      const close = message.loading('처리 중입니다.');

      await updateSampleStatusMutation({
        variables:
          newValue === 'MARKET_CONFIRMED'
            ? {
                adminPatchSampleStatusPromotionByIdId: record.promotionId,
                marketId,
                promotionData: {
                  sampleStatus: newValue,
                },
              }
            : {
                adminPatchSampleStatusPromotionByIdId: record.promotionId,
                promotionData: {
                  sampleStatus: newValue,
                },
              },
        onCompleted() {
          setMarketModal(false);
          close();
          void message.success('샘플상태가 수정되었습니다.');
        },
        onError() {
          close();
          setValue(record.sampleStatus);
          void message.error('수정을 실패하였습니다.');
        },
        refetchQueries: [
          AdminPromotionReleasesDocument,
          AdminCountPromotionStatusesDocument,
        ],
      });
    },
    [
      marketId,
      message,
      record.promotionId,
      record.sampleStatus,
      updateSampleStatusMutation,
    ]
  );

  const handleChange = useCallback(
    async (newValue: ESampleStatus) => {
      setValue(newValue);
      if (newValue === 'MARKET_CONFIRMED' && !record.marketId) {
        setMarketModal(true);
        return;
      }
      await updateSampleStatus(newValue);
    },
    [record.marketId, updateSampleStatus]
  );
  if (!hasPermission) {
    return <div>{SAMPLE_STATUS_LABEL[record.sampleStatus]}</div>;
  }
  return (
    <>
      <Spin spinning={loading} size={'small'}>
        <Select
          className='w-full'
          options={SAMPLE_STATUS_OPTIONS}
          value={value}
          onChange={handleChange}
        />
      </Spin>
      {marketModal && (
        <Modal
          title='마켓확정'
          onCancel={() => {
            setMarketModal(false);
            setValue(record.sampleStatus);
          }}
          onOk={() => updateSampleStatus(value)}
        >
          <MarketSelect
            marketId={marketId}
            onChange={setMarketId}
            sellerId={record.sellerId}
            productGroupId={record.promotionProductGroupId}
          />
        </Modal>
      )}
    </>
  );
}

function MarketSelect({
  marketId,
  onChange,
  sellerId,
  productGroupId,
}: {
  marketId?: number;
  onChange: (marketId: number) => void;
  sellerId?: number;
  productGroupId?: number;
}) {
  const { data, fetchMore } = useMarketOptionsQuery({
    variables: {
      filter: { productGroupId, sellerId },
    },
    async onCompleted(data) {
      if (!data?.adminMarkets.pageInfo?.hasNextPage) return;
      const endCursor = Number(data.adminMarkets.pageInfo.endCursor);

      await fetchMore({
        variables: {
          filter: {
            productGroupId,
            sellerId,
          },
          pagination: {
            skip: endCursor,
            take: size,
          },
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const edges = [
            ...previousResult.adminMarkets.edges,
            ...fetchMoreResult.adminMarkets.edges,
          ];

          return {
            adminMarkets: {
              ...fetchMoreResult.adminMarkets,
              edges,
            },
          };
        },
      });
    },
  });
  const options = data?.adminMarkets.edges
    .map(({ node }) => node)
    .map(({ id, name }) => ({ label: name, value: id }));

  return (
    <FieldWithLabel required label='마켓명'>
      <Select
        className='w-full'
        options={options}
        value={marketId}
        onChange={(value) => onChange(value)}
      />
    </FieldWithLabel>
  );
}

export default PromotionReleaseTableSampleStatusCell;
