import { Alert, App, Button, Descriptions, Typography } from 'antd';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import NotFound from '../common/components/404';
import CheckboxField from '../common/components/Fields/CheckboxField';
import HistoryModal from '../common/components/History/HistoryModal';
import PageLoader from '../common/components/PageLoader';
import { HistoriesDocument } from '../common/graphql/history.generated';
import { MeDocument } from '../common/graphql/me.generated';
import { usePermissions } from '../common/hooks/usePermissions';
import {
  OperatorDocument,
  useOperatorQuery,
  useUpdateOperatorMutation,
} from '../domain/operator/graphql/operator.generated';
import { ERole } from '../schema/types';

type Roles = {
  MANAGER_EDIT: boolean;
  SELLER_EDIT: boolean;
  BRAND_AND_VENDOR_EDIT: boolean;
  MARKET_EDIT: boolean;
  PRODUCT_EDIT: boolean;
  PROMOTION_RELEASE_EDIT: boolean;
  ORDER_EDIT: boolean;
  SETTLEMENT_EDIT: boolean;
};

export default function OperatorDetail() {
  const { message } = App.useApp();

  const params = useParams();
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { hasPermission: isEditable } = usePermissions('MANAGER_EDIT');
  const operatorId = Number(params.operatorId);

  const {
    data: operatorData,
    error,
    loading,
  } = useOperatorQuery({
    variables: { adminOperatorId: operatorId },
  });

  const operator = operatorData?.adminOperator;
  const operatorRoles = operator?.roles;
  const operatorName = operator?.name || '-';
  const hasPermission = (role: ERole) => !!operatorRoles?.includes(role);

  const defaultValues = {
    MANAGER_EDIT: hasPermission('MANAGER_EDIT'),
    SELLER_EDIT: hasPermission('SELLER_EDIT'),
    BRAND_AND_VENDOR_EDIT: hasPermission('BRAND_AND_VENDOR_EDIT'),
    MARKET_EDIT: hasPermission('MARKET_EDIT'),
    PRODUCT_EDIT: hasPermission('PRODUCT_EDIT'),
    PROMOTION_RELEASE_EDIT: hasPermission('PROMOTION_RELEASE_EDIT'),
    ORDER_EDIT: hasPermission('ORDER_EDIT'),
    SETTLEMENT_EDIT: hasPermission('SETTLEMENT_EDIT'),
  };

  const { handleSubmit, control, formState } = useForm<Roles>({
    values: defaultValues,
  });
  const isFormEdited = formState.isDirty;
  const [mutate] = useUpdateOperatorMutation();
  const onSubmit: SubmitHandler<Roles> = async (
    roles: Record<ERole, boolean>
  ) => {
    const updateRoles = Object.entries(roles).reduce((acc, [key, value]) => {
      if (value) acc.push(key as ERole);
      return acc;
    }, [] as ERole[]);

    await mutate({
      variables: {
        adminPatchOperatorId: operatorId,
        operatorData: {
          roles: updateRoles,
        },
      },
      refetchQueries: [OperatorDocument, HistoriesDocument, MeDocument],
      onCompleted() {
        message.success('저장이 완료되었습니다.');
        setShowAlert(true);
      },
      onError() {
        message.error('저장을 실패하였습니다.');
      },
    });
  };

  if (error) {
    return <NotFound subTitle={'회원을 찾을 수 없습니다.'} />;
  }

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div className='bg-white p-6'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography.Title level={4}>회원정보</Typography.Title>
        <Descriptions
          bordered
          column={1}
          className='[&>.ant-descriptions-view]:rounded-none'
          labelStyle={{ width: 200 }}
        >
          <Descriptions.Item key={'name'} label={'이름'}>
            {operatorName}
          </Descriptions.Item>
          <Descriptions.Item key={'email'} label={'이메일주소'}>
            {operator?.email}
          </Descriptions.Item>
          <Descriptions.Item key={'permission'} label={'메뉴 수정권한'}>
            <div className='flex flex-col gap-4'>
              <CheckboxField
                name='MANAGER_EDIT'
                control={control}
                children='운영자관리'
                disabled={!isEditable}
              />
              <CheckboxField
                name='SELLER_EDIT'
                control={control}
                children='셀러관리'
                disabled={!isEditable}
              />
              <CheckboxField
                name='BRAND_AND_VENDOR_EDIT'
                control={control}
                children='업체 / 브랜드관리'
                disabled={!isEditable}
              />
              <CheckboxField
                name='MARKET_EDIT'
                control={control}
                children='마켓관리'
                disabled={!isEditable}
              />
              <CheckboxField
                name='PRODUCT_EDIT'
                control={control}
                children='상품관리'
                disabled={!isEditable}
              />
              <CheckboxField
                name='PROMOTION_RELEASE_EDIT'
                control={control}
                children='출고요청관리'
                disabled={!isEditable}
              />
              <CheckboxField
                name='ORDER_EDIT'
                control={control}
                children='주문 / CS관리'
                disabled={!isEditable}
              />
              <CheckboxField
                name='SETTLEMENT_EDIT'
                control={control}
                children='정산관리'
                disabled={!isEditable}
              />
            </div>
          </Descriptions.Item>
        </Descriptions>
        <div className='mt-6 flex justify-between'>
          <Button htmlType={'button'} onClick={() => setIsHistoryOpen(true)}>
            변경히스토리
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            disabled={!isFormEdited || !isEditable}
          >
            저장
          </Button>
        </div>
      </form>
      <HistoryModal
        uiType={'MANAGER'}
        id={operatorId}
        onClose={() => setIsHistoryOpen(false)}
        open={isHistoryOpen}
      />
      {showAlert && (
        <Alert
          showIcon
          closable
          className='mt-4'
          type='success'
          message='회원권한 수정완료'
          description={`${operatorName}의 권한이 수정되었습니다. ${operatorName} 님의 화면 새로고침 시, 수정된 권한이 적용됩니다.`}
          onClose={() => setShowAlert(false)}
        />
      )}
    </div>
  );
}
