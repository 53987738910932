import { App } from 'antd';
import { useContext, useState } from 'react';

import Modal from '../../../common/components/Modal';
import { OrderListTableSelectedRowContext } from '../contexts/OrderListTableSelectedRowContext';
import {
  AdminCountOrderStatusesDocument,
  AdminOrdersDocument,
  useAdminBulkCreateOrderEventMutation,
} from '../graphql/orders.generated';

import EventSelect from './EventSelect';

type EventCreateModalProps = {
  onClose: () => void;
  marketId: number;
};

export default function EventCreateModal({
  onClose,
  marketId,
}: EventCreateModalProps) {
  const { message, modal } = App.useApp();
  const [mutateBulkCreateOrderEvent] = useAdminBulkCreateOrderEventMutation();
  const { selectedRowKeys, setSelectedRowKeys, setRowSpanGroupIds } =
    useContext(OrderListTableSelectedRowContext);
  const [eventId, setEventId] = useState<number>();

  const createOrderEvent = async () => {
    if (!eventId) {
      void message.error('이벤트 아이디가 없습니다. 이벤트를 선택해주세요.');
      return;
    }

    await mutateBulkCreateOrderEvent({
      variables: {
        orderData: {
          orderIds: selectedRowKeys.map(({ id }) => id),
          marketEventId: eventId,
        },
      },
      refetchQueries: [AdminOrdersDocument, AdminCountOrderStatusesDocument],
      onError({ message: errorMessage }) {
        void message.error(errorMessage);
      },
      onCompleted() {
        setSelectedRowKeys([]);
        setRowSpanGroupIds([]);
        void message.success('이벤트가 선정되었습니다.');
      },
    });
  };

  // mutation에 input에 eventId가 추가된 후 수정 예정
  const handleOk = () => {
    void modal.confirm({
      title: '이벤트 처리',
      content: `${selectedRowKeys.length}건을 이벤트로 처리하시겠습니까?`,
      cancelText: '아니오',
      okText: '네',
      onOk: createOrderEvent,
      maskClosable: true,
    });
  };

  return (
    <Modal
      okText={'적용'}
      onCancel={onClose}
      title={'이벤트처리'}
      onOk={handleOk}
    >
      <div>이벤트</div>
      <EventSelect
        marketId={marketId}
        onChange={(value) => setEventId(value)}
      />
    </Modal>
  );
}
