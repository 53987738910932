import { Typography } from 'antd';
import dayjs from 'dayjs';

import { AdminSettlementSheetQuery } from '../../../graphql/settlementSheet.generated';

import Block from './Block';

function Card({
  label,
  value,
  border = true,
}: {
  label: string;
  value: string;
  border?: boolean;
}) {
  return (
    <div
      className={`flex-1 border-x-0 border-y-0  border-solid border-r-gray-200 px-2 ${
        border ? 'border-r-[1px]' : ''
      }`}
    >
      <Typography.Title level={5} className='m-0'>
        {label}
      </Typography.Title>
      <Typography.Text>{value}원</Typography.Text>
    </div>
  );
}

function Summary({
  settlementSheet,
}: {
  settlementSheet: AdminSettlementSheetQuery['adminSettlementSheet'];
}) {
  const settlementSheetType = settlementSheet.settlementSheetType;
  const expectedSettlementAt = settlementSheet.expectedSettlementAt
    ? dayjs(settlementSheet.expectedSettlementAt).format('YYYY.MM.DD')
    : '-';
  const salesAmount = settlementSheet.salesAmount.toLocaleString() || '-';
  const settlementAmount =
    settlementSheet.settlementAmount.toLocaleString() || '-';
  const nonSalesSettlementAmount =
    settlementSheet.nonSalesSettlementAmount?.toLocaleString() || '-';
  const paymentFee = settlementSheet.paymentFee.toLocaleString() || '-';
  const withholdingTax = settlementSheet.withholdingTax
    ? settlementSheet.withholdingTax.toLocaleString()
    : '-';
  const finalSettlementAmount =
    settlementSheet.finalSettlementAmount.toLocaleString();
  // 업체정산서만 수정이 가능하여 최종정산금액은 adjustmentAmount
  // adjustAmount 가 없을 경우에는 finalSettlementAmount 가 최종정산금액이다.
  const adjustmentAmount =
    typeof settlementSheet.adjustmentAmount === 'number'
      ? settlementSheet.adjustmentAmount.toLocaleString()
      : finalSettlementAmount;

  return (
    <Block>
      {settlementSheetType === 'MARKET' && (
        <div className='flex justify-end'>
          <Typography.Title level={5} className='m-0'>
            정산예정일: {expectedSettlementAt}
          </Typography.Title>
        </div>
      )}
      <div className='mt-2 flex gap-[1px] border-x-0 border-y-2 border-solid py-6'>
        <Card label='매출' value={salesAmount} />
        <Card label='판매정산' value={settlementAmount} />
        <Card label='판매 외 정산' value={nonSalesSettlementAmount} />
        {settlementSheetType === 'MARKET' && (
          <>
            <Card label='결제수수료' value={paymentFee} />
            <Card label='원천징수세' value={withholdingTax} />
            <Card
              label='정산예정금액'
              value={finalSettlementAmount}
              border={false}
            />
          </>
        )}
        {settlementSheetType === 'VENDOR' && (
          <>
            <Card label='정산예정금액' value={finalSettlementAmount} />
            <Card
              label='최종정산금액'
              value={adjustmentAmount}
              border={false}
            />
          </>
        )}
      </div>
    </Block>
  );
}

export default Summary;
