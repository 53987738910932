import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import CheckboxField from '../../../common/components/Fields/CheckboxField';
import { CreateClaimFormValues } from '../type';

function WiredOwnDeliveryFaultCheckboxField() {
  const { control, watch, setValue } = useFormContext<CreateClaimFormValues>();

  const isBuyerTarget = watch('target') === 'BUYER';

  useEffect(() => {
    if (isBuyerTarget) {
      setValue('isWiredOwnDeliveryFault', false);
    }
  }, [isBuyerTarget]);

  return (
    <CheckboxField
      name={'isWiredOwnDeliveryFault'}
      control={control}
      className={'mt-2'}
      disabled={isBuyerTarget}
    >
      와이어드 자체물류 귀책
    </CheckboxField>
  );
}

export default WiredOwnDeliveryFaultCheckboxField;
