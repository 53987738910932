import * as Types from '../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductsInSelectFieldQueryVariables = Types.Exact<{
  filter: Types.ProductFilterQuery;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type ProductsInSelectFieldQuery = { __typename?: 'Query', adminProducts?: { __typename?: 'ProductPageGraphqlType', edges: Array<{ __typename?: 'PaginatedProductGraphqlTypeEdge', node: { __typename?: 'ProductGraphqlType', id: number, name: string } }> } | null };


export const ProductsInSelectFieldDocument = gql`
    query ProductsInSelectField($filter: ProductFilterQuery!, $pagination: Pagination) {
  adminProducts(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProductsInSelectFieldQuery__
 *
 * To run a query within a React component, call `useProductsInSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsInSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsInSelectFieldQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProductsInSelectFieldQuery(baseOptions: Apollo.QueryHookOptions<ProductsInSelectFieldQuery, ProductsInSelectFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsInSelectFieldQuery, ProductsInSelectFieldQueryVariables>(ProductsInSelectFieldDocument, options);
      }
export function useProductsInSelectFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsInSelectFieldQuery, ProductsInSelectFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsInSelectFieldQuery, ProductsInSelectFieldQueryVariables>(ProductsInSelectFieldDocument, options);
        }
export type ProductsInSelectFieldQueryHookResult = ReturnType<typeof useProductsInSelectFieldQuery>;
export type ProductsInSelectFieldLazyQueryHookResult = ReturnType<typeof useProductsInSelectFieldLazyQuery>;
export type ProductsInSelectFieldQueryResult = Apollo.QueryResult<ProductsInSelectFieldQuery, ProductsInSelectFieldQueryVariables>;