import { InputRef } from 'antd';
import { forwardRef } from 'react';

import { formatNumber, parseNumber } from '../../utils/number';

import TextInput, { TextInputProps } from './TextInput';

export type TextNumberInputProps = Omit<TextInputProps, 'value'> & {
  value?: string;
  format?: string;
  formatStrict?: boolean;
};

const TextNumberInput = forwardRef<InputRef, TextNumberInputProps>(
  ({ format, formatStrict, value, onChange, ...props }, ref) => {
    return (
      <TextInput
        ref={ref}
        onChange={(e) => {
          e.target.value = parseNumber(
            formatNumber(e.target.value, format, formatStrict)
          );
          onChange?.(e);
        }}
        value={formatNumber(value || '', format, formatStrict)}
        {...props}
      />
    );
  }
);

export default TextNumberInput;
