import { Tag, Typography } from 'antd';
import { useFormContext, useWatch } from 'react-hook-form';

import { ProductGroupFormValues } from '../types';

type ProductListItemLabelProps = { index: number };

const ProductListItemLabel = ({ index }: ProductListItemLabelProps) => {
  const { control } = useFormContext<ProductGroupFormValues>();
  const name = useWatch({ control, name: `products.${index}.name` });
  const isPrimary = useWatch({ control, name: `products.${index}.isPrimary` });

  return (
    <div className='flex items-center gap-2'>
      <Typography.Title level={5} className='mb-[-2px]'>
        {name}
      </Typography.Title>
      {isPrimary && <Tag color={'blue'}>대표</Tag>}
    </div>
  );
};

export default ProductListItemLabel;
