import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { SortableItemContents } from './ContentsSortableItemContents';

type SortableItemProps = {
  id: string;
  url: string;
  dragging: boolean;
  disabled?: boolean;
};

export function SortableItem({
  id,
  url,
  dragging,
  disabled,
}: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({
    id,
    transition: {
      duration: 150, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
    disabled,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: dragging ? '0.5' : '',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className='w-full max-w-[calc(25%-6px)]'
    >
      <SortableItemContents
        sortableItem
        ref={setActivatorNodeRef}
        listeners={listeners}
        url={url}
      />
    </div>
  );
}
