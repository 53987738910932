import { App, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { uniqBy } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import useExcelDownload from '../../../common/hooks/useExcelDownload';
import { sheetToJson } from '../../../common/utils/excel';
import { PatchPromotionReleaseDeliveryInfoInputType } from '../../../schema/types';
import {
  AdminCountPromotionStatusesDocument,
  AdminPromotionReleasesDocument,
  useAdminBulkPatchPromotionReleaseDeliveryInfoMutation,
} from '../graphql/promotionRelease.generated';

import usePromotionReleaseTableFilter from './usePromotionReleaseTableFilter';

type ExcelDataType = {
  출고코드: string;
  송장번호: string;
  택배사코드: string;
};

function useExcelHandler() {
  const { modal, message } = App.useApp();
  const filter = usePromotionReleaseTableFilter();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { excelDownload } = useExcelDownload();
  const [bulkPatchDeliveryInfo] =
    useAdminBulkPatchPromotionReleaseDeliveryInfoMutation();

  const handleExcelUpload = useCallback(async () => {
    const file = fileList[0].originFileObj as RcFile;
    const excelData = (await sheetToJson(file, {
      removeCol: { start: 'E', end: 'C' },
      deleteRowNumber: 2,
    })) as ExcelDataType[];
    const isDataCorrect = excelData.every(
      (data) => data.송장번호 && data.출고코드 && data.택배사코드
    );
    if (!isDataCorrect) {
      void message.error('잘못된 업로드 양식입니다.');
      return;
    }

    const bulkPatchData: PatchPromotionReleaseDeliveryInfoInputType[] =
      excelData.map((data) => ({
        deliveryCompanyCode: data.택배사코드,
        releaseCode: data.출고코드,
        trackingNumber: data.송장번호,
      }));
    await bulkPatchDeliveryInfo({
      variables: { data: uniqBy(bulkPatchData, 'releaseCode') },
      refetchQueries: [
        AdminPromotionReleasesDocument,
        AdminCountPromotionStatusesDocument,
      ],
      onCompleted({ adminBulkPatchPromotionReleaseDeliveryInfo }) {
        const { successCaseCount, failCases } =
          adminBulkPatchPromotionReleaseDeliveryInfo;
        const successMessage = `${successCaseCount}건 성공`;
        const failMessage = failCases.length
          ? ` / ${failCases.length}건 실패.`
          : '';
        if (failMessage) {
          void modal.error({
            title: successMessage + failMessage,
            content: (
              <>
                {failCases.map(({ reason, releaseCode }) => (
                  <div>
                    {releaseCode}: {reason}
                  </div>
                ))}
              </>
            ),
          });
        } else {
          void message.success(successMessage);
        }
      },
      onError() {
        void message.error(
          '알 수 없는 에러가 발생했습니다. 개발팀에 문의해주세요.'
        );
      },
    });
  }, [bulkPatchDeliveryInfo, fileList, message, modal]);

  const handleExcelDownload = useCallback(async () => {
    const queryString = JSON.stringify({ filter });

    await excelDownload({
      url: 'admin/promotion-releases/download',
      fileName: '출고요청리스트',
      config: { params: { q: queryString } },
    });
  }, [filter, excelDownload]);

  return useMemo(
    () => ({ fileList, setFileList, handleExcelUpload, handleExcelDownload }),
    [fileList, setFileList, handleExcelUpload, handleExcelDownload]
  );
}

export default useExcelHandler;
