import { Typography } from 'antd';
import { Key } from 'react';

import SummaryCard from '../../../common/components/SummaryCard';
import SummaryLinkCard from '../../../common/components/SummaryLinkCard';

export type SummaryItem = {
  key: any;
  label: string;
  count: number;
  onClick: () => void;
};

export type SummaryLinkItem = {
  key: any;
  label: string;
  count: number;
  onClick: () => void;
  buttonText: string;
};

export type SummaryProps = SummaryDefaultProps | SummaryLinkProps;

type SummaryDefaultProps = {
  label: string;
  activeKey?: string;
  items: SummaryItem[];
  type?: 'default';
};

type SummaryLinkProps = {
  label: string;
  items: SummaryLinkItem[];
  type: 'link';
};

function Summary(props: SummaryProps) {
  // 2개까지 고정
  return (
    <div>
      <Typography.Title level={5}>{props.label}</Typography.Title>
      <div className='flex gap-4'>
        {props.items?.map((item) => {
          if (props.type === 'link') {
            return (
              <SummaryLinkCard
                key={item.key as Key}
                label={item.label}
                count={item.count}
                onClick={item.onClick}
                buttonText={(item as SummaryLinkItem).buttonText}
                className={props.items.length > 2 ? 'flex-1' : 'w-[350px]'}
              />
            );
          } else {
            return (
              <SummaryCard
                className={props.items.length > 2 ? 'flex-1' : 'w-[264px]'}
                key={item.key as Key}
                label={item.label}
                count={item.count}
                active={props.activeKey === item.key}
                onClick={item.onClick}
                color='blue'
              />
            );
          }
        })}
      </div>
    </div>
  );
}

export default Summary;
