import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import VendorSelect from '../../../../common/components/Fields/VendorSelect';
import { useAdminVendorInSettlementListLazyQuery } from '../../graphql/vendor.generated';

export type SelectFieldProps = SelectProps<number> & {
  initialLoad?: boolean;
};

const SettlementVendorSelect = forwardRef<BaseSelectRef, SelectFieldProps>(
  function ({ initialLoad, ...props }, ref) {
    const [initialOption, setInitialOption] = useState<
      null | undefined | { value: number; label: string }
    >(null);

    const [fetch] = useAdminVendorInSettlementListLazyQuery();
    const fetchVendor = useCallback(async () => {
      if (!props.value) {
        setInitialOption(undefined);
        return;
      }

      const { data } = await fetch({
        variables: {
          vendorId: props.value,
        },
      });

      if (data?.adminVendorWithBrands) {
        setInitialOption({
          value: data.adminVendorWithBrands.id,
          label: data.adminVendorWithBrands.name,
        });
      }
    }, [fetch, props.value]);

    useEffect(() => {
      fetchVendor();
    }, []);

    if (initialOption === null) return <Select className={props.className} />;

    return (
      <VendorSelect
        ref={ref}
        initialLoad={initialLoad}
        className={'w-full'}
        initialOption={initialOption}
        {...props}
      />
    );
  }
);

export default SettlementVendorSelect;
