import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminSellerSettlementsQueryVariables = Types.Exact<{
  adminSellerId: Types.Scalars['Int']['input'];
}>;


export type AdminSellerSettlementsQuery = { __typename?: 'Query', adminSeller: { __typename?: 'SellerGraphqlType', settlementInfos: Array<{ __typename?: 'SellerSettlementInfoGraphqlType', id: number, bank?: Types.EBankCode | null, type: Types.ESellerSettlementType, sellerName?: string | null, companyName?: string | null, accountNumber?: string | null, accountHolderName?: string | null, isDefault: boolean }> } };

export type SellerOptionsQueryVariables = Types.Exact<{
  sellersInput: Types.SellersGraphqlInputType;
}>;


export type SellerOptionsQuery = { __typename?: 'Query', adminSellers: { __typename?: 'SellersPayload', sellers: Array<{ __typename?: 'SellerGraphqlType', id: number, name: string, kemiUserId?: string | null, settlementInfos: Array<{ __typename?: 'SellerSettlementInfoGraphqlType', id: number, type: Types.ESellerSettlementType, sellerName?: string | null, companyName?: string | null, isDefault: boolean, bank?: Types.EBankCode | null, accountNumber?: string | null, accountHolderName?: string | null }> }> } };


export const AdminSellerSettlementsDocument = gql`
    query AdminSellerSettlements($adminSellerId: Int!) {
  adminSeller(id: $adminSellerId) {
    settlementInfos {
      id
      bank
      type
      sellerName
      companyName
      accountNumber
      accountHolderName
      isDefault
    }
  }
}
    `;

/**
 * __useAdminSellerSettlementsQuery__
 *
 * To run a query within a React component, call `useAdminSellerSettlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSellerSettlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSellerSettlementsQuery({
 *   variables: {
 *      adminSellerId: // value for 'adminSellerId'
 *   },
 * });
 */
export function useAdminSellerSettlementsQuery(baseOptions: Apollo.QueryHookOptions<AdminSellerSettlementsQuery, AdminSellerSettlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSellerSettlementsQuery, AdminSellerSettlementsQueryVariables>(AdminSellerSettlementsDocument, options);
      }
export function useAdminSellerSettlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSellerSettlementsQuery, AdminSellerSettlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSellerSettlementsQuery, AdminSellerSettlementsQueryVariables>(AdminSellerSettlementsDocument, options);
        }
export type AdminSellerSettlementsQueryHookResult = ReturnType<typeof useAdminSellerSettlementsQuery>;
export type AdminSellerSettlementsLazyQueryHookResult = ReturnType<typeof useAdminSellerSettlementsLazyQuery>;
export type AdminSellerSettlementsQueryResult = Apollo.QueryResult<AdminSellerSettlementsQuery, AdminSellerSettlementsQueryVariables>;
export const SellerOptionsDocument = gql`
    query SellerOptions($sellersInput: SellersGraphqlInputType!) {
  adminSellers(sellersInput: $sellersInput) {
    sellers {
      id
      name
      kemiUserId
      settlementInfos {
        id
        type
        sellerName
        companyName
        isDefault
        bank
        accountNumber
        accountHolderName
      }
    }
  }
}
    `;

/**
 * __useSellerOptionsQuery__
 *
 * To run a query within a React component, call `useSellerOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerOptionsQuery({
 *   variables: {
 *      sellersInput: // value for 'sellersInput'
 *   },
 * });
 */
export function useSellerOptionsQuery(baseOptions: Apollo.QueryHookOptions<SellerOptionsQuery, SellerOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerOptionsQuery, SellerOptionsQueryVariables>(SellerOptionsDocument, options);
      }
export function useSellerOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerOptionsQuery, SellerOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerOptionsQuery, SellerOptionsQueryVariables>(SellerOptionsDocument, options);
        }
export type SellerOptionsQueryHookResult = ReturnType<typeof useSellerOptionsQuery>;
export type SellerOptionsLazyQueryHookResult = ReturnType<typeof useSellerOptionsLazyQuery>;
export type SellerOptionsQueryResult = Apollo.QueryResult<SellerOptionsQuery, SellerOptionsQueryVariables>;