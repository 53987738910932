import { Button } from 'antd';
import { FormProvider } from 'react-hook-form';

import useCreatePromotionEvent from '../../hooks/useCreatePromotionEvent';

import DetailInformation from './DetailInformation';
import ExtraInformation from './ExtraInformation';
import ReceiverInformation from './ReceiverInformation';

function PromotionEventFormProvider({ onClose }: { onClose: () => void }) {
  const { methods, onSubmit } = useCreatePromotionEvent({ onClose });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ReceiverInformation />
        <DetailInformation />
        <ExtraInformation />
        <div className='mt-4 flex justify-end gap-2'>
          <Button onClick={onClose}>취소</Button>
          <Button htmlType='submit' type='primary'>
            출고요청
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}

export default PromotionEventFormProvider;
