import { Skeleton, Typography } from 'antd';
import { useMemo } from 'react';

import SummaryCard from '../../../common/components/SummaryCard';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { ESalesChannelType } from '../../../schema/types';
import { ORDER_COUNT_STATUS } from '../const';
import { useAdminCountOrderStatusesQuery } from '../graphql/orders.generated';
import {
  CountOrderStatus,
  OrderListSearchParams,
  SummaryListType,
} from '../types';

export default function Summary() {
  const { searchParams, replaceSearchParams } =
    useRouteSearchParams<OrderListSearchParams>();

  const { summaryStatus } = searchParams;
  const salesChannelTypes: ESalesChannelType[] = useMemo(() => {
    if (!searchParams.salesChannelType)
      return ['KEMI', 'PB_SHOP', 'SELLER_SHOP'];
    return [searchParams.salesChannelType];
  }, [searchParams.salesChannelType]);

  const { data } = useAdminCountOrderStatusesQuery({
    variables: {
      filter: {
        salesChannelTypes,
      },
    },
  });

  const orderCountsOfStatus = useMemo(() => {
    return data?.adminCountOrderStatuses.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.status]: cur.count,
      }),
      {} as { [k in SummaryListType]: number }
    );
  }, [data]);

  const handleCardClick = (status: CountOrderStatus) => {
    if (summaryStatus === status) {
      replaceSearchParams({
        salesChannelType: searchParams.salesChannelType,
      });

      return;
    }

    replaceSearchParams({
      salesChannelType: searchParams.salesChannelType,
      summaryStatus: status,
    });
  };

  return (
    <>
      <div className='grid grid-cols-[1fr_1fr_1fr] gap-x-4 pb-5'>
        <div className='col-span-4 col-start-1'>
          <Typography.Title level={5}>주문현황</Typography.Title>
        </div>
        {/* 신규주문마켓 추가시 주석제거 */}
        {/* <div>
          <Typography.Title level={5}>신규주문 마켓</Typography.Title>
        </div> */}
        {ORDER_COUNT_STATUS.map(({ label, value }) =>
          orderCountsOfStatus ? (
            <SummaryCard
              key={value}
              label={label}
              count={orderCountsOfStatus[value]}
              active={summaryStatus === value}
              color='blue'
              onClick={() => handleCardClick(value)}
            />
          ) : (
            <Skeleton.Button key={value} className='h-16 w-full' active />
          )
        )}
        {/* 신규주문마켓 추가시 주석제거 */}
        {/* <Divider type={'vertical'} className='col-auto h-full' />
        {orderCountsOfStatus ? (
          <SummaryCard
            key={'NEW_MARKET'}
            label={'진행마켓'}
            count={orderCountsOfStatus['NEW_MARKET']}
            color='green'
          />
        ) : (
          <Skeleton.Button key={'NEW_MARKET'} className='h-16 w-full' active />
        )} */}
      </div>
    </>
  );
}
