import { Divider, Table } from 'antd';

import { AdminSettlementSheetQuery } from '../../../graphql/settlementSheet.generated';
import { SettlementSheetSalesInfoTableDataType } from '../../../types';

import Block from './Block';

function SellingInfo({
  settlementSheetType,
  productSalesRecords,
}: {
  settlementSheetType?: AdminSettlementSheetQuery['adminSettlementSheet']['settlementSheetType'];
  productSalesRecords: AdminSettlementSheetQuery['adminSettlementSheet']['productSalesRecords'];
}) {
  const dataSource: SettlementSheetSalesInfoTableDataType[] =
    productSalesRecords?.map((record, i) => ({
      key: i,
      productName: record.productName,
      jointPurchasePrice: record.jointPurchasePrice,
      sellerMargin: record.sellerMargin || 0,
      quantity: record.quantity,
      supplyPrice: record.supplyPrice || 0,
      salesAmount: record.salesAmount || 0,
      settlementAmount: record.settlementAmount || 0,
      totalSupplyPrice: record.totalSupplyPrice || 0,
    })) || [];
  if (dataSource.length === 0) return null;
  return (
    <Block>
      <Divider orientation='left' className='mt-0'>
        판매정보
      </Divider>
      <Table
        size='small'
        tableLayout='fixed'
        dataSource={dataSource}
        pagination={false}
        summary={(dataSource) => {
          const total = dataSource.reduce(
            (acc, cur) => ({
              quantity: acc.quantity + cur.quantity,
              salesAmount: acc.salesAmount + cur.salesAmount,
              settlementAmount: acc.settlementAmount + cur.settlementAmount,
              totalSupplyPrice: acc.totalSupplyPrice + cur.totalSupplyPrice,
            }),
            {
              quantity: 0,
              salesAmount: 0,
              settlementAmount: 0,
              totalSupplyPrice: 0,
            }
          );
          return (
            <Table.Summary>
              <Table.Summary.Row className=' bg-[#fafafa] font-semibold'>
                <Table.Summary.Cell index={0} colSpan={3}>
                  총계
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {total.quantity.toLocaleString()}개
                </Table.Summary.Cell>
                {settlementSheetType === 'MARKET' && (
                  <>
                    <Table.Summary.Cell index={2}>
                      {total.salesAmount.toLocaleString()}원
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      {total.settlementAmount.toLocaleString()}원
                    </Table.Summary.Cell>
                  </>
                )}
                {settlementSheetType === 'VENDOR' && (
                  <Table.Summary.Cell index={2}>
                    {total.totalSupplyPrice.toLocaleString()}원
                  </Table.Summary.Cell>
                )}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      >
        <Table.Column<SettlementSheetSalesInfoTableDataType>
          title='품목명'
          dataIndex={'productName'}
        />
        <Table.Column<SettlementSheetSalesInfoTableDataType>
          width={160}
          title='공동구매가'
          dataIndex={'jointPurchasePrice'}
          render={(_, record) => record.jointPurchasePrice.toLocaleString()}
        />
        {settlementSheetType === 'MARKET' && (
          <Table.Column<SettlementSheetSalesInfoTableDataType>
            width={160}
            title='개당마진'
            dataIndex={'sellerMargin'}
            render={(_, record) => record.sellerMargin.toLocaleString()}
          />
        )}
        {settlementSheetType === 'VENDOR' && (
          <Table.Column<SettlementSheetSalesInfoTableDataType>
            width={160}
            title='개당 공급가'
            dataIndex={'supplyPrice'}
            render={(_, record) => record.supplyPrice.toLocaleString()}
          />
        )}
        <Table.Column<SettlementSheetSalesInfoTableDataType>
          width={120}
          title='수량'
          dataIndex={'quantity'}
          render={(_, record) => `${record.quantity.toLocaleString()}개`}
        />
        {settlementSheetType === 'MARKET' && (
          <>
            <Table.Column<SettlementSheetSalesInfoTableDataType>
              width={160}
              title='매출'
              dataIndex={'salesAmount'}
              render={(_, record) => record.salesAmount.toLocaleString()}
            />
            <Table.Column<SettlementSheetSalesInfoTableDataType>
              width={160}
              title='정산예정금액'
              dataIndex={'settlementAmount'}
              render={(_, record) => record.settlementAmount.toLocaleString()}
            />
          </>
        )}
        {settlementSheetType === 'VENDOR' && (
          <Table.Column<SettlementSheetSalesInfoTableDataType>
            width={160}
            title='총 공급가'
            dataIndex={'totalSupplyPrice'}
            render={(_, record) => record.totalSupplyPrice.toLocaleString()}
          />
        )}
      </Table>
    </Block>
  );
}

export default SellingInfo;
