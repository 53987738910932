import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Table, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { sortBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { useMeQuery } from '../../../../common/graphql/me.generated';
import { usePermissions } from '../../../../common/hooks/usePermissions';
import { EMarketChecklistType } from '../../../../schema/types';
import {
  MARKET_CHECKLIST_TYPES,
  MAX_CHECKLIST_MANAGERS_LENGTH,
} from '../../const';
import {
  AdminMarketPreviewDocument,
  AdminMarketPreviewQuery,
  useAdminDeleteMarketChecklistManagerMutation,
  useAdminUpdateMarketChecklistMutation,
  useAdminUpsertMarketChecklistMutation,
} from '../../graphql/market.generated';
import EditableMarketCheckListMemoCell from '../EditableMarketCheckListMemoCell';

type MarketChecklistTableData = {
  key: number;
  id?: number;
  type?: EMarketChecklistType;
  typeLabel?: string;
  comment?: string;
  shouldCheck: boolean;
  checkManagers: {
    createdAt: string;
    id: number;
    name: string;
    managerId: number;
    checklistId: number;
  }[];
};

function ChecklistTable({
  market,
}: {
  market: AdminMarketPreviewQuery['adminMarket'];
}) {
  const { message } = App.useApp();

  const { data: me } = useMeQuery();
  const { hasPermission } = usePermissions('MARKET_EDIT');

  const [upsertChecklist, { loading: upsertLoading }] =
    useAdminUpsertMarketChecklistMutation();
  const [updateChecklistComment, { loading: commentMutationLoading }] =
    useAdminUpdateMarketChecklistMutation();
  const [deleteChecklist, { loading: deleteLoading }] =
    useAdminDeleteMarketChecklistManagerMutation();

  const meId = me?.adminMe.id;

  const upsertChecklistItem = useCallback(
    (values: { type: EMarketChecklistType; comment?: string }) => {
      if (!meId) {
        return;
      }

      upsertChecklist({
        variables: {
          upsertMarketChecklistInput: {
            ...values,
            managerId: meId,
            marketId: market.id,
          },
        },
        onError(e) {
          message.error(
            e.message || '체크리스트 정보를 업데이트하지 못했습니다.'
          );
        },
        onCompleted() {
          message.success('체크리스트 정보가 업데이트되었습니다.');
        },
        refetchQueries: [AdminMarketPreviewDocument],
      });
    },
    [market.id, meId, message, upsertChecklist]
  );

  const handleCheckButtonClick = useCallback(
    (type: EMarketChecklistType) => {
      upsertChecklistItem({
        type,
      });
    },
    [upsertChecklistItem]
  );

  const handleUncheckButtonClick = useCallback(
    (id: number) => {
      deleteChecklist({
        variables: { adminDeleteMarketChecklistManagerId: id },
        onError(e) {
          message.error(
            e.message || '체크리스트 정보를 업데이트하지 못했습니다.'
          );
        },
        onCompleted() {
          message.success('체크리스트 정보가 업데이트되었습니다');
        },
        refetchQueries: [AdminMarketPreviewDocument],
      });
    },
    [deleteChecklist, message]
  );

  const handleCommentChange = useCallback(
    (type: EMarketChecklistType, comment: string, id?: number) => {
      if (!id) {
        upsertChecklistItem({
          type,
          comment,
        });
        return;
      }

      updateChecklistComment({
        variables: {
          updateMarketChecklistInput: {
            data: {
              comment,
            },
            id,
          },
        },
        onError(e) {
          message.error(e.message || '메모를 업데이트하지 못했습니다.');
        },
        onCompleted() {
          message.success('메모가 업데이트되었습니다.');
        },
        refetchQueries: [AdminMarketPreviewDocument],
      });
    },
    [message, updateChecklistComment, upsertChecklistItem]
  );

  const dataSource = useMemo<MarketChecklistTableData[]>(() => {
    return MARKET_CHECKLIST_TYPES.map((type, i) => {
      const checklist = market.checklists?.find(
        (check) => check.type === type.value
      );

      const isMeCheckable =
        (hasPermission &&
          checklist?.checklistManagers?.every(
            (manager) => manager.manager.id !== meId
          )) ||
        true;

      return {
        key: i,
        id: checklist?.id,
        type: type.value,
        typeLabel: type.label,
        comment: checklist?.comment || '',
        shouldCheck:
          (checklist?.checklistManagers?.length || 0) <
            MAX_CHECKLIST_MANAGERS_LENGTH && isMeCheckable,
        checkManagers: sortBy(checklist?.checklistManagers || [], 'id').map(
          (manager) => ({
            createdAt: manager.createdAt,
            id: manager.id,
            name: manager.manager.name,
            managerId: manager.manager.id,
            checklistId: manager.marketChecklistId,
          })
        ),
      };
    });
  }, [market.checklists, meId, hasPermission]);

  return (
    <Table
      scroll={{ x: 760 }}
      pagination={false}
      loading={upsertLoading || commentMutationLoading || deleteLoading}
      columns={[
        {
          dataIndex: 'typeLabel',
          key: 'typeLabel',
          title: '리스트',
          width: 140,
        },
        {
          dataIndex: 'checkManagers',
          key: 'checkManagers',
          title: '확인',
          width: 180,
          render(_, record) {
            const hasMe = record.checkManagers.some(
              (manager) => manager.managerId === meId
            );
            return (
              <div className={'flex flex-wrap gap-2'}>
                {record.checkManagers.map((item) => {
                  return (
                    <Tooltip
                      key={item.managerId}
                      title={dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
                      className={'m-0'}
                    >
                      <Tag
                        icon={<CheckCircleOutlined />}
                        color='success'
                        onClick={
                          hasPermission
                            ? () => handleUncheckButtonClick(item.id)
                            : undefined
                        }
                        className={'hover:cursor-pointer hover:border-gray-400'}
                      >
                        {item.name || ''}
                      </Tag>
                    </Tooltip>
                  );
                })}
                {!hasMe && record.shouldCheck && (
                  <Tag
                    icon={<PlusOutlined />}
                    color='default'
                    className={`border-dashed bg-transparent ${
                      hasPermission
                        ? 'hover:cursor-pointer hover:border-gray-400'
                        : 'text-gray-300 hover:cursor-not-allowed'
                    }`}
                    onClick={() =>
                      hasPermission &&
                      record.type &&
                      handleCheckButtonClick(record.type)
                    }
                  >
                    확인
                  </Tag>
                )}
              </div>
            );
          },
        },
        {
          dataIndex: 'comment',
          key: 'comment',
          title: '메모',
          width: 440,
          fixed: true,
          render(_, record) {
            const id = record.id;
            const type = record.type;

            if (!type) {
              return null;
            }

            return (
              <EditableMarketCheckListMemoCell
                editable={hasPermission}
                onChange={(v) => {
                  if (record.comment !== v) {
                    handleCommentChange(type, v, id);
                  }
                }}
                initialValue={record.comment || ''}
              />
            );
          },
        },
      ]}
      dataSource={dataSource}
    />
  );
}

export default ChecklistTable;
