import { UploadProps } from 'antd';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { EUploadResourcesPurpose } from '../../../schema/types';
import FileUploader, { UploadButton } from '../FileUploader';
import { TextInputProps } from '../Input/TextInput';

import FieldWithLabel from './FieldWithLabel';
import TextNumberField from './TextNumberField';
import { FieldProps } from './types';

type ResidentRegistrationFieldProps<TFieldValues extends FieldValues> = {
  prevInputProps: TextInputProps & {
    name: Path<TFieldValues>;
  };
  nextInputProps: TextInputProps & {
    name: Path<TFieldValues>;
  };
  uploadProps: Omit<UploadProps, 'onChange' | 'value'> & {
    id: string;
    name: Path<TFieldValues>;
    purpose: EUploadResourcesPurpose;
  };
} & FieldProps & {
    control: Control<TFieldValues>;
    selectPosition?: 'before' | 'after';
  };

function ResidentRegistrationField<TFieldValues extends FieldValues>({
  label,
  required,
  prevInputProps,
  nextInputProps,
  uploadProps,
  control,
}: ResidentRegistrationFieldProps<TFieldValues>) {
  return (
    <div>
      <FieldWithLabel label={label || '주민등록번호'} required={required}>
        <div className={'flex items-start gap-sm'}>
          <TextNumberField
            control={control}
            format={'######'}
            {...prevInputProps}
          />
          <span className={'inline-flex h-xxl items-center'}>-</span>
          <TextNumberField
            control={control}
            format={'#######'}
            {...nextInputProps}
          />

          <Controller
            render={({ field }) => {
              return (
                <UploadButton
                  id={uploadProps.id}
                  label={'주민등록증'}
                  disabled={!!field.value || uploadProps.disabled}
                />
              );
            }}
            name={uploadProps.name}
          />
        </div>
      </FieldWithLabel>
      <Controller
        render={({ field }) => {
          const url = field.value ? [field.value] : [];

          return (
            <FileUploader
              multiple={false}
              maxCount={1}
              value={url}
              onChange={([url]) => field.onChange(url || null)}
              {...uploadProps}
            />
          );
        }}
        name={uploadProps.name}
      />
    </div>
  );
}

export default ResidentRegistrationField;
