import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductGroupsByBrandIdQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductGroupFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type ProductGroupsByBrandIdQuery = { __typename?: 'Query', adminProductGroups?: { __typename?: 'ProductGroupPageGraphqlType', edges: Array<{ __typename?: 'PaginatedProductGroupGraphqlTypeEdge', node: { __typename?: 'ProductGroupGraphqlType', id: number, name: string, deliveryCompanyCode?: string | null, deliveryCompanyName?: string | null, releaseType: Types.EProductGroupReleaseType, releaseTime?: string | null } }> } | null };

export type ProductGroupsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductGroupFilterQuery>;
  order?: Types.InputMaybe<Types.Scalars['Order']['input']>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type ProductGroupsQuery = { __typename?: 'Query', adminProductGroups?: { __typename?: 'ProductGroupPageGraphqlType', count: number, totalCount: number, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, startCursor?: string | null } | null, edges: Array<{ __typename?: 'PaginatedProductGroupGraphqlTypeEdge', node: { __typename?: 'ProductGroupGraphqlType', name: string, status: Types.EProductGroupStatus, id: number, manager: { __typename?: 'ManagerGraphqlType', name: string }, primaryMultimedia: { __typename?: 'ProductGroupMultimediaGraphqlType', url: string }, primaryProduct: { __typename?: 'ProductGraphqlType', jointPurchasePrice: number, freelanceInHouseMarginRate: number, businessSellerMarginRate: number, freelanceInHouseMargin: number, businessSellerMargin: number }, category: { __typename?: 'ProductGroupCategoryGraphqlType', categoryName: string, upperCategoryName: string, uppermostCategoryName: string, categoryId: number, upperCategoryId: number, uppermostCategoryId: number }, vendor: { __typename?: 'VendorGraphqlType', name: string }, brand: { __typename?: 'BrandGraphqlType', name: string }, markets?: Array<{ __typename?: 'MarketGraphqlType', endedAt?: string | null, startedAt: string, name: string }> | null } }> } | null };

export type TemporaryProductsTotalCountQueryVariables = Types.Exact<{
  input: Types.TemporaryProductsGraphqlInput;
}>;


export type TemporaryProductsTotalCountQuery = { __typename?: 'Query', adminTemporaryProducts: { __typename?: 'TemporaryProductPageGraphqlType', totalCount: number } };

export type TemporaryProductsQueryVariables = Types.Exact<{
  input: Types.TemporaryProductsGraphqlInput;
}>;


export type TemporaryProductsQuery = { __typename?: 'Query', adminTemporaryProducts: { __typename?: 'TemporaryProductPageGraphqlType', totalCount: number, count: number, edges: Array<{ __typename?: 'PaginatedTemporaryProductGraphqlTypeEdge', cursor: string, node: { __typename?: 'TemporaryProductGraphqlType', id: number, json: string, managerName: string, name: string, updatedAt: string } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage?: boolean | null, endCursor?: string | null, hasPreviousPage?: boolean | null, startCursor?: string | null } | null } };


export const ProductGroupsByBrandIdDocument = gql`
    query ProductGroupsByBrandId($filter: ProductGroupFilterQuery, $pagination: Pagination) {
  adminProductGroups(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
        deliveryCompanyCode
        deliveryCompanyName
        releaseType
        releaseTime
      }
    }
  }
}
    `;

/**
 * __useProductGroupsByBrandIdQuery__
 *
 * To run a query within a React component, call `useProductGroupsByBrandIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGroupsByBrandIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGroupsByBrandIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProductGroupsByBrandIdQuery(baseOptions?: Apollo.QueryHookOptions<ProductGroupsByBrandIdQuery, ProductGroupsByBrandIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupsByBrandIdQuery, ProductGroupsByBrandIdQueryVariables>(ProductGroupsByBrandIdDocument, options);
      }
export function useProductGroupsByBrandIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupsByBrandIdQuery, ProductGroupsByBrandIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupsByBrandIdQuery, ProductGroupsByBrandIdQueryVariables>(ProductGroupsByBrandIdDocument, options);
        }
export type ProductGroupsByBrandIdQueryHookResult = ReturnType<typeof useProductGroupsByBrandIdQuery>;
export type ProductGroupsByBrandIdLazyQueryHookResult = ReturnType<typeof useProductGroupsByBrandIdLazyQuery>;
export type ProductGroupsByBrandIdQueryResult = Apollo.QueryResult<ProductGroupsByBrandIdQuery, ProductGroupsByBrandIdQueryVariables>;
export const ProductGroupsDocument = gql`
    query ProductGroups($filter: ProductGroupFilterQuery, $order: Order, $pagination: Pagination) {
  adminProductGroups(filter: $filter, order: $order, pagination: $pagination) {
    count
    totalCount
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        name
        status
        manager {
          name
        }
        primaryMultimedia {
          url
        }
        primaryProduct {
          jointPurchasePrice
          freelanceInHouseMarginRate
          businessSellerMarginRate
          freelanceInHouseMargin
          businessSellerMargin
        }
        category {
          categoryName
          upperCategoryName
          uppermostCategoryName
          categoryId
          upperCategoryId
          uppermostCategoryId
        }
        id
        vendor {
          name
        }
        brand {
          name
        }
        markets {
          endedAt
          startedAt
          name
        }
      }
    }
  }
}
    `;

/**
 * __useProductGroupsQuery__
 *
 * To run a query within a React component, call `useProductGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProductGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ProductGroupsQuery, ProductGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupsQuery, ProductGroupsQueryVariables>(ProductGroupsDocument, options);
      }
export function useProductGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupsQuery, ProductGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupsQuery, ProductGroupsQueryVariables>(ProductGroupsDocument, options);
        }
export type ProductGroupsQueryHookResult = ReturnType<typeof useProductGroupsQuery>;
export type ProductGroupsLazyQueryHookResult = ReturnType<typeof useProductGroupsLazyQuery>;
export type ProductGroupsQueryResult = Apollo.QueryResult<ProductGroupsQuery, ProductGroupsQueryVariables>;
export const TemporaryProductsTotalCountDocument = gql`
    query TemporaryProductsTotalCount($input: TemporaryProductsGraphqlInput!) {
  adminTemporaryProducts(input: $input) {
    totalCount
  }
}
    `;

/**
 * __useTemporaryProductsTotalCountQuery__
 *
 * To run a query within a React component, call `useTemporaryProductsTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryProductsTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryProductsTotalCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTemporaryProductsTotalCountQuery(baseOptions: Apollo.QueryHookOptions<TemporaryProductsTotalCountQuery, TemporaryProductsTotalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemporaryProductsTotalCountQuery, TemporaryProductsTotalCountQueryVariables>(TemporaryProductsTotalCountDocument, options);
      }
export function useTemporaryProductsTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemporaryProductsTotalCountQuery, TemporaryProductsTotalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemporaryProductsTotalCountQuery, TemporaryProductsTotalCountQueryVariables>(TemporaryProductsTotalCountDocument, options);
        }
export type TemporaryProductsTotalCountQueryHookResult = ReturnType<typeof useTemporaryProductsTotalCountQuery>;
export type TemporaryProductsTotalCountLazyQueryHookResult = ReturnType<typeof useTemporaryProductsTotalCountLazyQuery>;
export type TemporaryProductsTotalCountQueryResult = Apollo.QueryResult<TemporaryProductsTotalCountQuery, TemporaryProductsTotalCountQueryVariables>;
export const TemporaryProductsDocument = gql`
    query TemporaryProducts($input: TemporaryProductsGraphqlInput!) {
  adminTemporaryProducts(input: $input) {
    totalCount
    count
    edges {
      cursor
      node {
        id
        json
        managerName
        name
        updatedAt
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useTemporaryProductsQuery__
 *
 * To run a query within a React component, call `useTemporaryProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemporaryProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemporaryProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTemporaryProductsQuery(baseOptions: Apollo.QueryHookOptions<TemporaryProductsQuery, TemporaryProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemporaryProductsQuery, TemporaryProductsQueryVariables>(TemporaryProductsDocument, options);
      }
export function useTemporaryProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemporaryProductsQuery, TemporaryProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemporaryProductsQuery, TemporaryProductsQueryVariables>(TemporaryProductsDocument, options);
        }
export type TemporaryProductsQueryHookResult = ReturnType<typeof useTemporaryProductsQuery>;
export type TemporaryProductsLazyQueryHookResult = ReturnType<typeof useTemporaryProductsLazyQuery>;
export type TemporaryProductsQueryResult = Apollo.QueryResult<TemporaryProductsQuery, TemporaryProductsQueryVariables>;