import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminMarketsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MarketPageQueryFilter>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  order?: Types.InputMaybe<Types.Scalars['Order']['input']>;
}>;


export type AdminMarketsQuery = { __typename?: 'Query', adminMarkets: { __typename?: 'AdminMarketPageGraphqlType', totalCount: number, edges: Array<{ __typename?: 'PaginatedMarketGraphqlTypeEdge', node: { __typename?: 'MarketGraphqlType', id: number, name: string, startedAt: string, endedAt?: string | null, status: Types.EMarketStatus, sellingChannel?: Types.ESellingChannel | null, expectedSales?: number | null, createdAt: string, expectedSettlementAt?: string | null, isSoldOut: boolean, comment?: string | null, updatedAt: string, etcSellingChannel?: string | null, seller?: { __typename?: 'SellerGraphqlType', id: number, name: string, grade?: number | null, links: Array<{ __typename?: 'SellerLinkGraphqlType', id: number, isDefault: boolean, url: string }> } | null, sellerSettlementInfo?: { __typename?: 'SellerSettlementInfoGraphqlType', id: number, type: Types.ESellerSettlementType } | null, productGroups: Array<{ __typename?: 'ProductGroupGraphqlType', name: string, id: number, manager: { __typename?: 'ManagerGraphqlType', id: number, name: string } }>, brand: { __typename?: 'BrandGraphqlType', name: string, id: number }, vendor: { __typename?: 'VendorGraphqlType', name: string, id: number }, manager: { __typename?: 'ManagerGraphqlType', id: number, name: string }, events?: Array<{ __typename?: 'MarketEventGraphqlType', id: number, type: Types.EMarketEventType, count?: number | null, comment?: string | null, isNotationRequired: boolean }> | null } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage?: boolean | null, endCursor?: string | null, startCursor?: string | null } | null } };


export const AdminMarketsDocument = gql`
    query AdminMarkets($filter: MarketPageQueryFilter, $pagination: Pagination, $order: Order) {
  adminMarkets(filter: $filter, pagination: $pagination, order: $order) {
    totalCount
    edges {
      node {
        id
        name
        startedAt
        endedAt
        status
        seller {
          id
          name
          grade
          links {
            id
            isDefault
            url
          }
        }
        sellerSettlementInfo {
          id
          type
        }
        sellingChannel
        expectedSales
        productGroups {
          name
          id
          manager {
            id
            name
          }
        }
        brand {
          name
          id
        }
        vendor {
          name
          id
        }
        manager {
          id
          name
        }
        createdAt
        expectedSettlementAt
        events {
          id
          type
          count
          comment
          isNotationRequired
        }
        isSoldOut
        comment
        updatedAt
        etcSellingChannel
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;

/**
 * __useAdminMarketsQuery__
 *
 * To run a query within a React component, call `useAdminMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMarketsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAdminMarketsQuery(baseOptions?: Apollo.QueryHookOptions<AdminMarketsQuery, AdminMarketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMarketsQuery, AdminMarketsQueryVariables>(AdminMarketsDocument, options);
      }
export function useAdminMarketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMarketsQuery, AdminMarketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMarketsQuery, AdminMarketsQueryVariables>(AdminMarketsDocument, options);
        }
export type AdminMarketsQueryHookResult = ReturnType<typeof useAdminMarketsQuery>;
export type AdminMarketsLazyQueryHookResult = ReturnType<typeof useAdminMarketsLazyQuery>;
export type AdminMarketsQueryResult = Apollo.QueryResult<AdminMarketsQuery, AdminMarketsQueryVariables>;