import { useFormContext } from 'react-hook-form';

import SelectField from '../../../../common/components/Fields/SelectField';
import { ORDER_STATUS_OPTIONS } from '../../const';

export default function OrderStatusField() {
  const { control } = useFormContext();

  return (
    <SelectField
      label='주문상태'
      mode='multiple'
      control={control}
      name='orderStatuses'
      options={ORDER_STATUS_OPTIONS}
      placeholder='주문상태'
    />
  );
}
