import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminCountOrderStatusesQueryVariables = Types.Exact<{
  filter: Types.OrderGroupByStatusFilterQuery;
}>;


export type AdminCountOrderStatusesQuery = { __typename?: 'Query', adminCountOrderStatuses: Array<{ __typename?: 'CountOrderStatusesGraphqlType', count: number, status: string }> };

export type AdminOrdersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  order?: Types.InputMaybe<Types.Scalars['Order']['input']>;
}>;


export type AdminOrdersQuery = { __typename?: 'Query', adminOrders?: { __typename?: 'OrderPageGraphqlType', count: number, totalCount: number, edges: Array<{ __typename?: 'PaginatedOrderGraphqlTypeEdge', cursor: string, node: { __typename?: 'OrderGraphqlType', id: number, groupId: number, code: string, salesChannelOrderCode?: string | null, status: Types.EOrderStatus, productGroupName: string, productName: string, salesChannelProductName?: string | null, productQuantity: number, orderAmount: number, orderType: Types.EOrderType, marketEventId?: number | null, bundleDeliveryCode: string, deliveryFee?: number | null, regionDeliveryFee?: number | null, extraDiscount?: number | null, deliveryCompanyCode?: string | null, deliveryCompanyName?: string | null, trackingNumber?: string | null, receiverName: string, receiverPhoneNumber: string, receiverAddress: string, receiverDetailAddress?: string | null, receiverZipCode: string, receiverDeliveryMemo?: string | null, salesChannelType: Types.ESalesChannelType, salesChannel: Types.ESalesChannel, pointDiscount?: number | null, orderConvertedAt?: string | null, productUseType: Types.EProductUseType, updatedAt: string, claims?: Array<{ __typename?: 'OrderClaimGraphqlType', claimType: Types.EOrderClaimType, status: Types.EOrderClaimStatus }> | null, group: { __typename?: 'OrderGroupGraphqlType', marketName?: string | null, marketId?: number | null, vendorName?: string | null, brandName?: string | null, code: string, ordererName?: string | null, ordererPhoneNumber?: string | null, paymentMethod?: Types.EOrderPaymentMethod | null, orderedAt?: string | null, paidAt?: string | null, salesChannelOrderGroupCode?: string | null }, items?: Array<{ __typename?: 'OrderItemGraphqlType', name: string }> | null } }>, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, startCursor?: string | null, hasPreviousPage?: boolean | null, hasNextPage?: boolean | null } | null } | null };

export type AdminSearchOrdersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OrderFilterQuery>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminSearchOrdersQuery = { __typename?: 'Query', adminOrders?: { __typename?: 'OrderPageGraphqlType', edges: Array<{ __typename?: 'PaginatedOrderGraphqlTypeEdge', node: { __typename?: 'OrderGraphqlType', code: string, group: { __typename?: 'OrderGroupGraphqlType', code: string } } }> } | null };

export type AdminDeliveryCompaniesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminDeliveryCompaniesQuery = { __typename?: 'Query', adminDeliveryCompanies: Array<{ __typename?: 'DeliveryCompanyGraphqlType', id: number, code: string, name: string, international: boolean }> };

export type AdminBulkPatchOrderStatusMutationVariables = Types.Exact<{
  orderData: Types.BulkPathOrderStatusInputGraphqlType;
}>;


export type AdminBulkPatchOrderStatusMutation = { __typename?: 'Mutation', adminBulkPatchOrderStatus: { __typename?: 'BulkOrderOutputGraphqlType', failCount?: number | null, message: string, result: boolean, successCount?: number | null } };

export type AdminBulkPatchOrderForReleaseMutationVariables = Types.Exact<{
  orderData: Types.BulkPatchOrderInputGraphqlType;
}>;


export type AdminBulkPatchOrderForReleaseMutation = { __typename?: 'Mutation', adminBulkPatchOrderForRelease: { __typename?: 'BulkOrderOutputGraphqlType', failCount?: number | null, message: string, result: boolean, successCount?: number | null } };

export type AdminBulkCreateOrderEventMutationVariables = Types.Exact<{
  orderData: Types.BulkCreateOrderEventInputGraphqlType;
}>;


export type AdminBulkCreateOrderEventMutation = { __typename?: 'Mutation', adminBulkCreateOrderEvent: { __typename?: 'BulkOrderOutputGraphqlType', failCount?: number | null, message: string, result: boolean, successCount?: number | null } };

export type AdminBulkDeleteOrderEventMutationVariables = Types.Exact<{
  orderData: Types.BulkDeleteOrderEventInputGraphqlType;
}>;


export type AdminBulkDeleteOrderEventMutation = { __typename?: 'Mutation', adminBulkDeleteOrderEvent: { __typename?: 'BulkOrderOutputGraphqlType', failCount?: number | null, message: string, result: boolean, successCount?: number | null } };

export type AdminBulkPatchOrderForInvoiceExcelMutationVariables = Types.Exact<{
  orderData: Types.BulkPathOrderForInvoiceExcelInputGraphqlType;
}>;


export type AdminBulkPatchOrderForInvoiceExcelMutation = { __typename?: 'Mutation', adminBulkPatchOrderForInvoiceExcel: { __typename?: 'BulkOrderOutputGraphqlType', failCount?: number | null, message: string, result: boolean, successCount?: number | null } };


export const AdminCountOrderStatusesDocument = gql`
    query AdminCountOrderStatuses($filter: OrderGroupByStatusFilterQuery!) {
  adminCountOrderStatuses(filter: $filter) {
    count
    status
  }
}
    `;

/**
 * __useAdminCountOrderStatusesQuery__
 *
 * To run a query within a React component, call `useAdminCountOrderStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountOrderStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountOrderStatusesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAdminCountOrderStatusesQuery(baseOptions: Apollo.QueryHookOptions<AdminCountOrderStatusesQuery, AdminCountOrderStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCountOrderStatusesQuery, AdminCountOrderStatusesQueryVariables>(AdminCountOrderStatusesDocument, options);
      }
export function useAdminCountOrderStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCountOrderStatusesQuery, AdminCountOrderStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCountOrderStatusesQuery, AdminCountOrderStatusesQueryVariables>(AdminCountOrderStatusesDocument, options);
        }
export type AdminCountOrderStatusesQueryHookResult = ReturnType<typeof useAdminCountOrderStatusesQuery>;
export type AdminCountOrderStatusesLazyQueryHookResult = ReturnType<typeof useAdminCountOrderStatusesLazyQuery>;
export type AdminCountOrderStatusesQueryResult = Apollo.QueryResult<AdminCountOrderStatusesQuery, AdminCountOrderStatusesQueryVariables>;
export const AdminOrdersDocument = gql`
    query AdminOrders($filter: OrderFilterQuery, $pagination: Pagination, $order: Order) {
  adminOrders(filter: $filter, pagination: $pagination, order: $order) {
    count
    edges {
      cursor
      node {
        id
        groupId
        code
        salesChannelOrderCode
        status
        claims {
          claimType
          status
        }
        group {
          marketName
          marketId
          vendorName
          brandName
          code
          ordererName
          ordererPhoneNumber
          paymentMethod
          orderedAt
          paidAt
          salesChannelOrderGroupCode
        }
        productGroupName
        productName
        salesChannelProductName
        items {
          name
        }
        productQuantity
        orderAmount
        orderType
        marketEventId
        bundleDeliveryCode
        deliveryFee
        regionDeliveryFee
        extraDiscount
        deliveryCompanyCode
        deliveryCompanyName
        trackingNumber
        receiverName
        receiverPhoneNumber
        receiverAddress
        receiverDetailAddress
        receiverZipCode
        receiverDeliveryMemo
        salesChannelType
        salesChannel
        pointDiscount
        orderConvertedAt
        productUseType
        updatedAt
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasPreviousPage
      hasNextPage
    }
    totalCount
  }
}
    `;

/**
 * __useAdminOrdersQuery__
 *
 * To run a query within a React component, call `useAdminOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAdminOrdersQuery(baseOptions?: Apollo.QueryHookOptions<AdminOrdersQuery, AdminOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOrdersQuery, AdminOrdersQueryVariables>(AdminOrdersDocument, options);
      }
export function useAdminOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOrdersQuery, AdminOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOrdersQuery, AdminOrdersQueryVariables>(AdminOrdersDocument, options);
        }
export type AdminOrdersQueryHookResult = ReturnType<typeof useAdminOrdersQuery>;
export type AdminOrdersLazyQueryHookResult = ReturnType<typeof useAdminOrdersLazyQuery>;
export type AdminOrdersQueryResult = Apollo.QueryResult<AdminOrdersQuery, AdminOrdersQueryVariables>;
export const AdminSearchOrdersDocument = gql`
    query AdminSearchOrders($filter: OrderFilterQuery, $pagination: Pagination) {
  adminOrders(filter: $filter, pagination: $pagination) {
    edges {
      node {
        code
        group {
          code
        }
      }
    }
  }
}
    `;

/**
 * __useAdminSearchOrdersQuery__
 *
 * To run a query within a React component, call `useAdminSearchOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminSearchOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminSearchOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminSearchOrdersQuery(baseOptions?: Apollo.QueryHookOptions<AdminSearchOrdersQuery, AdminSearchOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminSearchOrdersQuery, AdminSearchOrdersQueryVariables>(AdminSearchOrdersDocument, options);
      }
export function useAdminSearchOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminSearchOrdersQuery, AdminSearchOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminSearchOrdersQuery, AdminSearchOrdersQueryVariables>(AdminSearchOrdersDocument, options);
        }
export type AdminSearchOrdersQueryHookResult = ReturnType<typeof useAdminSearchOrdersQuery>;
export type AdminSearchOrdersLazyQueryHookResult = ReturnType<typeof useAdminSearchOrdersLazyQuery>;
export type AdminSearchOrdersQueryResult = Apollo.QueryResult<AdminSearchOrdersQuery, AdminSearchOrdersQueryVariables>;
export const AdminDeliveryCompaniesDocument = gql`
    query AdminDeliveryCompanies {
  adminDeliveryCompanies {
    id
    code
    name
    international
  }
}
    `;

/**
 * __useAdminDeliveryCompaniesQuery__
 *
 * To run a query within a React component, call `useAdminDeliveryCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDeliveryCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDeliveryCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminDeliveryCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<AdminDeliveryCompaniesQuery, AdminDeliveryCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminDeliveryCompaniesQuery, AdminDeliveryCompaniesQueryVariables>(AdminDeliveryCompaniesDocument, options);
      }
export function useAdminDeliveryCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminDeliveryCompaniesQuery, AdminDeliveryCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminDeliveryCompaniesQuery, AdminDeliveryCompaniesQueryVariables>(AdminDeliveryCompaniesDocument, options);
        }
export type AdminDeliveryCompaniesQueryHookResult = ReturnType<typeof useAdminDeliveryCompaniesQuery>;
export type AdminDeliveryCompaniesLazyQueryHookResult = ReturnType<typeof useAdminDeliveryCompaniesLazyQuery>;
export type AdminDeliveryCompaniesQueryResult = Apollo.QueryResult<AdminDeliveryCompaniesQuery, AdminDeliveryCompaniesQueryVariables>;
export const AdminBulkPatchOrderStatusDocument = gql`
    mutation AdminBulkPatchOrderStatus($orderData: BulkPathOrderStatusInputGraphqlType!) {
  adminBulkPatchOrderStatus(orderData: $orderData) {
    failCount
    message
    result
    successCount
  }
}
    `;
export type AdminBulkPatchOrderStatusMutationFn = Apollo.MutationFunction<AdminBulkPatchOrderStatusMutation, AdminBulkPatchOrderStatusMutationVariables>;

/**
 * __useAdminBulkPatchOrderStatusMutation__
 *
 * To run a mutation, you first call `useAdminBulkPatchOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkPatchOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkPatchOrderStatusMutation, { data, loading, error }] = useAdminBulkPatchOrderStatusMutation({
 *   variables: {
 *      orderData: // value for 'orderData'
 *   },
 * });
 */
export function useAdminBulkPatchOrderStatusMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkPatchOrderStatusMutation, AdminBulkPatchOrderStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkPatchOrderStatusMutation, AdminBulkPatchOrderStatusMutationVariables>(AdminBulkPatchOrderStatusDocument, options);
      }
export type AdminBulkPatchOrderStatusMutationHookResult = ReturnType<typeof useAdminBulkPatchOrderStatusMutation>;
export type AdminBulkPatchOrderStatusMutationResult = Apollo.MutationResult<AdminBulkPatchOrderStatusMutation>;
export type AdminBulkPatchOrderStatusMutationOptions = Apollo.BaseMutationOptions<AdminBulkPatchOrderStatusMutation, AdminBulkPatchOrderStatusMutationVariables>;
export const AdminBulkPatchOrderForReleaseDocument = gql`
    mutation AdminBulkPatchOrderForRelease($orderData: BulkPatchOrderInputGraphqlType!) {
  adminBulkPatchOrderForRelease(orderData: $orderData) {
    failCount
    message
    result
    successCount
  }
}
    `;
export type AdminBulkPatchOrderForReleaseMutationFn = Apollo.MutationFunction<AdminBulkPatchOrderForReleaseMutation, AdminBulkPatchOrderForReleaseMutationVariables>;

/**
 * __useAdminBulkPatchOrderForReleaseMutation__
 *
 * To run a mutation, you first call `useAdminBulkPatchOrderForReleaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkPatchOrderForReleaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkPatchOrderForReleaseMutation, { data, loading, error }] = useAdminBulkPatchOrderForReleaseMutation({
 *   variables: {
 *      orderData: // value for 'orderData'
 *   },
 * });
 */
export function useAdminBulkPatchOrderForReleaseMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkPatchOrderForReleaseMutation, AdminBulkPatchOrderForReleaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkPatchOrderForReleaseMutation, AdminBulkPatchOrderForReleaseMutationVariables>(AdminBulkPatchOrderForReleaseDocument, options);
      }
export type AdminBulkPatchOrderForReleaseMutationHookResult = ReturnType<typeof useAdminBulkPatchOrderForReleaseMutation>;
export type AdminBulkPatchOrderForReleaseMutationResult = Apollo.MutationResult<AdminBulkPatchOrderForReleaseMutation>;
export type AdminBulkPatchOrderForReleaseMutationOptions = Apollo.BaseMutationOptions<AdminBulkPatchOrderForReleaseMutation, AdminBulkPatchOrderForReleaseMutationVariables>;
export const AdminBulkCreateOrderEventDocument = gql`
    mutation AdminBulkCreateOrderEvent($orderData: BulkCreateOrderEventInputGraphqlType!) {
  adminBulkCreateOrderEvent(orderData: $orderData) {
    failCount
    message
    result
    successCount
  }
}
    `;
export type AdminBulkCreateOrderEventMutationFn = Apollo.MutationFunction<AdminBulkCreateOrderEventMutation, AdminBulkCreateOrderEventMutationVariables>;

/**
 * __useAdminBulkCreateOrderEventMutation__
 *
 * To run a mutation, you first call `useAdminBulkCreateOrderEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkCreateOrderEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkCreateOrderEventMutation, { data, loading, error }] = useAdminBulkCreateOrderEventMutation({
 *   variables: {
 *      orderData: // value for 'orderData'
 *   },
 * });
 */
export function useAdminBulkCreateOrderEventMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkCreateOrderEventMutation, AdminBulkCreateOrderEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkCreateOrderEventMutation, AdminBulkCreateOrderEventMutationVariables>(AdminBulkCreateOrderEventDocument, options);
      }
export type AdminBulkCreateOrderEventMutationHookResult = ReturnType<typeof useAdminBulkCreateOrderEventMutation>;
export type AdminBulkCreateOrderEventMutationResult = Apollo.MutationResult<AdminBulkCreateOrderEventMutation>;
export type AdminBulkCreateOrderEventMutationOptions = Apollo.BaseMutationOptions<AdminBulkCreateOrderEventMutation, AdminBulkCreateOrderEventMutationVariables>;
export const AdminBulkDeleteOrderEventDocument = gql`
    mutation AdminBulkDeleteOrderEvent($orderData: BulkDeleteOrderEventInputGraphqlType!) {
  adminBulkDeleteOrderEvent(orderData: $orderData) {
    failCount
    message
    result
    successCount
  }
}
    `;
export type AdminBulkDeleteOrderEventMutationFn = Apollo.MutationFunction<AdminBulkDeleteOrderEventMutation, AdminBulkDeleteOrderEventMutationVariables>;

/**
 * __useAdminBulkDeleteOrderEventMutation__
 *
 * To run a mutation, you first call `useAdminBulkDeleteOrderEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkDeleteOrderEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkDeleteOrderEventMutation, { data, loading, error }] = useAdminBulkDeleteOrderEventMutation({
 *   variables: {
 *      orderData: // value for 'orderData'
 *   },
 * });
 */
export function useAdminBulkDeleteOrderEventMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkDeleteOrderEventMutation, AdminBulkDeleteOrderEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkDeleteOrderEventMutation, AdminBulkDeleteOrderEventMutationVariables>(AdminBulkDeleteOrderEventDocument, options);
      }
export type AdminBulkDeleteOrderEventMutationHookResult = ReturnType<typeof useAdminBulkDeleteOrderEventMutation>;
export type AdminBulkDeleteOrderEventMutationResult = Apollo.MutationResult<AdminBulkDeleteOrderEventMutation>;
export type AdminBulkDeleteOrderEventMutationOptions = Apollo.BaseMutationOptions<AdminBulkDeleteOrderEventMutation, AdminBulkDeleteOrderEventMutationVariables>;
export const AdminBulkPatchOrderForInvoiceExcelDocument = gql`
    mutation AdminBulkPatchOrderForInvoiceExcel($orderData: BulkPathOrderForInvoiceExcelInputGraphqlType!) {
  adminBulkPatchOrderForInvoiceExcel(orderData: $orderData) {
    failCount
    message
    result
    successCount
  }
}
    `;
export type AdminBulkPatchOrderForInvoiceExcelMutationFn = Apollo.MutationFunction<AdminBulkPatchOrderForInvoiceExcelMutation, AdminBulkPatchOrderForInvoiceExcelMutationVariables>;

/**
 * __useAdminBulkPatchOrderForInvoiceExcelMutation__
 *
 * To run a mutation, you first call `useAdminBulkPatchOrderForInvoiceExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBulkPatchOrderForInvoiceExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBulkPatchOrderForInvoiceExcelMutation, { data, loading, error }] = useAdminBulkPatchOrderForInvoiceExcelMutation({
 *   variables: {
 *      orderData: // value for 'orderData'
 *   },
 * });
 */
export function useAdminBulkPatchOrderForInvoiceExcelMutation(baseOptions?: Apollo.MutationHookOptions<AdminBulkPatchOrderForInvoiceExcelMutation, AdminBulkPatchOrderForInvoiceExcelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBulkPatchOrderForInvoiceExcelMutation, AdminBulkPatchOrderForInvoiceExcelMutationVariables>(AdminBulkPatchOrderForInvoiceExcelDocument, options);
      }
export type AdminBulkPatchOrderForInvoiceExcelMutationHookResult = ReturnType<typeof useAdminBulkPatchOrderForInvoiceExcelMutation>;
export type AdminBulkPatchOrderForInvoiceExcelMutationResult = Apollo.MutationResult<AdminBulkPatchOrderForInvoiceExcelMutation>;
export type AdminBulkPatchOrderForInvoiceExcelMutationOptions = Apollo.BaseMutationOptions<AdminBulkPatchOrderForInvoiceExcelMutation, AdminBulkPatchOrderForInvoiceExcelMutationVariables>;