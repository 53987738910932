import { Tag } from 'antd';

import { useManagersQuery } from '../../../common/graphql/managers.generated';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { ClaimListSearchParams } from '../type';

function SearchFilterTags() {
  const { data } = useManagersQuery();
  const { searchParams, setSearchParams } =
    useRouteSearchParams<ClaimListSearchParams>();
  const { vendorName, brandName, productName, marketName } = searchParams;
  const claimAssigneeOperatorIds = searchParams.claimAssigneeOperatorIds?.map(
    (id) => Number(id)
  );
  const supportCreatorOperatorIds = searchParams.supportCreatorOperatorIds?.map(
    (id) => Number(id)
  );
  const supportAssigneeOperatorIds =
    searchParams.supportAssigneeOperatorIds?.map((id) => Number(id));
  const assigneeManagers = data?.adminManagers.filter(
    ({ id }) => claimAssigneeOperatorIds?.includes(id)
  );
  const supportCreatorOperators = data?.adminManagers.filter(
    ({ id }) => supportCreatorOperatorIds?.includes(id)
  );
  const supportAssigneeOperators = data?.adminManagers.filter(
    ({ id }) => supportAssigneeOperatorIds?.includes(id)
  );

  return (
    <div>
      {vendorName && (
        <Tag
          closable
          onClose={() => setSearchParams({ vendorName: undefined })}
        >
          {vendorName}
        </Tag>
      )}
      {brandName && (
        <Tag closable onClose={() => setSearchParams({ brandName: undefined })}>
          {brandName}
        </Tag>
      )}
      {productName && (
        <Tag
          closable
          onClose={() => setSearchParams({ productName: undefined })}
        >
          {productName}
        </Tag>
      )}
      {marketName && (
        <Tag
          closable
          onClose={() => setSearchParams({ marketName: undefined })}
        >
          {marketName}
        </Tag>
      )}
      {assigneeManagers &&
        assigneeManagers.map(({ id, name }) => (
          <Tag
            key={id}
            closable
            onClose={() =>
              setSearchParams({
                claimAssigneeOperatorIds:
                  claimAssigneeOperatorIds
                    ?.filter((managerId) => managerId !== id)
                    .map((id) => id.toString()) || [],
              })
            }
          >
            접수자: {name}
          </Tag>
        ))}
      {supportCreatorOperators &&
        supportCreatorOperators.map(({ id, name }) => (
          <Tag
            key={id}
            closable
            onClose={() =>
              setSearchParams({
                supportCreatorOperatorIds:
                  supportCreatorOperatorIds
                    ?.filter((managerId) => managerId !== id)
                    .map((id) => id.toString()) || [],
              })
            }
          >
            응대요청자: {name}
          </Tag>
        ))}
      {supportAssigneeOperators &&
        supportAssigneeOperators.map(({ id, name }) => (
          <Tag
            key={id}
            closable
            onClose={() =>
              setSearchParams({
                supportAssigneeOperatorIds:
                  supportAssigneeOperatorIds
                    ?.filter((managerId) => managerId !== id)
                    .map((id) => id.toString()) || [],
              })
            }
          >
            응대담당자: {name}
          </Tag>
        ))}
    </div>
  );
}

export default SearchFilterTags;
