import * as Types from '../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateUploadResourcesMutationVariables = Types.Exact<{
  generateUploadResourcesInput: Types.GenerateUploadResourcesInput;
}>;


export type GenerateUploadResourcesMutation = { __typename?: 'Mutation', adminGenerateUploadResources: { __typename?: 'GenerateUploadResourcesPayload', uploadUrl: string, uploadFormDataJSON: string, destinationUrl: string } };


export const GenerateUploadResourcesDocument = gql`
    mutation GenerateUploadResources($generateUploadResourcesInput: GenerateUploadResourcesInput!) {
  adminGenerateUploadResources(
    generateUploadResourcesInput: $generateUploadResourcesInput
  ) {
    uploadUrl
    uploadFormDataJSON
    destinationUrl
  }
}
    `;
export type GenerateUploadResourcesMutationFn = Apollo.MutationFunction<GenerateUploadResourcesMutation, GenerateUploadResourcesMutationVariables>;

/**
 * __useGenerateUploadResourcesMutation__
 *
 * To run a mutation, you first call `useGenerateUploadResourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUploadResourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUploadResourcesMutation, { data, loading, error }] = useGenerateUploadResourcesMutation({
 *   variables: {
 *      generateUploadResourcesInput: // value for 'generateUploadResourcesInput'
 *   },
 * });
 */
export function useGenerateUploadResourcesMutation(baseOptions?: Apollo.MutationHookOptions<GenerateUploadResourcesMutation, GenerateUploadResourcesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateUploadResourcesMutation, GenerateUploadResourcesMutationVariables>(GenerateUploadResourcesDocument, options);
      }
export type GenerateUploadResourcesMutationHookResult = ReturnType<typeof useGenerateUploadResourcesMutation>;
export type GenerateUploadResourcesMutationResult = Apollo.MutationResult<GenerateUploadResourcesMutation>;
export type GenerateUploadResourcesMutationOptions = Apollo.BaseMutationOptions<GenerateUploadResourcesMutation, GenerateUploadResourcesMutationVariables>;