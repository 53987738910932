import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminCreateOrderSupportMessageMutationVariables = Types.Exact<{
  supportMessageData: Types.CreateOrderSupportMessageInputGraphqlType;
}>;


export type AdminCreateOrderSupportMessageMutation = { __typename?: 'Mutation', adminCreateOrderSupportMessage: { __typename?: 'OrderSupportMessageOutputGraphqlType', orderSupportMessage: { __typename?: 'OrderSupportMessageGraphqlType', content?: string | null, createdAt: string, id: number, isStatusUpdate: boolean, title: string, updatedAt: string } } };

export type AdminOrderSupportMessagesQueryVariables = Types.Exact<{
  pageQuery: Types.OrderSupportMessagePageQuery;
}>;


export type AdminOrderSupportMessagesQuery = { __typename?: 'Query', adminOrderSupportMessages: { __typename?: 'OrderSupportMessagePageGraphqlType', totalCount: number, edges: Array<{ __typename?: 'PaginatedOrderSupportMessageGraphqlTypeEdge', node: { __typename?: 'OrderSupportMessageGraphqlType', content?: string | null, createdAt: string, id: number, isStatusUpdate: boolean, title: string, updatedAt: string, operatorId: number } }>, pageInfo?: { __typename?: 'PageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, endCursor?: string | null } | null } };

export type AdminCreateOrderSupportWithMessageMutationVariables = Types.Exact<{
  orderSupportData: Types.CreateOrderSupportWithMessageInputGraphqlType;
}>;


export type AdminCreateOrderSupportWithMessageMutation = { __typename?: 'Mutation', adminCreateOrderSupportWithMessage: { __typename?: 'OrderSupportOutputGraphqlType', orderSupport: { __typename?: 'OrderSupportGraphqlType', channel: Types.EOrderSupportChannelType, closedAt?: string | null, id: number, orderId: number, requestType: Types.EOrderSupportRequestType, status: Types.EOrderSupportStatus, order: { __typename?: 'OrderGraphqlType', status: Types.EOrderStatus, support?: { __typename?: 'OrderSupportGraphqlType', channel: Types.EOrderSupportChannelType, closedAt?: string | null, id: number, orderId: number, requestType: Types.EOrderSupportRequestType, status: Types.EOrderSupportStatus } | null, claims?: Array<{ __typename?: 'OrderClaimGraphqlType', claimQuantity?: number | null, claimType: Types.EOrderClaimType, createdAt: string, id: number, isWiredOwnDeliveryFault?: boolean | null, orderId: number, reason?: string | null, reasonType: Types.EOrderClaimReasonType, status: Types.EOrderClaimStatus, target: Types.EOrderClaimTarget, updatedAt: string }> | null } } } };

export type AdminPatchOrderSupportMessageMutationVariables = Types.Exact<{
  orderSupportMessageData: Types.PatchOrderSupportMessageInputGraphqlType;
  orderSupportMessageId: Types.Scalars['Int']['input'];
}>;


export type AdminPatchOrderSupportMessageMutation = { __typename?: 'Mutation', adminPatchOrderSupportMessage: { __typename?: 'OrderSupportMessageOutputGraphqlType', orderSupportMessage: { __typename?: 'OrderSupportMessageGraphqlType', content?: string | null, createdAt: string, id: number, isStatusUpdate: boolean, title: string, updatedAt: string } } };

export type AdminPatchOrderSupportStatusMutationVariables = Types.Exact<{
  orderSupportData: Types.PatchOrderSupportStatusInputGraphqlType;
}>;


export type AdminPatchOrderSupportStatusMutation = { __typename?: 'Mutation', adminPatchOrderSupportStatus: { __typename?: 'OrderSupportOutputGraphqlType', orderSupport: { __typename?: 'OrderSupportGraphqlType', id: number, status: Types.EOrderSupportStatus } } };

export type AdminCountOrderSupportStatusesQueryVariables = Types.Exact<{
  orderFilter: Types.OrderFilterQuery;
}>;


export type AdminCountOrderSupportStatusesQuery = { __typename?: 'Query', adminCountOrderSupportStatuses: Array<{ __typename?: 'CountOrderSupportStatusesGraphqlType', count: number, status: string }> };


export const AdminCreateOrderSupportMessageDocument = gql`
    mutation AdminCreateOrderSupportMessage($supportMessageData: CreateOrderSupportMessageInputGraphqlType!) {
  adminCreateOrderSupportMessage(supportMessageData: $supportMessageData) {
    orderSupportMessage {
      content
      createdAt
      id
      isStatusUpdate
      title
      updatedAt
    }
  }
}
    `;
export type AdminCreateOrderSupportMessageMutationFn = Apollo.MutationFunction<AdminCreateOrderSupportMessageMutation, AdminCreateOrderSupportMessageMutationVariables>;

/**
 * __useAdminCreateOrderSupportMessageMutation__
 *
 * To run a mutation, you first call `useAdminCreateOrderSupportMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateOrderSupportMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateOrderSupportMessageMutation, { data, loading, error }] = useAdminCreateOrderSupportMessageMutation({
 *   variables: {
 *      supportMessageData: // value for 'supportMessageData'
 *   },
 * });
 */
export function useAdminCreateOrderSupportMessageMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateOrderSupportMessageMutation, AdminCreateOrderSupportMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateOrderSupportMessageMutation, AdminCreateOrderSupportMessageMutationVariables>(AdminCreateOrderSupportMessageDocument, options);
      }
export type AdminCreateOrderSupportMessageMutationHookResult = ReturnType<typeof useAdminCreateOrderSupportMessageMutation>;
export type AdminCreateOrderSupportMessageMutationResult = Apollo.MutationResult<AdminCreateOrderSupportMessageMutation>;
export type AdminCreateOrderSupportMessageMutationOptions = Apollo.BaseMutationOptions<AdminCreateOrderSupportMessageMutation, AdminCreateOrderSupportMessageMutationVariables>;
export const AdminOrderSupportMessagesDocument = gql`
    query AdminOrderSupportMessages($pageQuery: OrderSupportMessagePageQuery!) {
  adminOrderSupportMessages(pageQuery: $pageQuery) {
    edges {
      node {
        content
        createdAt
        id
        isStatusUpdate
        title
        updatedAt
        operatorId
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
    }
  }
}
    `;

/**
 * __useAdminOrderSupportMessagesQuery__
 *
 * To run a query within a React component, call `useAdminOrderSupportMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrderSupportMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrderSupportMessagesQuery({
 *   variables: {
 *      pageQuery: // value for 'pageQuery'
 *   },
 * });
 */
export function useAdminOrderSupportMessagesQuery(baseOptions: Apollo.QueryHookOptions<AdminOrderSupportMessagesQuery, AdminOrderSupportMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOrderSupportMessagesQuery, AdminOrderSupportMessagesQueryVariables>(AdminOrderSupportMessagesDocument, options);
      }
export function useAdminOrderSupportMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOrderSupportMessagesQuery, AdminOrderSupportMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOrderSupportMessagesQuery, AdminOrderSupportMessagesQueryVariables>(AdminOrderSupportMessagesDocument, options);
        }
export type AdminOrderSupportMessagesQueryHookResult = ReturnType<typeof useAdminOrderSupportMessagesQuery>;
export type AdminOrderSupportMessagesLazyQueryHookResult = ReturnType<typeof useAdminOrderSupportMessagesLazyQuery>;
export type AdminOrderSupportMessagesQueryResult = Apollo.QueryResult<AdminOrderSupportMessagesQuery, AdminOrderSupportMessagesQueryVariables>;
export const AdminCreateOrderSupportWithMessageDocument = gql`
    mutation AdminCreateOrderSupportWithMessage($orderSupportData: CreateOrderSupportWithMessageInputGraphqlType!) {
  adminCreateOrderSupportWithMessage(orderSupportData: $orderSupportData) {
    orderSupport {
      channel
      closedAt
      id
      order {
        support {
          channel
          closedAt
          id
          orderId
          requestType
          status
        }
        status
        claims {
          claimQuantity
          claimType
          createdAt
          id
          isWiredOwnDeliveryFault
          orderId
          reason
          reasonType
          status
          target
          updatedAt
        }
      }
      orderId
      requestType
      status
    }
  }
}
    `;
export type AdminCreateOrderSupportWithMessageMutationFn = Apollo.MutationFunction<AdminCreateOrderSupportWithMessageMutation, AdminCreateOrderSupportWithMessageMutationVariables>;

/**
 * __useAdminCreateOrderSupportWithMessageMutation__
 *
 * To run a mutation, you first call `useAdminCreateOrderSupportWithMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreateOrderSupportWithMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreateOrderSupportWithMessageMutation, { data, loading, error }] = useAdminCreateOrderSupportWithMessageMutation({
 *   variables: {
 *      orderSupportData: // value for 'orderSupportData'
 *   },
 * });
 */
export function useAdminCreateOrderSupportWithMessageMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateOrderSupportWithMessageMutation, AdminCreateOrderSupportWithMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateOrderSupportWithMessageMutation, AdminCreateOrderSupportWithMessageMutationVariables>(AdminCreateOrderSupportWithMessageDocument, options);
      }
export type AdminCreateOrderSupportWithMessageMutationHookResult = ReturnType<typeof useAdminCreateOrderSupportWithMessageMutation>;
export type AdminCreateOrderSupportWithMessageMutationResult = Apollo.MutationResult<AdminCreateOrderSupportWithMessageMutation>;
export type AdminCreateOrderSupportWithMessageMutationOptions = Apollo.BaseMutationOptions<AdminCreateOrderSupportWithMessageMutation, AdminCreateOrderSupportWithMessageMutationVariables>;
export const AdminPatchOrderSupportMessageDocument = gql`
    mutation AdminPatchOrderSupportMessage($orderSupportMessageData: PatchOrderSupportMessageInputGraphqlType!, $orderSupportMessageId: Int!) {
  adminPatchOrderSupportMessage(
    orderSupportMessageData: $orderSupportMessageData
    orderSupportMessageId: $orderSupportMessageId
  ) {
    orderSupportMessage {
      content
      createdAt
      id
      isStatusUpdate
      title
      updatedAt
    }
  }
}
    `;
export type AdminPatchOrderSupportMessageMutationFn = Apollo.MutationFunction<AdminPatchOrderSupportMessageMutation, AdminPatchOrderSupportMessageMutationVariables>;

/**
 * __useAdminPatchOrderSupportMessageMutation__
 *
 * To run a mutation, you first call `useAdminPatchOrderSupportMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPatchOrderSupportMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPatchOrderSupportMessageMutation, { data, loading, error }] = useAdminPatchOrderSupportMessageMutation({
 *   variables: {
 *      orderSupportMessageData: // value for 'orderSupportMessageData'
 *      orderSupportMessageId: // value for 'orderSupportMessageId'
 *   },
 * });
 */
export function useAdminPatchOrderSupportMessageMutation(baseOptions?: Apollo.MutationHookOptions<AdminPatchOrderSupportMessageMutation, AdminPatchOrderSupportMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPatchOrderSupportMessageMutation, AdminPatchOrderSupportMessageMutationVariables>(AdminPatchOrderSupportMessageDocument, options);
      }
export type AdminPatchOrderSupportMessageMutationHookResult = ReturnType<typeof useAdminPatchOrderSupportMessageMutation>;
export type AdminPatchOrderSupportMessageMutationResult = Apollo.MutationResult<AdminPatchOrderSupportMessageMutation>;
export type AdminPatchOrderSupportMessageMutationOptions = Apollo.BaseMutationOptions<AdminPatchOrderSupportMessageMutation, AdminPatchOrderSupportMessageMutationVariables>;
export const AdminPatchOrderSupportStatusDocument = gql`
    mutation AdminPatchOrderSupportStatus($orderSupportData: PatchOrderSupportStatusInputGraphqlType!) {
  adminPatchOrderSupportStatus(orderSupportData: $orderSupportData) {
    orderSupport {
      id
      status
    }
  }
}
    `;
export type AdminPatchOrderSupportStatusMutationFn = Apollo.MutationFunction<AdminPatchOrderSupportStatusMutation, AdminPatchOrderSupportStatusMutationVariables>;

/**
 * __useAdminPatchOrderSupportStatusMutation__
 *
 * To run a mutation, you first call `useAdminPatchOrderSupportStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPatchOrderSupportStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPatchOrderSupportStatusMutation, { data, loading, error }] = useAdminPatchOrderSupportStatusMutation({
 *   variables: {
 *      orderSupportData: // value for 'orderSupportData'
 *   },
 * });
 */
export function useAdminPatchOrderSupportStatusMutation(baseOptions?: Apollo.MutationHookOptions<AdminPatchOrderSupportStatusMutation, AdminPatchOrderSupportStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminPatchOrderSupportStatusMutation, AdminPatchOrderSupportStatusMutationVariables>(AdminPatchOrderSupportStatusDocument, options);
      }
export type AdminPatchOrderSupportStatusMutationHookResult = ReturnType<typeof useAdminPatchOrderSupportStatusMutation>;
export type AdminPatchOrderSupportStatusMutationResult = Apollo.MutationResult<AdminPatchOrderSupportStatusMutation>;
export type AdminPatchOrderSupportStatusMutationOptions = Apollo.BaseMutationOptions<AdminPatchOrderSupportStatusMutation, AdminPatchOrderSupportStatusMutationVariables>;
export const AdminCountOrderSupportStatusesDocument = gql`
    query AdminCountOrderSupportStatuses($orderFilter: OrderFilterQuery!) {
  adminCountOrderSupportStatuses(orderFilter: $orderFilter) {
    count
    status
  }
}
    `;

/**
 * __useAdminCountOrderSupportStatusesQuery__
 *
 * To run a query within a React component, call `useAdminCountOrderSupportStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminCountOrderSupportStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminCountOrderSupportStatusesQuery({
 *   variables: {
 *      orderFilter: // value for 'orderFilter'
 *   },
 * });
 */
export function useAdminCountOrderSupportStatusesQuery(baseOptions: Apollo.QueryHookOptions<AdminCountOrderSupportStatusesQuery, AdminCountOrderSupportStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminCountOrderSupportStatusesQuery, AdminCountOrderSupportStatusesQueryVariables>(AdminCountOrderSupportStatusesDocument, options);
      }
export function useAdminCountOrderSupportStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminCountOrderSupportStatusesQuery, AdminCountOrderSupportStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminCountOrderSupportStatusesQuery, AdminCountOrderSupportStatusesQueryVariables>(AdminCountOrderSupportStatusesDocument, options);
        }
export type AdminCountOrderSupportStatusesQueryHookResult = ReturnType<typeof useAdminCountOrderSupportStatusesQuery>;
export type AdminCountOrderSupportStatusesLazyQueryHookResult = ReturnType<typeof useAdminCountOrderSupportStatusesLazyQuery>;
export type AdminCountOrderSupportStatusesQueryResult = Apollo.QueryResult<AdminCountOrderSupportStatusesQuery, AdminCountOrderSupportStatusesQueryVariables>;