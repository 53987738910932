import { SelectProps } from 'antd';
import { useCallback } from 'react';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import { FieldProps } from '../../../../common/components/Fields/types';
import AsyncSelect from '../../../../common/components/Input/AsyncSelect';
import { useAdminVendorsInSettlementListLazyQuery } from '../../graphql/vendors.generated';

type VendorSelectProps = SelectProps &
  FieldProps & {
    initialOption?: { value: number; label: string };
  };

const VendorSelect = ({
  onChange,
  initialOption,
  label,
  required,
  ...props
}: VendorSelectProps) => {
  const [fetchVendors] = useAdminVendorsInSettlementListLazyQuery();

  const fetchItems = useCallback(
    async (name: string) => {
      const { data } = await fetchVendors({
        variables: {
          vendorsInput: {
            search: {
              vendorNameOrCode: name,
            },
            sortings: [{ field: 'UPDATED_AT', order: 'DESC' }],
          },
        },
      });

      return (
        data?.adminVendors.vendors.map(({ name, id }) => ({
          label: name,
          value: id,
        })) || []
      );
    },
    [fetchVendors]
  );

  return (
    <FieldWithLabel label={label} required={required}>
      <AsyncSelect
        initialLoad
        className='w-full'
        fetchItems={fetchItems}
        onChange={onChange}
        initialOption={initialOption}
        {...props}
      />
    </FieldWithLabel>
  );
};

export default VendorSelect;
