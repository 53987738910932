import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, generatePath } from 'react-router-dom';

import { ROLES_TEXT } from '../domain/operator/const';
import {
  OperatorsInOperatorQuery as OperatorsQuery,
  useOperatorsInOperatorQuery as useOperatorsQuery,
} from '../domain/operator/graphql/operators.generated';
import { ROUTES } from '../routes/const';
import { ERole } from '../schema/types';

type DataType = OperatorsQuery['adminOperators']['edges'][number]['node'];

export default function OperaterList() {
  const { data } = useOperatorsQuery({
    variables: { order: { updatedAt: 'DESC' }, pagination: { take: 100 } },
    fetchPolicy: 'no-cache',
  });

  const operators = data?.adminOperators.edges.map((operator) => ({
    ...operator.node,
    key: operator.node.id,
  }));

  const getPermissionText = (roles: (ERole | null)[]) => {
    const text = roles.map((role) => role && ROLES_TEXT[role]).join(', ');
    return text ? text : '-';
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 160,
      render: (_, record) => (
        <Link
          to={generatePath(ROUTES.OPERATOR_DETAIL.path, {
            operatorId: record.id,
          })}
        >
          {record.name}
        </Link>
      ),
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      width: 240,
    },
    {
      title: '권한목록',
      dataIndex: 'permission',
      key: 'permission',
      render: (_, record) => getPermissionText(record.roles),
    },
  ];

  return (
    <div className='bg-white p-6 pb-20'>
      <Typography.Title level={4}>운영자리스트</Typography.Title>
      <Table
        dataSource={operators || []}
        columns={columns}
        pagination={false}
        footer={operators ? () => `총 ${operators.length}명` : undefined}
      />
    </div>
  );
}
