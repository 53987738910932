import { PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Typography } from 'antd';
import { omit } from 'lodash-es';
import { useCallback, useState } from 'react';

import HistoryModal from '../../../common/components/History/HistoryModal';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { useUpdateFormDirtyContext } from '../contexts/UpdateFormDirtyContext';
import useCancelConfirmModal from '../hooks/useCancelConfirmModal';
import useVendorDetailQuery from '../hooks/useVendorDetailQuery';

import { BrandDetailForm, BrandDetailExtra } from './BrandDetailFormItem';
import CreateBrandModal from './CreateBrandModal';

function BrandDetailFormList() {
  const { hasPermission } = usePermissions('BRAND_AND_VENDOR_EDIT');
  const { data } = useVendorDetailQuery();
  const vendorId = data?.adminVendorWithBrands?.id;
  const brands = data?.adminVendorWithBrands?.brands;
  const removeDisabled = data?.adminVendorWithBrands?.brands.length === 1;
  const [createModal, setCreateModal] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const defaultOpenKey = brands && brands[0].id;
  const [activeKey, setActiveKey] = useState<string[] | number | undefined>(
    defaultOpenKey
  );
  const { isDirty, setIsDirty } = useUpdateFormDirtyContext();
  const openCancelConfirm = useCancelConfirmModal();
  const handleCollapseKey = useCallback(
    (key: string | string[]) => {
      if (Array.isArray(key)) {
        if (isDirty) {
          openCancelConfirm(() => {
            setIsDirty(false);
            setActiveKey(key);
          });
        } else {
          setActiveKey(key);
        }
      }
    },
    [setActiveKey, isDirty, setIsDirty, openCancelConfirm]
  );

  return (
    <>
      <div className='flex justify-between'>
        <Typography.Title level={4} className={'m-0 p-lg'}>
          브랜드리스트
        </Typography.Title>
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => setCreateModal(true)}
          disabled={!hasPermission}
        >
          브랜드 등록
        </Button>
      </div>
      <Collapse
        accordion
        size='large'
        destroyInactivePanel
        onChange={handleCollapseKey}
        activeKey={activeKey}
        items={brands?.map((brand) => ({
          key: brand.id,
          label: (
            <Typography.Title level={5} className='m-0'>
              {brand.name}
            </Typography.Title>
          ),
          extra: (
            <BrandDetailExtra
              brandId={brand.id}
              status={brand.status}
              removeDisabled={removeDisabled}
              disabled={!hasPermission}
            />
          ),
          children: (
            <BrandDetailForm
              brandId={brand.id}
              name={brand.name}
              status={brand.status}
              brandContacts={brand.brandContacts.map((contact) =>
                omit(contact, '__typename')
              )}
              removeDisabled={removeDisabled}
              disabled={!hasPermission}
            />
          ),
        }))}
      />
      {createModal && vendorId && (
        <CreateBrandModal
          vendorId={vendorId}
          onClose={() => setCreateModal(false)}
        />
      )}
      {vendorId && (
        <HistoryModal
          uiType='BRAND'
          id={vendorId}
          open={historyModalOpen}
          onClose={() => setHistoryModalOpen(false)}
        />
      )}
    </>
  );
}

export default BrandDetailFormList;
