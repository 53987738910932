import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Tag, Tooltip } from 'antd';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import NumberField from '../../../common/components/Fields/NumberField';
import RadioField from '../../../common/components/Fields/RadioField';
import SelectField from '../../../common/components/Fields/SelectField';
import TextField from '../../../common/components/Fields/TextField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { PRODUCT_TAX_TYPE, PRODUCT_USE_TYPE } from '../const';
import useGetReferencedMarkets from '../hooks/useGetReferencedMarkets';
import useProductPriceCalculate from '../hooks/useProductPriceCalculate';
import { ProductGroupFormValues } from '../types';

import ProductTitleField from './Fields/ProductTitleField';
import ProductOptionType from './ProductOptionType';
import ProductOptionTypeDescription from './ProductOptionTypeDescription';

type ProductCollapseContentsProps = {
  index: number;
};

export default function ProductField({ index }: ProductCollapseContentsProps) {
  const { hasPermission } = usePermissions('PRODUCT_EDIT');
  const { editableProduct } = useGetReferencedMarkets();

  const { control, getValues } = useFormContext<ProductGroupFormValues>();
  const [modal, contextHolder] = Modal.useModal();
  const formType = getValues('formType');

  const isNew = getValues(`products.${index}.isNew`);
  const editable = hasPermission && (editableProduct || isNew);

  const {
    handleRetailPriceChange,
    handleOnlineLowestPriceChange,
    handleJointPurchasePriceChange,
    handleBusinessSellerSupplyPriceChange,
    handleBusinessSellerMarginChange,
    handleBusinessSellerMarginRateChange,
    handleFreelanceSellerSupplyPriceChange,
    handleFreelanceSellerMarginChange,
    handleFreelanceSellerMarginRateChange,
    handleInHousePurchasePriceChange,
    handleBusinessInHouseSellerMargin,
    handleBusinessInHouseMarginRateRateChange,
    handleFreelanceInHouseSellerMargin,
    handleFreelanceInHouseMarginRateRateChange,
  } = useProductPriceCalculate({ index });

  const product = getValues(`products.${index}`);

  const columns = useMemo(
    () => [
      {
        title: <ColumnName name='소비자가' required={editable} />,
        key: 'retailPrice',
        children: (
          <NumberField
            control={control}
            name={`products.${index}.retailPrice`}
            numberType='price'
            onBlur={handleRetailPriceChange}
            disabled={!editable}
            placeholder='소비자가'
            hideErrorMessage
          />
        ),
        width: 160,
      },
      {
        title: <ColumnName name='온라인최저가' required={false} />,
        key: 'onlineLowestPrice',
        children: (
          <>
            <NumberField
              control={control}
              name={`products.${index}.onlineLowestPrice`}
              numberType='price'
              onBlur={handleOnlineLowestPriceChange}
              disabled={!editable}
              placeholder='온라인최저가'
              hideErrorMessage
            />
          </>
        ),
        width: 160,
      },
      {
        title: <ColumnName name='공동구매가' required={editable} />,
        key: 'jointPurchasePrice',
        children: (
          <NumberField
            control={control}
            name={`products.${index}.jointPurchasePrice`}
            numberType='price'
            onBlur={() => {
              const jointPurchasePrice = getValues(
                `products.${index}.jointPurchasePrice`
              );

              const onlineLowestPrice = getValues(
                `products.${index}.onlineLowestPrice`
              );

              const isHigherThanOnlineLowestPrice =
                (jointPurchasePrice ?? 0) >= (onlineLowestPrice ?? 0);

              if (isHigherThanOnlineLowestPrice) {
                modal.warning({
                  title: '공동구매가를 다시 확인해주세요.',
                  content: (
                    <p className='break-keep'>
                      온라인최저가보다 가격이 높거나 동일합니다. 확인 후
                      저장해주세요.
                    </p>
                  ),
                });
              }

              handleJointPurchasePriceChange();
            }}
            disabled={!editable}
            placeholder='공동구매가'
            hideErrorMessage
          />
        ),
        width: 160,
      },
      {
        title: <ColumnName name='셀러공급가' required={editable} />,
        key: 'sellerSupplyPrice',
        children: (
          <div className='flex flex-col gap-2'>
            <NumberField
              control={control}
              name={`products.${index}.businessSellerSupplyPrice`}
              numberType='price'
              disabled={!editable}
              placeholder='사업자'
              hideErrorMessage
              onBlur={handleBusinessSellerSupplyPriceChange}
            />
            <NumberField
              control={control}
              name={`products.${index}.freelanceSellerSupplyPrice`}
              numberType='price'
              disabled={!editable}
              placeholder='프리랜서'
              hideErrorMessage
              onBlur={handleFreelanceSellerSupplyPriceChange}
            />
          </div>
        ),
        width: 160,
      },
      {
        title: <ColumnName name='셀러마진' required={editable} />,
        key: 'sellerMargin',
        children: (
          <div className='flex flex-col gap-2'>
            <NumberField
              control={control}
              name={`products.${index}.businessSellerMargin`}
              numberType='price'
              disabled={!editable}
              placeholder='사업자'
              hideErrorMessage
              onBlur={handleBusinessSellerMarginChange}
            />
            <NumberField
              control={control}
              name={`products.${index}.freelanceSellerMargin`}
              numberType='price'
              disabled={!editable}
              placeholder='프리랜서'
              hideErrorMessage
              onBlur={handleFreelanceSellerMarginChange}
            />
          </div>
        ),
        width: 160,
      },
      {
        title: <ColumnName name='셀러마진율' required={editable} />,
        key: 'sellerMarginRate',
        children: (
          <div className='flex flex-col gap-2'>
            <NumberField
              control={control}
              name={`products.${index}.businessSellerMarginRate`}
              numberType='percent'
              disabled={!editable}
              suffix={'%'}
              placeholder='사업자'
              hideErrorMessage
              onBlur={handleBusinessSellerMarginRateChange}
            />
            <NumberField
              control={control}
              name={`products.${index}.freelanceSellerMarginRate`}
              numberType='percent'
              disabled={!editable}
              suffix={'%'}
              placeholder='프리랜서'
              hideErrorMessage
              onBlur={handleFreelanceSellerMarginRateChange}
            />
          </div>
        ),
        width: 160,
      },
      {
        title: <ColumnName name='자사매입가' required={editable} />,
        key: 'inHousePurchasePrice',
        children: (
          <NumberField
            control={control}
            name={`products.${index}.inHousePurchasePrice`}
            numberType='price'
            disabled={!editable}
            placeholder='자사매입가'
            hideErrorMessage
            onBlur={handleInHousePurchasePriceChange}
          />
        ),
        width: 160,
      },
      {
        title: <ColumnName name='자사마진' required={editable} />,
        key: 'inHouseMargin',
        children: (
          <div className='flex flex-col gap-2'>
            <NumberField
              control={control}
              name={`products.${index}.businessInHouseMargin`}
              numberType='price'
              disabled={!editable}
              placeholder='사업자'
              hideErrorMessage
              onBlur={handleBusinessInHouseSellerMargin}
            />
            <NumberField
              control={control}
              name={`products.${index}.freelanceInHouseMargin`}
              numberType='price'
              disabled={!editable}
              placeholder='프리랜서'
              hideErrorMessage
              onBlur={handleFreelanceInHouseSellerMargin}
            />
          </div>
        ),
        width: 160,
      },
      {
        title: <ColumnName name='자사마진율' required={editable} />,
        key: 'inHouseMarginRate',
        children: (
          <div className='flex flex-col gap-2'>
            <NumberField
              control={control}
              name={`products.${index}.businessInHouseMarginRate`}
              numberType='percent'
              disabled={!editable}
              suffix={'%'}
              placeholder='사업자'
              hideErrorMessage
              onBlur={handleBusinessInHouseMarginRateRateChange}
            />
            <NumberField
              control={control}
              name={`products.${index}.freelanceInHouseMarginRate`}
              numberType='percent'
              disabled={!editable}
              suffix={'%'}
              placeholder='프리랜서'
              hideErrorMessage
              onBlur={handleFreelanceInHouseMarginRateRateChange}
            />
          </div>
        ),
        width: 160,
      },
      {
        title: <ColumnName name='과세/면세' required={editable} />,
        key: 'taxType',
        children: (
          <SelectField
            control={control}
            name={`products.${index}.taxType`}
            options={PRODUCT_TAX_TYPE}
            disabled={!editable}
          />
        ),
        width: 160,
      },
      {
        title: <ColumnName name='비고' required={false} />,
        key: 'note',
        children: (
          <TextField
            control={control}
            name={`products.${index}.note`}
            placeholder='내용입력'
            disabled={!editable}
          />
        ),
        width: 240,
      },
    ],
    [index, editable]
  );

  return (
    <>
      <div>
        {formType === 'UPDATE' && product.code && (
          <div>
            <Tag>{product.code}</Tag>
          </div>
        )}
        <div className='mt-2 flex justify-between gap-4'>
          <div className='flex-1'>
            <ProductTitleField
              index={index}
              disabled={!editable}
              hideErrorMessage
            />
          </div>
          <div className='flex items-center gap-2'>
            <RadioField
              control={control}
              name={`products.${index}.useType`}
              options={PRODUCT_USE_TYPE}
              optionType='button'
              disabled={!editable}
            />
            <Tooltip
              title={<ProductOptionTypeDescription />}
              placement='bottomRight'
              rootClassName='max-w-[320px]'
              arrow={{
                pointAtCenter: true,
              }}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        </div>
        <div className='mt-6'>
          <div className='flex gap-2'>
            <ProductOptionType index={index} disabled={!editable} />
          </div>
        </div>
        <div className='mt-6'>
          <ul className='scroll-box flex overflow-auto'>
            {columns.map((column) => {
              return (
                <li
                  key={column.key}
                  className='flex flex-shrink-0 flex-col items-center'
                  style={{ width: column.width }}
                >
                  <div
                    className='w-full bg-[#00000005]'
                    style={{ borderBottom: '1px solid #0000000f' }}
                  >
                    <div className='py-4'>
                      <div
                        className='px-4'
                        style={{ borderLeft: '1px solid #0000000f' }}
                      >
                        {column.title}
                      </div>
                    </div>
                  </div>
                  <div className='flex h-full w-full flex-1 items-center justify-center p-4'>
                    {column.children}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {contextHolder}
    </>
  );
}

type ColumnNameProps = {
  name: string;
  required?: boolean;
};

const ColumnName = ({ name, required = true }: ColumnNameProps) => {
  return (
    <>
      {required && <span className={'mr-sm text-red-400'}>*</span>}
      {name}
    </>
  );
};
