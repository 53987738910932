import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import {
  EOrderStatus,
  EOrderType,
  OrderFilterQuery,
} from '../../../schema/types';
import { useAdminOrdersQuery } from '../graphql/orders.generated';
import { getOrdersTableData } from '../helper';
import { OrderListSearchParams, OrderTableDataType } from '../types';

const defaultStatus: EOrderStatus[] = [
  'DELIVERY_COMPLETED',
  'DELIVERY_STARTED',
  'DELIVERY_WAIT',
  'NULL',
  'PAYMENT_COMPLETED',
  'PAYMENT_WAIT',
  'PURCHASE_CONFIRMED',
];

export default function useOrderListTable() {
  const [dataSourceState, setDataSourceState] = useState<OrderTableDataType[]>(
    []
  );

  const { searchParams } = useRouteSearchParams<OrderListSearchParams>();

  const currentPage = Number(searchParams.currentPage) - 1 || 0;
  const pageSize = Number(searchParams.pageSize) || 200;

  const period = useMemo(() => {
    if (!searchParams.periodType) return undefined;

    return {
      type: searchParams.periodType,
      startedAt: searchParams.startedAt
        ? dayjs(searchParams.startedAt).startOf('day').toISOString()
        : undefined,
      endedAt: searchParams.endedAt
        ? dayjs(searchParams.endedAt).endOf('day').toISOString()
        : undefined,
    };
  }, [searchParams.endedAt, searchParams.periodType, searchParams.startedAt]);

  const salesChannelTypes = useMemo(() => {
    if (!searchParams.salesChannelType) return undefined;

    return [searchParams.salesChannelType];
  }, [searchParams.salesChannelType]);

  const filterParams: OrderFilterQuery = {
    salesChannelTypes,
    searchKeyword: searchParams.searchKeyword,
    period,
    statuses: searchParams.orderStatuses
      ? searchParams.orderStatuses
      : searchParams.summaryStatus
      ? [searchParams.summaryStatus]
      : defaultStatus,
    code: searchParams.code,
    orderGroupCode: searchParams.groupCode,
    productGroupName: searchParams.productGroupName,
    vendorName: searchParams.vendorName,
    brandName: searchParams.brandName,
    marketName: searchParams.marketName,
    startOrderAmount: searchParams.startOrderAmount
      ? Number(searchParams.startOrderAmount)
      : undefined,
    endOrderAmount: searchParams.endOrderAmount
      ? Number(searchParams.endOrderAmount)
      : undefined,
    orderTypes: searchParams.orderTypes
      ? searchParams.orderTypes
      : (['PRODUCT', 'EVENT'] as EOrderType[]),
  };

  const { data, loading } = useAdminOrdersQuery({
    variables: {
      pagination: {
        skip: currentPage * pageSize,
        take: pageSize,
      },
      filter: filterParams,
      order: {
        groupId: 'DESC',
        createdAt: 'DESC',
        claims: {
          createdAt: 'DESC',
        },
      },
    },
  });

  const dataSource = useMemo(() => {
    if (!data) return [];

    return getOrdersTableData(data);
  }, [data]);

  useEffect(() => {
    setDataSourceState(dataSource);
  }, [dataSource]);

  const total = data?.adminOrders?.totalCount;

  const isNoResult = !loading && data?.adminOrders?.edges.length === 0;

  return {
    dataSourceState,
    total,
    loading,
    pageSize,
    isNoResult,
    params: filterParams,
  };
}
