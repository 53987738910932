import * as Types from '../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VendorsInSelectFieldQueryVariables = Types.Exact<{
  vendorsInput: Types.VendorsInput;
}>;


export type VendorsInSelectFieldQuery = { __typename?: 'Query', adminVendors: { __typename?: 'VendorsPayload', vendors: Array<{ __typename?: 'VendorGraphqlType', id: number, name: string }> } };


export const VendorsInSelectFieldDocument = gql`
    query VendorsInSelectField($vendorsInput: VendorsInput!) {
  adminVendors(vendorsInput: $vendorsInput) {
    vendors {
      id
      name
    }
  }
}
    `;

/**
 * __useVendorsInSelectFieldQuery__
 *
 * To run a query within a React component, call `useVendorsInSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsInSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsInSelectFieldQuery({
 *   variables: {
 *      vendorsInput: // value for 'vendorsInput'
 *   },
 * });
 */
export function useVendorsInSelectFieldQuery(baseOptions: Apollo.QueryHookOptions<VendorsInSelectFieldQuery, VendorsInSelectFieldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorsInSelectFieldQuery, VendorsInSelectFieldQueryVariables>(VendorsInSelectFieldDocument, options);
      }
export function useVendorsInSelectFieldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorsInSelectFieldQuery, VendorsInSelectFieldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorsInSelectFieldQuery, VendorsInSelectFieldQueryVariables>(VendorsInSelectFieldDocument, options);
        }
export type VendorsInSelectFieldQueryHookResult = ReturnType<typeof useVendorsInSelectFieldQuery>;
export type VendorsInSelectFieldLazyQueryHookResult = ReturnType<typeof useVendorsInSelectFieldLazyQuery>;
export type VendorsInSelectFieldQueryResult = Apollo.QueryResult<VendorsInSelectFieldQuery, VendorsInSelectFieldQueryVariables>;