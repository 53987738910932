import { Spin } from 'antd';

import HomeContents from '../domain/home/compoments/HomeContents';
import { useHomeQuery } from '../domain/home/graphql/home.generated';

const SUBTRACT_FROM_HEIGHT = 134; // Header height(64) + Content Padding(16 + 16) + BreadCrumb height(22) + BreadCrumb mb(16)

export default function Main() {
  const { data, loading } = useHomeQuery();
  const content = data?.adminHomeContents ?? '';

  return (
    <div
      className={`flex min-h-[calc(100vh-${SUBTRACT_FROM_HEIGHT}px)] w-full justify-start bg-white p-2 appMinWidth:justify-center`}
    >
      {loading && <Spin className='w-full' />}
      {!loading && <HomeContents content={content} />}
    </div>
  );
}
