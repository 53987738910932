import { Tooltip, Typography } from 'antd';

type SettlementListTableNameCellProps = {
  name: string;
  id?: number | null;
  ellipsisRows?: number;
};

function SettlementListTableNameCell({
  name,
  id,
  ellipsisRows = 1,
}: SettlementListTableNameCellProps) {
  return (
    <>
      <Tooltip title={name} placement='bottomLeft' arrow={false}>
        <Typography.Paragraph
          ellipsis={{ rows: ellipsisRows }}
          className='mb-0'
        >
          {name}
        </Typography.Paragraph>
      </Tooltip>
      {id && `(${id})`}
    </>
  );
}

export default SettlementListTableNameCell;
