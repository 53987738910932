import { QuestionCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { isNumber } from 'lodash-es';
import { useState } from 'react';

import {
  APP_HEADER_HEIGHT,
  TABLE_ACTION_HEADER_HEIGHT,
} from '../../../../theme/const';
import OrderDetailModal from '../../../order/component/OrderDetailModal';
import {
  SETTLEMENT_DETAIL_TYPE_LABEL,
  SETTLEMENT_SALES_CHANNEL_LABEL,
  SETTLEMENT_TYPE_LABEL,
} from '../../const';
import useEditSettlementListFields from '../../hooks/useEditSettlementListFields';
import useSettlementList from '../../hooks/useSettlementList';
import { SettlementListTableDataType } from '../../types';

import CommentEditableCell from './CommentEditableCell';
import SettlementAmountCell from './SettlementAmountCell';
import SettlementListTableHeader from './SettlementListTableHeader';
import SettlementListTableNameCell from './SettlementListTableNameCell';
import SettlementListTableSummaryRow from './SettlementListTableSummaryRow';
import SettlementSheetsCell from './SettlementSheetsCell';

function SettlementListTable() {
  const { selectedRows, dataSource, pagination, rowSelection } =
    useSettlementList();
  const { editSellerAmount, editVendorAmount, editDeliveryAmount } =
    useEditSettlementListFields();

  const [openOrderDetailModal, setOpenOrderDetailModal] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<number | undefined>();

  const handleOrderCodeClick = (orderId: number) => {
    setOpenOrderDetailModal(true);
    setCurrentOrderId(orderId);
  };

  return (
    <>
      <SettlementListTableHeader selectedRows={selectedRows} />
      <Table<SettlementListTableDataType>
        rowSelection={rowSelection}
        scroll={{ x: 4778 }}
        dataSource={dataSource}
        sticky={{
          offsetHeader: APP_HEADER_HEIGHT + TABLE_ACTION_HEADER_HEIGHT,
        }}
        pagination={pagination}
        summary={() => (
          // fixed='top'옵션이 동작하지 않아 Table.Summary로 감쌈
          <Table.Summary fixed='top'>
            <SettlementListTableSummaryRow />
          </Table.Summary>
        )}
      >
        <Column
          title='판매채널'
          width={170}
          render={(_, record) => {
            return SETTLEMENT_SALES_CHANNEL_LABEL[record.salesChannel];
          }}
        />
        <Column title='셀러명' width={240} dataIndex={'sellerName'} />
        <Column
          title='마켓명 (마켓ID)'
          width={240}
          render={(_, record) => (
            <SettlementListTableNameCell
              name={record.marketName}
              id={record.marketId}
            />
          )}
        />
        <Column
          title='업체명 (업체ID)'
          width={240}
          render={(_, record) => (
            <SettlementListTableNameCell
              name={record.vendorName}
              id={record.vendorId}
            />
          )}
        />
        <Column
          title='브랜드명'
          width={240}
          dataIndex={'brandName'}
          render={(_, record) => {
            if (record.settlementType === 'DEPOSIT') return '-';

            return record.brandName;
          }}
        />
        <Column title='상품명' width={240} dataIndex={'productGroupName'} />
        <Column
          title='정산서코드'
          width={240}
          render={(_, { id, sheets }) => {
            if (!sheets?.length) return '-';

            return <SettlementSheetsCell settlementId={id} sheets={sheets} />;
          }}
        />
        <Column
          title='와이어드주문코드'
          width={180}
          dataIndex={'orderGroupCode'}
        />
        <Column
          title='와이어드 품목주문코드'
          width={180}
          dataIndex={'orderCode'}
          render={(_, { orderId, orderCode }) => {
            if (!orderId) return '-';

            return (
              <Typography.Paragraph
                className='m-0 cursor-pointer text-primary'
                onClick={() => handleOrderCodeClick(orderId)}
              >
                {orderCode}
              </Typography.Paragraph>
            );
          }}
        />
        <Column
          title='출고코드/입금코드'
          width={180}
          dataIndex={'promotionReleaseCode'}
        />
        <Column title='수령자명' width={120} dataIndex={'receiverName'} />
        <Column
          title='구분'
          width={140}
          render={(_, record) => {
            return SETTLEMENT_TYPE_LABEL[record.settlementType];
          }}
        />
        <Column
          title='구분상세'
          width={140}
          render={(_, record) => {
            return SETTLEMENT_DETAIL_TYPE_LABEL[record.settlementDetailType];
          }}
        />
        <Column
          title='매출액'
          width={140}
          render={(_, { salesAmount }) => salesAmount.toLocaleString()}
        />
        <Column
          title='매출일시'
          width={170}
          render={(_, record) => {
            return dayjs(record.soldAt).format('YYYY-MM-DD HH:mm:ss');
          }}
        />
        <Column
          title='품목명'
          width={320}
          dataIndex={'productName'}
          render={(_, record) => {
            if (record.settlementType === 'DELIVERY_FEE') {
              return '-';
            }

            return record.productName;
          }}
        />
        <Column
          title='수량'
          width={120}
          render={(_, record) => record.productQuantity ?? '-'}
        />
        <Column
          title='셀러정산금액'
          width={200}
          render={(_, { id, sellerSettlementAmount, sheets }) => (
            <SettlementAmountCell
              hasSheet={!!sheets?.length}
              amount={sellerSettlementAmount}
              onEdit={async (amount) => {
                await editSellerAmount(id, amount);
              }}
            />
          )}
        />
        <Column
          title='업체정산금액'
          width={200}
          render={(_, { id, vendorSettlementAmount, sheets }) => (
            <SettlementAmountCell
              hasSheet={!!sheets?.length}
              amount={vendorSettlementAmount}
              onEdit={async (amount) => {
                await editVendorAmount(id, amount);
              }}
            />
          )}
        />
        <Column
          title='물류부담금액'
          width={200}
          render={(_, { id, deliveryBearingAmount, sheets }) => (
            <SettlementAmountCell
              hasSheet={!!sheets?.length}
              amount={deliveryBearingAmount}
              onEdit={async (amount) => {
                await editDeliveryAmount(id, amount);
              }}
            />
          )}
        />
        <Column
          title={
            <>
              <span className='mr-1'>생산원가</span>
              <Tooltip
                title={'*PB=자사매입가'}
                placement='bottomLeft'
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          }
          width={200}
          render={(_, { productionAmount }) =>
            isNumber(productionAmount) ? productionAmount.toLocaleString() : '-'
          }
        />
        <Column
          title='자사마진'
          width={200}
          render={(_, { inHouseMargin }) => {
            if (inHouseMargin === null)
              return <span className='text-[#FF4D4F]'>모름</span>;

            return isNumber(inHouseMargin)
              ? inHouseMargin.toLocaleString()
              : '-';
          }}
        />
        <Column
          title='발송일시'
          width={170}
          render={(_, { releasedAt }) =>
            releasedAt ? dayjs(releasedAt).format('YYYY-MM-DD HH:mm:ss') : '-'
          }
        />
        <Column
          title='결제수단'
          width={140}
          dataIndex={'paymentMethod'}
          render={(_, { settlementType, paymentMethod }) => {
            if (settlementType === 'PRODUCT_ORDER') return paymentMethod;
            if (settlementType === 'DELIVERY_FEE') return paymentMethod;

            return '-';
          }}
        />
        <Column
          title='비고'
          width={240}
          render={(_, { id, comment, sheets }) => (
            <CommentEditableCell
              id={id}
              comment={comment}
              hasSheet={!!sheets?.length}
            />
          )}
        />
      </Table>
      {openOrderDetailModal && currentOrderId && (
        <OrderDetailModal
          id={currentOrderId}
          onClose={() => {
            setOpenOrderDetailModal(false);
            setCurrentOrderId(undefined);
          }}
        />
      )}
    </>
  );
}

type TableColumnsProps<RecordType> = ColumnProps<RecordType>;

function Column(props: TableColumnsProps<SettlementListTableDataType>) {
  return <Table.Column {...props} />;
}

export default SettlementListTable;
