import { useApolloClient } from '@apollo/client';
import { App, Select, Spin } from 'antd';
import { useState } from 'react';

import {
  extractBadRequestError,
  removeCache,
} from '../../../common/utils/apollo';
import { SELLER_GRADES } from '../const';
import { useUpdateSellerPartialMutation } from '../graphql/sellers.generated';

interface EditableGradeSelectCellProps {
  id: number;
  initialValue: number | null;
}

function EditableGradeSelectCell({
  initialValue,
  id,
}: EditableGradeSelectCellProps) {
  const { message } = App.useApp();

  const client = useApolloClient();
  const [updateSellerPartial, { loading }] = useUpdateSellerPartialMutation();

  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const label =
    SELLER_GRADES.find((option) => option.value === value)?.label || '';

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async (value: number) => {
    setValue(value);

    const close = message.loading('처리 중입니다.');

    await updateSellerPartial({
      variables: { updateSellerPartialInput: { id, grade: value } },
      onCompleted() {
        close();
        message.success('등급이 업데이트되었습니다.');
        toggleEdit();
      },
      onError({ graphQLErrors }) {
        close();

        const noSellerError = extractBadRequestError(graphQLErrors);

        if (noSellerError) {
          message.error(noSellerError.message);

          removeCache(client.cache, {
            id,
            __typename: 'SellerGraphqlType',
          });
        } else {
          message.error('수정을 실패하였습니다.');
        }
      },
    });
  };

  if (editing) {
    return (
      <Spin spinning={loading} size={'small'}>
        <Select
          ref={(ref) => {
            ref?.focus();
          }}
          onBlur={toggleEdit}
          onChange={save}
          value={value}
          className={'w-full'}
          options={SELLER_GRADES}
        />
      </Spin>
    );
  }

  return (
    <div
      className='editable-cell min-h-[30px] rounded-lg px-lg py-sm hover:cursor-pointer'
      style={{ paddingRight: 24 }}
      onClick={toggleEdit}
    >
      {label || '-'}
    </div>
  );
}

export default EditableGradeSelectCell;
