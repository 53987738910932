import { InboxOutlined } from '@ant-design/icons';
import { Upload, UploadFile } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { useEffect, useState } from 'react';

const { Dragger } = Upload;

type FileDnDUploadProps = {
  onChange: (fileList: UploadFile[]) => void;
  fileList: UploadFile[];
  maxCount?: number;
  multiple?: boolean;
  accept?: string;
  description?: string;
};

export default function FileDnDUpload(props: FileDnDUploadProps) {
  const { maxCount = 50, multiple, accept, description } = props;

  const [fileList, setFileList] = useState<UploadFile[]>(props.fileList);

  useEffect(() => {
    setFileList(props.fileList);
  }, [props.fileList]);

  const handleRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleBeforeUpload = (file: RcFile) => {
    setFileList((fileList) => {
      const fileListCopy = fileList.slice();
      const newFileList = [...fileListCopy, file];

      return newFileList;
    });

    return false;
  };

  const handleChange = ({ fileList }: UploadChangeParam<UploadFile>) => {
    props.onChange(fileList);
  };

  return (
    <Dragger
      multiple={multiple}
      fileList={fileList}
      onRemove={handleRemove}
      beforeUpload={handleBeforeUpload}
      onChange={handleChange}
      maxCount={maxCount}
      accept={accept}
      className='[&_.ant-upload-list-item]:text-[#1677ff]'
    >
      <p className='ant-upload-drag-icon'>
        <InboxOutlined />
      </p>
      <p className='ant-upload-text'>
        드래그앤 드롭 또는 클릭하여 업로드해주세요.
      </p>
      <p className='ant-upload-hint'>{description}</p>
    </Dragger>
  );
}
