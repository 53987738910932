import { DownOutlined } from '@ant-design/icons';
import { App, Button, Dropdown, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import PageLoader from '../../../common/components/PageLoader';
import { EOrderClaimType } from '../../../schema/types';
import { CREATE_CLAIM_TARGET_STATUS } from '../const';
import { useAdminOrderGroupQuery } from '../graphql/createClaim.generated';
import { usePossibleClaim } from '../hooks/usePossibleClaim';

import CreateClaimModal from './CreateClaimModal';
import CreateOrderSupportModal from './OrderSupport/CreateOrderSupportModal';

function ClaimActionDropdown({
  disabled,
  orderId,
  orderGroupId,
}: {
  disabled: boolean;
  orderId?: number;
  orderGroupId: number;
}) {
  const { message } = App.useApp();

  const [openCreateClaimModalType, setOpenCreateClaimModalType] =
    useState<null | EOrderClaimType>(null);
  const [openCreateOrderSupportModal, setOpenCreateOrderSupportModal] =
    useState(false);

  const { data, loading } = useAdminOrderGroupQuery({
    variables: { adminOrderGroupId: orderGroupId },
    skip:
      !orderGroupId ||
      (!openCreateClaimModalType && !openCreateOrderSupportModal),
  });

  const currentOrder = data?.adminOrderGroup.orders.find(
    (order) => order.id === orderId
  );

  usePossibleClaim({
    order: currentOrder,
    type: openCreateClaimModalType,
    status: openCreateClaimModalType
      ? CREATE_CLAIM_TARGET_STATUS[openCreateClaimModalType]
      : null,
    onReject(reason) {
      message.error(reason);
      setOpenCreateClaimModalType(null);
    },
  });

  useEffect(() => {
    if (
      openCreateOrderSupportModal &&
      currentOrder &&
      currentOrder.orderType !== 'PRODUCT'
    ) {
      message.error('상품주문만 응대요청이 가능합니다.');
      setOpenCreateOrderSupportModal(false);
    }
  }, [openCreateOrderSupportModal, currentOrder, message]);

  return (
    <Tooltip
      trigger={disabled ? 'hover' : []}
      title={'1개의 와이어드 품목주문코드만 처리가능합니다.'}
    >
      <Dropdown
        menu={{
          className: 'text-center',
          items: [
            {
              label: '반품접수',
              key: 'RETURN',
            },
            {
              label: '교환접수',
              key: 'EXCHANGE',
            },
            {
              label: '취소처리',
              key: 'CANCEL',
            },
            {
              label: '응대관리',
              key: 'ORDER_SUPPORT_MANAGEMENT',
            },
            // 부분시리즈 스펙아웃: https://www.notion.so/wiredcompany/12-22-CS-Event-c4dc56459462406fbe70d47fb806e837
            // {
            //   label: '부분반품',
            //   key: 'PARTIAL_RETURN',
            // },
            // {
            //   label: '부분교환',
            //   key: 'PARTIAL_EXCHANGE',
            // },
            // {
            //   label: '부분취소',
            //   key: 'PARTIAL_CANCEL',
            // },
          ],
          onClick(e) {
            if (!e) return;
            if (e.key === 'ORDER_SUPPORT_MANAGEMENT') {
              setOpenCreateOrderSupportModal(true);
              return;
            }

            const type = e.key as EOrderClaimType;

            setOpenCreateClaimModalType(type);
          },
        }}
        disabled={disabled}
      >
        <Button>
          <DownOutlined />
          CS처리
        </Button>
      </Dropdown>
      {openCreateClaimModalType && orderId && data?.adminOrderGroup && (
        <CreateClaimModal
          type={openCreateClaimModalType}
          order={currentOrder}
          orderGroup={data?.adminOrderGroup}
          onCancel={() => setOpenCreateClaimModalType(null)}
        />
      )}
      {data?.adminOrderGroup && openCreateOrderSupportModal && (
        <CreateOrderSupportModal
          order={currentOrder}
          orderGroup={data?.adminOrderGroup}
          onCancel={() => setOpenCreateOrderSupportModal(false)}
        />
      )}
      {loading && <PageLoader />}
    </Tooltip>
  );
}

export default ClaimActionDropdown;
