import { Checkbox, DatePicker, InputRef } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useEffect, useRef } from 'react';

type MarketRangePickerProps = Omit<RangePickerProps, 'value' | 'onChange'> & {
  value: [string | null, string | null];
  onChange: (value: [string | null, string | null]) => void;
  errorMessages?: [string | undefined, string | undefined];
};

function MarketRangePicker({
  value,
  onChange,
  errorMessages,
  ...props
}: MarketRangePickerProps) {
  const ref = useRef<InputRef>(null);

  const [startAt, endAt] = value;
  const [startErrorMessage, endErrorMessage] = errorMessages || [];

  const hasError = !!startErrorMessage || !!endErrorMessage;
  const handleCheckBoxChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    if (checked) {
      onChange([startAt, null]);
    } else {
      onChange([startAt, dayjs(startAt).add(7, 'day').toISOString()]);
    }
  };

  useEffect(() => {
    if (hasError && ref.current) {
      ref.current.focus();
    }
  }, [hasError]);

  return (
    <div>
      <DatePicker.RangePicker
        changeOnBlur
        inputReadOnly
        allowClear={false}
        allowEmpty={[false, true]}
        renderExtraFooter={() => (
          <div className={'flex justify-end'}>
            <Checkbox
              className={'float-right px-md py-lg'}
              checked={!endAt}
              onChange={handleCheckBoxChange}
            >
              상시
            </Checkbox>
          </div>
        )}
        onChange={(v) => {
          if (!v) {
            onChange([null, null]);
            return;
          }

          const [startAt, endAt] = v;

          onChange([
            startAt?.startOf('date').toISOString() || null,
            endAt?.endOf('date').toISOString() || null,
          ]);
        }}
        value={[startAt ? dayjs(startAt) : null, endAt ? dayjs(endAt) : null]}
        className={'w-full'}
        placeholder={['시작일', '상시']}
        {...props}
      />

      {startErrorMessage && (
        <p className={'text-xs text-red-500'}>{startErrorMessage}</p>
      )}
      {endErrorMessage && (
        <p className={'text-xs text-red-500'}>{endErrorMessage}</p>
      )}
    </div>
  );
}

export default MarketRangePicker;
