import { ExclamationCircleFilled } from '@ant-design/icons';
import { App } from 'antd';
import { useCallback } from 'react';

function useCancelConfirmModal() {
  const { modal } = App.useApp();
  const openCancelComfirmModal = useCallback(
    (onOk: () => void) => {
      modal.confirm({
        icon: <ExclamationCircleFilled />,
        title: '작성 취소',
        content: '변경사항이 저장되지 않습니다.\n작성을 취소하시겠습니까?',
        onOk,
        okText: '네',
        cancelText: '아니오',
        centered: true,
      });
    },
    [modal]
  );

  return openCancelComfirmModal;
}

export default useCancelConfirmModal;
