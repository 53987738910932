import { Skeleton, Typography } from 'antd';
import { useMemo } from 'react';

import SummaryCard from '../../../common/components/SummaryCard';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { EPromotionReleaseStatus, ESampleStatus } from '../../../schema/types';
import { RELEASE_STATUS_LABEL, SAMPLE_STATUS_LABEL } from '../const';
import { useAdminCountPromotionStatusesQuery } from '../graphql/promotionRelease.generated';
import { PromotionReleaseListSearchParams } from '../types';

const RELEASE_STATUS: EPromotionReleaseStatus[] = [
  'RELEASE_REQUEST',
  'ORDER_CONFIRMED',
  'RELEASE_SCHEDULED',
  'RELEASE_HOLD',
];

const SAMPLE_STATUS: ESampleStatus[] = [
  'TEST_PENDING',
  'TEST_INPROGRESS',
  'MARKET_CONFIRMED',
  'NO_RESPONSE',
];

function Summary() {
  const { searchParams, replaceSearchParams } =
    useRouteSearchParams<PromotionReleaseListSearchParams>();
  const promotionType = searchParams.promotionType || 'ALL';
  const isSample = promotionType === 'SAMPLE';
  const { data } = useAdminCountPromotionStatusesQuery({
    variables: { promotionType },
  });
  const { summaryReleaseStatus, summarySampleStatus } = searchParams;

  const releaseCountsOfStatus = useMemo(
    () =>
      data?.adminCountPromotionStatuses.countReleaseStatuses?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.status]: cur.count,
        }),
        {} as { [k in EPromotionReleaseStatus]: number }
      ),
    [data]
  );

  const sampleCountsOfStatus = useMemo(
    () =>
      data?.adminCountPromotionStatuses.countSampleStatuses?.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.status]: cur.count,
        }),
        {} as { [k in ESampleStatus]: number }
      ),
    [data]
  );

  return (
    <>
      <Typography.Title level={5}>출고현황</Typography.Title>
      <div className='grid grid-cols-4 gap-4'>
        {RELEASE_STATUS.map((status) =>
          releaseCountsOfStatus ? (
            <SummaryCard
              key={status}
              label={RELEASE_STATUS_LABEL[status]}
              count={releaseCountsOfStatus[status]}
              active={summaryReleaseStatus === status}
              color='blue'
              onClick={() =>
                replaceSearchParams({
                  promotionType,
                  summaryReleaseStatus: status,
                })
              }
            />
          ) : (
            <Skeleton.Button key={status} className='h-16 w-full' active />
          )
        )}
      </div>
      {isSample && (
        <>
          <Typography.Title level={5} className='mt-6'>
            샘플현황
          </Typography.Title>
          <div className='grid grid-cols-4 gap-4'>
            {SAMPLE_STATUS.map((status) =>
              sampleCountsOfStatus ? (
                <SummaryCard
                  key={status}
                  label={SAMPLE_STATUS_LABEL[status]}
                  count={sampleCountsOfStatus[status]}
                  active={summarySampleStatus === status}
                  color='green'
                  onClick={() =>
                    replaceSearchParams({
                      promotionType,
                      summarySampleStatus: status,
                    })
                  }
                />
              ) : (
                <Skeleton.Button key={status} className='h-16 w-full' active />
              )
            )}
          </div>
        </>
      )}
    </>
  );
}

export default Summary;
