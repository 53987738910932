import { Select, SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useEffect, useMemo } from 'react';

import {
  CategoriesQueryVariables,
  useCategoriesLazyQuery,
} from '../../graphql/productGroup.generated';

type CategorySelectProps = SelectProps & {
  upperCategoryId?: number | null;
};

const CategorySelect = forwardRef<BaseSelectRef, CategorySelectProps>(function (
  { upperCategoryId, disabled, ...props },
  ref
) {
  const TAKE = 9999; // 모든 카테고리를 가져오기 위해 임의로 큰 값을 설정함

  const [fetchCategory, { data }] = useCategoriesLazyQuery({
    variables: {
      pagination: {
        skip: 0,
        take: TAKE,
      },
      filter: {
        upperCategoryId,
      },
    } as CategoriesQueryVariables,
  });

  const categories = useMemo(
    () => data?.adminCategories.edges,
    [data?.adminCategories.edges]
  );

  const options = useMemo(
    () =>
      categories?.map(({ node }) => ({
        value: node.id,
        label: node.name,
      })),
    [categories]
  );

  useEffect(() => {
    if (upperCategoryId) {
      fetchCategory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upperCategoryId]);

  return (
    <Select
      ref={ref}
      options={options}
      disabled={disabled || !upperCategoryId}
      {...props}
      showSearch
      filterOption={(input, option) =>
        ((option?.label as string) ?? '').includes(input)
      }
    />
  );
});

export default CategorySelect;
