import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import SelectField from '../../../../common/components/Fields/SelectField';
import { useAdminMarketEventProductQuery } from '../../graphql/products.generated';
import { CreateMarketFormValues, UpdateMarketFormValues } from '../../types';

function EventProductItemsSelectField({
  eventIndex,
  productIndex,
  editable,
}: {
  eventIndex: number;
  productIndex: number;
  editable?: boolean;
}) {
  const { control, watch } = useFormContext<
    CreateMarketFormValues | UpdateMarketFormValues
  >();

  const product = watch(`events.${eventIndex}.products.${productIndex}`);

  const { data } = useAdminMarketEventProductQuery({
    variables: {
      adminProductId: product.productId ? product.productId : 0,
    },
    skip: !product.productId,
  });

  const isSelectType = product.useType === 'SELECT';
  const items = useMemo(
    () =>
      (isSelectType ? data?.adminProduct?.items || [] : []).map((item) => ({
        value: item.id,
        label: item.name,
      })),
    [data?.adminProduct?.items, isSelectType]
  );

  if (!isSelectType) {
    return <span>-</span>;
  }
  return (
    <SelectField
      mode='multiple'
      disabled={!editable}
      control={control}
      name={`events.${eventIndex}.products.${productIndex}.itemIds`}
      options={items}
      placeholder={'옵션선택'}
      maxTagCount={'responsive'}
      maxTagTextLength={10}
    />
  );
}

export default EventProductItemsSelectField;
