import dayjs from 'dayjs';
import { flatMap, groupBy } from 'lodash-es';

import { getOrderClaimStatus } from '../cs/helper/create';

import {
  PAYMENT_METHOD_TEXT,
  SALES_CHANNEL_TEXT,
  SALES_CHANNEL_TYPE_TEXT,
} from './const';
import { AdminOrdersQuery } from './graphql/orders.generated';
import { OrderTableDataType } from './types';

export const getOrdersTableData = (data: AdminOrdersQuery) => {
  const tableData = data.adminOrders?.edges.reduce((acc, { node }, index) => {
    const { status, claimType } = getOrderClaimStatus(node);

    const data = {
      key: index,
      id: node.id,
      groupId: node.groupId,
      code: node.code,
      salesChannelOrderCode: node.salesChannelOrderCode ?? '',
      groupCode: node.group.code,
      salesChannelOrderGroupCode: node.group.salesChannelOrderGroupCode ?? '',
      status,
      claimType,
      marketName: node.group.marketName ?? '',
      marketId: node.group.marketId ?? undefined,
      vendorName: node.group.vendorName ?? '',
      brandName: node.group.brandName ?? '',
      productGroupName: node.productGroupName,
      productName: node.productName,
      salesChannelProductName: node.salesChannelProductName ?? '',
      productUseType: node.productUseType,
      items: node.items?.map((item) => item.name) || [],
      productQuantity: node.productQuantity,
      orderAmount: node.orderAmount,
      orderType: node.orderType,
      marketEventId: node.marketEventId ?? undefined,
      bundleDeliveryCode: node.bundleDeliveryCode,
      deliveryFee: node.deliveryFee ?? 0,
      regionDeliveryFee: node.regionDeliveryFee ?? 0,
      extraDiscount: node.extraDiscount ?? 0,
      deliveryCompanyCode: node.deliveryCompanyCode ?? '',
      deliveryCompanyName: node.deliveryCompanyName ?? '',
      trackingNumber: node.trackingNumber ?? '',
      receiverName: node.receiverName,
      receiverPhoneNumber: node.receiverPhoneNumber,
      receiverAddress: node.receiverAddress,
      receiverDetailAddress: node.receiverDetailAddress ?? '',
      receiverZipCode: node.receiverZipCode,
      receiverDeliveryMemo: node.receiverDeliveryMemo ?? '',
      ordererName: node.group.ordererName ?? '',
      ordererPhoneNumber: node.group.ordererPhoneNumber ?? '',
      paymentMethod: node.group.paymentMethod
        ? PAYMENT_METHOD_TEXT[node.group.paymentMethod]
        : '',
      salesChannelType: SALES_CHANNEL_TYPE_TEXT[node.salesChannelType],
      salesChannel: SALES_CHANNEL_TEXT[node.salesChannel],
      pointDiscount: node.pointDiscount ?? 0,
      orderedAt: convertDate(node.group.orderedAt),
      paidAt: convertDate(node.group.paidAt),
      orderConvertedAt: convertDate(node.orderConvertedAt),
      updatedAt: convertDate(node.updatedAt),
      rowSpan: 0,
      isFirstProduct: false,
      siblings: [],
    };

    acc.push(data);

    return acc;
  }, [] as OrderTableDataType[]);

  const groupedData = groupBy(tableData, 'groupCode');
  const flattenedData = flatMap(groupedData, (group) =>
    group.map((data, index) => ({
      ...data,
      rowSpan: index === 0 ? group.length : 0,
      isFirstProduct: group.length > 1 && index === 0,
      siblings: group.map((sibling) => ({
        id: sibling.id,
        key: sibling.key,
        groupId: sibling.groupId,
        marketId: sibling.marketId,
      })),
    }))
  );

  return flattenedData;
};

export const convertDate = (date?: string | null) => {
  if (!date) return '-';

  return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
};

export const convertDeliveryCompanyCode = (trackingNumber: string | number) => {
  if (Number(trackingNumber) < 10) {
    return String(trackingNumber).padStart(2, '0');
  }

  return String(trackingNumber);
};
