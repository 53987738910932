import { PropsWithChildren } from 'react';

import Label from './Label';
import { FieldProps } from './types';

function FieldWithLabel({
  children,
  label,
  required,
  tooltip,
}: PropsWithChildren<FieldProps>) {
  return (
    <div className={'w-full'}>
      {label && (
        <Label
          className={'mb-2 flex items-center'}
          required={required}
          tooltip={tooltip}
        >
          {label}
        </Label>
      )}
      {children}
    </div>
  );
}

export default FieldWithLabel;
