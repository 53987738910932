import { ApolloCache } from '@apollo/client';
import { App, Button } from 'antd';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import { useMeQuery } from '../../../common/graphql/me.generated';
import { usePermissions } from '../../../common/hooks/usePermissions';
import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { deleteCacheFields } from '../../../common/utils/apollo';
import {
  useCreateTemporaryProductMutation,
  useUpdateTemporaryProductMutation,
} from '../graphql/productGroup.generated';
import { ProductGroupFormValues, ProductGroupTabType } from '../types';

type SubmitButtonsProps = {
  tabKey: ProductGroupTabType;
  onClick: () => Promise<void>;
};

const ProductGroupSummitButtons = ({ tabKey, onClick }: SubmitButtonsProps) => {
  const { message } = App.useApp();
  const { hasPermission } = usePermissions('PRODUCT_EDIT');
  const { data: user } = useMeQuery();

  const { getValues, control } = useFormContext<ProductGroupFormValues>();
  const name = useWatch({ control, name: 'name' });
  const { dirtyFields, isSubmitting } = useFormState();

  const isDirty = !isEmpty(dirtyFields);
  const formType = getValues('formType');
  const isUpdate = formType === 'UPDATE';

  const [createMutate] = useCreateTemporaryProductMutation();
  const [updateMutate] = useUpdateTemporaryProductMutation();

  const { searchParams, setSearchParams } = useRouteSearchParams<{
    temporaryId: string | number;
  }>();

  const handleTemporarySave = async () => {
    const managerName = user?.adminMe.name || '';
    const name = getValues('name');
    const values = getValues();
    const json = JSON.stringify(values);

    const deleteCache = (cache: ApolloCache<any>) => {
      deleteCacheFields(cache, ['adminTemporaryProducts']);
    };

    const variables = {
      input: { json, managerName, name },
    };

    let temporaryId;

    if (searchParams?.temporaryId) {
      const { data } = await updateMutate({
        variables: {
          input: {
            ...variables.input,
            id: Number(searchParams.temporaryId),
          },
        },
        update: deleteCache,
      });

      temporaryId = data?.adminUpdateTemporaryProduct.temporaryProduct.id;
    } else {
      const { data } = await createMutate({
        variables,
        update: deleteCache,
      });
      temporaryId = data?.adminCreateTemporaryProduct.temporaryProduct.id;
    }

    setSearchParams({ temporaryId });

    message.success('임시저장 되었습니다.');
  };

  const isTemporary = !!searchParams?.temporaryId;
  const disabled = useMemo(() => {
    if (hasPermission && isTemporary) return false;

    return !hasPermission || !isDirty || !name;
  }, [hasPermission, isDirty, isTemporary, name]);

  return (
    <div className='flex gap-2'>
      {!isUpdate && (
        <Button
          htmlType='button'
          disabled={disabled || !name.trim()}
          onClick={handleTemporarySave}
        >
          임시저장
        </Button>
      )}
      {tabKey === 'product' && !isUpdate && (
        <Button
          type='primary'
          htmlType='button'
          onClick={onClick}
          disabled={disabled}
        >
          다음
        </Button>
      )}
      {(tabKey === 'price' || isUpdate) && (
        <Button
          type='primary'
          htmlType='submit'
          loading={isSubmitting}
          disabled={disabled}
        >
          저장
        </Button>
      )}
    </div>
  );
};

export default ProductGroupSummitButtons;
