import { App } from 'antd';

import { UpdateSettlementInputGraphqlType } from '../../../schema/types';
import { AdminCountMarketsForScheduledSettlementDocument } from '../graphql/markets.generated';
import { useAdminUpdateSettlementMutation } from '../graphql/settlement.generated';
import {
  AdminSettlementsDocument,
  AdminTotalCountSettlementsDocument,
} from '../graphql/settlements.generated';
import { AdminCountVendorsForScheduledSettlementDocument } from '../graphql/vendors.generated';

function useEditSettlementListFields() {
  const { message } = App.useApp();
  const [mutate] = useAdminUpdateSettlementMutation();

  const refetchQueries = [
    AdminSettlementsDocument,
    AdminCountMarketsForScheduledSettlementDocument,
    AdminCountVendorsForScheduledSettlementDocument,
    AdminTotalCountSettlementsDocument,
  ];

  const mutateUpdateSettlement = async ({
    id,
    updateSettlementData,
    successMessage,
  }: {
    id: number;
    updateSettlementData: UpdateSettlementInputGraphqlType;
    successMessage: string;
  }) => {
    await mutate({
      variables: {
        adminUpdateSettlementId: id,
        updateSettlementData,
      },
      refetchQueries,
      onError({ message: errorMessage }) {
        void message.error(errorMessage);
      },
      onCompleted() {
        void message.success(successMessage);
      },
    });
  };

  const editSellerAmount = async (id: number, amount: number) => {
    await mutateUpdateSettlement({
      id,
      updateSettlementData: {
        sellerSettlementAmount: amount,
      },
      successMessage: '셀러정산금액이 수정되었습니다.',
    });
  };

  const editVendorAmount = async (id: number, amount: number) => {
    await mutateUpdateSettlement({
      id,
      updateSettlementData: {
        vendorSettlementAmount: amount,
      },
      successMessage: '업체정산금액이 수정되었습니다.',
    });
  };

  const editDeliveryAmount = async (id: number, amount: number) => {
    await mutateUpdateSettlement({
      id,
      updateSettlementData: {
        deliveryBearingAmount: amount,
      },
      successMessage: '물류정산금액이 수정되었습니다.',
    });
  };

  const editComment = async (id: number, comment: string) => {
    await mutateUpdateSettlement({
      id,
      updateSettlementData: {
        comment,
      },
      successMessage: '비고가 수정되었습니다.',
    });
  };

  return {
    editSellerAmount,
    editVendorAmount,
    editDeliveryAmount,
    editComment,
  };
}

export default useEditSettlementListFields;
