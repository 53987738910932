import { Divider } from 'antd';
import { useFormContext } from 'react-hook-form';

import SelectField from '../../../common/components/Fields/SelectField';
import TextareaField from '../../../common/components/Fields/TextareaField';
import { usePermissions } from '../../../common/hooks/usePermissions';
import { RETURN_REASON_OPTIONS } from '../const';
import { CreateClaimFormValues } from '../type';

import WiredOwnDeliveryFaultCheckboxField from './WiredOwnDeliveryFaultCheckboxField';

function ReturnForm() {
  const { hasPermission } = usePermissions(['ORDER_EDIT']);

  const { control, setValue } = useFormContext<CreateClaimFormValues>();

  return (
    <div className={'grid gap-6'}>
      <Divider orientation={'left'} className={'m-0'}>
        반품사유
      </Divider>
      <div className={'w-[232px]'}>
        <SelectField
          required
          disabled={!hasPermission}
          label={'반품사유'}
          control={control}
          name={'reasonType'}
          options={RETURN_REASON_OPTIONS}
          onChange={(_, option) => {
            if (!Array.isArray(option)) {
              const target = option.data;

              setValue('target', target, {
                shouldTouch: true,
                shouldDirty: true,
                shouldValidate: true,
              });
            }
          }}
        />
        <WiredOwnDeliveryFaultCheckboxField />
      </div>
      <div className={'w-[472px]'}>
        <TextareaField
          disabled={!hasPermission}
          control={control}
          name={'reason'}
          label={'상세사유'}
          placeholder={'내용입력'}
        />
      </div>
    </div>
  );
}

export default ReturnForm;
