import { Typography } from 'antd';

export default function Paragraph({ children }: { children: React.ReactNode }) {
  return (
    <Typography.Paragraph
      className='mb-0 text-xs leading-5'
      ellipsis
      type={'secondary'}
    >
      {children}
    </Typography.Paragraph>
  );
}
