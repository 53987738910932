import { PlusOutlined } from '@ant-design/icons';
import { Descriptions, Divider, Typography, Button } from 'antd';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import BankAccountField from '../../../common/components/Fields/BankAccountField';
import BusinessRegistrationField from '../../../common/components/Fields/BusinessRestrationField';
import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import Label from '../../../common/components/Fields/Label';
import ManagerSelectField from '../../../common/components/Fields/ManagerSelectField';
import SelectField from '../../../common/components/Fields/SelectField';
import TextareaField from '../../../common/components/Fields/TextareaField';
import TextField from '../../../common/components/Fields/TextField';
import HistoryModal from '../../../common/components/History/HistoryModal';
import { usePermissions } from '../../../common/hooks/usePermissions';
import {
  DATE_ITEMS,
  DEFAULT_CONTACT_VALUE,
  SETTLEMENT_WARNING_DATES,
} from '../const';
import useVendorFormUpdateValues from '../hooks/useVendorFormUpdateValues';

import ContactForm from './ContactForm';

function VendorDetailForm() {
  const { hasPermission } = usePermissions('BRAND_AND_VENDOR_EDIT');
  const { method, onSubmit, vendorStatus, vendorId } =
    useVendorFormUpdateValues();
  const { control, watch, setValue } = method;
  const settlementDates = watch('settlementDates');
  const isUpdated = !isEmpty(method.formState.dirtyFields);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const showSettlementWarning = settlementDates?.some(
    (date) => date && SETTLEMENT_WARNING_DATES.includes(date)
  );
  return (
    <FormProvider {...method}>
      <form onSubmit={onSubmit}>
        <Typography.Title level={4} className={'m-0 p-lg'}>
          업체 기본정보
        </Typography.Title>
        <Descriptions bordered column={1} labelStyle={{ width: 200 }}>
          <Descriptions.Item label={<Label required>담당자</Label>}>
            <ManagerSelectField
              style={{ width: 326 }}
              control={control}
              name='managerId'
              disabled={!hasPermission}
            />
          </Descriptions.Item>
          <Descriptions.Item label={<Label required>업체명</Label>}>
            <TextField
              style={{ width: 326 }}
              control={control}
              name='name'
              disabled={!hasPermission}
              maxLength={30}
            />
          </Descriptions.Item>
          <Descriptions.Item label='거래상태'>{vendorStatus}</Descriptions.Item>
        </Descriptions>
        <Typography.Title level={4} className={'m-0 p-lg'}>
          업체 상세정보
        </Typography.Title>
        <Divider orientation='left'>업체연락처</Divider>
        <ArrayField name='vendorContacts' control={control}>
          {({ fields, append, remove, replace }) => (
            <>
              {fields.map((field, i) => (
                <ContactForm
                  key={field.key}
                  type='row'
                  control={control}
                  disabled={!hasPermission}
                  typeSelectProps={{ name: `vendorContacts.${i}.type` }}
                  nameInputProps={{ name: `vendorContacts.${i}.name` }}
                  emailInputProps={{ name: `vendorContacts.${i}.email` }}
                  phoneInputProps={{ name: `vendorContacts.${i}.phone` }}
                  onRemove={() => {
                    if (fields.length === 1) {
                      replace(DEFAULT_CONTACT_VALUE);
                    } else {
                      remove(i);
                    }
                  }}
                />
              ))}
              <Button
                className='mt-2'
                icon={<PlusOutlined />}
                onClick={() => append(DEFAULT_CONTACT_VALUE)}
                disabled={!hasPermission}
              />
            </>
          )}
        </ArrayField>

        <Divider orientation='left'>업체정산정보</Divider>
        <div className='flex w-updateFormWidth flex-col gap-6'>
          <FieldWithLabel label='정산일자 (매월)'>
            <SelectField
              className='w-full'
              mode='multiple'
              name='settlementDates'
              control={control}
              onChange={(value: string[]) => {
                if (value.length < 4)
                  setValue('settlementDates', value, {
                    shouldDirty: true,
                    shouldValidate: true,
                    shouldTouch: true,
                  });
              }}
              value={settlementDates}
              options={DATE_ITEMS}
              disabled={!hasPermission}
            />
            {showSettlementWarning && (
              <p className={'m-0 mt-1 text-xs'}>
                일부 월에 없는 날짜가 포함되어있습니다. 해당 월에는 정산내역이
                보이지 않을 수 있습니다.
              </p>
            )}
          </FieldWithLabel>
          <FieldWithLabel label='상호명'>
            <TextField
              placeholder='상호명 입력'
              name='businessName'
              control={control}
              disabled={!hasPermission}
            />
          </FieldWithLabel>
          <BusinessRegistrationField
            inputProps={{
              name: 'businessRegistrationNumber',
              disabled: !hasPermission,
            }}
            uploadProps={{
              id: 'business-registration-image',
              name: 'businessRegistrationImageUrl',
              purpose: 'VENDORS',
              disabled: !hasPermission,
            }}
            control={control}
          />
          <BankAccountField
            selectProps={{
              name: 'bankCode',
              disabled: !hasPermission,
            }}
            inputProps={{
              name: 'accountNumber',
              disabled: !hasPermission,
            }}
            uploadProps={{
              id: 'bank-account-copy-upload',
              name: 'businessBankBookImagerUrl',
              purpose: 'VENDORS',
              disabled: !hasPermission,
            }}
            control={control}
          />
          <FieldWithLabel label='예금주명'>
            <TextField
              placeholder='예금주명 입력'
              name='accountHolderName'
              control={control}
              disabled={!hasPermission}
            />
          </FieldWithLabel>
          <FieldWithLabel label='비고'>
            <TextareaField
              placeholder='내용입력'
              name='comment'
              control={control}
              disabled={!hasPermission}
            />
          </FieldWithLabel>
        </div>
        <Divider />
        <div className='flex justify-between'>
          <Button onClick={() => setHistoryModalOpen(true)}>
            변경히스토리
          </Button>
          <Button
            type='primary'
            htmlType='submit'
            disabled={!hasPermission || !isUpdated}
          >
            저장
          </Button>
        </div>
      </form>
      {vendorId && (
        <HistoryModal
          uiType='VENDOR'
          id={vendorId}
          open={historyModalOpen}
          onClose={() => setHistoryModalOpen(false)}
        />
      )}
    </FormProvider>
  );
}

export default VendorDetailForm;
