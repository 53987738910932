import { Radio } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

import ArrayField from '../../../common/components/Fields/ArrayField';
import TextField from '../../../common/components/Fields/TextField';
import {
  SellerLinkGraphqlType,
  UpdateSellerLinkGraphqlInput,
} from '../../../schema/types';
import { SELLER_LINKS_MAX_LENGTH } from '../const';

import ItemDeleteButton from './ItemDeleteButton';

type LinksFormValue = {
  links: UpdateSellerLinkGraphqlInput[] | SellerLinkGraphqlType[];
};

type LinksFieldProps = {
  disabled: boolean;
};

function LinksField({ disabled }: LinksFieldProps) {
  const methods = useFormContext<LinksFormValue>();
  const { control, formState, setValue, watch } = methods;

  const links = watch('links');
  const errorMessage = formState.errors.links?.message || '';

  return (
    <ArrayField
      required
      disabled={disabled}
      name={'links'}
      control={control}
      label={'셀러링크'}
      errorMessage={errorMessage}
      maxLength={SELLER_LINKS_MAX_LENGTH}
      addButtonProps={{
        onClick: ({ append, fields }) => {
          append(
            { url: '', isDefault: false },
            { focusName: `links.${fields.length}.url` }
          );
        },
      }}
    >
      {({ fields, remove, update }) => {
        return (
          <div className={'mb-md grid gap-md'}>
            {fields.map((field, index) => {
              const errors = formState.errors?.links?.[index];
              const link = links[index];

              return (
                <div
                  key={field.key}
                  className={`grid ${
                    fields.length > 1
                      ? 'grid-cols-textArrayField'
                      : 'grid-cols-1'
                  } gap-md`}
                >
                  <TextField
                    name={`links.${index}.url`}
                    control={control}
                    disabled={disabled}
                    placeholder={'URL 입력'}
                  />

                  {fields.length > 1 && (
                    <div className={'flex h-xxl items-center gap-md'}>
                      {!disabled && (
                        <ItemDeleteButton
                          onClick={() => {
                            if (link.isDefault) {
                              const target = index === 0 ? 1 : index - 1;

                              update(target, {
                                ...links[target],
                                isDefault: true,
                              });
                            }
                            remove(index);
                          }}
                        />
                      )}

                      <Controller
                        render={({ field: controllerField }) => (
                          <Radio
                            name={controllerField.name}
                            checked={controllerField.value as boolean}
                            className={'whitespace-nowrap'}
                            disabled={!link.url || !!errors || disabled}
                            onChange={() => {
                              setValue(
                                'links',
                                fields.map((eachField, index) => ({
                                  ...links?.[index],
                                  isDefault: eachField.key === field.key,
                                })),
                                {
                                  shouldTouch: true,
                                  shouldValidate: true,
                                  shouldDirty: true,
                                }
                              );
                            }}
                          >
                            대표
                          </Radio>
                        )}
                        name={`links.${index}.isDefault`}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      }}
    </ArrayField>
  );
}

export default LinksField;
