import { useApolloClient } from '@apollo/client';
import { App } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { HistoriesDocument } from '../../../common/graphql/history.generated';
import { useFormError } from '../../../common/hooks/useFormError';
import {
  deleteCacheFields,
  extractBadRequestError,
  removeCache,
} from '../../../common/utils/apollo';
import { ROUTES } from '../../../routes/const';
import {
  ProductGroupQuery,
  useUpdateProductGroupWithProductMutation,
} from '../graphql/productGroup.generated';
import { defaultValuesMapper, updateInputMapper } from '../helper/mapper';
import { updateProductGroupFormResolver } from '../helper/validator';
import { UpdateProductGroupFormValues } from '../types';

export default function useUpdateProductGroupFormValues(
  productGroup: ProductGroupQuery['adminProductGroup']
) {
  const client = useApolloClient();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const params = useParams();
  const productGroupId = Number(params.productGroupId);

  const defaultValues = defaultValuesMapper(productGroup);

  const methods = useForm<UpdateProductGroupFormValues>({
    defaultValues,
    /**
     * input change 시점에 validation 동작
     * default는 submit 시점에 validation 동작
     */
    mode: 'onChange',
    resolver: updateProductGroupFormResolver,
  });

  const [updateProductGroup] = useUpdateProductGroupWithProductMutation();

  const { handleSubmit } = methods;

  const { onInvalid } = useFormError();

  const onSubmit = handleSubmit(async (data: UpdateProductGroupFormValues) => {
    const updateProductGroupInput = updateInputMapper(data);

    await updateProductGroup({
      variables: {
        productGroupData: updateProductGroupInput,
        adminUpdateProductGroupWithProductId: productGroupId,
      },
      onCompleted() {
        message.success('저장이 완료되었습니다.');
        navigate(ROUTES.PRODUCTS.path);
      },
      refetchQueries: [HistoriesDocument],
      update(cache) {
        removeCache(cache, {
          id: productGroupId,
          __typename: 'ProductGroupGraphqlType',
        });

        deleteCacheFields(cache, [
          'adminProductGroups',
          'adminProductGroups',
          'adminItems',
        ]);
      },
      onError({ graphQLErrors, message: errorMessage }) {
        const noProductGroupError = extractBadRequestError(graphQLErrors);

        if (noProductGroupError) {
          message.error(noProductGroupError.message);

          removeCache(client.cache, {
            id: productGroupId,
            __typename: 'ProductGroupGraphqlType',
          });
        } else {
          const text = errorMessage || '저장을 실패하였습니다.';
          message.error(text);
        }
      },
    });
  }, onInvalid);

  return { methods, onSubmit };
}
