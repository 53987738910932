import { Spin, UploadFile } from 'antd';
import { useState } from 'react';

import FileDnDUploader from '../../../../common/components/FileDnDUploader';
import Modal from '../../../../common/components/Modal';
import useFileDndUploader from '../../../../common/hooks/useFileDnDUploader';

type ArchiveUploadModalProps = {
  onClose: () => void;
  endUpload: (urls: string[]) => void;
  possibleCount: number;
  accept?: string;
};

export default function ArchiveUploadModal({
  onClose,
  endUpload,
  possibleCount,
  accept,
}: ArchiveUploadModalProps) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const close = () => {
    setIsModalOpen(false);
  };

  const { upload } = useFileDndUploader({ purpose: 'PRODUCTS' });

  const handleUpload = async () => {
    setLoading(true);
    await upload({
      fileList: fileList,
      endUpload: (urls, errorFiles) => {
        endUpload(urls);

        if (errorFiles?.length) {
          setFileList(errorFiles);
        } else {
          close();
        }

        setLoading(false);
      },
    });
  };

  return (
    <>
      <Modal
        title={'이미지 업로드'}
        open={isModalOpen}
        onOk={handleUpload}
        onCancel={close}
        afterClose={onClose}
      >
        <div className='my-6'>
          <FileDnDUploader
            fileList={fileList}
            onChange={setFileList}
            multiple
            maxCount={possibleCount}
            description='단일 또는 여러개의 이미지 첨부가능'
            accept={accept}
          />
        </div>
      </Modal>
      {loading && (
        <div className='fixed inset-0 z-[1001] flex w-full items-center justify-center bg-black/30'>
          <Spin />
        </div>
      )}
    </>
  );
}
