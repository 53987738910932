import { Tag, Typography } from 'antd';

import Modal from '../../../common/components/Modal';
import { ORDER_TYPE_TEXT } from '../const';
import { useAdminOrderQuery } from '../graphql/order.generated';

import OrderDetailChannel from './OrderDetailChannel';
import OrderDetailClaim from './OrderDetailClaim';
import OrderDetailDate from './OrderDetailDate';
import OrderDetailDelivery from './OrderDetailDelivery';
import OrderDetailEvent from './OrderDetailEvent';
import OrderDetailInformation from './OrderDetailInformation';
import OrderDetailMarket from './OrderDetailMarket';

type OrderDetailModalProps = {
  id: number;
  onClose: () => void;
};

export default function OrderDetailModal({
  id,
  onClose,
}: OrderDetailModalProps) {
  const { data } = useAdminOrderQuery({
    variables: {
      adminOrderId: id,
    },
  });

  if (!data) return null;

  const order = data.adminOrder;
  const claims = order.claims?.map((claim) => {
    return {
      key: claim.id,
      id: claim.id,
      claimType: claim.claimType,
      reasonType: claim.reasonType,
      reason: claim.reason || '-',
      claimQuantity: claim.claimQuantity || 0,
      createdAt: claim.createdAt,
      extraDeliveryFee: claim.extraDeliveryFee,
      extraDeliveryFeeBearer: claim.extraDeliveryFeeBearer,
      extraDeliverySettlementPrice: claim.extraDeliverySettlementPrice,
      extraDeliverySettlementTarget: claim.extraDeliverySettlementTarget,
    };
  });

  return (
    <Modal
      width={1000}
      title='주문상세'
      onCancel={onClose}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={onClose}
    >
      <div className='pt-4'>
        <div className='mb-2 bg-[#E6F4FF] p-4'>
          <Typography.Paragraph className='m-0' strong>
            와이어드 품목주문코드: {order.code}
            <Tag
              bordered
              className='ml-2'
              color={order.orderType === 'PRODUCT' ? 'magenta' : 'green'}
            >
              {ORDER_TYPE_TEXT[order.orderType]}
            </Tag>
          </Typography.Paragraph>
        </div>
        <div className='flex flex-col gap-6'>
          <OrderDetailDate order={order} />
          <OrderDetailChannel order={order} />
          {order.orderType === 'PRODUCT' && (
            <OrderDetailInformation order={order} />
          )}
          {order.group.marketId && (
            <OrderDetailMarket marketId={order.group.marketId} />
          )}
          {order.orderType === 'EVENT' && order.marketEventId && (
            <OrderDetailEvent eventId={order.marketEventId} />
          )}
          <OrderDetailDelivery order={order} />
          {claims && claims.length > 0 && <OrderDetailClaim claims={claims} />}
        </div>
      </div>
    </Modal>
  );
}
