import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import ScreenShotAndImageUpload from '../ScreenShotAndImageUpload';

type ScreenShotAndImageUploadFieldProps<TFieldValues extends FieldValues> = {
  control: Control<TFieldValues>;
  name: Path<TFieldValues>;
  disabled?: boolean;
  hideErrorMessage?: boolean;
};

export default function ScreenShotAndImageUploadField<
  TFieldValues extends FieldValues,
>({
  control,
  name,
  disabled,
  hideErrorMessage,
}: ScreenShotAndImageUploadFieldProps<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <ScreenShotAndImageUpload
            endUpload={field.onChange}
            purpose='PRODUCTS'
            image={field.value}
            error={fieldState.error?.message}
            editable={!disabled}
            hideErrorMessage={hideErrorMessage}
          />
        );
      }}
    />
  );
}
