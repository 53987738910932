import { DownOutlined } from '@ant-design/icons';
import { Dropdown as AntdDropdown, DropdownProps, Space } from 'antd';

function Dropdown({ label, ...props }: DropdownProps & { label?: string }) {
  return (
    <div className='flex justify-end'>
      <AntdDropdown {...props}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {label}
            <DownOutlined />
          </Space>
        </a>
      </AntdDropdown>
    </div>
  );
}

export default Dropdown;
