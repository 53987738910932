import { Descriptions, Divider, Typography } from 'antd';
import dayjs from 'dayjs';

import { useAdminMarketInOrderQuery } from '../graphql/markets.generated';

type OrderDetailMarketProps = {
  marketId: number;
};

export default function OrderDetailMarket({
  marketId,
}: OrderDetailMarketProps) {
  const { data } = useAdminMarketInOrderQuery({
    variables: {
      marketId,
    },
  });

  if (!data?.adminMarket) return null;

  const { id, name, startedAt, endedAt, expectedSettlementAt } =
    data.adminMarket;

  return (
    <div>
      <Divider orientation='left' plain>
        <Typography.Title level={5} className='mb-0'>
          마켓정보
        </Typography.Title>
      </Divider>
      <Descriptions
        bordered
        className='[&_.ant-descriptions-view]:rounded-none'
      >
        <Descriptions.Item
          label='마켓ID'
          span={2}
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
        >
          {id ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item
          label='마켓명'
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
        >
          {name ?? '-'}
        </Descriptions.Item>
        <Descriptions.Item label='마켓기간' span={2}>
          {dayjs(startedAt).format('YYYY.MM.DD')}
          {endedAt && ` ~ ${dayjs(endedAt).format('YYYY.MM.DD')}`}
        </Descriptions.Item>
        <Descriptions.Item label='정산예정일'>
          {expectedSettlementAt
            ? dayjs(expectedSettlementAt).format('YYYY.MM.DD')
            : '-'}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
