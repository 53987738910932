import { Typography } from 'antd';

const ProductOptionTypeDescription = () => {
  return (
    <div className='w-[320px] [&_.ant-typography]:mb-0 [&_.ant-typography]:break-keep [&_.ant-typography]:text-white'>
      <div>
        <Typography.Paragraph>[고정타입]</Typography.Paragraph>
        <Typography.Paragraph>
          품목에 고정된 구성품이 있을 때 사용합니다
        </Typography.Paragraph>
        <Typography.Paragraph>{`예시1) 품목: 세븐브레스 3box`}</Typography.Paragraph>
        <Typography.Paragraph>{`-> ‘세븐브레스 1box’ 가 구성품이 됩니다.`}</Typography.Paragraph>
        <Typography.Paragraph>{`예시2) 품목: 금왕set`}</Typography.Paragraph>
        <Typography.Paragraph>{`-> ‘등심돈까스’, ‘치즈돈까스’, ‘함박(2개입)’, ‘생선까스’, ‘고구마롤까스(2개입)’가 구성품이 됩니다.`}</Typography.Paragraph>
      </div>
      <div className='mt-4'>
        <Typography.Paragraph>[선택타입]</Typography.Paragraph>
        <Typography.Paragraph>
          구매자가 선택할 수 있는 옵션이 있을 때 사용합니다.
        </Typography.Paragraph>
        <Typography.Paragraph>{`예시) 품목: 프프프베이커리 단품 3EA`}</Typography.Paragraph>
        <Typography.Paragraph>{`-> ‘얼그레이’, ‘허니버터’가 옵션이 됩니다.`}</Typography.Paragraph>
      </div>
    </div>
  );
};

export default ProductOptionTypeDescription;
