import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminMarketsInOrderSearchQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MarketPageQueryFilter>;
  pagination?: Types.InputMaybe<Types.Pagination>;
}>;


export type AdminMarketsInOrderSearchQuery = { __typename?: 'Query', adminMarkets: { __typename?: 'AdminMarketPageGraphqlType', edges: Array<{ __typename?: 'PaginatedMarketGraphqlTypeEdge', node: { __typename?: 'MarketGraphqlType', id: number, name: string } }> } };

export type AdminMarketInOrderQueryVariables = Types.Exact<{
  marketId: Types.Scalars['Int']['input'];
}>;


export type AdminMarketInOrderQuery = { __typename?: 'Query', adminMarket: { __typename?: 'MarketGraphqlType', endedAt?: string | null, startedAt: string, expectedSettlementAt?: string | null, id: number, name: string, events?: Array<{ __typename?: 'MarketEventGraphqlType', id: number, type: Types.EMarketEventType, count?: number | null, comment?: string | null, shippingBearer?: Types.EShippingBearer | null, shippingCost?: number | null, isExtraDeliveryCharged: boolean, products: Array<{ __typename?: 'MarketEventProductGraphqlType', count: number, customerBearingCost?: number | null, id: number, isFreeProvided: boolean, isSellerBearing?: boolean | null, isWiredBearing?: boolean | null, name: string, sellerBearingCost?: number | null, wiredBearingCost?: number | null, items?: Array<{ __typename?: 'ItemGraphqlType', name: string }> | null }> }> | null } };


export const AdminMarketsInOrderSearchDocument = gql`
    query AdminMarketsInOrderSearch($filter: MarketPageQueryFilter, $pagination: Pagination) {
  adminMarkets(filter: $filter, pagination: $pagination) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAdminMarketsInOrderSearchQuery__
 *
 * To run a query within a React component, call `useAdminMarketsInOrderSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMarketsInOrderSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMarketsInOrderSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminMarketsInOrderSearchQuery(baseOptions?: Apollo.QueryHookOptions<AdminMarketsInOrderSearchQuery, AdminMarketsInOrderSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMarketsInOrderSearchQuery, AdminMarketsInOrderSearchQueryVariables>(AdminMarketsInOrderSearchDocument, options);
      }
export function useAdminMarketsInOrderSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMarketsInOrderSearchQuery, AdminMarketsInOrderSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMarketsInOrderSearchQuery, AdminMarketsInOrderSearchQueryVariables>(AdminMarketsInOrderSearchDocument, options);
        }
export type AdminMarketsInOrderSearchQueryHookResult = ReturnType<typeof useAdminMarketsInOrderSearchQuery>;
export type AdminMarketsInOrderSearchLazyQueryHookResult = ReturnType<typeof useAdminMarketsInOrderSearchLazyQuery>;
export type AdminMarketsInOrderSearchQueryResult = Apollo.QueryResult<AdminMarketsInOrderSearchQuery, AdminMarketsInOrderSearchQueryVariables>;
export const AdminMarketInOrderDocument = gql`
    query AdminMarketInOrder($marketId: Int!) {
  adminMarket(marketId: $marketId) {
    endedAt
    startedAt
    expectedSettlementAt
    id
    name
    events {
      id
      type
      count
      comment
      shippingBearer
      shippingCost
      isExtraDeliveryCharged
      products {
        count
        customerBearingCost
        id
        isFreeProvided
        isSellerBearing
        isWiredBearing
        items {
          name
        }
        name
        sellerBearingCost
        wiredBearingCost
      }
    }
  }
}
    `;

/**
 * __useAdminMarketInOrderQuery__
 *
 * To run a query within a React component, call `useAdminMarketInOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMarketInOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMarketInOrderQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useAdminMarketInOrderQuery(baseOptions: Apollo.QueryHookOptions<AdminMarketInOrderQuery, AdminMarketInOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMarketInOrderQuery, AdminMarketInOrderQueryVariables>(AdminMarketInOrderDocument, options);
      }
export function useAdminMarketInOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMarketInOrderQuery, AdminMarketInOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMarketInOrderQuery, AdminMarketInOrderQueryVariables>(AdminMarketInOrderDocument, options);
        }
export type AdminMarketInOrderQueryHookResult = ReturnType<typeof useAdminMarketInOrderQuery>;
export type AdminMarketInOrderLazyQueryHookResult = ReturnType<typeof useAdminMarketInOrderLazyQuery>;
export type AdminMarketInOrderQueryResult = Apollo.QueryResult<AdminMarketInOrderQuery, AdminMarketInOrderQueryVariables>;