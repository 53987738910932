import { Button } from 'antd';
import { first } from 'lodash-es';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import FieldWithLabel from '../../../common/components/Fields/FieldWithLabel';
import TextInput from '../../../common/components/Input/TextInput';
import { formatNumber, parseNumber } from '../../../common/utils/number';
import { useCheckIsPhoneNumberUniqueLazyQuery } from '../graphql/seller.generated';
import { useSellersByFormLazyQuery } from '../graphql/sellers.generated';
import { CreateSellerFormValues, UpdateSellerFormValues } from '../types';

type MobileFieldProps = {
  disabled: boolean;
  sellerId?: number;
};

function MobileField({ disabled, sellerId }: MobileFieldProps) {
  const { control, formState, watch, setValue } = useFormContext<
    CreateSellerFormValues | UpdateSellerFormValues
  >();
  const [checkPhoneNumber] = useCheckIsPhoneNumberUniqueLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [fetchSellers] = useSellersByFormLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [duplicatedSeller, setDuplicatedSeller] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const value = watch('phoneNumber');

  const phoneNumberError =
    formState.errors.phoneNumber?.type !== 'required'
      ? formState.errors.phoneNumber
      : null;
  const requiredError =
    formState.errors.phoneNumber?.type === 'required'
      ? formState.errors.phoneNumber
      : null;
  const checkedError = formState.errors.isCheckedPhoneNumber;
  const uniqueError = formState.errors.isUniquePhoneNumber;

  const hasError = !!requiredError || !!uniqueError;
  const hasWarning = !!checkedError || !!phoneNumberError;

  const status = hasError
    ? 'error'
    : hasWarning
    ? 'warning'
    : value
    ? 'success'
    : '';
  const errorMessage =
    formState.errors.phoneNumber?.message ||
    checkedError?.message ||
    uniqueError?.message;

  const checkUnique = async () => {
    try {
      const { data } = await checkPhoneNumber({
        variables: {
          phoneNumber: value,
          sellerId,
        },
      });

      const isUnique = data?.adminCheckIsPhoneNumberUnique;

      if (!isUnique) {
        const { data } = await fetchSellers({
          variables: { sellersInput: { filter: { phoneNumber: value } } },
        });

        const seller = first(data?.adminSellers?.sellers || []);

        setDuplicatedSeller(seller || null);
      }

      setIsUnique(!!isUnique);
      setIsChecked(true);
    } catch (e) {
      setIsUnique(true);
      setIsChecked(false);
    }
  };

  const setIsChecked = (isChecked: boolean) => {
    setValue('isCheckedPhoneNumber', isChecked, { shouldValidate: true });
  };

  const setIsUnique = (isUnique: boolean) => {
    setValue('isUniquePhoneNumber', isUnique, { shouldValidate: true });
  };

  return (
    <FieldWithLabel label={'휴대폰번호'} required>
      <div className={'flex gap-md'}>
        <Controller
          control={control}
          render={({ field: { onChange, value, ...lastField } }) => (
            <TextInput
              disabled={disabled}
              status={status}
              placeholder={'010-1234-5678'}
              onChange={(e) => {
                e.target.value = parseNumber(
                  formatNumber(e.target.value, '###-####-####')
                );
                onChange(e);
                setIsChecked(false);
                setIsUnique(true);
              }}
              value={formatNumber(value || '', '###-####-####')}
              {...lastField}
            />
          )}
          name={'phoneNumber'}
        />
        <Button
          disabled={
            !hasWarning || !!requiredError || !!phoneNumberError || disabled
          }
          htmlType={'button'}
          onClick={checkUnique}
        >
          중복확인
        </Button>
      </div>
      {errorMessage && (
        <p className={'text-xs text-red-500'}>
          {errorMessage}
          {uniqueError && duplicatedSeller && (
            <Link
              to={`/sellers/${duplicatedSeller.id}`}
              className={'ml-sm'}
              target={'_blank'}
            >
              바로가기
            </Link>
          )}
        </p>
      )}
    </FieldWithLabel>
  );
}

export default MobileField;
