import { EyeOutlined, FileTextOutlined } from '@ant-design/icons';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { Image } from 'antd';
import mime from 'mime';
import { forwardRef, useMemo, useState } from 'react';

import PreviewModal from '../../../../common/components/PreviewModal';
import {
  ALLOW_UPLOAD_IMAGE_MIME_TYPES,
  FALLBACK_IMAGE,
} from '../../../../common/utils/const';

type ItemProps =
  | {
      sortableItem: true;
      url: string;
      listeners: DraggableSyntheticListeners;
    }
  | { sortableItem: false; url: string };

export const SortableItemContents = forwardRef<HTMLDivElement, ItemProps>(
  (props, ref) => {
    const { sortableItem, url } = props;
    const [visible, setVisible] = useState(false);

    const isImage = useMemo(() => {
      const extension = mime.getType(url);

      return extension
        ? ALLOW_UPLOAD_IMAGE_MIME_TYPES.includes(extension)
        : false;
    }, [url]);

    const previewOption = useMemo(() => {
      if (!sortableItem) return false;

      return {
        mask: <Mask onClick={() => setVisible(true)} />,
        visible: false,
      };
    }, [sortableItem]);

    const closeModal = () => {
      setVisible(false);
    };

    return (
      <>
        <div
          className='relative w-full overflow-hidden rounded-lg pb-[100%] '
          ref={ref}
          {...(sortableItem && props.listeners)}
        >
          {isImage && (
            <Image
              key={url}
              alt='example'
              src={url}
              className='block h-full w-full object-cover'
              wrapperStyle={{
                display: 'block',
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
              fallback={FALLBACK_IMAGE}
              preview={previewOption}
            />
          )}
          {!isImage && (
            <File mask={sortableItem} onClick={() => setVisible(true)} />
          )}
        </div>
        {visible && <PreviewModal onClose={closeModal} previewImage={url} />}
      </>
    );
  }
);

type MaskProps = {
  onClick: () => void;
};

function Mask({ onClick }: MaskProps) {
  return (
    <div
      className='absolute inset-0 flex items-center justify-center'
      onClick={onClick}
    >
      <div>
        <EyeOutlined /> Preview
      </div>
    </div>
  );
}

type FileProps = {
  mask?: boolean;
  onClick?: () => void;
};

function File({ mask, onClick }: FileProps) {
  return (
    <div
      className={
        'group/file absolute left-0 top-0 flex h-full w-full cursor-pointer items-center justify-center bg-[#00000005]'
      }
    >
      <FileTextOutlined
        style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.45)' }}
      />
      {mask && (
        <div
          className='absolute inset-0 left-0 top-0 flex items-center justify-center bg-[#00000080] text-white opacity-0 transition-opacity duration-300 group-hover/file:opacity-100'
          onClick={onClick}
        >
          <div>
            <EyeOutlined /> Preview
          </div>
        </div>
      )}
    </div>
  );
}
