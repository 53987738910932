import { SelectProps } from 'antd';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useCallback } from 'react';

import { useSellersInSelectFieldLazyQuery } from '../../graphql/sellers.generated';
import AsyncSelect from '../Input/AsyncSelect';

export type SelectFieldProps = SelectProps & {
  initialOption?: { value: number; label: string };
  initialLoad?: boolean;
};

const SellerSelect = forwardRef<BaseSelectRef, SelectFieldProps>(function (
  { initialLoad, ...props },
  ref
) {
  const [fetchSeller] = useSellersInSelectFieldLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const fetchItems = useCallback(
    async (v: string) => {
      const { data } = await fetchSeller({
        variables: {
          sellersInput: {
            search: { types: ['NAME'], keyword: v },
            pagination: { skip: 0, take: 20 },
          },
        },
      });

      return (data?.adminSellers.sellers || []).map((seller) => {
        return {
          label: seller.name,
          value: seller.id,
          data: seller,
        };
      });
    },
    [fetchSeller]
  );

  return (
    <AsyncSelect
      ref={ref}
      initialLoad={initialLoad}
      className={'w-full'}
      fetchItems={fetchItems}
      {...props}
    />
  );
});

export default SellerSelect;
