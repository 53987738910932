import { Divider, Spin } from 'antd';

import Modal from '../../../../common/components/Modal';
import { useAdminMarketPreviewQuery } from '../../graphql/market.generated';

import ChecklistTable from './ChecklistTable';
import PreviewContent from './PreviewContent';

type MarketPreviewModalProps = {
  open: boolean;
  onCancel: () => void;
  marketId: number;
};
function MarketPreviewModal({ marketId, ...props }: MarketPreviewModalProps) {
  const { data, loading } = useAdminMarketPreviewQuery({
    variables: { marketId },
  });

  const market = data?.adminMarket;

  return (
    <Modal {...props} destroyOnClose footer={null} className={'relative'}>
      {loading && (
        <div
          className={'flex h-[calc(100vh-200px)] items-center justify-center'}
        >
          <Spin />
        </div>
      )}
      {market && (
        <div>
          <div className={'pb-8'}>
            <PreviewContent market={market} />

            <Divider />
            <ChecklistTable market={market} />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default MarketPreviewModal;
