import { DefaultOptionType } from 'antd/es/select';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import SelectField from '../../../common/components/Fields/SelectField';
import { usePromotionProductQuery } from '../graphql/productGroups.generated';
import { PromotionFormValues } from '../types';

function PromotionProductItemSelectCell({
  index,
  disabled,
}: {
  index: number;
  disabled?: boolean;
}) {
  const { control, watch, setValue } = useFormContext<PromotionFormValues>();
  const [originalProductId, promotionItems] = watch([
    `promotionProducts.${index}.originalProductId`,
    `promotionProducts.${index}.promotionItems`,
  ]);
  const originalItemIds = promotionItems?.map(
    ({ originalItemId }) => originalItemId
  );
  const { data } = usePromotionProductQuery({
    variables: {
      adminProductId:
        typeof originalProductId === 'number' ? originalProductId : 0,
    },
    skip: typeof originalProductId !== 'number',
  });

  const isSellectType = data?.adminProduct?.useType === 'SELECT';

  useEffect(() => {
    if (data?.adminProduct) {
      setValue(`promotionProducts.${index}.useType`, data.adminProduct.useType);
    }
  }, [data?.adminProduct, index, setValue]);

  const options = useMemo(() => {
    return data?.adminProduct?.items?.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [data]);
  const handleItemSelect = useCallback(
    (_: any, option: DefaultOptionType | DefaultOptionType[]) => {
      if (Array.isArray(option)) {
        setValue(
          `promotionProducts.${index}.promotionItems`,
          option.map(({ value }) => ({ originalItemId: value as number }))
        );
      }
    },
    [setValue, index]
  );
  if (!isSellectType) return <div>-</div>;

  return (
    <SelectField
      mode='multiple'
      disabled={disabled}
      className='w-full'
      control={control}
      name={`promotionProducts.${index}.promotionItems`}
      options={options}
      value={originalItemIds}
      onChange={handleItemSelect}
      maxTagCount={'responsive'}
      maxTagTextLength={10}
    />
  );
}

export default PromotionProductItemSelectCell;
