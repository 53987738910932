import { ApolloError } from '@apollo/client';

import {
  EOrderClaimReasonType,
  EOrderClaimStatus,
  EOrderClaimType,
  EOrderStatus,
  EOrderType,
  ESalesChannel,
  ESalesChannelType,
  EOrderClaimTarget,
} from '../../../schema/types';
import { CLAIM_STATUS } from '../const';
import { AdminOrderGroupQuery } from '../graphql/createClaim.generated';

import { checkCreateClaimRejectReason } from './create';

export const getRejectReasonCreateClaim = (
  order: {
    orderType?: EOrderType | null;
    salesChannel: ESalesChannel;
    salesChannelType: ESalesChannelType;
    activeClaim?: {
      claimQuantity?: number | null;
      claimType: EOrderClaimType;
      id: number;
      isWiredOwnDeliveryFault?: boolean | null;
      reason?: string | null;
      reasonType: EOrderClaimReasonType;
      status: EOrderClaimStatus;
      target: EOrderClaimTarget;
    } | null;
    status: EOrderStatus;
    claims?:
      | {
          id: number;
          claimType: EOrderClaimType;
          status: EOrderClaimStatus;
        }[]
      | null;
  },
  // 바꾸고자하는 claim
  claim: {
    type: EOrderClaimType;
    status: EOrderClaimStatus;
  }
) => {
  /**
   * 상품주문에 대한 CS처리만 허용
   */
  if (order.orderType !== 'PRODUCT') {
    return '상품주문만 CS처리가 가능합니다.';
  }

  /**
   * 케미 채널 주문은 부분반품 불가
   */

  if (
    order.salesChannel === 'KEMI' &&
    claim.status === 'RETURN_COMPLETED' &&
    claim.type === 'PARTIAL_RETURN'
  ) {
    return '케미 채널 주문은 부분반품처리가 불가합니다.';
  }

  /**
   * 셀러쇼핑몰 - 주문서변환
   * 취소처리, 부분취소처리 Type은 채널(타입)의 주문만 허용된다.
   * 결제대기 / 취소완료 / 반품환불완료 상태인 주문은 반품접수 상태로 변경이 불가능하다.
   */
  const isBaljuSellerShop =
    order.salesChannel === 'BALJU' && order.salesChannelType === 'SELLER_SHOP';

  if (
    !isBaljuSellerShop &&
    claim.status === 'CANCEL_COMPLETED' &&
    claim.type === 'CANCEL'
  ) {
    return '셀러쇼핑몰 채널에서 주문서변환으로 유입된 주문 건만 취소처리가 가능합니다.';
  }

  /**
   * order가 claim 생성이 가능한 상황인지 확인.
   */
  if (!order.activeClaim) {
    const reason = checkCreateClaimRejectReason[claim.type]({
      orderStatus: order.status,
      claims: order.claims || [],
    });

    if (reason) {
      return reason;
    }
  }

  return '';
};

export const getRejectReasonChangeClaim = (
  order: Exclude<
    AdminOrderGroupQuery['adminOrderGroup']['orders']['0'],
    undefined | null
  >,
  // 바꾸고자하는 claim
  claim: {
    type: EOrderClaimType;
    status: EOrderClaimStatus;
  }
) => {
  const rejectCreateReason = getRejectReasonCreateClaim(order, claim);

  if (rejectCreateReason) {
    return rejectCreateReason;
  }

  /**
   * 현재 active한 claim이 변경 가능한 상태인지 확인
   */
  if (order.activeClaim) {
    /**
     * 자기자신은 허용
     */
    if (order.activeClaim.status === claim.status) {
      return '';
    }

    const convertableClaims = getConvertableClaimOptions(
      order.activeClaim.claimType,
      order.activeClaim.status,
      order.salesChannelType
    );

    const isConvertable = convertableClaims.some(
      (option) =>
        option.data.claimType === claim.type &&
        option.data.status === claim.status
    );

    if (!isConvertable) {
      return '변경 가능한 클레임 상태가 아닙니다.';
    }
  }

  return '';
};

export const getConvertableClaimOptions = (
  claimType: EOrderClaimType,
  claimStatus: EOrderClaimStatus,
  salesChannelType: ESalesChannelType
): {
  label: string;
  value: EOrderClaimStatus;
  data: { status: EOrderClaimStatus; claimType: EOrderClaimType };
}[] => {
  const possibleClaims = (() => {
    if (claimType === 'RETURN') {
      return getConvertableReturnClaim;
    }
    if (claimType === 'EXCHANGE') {
      return getConvertableExchangeClaim;
    }

    if (claimType === 'CANCEL') {
      return getConvertableCancelClaim;
    }

    return null;
  })();

  if (!possibleClaims) {
    return [];
  }

  const statuses = possibleClaims(claimStatus, salesChannelType);

  return statuses
    .map(({ status, claimType }) => {
      return {
        label: CLAIM_STATUS[status],
        value: status,
        data: { status, claimType },
      };
    })
    .filter(
      (
        status
      ): status is {
        label: string;
        value: EOrderClaimStatus;
        data: { status: EOrderClaimStatus; claimType: EOrderClaimType };
      } => !!status
    );
};

export const getConvertableReturnClaim = (
  claimStatus: EOrderClaimStatus, // 현재상태
  salesChannelType: ESalesChannelType
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (salesChannelType) {
    case 'KEMI':
      return getKemiConvertableReturnClaimStatus(claimStatus);
    case 'SELLER_SHOP':
      return getSellerShopConvertableReturnClaimStatus(claimStatus);
    case 'PB_SHOP':
      return getPBConvertableReturnClaimStatus(claimStatus);

    default:
      return [];
  }
};

export const getConvertableExchangeClaim = (
  claimStatus: EOrderClaimStatus, // 현재상태
  salesChannelType: ESalesChannelType
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (salesChannelType) {
    case 'KEMI':
      return getKemiConvertableExchangeClaimStatus(claimStatus);
    case 'SELLER_SHOP':
      return getSellerShopConvertableExchangeClaimStatus(claimStatus);
    case 'PB_SHOP':
      return getPBConvertableExchangeClaimStatus(claimStatus);

    default:
      return [];
  }
};

export const getConvertableCancelClaim = (
  claimStatus: EOrderClaimStatus, // 현재상태
  salesChannelType: ESalesChannelType
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (salesChannelType) {
    case 'KEMI':
      return [];
    case 'SELLER_SHOP':
      return [];
    case 'PB_SHOP':
      return getPBConvertableCancelClaimStatus(claimStatus);

    default:
      return [];
  }
};

const getKemiConvertableReturnClaimStatus = (
  claimStatus: EOrderClaimStatus
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (claimStatus) {
    case 'RETURN_REQUESTED':
      return [
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
        { status: 'RETURN_CANCELED', claimType: 'RETURN' },
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
      ];

    case 'RETURN_RECEIPT':
      return [
        { status: 'RETURN_RECLAIM_COMPLETED', claimType: 'RETURN' },
        { status: 'RETURN_CANCELED', claimType: 'RETURN' },
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
      ];

    case 'RETURN_RECLAIM_COMPLETED':
      return [
        { status: 'RETURN_CANCELED', claimType: 'RETURN' },
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
      ];

    case 'RETURN_HOLD_COMPLETED':
      return [{ status: 'RETURN_COMPLETED', claimType: 'RETURN' }];

    case 'RETURN_COMPLETED':
      return [];

    default:
      return [];
  }
};

const getKemiConvertableExchangeClaimStatus = (
  claimStatus: EOrderClaimStatus
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (claimStatus) {
    case 'EXCHANGE_REQUESTED':
      return [
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
        { status: 'EXCHANGE_CANCELED', claimType: 'EXCHANGE' },
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
      ];

    case 'EXCHANGE_RECEIPT':
      return [
        { status: 'EXCHANGE_RE_DELIVERY', claimType: 'EXCHANGE' },
        { status: 'EXCHANGE_COMPLETED', claimType: 'EXCHANGE' },
        { status: 'EXCHANGE_CANCELED', claimType: 'EXCHANGE' },
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
      ];

    case 'EXCHANGE_RE_DELIVERY':
      return [{ status: 'EXCHANGE_COMPLETED', claimType: 'EXCHANGE' }];

    case 'EXCHANGE_COMPLETED':
      return [
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
      ];

    default:
      return [];
  }
};

const getSellerShopConvertableReturnClaimStatus = (
  claimStatus: EOrderClaimStatus
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (claimStatus) {
    case 'RETURN_REQUESTED':
      return [];

    case 'RETURN_RECEIPT':
      return [
        { status: 'RETURN_RECLAIM_COMPLETED', claimType: 'RETURN' },
        { status: 'RETURN_CANCELED', claimType: 'RETURN' },
        { status: 'RETURN_COMPLETED', claimType: 'RETURN' },
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
      ];

    case 'RETURN_RECLAIM_COMPLETED':
      return [
        { status: 'RETURN_COMPLETED', claimType: 'RETURN' },
        { status: 'RETURN_CANCELED', claimType: 'RETURN' },
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
      ];

    case 'RETURN_HOLD_COMPLETED':
      return [{ status: 'RETURN_COMPLETED', claimType: 'RETURN' }];

    case 'RETURN_COMPLETED':
      return [{ status: 'RETURN_COMPLETED', claimType: 'RETURN' }];

    default:
      return [];
  }
};

const getSellerShopConvertableExchangeClaimStatus = (
  claimStatus: EOrderClaimStatus
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (claimStatus) {
    case 'EXCHANGE_REQUESTED':
      return [];

    case 'EXCHANGE_RECEIPT':
      return [
        { status: 'EXCHANGE_RE_DELIVERY', claimType: 'EXCHANGE' },
        { status: 'EXCHANGE_COMPLETED', claimType: 'EXCHANGE' },
        { status: 'EXCHANGE_CANCELED', claimType: 'EXCHANGE' },
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
      ];

    case 'EXCHANGE_RE_DELIVERY':
      return [{ status: 'EXCHANGE_COMPLETED', claimType: 'EXCHANGE' }];

    case 'EXCHANGE_COMPLETED':
      return [
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
      ];

    default:
      return [];
  }
};

export const getPBConvertableReturnClaimStatus = (
  claimStatus: EOrderClaimStatus
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (claimStatus) {
    case 'RETURN_REQUESTED':
      return [
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
      ];

    case 'RETURN_RECEIPT':
      return [
        { status: 'RETURN_RECLAIM_COMPLETED', claimType: 'RETURN' },
        { status: 'RETURN_CANCELED', claimType: 'RETURN' },
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
      ];

    case 'RETURN_RECLAIM_COMPLETED':
      return [
        { status: 'RETURN_CANCELED', claimType: 'RETURN' },
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
      ];

    case 'RETURN_HOLD_COMPLETED':
      return [{ status: 'RETURN_COMPLETED', claimType: 'RETURN' }];

    case 'RETURN_COMPLETED':
      return [];

    default:
      return [];
  }
};

export const getPBConvertableExchangeClaimStatus = (
  claimStatus: EOrderClaimStatus
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (claimStatus) {
    case 'EXCHANGE_REQUESTED':
      return [
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
      ];

    case 'EXCHANGE_RECEIPT':
      return [
        { status: 'EXCHANGE_RE_DELIVERY', claimType: 'EXCHANGE' },
        { status: 'EXCHANGE_COMPLETED', claimType: 'EXCHANGE' },
        { status: 'EXCHANGE_CANCELED', claimType: 'EXCHANGE' },
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
      ];

    case 'EXCHANGE_RE_DELIVERY':
      return [{ status: 'EXCHANGE_COMPLETED', claimType: 'EXCHANGE' }];

    case 'EXCHANGE_COMPLETED':
      return [
        { status: 'EXCHANGE_RECEIPT', claimType: 'EXCHANGE' },
        { status: 'RETURN_RECEIPT', claimType: 'RETURN' },
      ];

    default:
      return [];
  }
};

export const getPBConvertableCancelClaimStatus = (
  claimStatus: EOrderClaimStatus
): { status: EOrderClaimStatus; claimType: EOrderClaimType }[] => {
  switch (claimStatus) {
    case 'CANCEL_REQUESTED':
      return [
        { status: 'CANCEL_NOT_APPROVED', claimType: 'CANCEL' },
        { status: 'CANCEL_COMPLETED', claimType: 'CANCEL' },
      ];

    default:
      return [];
  }
};

export const getStatusUpdateFailMessage = (error: ApolloError) => {
  const errors = error.graphQLErrors?.[0];
  if (errors?.extensions.detailCode === 'CAN_NOT_CHANGE_CLAIM_STATUS') {
    const possibleClaimStatus = errors.extensions
      .possibleClaimStatus as EOrderClaimStatus[];

    return `${possibleClaimStatus
      .map((status) => CLAIM_STATUS[status])
      .join(', ')} 상태만 처리 가능합니다.`;
  }

  return '';
};
