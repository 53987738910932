import { Descriptions, Divider, Typography } from 'antd';

import { formatNumber } from '../../../common/utils/number';
import { AdminOrderQuery } from '../graphql/order.generated';

type OrderDetailDeliveryProps = {
  order: AdminOrderQuery['adminOrder'];
};

export default function OrderDetailDelivery({
  order,
}: OrderDetailDeliveryProps) {
  return (
    <div>
      <Divider orientation='left' plain>
        <Typography.Title level={5} className='mb-0'>
          배송지정보
        </Typography.Title>
      </Divider>
      <Descriptions
        bordered
        className='[&_.ant-descriptions-view]:rounded-none'
        column={4}
      >
        <Descriptions.Item
          label='수령자명'
          span={2}
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
        >
          {order.receiverName}
        </Descriptions.Item>
        <Descriptions.Item
          label='수령자 휴대폰번호'
          labelStyle={{ width: 200 }}
          contentStyle={{ width: 276 }}
          span={2}
        >
          {formatNumber(order.receiverPhoneNumber, '###-####-####')}
        </Descriptions.Item>
        <Descriptions.Item label='배송지주소' span={4}>
          {`${order.receiverAddress} ${order.receiverDetailAddress ?? ''} (${
            order.receiverZipCode
          })`}
        </Descriptions.Item>
        <Descriptions.Item label='배송메모' span={4}>
          {order.receiverDeliveryMemo || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='택배사' span={2}>
          {order.deliveryCompanyName || '-'}
        </Descriptions.Item>
        <Descriptions.Item label='송장번호' span={2}>
          {order.trackingNumber ?? '-'}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
