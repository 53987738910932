import { App, Select, Spin } from 'antd';
import { useCallback, useState } from 'react';

import { useAdminPatchSettlementSheetMutation } from '../../graphql/settlementSheet.generated';
import useSettlementPermission from '../../hooks/useSettlementPermission';

function TaxInvoiceIssuedCell({
  settlementSheetId,
  isIssued,
}: {
  settlementSheetId: number;
  isIssued: boolean;
}) {
  const { message } = App.useApp();
  const hasPermission = useSettlementPermission();
  const [_isIssued, setIsIssued] = useState(isIssued);
  const [mutate, { loading }] = useAdminPatchSettlementSheetMutation();
  const handleChange = useCallback(
    async (value: number) => {
      await mutate({
        variables: {
          input: { isTaxInvoiceIssued: !!value },
          adminPatchSettlementSheetId: settlementSheetId,
        },
        onCompleted() {
          setIsIssued(!!value);
          void message.success('발행상태가 수정되었습니다.');
        },
        onError() {
          void message.error('수정실패하였습니다.');
        },
      });
    },
    [mutate, settlementSheetId, message]
  );

  if (!hasPermission) {
    return <>{isIssued ? '발행' : '미발행'}</>;
  }

  return (
    <Spin spinning={loading}>
      <Select
        className='w-full'
        value={Number(_isIssued)}
        onChange={handleChange}
        options={[
          { label: '발행', value: 1 },
          { label: '미발행', value: 0 },
        ]}
      />
    </Spin>
  );
}

export default TaxInvoiceIssuedCell;
