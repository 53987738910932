import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Drawer, FloatButton, Menu, Tag, Typography } from 'antd';
import { useState } from 'react';

import { authorization } from '../../domain/auth/helper';
import { APP_HEADER_HEIGHT } from '../../theme/const';
import { ENV } from '../constants/env';

import { MENU } from './const';

const APOLLO_STUDIO = [
  { label: 'dev', value: 'https://api-dev.wiredm.in/graphql' },
  { label: 'stage', value: 'https://api-stage.wiredm.in/graphql' },
  { label: 'production', value: 'https://api.wiredm.in/graphql' },
];

const FIGMA = [
  {
    label: '마켓관리',
    value:
      'https://www.figma.com/file/Xj7eNdquEAqZFH1x2z7wuW/WIREDMIN?type=design&node-id=938-163308&mode=design&t=q70zVBrLl7chNyqD-0',
  },
  {
    label: '출고요청',
    value:
      'https://www.figma.com/file/Xj7eNdquEAqZFH1x2z7wuW/WIREDMIN?type=design&node-id=2253-107657&mode=design&t=q70zVBrLl7chNyqD-0',
  },
  {
    label: '주문관리',
    value:
      'https://www.figma.com/file/Xj7eNdquEAqZFH1x2z7wuW/WIREDMIN?type=design&node-id=4028-127178&mode=design&t=q70zVBrLl7chNyqD-0',
  },
  {
    label: '발주관리',
    value:
      'https://www.figma.com/file/Xj7eNdquEAqZFH1x2z7wuW/WIREDMIN?type=design&node-id=4846-69338&mode=design&t=q70zVBrLl7chNyqD-0',
  },
  {
    label: 'CS관리',
    value:
      'https://www.figma.com/file/wGDHYltqOwm19ppWQ0HKjj/WIREDMIN-(CS%2C-%EC%A0%95%EC%82%B0)?type=design&node-id=1-2&mode=design&t=mhWRX5ka6JhixmUK-0',
  },
  {
    label: '정산관리',
    value:
      'https://www.figma.com/file/Xj7eNdquEAqZFH1x2z7wuW/WIREDMIN?type=design&node-id=8303-44905&mode=design&t=Q73whQKEmHxOy3zX-0',
  },
];

function Dev() {
  const [open, setOpen] = useState(false);

  const token = authorization.accessToken;
  const authorizationHeader = token ? `Bearer ${token}` : '';

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  if (ENV === 'production') {
    return null;
  }

  return (
    <div>
      <FloatButton
        shape='circle'
        type='primary'
        style={{ right: 48, top: APP_HEADER_HEIGHT + 24 }}
        icon={<ExclamationCircleOutlined />}
        onClick={showDrawer}
      />
      <Drawer
        title='개발자 공간'
        placement='right'
        onClose={onClose}
        open={open}
      >
        <div className='flex flex-col gap-8'>
          <div>
            <Typography.Text strong copyable={{ text: authorizationHeader }}>
              Authorization Token
            </Typography.Text>
          </div>
          <div className='flex items-center justify-between'>
            <Typography.Text strong>Apollo Studio</Typography.Text>
            <div className='flex gap-1'>
              {APOLLO_STUDIO.map(({ label, value }) => (
                <Tag className='m-0' key={value}>
                  <a href={value} target='_blank'>
                    {label}
                  </a>
                </Tag>
              ))}
            </div>
          </div>
          <div>
            <Typography.Text strong>Figma</Typography.Text>
            <div className='mt-2 flex flex-wrap gap-1'>
              {FIGMA.map(({ label, value }) => (
                <Tag className='m-0' key={value}>
                  <a href={value} target='_blank'>
                    {label}
                  </a>
                </Tag>
              ))}
            </div>
          </div>
          <div>
            <Typography.Text strong>Menu</Typography.Text>
            <Menu mode='inline' className='h-full border-r-0' items={MENU} />
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Dev;
