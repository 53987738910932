import { Button, Table, Typography } from 'antd';
import { useState } from 'react';
import { Link, generatePath } from 'react-router-dom';

import Modal from '../../../../common/components/Modal';
import { ROUTES } from '../../../../routes/const';
import { useAdminVendorsForScheduledSettlementQuery } from '../../graphql/vendors.generated';
import { SentLastMonthVendorModalTableDataType } from '../../types';

type SentLastMonthVendorModalModalProps = {
  onClose: () => void;
};

const DEFAULT_PAGE_SIZE = 10;

function SentLastMonthVendorModal({
  onClose,
}: SentLastMonthVendorModalModalProps) {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);

  const { data, loading } = useAdminVendorsForScheduledSettlementQuery({
    variables: {
      pagination: {
        skip: currentPage * pageSize,
        take: pageSize,
      },
    },
  });

  const totalCount = data?.adminVendorsForScheduledSettlement.totalCount || 0;
  const dataSource = data?.adminVendorsForScheduledSettlement.edges.map(
    ({ node }) => {
      return {
        key: node.id,
        name: node.name,
        id: node.id,
        managerName: node.manager?.name,
        settlementDates: node.settlementDates,
        comment: node.comment,
      };
    }
  );

  const handlePageChange = (page: number, size: number) => {
    if (size !== pageSize) {
      setPageSize(size);
      setCurrentPage(0);
    } else {
      setCurrentPage(page - 1);
    }
  };

  return (
    <>
      <Modal
        onCancel={onClose}
        title='지난달 발송 업체'
        width={1000}
        footer={null}
      >
        <div className='py-4'>
          <Table
            dataSource={dataSource}
            scroll={{ x: 820 }}
            loading={loading}
            pagination={{
              showSizeChanger: true,
              showTotal: (total) => `총 ${total}건`,
              pageSize,
              total: totalCount,
              pageSizeOptions: ['10', '20', '50', '100'],
              onChange: handlePageChange,
            }}
          >
            <Table.Column<SentLastMonthVendorModalTableDataType>
              title='업체명'
              width={320}
              render={(_, record) => {
                return (
                  <div className='flex items-center justify-center gap-4'>
                    <Link
                      to={generatePath(ROUTES.VENDOR_DETAIL.path, {
                        vendorId: record.id,
                      })}
                    >
                      <Typography.Text
                        className='w-[190px] text-primary'
                        ellipsis
                      >
                        {record.name}
                      </Typography.Text>
                    </Link>
                    <Button
                      size='small'
                      href={`${ROUTES.SETTLEMENTS_LIST.path}?vendorId=${record.id}`}
                    >
                      상세보기
                    </Button>
                  </div>
                );
              }}
            />
            <Table.Column<SentLastMonthVendorModalTableDataType>
              title='담당자'
              width={120}
              render={(_, record) => record.managerName || '-'}
            />
            <Table.Column<SentLastMonthVendorModalTableDataType>
              title='정산일자'
              width={140}
              render={(_, record) => {
                if (!record.settlementDates?.length) return '-';

                return record.settlementDates
                  ?.map((date) => `${date}일`)
                  .join(', ');
              }}
            />
            <Table.Column<SentLastMonthVendorModalTableDataType>
              title='비고'
              width={240}
              render={(_, record) => record.comment || '-'}
            />
          </Table>
        </div>
      </Modal>
    </>
  );
}

export default SentLastMonthVendorModal;
