import { useMemo } from 'react';

import useRouteSearchParams from '../../../common/hooks/useRouteSearchParams';
import { useAdminSettlementSheetsQuery } from '../graphql/settlementSheet.generated';
import {
  SettlementSheetListTableDataType,
  SheetListSearchParams,
} from '../types';

import useSettlementSheetListSearchParams from './useSettlementSheetListSearchParams';

function useSettlementSheetList() {
  const { searchParams } = useRouteSearchParams<SheetListSearchParams>();
  const currentPage = Number(searchParams.currentPage) - 1 || 0;
  const pageSize = Number(searchParams.pageSize) || 50;
  const filter = useSettlementSheetListSearchParams();

  const { data, loading } = useAdminSettlementSheetsQuery({
    variables: {
      filter,
      pagination: {
        skip: currentPage * pageSize,
        take: pageSize,
      },
    },
  });

  const dataSource: SettlementSheetListTableDataType[] =
    useMemo(
      () =>
        data?.adminSettlementSheets?.edges.map(({ node }) => ({
          key: node.id,
          ...node,
        })),
      [data]
    ) || [];
  return {
    dataSource,
    loading,
    total: data?.adminSettlementSheets?.totalCount || 0,
  };
}

export default useSettlementSheetList;
