import { Select, SelectProps } from 'antd';
import { concat } from 'lodash-es';
import { BaseSelectRef } from 'rc-select/lib/BaseSelect';
import { forwardRef, useMemo } from 'react';

import { useManagersQuery } from '../../graphql/managers.generated';
import { useMeQuery } from '../../graphql/me.generated';

export type ManagerSelectProps = SelectProps & {
  valueType?: 'manager' | 'operator';
};

const ManagerSelect = forwardRef<BaseSelectRef, ManagerSelectProps>(function (
  { valueType = 'manager', ...props },
  ref
) {
  const { data: userData } = useMeQuery();
  const { data, loading } = useManagersQuery();

  const options = useMemo(() => {
    const currentUser =
      data?.adminManagers?.filter(({ id }) => id === userData?.adminMe?.id) ||
      [];
    const withoutCurrentUser =
      data?.adminManagers?.filter(({ id }) => id !== userData?.adminMe?.id) ||
      [];
    return concat(currentUser, withoutCurrentUser).map(
      ({ id, name, operatorId }) => ({
        label: name,
        value: valueType === 'manager' ? id : operatorId,
      })
    );
  }, [data, userData, valueType]);

  return (
    <Select
      showSearch
      ref={ref}
      options={options}
      loading={loading}
      placeholder='담당자 선택'
      filterOption={(input, option) => {
        if (typeof option?.label !== 'string') {
          return false;
        }

        return option.label?.toLowerCase().includes(input.toLowerCase());
      }}
      {...props}
    />
  );
});

export default ManagerSelect;
