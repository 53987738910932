import * as Types from '../../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminMarketInSettlementListQueryVariables = Types.Exact<{
  marketId: Types.Scalars['Int']['input'];
}>;


export type AdminMarketInSettlementListQuery = { __typename?: 'Query', adminMarket: { __typename?: 'MarketGraphqlType', endedAt?: string | null, startedAt: string, status: Types.EMarketStatus, sellingChannel?: Types.ESellingChannel | null, expectedSettlementAt?: string | null, name: string, id: number, sellerSettlementInfo?: { __typename?: 'SellerSettlementInfoGraphqlType', type: Types.ESellerSettlementType } | null } };


export const AdminMarketInSettlementListDocument = gql`
    query AdminMarketInSettlementList($marketId: Int!) {
  adminMarket(marketId: $marketId) {
    endedAt
    startedAt
    status
    sellingChannel
    expectedSettlementAt
    sellerSettlementInfo {
      type
    }
    name
    id
  }
}
    `;

/**
 * __useAdminMarketInSettlementListQuery__
 *
 * To run a query within a React component, call `useAdminMarketInSettlementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminMarketInSettlementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminMarketInSettlementListQuery({
 *   variables: {
 *      marketId: // value for 'marketId'
 *   },
 * });
 */
export function useAdminMarketInSettlementListQuery(baseOptions: Apollo.QueryHookOptions<AdminMarketInSettlementListQuery, AdminMarketInSettlementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminMarketInSettlementListQuery, AdminMarketInSettlementListQueryVariables>(AdminMarketInSettlementListDocument, options);
      }
export function useAdminMarketInSettlementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminMarketInSettlementListQuery, AdminMarketInSettlementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminMarketInSettlementListQuery, AdminMarketInSettlementListQueryVariables>(AdminMarketInSettlementListDocument, options);
        }
export type AdminMarketInSettlementListQueryHookResult = ReturnType<typeof useAdminMarketInSettlementListQuery>;
export type AdminMarketInSettlementListLazyQueryHookResult = ReturnType<typeof useAdminMarketInSettlementListLazyQuery>;
export type AdminMarketInSettlementListQueryResult = Apollo.QueryResult<AdminMarketInSettlementListQuery, AdminMarketInSettlementListQueryVariables>;