import { Typography } from 'antd';

import { EMarketStatus } from '../../../schema/types';
import { MARKETS_FOR_PRODUCT_GROUP_ALL_STATUSES } from '../const';
import { useAdminSummaryMarketForProductGroupQuery } from '../graphql/market.generated';
import { ProductMarketListModalStatusType } from '../types';

type ProductMarketListTotalAmountProps = {
  type: ProductMarketListModalStatusType;
  productGroupId: number;
};

function ProductMarketListTotalAmount({
  type,
  productGroupId,
}: ProductMarketListTotalAmountProps) {
  const statuses: EMarketStatus[] =
    type === 'ALL' ? MARKETS_FOR_PRODUCT_GROUP_ALL_STATUSES : ['ON_SALE'];

  const { data } = useAdminSummaryMarketForProductGroupQuery({
    variables: {
      filter: {
        productGroupId,
        statuses,
      },
    },
  });

  const totalOrderAmount =
    data?.adminSummaryMarketForProductGroup.totalOrderAmount || 0;

  return (
    <div className='flex justify-between bg-black/[0.02] px-4 py-2'>
      <Typography.Paragraph strong className='m-0'>
        총 매출
      </Typography.Paragraph>
      <Typography.Paragraph strong className='m-0'>
        {totalOrderAmount.toLocaleString()}원
      </Typography.Paragraph>
    </div>
  );
}

export default ProductMarketListTotalAmount;
