import { AutoComplete, AutoCompleteProps } from 'antd';
import { debounce, get } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import { FieldProps } from '../../../../common/components/Fields/types';
import { useAdminProductGroupsInOrderSearchLazyQuery } from '../../graphql/products.generated';

type ProductGroupAutoCompleteFieldProps<TFieldValues extends FieldValues> =
  AutoCompleteProps &
    FieldProps & {
      control: Control<TFieldValues>;
      name: Path<TFieldValues>;
    };

export default function ProductGroupAutoCompleteField<
  TFieldValues extends FieldValues,
>({
  name,
  label,
  required,
  control,
  hideError,
  ...props
}: ProductGroupAutoCompleteFieldProps<TFieldValues>) {
  const [fetchProducts, { data }] =
    useAdminProductGroupsInOrderSearchLazyQuery();

  const fetchItems = useCallback(
    async (v: string) => {
      await fetchProducts({
        variables: {
          filter: {
            productGroupName: v || undefined,
          },
          pagination: {
            take: 20,
            skip: 0,
          },
        },
      });
    },
    [fetchProducts]
  );

  const handleSearch = useCallback(
    debounce(async (v: string) => {
      await fetchItems(v);
    }, 200),
    []
  );

  const options = useMemo(() => {
    return (data?.adminProductGroups?.edges || []).map((edge) => {
      return {
        label: edge.node.name,
        value: edge.node.name,
        key: edge.node.id,
      };
    });
  }, [data]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const error = get(formState.errors, name);
        const errorMessage =
          !hideError && typeof error?.message === 'string'
            ? error.message
            : undefined;
        const status = !hideError && errorMessage ? 'error' : '';

        return (
          <FieldWithLabel label={label} required={required}>
            <AutoComplete
              options={options}
              onSearch={handleSearch}
              className='w-full'
              status={status}
              optionRender={(oriOption) => {
                return (
                  <>
                    {oriOption.label}
                    <span className='ml-1 text-xs text-black/25'>
                      {oriOption.key}
                    </span>
                  </>
                );
              }}
              {...field}
              {...props}
            />

            {!hideError && errorMessage && (
              <p className={'text-xs text-red-500'}>{errorMessage}</p>
            )}
          </FieldWithLabel>
        );
      }}
    />
  );
}
