import { Button } from 'antd';
import { useState } from 'react';

import PageLoader from '../../../../common/components/PageLoader';
import { useAdminOrderGroupQuery } from '../../graphql/createClaim.generated';

import CreateOrderSupportModal from './CreateOrderSupportModal';

function OrderSupportId({
  orderId,
  orderGroupId,
}: {
  orderId: number;
  orderGroupId: number;
}) {
  const [openCreateOrderSupportModal, setOpenCreateOrderSupportModal] =
    useState(false);

  const { data, loading } = useAdminOrderGroupQuery({
    variables: { adminOrderGroupId: orderGroupId },
    skip: !orderGroupId || !openCreateOrderSupportModal,
  });

  const currentOrder = data?.adminOrderGroup.orders.find(
    (order) => order.id === orderId
  );

  return (
    <>
      <Button onClick={() => setOpenCreateOrderSupportModal(true)}>
        응대상세
      </Button>
      {data?.adminOrderGroup && openCreateOrderSupportModal && (
        <CreateOrderSupportModal
          title={'응대상세'}
          order={currentOrder}
          orderGroup={data?.adminOrderGroup}
          onCancel={() => setOpenCreateOrderSupportModal(false)}
        />
      )}
      {loading && <PageLoader />}
    </>
  );
}

export default OrderSupportId;
