import { gql, useQuery, makeVar } from '@apollo/client';

import { LocalCreateProductOption } from '../types';

export const localProductOptionsVar = makeVar<LocalCreateProductOption[]>([]);

export const LOCAL_PRODUCT_OPTIONS = gql`
  query LocalProductOptions {
    localProductOptions @client
  }
`;

export function useLocalProductOptionsQuery() {
  const { data, loading, error } = useQuery<{
    localProductOptions: LocalCreateProductOption[];
  }>(LOCAL_PRODUCT_OPTIONS);

  return { data, loading, error };
}

export function useLocalProductOptionMutation() {
  const setLocalProductOptions = (options?: LocalCreateProductOption[]) => {
    const localProductOptions = localProductOptionsVar();
    const newLocalProductOptions =
      options?.filter((option) => {
        return !localProductOptions.find(
          (localOption) => localOption.code === option.code
        );
      }) ?? [];

    const mergedOptions = [...newLocalProductOptions, ...localProductOptions];

    localProductOptionsVar(mergedOptions);
  };

  return {
    setLocalProductOptions,
  };
}

export function getLocalProductOptions() {
  return localProductOptionsVar();
}

export function clearLocalProductOptions() {
  localProductOptionsVar([]);
}
