import { App, DatePicker, Divider } from 'antd';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import FieldWithLabel from '../../../../common/components/Fields/FieldWithLabel';
import TextareaField from '../../../../common/components/Fields/TextareaField';
import { PromotionFormValues, PromotionEventFormValues } from '../../types';

function ExtraInformation() {
  const { message } = App.useApp();
  const { setValue, watch, control } = useFormContext<
    PromotionFormValues | PromotionEventFormValues
  >();
  const scheduledReleaseAt = watch('scheduledReleaseAt');
  const handleReleaseAt = useCallback(
    (date: dayjs.Dayjs | null) => {
      const isTodayPossible = dayjs().isBefore(dayjs().hour(11).minute(50));
      // 11:50 전에는 오늘부터 출고요청이 가능.
      // 11:50 후에는 내일부터 출고요청이 가능.
      const standard = isTodayPossible
        ? dayjs().startOf('day')
        : dayjs().add(1, 'day').startOf('day');

      if (date?.isBefore(standard)) {
        void message.warning('오늘 이후의 날짜를 선택해주세요.');
        return;
      }

      const scheduledReleaseAt = date?.toISOString() || '';
      setValue('scheduledReleaseAt', scheduledReleaseAt);
    },
    [setValue, message]
  );
  return (
    <div>
      <Divider orientation='left' className='m-0 py-4'>
        기타정보
      </Divider>
      <div className='flex w-[472px] flex-col gap-6'>
        <FieldWithLabel label='출고 희망일'>
          <DatePicker
            className='w-[232px]'
            value={scheduledReleaseAt ? dayjs(scheduledReleaseAt) : null}
            onChange={handleReleaseAt}
          />
        </FieldWithLabel>
        <FieldWithLabel label='비고'>
          <TextareaField placeholder='내용입력' control={control} name='note' />
        </FieldWithLabel>
      </div>
    </div>
  );
}

export default ExtraInformation;
