import {
  EOrderClaimStatus,
  EOrderClaimType,
  EOrderStatus,
  ESalesChannel,
  ESalesChannelType,
} from '../../../schema/types';
import { ORDER_STATUS_TEXT } from '../../order/const';
import {
  CLAIM_STATUS,
  CREATE_CLAIM_CONFIRM_MESSAGES,
  CREATE_CLAIM_TITLES,
  SALES_CHANNEL_MAP,
  SALES_CHANNEL_TYPE_MAP,
} from '../const';
import { AdminOrderGroupQuery } from '../graphql/createClaim.generated';

/**
 * SalesChannelType : SalesChannel 대응
 *
 * SELLER_SHOP : BALJU / SMART_STORE
 * PB_SHOP : IMWEB / SMART_STORE / MANUAL
 * KEMI : KEMI
 */
export const toSalesChannelLabel = (
  salesChannelType: ESalesChannelType,
  salesChannel: ESalesChannel
) => {
  if (salesChannelType === 'KEMI') {
    return '케미';
  }

  return `${SALES_CHANNEL_TYPE_MAP[salesChannelType]} - ${SALES_CHANNEL_MAP[salesChannel]}`;
};

/**
 *
 * 클레임이 완료된 status = 취소완료, 취소미승인, 교환취소, 교환완료, 반품취소, 반품완료
 * 반품완료, 취소완료된 클레임을 가지고 있다고 신규 클레임 생성 불가.
 *
 * 반품접수 -> 반품요청 = orderStatus : 배송준비, 배송중, 배송완료, 구매확정
 *                   claims: 부분반품/부분취소 클레임 내역이 없고, 모두 완료된 상태이어야 함.
 *
 * 교환접수 -> 교환요청 = orderStatus : 배송준비, 배송중, 배송완료, 구매확정, 반품진행중, 교환진행중, 취소진행중,
 *                   claims: 부분반품/부분취소 클레임 내역이 없고, 모두 완료된 상태이어야 함.
 *
 * 취소처리 -> 취소완료 = orderStatus : 결제대기, 결제완료, 배송준비, 취소진행중
 *                   claims: 부분반품/부분취소 클레임 내역이 없고, 모두 완료된 상태이어야 함.
 *
 * -> 부분반품완료 = orderStatus : 배송준비, 배송중, 배송완료, 구매확정,
 *                   claims: 모두 완료된 상태이어야 함.
 *
 * -> 부분교환완료 = orderStatus : 배송준비, 배송중, 배송완료, 구매확정,
 *                   claims: 모두 완료된 상태이어야 함.
 *
 * -> 부분취소완료 = orderStatus : 결제대기, 결제완료, 배송준비
 *                   claims: 모두 완료된 상태이어야 함.
 */

/**
 * TODO: 부분클레임 스펙아웃 / 부분클레임 삭제 해야함.
 * https://www.notion.so/wiredcompany/12-22-CS-Event-c4dc56459462406fbe70d47fb806e837
 */
export const checkCreateClaimRejectReason: {
  [key in EOrderClaimType]: (args: {
    orderStatus: EOrderStatus;
    claims: Claim[];
  }) => string;
} = {
  RETURN({ orderStatus, claims }) {
    /**
     * 반품접수는 배송준비 이후 단계부터 가능
     */
    if (!AFTER_DELIVERY_ORDER_STATUSES.includes(orderStatus)) {
      return '반품접수가 불가능한 주문상태입니다. 주문상태를 확인해주세요.';
    }

    /**
     * 부분반품, 부분취소 내역이 있으면 일반 클레임 등록 불가
     */
    if (hasPartialReturnClaim(claims) || hasPartialCancelClaim(claims)) {
      return '부분반품/부분취소가 발생한 건은 반품접수가 불가능합니다. 잔여 주문은 부분반품으로 처리해주세요.';
    }

    /**
     * 반품완료 내역이 있으면 클레임 등록 불가
     */
    if (hasReturnCompletedClaim(claims)) {
      return '이미 반품이 완료된 주문입니다.';
    }

    /**
     * 취소완료 내역이 있으면 클레임 등록 불가
     */
    if (hasCancelCompletedClaim(claims)) {
      return '이미 취소처리가 완료된 주문입니다.';
    }

    /**
     * 현재 진행 중인 클레임이 있으면 새로운 클레임을 생성할 수 없다.
     */
    if (getActiveClaim(claims)) {
      return '반품접수가 불가능한 클레임상태입니다. 클레임 내역을 확인해주세요.';
    }

    return '';
  },
  EXCHANGE({ orderStatus, claims }) {
    /**
     * 교환접수는 배송준비 이후 단계부터 가능
     */
    if (!AFTER_DELIVERY_ORDER_STATUSES.includes(orderStatus)) {
      return '교환접수가 불가능한 주문상태입니다. 주문상태를 확인해주세요.';
    }

    /**
     * 부분반품, 부분취소 내역이 있으면 일반 클레임 등록 불가
     */
    if (hasPartialReturnClaim(claims) || hasPartialCancelClaim(claims)) {
      return '부분반품/부분취소가 발생한 주문 건은 교환접수가 불가능합니다. 부분교환으로 처리해주세요.';
    }

    /**
     * 반품완료 내역이 있으면 클레임 등록 불가
     */
    if (hasReturnCompletedClaim(claims)) {
      return '이미 반품이 완료된 주문입니다.';
    }

    /**
     * 취소완료 내역이 있으면 클레임 등록 불가
     */
    if (hasCancelCompletedClaim(claims)) {
      return '이미 취소처리가 완료된 주문입니다.';
    }

    /**
     * 현재 진행 중인 클레임이 있으면 새로운 클레임을 생성할 수 없다.
     */
    if (getActiveClaim(claims)) {
      return '교환접수가 불가능한 클레임상태입니다. 클레임 내역을 확인해주세요.';
    }

    return '';
  },
  CANCEL({ orderStatus, claims }) {
    /**
     * 취소처리는 배송준비 이전 단계에 가능
     */
    if (!BEFORE_DELIVERY_ORDER_STATUSES.includes(orderStatus)) {
      return '취소처리가 불가능한 주문상태입니다. 주문상태를 확인해주세요.';
    }

    /**
     * 부분반품, 부분취소 내역이 있으면 일반 클레임 등록 불가
     * 이 경우는 없다. 부분반품을 했다는 것은 배송이 이루어진 것이니 주문상태 검증에서 걸린다.
     */

    /**
     * 반품완료 내역이 있으면 클레임 등록 불가
     */
    if (hasReturnCompletedClaim(claims)) {
      return '이미 반품이 완료된 주문입니다.';
    }

    /**
     * 취소완료 내역이 있으면 클레임 등록 불가
     */
    if (hasCancelCompletedClaim(claims)) {
      return '이미 취소처리가 완료된 주문입니다.';
    }

    /**
     * 현재 진행 중인 클레임이 있으면 새로운 클레임을 생성할 수 없다.
     */
    if (getActiveClaim(claims)) {
      return '취소처리가 불가능한 클레임상태입니다. 클레임 내역을 확인해주세요.';
    }

    return '';
  },
  PARTIAL_RETURN({ orderStatus, claims }) {
    /**
     * 부분반품처리는 배송준비 이후 단계부터 가능
     */
    if (!AFTER_DELIVERY_ORDER_STATUSES.includes(orderStatus)) {
      return '부분반품처리가 불가능한 주문상태입니다. 주문상태를 확인해주세요.';
    }

    /**
     * 반품완료 내역이 있으면 클레임 등록 불가
     */
    if (hasReturnCompletedClaim(claims)) {
      return '이미 반품이 완료된 주문입니다.';
    }

    /**
     * 취소완료 내역이 있으면 클레임 등록 불가
     */
    if (hasCancelCompletedClaim(claims)) {
      return '이미 취소처리가 완료된 주문입니다.';
    }

    /**
     * 현재 진행 중인 클레임이 있으면 새로운 클레임을 생성할 수 없다.
     */
    if (getActiveClaim(claims)) {
      return '부분반품처리가 불가능한 클레임상태입니다. 클레임 내역을 확인해주세요.';
    }

    return '';
  },
  PARTIAL_EXCHANGE({ orderStatus, claims }) {
    /**
     * 부분교환처리는 배송준비 이후 단계부터 가능
     */
    if (!AFTER_DELIVERY_ORDER_STATUSES.includes(orderStatus)) {
      return '부분교환처리가 불가능한 주문상태입니다. 주문상태를 확인해주세요.';
    }

    /**
     * 반품완료 내역이 있으면 클레임 등록 불가
     */
    if (hasReturnCompletedClaim(claims)) {
      return '이미 반품이 완료된 주문입니다.';
    }

    /**
     * 취소완료 내역이 있으면 클레임 등록 불가
     */
    if (hasCancelCompletedClaim(claims)) {
      return '이미 취소처리가 완료된 주문입니다.';
    }

    /**
     * 현재 진행 중인 클레임이 있으면 새로운 클레임을 생성할 수 없다.
     * 일반교환완료 이후 부분교환을 생성할 수 없다.
     */
    if (getActiveClaim(claims) || hasExchangeCompletedClaim(claims)) {
      return '부분교환처리가 불가능한 클레임상태입니다. 클레임 내역을 확인해주세요.';
    }

    return '';
  },
  PARTIAL_CANCEL({ orderStatus, claims }) {
    /**
     * 부분취소처리는 배송준비 이전 단계에 가능
     */
    if (!BEFORE_DELIVERY_ORDER_STATUSES.includes(orderStatus)) {
      return '부분취소처리가 불가능한 주문상태입니다. 주문상태를 확인해주세요.';
    }

    /**
     * 반품완료 내역이 있으면 클레임 등록 불가
     */
    if (hasReturnCompletedClaim(claims)) {
      return '이미 반품이 완료된 주문입니다.';
    }

    /**
     * 취소완료 내역이 있으면 클레임 등록 불가
     */
    if (hasCancelCompletedClaim(claims)) {
      return '이미 취소처리가 완료된 주문입니다.';
    }

    /**
     * 현재 진행 중인 클레임이 있으면 새로운 클레임을 생성할 수 없다.
     */
    if (getActiveClaim(claims)) {
      return '부분취소처리가 불가능한 클레임상태입니다. 클레임 내역을 확인해주세요.';
    }

    return '';
  },
};

export type Claim = {
  status: EOrderClaimStatus | null;
  claimType: EOrderClaimType | null;
};

export const getActiveClaim = (claims: Claim[]) => {
  return claims.find(
    (claim) =>
      !!claim.status && !INACTIVE_ORDER_CLAIM_STATUS.includes(claim.status)
  );
};

export const hasReturnCompletedClaim = (claims: Claim[]) => {
  return claims.some(
    (claim) =>
      claim.claimType === 'RETURN' && claim.status === 'RETURN_COMPLETED'
  );
};
export const hasExchangeCompletedClaim = (claims: Claim[]) => {
  return claims.some(
    (claim) =>
      claim.claimType === 'EXCHANGE' && claim.status === 'EXCHANGE_COMPLETED'
  );
};
export const hasCancelCompletedClaim = (claims: Claim[]) => {
  return claims.some(
    (claim) =>
      claim.claimType === 'CANCEL' && claim.status === 'CANCEL_COMPLETED'
  );
};

const hasPartialReturnClaim = (claims: Claim[]) => {
  return claims.some((claim) => claim.claimType === 'PARTIAL_RETURN');
};

const hasPartialCancelClaim = (claims: Claim[]) => {
  return claims.some((claim) => claim.claimType === 'PARTIAL_CANCEL');
};

const AFTER_DELIVERY_ORDER_STATUSES: EOrderStatus[] = [
  'DELIVERY_COMPLETED',
  'DELIVERY_STARTED',
  'DELIVERY_WAIT',
  'PURCHASE_CONFIRMED',
  'EXCHANGE_COMPLETED',
];

const BEFORE_DELIVERY_ORDER_STATUSES: EOrderStatus[] = [
  'PAYMENT_WAIT',
  'PAYMENT_COMPLETED',
  'DELIVERY_WAIT',
];

export const INACTIVE_ORDER_CLAIM_STATUS: EOrderClaimStatus[] = [
  'CANCEL_COMPLETED',
  'CANCEL_NOT_APPROVED',
  'EXCHANGE_CANCELED',
  'EXCHANGE_COMPLETED',
  'RETURN_CANCELED',
  'RETURN_COMPLETED',
];

export const getMaxClaimQuantity = (
  order: AdminOrderGroupQuery['adminOrderGroup']['orders']['0'],
  type: EOrderClaimType
): number => {
  // 일반 클레임은 전체 주문 수량이 대상이므로 productQuantity를 리턴
  if (type === 'RETURN' || type === 'CANCEL' || type === 'EXCHANGE') {
    return order.productQuantity;
  }

  const totalClaimQuantity =
    order.claims?.reduce((acc, claim) => {
      if (
        claim.claimType === 'PARTIAL_CANCEL' ||
        claim.claimType === 'PARTIAL_RETURN'
      ) {
        if (claim.claimQuantity) {
          acc += claim.claimQuantity;
        }
      }

      return acc;
    }, 0) || 0;

  return order?.productQuantity
    ? order.productQuantity - totalClaimQuantity
    : 0;
};

export const getClaimConfirmMessage = (
  type: EOrderClaimType,
  status: EOrderClaimStatus,
  order?: AdminOrderGroupQuery['adminOrderGroup']['orders']['0']
) => {
  if (type === 'PARTIAL_RETURN' && order?.salesChannelType === 'SELLER_SHOP') {
    return {
      title: '부분반품처리',
      message: '선택하신 주문을 접수처리하시겠습니까?',
    };
  }

  if (
    type === 'PARTIAL_EXCHANGE' &&
    order?.salesChannelType === 'SELLER_SHOP'
  ) {
    return {
      title: '부분교환처리',
      message: '선택하신 주문을 접수처리하시겠습니까?',
    };
  }

  if (status === 'RETURN_COMPLETED') {
    return {
      title: '반품완료',
      message: '선택하신 주문을 반품완료 처리하시겠습니까?',
    };
  }

  if (status === 'EXCHANGE_COMPLETED') {
    return {
      title: '교환완료',
      message: '선택하신 주문을 교환완료 처리하시겠습니까?',
    };
  }

  return CREATE_CLAIM_CONFIRM_MESSAGES[type];
};

export const getOrderClaimStatus = (order?: {
  activeClaim?: Claim | null;
  status: EOrderStatus;
}): { status: string; claimType: string } => {
  if (!order) {
    return { status: '', claimType: '' };
  }

  const { activeClaim } = order;

  const claimType = activeClaim?.claimType;
  const claimStatus = activeClaim?.status;

  const isNormalClaim =
    claimType === 'RETURN' ||
    claimType === 'CANCEL' ||
    claimType === 'EXCHANGE';

  // TODO: PARTIAL_CANCEL 삭제예정이므로 제외한다.
  const isPartialClaim =
    claimType === 'PARTIAL_EXCHANGE' || claimType === 'PARTIAL_RETURN';

  const isCanceledClaim =
    claimStatus === 'CANCEL_NOT_APPROVED' ||
    claimStatus === 'RETURN_CANCELED' ||
    claimStatus === 'EXCHANGE_CANCELED';

  // 일반 클레임이고 취소 클레임이 아닐 때, 클레임 status 라벨
  if (claimStatus && isNormalClaim && !isCanceledClaim) {
    return { status: CLAIM_STATUS[claimStatus], claimType: '' };
  }

  return {
    status: ORDER_STATUS_TEXT[order.status],
    claimType:
      // 부분클레임이 있을 경우에만 클레임 표기
      isPartialClaim && claimType ? CREATE_CLAIM_TITLES[claimType] : '',
  };
};
