import { Spin, SpinProps } from 'antd';

import useAntdTheme from '../hooks/useAntdTheme';

type PageLoaderProps = {
  overlay?: boolean;
} & SpinProps;

function PageLoader({ overlay, ...props }: PageLoaderProps) {
  const { colorBgMask } = useAntdTheme();

  return (
    <div
      className='fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-white opacity-50'
      style={overlay ? { backgroundColor: colorBgMask } : undefined}
    >
      <Spin size='large' {...props} />
    </div>
  );
}

export default PageLoader;
