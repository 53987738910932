import * as Types from '../../schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ManagersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ManagersQuery = { __typename?: 'Query', adminManagers: Array<{ __typename?: 'ManagerGraphqlType', id: number, email: string, name: string, operatorId: number }> };


export const ManagersDocument = gql`
    query Managers {
  adminManagers {
    id
    email
    name
    operatorId
  }
}
    `;

/**
 * __useManagersQuery__
 *
 * To run a query within a React component, call `useManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagersQuery(baseOptions?: Apollo.QueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, options);
      }
export function useManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, options);
        }
export type ManagersQueryHookResult = ReturnType<typeof useManagersQuery>;
export type ManagersLazyQueryHookResult = ReturnType<typeof useManagersLazyQuery>;
export type ManagersQueryResult = Apollo.QueryResult<ManagersQuery, ManagersQueryVariables>;