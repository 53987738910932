import { DatePicker, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

interface EditableSettlementDateCellProps {
  initialValue: string | null;
  onSubmit: (v: string) => Promise<void>;
}

function EditableSettlementDateCell({
  initialValue,
  onSubmit,
}: EditableSettlementDateCellProps) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const label = value ? dayjs(value).format('YYYY-MM-DD') : '-';

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const handleChange = async (v: Dayjs | null) => {
    const value = v?.startOf('date').toISOString() || null;

    setValue(value);
    value && (await onSubmit(value));
  };

  if (editing) {
    return (
      <Spin spinning={false} size={'small'}>
        <DatePicker
          changeOnBlur
          inputReadOnly
          allowClear={false}
          ref={(ref) => {
            ref?.focus();
          }}
          onOpenChange={setEditing}
          onChange={handleChange}
          value={value ? dayjs(value) : null}
          className={'w-full'}
        />
      </Spin>
    );
  }

  return (
    <div
      className='editable-cell min-h-[30px] rounded-lg px-lg py-sm hover:cursor-pointer'
      onClick={toggleEdit}
    >
      {label}
    </div>
  );
}

export default EditableSettlementDateCell;
