import { Table } from 'antd';

import { useAdminTotalCountSettlementsQuery } from '../../graphql/settlements.generated';
import useSettlementListSearchParams from '../../hooks/useSettlementListSearchParams';

function SettlementListTableSummaryRow() {
  const { filter } = useSettlementListSearchParams();

  const { data: countData } = useAdminTotalCountSettlementsQuery({
    variables: {
      filter,
    },
  });

  if (!countData?.adminTotalCountSettlements) return null;

  const {
    totalCount,
    totalDeliveryBearingAmount,
    totalInHouseMargin,
    totalProductionAmount,
    totalSalesAmount,
    totalSellerSettlementAmount,
    totalVendorSettlementAmount,
  } = countData.adminTotalCountSettlements;

  return (
    <Table.Summary.Row className='bg-[#fafafa] font-semibold'>
      <Table.Summary.Cell index={0} className='bg-[#fafafa]' />
      <Table.Summary.Cell index={1} colSpan={13}>
        총계
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2} colSpan={3}>
        {totalSalesAmount.toLocaleString()}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3}>{totalCount}</Table.Summary.Cell>
      <Table.Summary.Cell index={4}>
        {totalSellerSettlementAmount.toLocaleString()}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={5}>
        {totalVendorSettlementAmount.toLocaleString()}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={6}>
        {totalDeliveryBearingAmount.toLocaleString()}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={7}>
        {totalProductionAmount.toLocaleString()}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={8}>
        {totalInHouseMargin.toLocaleString()}
      </Table.Summary.Cell>
      <Table.Summary.Cell index={9} colSpan={3} />
    </Table.Summary.Row>
  );
}

export default SettlementListTableSummaryRow;
