import { PlusOutlined } from '@ant-design/icons';
import { Pagination, Typography, Button } from 'antd';
import { useState } from 'react';

import EmptyTable from '../common/components/EmptyTable';
import { usePermissions } from '../common/hooks/usePermissions';
import useRouteSearchParams from '../common/hooks/useRouteSearchParams';
import CreateSellerForm from '../domain/seller/components/CreateSellerForm';
import ListSearchForm from '../domain/seller/components/ListSearchForm';
import SellersTable from '../domain/seller/components/SellersTable';
import { useSellersQuery } from '../domain/seller/graphql/sellers.generated';
import { SellerListSearchParams } from '../domain/seller/types';

function SellerList() {
  const { hasPermission } = usePermissions('SELLER_EDIT');

  const { searchParams, setSearchParams } =
    useRouteSearchParams<SellerListSearchParams>();

  const grade = searchParams.grade?.map((grade) => Number(grade));
  const managerId = searchParams.managerId?.map((manager) => Number(manager));
  const currentPage = Number(searchParams.currentPage || 1);
  const pageSize = Number(searchParams.pageSize || 50);
  const sorting =
    searchParams.sortingField && searchParams.sortingOrder
      ? {
          field: searchParams.sortingField,
          order: searchParams.sortingOrder,
        }
      : undefined;

  const filter = searchParams.search
    ? undefined
    : {
        grade,
        managerId,
        phoneNumber: searchParams.phoneNumber,
      };

  const { data, loading, client } = useSellersQuery({
    variables: {
      sellersInput: {
        filter,
        pagination: {
          skip: (currentPage - 1) * pageSize,
          take: pageSize,
        },
        search: searchParams.search
          ? {
              types: ['NAME', 'COMPANY_NAME', 'KEMI_USER_ID', 'SELLER_NAME'],
              keyword: searchParams.search,
            }
          : undefined,
        sorting,
      },
    },
  });

  const [openCreateSeller, setOpenCreateSeller] = useState(false);

  const sellers = data?.adminSellers?.sellers?.map((seller) => ({
    ...seller,
    key: seller?.id,
  }));

  const totalCount = data?.adminSellers?.totalCount || 0;

  const handlePageChange = (page: number, size: number) => {
    if (size !== pageSize) {
      handleSearch({ currentPage: '1', pageSize: size.toString() });
    } else {
      handleSearch({ currentPage: page.toString() });
    }
  };

  const handleCreateSuccess = () => {
    setOpenCreateSeller(false);
  };

  const closeCreateModal = () => setOpenCreateSeller(false);

  const handleSearch = (params: SellerListSearchParams) => {
    setSearchParams(params);

    client.cache.modify({
      fields: {
        adminSellers(_, { DELETE }) {
          // search params 세팅 이후에 기존 sellers 캐시를 날리고 새로 받아와야 함.
          // inline 으로 수정되는 데이터들이 새로운 조건에 맞게 불러와야 함.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return DELETE;
        },
      },
    });
  };

  const isNoResult = !loading && !sellers?.length;

  return (
    <div className='flex flex-col gap-4 pb-xl'>
      <ListSearchForm onSearch={handleSearch} />
      <div className='bg-white p-6'>
        <div className='flex justify-between'>
          <Typography.Title level={4}>셀러리스트</Typography.Title>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            disabled={!hasPermission}
            onClick={() => setOpenCreateSeller(true)}
          >
            셀러등록
          </Button>
        </div>
        {isNoResult && <EmptyTable />}
        {!isNoResult && (
          <>
            <SellersTable
              data={sellers || []}
              editable={hasPermission}
              loading={loading}
              onSearch={handleSearch}
            />
            <div className='mt-4 flex items-center justify-end gap-4'>
              <div>{`총 ${totalCount}건`}</div>
              <Pagination
                showSizeChanger
                onChange={handlePageChange}
                current={currentPage}
                pageSize={pageSize}
                total={totalCount}
              />
            </div>
          </>
        )}
      </div>
      {openCreateSeller && (
        <CreateSellerForm
          close={closeCreateModal}
          editable={hasPermission}
          onSuccess={handleCreateSuccess}
        />
      )}
    </div>
  );
}

export default SellerList;
