import { ThemeConfig } from 'antd';

const fontFamily = `
  "Pretendard Variable",
  Pretendard,
  -apple-system,
  BlinkMacSystemFont,
  system-ui,
  Roboto,
  "Helvetica Neue",
  "Segoe UI",
  "Apple SD Gothic Neo",
  "Noto Sans KR",
  "Malgun Gothic",
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  sans-serif
`;

const theme: ThemeConfig = {
  token: {
    fontFamily,
    colorPrimary: '#1677FF',
  },
  components: {
    Layout: {
      headerBg: '#061178',
    },
  },
};
export default theme;
