import { Empty, Typography } from 'antd';

function EmptyTable({ title }: { title?: string }) {
  return (
    <div className={'flex h-[500px] items-center justify-center'}>
      <Empty
        description={
          <Typography.Paragraph type={'secondary'}>
            {title || '검색결과가 없어요'}
          </Typography.Paragraph>
        }
      />
    </div>
  );
}

export default EmptyTable;
