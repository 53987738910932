import { App } from 'antd';
import { flatMapDeep } from 'lodash-es';
import { FieldValues, FieldErrors } from 'react-hook-form';

export const useFormError = (props?: {
  afterInValid: (errors: FieldErrors) => void;
}) => {
  const { message } = App.useApp();

  const onInvalid = <T extends FieldValues>(errors: FieldErrors<T>) => {
    const getErrorMessages = (errors: any) => {
      const messages = flatMapDeep(errors, (v) => {
        if (!v) {
          return;
        }

        if (Array.isArray(v)) {
          return v.filter((e) => e).map((e) => getErrorMessages(e));
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
        return [v.message];
      }) as string[];

      return messages;
    };

    const errorMessages = getErrorMessages(errors);

    if (errorMessages?.[0]) {
      message.error(errorMessages[0] || '');
    }

    props?.afterInValid(errors);
  };

  return { onInvalid };
};
