import { Tabs } from 'antd';
import { useEffect, useMemo } from 'react';

import useRouteSearchParams from '../common/hooks/useRouteSearchParams';
import OrderListTableWrapper from '../domain/order/component/OrderListTableWrapper';
import Search from '../domain/order/component/Search';
import Summary from '../domain/order/component/Summary';
import { ORDER_LIST_TABS_ITEMS } from '../domain/order/const';
import { OrderListTableSelectedRowProvider } from '../domain/order/contexts/OrderListTableSelectedRowContext';
import { OrderListSearchParams } from '../domain/order/types';

export default function OrderList() {
  const { searchParams, replaceSearchParams } =
    useRouteSearchParams<OrderListSearchParams>();

  const isSummary = useMemo(() => {
    const searchParamsCopy = { ...searchParams };
    delete searchParamsCopy.currentPage;
    delete searchParamsCopy.pageSize;
    delete searchParamsCopy.summaryStatus;
    delete searchParamsCopy.salesChannelType;

    if (Object.keys(searchParamsCopy).length > 0) {
      return false;
    }

    return true;
  }, [searchParams]);

  useEffect(() => {
    if (isSummary) {
      replaceSearchParams({
        salesChannelType: searchParams.salesChannelType,
        summaryStatus: 'PAYMENT_COMPLETED',
      });
    }
  }, []);

  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-6 bg-white p-6'>
        <Tabs
          items={ORDER_LIST_TABS_ITEMS}
          destroyInactiveTabPane
          defaultActiveKey={searchParams.salesChannelType || ''}
          onChange={(activeKey) =>
            replaceSearchParams({
              salesChannelType:
                activeKey === 'ALL'
                  ? undefined
                  : (activeKey as OrderListSearchParams['salesChannelType']),
              currentPage: undefined,
            })
          }
        />
        <div>
          <Summary />
        </div>
      </div>
      <div className='bg-white p-6'>
        <Search />
      </div>
      <div className='bg-white'>
        <OrderListTableSelectedRowProvider>
          <OrderListTableWrapper />
        </OrderListTableSelectedRowProvider>
      </div>
    </div>
  );
}
