import { Input, InputProps, InputRef, Tooltip } from 'antd';
import { forwardRef } from 'react';

import SuffixIcon from './SuffixIcon';

export type TextInputProps = Omit<InputProps, 'status'> & {
  status?: 'error' | 'warning' | 'success' | '';
  errorMessage?: string;
};

const TextInput = forwardRef<InputRef, TextInputProps>(
  ({ status, errorMessage, ...props }, ref) => {
    return (
      <Input
        ref={ref}
        status={status === 'success' ? undefined : status}
        suffix={
          <Tooltip title={errorMessage}>
            {/**
             * suffix icon이 dynamic하게 변경될 때 포커스를 잃는 이슈가 있어 icon을 span으로 감싼다.
             * https://ant.design/components/input#why-input-lose-focus-when-change-prefixsuffixshowcount
             */}
            <span>
              <SuffixIcon status={status} />
            </span>
          </Tooltip>
        }
        {...props}
      />
    );
  }
);

export default TextInput;
