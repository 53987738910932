import { yupResolver } from '@hookform/resolvers/yup';
import { App } from 'antd';
import { get, isEmpty, omit } from 'lodash-es';
import { useEffect } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { HistoriesDocument } from '../../../common/graphql/history.generated';
import { useFormError } from '../../../common/hooks/useFormError';
import { ROUTES } from '../../../routes/const';
import { UpdateVendorInput } from '../../../schema/types';
import { DEFAULT_VENDORS_QUERY_VARIABLE } from '../const';
import { useUpdateFormDirtyContext } from '../contexts/UpdateFormDirtyContext';
import {
  VendorsDocument,
  useUpdateVendorMutation,
} from '../graphql/vendor.generated';
import { updateVendorSchema } from '../validator';

import useVendorDetailQuery from './useVendorDetailQuery';

function useVendorFormUpdateValues() {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [updateVendor] = useUpdateVendorMutation();
  const { data } = useVendorDetailQuery();
  const vendor = data?.adminVendorWithBrands;
  const { setIsDirty } = useUpdateFormDirtyContext();
  const method = useForm<UpdateVendorInput>({
    defaultValues: {
      id: vendor?.id,
      name: vendor?.name,
      managerId: vendor?.managerId,
      vendorContacts: vendor?.vendorContacts.map((contact) =>
        omit(contact, '__typename')
      ),
      settlementDates: vendor?.settlementDates,
      businessName: vendor?.businessName,
      businessRegistrationNumber: vendor?.businessRegistrationNumber,
      businessRegistrationImageUrl:
        vendor?.businessRegistrationImageUrl || null,
      bankCode: vendor?.bankCode,
      accountNumber: vendor?.accountNumber,
      businessBankBookImagerUrl: vendor?.businessBankBookImagerUrl || null,
      accountHolderName: vendor?.accountHolderName,
      comment: vendor?.comment,
    },
    resolver: yupResolver<UpdateVendorInput>(updateVendorSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    setIsDirty(!isEmpty(method.formState.dirtyFields));
  }, [method.formState.dirtyFields, setIsDirty]);
  const { onInvalid } = useFormError({
    afterInValid: (errors: FieldErrors<UpdateVendorInput>) => {
      const businessRegistrationImageUrlError = get(
        errors,
        'businessRegistrationImageUrl'
      );
      const businessBankBookImagerUrlError = get(
        errors,
        'businessBankBookImagerUrl'
      );

      if (businessRegistrationImageUrlError) {
        method.setFocus('businessRegistrationNumber');
      }
      if (businessBankBookImagerUrlError) {
        method.setFocus('businessBankBookImagerUrl');
      }
    },
  });
  const onSubmit = method.handleSubmit(async (data) => {
    await updateVendor({
      variables: { updateVendorInput: data },
      onCompleted() {
        navigate(ROUTES.VENDORS.path);
        message.success('저장이 완료되었습니다.');
      },
      onError() {
        message.error('저장을 실패하였습니다.');
      },
      refetchQueries: [
        HistoriesDocument,
        {
          query: VendorsDocument,
          variables: DEFAULT_VENDORS_QUERY_VARIABLE,
        },
      ],
    });
  }, onInvalid);

  const vendorStatus = vendor?.status === 'ACTIVE' ? '거래중' : '거래중단';
  return {
    vendorId: vendor?.id,
    method,
    onSubmit,
    vendorStatus,
  };
}

export default useVendorFormUpdateValues;
